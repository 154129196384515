import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subject, takeUntil } from 'rxjs';
import { ProductCardComponent } from '../../../components/product-card/product-card.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoriteDetail, FavoritesService } from '../../../services/favorites/favorites.service';
import { SeoService } from '../../../services/seo/seo.service';

@Component({
  selector: 'app-my-favorites',
  standalone: true,
  imports: [
    CommonModule,
    ProductCardComponent,
    ProgressSpinnerModule,
    RetryableSectionComponent,
    TranslocoPipe,
  ],
  templateUrl: './my-favorites.page.html',
  styleUrl: './my-favorites.page.scss',
})
export class MyFavoritesPage implements OnInit, OnDestroy {
  errorLoadingFavorites = false;
  favorite: FavoriteDetail | null = null;
  loadingFavorites = true;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  favoriteItemRemoved(itemId: string): void {
    if (!this.favorite) {
      return;
    }
    this.favorite.items = this.favorite.items.filter((item) => item.resourceId !== itemId);
  }

  initialize(): void {
    this.favoritesService.get().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (favorite) => {
        this.favorite = favorite;
        this.loadingFavorites = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyFavoritesPage.initialize()', 'get-all-favorites', error);
        this.loadingFavorites = false;
        this.errorLoadingFavorites = true;
      },
    });
  }
}
