import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MortgageSimulationPaymentTerm, MortgageSimulationType } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';
import { SendSimulationSidebarComponent } from '../../../components/send-simulation-sidebar/send-simulation-sidebar.component';
import { emailPattern, namePattern, phoneNumberPattern } from '../../../constants/validation-patterns.constants';
import { LocalMortgageSimulation, MortgageSimulatorStep } from '../../../models/mortgage-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MortgageSimulationsService } from '../../../services/mortgage-simulations/mortgage-simulations.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { ToastService } from '../../../services/toast/toast.service';
import { isActive } from '../../../utils/forms.utils';
import { AnalyticsService } from '../../../services/analytics/analytics.service';

@Component({
  selector: 'app-contact-data',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputMaskModule,
    InputTextModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    ReminderComponent,
    SendSimulationSidebarComponent,
    SidebarModule,
    TranslocoModule,
  ],
  templateUrl: './contact-data.page.html',
  styleUrl: './contact-data.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ContactDataPage implements OnInit {
  @ViewChild('sendSimulationSidebar') sendSimulationSidebar: SendSimulationSidebarComponent;
  form: FormGroup<{
    email: FormControl<string | null>;
    fullName: FormControl<string | null>;
    phoneNumber: FormControl<string | null>;
  }>;
  mortgageSimulation: LocalMortgageSimulation;
  sendingSimulation = false;
  simulationSent = false;

  constructor(
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private mortgageSimulationsService: MortgageSimulationsService,
    private platformService: PlatformService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.form = new FormGroup({
      email: new FormControl<string | null>('', [Validators.required, Validators.pattern(emailPattern)]),
      fullName: new FormControl<string | null>('', [Validators.required, Validators.maxLength(108), Validators.pattern(namePattern)]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(phoneNumberPattern)])
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.platformService.isServer()) {
      return;
    }
    const currentMortgageSimulation = await this.getCurrentSimulation();
    const prerequisitesMet = this.stepPrerequisitesMet(currentMortgageSimulation);
    if (!prerequisitesMet) {
      return;
    }
    this.mortgageSimulation = currentMortgageSimulation as LocalMortgageSimulation;
    this.form.patchValue({
      email: this.mortgageSimulation.email,
      fullName: this.mortgageSimulation.fullName,
      phoneNumber: this.mortgageSimulation.phoneNumber || this.form.controls.phoneNumber.value,
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  openConfirmPhoneNumberSidebar(): void {
    const { email, fullName, phoneNumber } = this.form.getRawValue();
    if (!fullName || !email || !phoneNumber) {
      return;
    }
    this.simulationSent = false;
    const simulation = {
      ...this.mortgageSimulation,
      email,
      fullName,
      phoneNumber,
      stepsCompleted: this.mortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.ContactData) ?
        this.mortgageSimulation.stepsCompleted :
        [...this.mortgageSimulation.stepsCompleted, MortgageSimulatorStep.ContactData],
    };
    this.dataStorageService.set<LocalMortgageSimulation>(DataKey.MortgageSimulation, simulation);
    this.mortgageSimulation = simulation;
    this.sendSimulationSidebar.open();
  }

  async sendSimulation(): Promise<void> {
    this.sendingSimulation = true;
    let step = '';
    try {
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('mortgageSimulationsRouter/create'));
      step = 'create-mortgage-simulation';
      await this.mortgageSimulationsService.create({
        ...this.mortgageSimulation,
        paymentTerm: this.mortgageSimulation.paymentTerm as MortgageSimulationPaymentTerm,
        type: this.mortgageSimulation.type as MortgageSimulationType,
        phoneNumber: `+${this.mortgageSimulation.phoneNumber}`,
        recaptchaToken
      });
      this.analyticsService.logGenerateLeadEvent({ lead_source: 'mortgage-simulator' });
      this.toastService.showSuccess({ title: 'Simulación enviada', description: 'Hemos enviado la simulación por WhatsApp al número proporcionado.' });
      this.simulationSent = true;
    } catch (error) {
      this.errorReportingService.log('ContactDataPage.sendSimulation()', step, error);
      this.toastService.showError({ title: 'Algo salió mal', description: 'No se ha podido enviar tu simulación, vuelve a intentarlo.' });
    } finally {
      this.sendingSimulation = false;
    }
  }

  simulateAgain(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/mortgage-simulator']);
  }

  private getCurrentSimulation(): Promise<LocalMortgageSimulation | null> {
    return firstValueFrom(this.dataStorageService.get<LocalMortgageSimulation>(DataKey.MortgageSimulation));
  }

  private stepPrerequisitesMet(currentMortgageSimulation: LocalMortgageSimulation | null): boolean {
    if (!currentMortgageSimulation || !currentMortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationType)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/mortgage-simulator']);
      return false;
    } else if (!currentMortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationData)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/mortgage-simulator/simulation-data']);
      return false;
    } /* else if (!currentMortgageSimulation.stepsCompleted.includes(MortgageSimulatorStep.RemodelingAmount)) { TODO: Uncomment when remodeling amount is available
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/mortgage-simulator/remodeling-amount']);
      return false;
    } */
    return true;
  }
}
