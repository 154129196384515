<section class="consumer-credit-discount-section">
  <div class="left-column">
    @if (displayBenefitKeyPoints) {
      <div class="benefit-key-points-container">
        <h2 class="title">Puntos clave del beneficio</h2>
        <ul class="key-points-list">
          @for (keyPoint of benefitKeyPoints; track $index) {
            <li class="text">{{ keyPoint | transloco }}</li>
          }
        </ul>
        <div class="terms-and-conditions-container">
          <p-checkbox [(ngModel)]="acceptedTermsAndConditions" [binary]="true"></p-checkbox>
          <label class="text">He leído y acepto los <span class="highlighted">Términos y Condiciones</span> de este beneficio</label>
        </div>
        <div class="action-button-container">
          <app-button label="Comenzar" styleClass="primary expand" [disabled]="!acceptedTermsAndConditions" (action)="start()" />
        </div>
      </div>
    } @else if (displayConsumerCreditForm) {
      <div class="consumer-credit-discount-form-container">
        <h2 class="title">¡Obtén un descuento en tu crédito!</h2>
        <p class="description">Mundo Hogar te premia por las compras realizadas en la plataforma.</p>
        <form [formGroup]="form" class="form">
          <div class="input-container">
            <label class="label" for="rfc">RFC</label>
            <p-inputMask id="rfc" [class.active]="isActive(form.controls.rfc)" [formControl]="form.controls.rfc" [autoClear]="false" [unmask]="true" mask="aaaa999999***" placeholder="ABCD1234567X8" slotChar="" aria-describedby="rfc-help"></p-inputMask>
            <small id="rfc-help" [class.hidden]="!this.form.controls.rfc.touched || !this.form.controls.rfc.errors" class="p-error input-error">Ingresa un RFC válido.</small>
          </div>
          <div class="input-container">
            <label for="requested-credit-date">Fecha del crédito solicitado</label>
            <p-calendar class="requested-credit-date" [class.active]="isActive(form.controls.requestedCreditDate)" id="requested-credit-date" dateFormat="dd/mm/yy" [maxDate]="maxRequestedCreditDate" [formControl]="form.controls.requestedCreditDate" aria-describedby="requested-credit-date-help" placeholder="DD/MM/AAAA" />
            <small id="requested-credit-date-help" [class.hidden]="!form.controls.requestedCreditDate.touched || !form.controls.requestedCreditDate.errors" class="p-error input-error">Ingresa una fecha del crédito solicitado válida.</small>
          </div>
          <div class="input-container">
            <label for="credit-amount">Monto total del crédito solicitado</label>
            <p-inputNumber id="credit-amount" inputId="credit-amount" [formControl]="form.controls.creditAmount" locale="es-MX"  mode="currency" placeholder="Ingresa el total del crédito" currency="MXN" suffix=" MXN" [minFractionDigits]="2" [maxFractionDigits]="2" aria-describedby="credit-amount-help" />
            <small id="credit-amount-help" [class.hidden]="!form.controls.creditAmount.touched || !form.controls.creditAmount.errors" class="p-error input-error">Ingresa un monto total del crédito solicitado válido.</small>
          </div>
        </form>
        <p class="obtain-benefit-description">Necesitas haber utilizado en compras dentro de Mundo Hogar el equivalente al 70% de tu crédito solicitado para obtener el beneficio.</p>
        <div class="action-button-container">
          <app-button label="Calcular compras" styleClass="primary expand" [disabled]="form.invalid || calculatingPurchases" [loading]="calculatingPurchases" (action)="calculatePurchases()" />
        </div>
      </div>
    } @else if(displayMeetsRequirements) {
      <div class="meets-requirements-section">
        <div class="go-back-and-title-container">
          <div class="title-container">
            <i class="icon icon-arrow-left" (click)="goBack()"></i>
            <h3 class="title">{{ (meetsRequirements ? 'You meet the requirements!' : 'You do not yet meet the requirements') | transloco }}</h3>
          </div>
          <p class="description">{{ (meetsRequirements ? 'Submit your request so that an executive can confirm the data.' : 'The total amount purchased is not enough.') | transloco }}</p>
        </div>
        <div class="purchases-bar-container">
          <div class="lower-and-upper-amount-container">
            <span class="amount">{{ 0 | currencyFormatter }} {{ currency }}</span>
            <span class="amount">{{ form.controls.creditAmount.value! | currencyFormatter }} {{ currency }}</span>
          </div>
          <div class="bar-container">
            <p-progressBar class="bar" [styleClass]="meetsRequirements ? 'bar meets-requirements' : 'bar'" showValue="false" [value]="completedPercentage * 100" />
          </div>
        </div>
        <div class="purchases-summary">
          <span class="quantity-label">{{ (paymentIntents.length !== 1 ? 'X purchases in Mundo Hogar for' : '1 purchase in Mundo Hogar for') | transloco: { quantity: paymentIntents.length } }}</span>
          <span class="amount">{{ purchasesAmount | currencyFormatter }} {{ currency }}</span>
        </div>
        @if (paymentIntents.length) {
          <a class="see-all-purchases-link" (click)="seeAllPurchases()">Ver todas las compras</a>
        }
        <div class="action-button-container">
          @if (meetsRequirements) {
            <app-button label="Enviar solicitud" styleClass="primary expand" [loading]="sendingRequest" [disabled]="sendingRequest" (action)="sendRequest()" />
          } @else {
            <app-button label="Reingresar datos" styleClass="secondary expand" (action)="goBack()" />
          }
        </div>
      </div>
    } @else if (displayRequestSuccess) {
      <div class="success-container">
        <img class="image" src="assets/images/success-img.svg" alt="Imagen verificación de éxito" />
        <h3 class="title">¡Enviamos tu solicitud!</h3>
        <p class="description">Te recordamos que en casó de ser validada el beneficio se verá reflejado en tu próxima mensualidad.</p>
        <div class="action-button-container">
          <app-button label="Ir al Home" styleClass="primary expand" href="/" />
        </div>
      </div>
    }
  </div>
  <div class="right-column">
    <div class="text-container">
      <h4 class="title">Mundo Hogar te premia</h4>
      <p class="subtitle">Beneficios exclusivos para clientes Santander</p>
    </div>
    <img class="image" src="assets/images/woman-looking-sky.jpg" alt="Imagen de beneficio crédito de consumo" />
  </div>
</section>
<app-purchases-sidebar #purchasesSidebar
  [creditAmount]="form.controls.creditAmount.value!"
  [currency]="currency"
  [meetsRequirements]="meetsRequirements"
  [paymentIntents]="paymentIntents"
  [purchasesAmount]="purchasesAmount"
  [sendingRequest]="sendingRequest"
  (reEnterData)="goBack()"
  (sendRequest)="sendRequest()" />