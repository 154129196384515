import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { WarehouseOrder } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { WarehousePurchaseDetailsComponent } from '../../../../components/warehouse-purchase-details/warehouse-purchase-details.component';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { WarehouseOrdersService } from '../../../../services/warehouse-orders/warehouse-orders.service';
import { getWarehouseOrderFinalStatus, goToReserveWarehouse, WarehouseOrderFinalStatus } from '../../../../utils/warehouse.utils';

@Component({
  selector: 'app-warehouse-status',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    RouterModule,
    TranslocoModule,
    RetryableSectionComponent,
    WarehousePurchaseDetailsComponent,
  ],
  templateUrl: './warehouse-status.page.html',
  styleUrl: './warehouse-status.page.scss',
})
export class WarehouseStatusPage implements OnInit {
  errorLoading = false;
  loading = false;
  order: WarehouseOrder;
  orderFinalStatus: WarehouseOrderFinalStatus;
  orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private router: Router,
    private toastService: ToastService,
    private warehouseOrdersService: WarehouseOrdersService,
  ){}

  goBack(): void {
    this.location.back();
  }

  goToMyWarehouses(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigateByUrl('/private-site/my-warehouses');
  }

  goToReserveWarehouse(order: WarehouseOrder): void {
    goToReserveWarehouse(order, this.errorReportingService, this.toastService);
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.warehouseOrdersService.get(this.orderId).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (order) => {
        if (!order) {
          this.goToMyWarehouses();
          return;
        }
        this.order = order;
        this.orderFinalStatus = getWarehouseOrderFinalStatus(order);
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('WarehouseStatusPage.initialize()', 'get-order', error);
        this.errorLoading = true;
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');

    if (!orderId) {
      this.goToMyWarehouses();
      return;
    }

    this.orderId = orderId;
    this.initialize();
  }
}
