<div class="sign-in-container">
  <section class="sign-in-section">
    <div class="column left">
      <p-tabView class="tab-panel-customer">
        <p-tabPanel class="customer-panel" header="Soy cliente Santander">
          <div class="go-back-container" (click)="goBack()">
            <i class="icon-arrow-left"></i><span class="text">Atrás</span>
          </div>
          <h5 class="title">Para continuar, ingresa a tu cuenta Mundo Hogar</h5>
          @if (!signInWithEmail) {
            <div class="providers-container">
              <app-button [icon]="{ name: 'google', position: 'end' }" label="Ingresar con Google" styleClass="secondary alternative expand" [disabled]="isLoadingOther('google')" [loading]="isLoading('google')" (action)="signIn('google')" />
              <app-button [icon]="{ name: 'apple', position: 'end' }" label="Ingresar con Apple" styleClass="secondary alternative expand" [disabled]="isLoadingOther('apple')" [loading]="isLoading('apple')" (action)="signIn('apple')" />
              <app-button [icon]="{ name: 'email', position: 'end' }" label="Ingresar con email" styleClass="secondary alternative expand" [disabled]="isLoadingOther('email')" [loading]="isLoading('email')" (action)="showEmailForm()" />
            </div>
          } @else {
            <form [formGroup]="form" class="form-container">
              <div class="input-container">
                <label for="email">Correo electrónico</label>
                <input id="email" type="email" [class.active]="isActive(form.controls.email)" pInputText [formControl]="form.controls.email" aria-describedby="email-help" email />
                <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error">Ingresa un correo válido.</small>
              </div>
              <div class="input-container">
                <label for="password">Contraseña</label>
                <p-password id="password" [class.active]="isActive(form.controls.password)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" [formControl]="form.controls.password" aria-describedby="password-help" />
                <small id="password-help" [class.hidden]="!form.controls.password.touched || !form.controls.password.errors" class="p-error input-error">Ingresa una contraseña válida.</small>
              </div>
              <p class="forgot-password"><a class="link" routerLink="/enrollment/password-reset">¿Olvidaste tu contraseña?</a></p>
              <app-button type="submit" label="Ingresar" styleClass="primary expand" [disabled]="!form.valid || isLoadingOther('email')" [loading]="isLoading('email')" (action)="signIn('email')" />
            </form>
          }
          <p class="account-reminder">{{ 'Don\'t have a Mundo Hogar account?' | transloco }} <a class="link" (click)="goTo('/enrollment/sign-up')">Crear cuenta</a></p>
        </p-tabPanel>
        <p-tabPanel class="not-customer-panel" header="No soy cliente Santander">
          <h2 class="title">Únete y descubre la tranquilidad financiera con <span class="highlight">Santander</span></h2>
          <p class="subtitle">Más de <strong>10 millones de clientes </strong>ya disfrutan de los beneficios de Mundo Hogar.</p>
          <a class="debit-container" href="https://www.santander.com.mx/personas/cuentas/debito-likeu.html" rel="noopener" target="_blank">
            <div class="content">
              <img class="image" src="/assets/images/likeu.png" alt="Imagen de Débito LikeU" />
              <div class="text-container">
                <p class="title">Cuenta Débito LikeU</p>
                <p class="join-us">Abre tu cuenta desde tu celular y recibe tu tarjeta personalizada en tu domicilio. ¡Compra, retira y paga desde donde estés!</p>
                <p class="join-us summary">¡Compra, retira y paga desde donde estés!</p>
              </div>
            </div>
          </a>
          <p class="discover">Descubre todo lo que Santander tiene para ti <a class="link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener" target="_blank">aquí</a></p>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="column right">
      <div class="text-container">
        <h2 class="title">Disfruta con Mundo Hogar</h2>
        <p class="subtitle">Todo para tu hogar en un solo lugar</p>
      </div>
    </div>
  </section>
</div>
