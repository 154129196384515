<section class="my-purchases-container">
  <div class="title-container">
    <i class="icon icon-arrow-left" routerLink="/private-site"></i>
    <h2 class="title">Mis compras
      @if (ecommerceOrders.length) {
        <span class="total-items">({{ ecommerceOrders.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!ecommerceOrders.length) {
      <div class="empty-purchases animate__fadeIn">
        <img class="image" src="/assets/illustrations/empty-purchases.svg" [alt]="'You don\'t have purchases illustration' | transloco" />
        <h5 class="title">Aún no tienes compras</h5>
        <p class="subtitle">Comienza a explorar las compras para el hogar</p>
        <app-button label="Ir a compras para el hogar" styleClass="secondary" href="/ecommerce" />
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
        @for (order of ecommerceOrders; track $index) {
          <div class="order-container">
            <div class="header">
              <span class="creation-date">{{ order.createdAt | date: 'dd/MM/yyyy' }}</span>
              <!-- TODO: Add logic to repurchase. This link is displayed only when one product was purchased -->
              <a class="repurchase-link single">Volver a comprar</a>
            </div>
            <div class="products-container">
              @for (item of order.items; track $index) {
                <div class="product-container" routerLink="/private-site/my-purchases/{{ order.id }}" [queryParams]="{ itemId: item.id }">
                  <div class="column first">
                    <img [src]="item.thumbnail" alt="Imagen del producto" loading="lazy" />
                    <div class="right-container">
                      @if (['in-process', 'collected'].includes(item.shippingStatus)) {
                        <span class="shipping-status active">En preparación</span>
                        @if (item.shippingDate) {
                          <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: item.shippingDate | date: 'dd/MM/yyyy' } }}</span>
                        }
                        <!-- TODO: Uncomment when the estimatedShippingDate is available
                        @else {
                          <span class="delivery-date">{{ 'Arrives between X and Y' | transloco: { dateFrom: order.estimatedShippingDate.from | date: 'dd/MM/yyyy', dateTo: order.estimatedShippingDate.to | date: 'dd/MM/yyyy' } }}</span>
                        }
                        -->
                        <span class="shipping-status-description">Estamos preparando tu pedido</span>
                      } @else if (item.shippingStatus === 'in-transit') {
                        <span class="shipping-status active">En camino</span>
                        @if (item.shippingDate) {
                          <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: item.shippingDate | date: 'dd/MM/yyyy' } }}</span>
                        }
                        <!-- TODO: Uncomment when the estimatedShippingDate is available
                        @else {
                          <span class="delivery-date">{{ 'Arrives between X and Y' | transloco: { dateFrom: order.estimatedShippingDate.from | date: 'dd/MM/yyyy', dateTo: order.estimatedShippingDate.to | date: 'dd/MM/yyyy' } }}</span>
                        }
                        -->
                        <span class="shipping-status-description">Tu pedido está en camino</span>
                      } @else if (item.shippingStatus === 'delivered') {
                        <span class="shipping-status done">Entregado</span>
                        @if (item.shippingDate) {
                          <span class="delivery-date">{{ 'Arrived on X' | transloco: { date: (item.shippingDate | date: 'dd/MM/yyyy') } }}</span>
                        }
                        <span class="shipping-status-description">Tu pedido ha sido entregado</span>
                      } @else if (item.shippingStatus === 'exception-of-delivery') {
                        <span class="shipping-status delayed">Fallo de entrega</span>
                        @if (item.shippingDate) {
                          <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: item.shippingDate | date: 'dd/MM/yyyy' } }}</span>
                        }
                        <span class="shipping-status-description">Tu pedido no se pudo entregar debido a circunstancias fuera de lo normal</span>
                      } @else if (item.shippingStatus === 'voided') {
                        <span class="shipping-status done">Cancelado</span>
                        <span class="shipping-status-description">Tu pedido ha sido cancelado, contáctanos para más información</span>
                      }
                    </div>
                  </div>
                  <div class="column second">
                    <span>{{ item.quantity }} u. | {{ (item.quantity * item.price) | currencyFormatter: order.currency }} {{ order.currency }}</span>
                  </div>
                  <div class="column third">
                    <!-- TODO: Add logic to repurchase. This link is displayed only when one product was purchased -->
                    <!-- <app-button label="Volver a comprar" styleClass="tertiary" /> -->
                    <app-button label="Ver compra" styleClass="secondary" href="/private-site/my-purchases/{{ order.id }}" [queryParams]="{ itemId: item.id }" />
                  </div>
                  <i class="icon icon-arrow-right"></i>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </app-retryable-section>
</section>