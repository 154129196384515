import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonComponent } from '../../components/button/button.component';
import { PlatformService } from '../../services/platform/platform.service';
import { SeoService } from '../../services/seo/seo.service';

interface Category {
  alt: string;
  buttonLabel: string;
  company: string;
  image: string;
  services: string;
  shortCompany: string;
  showMore: boolean;
  title: string;
  trustedInfo: string[];
  url: string;
}

@Component({
  selector: 'app-what-is-mundo-hogar',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CardModule,
    CarouselModule,
    CommonModule,
    RouterLink,
    SharedModule,
    TooltipModule,
  ],
  templateUrl: './what-is-mundo-hogar.page.html',
  styleUrl: './what-is-mundo-hogar.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class WhatIsMundoHogarPage implements OnInit {
  categories: Category[] = [
    {
      alt: 'Remodelación imagen principal',
      buttonLabel: 'Simular remodelación',
      company: 'Aquí Tu Remodelación (ATR)',
      image: 'assets/images/remodeling-main.svg',
      services: 'Remodelaciones integrales, cocina y baños.',
      shortCompany: 'ATR',
      showMore: false,
      title: 'Remodelación',
      trustedInfo: [
        'Plazos, costos y garantías acordados a través de contratos digitales.',
        'Profesionales avalados, auditados y capacitados a través de ATR University.',
        'Soporte técnico post venta con hasta 2 años de garantía.',
        'Presencia a nivel nacional con más de 140 franquicias.',
      ],
      url: '/project-simulator',
    },
    {
      alt: 'Imagen principal de mudanzas',
      buttonLabel: 'Cotizar mudanza',
      company: 'MUDANGO',
      image: 'assets/images/moving-main.svg',
      services: 'Mudanza local y bodegaje seguro.',
      shortCompany: 'MUDANGO',
      showMore: false,
      title: 'Mudanzas y bodegaje',
      trustedInfo: [
        '30,000 mudanzas al año.',
        '96% de satisfacción de clientes.',
        'Presencia en las principales ciudades y zonas del país.',
        'Primera empresa de mudanzas 100% online en México, Colombia y Chile.',
      ],
      url: '/moving',
    },
    {
      alt: 'Imagen de asistencias del hogar',
      buttonLabel: 'Revisar planes',
      company: 'IKE',
      image: 'assets/images/home-assistance-main.svg',
      services: 'Plomería, electricidad, vidriería y asistencia para mascotas.',
      shortCompany: 'IKE',
      showMore: false,
      title: 'Asistencias Hogar y Mascotas',
      trustedInfo: [
        '30 años con bancos y aseguradoras.',
        'Nacional, asistencia 24/7.',
        '900,000 servicios en México y 1.7 millones en LATAM.',
        'Trayectoria: Fundada en 1988, presencia en 4 países.',
      ],
      url: '/home-assistance',
    },
    {
      alt: 'Imagen compras para el hogar',
      buttonLabel: 'Ir a comprar',
      company: 'SEARS',
      image: 'assets/images/home-shopping.svg',
      services: 'Venta de productos de línea blanca, electrodomésticos, muebles y decoración.',
      shortCompany: 'SEARS',
      showMore: false,
      title: 'Compras para el hogar',
      trustedInfo: [
        'Especializada en Hogar: Más de 15,000 productos de calidad.',
        'Capacidad: Más de 90 tiendas a nivel nacional.',
        'Marketplace: Tecnología avanzada, cobertura y distribución.',
        'Trayectoria: Parte del Grupo Sanborns, en México desde 1947.',
      ],
      url: '/ecommerce',
    }
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private platformService: PlatformService,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('what-is-mundo-hogar');
    }
  }

  toggleShowMore(category: Category): void {
    category.showMore = !category.showMore;
  }
}
