import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Params, RouterModule } from '@angular/router';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent implements OnInit {
  @Output() action = new EventEmitter<void>();
  @Input() context: string | null = null;
  @Input() disabled: boolean;
  @Input() href: string;
  @Input() icon: string | { name: string; position: 'start' | 'end'; };
  @Input({ required: true }) innerId: string;
  @Input() label: string;
  @Input() loading: boolean;
  @Input() queryParams: Params;
  @Input() rel = '';
  @Input() styleClass = 'primary';
  @Input() target = '_self';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  iconConfig: { name: string; position: 'start' | 'end' } | null;
  internalLink = false;

  constructor() {}

  ngOnInit(): void {
    this.initialize();
  }

  triggerAction(): void {
    if (this.disabled || this.loading) {
      return;
    }
    this.action?.emit();
  }

  private initialize(): void {
    this.internalLink = this.href?.startsWith('/');
    if (typeof this.icon === 'string') {
      this.iconConfig = { name: this.icon, position: 'start' };
    } else {
      this.iconConfig = this.icon ?? null;
    }
  }
}
