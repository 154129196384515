import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonComponent } from '../../../components/button/button.component';
import { emailPattern } from '../../../constants/validation-patterns.constants';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';
import { SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { isActive } from '../../../utils/forms.utils';
import { equalValuesValidator } from '../../../validators/equal-values.validator';
import { passwordValidator } from '../../../validators/password.validator';

@Component({
  selector: 'app-password-reset',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
  templateUrl: './password-reset.page.html',
  styleUrl: './password-reset.page.scss',
})
export class PasswordResetPage implements OnInit {
  displayNewPasswordView = false;
  emailForm: FormGroup<{
    email: FormControl<string | null>;
  }>;
  newPasswordForm: FormGroup<{
    password: FormControl<string | null>;
    repeatedPassword: FormControl<string | null>;
  }>;
  recoveringPassword = false;
  updatedPassword = false;
  updatingPassword = false;
  private resetCode: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private platformService: PlatformService,
    private seoService: SeoService,
    private sessionsService: SessionsService,
    private toastService: ToastService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.emailForm = new FormGroup({
      email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(emailPattern)]),
    });
    this.newPasswordForm = new FormGroup({
      password: new FormControl<string | null>(null, [Validators.required, passwordValidator]),
      repeatedPassword: new FormControl<string | null>(null, [Validators.required, equalValuesValidator('password')]),
    });
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  async recover(): Promise<void> {
    if (this.emailForm.invalid) {
      return;
    }
    this.emailForm.disable();
    this.recoveringPassword = true;
    try {
      await this.sessionsService.sendPasswordRecovery({
        email: this.emailForm.controls.email.value!.trim(),
      });
      this.toastService.showSuccess({ title: 'Enviamos instrucciones', description: 'Hemos enviado a tu correo las instrucciones para reestablecer contraseña.' });
      this.emailForm.reset();
    } catch (error) {
      this.errorReportingService.log('PasswordResetPage.recover()', 'recover-password', error);
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
    } finally {
      this.emailForm.enable();
      this.recoveringPassword = false;
    }
  }

  async update(): Promise<void> {
    if (this.newPasswordForm.invalid) {
      return;
    }
    this.newPasswordForm.disable();
    this.updatingPassword = true;
    try {
      await this.sessionsService.updatePassword(this.newPasswordForm.controls.password.value!.trim(), this.resetCode);
      this.toastService.showSuccess({ title: 'Reestableciste tu contraseña', description: 'Ya puedes utilizar tu nueva contraseña de tu cuenta Mundo Hogar.' });
      this.updatedPassword = true;
    } catch (error) {
      this.errorReportingService.log('PasswordResetPage.update()', 'update-password', error);
      this.toastService.showError({ title: 'Enlace con problemas', description: 'El enlace para reestablecer tu contraseña es inválido o ya caducó.' });
      this.displayNewPasswordView = false;
    } finally {
      this.updatingPassword = false;
    }
  }

  private async initialize(): Promise<void> {
    const resetCode = this.activatedRoute.snapshot.queryParamMap.get('oobCode');
    if (!resetCode) {
      return;
    }
    this.resetCode = resetCode;
    try {
      await this.sessionsService.verifyPasswordResetCode(this.resetCode);
      this.displayNewPasswordView = true;
    } catch (error) {
      this.errorReportingService.log('PasswordResetPage.initialize()', 'verify-password-reset-code', error);
      this.toastService.showError({ title: 'Enlace con problemas', description: 'El enlace para reestablecer tu contraseña es inválido o ya caducó.' });
    }
  }
}
