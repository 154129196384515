import { EventEmitter, Inject, Injectable } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  IdTokenResult,
  OAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
  UserCredential,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { EmailVerificationsRouter, PasswordRecoveriesRouter, SendEmailVerificationData, SendPasswordRecoveryData, SessionsRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { EcdhService } from '../ecdh/ecdh.service';
import { SoftTokensService } from '../soft-tokens/soft-tokens.service';
import { DataStorageService } from '../data-storage/data-storage.service';
import { Session, SessionsService } from './sessions.service';

@Injectable({
  providedIn: 'root'
})
export class SessionsServiceApi implements SessionsService {
  private accountsServerAuthStateChangedObservable: Observable<User | null>;
  private emailVerificationsClient: CreateTRPCProxyClient<EmailVerificationsRouter>;
  private hogarServerAuthStateChangedObservable: Observable<User | null>;
  private onSignInEventEmitter = new EventEmitter<void>();
  private onSignOutEventEmitter = new EventEmitter<void>();
  private passwordRecoveriesClient: CreateTRPCProxyClient<PasswordRecoveriesRouter>;
  private sessionsClient: CreateTRPCProxyClient<SessionsRouter>;

  constructor(
    private hogarServerAuth: Auth,
    @Inject('accountsServerAuth') private accountsServerAuth: Auth,
    private dataStorageService: DataStorageService,
    private ecdhService: EcdhService,
    private activatedRoute: ActivatedRoute,
    private softTokensService: SoftTokensService,
  ) {
    this.emailVerificationsClient = createTRPCProxyClient<EmailVerificationsRouter>(getTRPCClientOptions(`${environment.apiUrl}/emailVerifications`, () => this.getAccessToken()));
    this.passwordRecoveriesClient = createTRPCProxyClient<PasswordRecoveriesRouter>(getTRPCClientOptions(`${environment.apiUrl}/passwordRecoveries`));
    this.sessionsClient = createTRPCProxyClient<SessionsRouter>(getTRPCClientOptions(`${environment.apiUrl}/sessions`, () => this.getAccessToken()));
    this.hogarServerAuth.onIdTokenChanged((user) => this.handleSession(user));
    this.hogarServerAuthStateChangedObservable = new Observable((subscriber) => {
      const unsubscribe = this.hogarServerAuth.onAuthStateChanged((user) => subscriber.next(user), (error) => subscriber.error(error), () => subscriber.complete());
      subscriber.add(unsubscribe);
    });
    this.accountsServerAuthStateChangedObservable = new Observable((subscriber) => {
      const unsubscribe = this.accountsServerAuth.onAuthStateChanged((user) => subscriber.next(user), (error) => subscriber.error(error), () => subscriber.complete());
      subscriber.add(unsubscribe);
    });
  }

  getAccessToken(): Promise<string | null> {
    return this.hogarServerAuth.currentUser?.getIdToken() ?? Promise.resolve(null);
  }

  getSession(): Observable<Session | null> {
    return new Observable((subscriber) => {
      const unsubscribe = combineLatest([this.hogarServerAuthStateChangedObservable, this.accountsServerAuthStateChangedObservable]).subscribe({
        next: ([hogarUser, accountsUser]) => {
          if (!hogarUser || !accountsUser) {
            subscriber.next(null);
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          Promise.allSettled([
            hogarUser.getIdToken(),
            hogarUser.getIdTokenResult()
          ]).then(([idToken, idTokenResult]) => {
            if (idToken.status === 'fulfilled' && idTokenResult.status === 'fulfilled') {
              if (!!idTokenResult.value.claims?.['app_session_id'] && !!idTokenResult.value.claims?.['app_user_id']) {
                subscriber.next({
                  claims: idTokenResult.value.claims,
                  token: idToken.value,
                  userId: idTokenResult.value.claims['app_user_id'] as string
                });
              } else {
                subscriber.next(null);
              }
            } else {
              subscriber.next(null);
              subscriber.error(idToken.status === 'rejected' ? idToken.reason : idTokenResult.status === 'rejected' ? idTokenResult.reason : new Error('Unknown error getting idToken and idTokenResult'));
            }
          });
        },
        error: (error) => subscriber.error(error),
        complete: () => subscriber.complete(),
      });
      subscriber.add(unsubscribe);
    });
  }

  onSignIn(): EventEmitter<void> {
    return this.onSignInEventEmitter;
  }

  onSignOut(): EventEmitter<void> {
    return this.onSignOutEventEmitter;
  }

  async sendEmailVerification(data: SendEmailVerificationData): Promise<void> {
    await this.emailVerificationsClient.send.mutate(data);
  }

  sendPasswordRecovery(data: SendPasswordRecoveryData): Promise<void> {
    return this.passwordRecoveriesClient.sendPasswordRecovery.mutate(data);
  }

  async signIn(options: { provider: 'apple'; } | { provider: 'google'; } | { data: { email: string; password: string; }; provider: 'email'; }, recaptchaTokenGenerator: () => Promise<string>): Promise<void> {
    let step = '';
    try {
      let userCredential: UserCredential;
      const { provider } = options;
      if (provider !== 'email') {
        step = 'sign-in-with-popup';
        const authProvider = provider === 'google' ? new GoogleAuthProvider() : new OAuthProvider('apple.com');
        userCredential = await signInWithPopup(this.hogarServerAuth, authProvider);
      } else {
        step = 'sign-in-with-email';
        const { email, password } = options.data;
        userCredential = await signInWithEmailAndPassword(this.hogarServerAuth, email, password);
      }
      step = 'generate-recaptcha-token';
      const recaptchaToken = await recaptchaTokenGenerator();
      step = 'create-session';
      await this.createSession(userCredential.user, recaptchaToken);
    } catch (error) {
      const { code, message } = error as { code: string; message: string; };
      if (code?.startsWith('auth/')) {
        return Promise.reject({ error, code: code.split('/')[1], message, step });
      }
      return Promise.reject({ code: 'unknown-error', message: 'Unknown error', error, step });
    }
  }

  async signOut(): Promise<void> {
    const [hogarSignOut, accountsSignOut] = await Promise.allSettled([
      this.hogarServerAuth.signOut(),
      this.accountsServerAuth.signOut(),
    ]);
    const reasons = [];
    if (hogarSignOut.status === 'rejected') {
      reasons.push(hogarSignOut.reason);
    }
    if (accountsSignOut.status === 'rejected') {
      reasons.push(accountsSignOut.reason);
    }
    if (reasons.length) {
      return Promise.reject({ code: 'sign-out', message: 'Unknown error', error: reasons, step: 'sign-out' });
    } else {
      this.dataStorageService.clearAll();
    }
  }

  async signUp(options: { data: { email: string; password: string; }; provider: 'email'; }, recaptchaTokenGenerator: () => Promise<string>): Promise<void> {
    let step = '';
    try {
      const { provider } = options;
      if (provider !== 'email') {
        throw new Error('Provider not supported');
      }
      step = 'sign-in-with-email';
      const userCredential = await createUserWithEmailAndPassword(this.hogarServerAuth, options.data.email, options.data.password);
      step = 'generate-recaptcha-token';
      const recaptchaToken = await recaptchaTokenGenerator();
      step = 'create-session';
      await this.createSession(userCredential.user, recaptchaToken);
      step = 'send-email-verification';
      const origin = this.activatedRoute.snapshot.queryParamMap.get('origin');
      const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
      await this.sendEmailVerification({
        ...(origin && { origin }),
        ...(redirectTo && { redirectTo }),
      });
    } catch (error) {
      const { code, message } = error as { code: string; message: string; };
      if (code.startsWith('auth/')) {
        return Promise.reject({ code: code.split('/')[1], message, error, step });
      }
      throw error;
    }
  }

  updatePassword(password: string, resetCode?: string): Promise<void> {
    if (!resetCode) {
      throw new Error('Reset code not provided');
    }
    return confirmPasswordReset(this.hogarServerAuth, resetCode, password);
  }

  async verifyEmail(token: string): Promise<{ origin?: string; redirectTo: string; } | null> {
    let step = '';
    try {
      step = 'verify-email';
      const { data } = await this.emailVerificationsClient.verify.mutate({ token });
      step = 'refresh-id-tokens';
      await this.refreshIdTokens();
      return data;
    } catch (error) {
      return Promise.reject({ code: step, message: 'Unknown error', error, step });
    }
  }

  async verifyPasswordResetCode(code: string): Promise<void> {
    return verifyPasswordResetCode(this.hogarServerAuth, code).then();
  }

  private async createSession(user: User, recaptchaToken: string): Promise<void> {
    let step = '';
    try {
      if (!user.email) {
        return Promise.reject(new Error('User not have email'));
      }
      step = 'generate-key-pair';
      const clientKeyPair = this.ecdhService.generateKeyPair();
      step = 'get-public-values';
      const { x: cPublicX, y: cPublicY } = this.ecdhService.getPublicValues(clientKeyPair);
      step = 'enroll-soft-token';
      const { id: softTokenId } = await this.softTokensService.enrollSoftToken(user.email, cPublicX, cPublicY);
      step = 'create-hogar-server-session';
      const { accountsServerToken } = await this.sessionsClient.create.mutate({ softTokenId, recaptchaToken });
      step = 'sign-in-with-custom-token';
      await signInWithCustomToken(this.accountsServerAuth, accountsServerToken);
      await this.refreshIdTokens();
      // TODO: should generate the shared secret, encrypt it and save it on the user browser
    } catch (error) {
      return Promise.reject({ code: 'unknown-error', message: 'Unknown error', error, step });
    }
  }

  private handleSession(user: User | null): void {
    if (!user) {
      this.onSignOutEventEmitter.emit();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.allSettled([
      user.getIdToken(),
      user.getIdTokenResult()
    ]).then(([idToken, idTokenResult]) => {
      if (idToken.status === 'fulfilled' && idTokenResult.status === 'fulfilled') {
        if (this.isSessionValid(idTokenResult.value)) {
          this.onSignInEventEmitter.emit();
        } else {
          this.onSignOutEventEmitter.emit();
        }
      } else {
        this.onSignOutEventEmitter.emit();
      }
    });
  }

  private isSessionValid(idTokenResult: IdTokenResult): boolean {
    return !!idTokenResult.claims?.['app_session_id'] && !!idTokenResult.claims?.['app_user_id'];
  }

  private async refreshIdTokens(): Promise<void> {
    const promises: Promise<string | IdTokenResult>[] = [];
    if (this.hogarServerAuth.currentUser) {
      promises.push(this.hogarServerAuth.currentUser.getIdToken(true));
      promises.push(this.hogarServerAuth.currentUser.getIdTokenResult(true));
    }
    if (this.accountsServerAuth.currentUser) {
      promises.push(this.accountsServerAuth.currentUser.getIdToken(true));
      promises.push(this.accountsServerAuth.currentUser.getIdTokenResult(true));
    }
    await Promise.allSettled(promises);
  }
}
