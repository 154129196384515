import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PaymentIntent } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { LottieComponent } from 'ngx-lottie';
import { ButtonModule } from 'primeng/button';
import { combineLatest, firstValueFrom, interval, Subject, Subscription, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { PaymentIntentsService } from '../../../services/payment-intents/payment-intents.service';
import { LocalUser } from '../../../services/users/users.service';
import { EcommerceOrdersService } from '../../../services/ecommerce-orders/ecommerce-orders.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { mapEcommerceOrderItemsToCheckoutItems } from '../../../utils/analytics.utils';

@Component({
  selector: 'app-payment-result',
  standalone: true,
  imports: [
    ButtonComponent,
    ButtonModule,
    CommonModule,
    CurrencyFormatterPipe,
    LottieComponent,
    RetryableSectionComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './payment-result.page.html',
  styleUrl: './payment-result.page.scss',
})
export class PaymentResultPage implements OnInit, OnDestroy {
  countdown = 30;
  email: string;
  errorLoading = false;
  externalPaymentData: {
    authorization: string | null;
    company: string;
    date: string;
    status: 'approved' | 'denied';
    statusAction: string | null;
    statusDetail: string | null;
  } | null = null;
  loading = false;
  paymentIntent: PaymentIntent;
  private paymentIntentId: string;
  private timerSubscription: Subscription | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private paymentIntentsService: PaymentIntentsService,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const status = this.activatedRoute.snapshot.queryParamMap.get('nbResponse');
    const statusDetail = decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('cdResponse') ?? '').replace(/[+]/g, ' ');
    const errorDetail = decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('nb_error') ?? '').replace(/[+]/g, ' ');
    const company = this.activatedRoute.snapshot.queryParamMap.get('empresa') ?? '';
    const reference = this.activatedRoute.snapshot.queryParamMap.get('referencia');
    const date = decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('fecha') ?? '');
    const authorization = this.activatedRoute.snapshot.queryParamMap.get('nuAut');
    const paymentIntentId = this.activatedRoute.snapshot.paramMap.get('paymentIntentId');
    if (!paymentIntentId && !status) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/']);
      return;
    }
    if (status) {
      this.externalPaymentData = {
        authorization,
        company,
        date,
        status: status === 'Aprobado' ? 'approved' : 'denied',
        statusAction: null,
        ...(statusDetail.length ? {
          statusDetail: `${statusDetail}${errorDetail.length ? `: ${errorDetail}` : ''}`,
        }: { statusDetail: null }),
      };
    }
    this.paymentIntentId = paymentIntentId ?? reference as string;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goToMyHome(): void {
    let url = '/private-site';
    if (this.paymentIntent.source === 'ecommerce') {
      url = '/private-site/my-purchases';
    } else if (this.paymentIntent.source === 'home-assistance') {
      url = '/private-site/my-home-assistances';
    } else if (this.paymentIntent.source === 'moving') {
      url = '/private-site/my-movings';
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate([url]);
  }

  goToPayment() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/payment-gateway/checkout'], {
      queryParams: {
        resourceIds: this.paymentIntent.resourceIds,
        resourceType: this.paymentIntent.resourceType,
        source: this.paymentIntent.source,
      }
    });
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    combineLatest([
      this.paymentIntentsService.get(this.paymentIntentId),
      this.dataStorageService.get<LocalUser>(DataKey.LocalUser)
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([paymentIntent, localUser]) => {
        if (!paymentIntent || !localUser) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['/']);
          return;
        }
        if (paymentIntent && !this.paymentIntent) {
          if (paymentIntent.status === 'paid') {
            if (paymentIntent.resourceType === 'product') {
              firstValueFrom(this.ecommerceOrdersService.getByPaymentIntentId(paymentIntent.id)).then((ecommerceOrder) => {
                if (ecommerceOrder) {
                  this.analyticsService.logPurchaseEvent({
                    currency: ecommerceOrder.currency,
                    items: mapEcommerceOrderItemsToCheckoutItems(ecommerceOrder.items),
                    shipping: ecommerceOrder.shippingCost,
                    tax: ecommerceOrder.tax,
                    value: ecommerceOrder.subtotal,
                    transaction_id: ecommerceOrder.id,
                  });
                }
              }).catch((error) => this.errorReportingService.log('PaymentResultPage.initialize()', 'get-ecommerce-order-by-payment-intent-id', error));
            }
            this.startCountdown();
          }
        }
        this.paymentIntent = paymentIntent;
        this.email = localUser.email;
        if (paymentIntent.status === 'paid') {
          this.startCountdown();
        }
        if (paymentIntent.status !== 'pending') {
          this.loading = false;
          if (paymentIntent.apportions.external.length && paymentIntent.apportions.external[0].channel === 'getnet') {
            if (this.externalPaymentData) {
              if (['03', '05', '08', '09', '16'].includes((this.paymentIntent.additionalData?.['getnet']?.responseCode))) {
                this.externalPaymentData.statusAction = 'Ha ocurrido un error al procesar tu solicitud, intenta de nuevo más tarde.';
              } else if ((this.paymentIntent.additionalData?.['getnet']?.responseCode) === '11') {
                this.externalPaymentData.statusAction = 'La transacción ya fue rechazada durante el día. Intenta con otra tarjeta o en caso contrario, intenta a partir de mañana.';
              } else {
                this.externalPaymentData.statusAction = 'La transacción no se pudo completar, revisa los datos ingresados o contacta a tu banco.';
              }
            }
          }
        }
      },
      error: (error) => {
        this.errorReportingService.log('PaymentResultPage.initialize()', 'get-payment-intent', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  private startCountdown(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = interval(1000).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: () => {
        this.countdown--;
        if (this.countdown === 0) {
          this.goToMyHome();
        }
      }
    });
  }
}
