import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AdvertisingSpace } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { LottieComponent } from 'ngx-lottie';
import { AccordionModule } from 'primeng/accordion';
import { Carousel, CarouselModule, CarouselPageEvent } from 'primeng/carousel';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { ProductsCarouselComponent } from '../../components/products-carousel/products-carousel.component';
import { RetryableSectionComponent } from '../../components/retryable-section/retryable-section.component';
import { TermsBannerComponent } from '../../components/terms-banner/terms-banner.component';
import { AdvertisingSpacesService } from '../../services/advertising-spaces/advertising-spaces.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { PlatformService } from '../../services/platform/platform.service';
import { SeoService } from '../../services/seo/seo.service';
import { getRandomInteger } from '../../utils/numbers.utils';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    LottieComponent,
    ProductsCarouselComponent,
    RetryableSectionComponent,
    RouterLink,
    TermsBannerComponent,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit {
  @ViewChild('carousel') carousel: Carousel;
  currentPage = 0;
  errorLoadingAdvertisingSpaces = false;
  exclusiveBenefits: AdvertisingSpace[] = [];
  highlightedProductsPage = getRandomInteger(1, 5);
  loadingAdvertisingSpaces = false;
  promotions: AdvertisingSpace[] = [];
  private isScrolling = false;
  private scrollTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private advertisingSpacesService: AdvertisingSpacesService,
    private errorReportingService: ErrorReportingService,
    private platformService: PlatformService,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('');
      return;
    }
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async getAdvertisingSpaces(): Promise<void> {
    this.loadingAdvertisingSpaces = true;
    this.errorLoadingAdvertisingSpaces = false;
    try {
      const [exclusiveBenefits, promotions] = await Promise.allSettled([
        firstValueFrom(this.advertisingSpacesService.getExclusiveBenefits()),
        firstValueFrom(this.advertisingSpacesService.getPromotions()),
      ]);
      this.exclusiveBenefits = exclusiveBenefits.status === 'fulfilled'
        ? exclusiveBenefits.value.map((item, index) => ({
          ...item,
          index: index + 1,
        }))
        : [];
      this.promotions = promotions.status === 'fulfilled'
        ? promotions.value.map((item, index) => ({
          ...item,
          index: index + 1,
        }))
        : [];
    } catch (error) {
      this.errorReportingService.log('HomePage.getAdvertisingSpaces()', 'get-advertising-spaces', error);
      this.errorLoadingAdvertisingSpaces = true;
    } finally {
      this.loadingAdvertisingSpaces = false;
    }
  }

  nextPage(carousel: Carousel) {
    const totalPages = carousel.totalDots();
    if (this.currentPage < totalPages - 1) {
      this.currentPage += 1;
      carousel.page = this.currentPage;
    }
  }

  onPageChange(event: CarouselPageEvent, ) {
    this.currentPage = event.page ?? 0;
  }

  // TODO: F&F move to an utils, and apply to the red banner
  onWheel(event: WheelEvent, carousel: Carousel): void {
    const isHorizontalScroll = Math.abs(event.deltaX) > Math.abs(event.deltaY);
    const SCROLL_THRESHOLD = 0.5;
    if (isHorizontalScroll && this.isScrolling) {
      event.preventDefault();
      return;
    }
    if (isHorizontalScroll) {
      this.isScrolling = true;
      if (event.deltaX > SCROLL_THRESHOLD) {
        event.preventDefault();
        this.nextPage(carousel);
      } else if (event.deltaX < -SCROLL_THRESHOLD) {
        event.preventDefault();
        this.prevPage(carousel);
      }
      clearTimeout(this.scrollTimeout as ReturnType<typeof setTimeout>);
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, 1000);
    }
  }

  prevPage(carousel: Carousel) {
    if (this.currentPage > 0) {
      this.currentPage--;
      carousel.page = this.currentPage;
    }
  }

  startAutoplay(carousel: Carousel): void {
    carousel.startAutoplay();
  }

  stopAutoplay(carousel: Carousel): void {
    carousel.stopAutoplay();
  }

  private initialize(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.getAdvertisingSpaces();
  }
}
