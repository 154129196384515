import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HomeAssistancePlanDuration } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { ButtonComponent } from '../../../components/button/button.component';
import { HomeAssistancePlanDetailsSidebarComponent } from '../../../components/home-assistance-plan-details-sidebar/home-assistance-plan-details-sidebar.component';
import { SelectAssistancePlanSidebarComponent } from '../../../components/select-assistance-plan-sidebar/select-assistance-plan-sidebar.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';

@Component({
  selector: 'app-plan-mascotas',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CommonModule,
    HomeAssistancePlanDetailsSidebarComponent,
    RouterLink,
    SelectAssistancePlanSidebarComponent,
    SharedModule,
    TermsBannerComponent,
    TranslocoPipe,
  ],
  templateUrl: './plan-mascotas.page.html',
  styleUrl: './plan-mascotas.page.scss',
})
export class PlanMascotasPage implements OnInit {
  @ViewChild('assistancePlanDetailsSidebar') assistancePlanDetailsSidebar: HomeAssistancePlanDetailsSidebarComponent;
  @ViewChild('selectAssistancePlanSidebar') selectAssistancePlanSidebar: SelectAssistancePlanSidebarComponent;
  sidebarVisible = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private platformService: PlatformService,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('home-assistance/plan-mascotas');
    }
    this.analyticsService.logHomeAssistanceEvent({ step: 'view', plan: 'plan-mascotas' });
    const action = this.activatedRoute.snapshot.queryParamMap.get('action');
    const duration = this.activatedRoute.snapshot.queryParamMap.get('duration') as HomeAssistancePlanDuration;
    if (action === 'repurchase' && duration) {
      setTimeout(() => {
        this.openSelectPlan(duration);
      }, 0);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  cancel(): void {
    this.sidebarVisible = false;
  }

  openPlanDetails(): void {
    this.assistancePlanDetailsSidebar.open();
  }

  openSelectPlan(duration?: HomeAssistancePlanDuration): void {
    this.selectAssistancePlanSidebar.open(duration);
  }
}
