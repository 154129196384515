import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProjectSimulationQuality } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { StepperModule } from 'primeng/stepper';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { CircularProgressComponent } from '../../../components/circular-progress/circular-progress.component';
import { LocalProjectSimulation } from '../../../models/local-project-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { SeoService } from '../../../services/seo/seo.service';
import { getImageByType } from '../../../utils/project-simulator.utils';

@Component({
  selector: 'app-step-3',
  standalone: true,
  imports: [
    ButtonComponent,
    CircularProgressComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './step-3.page.html',
  styleUrl: './step-3.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class Step3Page implements OnInit {
  bathroomForm: FormGroup<{
    numberOfBathrooms: FormControl<number | null>;
  }>;
  integralRemodelingForm: FormGroup<{
    surfaces: FormControl<ProjectSimulationQuality | null>;
  }>;
  kitchenForm: FormGroup<{
    counterTop: FormControl<ProjectSimulationQuality | null>;
  }>;
  projectSimulation: LocalProjectSimulation | null;
  selectedImage = '';
  subtitleByType = '';
  titleByType = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private location: Location,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.bathroomForm = new FormGroup({
      numberOfBathrooms: new FormControl<number | null>(null, [Validators.required, Validators.min(1), Validators.max(9)]),
    });
    this.integralRemodelingForm = new FormGroup({
      surfaces: new FormControl<ProjectSimulationQuality | null>(null, [Validators.required]),
    });
    this.kitchenForm = new FormGroup({
      counterTop: new FormControl<ProjectSimulationQuality | null>(null, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.projectSimulation = await this.getCurrentSimulation();
    if (!this.projectSimulation || !this.projectSimulation.completedSteps.includes(1) || !this.projectSimulation.completedSteps.includes(2)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/project-simulator/step-1']);
      return;
    }
    const { type } = this.projectSimulation;
    this.selectedImage = getImageByType(type);
    if (type === 'bathroom') {
      this.bathroomForm.patchValue({
        numberOfBathrooms: this.projectSimulation.numberOfBathrooms ?? null,
      });
      this.titleByType = 'Number of bathrooms';
      this.subtitleByType = 'Next: Remodeling standard';
    } else if (type === 'kitchen') {
      this.kitchenForm.patchValue({
        counterTop: this.projectSimulation.counterTop ?? null,
      });
      this.titleByType = 'Kitchen configuration';
      this.subtitleByType = 'Next: Remodeling standard';
    } else if (type === 'integral-remodeling') {
      this.integralRemodelingForm.patchValue({
        surfaces: this.projectSimulation.surfaces ?? null,
      });
      this.titleByType = 'Finishing standard';
      this.subtitleByType = 'Next: Accessory standard';
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get isInvalid(): boolean {
    if (this.projectSimulation?.type === 'bathroom') {
      return this.bathroomForm.invalid;
    } else if (this.projectSimulation?.type === 'kitchen') {
      return this.kitchenForm.invalid;
    } else if (this.projectSimulation?.type === 'integral-remodeling') {
      return this.integralRemodelingForm.invalid;
    }
    return true;
  }

  goBack(): void {
    this.location.back();
  }

  async onSubmit(): Promise<void> {
    const currentSimulation = await this.getCurrentSimulation();
    if (!currentSimulation) {
      return;
    }
    if (!currentSimulation.completedSteps.includes(3)) {
      currentSimulation.completedSteps.push(3);
    }
    if (currentSimulation.type === 'bathroom' && this.bathroomForm.controls.numberOfBathrooms.value) {
      currentSimulation.numberOfBathrooms = this.bathroomForm.controls.numberOfBathrooms.value;
    }
    if (currentSimulation.type === 'kitchen' && this.kitchenForm.valid && this.kitchenForm.controls.counterTop.value) {
      currentSimulation.counterTop = this.kitchenForm.controls.counterTop.value;
    }
    if (currentSimulation.type === 'integral-remodeling' && this.integralRemodelingForm.valid && this.integralRemodelingForm.controls.surfaces.value) {
      currentSimulation.surfaces = this.integralRemodelingForm.controls.surfaces.value;
    }
    this.dataStorageService.set(DataKey.ProjectSimulation, currentSimulation);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/project-simulator/step-4']);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setFormControlValue(formControl: FormControl, value: any): void {
    formControl.setValue(value);
  }

  private getCurrentSimulation(): Promise<LocalProjectSimulation | null> {
    return firstValueFrom(this.dataStorageService.get<LocalProjectSimulation>(DataKey.ProjectSimulation));
  }
}