import { Routes } from '@angular/router';

export default [
  {
    path: 'my-balance',
    loadComponent: () => import('./my-balance/my-balance.page').then(m => m.MyBalancePage),
    data: {
      description: 'Consulta el saldo y movimientos de tus cuentas en Mundo Hogar.',
      title: 'Mis saldos y movimientos',
    },
  },
  {
    path: 'my-personal-data',
    loadComponent: () => import('./my-personal-data/my-personal-data.page').then(m => m.MyPersonalDataPage),
    data: {
      description: 'Actualiza tus datos personales en Mundo Hogar.',
      title: 'Datos personales',
    },
  },
] satisfies Routes;
