import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Address, SearchEngineProduct } from '@homein-hogar-server';
import { TRPCClientError } from '@trpc/client';
import { RippleModule } from 'primeng/ripple';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ManageAddressesSidebarComponent } from '../../../components/manage-addresses-sidebar/manage-addresses-sidebar.component';
import { ProductsCarouselComponent } from '../../../components/products-carousel/products-carousel.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { constants } from '../../../constants';
import { CustomError } from '../../../models/custom-error.model';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { AddressesService } from '../../../services/addresses/addresses.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';
import { ShoppingCartDetail, ShoppingCartItemProduct, ShoppingCartsService } from '../../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LocalUser } from '../../../services/users/users.service';
import { mapSearchEngineProductToCheckoutItem, mapShoppingCartItemProductsToCheckoutItems } from '../../../utils/analytics.utils';

@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    ManageAddressesSidebarComponent,
    ProductsCarouselComponent,
    RetryableSectionComponent,
    RippleModule,
    RouterModule,
  ],
  templateUrl: './shopping-cart.page.html',
  styleUrl: './shopping-cart.page.scss',
})
export class ShoppingCartPage implements OnInit, OnDestroy {
  @ViewChild('manageAddressesSidebar') manageAddressesSidebar: ManageAddressesSidebarComponent;
  addingProduct = false;
  address: Address | null = null;
  categories: string[] = [];
  currency = constants.defaultCurrency;
  errorLoadingShoppingCart = false;
  loadingShoppingCart = false;
  offerThreshold = constants.pricing.ecommerce.sale;
  removingProduct = false;
  selectedItemIds: string[] = [];
  shoppingCartDetail: ShoppingCartDetail | null = null;
  private isMobile = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private addressesService: AddressesService,
    private dataStorageService: DataStorageService,
    private elementRef: ElementRef,
    private errorReportingService: ErrorReportingService,
    private platformService: PlatformService,
    private renderer: Renderer2,
    private router: Router,
    private seoService: SeoService,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
    this.setIsMobile();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async addProduct(product: SearchEngineProduct, quantity = 1): Promise<void> {
    if (this.addingProduct || this.removingProduct) {
      return;
    }
    this.addingProduct = true;
    try {
      await this.shoppingCartsService.addItem({
        quantity,
        resourceId: product.id,
        resourceType: 'product',
      });
      this.updateShoppingCart(product.id, quantity, 'add');
      this.toastService.showSuccess({ title: 'Añadiste un producto al carrito', description: `Se agregó "${product.name}" a tu carrito de compras.` }, 3000);
      this.analyticsService.logAddToShoppingCartEvent({
        currency: product.currency,
        items: [mapSearchEngineProductToCheckoutItem(product)],
        value: product.price,
      });
    } catch (error) {
      if ((error instanceof CustomError || error instanceof TRPCClientError) && error.data.cause === 'max-shopping-cart-items-reached') {
        this.toastService.showWarning({ title: 'Alcanzaste el límite', description: 'Has alcanzado el límite de productos, puedes eliminar algunos para agregar nuevos.' });
        return;
      }
      this.errorReportingService.log('ShoppingCartPage.addProduct()', 'add-product', error);
    } finally {
      this.addingProduct = false;
    }
  }

  async goToCheckout(): Promise<void> {
    const localUser = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
    if (!localUser) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/enrollment/sign-in'], { queryParams: { redirectTo: '/ecommerce/shopping-cart' } });
      return;
    }
    if (!this.address && localUser?.enrollmentStatus === 'done') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.openManageAddresses();
      return;
    }
    if (localUser?.enrollmentStatus === 'pending') {
      this.toastService.showWarning({
        title: 'Finaliza tu registro',
        description: 'Para acceder a todos los beneficios exclusivos que Mundo Hogar tiene para ti, necesitas finalizar tu registro.' ,
        action: () => this.router.navigate(['/enrollment/sign-in'], { queryParams: { redirectTo: '/ecommerce/shopping-cart' } }),
        actionLabel: 'Finalizar registro',
      }, 3000);
    }
    if (this.shoppingCartDetail) {
      this.analyticsService.logAddShippingAddressEvent({
        currency: this.shoppingCartDetail.currency,
        items: mapShoppingCartItemProductsToCheckoutItems(this.shoppingCartDetail.items),
        value: this.shoppingCartDetail.subtotal,
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/payment-gateway/checkout'], {
      queryParams: {
        resourceIds: this.selectedItemIds,
        resourceType: 'product',
        source: 'ecommerce',
      }
    });
  }

  initialize(): void {
    this.loadingShoppingCart = true;
    this.errorLoadingShoppingCart = false;
    const shoppingCartSubscription = this.shoppingCartsService.get().pipe(takeUntil(this.viewDestroyed)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async (shoppingCartDetail) => {
        if (shoppingCartDetail && !this.shoppingCartDetail) {
          this.analyticsService.logViewShoppingCartEvent({
            currency: shoppingCartDetail.currency,
            items: mapShoppingCartItemProductsToCheckoutItems(shoppingCartDetail.items),
            value: shoppingCartDetail.subtotal,
          });
        }
        if (shoppingCartDetail && ((!this.shoppingCartDetail && shoppingCartDetail.shippingAddressId) || (this.shoppingCartDetail?.shippingAddressId !== shoppingCartDetail.shippingAddressId))) {
          this.analyticsService.logAddShippingAddressEvent({
            currency: shoppingCartDetail.currency,
            items: mapShoppingCartItemProductsToCheckoutItems(shoppingCartDetail.items),
            value: shoppingCartDetail.subtotal,
          });
        }
        this.shoppingCartDetail = shoppingCartDetail;
        this.selectedItemIds = [];
        if (this.shoppingCartDetail) {
          if (this.shoppingCartDetail.shippingAddressId) {
            this.address = await firstValueFrom(this.addressesService.get(this.shoppingCartDetail.shippingAddressId));
          }
          // Later on we should be able to select some items to buy instead of all of them
          this.selectedItemIds = this.shoppingCartDetail.items
            .filter((item) => item.resourceType === 'product')
            .map((item) => item.resourceId);
          this.extractCategories(this.shoppingCartDetail.items.filter((item) => item.resourceType === 'product'));
        }
        this.loadingShoppingCart = false;
      },
      error: (error) => {
        this.errorReportingService.log('ShoppingCartPage.getShoppingCart()', 'get-shopping-cart', error);
        this.errorLoadingShoppingCart = true;
        this.shoppingCartDetail = null;
        this.loadingShoppingCart = false;
        shoppingCartSubscription.unsubscribe();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.setIsMobile();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.platformService.isServer() || !this.isMobile || !this.shoppingCartDetail?.items.length) {
      return;
    }
    const scrollY = window.scrollY;
    const payButton = this.elementRef.nativeElement.querySelector('.floating-pay-order');
    const staticButton = this.elementRef.nativeElement.querySelector('.pay-order-button-container');
    const staticButtonRect = staticButton.getBoundingClientRect();
    const isStaticButtonVisible = staticButtonRect.top <= window.innerHeight;
    if (scrollY >= 0 && !isStaticButtonVisible) {
      this.renderer.setStyle(payButton, 'transform', 'translateY(0%)');
    } else {
      this.renderer.setStyle(payButton, 'transform', 'translateY(100%)');
    }
  }

  async openManageAddresses() {
    const localUser = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
    if (localUser?.enrollmentStatus === 'done') {
      this.manageAddressesSidebar.open();
      return;
    } else if (localUser?.enrollmentStatus === 'pending') {
      this.toastService.showWarning({
        title: 'Finaliza tu registro',
        description: 'Para acceder a todos los beneficios exclusivos que Mundo Hogar tiene para ti, necesitas finalizar tu registro.' ,
        action: () => this.router.navigate(['enrollment/sign-in'], { queryParams: { redirectTo: '/ecommerce/shopping-cart' } }),
        actionLabel: 'Finalizar registro',
      }, 3000);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/enrollment/sign-in'], { queryParams: { redirectTo: '/ecommerce/shopping-cart' } });
  }

  async removeProduct(product: SearchEngineProduct, quantity: number = 1): Promise<void> {
    if (this.addingProduct || this.removingProduct) {
      return;
    }
    this.removingProduct = true;
    try {
      await this.shoppingCartsService.removeItem({
        quantity,
        resourceId: product.id,
        resourceType: 'product',
      });
      this.toastService.showSuccess({ title: 'Quitaste un producto del carrito', description: `Se quitó "${product.name}" de tu carrito de compras.` }, 3000);
      this.updateShoppingCart(product.id, quantity, 'remove');
      if (this.shoppingCartDetail) {
        this.analyticsService.logRemoveFromShoppingCartEvent({
          currency: product.currency,
          items: [{
            ...mapSearchEngineProductToCheckoutItem(product),
            quantity,
          }],
          value: product.price,
        });
      }
    } catch (error) {
      this.errorReportingService.log('ShoppingCartPage.removeProduct()', 'remove-product', error);
    } finally {
      this.removingProduct = false;
    }
  }

  private extractCategories(shoppingCartItems: ShoppingCartItemProduct[]): void {
    const categories: Set<string> = new Set();
    shoppingCartItems.forEach(({ resource: product }) => {
      if (product) {
        product.categories.forEach((category) => categories.add(category));
      }
    });
    this.categories = Array.from(categories);
  }

  private setIsMobile(): void {
    if (this.platformService.isServer()) {
      return;
    }
    this.isMobile = window.innerWidth < 900;
  }

  private updateShoppingCart(productId: string, quantity: number, action: 'add' | 'remove'): void {
    if (!this.shoppingCartDetail) {
      return;
    }
    const items = this.shoppingCartDetail.items.map((item) => ({
      ...item,
      quantity: item.quantity + (item.resourceId === productId ? (action === 'add' ? 1 : -1 ) * quantity : 0),
    })).filter((item) => item.quantity > 0);
    const subtotal = items.reduce((total, item) => total + item.quantity * (item.resource?.pricing.debit.price ?? 0), 0);
    const shippingCost = items.reduce((total, item) => total + item.quantity * (item.resource?.pricing.debit.shippingCost ?? 0), 0);
    this.shoppingCartDetail = {
      ...this.shoppingCartDetail,
      items,
      totalItems: items.reduce((total, item) => total + item.quantity, 0),
      subtotal,
      discounts: items.reduce((total, item) => total + item.quantity * ((item.resource?.originalPrice ?? 0) - (item.resource?.pricing.debit.price ?? 0)), 0),
      total: subtotal + (this.address ? shippingCost : 0),
      shippingCost,
    };
  }
}
