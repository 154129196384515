@if (product) {
  <div class="product-card">
    <div class="info" [routerLink]="['/ecommerce/products', product.slug || product.id]" [id]="'btn-card-'+ innerId">
      <div class="image-container" [ngStyle]="{'background-image': 'url(' + product.thumbnails[0] + ')'}">
        @if (product.tags.includes('highlighted')) {
          <span class="offer-type">{{ 'Highlighted' | transloco }}</span>
        } @else {
          <span class="offer-type">{{ offerTypeTranslationKey | transloco }}</span>
        }
      </div>
      <h4 class="brand">{{ product.brand }}</h4>
      <h4 class="description">{{ product.name }}</h4>
      @if (product.discountPercentage >= 0) {
        <span class="discount-percent">-{{ product.discountPercentage | translocoPercent }}</span>
      }
      <h6 class="current-price" [ngClass]="{'highlighted': offerType === 'super-sale'}">{{ product.price | currencyFormatter: product.currency }} <span class="currency-type">{{ product.currency }}</span></h6>
      @if (product.discountPercentage >= 0) {
        <h6 class="original-price">{{ product.originalPrice | currencyFormatter: product.currency }} {{ product.currency }}</h6>
      }
      <p class="installments-text">Paga hasta en 36 mensualidades de {{ product.pricing['credit-36'].monthlyPayment | currencyFormatter: product.currency }} {{ product.currency }}</p>
      <!-- TODO: Uncomment when the valuation is available
        <div class="rating-container">
          <p-rating [ngModel]="product.rating" [cancel]="false" [readonly]="true"></p-rating>
          <span class="comments">({{ product.comments | translocoDecimal }})</span>
        </div>
      -->
    </div>
    <div class="actions-container">
      <button class="shopping-cart" aria-hidden="false" (click)="addToShoppingCart()" [id]="'btn-icon-cart'+ innerId">
        @if (addingProductToShoppingCart) {
          <i class="pi pi-spin pi-spinner"></i>
        } @else {
          <i class="pi icon icon-supermarket-cart"></i>
        }
      </button>
      <button class="favorites" aria-hidden="false" (click)="toggleFavorites()" [id]="'btn-icon-favorite-' + innerId">
        @if (isFavorite && !togglingFavorite) {
          <i class="icon-heart-fill"></i>
        } @else if (!isFavorite && !togglingFavorite) {
          <i class="icon-heart"></i>
        } @else {
          <i class="pi pi-spin pi-spinner"></i>
        }
      </button>
    </div>
  </div>
}