import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WarehouseOrder } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { WarehouseOrdersService } from '../../../../services/warehouse-orders/warehouse-orders.service';
import { getWarehouseOrderFinalStatus, goToReserveWarehouse, WarehouseOrderFinalStatus } from '../../../../utils/warehouse.utils';

@Component({
  selector: 'app-my-warehouses',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    RetryableSectionComponent,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './my-warehouses.page.html',
  styleUrl: './my-warehouses.page.scss',
})
export class MyWarehousesPage implements OnInit, OnDestroy {
  errorLoading = false;
  loading = false;
  warehouseOrders: WarehouseOrder[] = [];
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private toastService: ToastService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {}

  getOrderFinalStatus(order: WarehouseOrder): WarehouseOrderFinalStatus {
    return getWarehouseOrderFinalStatus(order);
  }

  goBack(): void {
    this.location.back();
  }

  goToReserveWarehouse(order: WarehouseOrder): void {
    goToReserveWarehouse(order, this.errorReportingService, this.toastService);
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.warehouseOrdersService.getAll().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (orders) => {
        this.warehouseOrders = orders;
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyWarehousesPage.initialize()', 'get-all-warehouse-orders', error);
        this.errorLoading = true;
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }
}
