<div class="reminder-container {{ styleClass }}">
  <i class="icon-information-circle"></i>
  <div class="content">
    <h3 class="title">{{ title }}</h3>
    @if (description) {
      <p class="description">{{ description }}</p>
    } @else {
      <ng-container>
        <ng-content></ng-content>
      </ng-container>
    }
    @if (action && actionLabel) {
      <button class="action" (click)="triggerAction()">{{ actionLabel }}</button>
    }
  </div>
</div>
