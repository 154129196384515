import { Injectable } from '@angular/core';
import { WarehouseOrder } from '@homein-hogar-server';
import * as faker from 'faker';
import { map, Observable, of, switchMap } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { WarehouseOrdersService } from './warehouse-orders.service';

@Injectable()
export class WarehouseOrdersServiceMock implements WarehouseOrdersService {
  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  createId(): string {
    return faker.random.alphaNumeric(20);
  }

  get(id: string): Observable<WarehouseOrder | null> {
    return this.mockService.getWarehouseOrder(id);
  }

  getAll(): Observable<WarehouseOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap(session => {
      if (session) {
        return this.mockService.getWarehouseOrders().pipe(map((orders) => {
          return orders.filter((order) => order.userId === session.userId && order.status !== 'initializing');
        }));
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<WarehouseOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.mockService.getLastWarehouseOrderInProgress(session.userId);
      }
      return of(null);
    }));
  }
}
