<p-sidebar class="search-sidebar" [(visible)]="isVisible" [fullScreen]="true" [showCloseIcon]="false" position="right" [blockScroll]="true" (onHide)="close()">
  <ng-template pTemplate="content">
    <div class="search-input-sidebar-container">
      <app-search-input [query]="query"  [showBackButton]="true" (backButtonClick)="close()" (searchClick)="searchQuery($event)" (changeQuery)="changeInputQuery($event)" #searchInput></app-search-input>
    </div>
    @if (searchHistory?.length) {
      <div class="search-history-container">
        <div class="search-history-headers">
          <span class="title">Historial de búsqueda</span>
          <span class="delete-history" (click)="deleteSearchHistory()">Borrar historial</span>
        </div>
        <div class="search-history-entries">
          @for (entry of searchHistory; track $index) {
            <div class="entry" (click)="searchQuery(entry)">
              <i class="icon-search"></i>
              <span class="entry-name">{{ entry }}</span>
            </div>
          }
        </div>
      </div>
    } @else {
      <div class="empty-search-history-container">
        <img class="image" src="/assets/illustrations/empty-search.svg" alt="Ilustración de historial de búsqueda vacío." />
        <p class="message">Aún no tienes búsquedas recientes</p>
        <div class="disclaimer">
          <p>Te ayudamos a encontrar eso que tanto deseas para tu hogar.</p>
          <p>Comienza buscando algún producto.</p>
        </div>
      </div>
    }
  </ng-template>
</p-sidebar>