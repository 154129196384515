import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SeoService } from '../../../services/seo/seo.service';

@Component({
  selector: 'app-simulate-again',
  standalone: true,
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    RouterModule,
  ],
  templateUrl: './simulate-again.page.html',
  styleUrl: './simulate-again.page.scss',
})
export class SimulateAgainPage implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams['token'];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/project-simulator/step-1'], { queryParams: { token } });
  }
}
