<section class="project-simulator-container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">Atrás</span>
      <span class="mobile-text">Simula tu proyecto</span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">Simula el costo del proyecto que tienes en mente</h5>
      <app-circular-progress [currentStep]="2" [numberSteps]="6" stepTitle="Espacio a remodelar" [stepSubtitle]="subtitleByType | transloco" />
    </div>
    <div class="bottom-container animate__fadeIn">
      <div class="project-simulation-types-container">
        <h2 class="title">¿Cuál es el espacio que quieres remodelar?</h2>
        <div class="project-simulation-type-container" id="btn-d9N-dfT9" [class.selected]="form.controls.type.value === 'bathroom'" (click)="setFormControlValue(form.controls.type, 'bathroom')">
          <span class="text">Baño</span>
        </div>
        <div class="project-simulation-type-container" id="btn-v0KxpBnV" [class.selected]="form.controls.type.value === 'kitchen'" (click)="setFormControlValue(form.controls.type, 'kitchen')">
          <span class="text">Cocina</span>
        </div>
        <div class="project-simulation-type-container" id="btn-9mS_RZ19" [class.selected]="form.controls.type.value === 'integral-remodeling'" (click)="setFormControlValue(form.controls.type, 'integral-remodeling')">
          <span class="text">Remodelación integral</span>
        </div>
      </div>
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="Ilustración de la opción seleccionada" />
        <div class="continue-button-container">
          <app-button innerId="btn-QR0v_saY" label="Continuar" styleClass="primary expand" type="submit" [disabled]="form.invalid" />
        </div>
      </div>
    </div>
  </form>
</section>
