<section class="my-remodelings-container">
  <div class="title-container">
    <i class="icon icon-arrow-left" routerLink="/private-site"></i>
    <h2 class="title">Mis remodelaciones
      @if (remodelingOrders.length) {
        <span class="total-items">({{ remodelingOrders.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!remodelingOrders.length) {
      <div class="empty-remodelings animate__fadeIn">
        <img class="image" src="/assets/illustrations/remodeling-empty.svg" alt="Ilustración de no tienes remodelaciones" />
        <h5 class="title">Aún no tienes remodelaciones programadas/cotizadas</h5>
        <p class="subtitle">Cotiza tu próxima remodelación</p>
        <app-button label="Ir a remodelación" styleClass="secondary" href="/remodeling" [queryParams]="{ action: 'quote' }" />
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
        @for (remodelingOrder of remodelingOrders; track $index) {
          <div class="order-container">
            <div class="remodelings-container">
              <a class="remodeling-container" routerLink="/private-site/my-remodelings/{{ remodelingOrder.id }}">
                <div class="column first">
                  <div class="right-container">
                    @if (remodelingOrder.status === 'lead-created') {
                      <span class="remodeling-status active">Solicitud recibida</span>
                      <span class="description"></span> <!-- TODO: Add description -->
                      <span class="description-status">Hemos recibido tus datos. Pronto te llamaremos para recopilar más información de tu proyecto.</span>
                    } @else if (remodelingOrder.status === 'pending-budget') {
                      <span class="remodeling-status active">Preparando tu presupuesto</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] ?? '' }}</span>
                      <span class="description-status">Estamos preparando un buen presupuesto para tí.</span>
                    } @else if (remodelingOrder.status === 'pending') {
                      @if (!remodelingOrder.paid || remodelingOrder.pendingChargeIntentId) {
                        <span class="remodeling-status active">Todo listo, paga tu presupuesto</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">¡Tu presupuesto fue enviado! Págalo para confirmar y agendar el inicio de la obra.</span>
                      } @else if (!remodelingOrder.contractSigned) {
                        <span class="remodeling-status active">Ahora solo falta tu firma</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">¡Tu presupuesto se encuentra pagado! Fírmalo para confirmar y agendar el inicio de la obra.</span>
                      }
                    } @else if (remodelingOrder.status === 'waiting') {
                      <span class="remodeling-status active">¡Todo listo!</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                      <span class="description-status">Tu proyecto está agendado para que comience el {{ remodelingOrder.startAt | date: 'dd/MM/yyyy' }}</span>
                    } @else if (remodelingOrder.status === 'in-progress') {
                      @if (!remodelingOrder.pendingChargeIntentId) {
                        <span class="remodeling-status active">Proyecto en obra</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">Tu proyecto ha comenzando el {{ remodelingOrder.startAt | date: 'dd/MM/yyyy' }}</span>
                      } @else {
                        <span class="remodeling-status pending">Pago pendiente</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">Tienes un pago pendiente</span>
                      }
                    } @else if (remodelingOrder.status === 'done') {
                      <span class="remodeling-status">Proyecto finalizado</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                      <span class="description-status">Tu proyecto ha finalizado el {{ remodelingOrder.endAt | date: 'dd/MM/yyyy' }}</span>
                      <span class="description-status">Ayúdanos a mejorar la experiencia Mundo Hogar.</span>
                    } @else if (remodelingOrder.status === 'cancelled') {
                      <span class="remodeling-status pending">Proyecto cancelado</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                      <span class="description-status">Lamentamos cancelar tu proyecto.</span>
                      <span class="description-status">Por favor, coméntanos como podemos mejorar el servicio.</span>
                    }
                  </div>
                </div>
                <div class="column second">
                  @if (remodelingOrder.status === 'lead-created') {
                    <span class="description-status">Hemos recibido tus datos. Pronto te llamaremos para recopilar más información de tu proyecto.</span>
                  } @else if (remodelingOrder.status === 'pending-budget') {
                    <span class="description-status">Estamos preparando un buen presupuesto para tí.</span>
                  } @else if (remodelingOrder.status === 'pending') {
                    @if (!remodelingOrder.paid || remodelingOrder.pendingChargeIntentId) {
                      <span class="description-status">¡Tu presupuesto fue enviado! Págalo para confirmar y agendar el inicio de la obra.</span>
                    } @else if (!remodelingOrder.contractSigned) {
                      <span class="description-status">¡Tu presupuesto se encuentra pagado! Fírmalo para confirmar y agendar el inicio de la obra.</span>
                    }
                  } @else if (remodelingOrder.status === 'waiting') {
                    <span class="description-status">Tu proyecto está agendado para que comience el {{ remodelingOrder.startAt | date: 'dd/MM/yyyy' }}</span>
                  } @else if (remodelingOrder.status === 'in-progress') {
                    @if (!remodelingOrder.pendingChargeIntentId) {
                      <span class="description-status">Tu proyecto ha comenzando el {{ remodelingOrder.startAt | date: 'dd/MM/yyyy' }}</span>
                    } @else {
                      <span class="description-status">Tienes un pago pendiente</span>
                    }
                  } @else if (remodelingOrder.status === 'done') {
                    <span class="description-status">Tu proyecto ha finalizado el {{ remodelingOrder.endAt | date: 'dd/MM/yyyy' }}</span>
                    <span class="description-status">Ayúdanos a mejorar la experiencia Mundo Hogar.</span>
                  } @else if (remodelingOrder.status === 'cancelled') {
                    <span class="description-status">Lamentamos cancelar tu proyecto.</span>
                    <span class="description-status">Por favor, coméntanos como podemos mejorar el servicio.</span>
                  }
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  @if (['lead-created', 'pending', 'waiting', 'in-progress'].includes(remodelingOrder.status)) {
                    <app-button label="Ver detalle" styleClass="secondary" href="/private-site/my-remodelings/{{ remodelingOrder.id }}" />
                  } @else if (remodelingOrder.status === 'cancelled' || remodelingOrder.status === 'done') {
                    <!-- TODO: Enable when implementing page to evaluate experience
                    <app-button label="Evaluar experiencia" styleClass="tertiary" href="/" />
                    -->
                    <app-button label="Ver detalle" styleClass="secondary" href="/private-site/my-remodelings/{{ remodelingOrder.id }}" />
                  }
                </div>
                <i class="icon icon-arrow-right"></i>
              </a>
            </div>
          </div>
        }
      </div>
    }
  </app-retryable-section>
</section>