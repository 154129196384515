import { Inject, Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { CreatePaymentIntentData, PaymentIntent, PaymentIntentsRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { PaymentIntentsService } from './payment-intents.service';

const COLLECTION_PATH = 'paymentIntents';

@Injectable()
export class PaymentIntentsServiceApi implements PaymentIntentsService {
  private client: CreateTRPCProxyClient<PaymentIntentsRouter>;

  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<PaymentIntentsRouter>(getTRPCClientOptions(`${environment.apiUrl}/${COLLECTION_PATH}`, () => this.sessionsService.getAccessToken()));
  }

  createExternal(data: CreatePaymentIntentData): Promise<{ code: string; id: string; message: string; redirectUrl: string; }> {
    return this.client.createExternal.mutate(data);
  }

  createInternal(data: CreatePaymentIntentData): Promise<{ code: string; id: string; message: string; }> {
    return this.client.createInternal.mutate(data);
  }

  get(id: string): Observable<PaymentIntent | null> {
    return this.firestore.doc(`${COLLECTION_PATH}/${id}`);
  }

  getAllPaid(): Observable<PaymentIntent[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<PaymentIntent>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', '==', 'paid'),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }
}