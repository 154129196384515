import { EventEmitter, Injectable } from '@angular/core';
import { IdTokenResult } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { Account } from '@homein-accounts-server';
import { SendEmailVerificationData, SendPasswordRecoveryData, UserEnrollment } from '@homein-hogar-server';
import * as faker from 'faker';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { constants } from '../../constants';
import { triggerRandomError } from '../../utils/mock.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { Session, SessionsService } from './sessions.service';

@Injectable()
export class SessionsServiceMock implements SessionsService {
  private onSignInEventEmitter = new EventEmitter<void>();
  private onSignOutEventEmitter = new EventEmitter<void>();
  private session: Observable<Session | null>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private mockService: MockService,
  ) {
    this.session = new Observable((subscriber) => {
      combineLatest([
        this.dataStorageService.get<string>(DataKey.MockIdToken),
        this.dataStorageService.get<IdTokenResult>(DataKey.MockIdTokenResult),
      ]).subscribe(([idToken, idTokenResult]) => {
        if (idToken && idTokenResult) {
          subscriber.next({
            claims: idTokenResult.claims,
            token: idToken,
            userId: idTokenResult.claims['app_user_id'] as string
          });
          this.onSignInEventEmitter.emit();
        } else {
          subscriber.next(null);
          this.onSignOutEventEmitter.emit();
        }
      });
    });
  }

  getAccessToken(): Promise<string | null> {
    return firstValueFrom(this.getSession()).then((session) => session?.token || null);
  }

  getSession(): Observable<Session | null> {
    return this.session;
  }

  onSignIn(): EventEmitter<void> {
    return this.onSignInEventEmitter;
  }

  onSignOut(): EventEmitter<void> {
    return this.onSignOutEventEmitter;
  }

  async sendEmailVerification(data: SendEmailVerificationData): Promise<void> {
    let step = '';
    try {
      step = 'send-verification-email';
      const session = await firstValueFrom(this.getSession());
      if (!session) {
        throw new Error('Session not found');
      }
      const user = await firstValueFrom(this.mockService.getUser(session.userId));
      if (!user) {
        throw new Error('User not found');
      }
      await this.mockService.updateUser(user.id, {
        lastEmailVerificationSentAt: new Date(),
      });
      setTimeout(() => {
        let token = null;
        if (data.redirectTo) {
          const { origin, redirectTo } = data;
          token = JSON.stringify({
            ...(origin && { origin }),
            redirectTo
          });
        } else {
          token = '123456';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.verifyEmail(token);
      }, 5000);
    } catch (error) {
      return Promise.reject({ code: 'unknown', message: 'Unknown error', error, step });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sendPasswordRecovery(data: SendPasswordRecoveryData): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 1000);
    });
  }

  async signIn(options: { provider: 'apple'; } | { provider: 'google'; } | { data: { email: string; password: string; }; provider: 'email'; }): Promise<void> {
    try {
      if (options.provider !== 'email') {
        throw new Error('Provider not supported');
      }
      const users = await firstValueFrom(this.mockService.getUsers());
      const user = users.find((mock) => mock.email === options.data.email);
      if (!user) {
        return Promise.reject({ code: 'invalid-credential', message: 'Invalid credential.' });
      }
      await this.mockService.updateUser(user.id, {
        lastSignInAt: new Date(),
      });
      this.createSession(user.id);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  signOut(): Promise<void> {
    this.dataStorageService.clearAll();
    this.dataStorageService.clear(DataKey.MockIdToken);
    this.dataStorageService.clear(DataKey.MockIdTokenResult);
    this.dataStorageService.clear(DataKey.MockUserId);
    this.onSignOutEventEmitter.emit();
    return Promise.resolve();
  }

  async signUp(options: { data: { email: string; password: string; }; provider: 'email'; }): Promise<void> {
    let step = '';
    try {
      if (options.provider !== 'email') {
        throw new Error('Provider not supported');
      }
      const users = await firstValueFrom(this.mockService.getUsers());
      const existingUser = users.find((mock) => mock.email === options.data.email);
      if (existingUser) {
        return Promise.reject({ code: 'auth/email-already-exists', message: 'Email already exists.' });
      }
      const newUser: Omit<UserEnrollment, 'createdAt' | 'id' | 'updatedAt'> = {
        bankValidated: false,
        birthDate: null,
        email: options.data.email,
        emailValidated: false,
        enrollmentStatus: 'pending',
        fatherLastName: null,
        lastEmailVerificationSentAt: null,
        lastSignInAt: null,
        motherLastName: null,
        name: null,
        phoneNumber: null,
        profileDataCompleted: false,
        provider: options.provider,
        status: 'active',
        statusDetail: null,
        uid: faker.random.alphaNumeric(20),
      };
      step = 'create-user';
      const userId = await this.mockService.createUser(newUser);
      step = 'create-accounts';
      const defaultValues: Omit<Account, 'createdAt' | 'id' | 'type' | 'updatedAt'> = {
        additionalData: null,
        balance: 0,
        blockedBalance: 0,
        currency: constants.defaultCurrency,
        lastMovementAt: null,
        maxBalance: null,
        ownerId: userId,
        ownerType: 'user',
        status: 'active',
        transactionId: null,
        usageRules: null,
      };
      const accounts = await Promise.allSettled([
        this.mockService.createAccount({
          ...defaultValues,
          type: 'debit',
        }),
        this.mockService.createAccount({
          ...defaultValues,
          type: 'mortgage',
        })
      ]);
      if (accounts[0].status === 'rejected' || accounts[1].status === 'rejected') {
        throw new Error('Error creating accounts');
      }
      step = 'create-session';
      this.createSession(userId);
      step = 'send-email-verification';
      const origin = this.activatedRoute.snapshot.queryParamMap.get('origin');
      const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
      await this.sendEmailVerification({
        ...(origin && { origin }),
        ...(redirectTo && { redirectTo }),
      });
    } catch (error) {
      return Promise.reject({ code: 'unknown', message: 'Unknown error', error, step });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePassword(password: string, resetCode?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 1000);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async verifyEmail(token: string): Promise<{ origin?: string; redirectTo: string } | null> {
    let step = '';
    try {
      step = 'verify-email';
      const session = await firstValueFrom(this.getSession());
      if (!session) {
        throw new Error('Session not found');
      }
      const user = await firstValueFrom(this.mockService.getUser(session.userId));
      if (!user) {
        throw new Error('User not found');
      }
      await this.mockService.updateUser(user.id, {
        emailValidated: true,
        enrollmentStatus: user.bankValidated && user.profileDataCompleted ? 'done' : 'pending',
      });
      const data = JSON.parse(token);
      return typeof data === 'string' ? null : data;
    } catch (error) {
      return Promise.reject({ code: 'unknown', message: 'Unknown error', error, step });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  verifyPasswordResetCode(code: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 1000);
    });
  }

  private createSession(userId: string): void {
    const idToken = faker.random.alphaNumeric(128);
    const authTime = new Date();
    const expirationDate = new Date(authTime.getTime());
    expirationDate.setDate(expirationDate.getDate() + 7);
    const idTokenResult: IdTokenResult = {
      authTime: authTime.toISOString(),
      expirationTime: expirationDate.toISOString(),
      issuedAtTime: new Date().toISOString(),
      signInProvider: 'password',
      signInSecondFactor: null,
      token: idToken,
      claims: {
        app_user_id: userId,
      },
    };
    this.dataStorageService.set(DataKey.MockIdToken, idToken);
    this.dataStorageService.set(DataKey.MockIdTokenResult, idTokenResult);
    this.dataStorageService.set(DataKey.MockUserId, userId);
    this.onSignInEventEmitter.emit();
  }
}
