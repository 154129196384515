import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-home-assistances/my-home-assistances.page').then(m => m.MyHomeAssistancesPage),
    data: {
      description: 'Portal exclusivo para clientes de Banco Santander que reúne en un mismo lugar beneficios únicos en servicios para el hogar.',
      title: 'Mis planes de asistencia',
    },
  },
  {
    path: ':orderId',
    loadComponent: () => import('./home-assistance-status/home-assistance-status.page').then(m => m.HomeAssistanceStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('../../../components/home-assistance-purchase-details/home-assistance-purchase-details.component').then(m => m.HomeAssistancePurchaseDetailsComponent)
  }
] satisfies Routes;
