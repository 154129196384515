<section class="main">
  <div class="main-container">
    <div class="right-side">
      <div class="title-container">
        <p class="on-title-text">Beneficios exclusivos para clientes <strong>Santander</strong></p>
        <h1 class="title">Simula el costo de la remodelación que sueñas</h1>
      </div>
      <div class="button-container">
        <app-button label="Simular" innerId="btn-5Nk5KOsZ" styleClass="primary" icon="remodeling" href="/project-simulator" />
      </div>
    </div>
    <div class="left-side">
      <img class="main-image" src="assets/images/remodeling-main.svg" alt="Remodelación imagen principal" />
    </div>
  </div>
</section>
<section class="terms-banner">
  <app-terms-banner page="remodeling" />
</section>
<section class="remodeling-benefits">
  <div class="container">
    <div class="left-container">
      <img class="image" src="assets/images/assistance-benefits.svg" alt="Imagen de los beneficios de la remodelación" />
    </div>
    <div class="right-container">
      <div class="title-container">
        <h2 class="title">¿Por qué remodelar en Mundo Hogar?</h2>
      </div>
      <div class="benefits-container">
        <ol class="benefits">
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>Cotiza y planifica tu remodelación. ¡Sin sorpresas!</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>Escoge el financiamiento a tu medida</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>Haz tu vida más fácil dejando tu hogar en nuestras manos</li>
        </ol>
        <app-button label="Cotiza gratis" innerId="btn-BwbAQ3ex" styleClass="secondary" (action)="openRemodelingQuoteSidebar()" />
      </div>
    </div>
  </div>
</section>
<section class="opportunities">
  <div class="opportunities-container">
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">Solicita tu tarjeta y paga todo hasta en 36 mensualidades</h4>
        <app-button label="Solicitar" innerId="btn-EjxlKXXe" styleClass="secondary" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener" />
      </div>
      <div class="right-card-container">
        <!-- TODO: change alt of images and also images. -->
        <img class="img" src="assets/images/banner-opportunity-01.png" alt="Imagen de oportunidad" loading="lazy" />
      </div>
    </div>
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">Simula la remodelación que sueñas</h4>
        <app-button label="Simular" innerId="btn-GkfABNY8" styleClass="secondary" routerLink="/project-simulator/step-1" />
      </div>
      <div class="right-card-container">
        <!-- TODO: change alt of images and also images. -->
        <img class="img" src="assets/images/second-opportunity.jpg" alt="Imagen de oportunidad" loading="lazy" />
      </div>
    </div>
  </div>
</section>
<section class="why-trust-section">
  <div class="container">
    <div class="left-container">
      <div class="title-container">
        <span class="title">¿Por qué confiamos en<img class="remodeling-logo" alt="Logo Aquí tu remodelación" src="assets/images/atr-logo-cafe.png" />?</span>
        <hr class="underline">
      </div>
    </div>
    <div class="right-container">
      <div class="trust-content">
        <i class="icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">Presupuesto sin sorpresas</h4>
          <p class="description">Tu remodelación sin preocuparte por imprevistos o cambios en los costos acordados.</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">Cumplimiento de plazos</h4>
          <p class="description">Para que disfrutes tu hogar tal como planificaste, se establece en el contrato las fechas del término de obras.</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">Proveedores seleccionados</h4>
          <p class="description">Los mejores expertos y proveedores seleccionados para tí.</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">Hasta 2 años de garantía</h4>
          <p class="description">Respaldo técnico frente a incidentes posteriores a la remodelación.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="quote-free-section">
  <div class="container">
    <span class="text">Si quieres cotizar gratis la remodelación para la casa de tus sueños, haz <a class="link" id="btn-link-iRPvlK9p" (click)="openRemodelingQuoteSidebar()">clic aquí</a></span>
  </div>
</section>
<section class="remodeling-cost-section">
  <div class="container">
    <div class="left-container">
      <div class="text-container">
        <h5 class="title">¿Cuánto cuesta la remodelación que tienes en mente?</h5>
        <p class="description">Simula online en 3 minutos:</p>
        <ol class="items">
          <li class="item">Ingresa los datos de tu vivienda</li>
          <li class="item">Selecciona el tipo de remodelación</li>
          <li class="item">{{ 'Enter the m2 and that\'s it!' | transloco }}</li>
        </ol>
      </div>
      <div class="button-container">
        <app-button innerId="btn-5vxjqlTc" label="Simular proyecto" styleClass="primary expand" href="/project-simulator" />
      </div>
    </div>
  </div>
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">¿Por qué Mundo Hogar?</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">Todo en un mismo lugar</h4>
          <span class="description">Encuentra todo lo que necesitas para tu hogar, en un mismo portal 100% online.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">Beneficios exclusivos</h4>
          <span class="description">Alternativas de financiamiento exclusivas para clientes Santander y mucho más.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">Los mejores proveedores</h4>
          <span class="description">Proveedores calificados, servicios garantizados y métodos de pagos seguros para ti.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">Simple y transparente</h4>
          <span class="description">Gestiona y haz el seguimiento online de tus proyectos en pocos clics.</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <p class="customer-benefit">Beneficios exclusivos para clientes Santander. Si aún no eres cliente, haz <a class="customer-link" id="btn-link-7X57kgfg" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">clic aquí.</a><p>
    </div>
  </div>
</article>
<article class="faq-article">
  <h2 class="title">Preguntas frecuentes</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="¿Cuál es la cobertura de los servicios de remodelación?" iconPos="end">
        <div class="text-container">
          <p class="text">Cobertura en más de 20 estados. Con base en tu código postal puedes verificar si se cuenta con servicio en tu zona.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Puedo saber el costo aproximado de mí remodelación?" iconPos="end">
        <div class="text-container">
          <p class="text">Si quieres tener una idea aproximada del valor de tu remodelación, tenemos un simulador online que te dará una aproximación muy ajustada a la realidad. Si pides un presupuesto gratuito y sin compromiso uno de nuestros franquiciatarios de la marca Aquí tu Remodelación, visitará tu vivienda para asesorarte y hacer un presupuesto a medida.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Quién me va a contactar?" iconPos="end">
        <div class="text-container">
          <p class="text">Un franquiciatario de Aquí Tu Remodelación se pondrá en contacto contigo y te hará saber que es parte de ATR. Los franquiciatarios son profesionales de la remodelación homologados, que han pasado un proceso de validación estricto antes de incorporarse a la marca y con una amplia experiencia realizando proyectos de remodelación como el que tienes en mente.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué pasa si tengo alguna incidencia durante la remodelación?" iconPos="end">
        <div class="text-container">
          <p class="text">Esperamos que no suceda ninguna incidencia durante tu remodelación, pero en el caso de que exista una diferencia de opiniones entre cliente y franquiciatario, desde Aquí Tu Remodelación realizan una visita técnica para determinar desde un punto de vista objetivo qué se debe reparar/ajustar por parte del franquiciatario.<a class="link" routerLink="/contact">¿Quieres informarnos alguna incidencia durante tu remodelación?</a></p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Cuál es la diferencia entre “cotizar” y “simular”?" iconPos="end">
        <div class="text-container">
          <ul>
            <li>Cotizar implica solicitar un precio o valor específico para un producto o servicio, generalmente basado en detalles precisos y condiciones claras. Una cotización es un compromiso formal por parte del proveedor de ofrecer ese producto o servicio a un precio determinado.</li>
            <li>Simular, en cambio, se refiere a crear una estimación o cálculo aproximado del costo basado en ciertos supuestos o datos preliminares. Una simulación es menos formal y no implica un compromiso; simplemente sirve para tener una idea aproximada del costo bajo determinadas condiciones.</li>
          </ul>
          <p class="text">En resumen, cotizar es un compromiso con un precio fijo, mientras que simular es una aproximación para tener una idea del costo.</p>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">Visita nuestro <a class="link" id="btn-link-aryz1lwQ" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">Centro de ayuda</a> para más información</h5>
</article>
<app-remodeling-quote-sidebar #remodelingQuoteSidebar></app-remodeling-quote-sidebar>