import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ButtonComponent } from '../../../components/button/button.component';
import { RemodelingQuoteSidebarComponent } from '../../../components/remodeling-quote-sidebar/remodeling-quote-sidebar.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LocalUser } from '../../../services/users/users.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    RemodelingQuoteSidebarComponent,
    RouterLink,
    SharedModule,
    TermsBannerComponent,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomePage implements OnInit {
  @ViewChild('remodelingQuoteSidebar') remodelingQuoteSidebar: RemodelingQuoteSidebarComponent;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private metaTagsService: MetaTagsService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    const action = this.activatedRoute.snapshot.queryParamMap.get('action') || this.activatedRoute.snapshot.data['action'];
    if (action === 'quote') {
      setTimeout(() => {
        this.openRemodelingQuoteSidebar();
      }, 0);
    }
  }

  openRemodelingQuoteSidebar(): void {
    const user = this.dataStorageService.getLocal<LocalUser>(DataKey.LocalUser);
    if (!user) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['enrollment/sign-in'], { queryParams: { redirectTo: encodeURIComponent('/remodeling?action=quote') } });
      return;
    } else if (user.enrollmentStatus === 'pending') {
      this.toastService.showWarning({
        title: 'Finalize your registration',
        description: 'To access all the exclusive benefits that Mundo Hogar has for you, you need to complete your registration.' ,
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['enrollment/sign-in'], { queryParams: { redirectTo: encodeURIComponent('/remodeling?action=quote') } });
        },
        actionLabel: 'Finish registration',
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['enrollment/sign-in'], { queryParams: { redirectTo: encodeURIComponent('/remodeling?action=quote') } });
      return;
    }
    this.remodelingQuoteSidebar.open();
  }
}
