import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { DropdownModule } from 'primeng/dropdown';
import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom, Subject } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { SendResultSidebarComponent } from '../../components/send-result-sidebar/send-result-sidebar.component';
import { constants } from '../../constants';
import { emailPattern, namePattern, phoneNumberPattern } from '../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../services/forms/forms.service';
import { SeoService } from '../../services/seo/seo.service';
import { ToastService } from '../../services/toast/toast.service';

type Financing = 'credit-card' | 'mortgage-credit' | 'personal-credit'; // TODO: enable later on 'need-advice';

@Component({
  selector: 'app-partner-leads-form',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    SendResultSidebarComponent,
    TooltipModule,
  ],
  templateUrl: './partner-leads-form.page.html',
  styleUrl: './partner-leads-form.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PartnerLeadsFormPage implements OnInit, OnDestroy {
  @ViewChild('phoneNumberInput') phoneNumberInput: InputMask;
  @ViewChild('sendResultSidebar') sendResultSidebar: SendResultSidebarComponent;
  financingOptions: { label: string; subtitle: string; value: Financing; }[] = [
    { label: 'Tarjeta de crédito', subtitle: 'Hasta 36 MSI', value: 'credit-card' },
    { label: 'Crédito Hipotecario', subtitle: '(10, 15 y 20 años)', value: 'mortgage-credit' },
    { label: 'Crédito Personal', subtitle: '(6 a 72 meses)', value: 'personal-credit' },
    // TODO: enable later on
    // { label: 'Necesito asesoria', subtitle: '(No lo tengo claro)', value: 'need-advice' },
  ];
  form: FormGroup<{
    clientCode: FormControl<string | null>;
    email: FormControl<string | null>;
    fatherLastName: FormControl<string | null>,
    financing: FormControl<Financing | null>;
    franchiseCode: FormControl<string | null>;
    lastNumbersOpportunity: FormControl<string | null>;
    motherLastName: FormControl<string | null>,
    name: FormControl<string | null>,
    phoneNumber: FormControl<string | null>;
    postalCode: FormControl<string | null>;
  }>;
  isClient = true;
  requestSent = false;
  sendingForm = false;
  sendingRequest = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private seoService: SeoService,
    private toastService: ToastService,
  ) {
    this.form = new FormGroup({
      clientCode: new FormControl<string | null>(null, [Validators.required, Validators.pattern(/^[0-9]{1,8}$/), Validators.maxLength(8), Validators.minLength(8)]),
      email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(emailPattern)]),
      name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      financing: new FormControl<Financing | null>(null, [Validators.required]),
      fatherLastName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      franchiseCode: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(6), Validators.pattern(/^R[0-9]{5}$/)]),
      lastNumbersOpportunity: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(5), Validators.pattern(/^[0-9]{5}$/)]),
      motherLastName: new FormControl<string | null>(null, [Validators.maxLength(36), Validators.pattern(namePattern)]),
      postalCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(constants.postalCodeLength), Validators.maxLength(constants.postalCodeLength)]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(phoneNumberPattern)]),
    });
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.analyticsService.logLeadsForm({ step: 'start' });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  changePhoneNumber(): void {
    this.form.controls.phoneNumber.patchValue('52');
    setTimeout(() => this.phoneNumberInput.focus(), 150);
  }

  goToHome(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/']);
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.analyticsService.logLeadsForm({ step: 'confirm_contact' });
    this.sendResultSidebar.open();
  }

  async sendRequest(): Promise<void> {
    this.sendingRequest = true;
    let step = '';
    try {
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitLeadsForm'));
      const { clientCode, email, fatherLastName, financing, franchiseCode, lastNumbersOpportunity, motherLastName, name, phoneNumber, postalCode } = this.form.value;
      const services = ['remodeling' as const];
      step = 'submit-leads-form';
      await this.formsService.submitPartnerLeadsForm({
        ...(clientCode && { clientCode: clientCode }),
        email: email!,
        fatherLastName: fatherLastName!,
        financing: financing!,
        franchiseCode: franchiseCode!,
        lastNumbersOpportunity: lastNumbersOpportunity!,
        motherLastName: motherLastName ?? null,
        name: name!,
        phoneNumber: `+${phoneNumber!}`,
        postalCode: postalCode!,
        recaptchaToken,
        services,
      });
      this.toastService.showSuccess({ title: 'Solicitud enviada', description: 'Hemos enviado la confirmación por WhatsApp al número proporcionado.' });
      this.requestSent = true;
      this.analyticsService.logGenerateLeadEvent({ lead_source: 'leads-form' });
      this.analyticsService.logLeadsForm({ step: 'generate_lead', services, financing: financing!, is_client: this.isClient });
    } catch (error) {
      this.errorReportingService.log('LeadsFormPage.sendRequest()', step, error);
      this.toastService.showError({ title: 'Algo salió mal', description: 'No se ha podido enviar tu solicitud, vuelve a intentarlo.' });
      this.requestSent = false;
    } finally {
      this.sendingRequest = false;
    }
  }

  toggleIsClient(): void {
    if (this.isClient) {
      this.form.controls.clientCode.addValidators(Validators.required);
    } else {
      this.form.controls.clientCode.setValue(null);
      this.form.controls.clientCode.removeValidators(Validators.required);
    }
    this.form.controls.clientCode.updateValueAndValidity();
  }
}