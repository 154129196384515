<section class="main-why-mundo-hogar">
  <div class="main-container">
    <div class="left-container">
      <h6 class="above-title">¿Qué es Mundo Hogar?</h6>
      <div class="title-container">
        <h2 class="title">El lugar en donde encontrarás todo lo que necesitas para tu hogar</h2>
      </div>
      <div class="subtitle-offers">
        <ul class="offers">
          <li class="offer">Portal con beneficios exclusivos para clientes Santander</li>
          <li class="offer">Servicios 100% online</li>
          <li class="offer">Encuentra las mejores opciones de crédito y financiamiento</li>
        </ul>
      </div>
      <div class="subtitle-container">
        <p class="subtitle">Si ya eres cliente Santander, solo crea tu cuenta Mundo Hogar y comienza a explorar un mundo de posibilidades para tu hogar.</p>
        <p class="subtitle-mobile">Si ya eres cliente Santander, solo crea tu cuenta Mundo Hogar y comienza a explorar un mundo de posibilidades para tu hogar.</p>
      </div>
      <div class="button-container">
        <app-button innerId="btn-AsKD8YfB" label="Crear cuenta" styleClass="primary expand" routerLink="/enrollment/sign-up" />
      </div>
    </div>
  </div>
</section>
<section class="new-customer-section">
  <div class="container">
    <p class="customer-benefit">Si aún no eres cliente Santander, haz <a class="link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">clic aquí</a><p>
  </div>
</section>
<section class="discover-section">
  <div class="container">
    <div class="left-container">
      <div class="title-container">
        <h2 class="title">¿Qué puedes encontrar en Mundo Hogar?</h2>
        <hr class="underline">
      </div>
      <div class="image-container">
        <img class="image" src="/assets/images/home-assistance-plans/hero-plan-hogar.png" alt="Imagen de qué puedes encontrar en Mundo Hogar" />
      </div>
      <div class="content">
        <p class="text">Desde los servicios necesarios para una remodelación, mudanza, servicio de bodegas, hasta la compra de muebles y electrónicos para hacer de tu hogar, el espacio ideal.</p>
        <p class="text">Además, puedes acceder a simuladores hipotecarios o de proyectos de remodelación para conocer el costo aproximado de lo que necesitas, 100% online, sin ir a sucursal.</p>
        <p class="text">Mundo Hogar es un portal exclusivo para clientes Santander.</p>
      </div>
    </div>
    <div class="right-container">
    <!-- TODO: change to final image-->
      <img class="image" src="/assets/images/home-assistance-plans/hero-plan-hogar.png" alt="Imagen de qué puedes encontrar en Mundo Hogar" />
    </div>
  </div>
</section>
<section class="payment-facilities-section">
  <div class="container">
    <div class="items">
      <div class="item">
        <div class="number-container">
          <span class="number">1</span>
        </div>
        <div class="title-container">
          <h4 class="title">Créditos hipotecarios</h4>
        </div>
        <p class="subtitle">Te permiten financiar la compra y remodelación de tu hogar.</p>
        <div class="button-container">
          <app-button innerId="btn-2pWkfr5Z" styleClass="tertiary" label="Saber más" href="https://www.santander.com.mx/personas/creditos-hipotecarios.html" />
        </div>
      </div>
      <div class="item">
        <div class="number-container">
          <span class="number">2</span>
        </div>
        <div class="title-container">
          <h4 class="title">Créditos Personales</h4>
        </div>
        <p class="subtitle">Consigue una reducción en tu tasa de interés y hasta 60 meses de plazo de pago (solo aplica para compras en el portal).</p>
        <div class="button-container">
          <app-button innerId="btn-xm8tsBjp" styleClass="tertiary" label="Saber más" href="https://www.santander.com.mx/personas/creditos-personales.html" />
        </div>
      </div>
      <div class="item">
        <div class="number-container">
          <span class="number">3</span>
        </div>
        <div class="title-container">
          <h4 class="title">Pago con tarjeta de crédito</h4>
        </div>
        <p class="subtitle">Paga con tus tarjetas Santander hasta en 36 meses.</p>
        <div class="button-container">
          <app-button innerId="btn-Xpdu8xS6" styleClass="tertiary" label="Saber más" href="https://www.santander.com.mx/personas/creditos-hipotecarios.html" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="why-platform-section">
  <div class="container">
    <h2 class="title">¿Por qué Mundo Hogar?</h2>
    <hr class="underline">
    <div class="description-container">
      <p class="description">Mundo Hogar es tu aliado perfecto para transformar y personalizar tu hogar, con el respaldo y confianza de Banco Santander.</p>
      <p class="description">Accederás a beneficios como:</p>
    </div>
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon icon-call-center"></i>
        <div class="benefit-content">
          <h4 class="label">Acompañamiento integral</h4>
          <span class="description">Apoyo en cada reto asociado al hogar.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">Experiencia unificada y digital</h4>
          <span class="description">Todo en un solo lugar, de manera simple y digital.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-heart"></i>
        <div class="benefit-content">
          <h4 class="label">Confianza y transparencia</h4>
          <span class="description">Procesos claros y confiables.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-payment-dollar"></i>
        <div class="benefit-content">
          <h4 class="label">Personalización de la oferta</h4>
          <span class="description">Soluciones adaptadas a tus necesidades.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-hand-financing"></i>
        <div class="benefit-content">
          <h4 class="label">Alternativas únicas de financiamiento</h4>
          <span class="description">Opciones exclusivas.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon icon-money-pouch"></i>
        <div class="benefit-content">
          <h4 class="label">Mejores precios del mercado</h4>
          <span class="description">Garantía de las mejores ofertas.</span>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="how-mundo-hogar-works-section">
  <div class="container">
    <div class="left-container">
      <img class="image" src="assets/images/assistance-benefits.svg" alt="Imagen de los beneficios de la remodelación" />
    </div>
    <div class="right-container">
      <div class="title-container">
        <h2 class="title">¿Como funciona Mundo Hogar?</h2>
      </div>
      <div class="items-container">
        <ol class="items">
          <div class="item-wrapper"><i class="icon icon-checkmark-in-a-circle"></i><li class="item">Activa tu cuenta: <span class="text">Regístrate en Mundo Hogar usando tus credenciales de Apple, Google o tu correo electrónico. Recibirás un correo de validación para activar tu cuenta.</span></li></div>
          <div class="item-wrapper"><i class="icon icon-checkmark-in-a-circle"></i><li class="item">Confirma tus datos: <span class="text">Verifica tu información para asegurarnos de entregar cotizaciones, presupuestos, ofertas y beneficios personalizados.</span></li></div>
          <div class="item-wrapper"><i class="icon icon-checkmark-in-a-circle"></i><li class="item">Identifícate como cliente Santander: <span class="text">Ingresa los 6 primeros dígitos de alguna de tus tarjetas Santander para acceder a los beneficios exclusivos.</span></li></div>
        </ol>
      </div>
    </div>
  </div>
  <div class="ready-text">
    <p class="text">¡Ahora estás listo para comprar productos o contratar servicios en Mundo Hogar!</p>
  </div>
</section>
<section class="service-categories-and-suppliers">
  <div class="container">
    <h2 class="title">Categorías de servicios y proveedores asociados a Mundo Hogar:</h2>
    <hr class="underline">
    <div class="categories-container">
      @for (category of categories; track $index) {
        <p-card class="category-card">
          <ng-template pTemplate="header">
            <img class="card-img" [src]="category.image" [alt]="category.alt" />
            <h4 class="title-card">{{ category.title }}</h4>
          </ng-template>
          <ng-template pTemplate="content">
            <ul class="info-section">
              <li class="text">Empresa asociada: {{ category.company }}</li>
              @if (category.showMore) {
                <li class="text">Servicios: {{ category.services }}</li>
                <li class="text">
                  ¿Por qué confiamos en {{ category.shortCompany }}?
                  <ul class="sub-info-section">
                    @for (infoSection of category.trustedInfo; track $index) {
                      <div class="sub-info-list">
                        <i class="icon icon-checkmark-in-a-circle"></i><li class="sub-text">{{ infoSection }}</li>
                      </div>
                    }
                  </ul>
                </li>
              }
            </ul>
          </ng-template>
          <ng-template pTemplate="footer">
            @if (category.showMore) {
              <app-button innerId="btn-ZIJf0coM" class="button" [label]="category.buttonLabel" styleClass="secondary" [href]="category.url" />
            }
            <p-accordion class="accordion" expandIcon="pi pi-chevron-down"
              collapseIcon="pi pi-chevron-up" [activeIndex]="category.showMore ? $index : null">
              <p-accordionTab
                class="accordion-tab"
                [header]="category.showMore ? 'Ver menos' : 'Ver más'"
                iconPos="end"
                (click)="toggleShowMore(category)"
              />
            </p-accordion>
          </ng-template>
        </p-card>
      }
    </div>
  </div>
</section>
