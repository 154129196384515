<section class="project-simulator-container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">{{ 'Back' | transloco }}</span>
      <span class="mobile-text">{{ 'Simulate your project' | transloco }} </span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">{{ 'Simulates the cost of the project you have in mind' | transloco }}</h5>
      <app-circular-progress [currentStep]="6" [numberSteps]="6" stepTitle="{{ 'We will send your simulation!' | transloco }}" stepSubtitle="{{ 'You will complete the process' | transloco }}" />
    </div>
    <div class="bottom-container animate__fadeIn">
      <div class="project-simulation-types-container">
        <h2 class="title">{{ 'Almost done! Enter your information' | transloco }}</h2>
        <div class="input-container">
          <label for="full-name">{{ 'Full name' | transloco }}</label>
          <input id="full-name" type="text" pInputText [formControl]="form.controls.fullName" />
        </div>
        <div class="input-container">
          <label for="email">{{ 'Email' | transloco }}</label>
          <input id="email" type="email" pInputText [formControl]="form.controls.email" />
        </div>
        <div class="input-container">
          <label for="phone-number">{{ 'Mobile phone number' | transloco }}*</label>
          <p-inputMask id="phone-number" [class.active]="isActive(form.controls.phoneNumber)" [formControl]="form.controls.phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 999?9" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" #phoneNumberInput />
          <small [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">{{ 'Enter a valid phone number, it must start with \"52\" and then 10 digits.' | transloco }}</small>
        </div>
        <div class="reminder-container">
          <app-reminder styleClass="alert" title="Important" description="The simulation will be sent via WhatsApp, it is important to enter a valid phone number to which you have access."></app-reminder>
        </div>
      </div>
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="{{ 'Illustration of the selected option' | transloco }}" />
        <div class="continue-button-container">
          <app-button label="Continue" styleClass="primary expand" type="submit" [disabled]="form.invalid" />
        </div>
      </div>
    </div>
  </form>
</section>
@if (form.controls.phoneNumber.value) {
  <app-send-simulation-sidebar #sendSimulationSidebar [phoneNumber]="form.controls.phoneNumber.value" [sendingSimulation]="sendingSimulation" [simulationSent]="simulationSent" (sendSimulation)="sendSimulation()" (simulateAgain)="simulateAgain()" (changePhoneNumber)="changePhoneNumber()" />
}
