import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Account } from '@homein-accounts-server';
import { PaymentIntent, PaymentIntentSource } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { CurrencyFormatterPipe } from '../../../../pipes/currency-formatter.pipe';
import { AccountsService } from '../../../../services/accounts-server/accounts/accounts.service';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { PaymentIntentsService } from '../../../../services/payment-intents/payment-intents.service';
import { SeoService } from '../../../../services/seo/seo.service';
import { sortAccounts } from '../../../../utils/accounts.utils';

type InternalMovementType = 'charge' | 'deposit';

interface InternalMovement {
  amount: number;
  currency: string;
  date: Date;
  orderId: string;
  source: PaymentIntentSource;
  subtitle: string;
  title: string;
  type: InternalMovementType;
}

@Component({
  selector: 'app-my-balance',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    RouterLink,
    TooltipModule,
    TranslocoPipe,
  ],
  templateUrl: './my-balance.page.html',
  styleUrl: './my-balance.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MyBalancePage implements OnDestroy, OnInit {
  accounts: Account[] = [];
  errorLoadingAccounts = false;
  errorLoadingMovements = false;
  loadingAccounts = false;
  loadingMovements = false;
  movements: InternalMovement[] = [];
  private viewDestroyed = new Subject<void>();

  constructor(
    private accountsService: AccountsService,
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private paymentIntentsService: PaymentIntentsService,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  childEventClicked(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  getAccountTypeDetailUrl(type: string): string {
    return type === 'mortgage' ?
      'https://www.santander.com.mx/personas/creditos-hipotecarios.html' :
      'https://www.mundohogar.com.mx/what-is-mundo-hogar';
  }

  getAccounts(): void {
    this.loadingAccounts = true;
    this.errorLoadingAccounts = false;
    this.accountsService.getAll().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (accounts) => {
        // TODO: delete filter later on, in the meantime we will only show debit account
        this.accounts = sortAccounts(accounts.filter((account) => account.type === 'debit'), ['mortgage', 'debit']);
        this.loadingAccounts = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyBalancePage.getAccounts()', 'get-accounts-data', error);
        this.errorLoadingAccounts = true;
        this.loadingAccounts = false;
      },
    });
  }

  getMovementDetailUrl(movement: InternalMovement): string {
    const { source, orderId } = movement;
    switch (source) {
    case 'ecommerce':
      return `/private-site/my-purchases/${orderId}`;
    case 'home-assistance':
      return `/private-site/my-home-assistances/${orderId}`;
    case 'moving':
      return `/private-site/my-movings/${orderId}`;
    case 'remodeling':
      return `/private-site/my-remodelings/${orderId}`;
    case 'warehouse': // TODO: add my warehouses url when implemented
    default:
      return '/';
    }
  }

  getMovements(): void {
    this.loadingMovements = true;
    this.errorLoadingMovements = false;
    this.paymentIntentsService.getAllPaid().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (paymentIntents) => {
        this.movements = this.mapMovements(paymentIntents);
        this.loadingMovements = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyBalancePage.getMovements()', 'get-movements-data', error);
        this.errorLoadingMovements = true;
        this.loadingMovements = false;
      },
    });
  }

  goBack(): void  {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site']);
  }

  initialize(): void {
    this.getAccounts();
    this.getMovements();
  }

  private mapMovements(paymentIntents: PaymentIntent[]): InternalMovement[] {
    return paymentIntents.reduce((movements, paymentIntent) => {
      const apportions = paymentIntent.apportions.internal.map(({ amount }) => ({ amount })).concat(paymentIntent.apportions.external);
      const apportionMovements = apportions.reduce((_, apportion, index) => {
        const elements = [{
          amount: apportion.amount,
          currency: paymentIntent.currency,
          date: paymentIntent.createdAt,
          orderId: paymentIntent.orderId,
          subtitle: apportions.length === 1 ? paymentIntent.message : `${paymentIntent.message} (Cargo ${index + 1}/${apportions.length})`,
          source: paymentIntent.source,
          title: this.getMovementTitleBySource(paymentIntent.source, paymentIntent.reversed),
          type: 'charge' as InternalMovementType,
        }];
        if (paymentIntent.reversed && paymentIntent.reversedAt) {
          elements.push({
            amount: apportion.amount,
            currency: paymentIntent.currency,
            date: paymentIntent.reversedAt,
            orderId: paymentIntent.orderId,
            subtitle: apportions.length === 1 ? paymentIntent.message : `${paymentIntent.message} (Devolución ${index + 1}/${apportions.length})`,
            source: paymentIntent.source,
            title: this.getMovementTitleBySource(paymentIntent.source, paymentIntent.reversed),
            type: 'deposit' as InternalMovementType,
          });
        }
        return _.concat(elements);
      }, [] as InternalMovement[]);
      movements = [...movements, ...apportionMovements];
      return movements.sort((a, b) => b.date.getTime() - a.date.getTime());
    }, [] as InternalMovement[]);
  }

  // TODO: handle later on deposits
  private getMovementTitleBySource(source: PaymentIntentSource, reversed: boolean): string {
    if (reversed) {
      if (source === 'ecommerce') {
        return 'Devolución compra para el hogar';
      } else if (source === 'home-assistance') {
        return 'Devolución de plan de asistencia';
      } else if (source === 'moving') {
        return 'Devolución reserva de mudanza';
      } else if (source === 'remodeling') {
        return 'Devolución pago en remodelaciones';
      } else if (source === 'warehouse') {
        return 'Devolución reserva de bodega';
      }
      return 'Devolución';
    } else {
      if (source === 'ecommerce') {
        return 'Compra para el hogar';
      } else if (source === 'home-assistance') {
        return 'Compra plan de asistencia';
      } else if (source === 'moving') {
        return 'Reserva de mudanza';
      } else if (source === 'remodeling') {
        return 'Pago en remodelaciones';
      }  else if (source === 'warehouse') {
        return 'Reserva de bodega';
      }
      return 'Cargo';
    }
  }
}