import { Injectable } from '@angular/core';
import { CreatePaymentIntentData, PaymentIntent } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentIntentsServiceApi } from './payment-intents.service.api';
import { PaymentIntentsServiceMock } from './payment-intents.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? PaymentIntentsServiceMock : PaymentIntentsServiceApi,
})
export abstract class PaymentIntentsService {
  abstract createExternal(data: CreatePaymentIntentData): Promise<{ id: string; redirectUrl: string; }>;
  abstract createInternal(data: CreatePaymentIntentData): Promise<{ id: string }>;
  abstract get(id: string): Observable<PaymentIntent | null>;
  abstract getAllPaid(): Observable<PaymentIntent[]>;
}