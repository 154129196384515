import { Injectable } from '@angular/core';
import { AddFavoriteItemData, Favorite, FavoriteItem, RemoveFavoriteItemData, SearchEngineProduct } from '@homein-hogar-server';
import { firstValueFrom, from, map, Observable, of, switchMap } from 'rxjs';
import { constants } from '../../constants';
import { getUpdatedFavoriteItems, MAX_FAVORITES_ITEMS } from '../../utils/favorite.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { SearchService } from '../search/search.service';
import { SessionsService } from '../sessions/sessions.service';
import { FavoriteDetail, FavoritesService, LocalFavorite } from './favorites.service';

@Injectable()
export class FavoritesServiceMock implements FavoritesService {
  private favoriteDetail: Observable<FavoriteDetail | null>;

  constructor(
    private dataStorageService: DataStorageService,
    private mockService: MockService,
    private searchService: SearchService,
    private sessionsService: SessionsService,
  ) {
    this.favoriteDetail = this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.getFavoriteMock(session.userId).pipe(switchMap((favorite) => this.getMockedChildren(favorite)));
      }
      return this.getLocalFavorite().pipe(switchMap((localFavorite) => this.getMockedChildren(localFavorite)));
    }));
  }

  async addItem(data: AddFavoriteItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const favorite = await firstValueFrom(this.getFavoriteMock(session.userId));
      if (!favorite) {
        return this.mockService.createFavorite({
          id: session.userId,
          items: [{
            ...data,
            addedAt: new Date(),
          }],
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateFavorite(session.userId, {
        items: getUpdatedFavoriteItems({
          action: 'add',
          items: favorite.items,
          addItemData: data,
        }),
      });
    }
    let localFavorite = await firstValueFrom(this.getLocalFavorite());
    if (!localFavorite) {
      localFavorite = {
        items: [{
          ...data,
          addedAt: new Date(),
        }],
      };
    } else {
      localFavorite = {
        ...localFavorite,
        items: getUpdatedFavoriteItems({
          action: 'add',
          items: localFavorite.items,
          addItemData: data,
        }),
      };
    }
    this.dataStorageService.set<LocalFavorite>(DataKey.Favorites, localFavorite);
  }

  get(): Observable<FavoriteDetail | null> {
    return this.favoriteDetail;
  }

  hasFavorite(resourceType: string, resourceId: string): Observable<boolean> {
    return this.favoriteDetail.pipe(map((favorite) => !!favorite?.items.some((item) => item.resourceType === resourceType && item.resourceId === resourceId)));
  }

  hasFavoriteItems(): Observable<boolean> {
    return this.favoriteDetail.pipe(map((favorites) => !!favorites?.items.length));
  }

  async removeItem(data: RemoveFavoriteItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const favorite = await firstValueFrom(this.getFavoriteMock(session.userId));
      if (!favorite) {
        return this.mockService.createFavorite({
          id: session.userId,
          items: [{
            ...data,
            addedAt: new Date(),
          }],
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateFavorite(session.userId, {
        items: getUpdatedFavoriteItems({
          action: 'remove',
          items: favorite.items,
          removeItemData: data,
        }),
      });
    }
    let localFavorite = await firstValueFrom(this.getLocalFavorite());
    if (!localFavorite) {
      localFavorite = {
        items: [],
      };
    } else {
      localFavorite = {
        ...localFavorite,
        items: getUpdatedFavoriteItems({
          action: 'remove',
          items: localFavorite.items,
          removeItemData: data,
        }),
      };
    }
    this.dataStorageService.set<LocalFavorite>(DataKey.Favorites, localFavorite);
  }

  async synchronize(): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (!session) {
      return;
    }
    const localFavorite = await firstValueFrom(this.getLocalFavorite());
    const favorite = await firstValueFrom(this.getFavoriteMock(session.userId));
    if (!favorite) {
      return this.mockService.createFavorite({
        id: session.userId,
        items: localFavorite ? localFavorite.items : [],
        userId: session.userId,
      }).then();
    }
    if (localFavorite && !localFavorite.items.length) {
      return;
    }
    const favoriteProductItems = favorite.items.filter((item) => item.resourceType === 'product');
    let newFavoriteProductItems: FavoriteItem[] = [];
    (localFavorite?.items ?? []).forEach((item) => {
      const index = favoriteProductItems.findIndex((product) => product.resourceId === item.resourceId);
      if (index !== -1) {
        const favoriteProductItem = favoriteProductItems[index];
        newFavoriteProductItems.push(favoriteProductItem.addedAt.getTime() > item.addedAt.getTime() ? favoriteProductItem : item);
        favoriteProductItems.splice(index, 1);
      } else {
        newFavoriteProductItems.push(item);
      }
    });
    newFavoriteProductItems = newFavoriteProductItems.sort((a, b) => a.addedAt.getTime() - b.addedAt.getTime());
    if (newFavoriteProductItems.length > constants.maxFavoriteItems) {
      newFavoriteProductItems = newFavoriteProductItems.slice(-constants.maxFavoriteItems);
    }
    const newFavoriteItems = favorite.items.filter((item) => item.resourceType !== 'product').concat(newFavoriteProductItems);
    await this.mockService.updateFavorite(session.userId, { items: newFavoriteItems });
  }

  private getFavoriteMock(id: string): Observable<LocalFavorite | null> {
    return this.mockService.getFavorite(id);
  }

  private getLocalFavorite(): Observable<LocalFavorite | null> {
    return this.dataStorageService.get<LocalFavorite>(DataKey.Favorites);
  }

  private getMockedChildren(favorite: Favorite | LocalFavorite | null): Observable<FavoriteDetail | null> {
    if (!favorite) {
      return of(null);
    }
    return from(this.searchService.search<SearchEngineProduct>('products', {
      filters: [
        {
          field: 'id',
          operator: 'in',
          value: favorite.items.map((item) => item.resourceId),
        }
      ],
      pagination: {
        documentsPerPage: MAX_FAVORITES_ITEMS,
      },
    })).pipe(map((paginable) => {
      const detailItems = favorite.items.map((item) => {
        if (item.resourceType === 'product') {
          return {
            ...item,
            resource: paginable.data.find((product) => product.id === item.resourceId) ?? null,
          };
        }
        return {
          ...item,
          resource: null,
        };
      }).filter((item) => item.resource);
      return {
        items: detailItems,
      };
    }));
  }
}