import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MovingOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { MovingPurchaseDetailsComponent } from '../../../../components/moving-purchase-details/moving-purchase-details.component';
import { RatingComponent } from '../../../../components/rating/rating.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { MovingOrdersService } from '../../../../services/moving-orders/moving-orders.service';
import { SeoService } from '../../../../services/seo/seo.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { openExternalLink } from '../../../../utils/link.utils';

@Component({
  selector: 'app-moving-status',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CommonModule,
    MovingPurchaseDetailsComponent,
    RatingComponent,
    RetryableSectionComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './moving-status.page.html',
  styleUrl: './moving-status.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class MovingStatusPage implements OnInit, OnDestroy {
  errorLoading = false;
  loading = true;
  movingOrder: MovingOrder;
  private orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private movingOrdersService: MovingOrdersService,
    private router: Router,
    private seoService: SeoService,
    private toastService: ToastService
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyMovings();
      return;
    }
    this.orderId = orderId;
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goToChat(movingOrder: MovingOrder): void {
    if (!movingOrder.additionalData['chatUrl']) {
      this.errorReportingService.log('MovingStatusPage.goToChat()', 'go-to-chat', new Error('chatUrl empty or not defined'));
      this.toastService.showWarning({ title: 'Chat no disponible', description: 'No se puede acceder al chat en este momento.' }, 3000);
      return;
    }
    openExternalLink(movingOrder.additionalData['chatUrl']);
  }

  goToReserveTheDate(movingOrder: MovingOrder): void {
    if (!movingOrder.additionalData['quoteUrl']) {
      this.errorReportingService.log('MovingStatusPage.goToReserveTheDate()', 'go-to-reserve-the-date', new Error('quoteUrl empty or not defined'));
      this.toastService.showWarning({ title: 'Fecha de reserva no disponible', description: 'No se puede acceder a la fecha de reserva en este momento.' }, 3000);
      return;
    }
    openExternalLink(movingOrder.additionalData['quoteUrl']);
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.movingOrdersService.get(this.orderId).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (movingOrder) => {
        if (!movingOrder) {
          this.goToMyMovings();
          return;
        }
        this.seoService.setMetaTags({
          description: 'Consulta el estado de tu mudanza en Mundo Hogar.',
          title: `Estado de la mudanza: ${movingOrder.additionalData['serviceType']}`,
        });
        this.movingOrder = movingOrder;
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('MovingStatusPage.initialize()', 'get-order', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  private goToMyMovings(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-movings']);
  }
}