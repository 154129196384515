export class CustomError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;

  constructor(public cause: string, public code: string, override message: string) {
    super(message);
    this.name = 'CustomError';
    this.data = {
      cause,
      code,
      message,
    };
  }
}