import { Injectable } from '@angular/core';
import { GetEstimatedDeliveryData, SearchEngineProduct } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { Paginable, SearchFilter, SearchOrder, SearchPagination } from '../search/search.service';
import { ProductsServiceApi } from './products.service.api';
import { ProductsServiceMock } from './products.service.mock';

export const PRODUCT_SEARCH_DEFAULT_FIELDS = ['name', 'brand', 'description', 'searchId'];
export const PRODUCT_SEARCH_DEFAULT_WEIGHTS = [2, 2, 1, 2];
export const PRODUCTS_PER_PAGE_DEFAULT = 16;
export const BASIC_FILTER: SearchFilter[] = [
  { field: 'status', operator: '=', value: 'active' },
  { field: 'stock', operator: '>', value: 0 },
];

export interface ProductSearchConfig {
  filters?: SearchFilter[] | null;
  order?: SearchOrder | null;
  pagination?: SearchPagination | null;
}

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? ProductsServiceMock : ProductsServiceApi,
})
export abstract class ProductsService {
  abstract get(id: string): Promise<SearchEngineProduct | null>;
  abstract getEstimatedDelivery(data: GetEstimatedDeliveryData): Promise<{ from: Date, to: Date; }>;
  abstract search(query: string | null, config?: ProductSearchConfig): Promise<Paginable<SearchEngineProduct>>;
}
