import { Route } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
    data: {
      description: 'Simula el proyecto de la remodelación de tu hogar junto a la empresa líder de remodelaciones en México y solicita tu tarjeta Santander de manera 100% online. ',
      title: 'Simula el costo de tu proximo proyecto',
    },
  },
  {
    path: 'free-quote',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
    data: {
      action: 'quote',
      description: 'Simula el proyecto de la remodelación de tu hogar junto a la empresa líder de remodelaciones en México y solicita tu tarjeta Santander de manera 100% online. ',
      title: 'Simula el costo de tu proximo proyecto',
    },
  },
] satisfies Route[];