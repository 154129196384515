import { Inject, Injectable } from '@angular/core';
import { limit, orderBy, where } from '@angular/fire/firestore';
import { WarehouseOrder } from '@homein-hogar-server';
import { map, Observable, of, switchMap } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { add } from '../../utils/date.utils';
import { WarehouseOrdersService } from './warehouse-orders.service';

const COLLECTION_PATH = 'warehouseOrders';

@Injectable()
export class WarehouseOrdersServiceApi implements WarehouseOrdersService {
  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {}

  createId(): string {
    return this.firestore.createId(COLLECTION_PATH);
  }

  get(id: string): Observable<WarehouseOrder | null> {
    return this.firestore.doc<WarehouseOrder>(`${COLLECTION_PATH}/${id}`);
  }

  getAll(): Observable<WarehouseOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<WarehouseOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', 'in', ['done', 'pending-payment', 'waiting-confirmation', 'voided']),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<WarehouseOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<WarehouseOrder>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('status', 'in', ['done', 'pending-payment', 'waiting-confirmation']),
            where('finishAt', '>=', add(new Date(), 1, 'days')),
            orderBy('createdAt', 'desc'),
            limit(1),
          ],
        }).pipe(map((warehouseOrders) => warehouseOrders.length ? warehouseOrders[0] : null));
      }
      return of(null);
    }));
  }
}
