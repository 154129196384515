<section class="home-assistance-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon-arrow-left" routerLink="/private-site/my-home-assistances/{{ orderId }}"></i>
    }
    <h2 class="title">Detalle de compra</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (order) {
      <div class="total-container">
        <div class="purchase-detail">{{ order.createdAt | date: 'dd/MM/yyyy' }} | #{{ order.id }}</div>
        <div class="products-value">
          <span class="label">Plan</span>
          <span class="value">{{ order.subtotal | currencyFormatter: order.currency }} {{ order.currency }}</span>
        </div>
        <div class="separator"></div>
        <div class="total">
          <div class="label">Total</div>
          <div class="value">{{ order.totalAmount | currencyFormatter: order.currency }} {{ order.currency }}</div>
        </div>
      </div>
      <div class="app-purchase-payment">
        <app-purchase-payment-details [paymentIntentIds]="[order.paymentIntentId]" />
      </div>
      <div class="home-assistance-items-container">
        <div class="assistance-item">
          <span class="assistance-header">Items de la compra</span>
          <div class="product-container">
            <img class="image" src="assets/illustrations/{{ order.plan.id }}.svg" alt="Imagen de {{ order.plan.name }}" loading="lazy" />
            <div class="info">
              <span class="above">{{ order.plan.name }}</span>
              <span class="under">{{ order.totalAmount | currencyFormatter: order.currency }} {{ order.currency }} | 1 u.</span>
            </div>
          </div>
        </div>
      </div>
    }
  </app-retryable-section>
</section>