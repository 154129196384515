import { AddShoppingCartItemData, RemoveShoppingCartItemData, ShoppingCartItem } from '@homein-hogar-server';
import { constants } from '../constants';
import { CustomError } from '../models/custom-error.model';

export function getUpdatedShoppingCartItems(params: ({ items: ShoppingCartItem[] } & ({
  action: 'add';
  addItemData: AddShoppingCartItemData;
} | {
  action: 'remove';
  removeItemData: RemoveShoppingCartItemData;
}))): ShoppingCartItem[] {
  const { quantity, resourceId, resourceType } = params.action === 'add' ? params.addItemData : params.removeItemData;
  if (params.action === 'add' && params.items.length >= constants.maxShoppingCartItems) {
    throw new CustomError('max-shopping-cart-items-reached', 'CONFLICT', 'Max shopping cart items reached.');
  }
  const index = params.items.findIndex((item) => item.resourceId === resourceId && item.resourceType === resourceType);
  if (params.action === 'add') {
    if (index === -1) {
      params.items.push({
        addedAt: new Date(),
        quantity,
        resourceId,
        resourceType,
      });
    } else {
      params.items[index] = {
        ...params.items[index],
        addedAt: new Date(),
        quantity: params.items[index].quantity + quantity,
      };
    }
    return params.items;
  }
  if (index === -1) {
    return params.items;
  }
  params.items[index] = {
    ...params.items[index],
    quantity: params.items[index].quantity - quantity,
  };
  if (params.items[index].quantity <= 0) {
    params.items.splice(index, 1);
  }
  return params.items;
}
