import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-warehouses/my-warehouses.page').then(m => m.MyWarehousesPage)
  },
  {
    path: ':orderId',
    loadComponent: () => import('./warehouse-status/warehouse-status.page').then(m => m.WarehouseStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('./warehouse-details/warehouse-details.page').then(m => m.WarehouseDetailsPage)
  }
] satisfies Routes;
