<app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
  @if (paymentIntent && paymentIntent.status === 'paid') {
    <section class="payment-section payment-successful">
      <div class="payment-bar">
        <div class="payment-content">
          <div class="text-content">
            <h3 class="title">{{ 'Payment completed' | transloco }}</h3>
            <p class="description">{{ 'We have successfully processed the payment' | transloco }}</p>
          </div>
        </div>
        <div class="icon-circle">
          <i class="icon-checkmark"></i>
        </div>
      </div>
      <div class="payment-details">
        <h5 class="subtitle">{{ 'Your payment was successful!' | transloco }}</h5>
        <p class="info-text">{{ 'Your receipt was sent to:' | transloco }}</p>
        <p class="info-text">
          <strong class="info-email">{{ email }}</strong>
        </p>
        <div class="payment-details-info">
          @if (externalPaymentData) {
            <p class="text">{{ 'Company' | transloco }}:
              <strong class="value">{{ externalPaymentData.company }}</strong>
            </p>
            <p class="text">{{ 'Payment date' | transloco }}:
              <strong class="value">{{ externalPaymentData.date }}</strong>
            </p>
            @if (externalPaymentData.authorization) {
              <p class="text">{{ 'Authorization #' | transloco }}:
                <strong class="value">{{ externalPaymentData.authorization }}</strong>
              </p>
            }
          } @else {
            <p class="text">{{ 'Payment date' | transloco }}:
              <strong class="value">{{ paymentIntent.createdAt | date: 'dd/MM/yy \'' + ('at' | transloco) + '\' h:mm a' }}</strong>
            </p>
          }
          <p class="text">{{ 'Amount' | transloco }}:
            <strong class="value">{{ paymentIntent.amount | currencyFormatter: paymentIntent.currency }} {{ paymentIntent.currency }}</strong>
          </p>
          <p class="text">{{ 'Transaction ID' | transloco }}:
            <strong class="value">{{ paymentIntent.id }}</strong>
          </p>
        </div>
        <div class="button-container">
          <app-button label="Return to My Home" styleClass="primary dark expand" (action)="goToMyHome()" />
        </div>
        <div class="countdown">
          <span class="text">{{ 'In X seconds you will be redirected to My Home' | transloco: { countdown } }}</span>
        </div>
      </div>
      <div class="lottie-container">
        <ng-lottie [options]="{ path: 'assets/animations/lottie-successful-payment.json' }" class="lottie-animation"></ng-lottie>
        <ng-lottie [options]="{ path: 'assets/animations/lottie-successful-payment.json' }" class="lottie-animation"></ng-lottie>
      </div>
    </section>
  } @else if (paymentIntent && paymentIntent.status === 'failed') {
      <section class="payment-section payment-failure">
        <div class="payment-bar">
          <div class="payment-content">
            <div class="text-content">
              <h3 class="title">{{ 'Payment failed' | transloco }}</h3>
              <p class="description">{{ 'An error has occurred in the process' | transloco }}</p>
            </div>
          </div>
          <div class="icon-circle">
            <i class="icon-close"></i>
          </div>
        </div>
        <div class="payment-details">
          <h5 class="subtitle">{{ 'Your payment was not processed' | transloco }}</h5>
          <p class="info-text">{{ 'No charges were made to your accounts or payment methods.' | transloco }}</p>
          <div class="payment-details-info">
            @if (externalPaymentData) {
              @if (externalPaymentData.statusDetail) {
                <p class="text">{{ 'Reason' | transloco }}:
                  <strong class="value">{{ externalPaymentData.statusDetail }}</strong>
                </p>
              }
              @if (externalPaymentData.statusAction) {
                <p class="text">
                  <strong class="value">{{ externalPaymentData.statusAction }}</strong>
                </p>
              }
              <p class="text">{{ 'Company' | transloco }}:
                <strong class="value">{{ externalPaymentData.company }}</strong>
              </p>
              @if (externalPaymentData.authorization) {
                <p class="text">{{ 'Payment date' | transloco }}:
                  <strong class="value">{{ externalPaymentData.date }}</strong>
                </p>
                <p class="text">{{ 'Authorization #' | transloco }}:
                  <strong class="value">{{ externalPaymentData.authorization }}</strong>
                </p>
              }
              <p class="text">{{ 'Transaction ID' | transloco }}:
                <strong class="value">{{ paymentIntent.id }}</strong>
              </p>
            }
          </div>
          <div class="button-container">
            <app-button label="Retry payment" styleClass="primary dark expand" (action)="goToPayment()" />
          </div>
        </div>
      </section>
  }
</app-retryable-section>