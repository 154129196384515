<section class="main-container">
  <div class="moving-status-container">
    <div class="title-container">
      <i class="icon-arrow-left" routerLink="/private-site/my-movings"></i>
      <h2 class="title">Estado de la mudanza</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (movingOrder) {
        <div class="moving-status-info">
          @if (movingOrder.status === 'pending-payment') {
            <span class="status pending">Pago pendiente</span>
            <span class="description">Servicio por seleccionar</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <p class="description">La reserva permite asegurar disponibilidad para tu mudanza. En cuanto realices el pago de la reserva, nos pondremos en contacto contigo para confirmar los detalles de tu mudanza.</p>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Ir a reservar la fecha" (action)="goToReserveTheDate(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'waiting-confirmation') {
            <span class="status pending">Esperando confirmación</span>
            <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description date">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Te notificaremos en cuanto tu solicitud de mudanza esté lista para continuar</span>
          } @else if (movingOrder.status === 'pending-confirmation-visit') {
            <span class="status pending">Visita pendiente</span>
            <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description date">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Para continuar debes coordinar la visita de inspección</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Ir al chat" (action)="goToChat(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'waiting-moving') {
            <span class="status">Todo listo</span>
            <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Todo listo para tu mudanza</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Ir al chat" (action)="goToChat(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'done') {
            <span class="status done">Finalizada</span>
            <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Tu mudanza se realizó el día {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Ir al chat" (action)="goToChat(movingOrder)" />
            </div>
          } @else if (movingOrder.status === 'voided') {
            <span class="status done">Anulado</span>
            <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
            <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
            <span class="description-date">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Tu mudanza ha sido anulada, si tienes consultas puedes dirigirte al chat de soporte</span>
            <div class="secondary-button-container">
              <app-button styleClass="secondary expand" label="Ir al chat" (action)="goToChat(movingOrder)" />
            </div>
          }
        </div>
        <div class="moving-detail-container">
          <div class="moving-items-container">
            <p-accordion class="accordion-moving-items">
              <p-accordionTab class="accordion-items" header="{{ 'Moving items (X items)' | transloco: { quantity: movingOrder.items.length } }}" iconPos="end">
                @for (item of movingOrder.items; track $index) {
                  <div class="item">
                    <span class="name">{{ item.name }}</span>
                    <span class="quantity">{{ item.quantity }} u.</span>
                  </div>
                }
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="help-links-container">
          <h4 class="title">Ayuda con esta reserva</h4>
          <a routerLink="/complaints-and-returns" [queryParams]="{ orderId: movingOrder.id, service: 'moving' }" target="_blank" class="link">Obtener ayuda</a>
          <a href="https://ayuda.mundohogar.com.mx/es/" target="_blank" class="link">Información sobre Garantías</a>
          <!-- TODO: Enable when implementing page to evaluate experience
          <a href="" target="_blank" class="link">Escribir una opinión sobre el servicio</a>
          --->
        </div>
        @if (movingOrder && movingOrder.status !== 'pending-payment') {
          <div class="moving-details-navigation-container" routerLink="/private-site/my-movings/{{ movingOrder.id }}/details">
            <span class="label">Detalles de la compra</span>
            <i class="icon-arrow-right"></i>
          </div>
        }
      }
    </app-retryable-section>
  </div>
  @if (movingOrder && movingOrder.status !== 'pending-payment') {
    <div class="moving-purchase-container">
      <app-moving-purchase-details [order]="movingOrder" [showBackButton]="false" />
    </div>
  }
</section>