<div class="my-warehouses-container">
  <div class="go-back-container" (click)="goBack()">
    <i class="icon icon-arrow-left"></i>
    <h3 class="text">{{ 'My warehouses' | transloco }}
      @if (warehouseOrders.length) {
        <span class="orders-quantity">({{ warehouseOrders.length }})</span>
      }
    </h3>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    <div class="orders-container animate__fadeIn">
      @for (order of warehouseOrders; track order.id) {
        <div class="order-container">
          <div class="left-column">
            <div class="first-column">
              <h4 class="status {{ getOrderFinalStatus(order) }}">
                @switch (getOrderFinalStatus(order)) {
                  @case ('about-to-expire') {
                    {{ 'About to expire' | transloco }}
                  } @case ('done') {
                    {{ 'Active' | transloco }}
                  } @case ('finalized') {
                    {{ 'Finalized' | transloco }}
                  } @case ('pending-payment') {
                    {{ 'Payment pending' | transloco }}
                  } @case ('voided') {
                    {{ 'Voided' | transloco }}
                  } @case ('waiting-confirmation') {
                    {{ 'Pending confirmation' | transloco }}
                  }
                }  
              </h4>
              <p class="total-volume">{{ 'Warehouse X㎥' | transloco: { volume: order.additionalData['volume']} }}</p>
              @if (order.status !== 'voided') {
                <p class="start-and-end-dates">{{ 'Start: XX - End: YY' | transloco: { end: order.finishAt| date: 'dd/MM/yyyy', start: order.startAt | date: 'dd/MM/yyyy' } }}</p>
              }
            </div>
            <p class="description">
              @switch (getOrderFinalStatus(order)) {
                @case ('about-to-expire') {
                  {{ 'Your warehouse lease is about to expire. If you want to keep your plan active, please contact us.' | transloco }}
                } @case ('done') {
                  {{ 'Your warehouse is active, use it when you need it. For any questions please contact us.' | transloco }}
                } @case ('finalized') {
                  {{ 'Your warehouse lease has already ended. You can hire it again using the reactivate button.' | transloco }}
                } @case ('pending-payment') {
                  {{ 'You need to make the payment to reserve your warehouse rental.' | transloco }}
                } @case ('voided') {
                  {{ 'We regret canceling your warehouse rental. Please let us know how we can improve the service.' | transloco }}
                } @case ('waiting-confirmation') {
                  {{ 'Your warehouse is in the process of being confirmed. We will review that the payment has been made and other processes.' | transloco }}
                }
              } 
            </p>
            <div class="last-column">
              <div class="action-button-container">
                @if (['done', 'about-to-expire'].includes(getOrderFinalStatus(order))) {
                  <app-button label="Go to chat" styleClass="tertiary" [href]="order.additionalData['chatUrl']" target="_blank" rel="noopener"></app-button>
                } @else {
                  @switch (getOrderFinalStatus(order)) {
                    @case ('pending-payment') {
                      <app-button label="Go to reserve the warehouse" styleClass="tertiary" (action)="goToReserveWarehouse(order)"></app-button>
                    } @case ('finalized') {
                      <!-- TODO: Uncomment this line when the action is available -->
                      <!--<app-button label="Renew lease" styleClass="tertiary"></app-button>-->
                    }
                  }
                }
              </div>
              <app-button label="See warehouse" styleClass="secondary" href="/private-site/my-warehouses/{{ order.id }}" />
            </div>
          </div>
          <div class="right-column">
            <i class="icon icon-arrow-right" routerLink="/private-site/my-warehouses/{{ order.id }}"></i>
          </div>
        </div>
      } @empty {
        <div class="empty-warehouses">
          <img class="image" src="/assets/illustrations/warehouseman-carrying-boxes-to-warehouse.svg" [alt]="'You don\'t have warehouses illustration' | transloco" />
          <h5 class="title">{{ 'You have no scheduled/quoted warehouses yet' | transloco }}</h5>
          <p class="subtitle">{{ 'Quote your next warehouse' | transloco }}</p>
          <div class="action-button-container">
            <app-button label="Go to warehouses" styleClass="secondary" href="/warehouse"></app-button>
          </div>
        </div>
      }
    </div>
  </app-retryable-section>
</div>