import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { TranslationItem, TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent implements OnChanges, OnInit {
  @Output() action = new EventEmitter<void>();
  @Input() disabled: boolean;
  @Input() href: string;
  @Input() icon: string | { name: string; position: 'start' | 'end'; };
  // TODO: add required: true, to innerId
  @Input() innerId: string;
  @Input() label: string | TranslationItem;
  @Input() loading: boolean;
  @Input() queryParams: Params;
  @Input() rel = '';
  @Input() styleClass = 'primary';
  @Input() target = '_self';
  @Input() translate = true;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  iconConfig: { name: string; position: 'start' | 'end' } | null;
  internalLink = false;
  labelTranslationItem: TranslationItem;

  constructor(
    private translationService: TranslationService,
  ) {}

  ngOnChanges(): void {
    this.initialize();
  }

  ngOnInit(): void {
    this.internalLink = this.href?.startsWith('/');
  }

  triggerAction(): void {
    this.action?.emit();
  }

  private initialize(): void {
    if (this.label && this.translate) {
      this.labelTranslationItem = this.translationService.getTranslationItem(this.label);
    }
    if (typeof this.icon === 'string') {
      this.iconConfig = { name: this.icon, position: 'start' };
    } else {
      this.iconConfig = this.icon ?? null;
    }
  }
}
