import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { Subject, takeUntil } from 'rxjs';
import { constants } from '../../constants';

type CurrentPageType =
  | 'contact'
  | 'ecommerce'
  | 'finance-and-insurance'
  | 'help-center'
  | 'home'
  | 'home-assistance'
  | 'legal'
  | 'moving'
  | 'my-profile'
  | 'remodeling'
  | 'warehouse';

@Component({
  selector: 'app-menu-sidebar',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonModule,
    CommonModule,
    DividerModule,
    RouterModule,
    ScrollPanelModule,
    SidebarModule,
    TranslocoPipe,
  ],
  templateUrl: './menu-sidebar.component.html',
  styleUrl: './menu-sidebar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MenuSidebarComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<void>();
  @Input() visible = false;
  categories: {
    children: {
      code: string;
      label: string;
      queryParams: Record<string, string>;
    }[];
    code: string;
    label: string;
  }[];
  currentPage: CurrentPageType | null = null;
  scrollPanelConfig = { height: '97%', width: '100%' };
  private viewDestroyed = new Subject<void>();

  constructor(
    private router: Router,
  ) {
    this.setEcommerceCategories();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/member-ordering
  close(): void {
    this.closed.emit();
  }

  private initialize(): void {
    this.setCurrentPage();
  }

  private setCurrentPage(): void {
    this.router.events.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (event) => {
        this.close();
        if (event instanceof NavigationEnd) {
          this.currentPage = null;
          if (event.url.includes('ecommerce') || event.url.startsWith('/private-site/my-purchases')) {
            this.currentPage = 'ecommerce';
          } else if (event.url.includes('moving')) {
            this.currentPage = 'moving';
          } else if (event.url.includes('remodeling')) {
            this.currentPage = 'remodeling';
          } else if (event.url.includes('home-assistance')) {
            this.currentPage = 'home-assistance';
          } else if (event.url.includes('warehouse')) {
            this.currentPage = 'warehouse';
          } else if (event.url.includes('finance-and-insurance')) {
            this.currentPage = 'home';
          } else if (event.url.includes('private-site/profile')) {
            this.currentPage = 'my-profile';
          } else if (event.url.startsWith('/contact')) {
            this.currentPage = 'help-center';
          }  else if (event.url === '/private-site') {
            this.currentPage = 'home';
          }
        }
      }
    });
  }

  private setEcommerceCategories(): void {
    const { tree, labels } = constants.ecommerce.categories;
    this.categories = Object.keys(tree).map((code) => ({
      code,
      label: labels[code],
      children: tree[code].map((childrenCode) => ({
        code: childrenCode,
        label: labels[childrenCode],
        queryParams: {
          filters: JSON.stringify({ categories: { value: [childrenCode], operator: 'in', field: 'categories' } })
        },
      }))
    }));
  }
}