import { Injectable } from '@angular/core';
import { AdvertisingSpace } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { AdvertisingSpacesService } from './advertising-spaces.service';

@Injectable()
export class AdvertisingSpacesServiceMock implements AdvertisingSpacesService {
  constructor(
    private mockService: MockService,
  ) {}

  getExclusiveBenefits(): Observable<AdvertisingSpace[]> {
    return this.mockService.getAdvertisingSpaces('exclusive-benefit');
  }

  getPrivateSitePromotions(): Observable<AdvertisingSpace[]> {
    return this.mockService.getAdvertisingSpaces('private-site-promotion');
  }

  getPromotions(): Observable<AdvertisingSpace[]> {
    return this.mockService.getAdvertisingSpaces('promotion');
  }
}
