<section class="home-ecommerce-container">
  <section class="categories-container">
    <h3 class="title">Categorías</h3>
    @if (showCategories) {
      <p-carousel class="categories-carousel"
        contentClass="carousel display-navigators"
        [value]="categories"
        [numVisible]="7"
        [numScroll]="1"
        [showIndicators]="false"
        [responsiveOptions]="[
          { breakpoint: '1365px', numVisible: 7.5, numScroll: 1 },
          { breakpoint: '1050px', numVisible: 6.5, numScroll: 1 },
          { breakpoint: '900px', numVisible: 6, numScroll: 1 },
          { breakpoint: '650px', numVisible: 4.5, numScroll: 1 },
          { breakpoint: '500px', numVisible: 3.5, numScroll: 1 },
          { breakpoint: '450px', numVisible: 2.5, numScroll: 1 },
        ]" ngSkipHydration>
        <ng-template pTemplate="previousicon">
          <div class="navigation-container">
            <i class="icon-arrow-left"></i>
          </div>
        </ng-template>
        <ng-template let-category pTemplate="item">
          <a class="item-container" routerLink="/ecommerce/search" [queryParams]="category.queryParams">
            <img class="category-image" src="assets/images/categories/{{ category.image }}" alt="{{ 'X image' | transloco: { value: category.label } }}" />
            <h4 class="label">{{ category.label }}</h4>
          </a>
        </ng-template>
        <ng-template pTemplate="nexticon">
          <div class="navigation-container">
            <i class="icon-arrow-right"></i>
          </div>
        </ng-template>
      </p-carousel>
    }
  </section>
  <section class="banners-section">
    <h3 class="banners-section-title">Renueva tus espacios</h3>
    <div class="banners-container">
      <div class="main-banner" routerLink="/ecommerce/search" [queryParams]="mainBanners[0].queryParams">
        <!-- Enable when needed -->
        <!--<div class="floating-top"></div>
        <div class="floating-bottom"></div>-->
      </div>
      <div class="side-banners-container">
        <div class="side-banner top" routerLink="/ecommerce/search" [queryParams]="mainBanners[1].queryParams">
          <div class="banner-content">
            <h4 class="banner-title">{{ mainBanners[1].title }}</h4>
            @if (mainBanners[1].subtitle) {
              <h5 class="banner-subtitle">{{ mainBanners[1].subtitle }}</h5>
            }
          </div>
        </div>
        <div class="side-banner bottom" routerLink="/ecommerce/search" [queryParams]="mainBanners[2].queryParams">
          <div class="banner-content">
            <h4 class="banner-title">{{ mainBanners[2].title }}</h4>
            @if (mainBanners[2].subtitle) {
              <h5 class="banner-subtitle">{{ mainBanners[2].subtitle }}</h5>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
  @if (productCategories.length) {
    <section class="products-container">
      <app-products-carousel title="Recomendado para ti" [filters]="[{ field: 'categories', operator: 'in', value: productCategories }]" />
    </section>
  }
  <section class="products-container">
    <app-products-carousel title="Oportunidades" [filters]="[{ field: 'tags', operator: 'in', value: ['opportunity'] }]" [order]="{ field: 'updatedAt', direction: 'asc' }" href="/ecommerce/search" [queryParams]="{ filters: { categories: { field: 'tags', operator: 'in', value: 'opportunity' } } }" />
  </section>
  <section class="products-container">
    <app-products-carousel title="Productos destacados" [filters]="[{ field: 'tags', operator: 'in', value: ['highlighted'] }]" [order]="{ field: 'updatedAt', direction: 'asc' }" [pagination]="{ documentsPerPage: 20, page: highlightedProductsPage }" href="/ecommerce/search" [queryParams]="{ filters: { categories: { field: 'tags', operator: 'in', value: 'highlighted' } } }" />
  </section>
</section>
