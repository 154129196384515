<div class="search-container">
  <div class="search-wrapper">
    <div class="input-wrapper" [class.active]="query?.length">
      @if (showBackButton) {
        <button class="clear-button left" (click)="goBack()"><i class="pi pi-arrow-left"></i></button>
      }
      <input type="text" placeholder="{{ 'What do you want to search for?' | transloco }}" [(ngModel)]="query"  (keydown.enter)="searchQuery()" (click)="showOverlay()" #input />
      @if (query?.length) {
        <button class="clear-button right" (click)="clearQuery()"><i class="icon-cancel-fill"></i></button>
      }
    </div>
    <button class="search-button" [disabled]="!query?.length" (click)="searchQuery()"><i class="icon-search icon"></i></button>
  </div>
  @if (isOverlayVisible) {
    <div class="search-overlay" #overlay>
      @if (searchHistory?.length) {
        <div class="search-history-container">
          <div class="search-history-headers">
            <span class="title">{{ 'Search history' | transloco }}</span>
            <span class="delete-history" (click)="deleteSearchHistory()" >{{ 'Delete history' | transloco }}</span>
          </div>
          <div class="search-history-entries">
            @for (entry of searchHistory; track $index) {
              <div class="entry" (click)="searchQuery(entry)">
                <i class="icon-search"></i>
                <span class="entry-name">{{ entry }}</span>
              </div>
            }
          </div>
        </div>
      } @else {
        <div class="empty-search-history-container">
          <img src="/assets/illustrations/empty-search.svg" alt="{{ 'Illustration of empty search history.' | transloco }}"/>
          <p class="message">{{ 'You have no recent searches' | transloco }}</p>
          <div class="disclaimer">
            <p>{{ 'We help you find what you want for your home.' | transloco }}</p>
            <p>{{ 'Start looking for a product.' | transloco }}</p>
          </div>
        </div>
      }
    </div>
  }
</div>
