import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateComparison = (date: Date, operator: 'eq' | 'gt' | 'gte' | 'lt' | 'lte' ): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value as Date;
    let meetCondition = true;

    if (!controlValue) {
      return null;
    }

    switch (operator) {
    case 'eq':
      meetCondition = controlValue.getTime() === date.getTime();
      break;
    case 'gt':
      meetCondition = controlValue.getTime() > date.getTime();
      break;
    case 'gte':
      meetCondition = controlValue.getTime() >= date.getTime();
      break;
    case 'lt':
      meetCondition = controlValue.getTime() < date.getTime();
      break;
    case 'lte':
      meetCondition = controlValue.getTime() <= date.getTime();
      break;
    }

    return meetCondition ? null : { dateComparison: { operator, value: date } };
  };
};