import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProjectSimulationQuality } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { StepperModule } from 'primeng/stepper';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { CircularProgressComponent } from '../../../components/circular-progress/circular-progress.component';
import { LocalProjectSimulation } from '../../../models/local-project-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { SeoService } from '../../../services/seo/seo.service';
import { getImageByType } from '../../../utils/project-simulator.utils';

@Component({
  selector: 'app-step-4',
  standalone: true,
  imports: [
    ButtonComponent,
    CircularProgressComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './step-4.page.html',
  styleUrl: './step-4.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class Step4Page implements OnInit {
  bathroomForm: FormGroup<{
    accessories: FormControl<ProjectSimulationQuality | null>;
    surfaces: FormControl<ProjectSimulationQuality | null>;
  }>;
  integralRemodelingForm: FormGroup<{
    accessories: FormControl<ProjectSimulationQuality | null>;
  }>;
  kitchenForm: FormGroup<{
    accessories: FormControl<ProjectSimulationQuality | null>;
  }>;
  projectSimulation: LocalProjectSimulation | null;
  selectedImage = '';
  titleByType = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private location: Location,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.bathroomForm = new FormGroup({
      accessories: new FormControl<ProjectSimulationQuality | null>(null,[Validators.required]),
      surfaces: new FormControl<ProjectSimulationQuality | null>(null, [Validators.required]),
    });
    this.kitchenForm = new FormGroup({
      accessories: new FormControl<ProjectSimulationQuality | null>(null, [Validators.required]),
    });
    this.integralRemodelingForm = new FormGroup({
      accessories: new FormControl<ProjectSimulationQuality | null>(null, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.projectSimulation = await this.getCurrentSimulation();
    if (!this.projectSimulation || !this.projectSimulation.completedSteps.includes(1) || !this.projectSimulation.completedSteps.includes(2) || !this.projectSimulation.completedSteps.includes(3)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/project-simulator/step-1']);
      return;
    }
    const { type } = this.projectSimulation;
    this.selectedImage = getImageByType(type);
    if (type === 'bathroom') {
      this.bathroomForm.patchValue({
        accessories: this.projectSimulation.accessories ?? null,
        surfaces: this.projectSimulation.surfaces ?? null,
      });
    } else if (type === 'kitchen') {
      this.kitchenForm.patchValue({
        accessories: this.projectSimulation.accessories ?? null,
      });
    } else if (type === 'integral-remodeling') {
      this.integralRemodelingForm.patchValue({
        accessories: this.projectSimulation.accessories ?? null,
      });
    }
    if (type === 'bathroom' || type === 'kitchen') {
      this.titleByType = 'Remodeling standard';
    } else if (this.projectSimulation.type === 'integral-remodeling') {
      this.titleByType = 'Accessories standard';
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get isInvalid(): boolean {
    if (this.projectSimulation?.type === 'bathroom') {
      return this.bathroomForm.invalid;
    } else if (this.projectSimulation?.type === 'kitchen') {
      return this.kitchenForm.invalid;
    } else if (this.projectSimulation?.type === 'integral-remodeling') {
      return this.integralRemodelingForm.invalid;
    }
    return true;
  }

  goBack(): void {
    this.location.back();
  }

  async onSubmit(): Promise<void> {
    const currentSimulation = await this.getCurrentSimulation();
    if (!currentSimulation) {
      return;
    }
    if (!currentSimulation.completedSteps.includes(4)) {
      currentSimulation.completedSteps.push(4);
    }
    if (currentSimulation.type === 'bathroom' && this.bathroomForm.controls.accessories.value && this.bathroomForm.controls.surfaces.value) {
      currentSimulation.accessories = this.bathroomForm.controls.accessories.value;
      currentSimulation.surfaces = this.bathroomForm.controls.surfaces.value;
    }
    if (currentSimulation.type === 'kitchen' && this.kitchenForm.valid && this.kitchenForm.controls.accessories.value) {
      currentSimulation.accessories = this.kitchenForm.controls.accessories.value;
    }
    if (currentSimulation.type === 'integral-remodeling' && this.integralRemodelingForm.valid && this.integralRemodelingForm.controls.accessories.value) {
      currentSimulation.accessories = this.integralRemodelingForm.controls.accessories.value;
    }
    this.dataStorageService.set(DataKey.ProjectSimulation, currentSimulation);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/project-simulator/step-5']);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setFormControlValue(formControl: FormControl, value: any): void {
    formControl.setValue(value);
  }

  private getCurrentSimulation(): Promise<LocalProjectSimulation | null> {
    return firstValueFrom(this.dataStorageService.get<LocalProjectSimulation>(DataKey.ProjectSimulation));
  }
}
