<p-sidebar styleClass="purchases-sidebar" [(visible)]="isVisible" [blockScroll]="true" [position]="isMobile ? 'bottom' : 'right'" [showCloseIcon]="false" (onShow)="initialize()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <h3 class="title">Compras realizadas</h3>
      <div class="icon-container" (click)="close()">
        <i class="icon-close"></i>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="purchases-summary">
      <span class="quantity-label">{{ (paymentIntents.length > 1 ? 'X purchases in Mundo Hogar for' : '1 purchase in Mundo Hogar for') | transloco: { quantity: paymentIntents.length } }}</span>
      <span class="amount">{{ purchasesAmount | currencyFormatter }} {{ currency }}</span>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      <div class="orders-container">
        @for (order of orders; track order.id) {
          <div class="order-container">
            <div class="header">
              <span class="date">{{ order.createdAt | date: 'dd/MM/yyyy' }}</span>
              <span class="id">ID orden: {{ order.id }}</span>
            </div>
            @if (order.type === 'ecommerce') {
              @for (item of order.value.items!; track item.id) {
                <div class="ecommerce-item-container">
                  <img class="image" [src]="item.thumbnail" alt="Imagen del producto">
                  <div class="info-container">
                    <span class="brand">{{ item.brand }}</span>
                    <span class="name">{{ item.name }}</span>
                  </div>
                </div>
              }
            } @else if (order.type === 'remodeling') {
              <div class="remodeling-container">
                <h4 class="title">
                  @switch (order.value.additionalData['service']!) {
                    @case ('bathroom') {
                      Remodelación baño
                    } @case ('kitchen') {
                      Remodelación cocina
                    } @case ('fullhouse') {
                      Remodelación integral
                    } @case ('others') {
                      Otra remodelación
                    }
                  }
                </h4>
              </div>
            } @else if (order.type === 'moving') {
              <div class="moving-container">
                <h4 class="service-type">{{ order.value.additionalData['serviceType'] ?? 'Service to select' | transloco }}</h4>
                <p class="items">{{ 'X items' | transloco: { quantity: order.value.items!.length } }}</p>
                <p class="moving-date">{{ 'Moving day: X' | transloco: { date: order.value.movingDate ?? '' | date: 'dd/MM/yyyy' } }}</p>
              </div>
            } @else if (order.type === 'home-assistance') {
              <div class="home-assistance-container">
                <h4 class="plan-name">{{ order.value.plan!.name }}</h4>
                <p class="duration">
                  @switch (order.value.duration!) {
                    @case ('1-month') {
                      Membresía mensual
                    } @case ('6-months') {
                      Membresía semestral
                    } @case ('12-months') {
                      Membresía anual
                    }
                  }
                </p>
                <p class="coverage">{{ 'Coverage up to: X' | transloco: { date: order.value.finishAt! | date: 'dd/MM/yyyy' } }}</p>
              </div>
            }
          </div>
        }
      </div>
    </app-retryable-section>
  </ng-template>
  <ng-template pTemplate="footer">
    @if (meetsRequirements) {
      <app-button label="Enviar solicitud" styleClass="primary expand" [loading]="sendingRequest" [disabled]="sendingRequest" (action)="sendRequestHandler()" />
    } @else {
      <app-button label="Reingresar datos" styleClass="secondary expand" (action)="reEnterDataHandler()" />
    }
  </ng-template>
</p-sidebar>