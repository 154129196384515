import { Injectable } from '@angular/core';
import { Client } from 'typesense';
import { SearchParams, SearchParamsWithPreset } from 'typesense/lib/Typesense/Documents';
import { environment } from '../../../environments/environment';
import { DOCUMENTS_PER_PAGE_DEFAULT, PAGE_DEFAULT, Paginable, SearchConfig, SearchService } from './search.service';

@Injectable()
export class SearchServiceApi implements SearchService {
  private client: Client;

  constructor() {
    const { apiKey, url } = environment.typesenseConfig;
    this.client = new Client({
      nodes: [{ url }],
      apiKey,
    });
  }

  async get<T>(collection: string, id: string): Promise<T | null> {
    const document = await this.client.collections(collection)
      .documents(id)
      .retrieve();
    return document as T;
  }

  async search<T>(collection: string, config?: SearchConfig): Promise<Paginable<T>> {
    const params: SearchParams | SearchParamsWithPreset = {};
    if (config?.query?.value) {
      const { fields, value: q, weights } = config.query;
      params.q = q;
      params.query_by = fields;
      params.query_by_weights = weights;
    } else {
      params.q = '*';
    }
    if (config?.order) {
      const { field, direction } = config.order;
      params.sort_by = [`${field}:${direction ?? 'desc'}`, '_text_match:desc'];
    } else {
      params.sort_by = ['_text_match:desc'];
    }
    if (config?.filters) {
      const { filters } = config;
      let filterBy = '';
      filters.forEach((filter, index) => {
        if (filter.operator === 'in') {
          filterBy = `${filterBy}${filter.field}:=[${(filter.value as string[]).toString()}]`;
        } else {
          filterBy = `${filterBy}${filter.field}:${filter.operator}${filter.value.toString()}`;
        }
        if (index < filters.length - 1) {
          filterBy = `${filterBy} && `;
        }
      });
      params.filter_by = filterBy;
    }
    const documentsPerPage = config?.pagination?.documentsPerPage ?? DOCUMENTS_PER_PAGE_DEFAULT;
    const page = config?.pagination?.page ?? PAGE_DEFAULT;
    params.per_page = documentsPerPage;
    params.page = page;
    const search = await this.client.collections(collection)
      .documents()
      .search(params);
    const totalItems = search.found;
    const done = totalItems > 0 ? Math.ceil((totalItems / params.per_page)) === params.page : true;
    return {
      data: (search.hits ?? []).map((hit) => hit.document as T),
      done,
      getMore: () => this.search<T>(collection, {
        filters: config?.filters,
        query: {
          fields: config?.query?.fields ?? [],
          value: config?.query?.value ?? null,
          weights: config?.query?.weights ?? [],
        },
        order: config?.order,
        pagination: {
          documentsPerPage,
          page: page + 1,
        },
      }),
      size: documentsPerPage,
      totalItems,
    };
  }
}