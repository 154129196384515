<section class="my-movings-container">
  <div class="title-container">
    <i class="icon-arrow-left" routerLink="/private-site"></i>
    <h2 class="title">Mis mudanzas
      @if (movingOrders.length) {
        <span class="total-items">({{ movingOrders.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!movingOrders.length) {
      <div class="empty-movings animate__fadeIn">
        <img class="image" src="/assets/illustrations/empty-movings.svg" [alt]="'You don\'t have moving illustration' | transloco" />
        <h5 class="title">Aún no tienes mudanzas programadas/cotizadas</h5>
        <p class="subtitle">Cotiza tu próxima mudanza</p>
        <app-button label="Ir a mudanzas" styleClass="secondary" href="/moving" />
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
      @for (movingOrder of movingOrders; track $index) {
        <div class="order-container">
          <div class="products-container">
            <a class="product-container" routerLink="/private-site/my-movings/{{ movingOrder.id }}">
              @if (movingOrder.status === 'pending-payment') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status pending">Pago pendiente</span>
                    <span class="description">Servicio por seleccionar</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">Para continuar debes pagar el servicio</span>
                    <div class="button-container">
                      <app-button label="Ir a reservar la fecha" styleClass="secondary" (action)="goToReserveTheDate(movingOrder)" />
                    </div>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">Necesitas realizar el pago para reservar tu fecha de mudanza</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Ir a reservar la fecha" styleClass="tertiary" (action)="goToReserveTheDate(movingOrder)" />
                  <app-button label="Ver mudanza" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'waiting-confirmation') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status pending">Esperando confirmación</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">Tu solicitud de mudanza está siendo procesada</span>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">Te notificaremos en cuanto tu solicitud de mudanza esté lista para continuar</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Ver mudanza" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'pending-confirmation-visit') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status pending">Visita pendiente</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">Para continuar debes coordinar la visita de inspección</span>
                    <div class="button-container">
                      <app-button label="Coordinar visita en el chat" styleClass="secondary" (action)="goToChat(movingOrder)" />
                    </div>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">{{ 'You need to coordinate the inspection visit in the chat. In this chat you can manage everything about your move.' | transloco}}</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Coordinar visita en el chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="Ver mudanza" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'waiting-moving') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status">Todo listo</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">Todo listo para tu mudanza</span>
                    <div class="button-container">
                      <app-button label="Ir al chat" styleClass="secondary" (action)="goToChat(movingOrder)" />
                    </div>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">Todo listo para tu mudanza. Puedes visitar nuestra <a class="tips-page" routerLink="/">página de tips y recomendaciones.</a></span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Ir al chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="Ver mudanza" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'done') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status done">Finalizada</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                    <span class="status-description">Tu mudanza se realizó el día: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">Tu mudanza ya se realizó, si tienes consultas puedes dirigirte al chat de soporte</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Ir al chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="Ver mudanza" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              } @else if (movingOrder.status === 'voided') {
                <div class="column first">
                  <div class="right-container">
                    <span class="moving-status done">Anulado</span>
                    <span class="description">{{ movingOrder.additionalData['serviceType'] }}</span>
                    <span class="description">{{ 'X items' | transloco: { quantity: movingOrder.items.length } }}</span>
                    <span class="description">Día mudanza: {{ movingOrder.movingDate | date: 'dd/MM/yyyy' }}</span>
                  </div>
                </div>
                <div class="column second">
                  <span class="description-status">Tu mudanza ha sido anulada, si tienes consultas puedes dirigirte al chat de soporte</span>
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  <app-button label="Ir al chat" styleClass="tertiary" (action)="goToChat(movingOrder)" />
                  <app-button label="Ver mudanza" styleClass="secondary" href="/private-site/my-movings/{{ movingOrder.id }}" />
                </div>
              }
              <i class="icon-arrow-right"></i>
            </a>
          </div>
        </div>
      }
    </div>
    }
  </app-retryable-section>
</section>