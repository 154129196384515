<footer>
  <div class="footer-container">
    <div class="logo"></div>
    <div class="content-container">
      <div class="section">
        <div class="title">{{ 'What do you want to do?' | transloco }}</div>
        <ul>
          <li><a routerLink="/moving" id="btn-link-NaPXDUNr">{{ 'Moving home' | transloco }}</a></li>
          <li><a routerLink="/remodeling" id="btn-link-2dJOM8Xe">{{ 'Remodel my home' | transloco }}</a></li>
          <li><a routerLink="/ecommerce" id="btn-link-pWY0xIMk">{{ 'Home shopping' | transloco }}</a></li>
          <li><a routerLink="/home-assistance" id="btn-link-BT13gAOi">{{ 'Assistance plans' | transloco }}</a></li>
          <li><a routerLink="/warehouse" id="btn-link-W7knyd2-">{{ 'Rent warehouse' | transloco }}</a></li>
        </ul>
      </div>
      <div class="section">
        <h2 class="title">{{ 'Operation' | transloco }}</h2>
        <ul>
          <li><a routerLink="/what-is-mundo-hogar" id="btn-link-CCfZpHVV">{{ 'What is Mundo Hogar?' | transloco }}</a></li>
        </ul>
      </div>
      <div class="section">
        <h2 class="title">{{ 'Help' | transloco }}</h2>
        <ul>
          <li><a href="https://ayuda.mundohogar.com.mx/" target="_blank" id="btn-link-dFZ7WA6_">{{ 'Go to help center' | transloco }}</a></li>
          <li><a href="https://ayuda.mundohogar.com.mx/preguntas-frecuentes" target="_blank" id="btn-link-rsrrnpA4">{{ 'FAQ' | transloco }}</a></li>
          <li><a href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank" id="btn-link-6SlfJF4M">{{ 'Privacy policies' | transloco }}</a></li>
          <li><a href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank" id="btn-link-W6S-_PC8">{{ 'Terms and conditions' | transloco }}</a></li>
          <li><a routerLink="/complaints-and-returns" target="_blank" id="btn-link-VpFcM2w9">{{ 'Complaints and returns' | transloco }}</a></li>
          <li><a routerLink="/contact" id="btn-link-iUw-BwBq">{{ 'Contact' | transloco }}</a></li>
        </ul>
      </div>
      <div class="section">
        <div class="title">{{ 'Not a Santander customer?' | transloco }}</div>
        <ul>
          <li><a href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener" id="btn-link-CcLAb24S">{{ 'I want to be a customer' | transloco }}</a></li>
        </ul>
      </div>
      <!-- TODO: enable this later on -->
      <!--<div class="section">
        <div class="title">{{ 'Follow us on social networks' | transloco }}</div>
        <div class="social">
          <a href="https://www.facebook.com/" target="_blank" rel="noopener" id="btn-link-KZlCqcZ-"><i class="icon-facebook"></i></a>
          <a href="https://www.instagram.com/" target="_blank" rel="noopener" id="btn-link-Gl6rpAQM"><i class="icon-instagram"></i></a>
        </div>
      </div>-->
    </div>
  </div>
  <div class="version">MHv{{ appVersionCode }}</div>
</footer>
