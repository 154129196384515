import { Injectable } from '@angular/core';
import { ContactForm } from '@homein-hogar-server';
import * as faker from 'faker';
import { Observable, of } from 'rxjs';
import { triggerRandomError } from '../../utils/mock.utils';
import { FormsService } from './forms.service';

@Injectable()
export class FormsServiceMock implements FormsService {
  getContactForms(): Observable<ContactForm[]> {
    return of([]);
  }

  submitConsumerCreditDiscount(): Promise<void> {
    return this.commonMock();
  }

  submitContactEcommerce(): Promise<void> {
    return this.commonMock();
  }

  submitContactHomeAssistance(): Promise<void> {
    return this.commonMock();
  }

  submitContactMoving(): Promise<void> {
    return this.commonMock();
  }

  submitContactPlatform(): Promise<void> {
    return this.commonMock();
  }

  submitContactRemodeling(): Promise<void> {
    return this.commonMock();
  }

  submitContactWarehouse(): Promise<void> {
    return this.commonMock();
  }

  submitInvoice(): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve(faker.random.alphaNumeric(20));
      }, 2000);
    });
  }

  submitInvoiceAgain(): Promise<void> {
    return this.commonMock();
  }

  private commonMock(): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        resolve();
      }, 2000);
    });
  }
}