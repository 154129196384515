import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { ButtonComponent } from '../../../components/button/button.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { SeoService } from '../../../services/seo/seo.service';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'app-request-home-assistance',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ReminderComponent,
    RouterLink,
    SharedModule,
    TranslocoPipe,
  ],
  templateUrl: './request-home-assistance.page.html',
  styleUrl: './request-home-assistance.page.scss',
})
export class RequestHomeAssistancePage implements OnInit {
  email: string;
  planId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private seoService: SeoService,
    private toastService: ToastService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private initialize(): void {
    const email = this.activatedRoute.snapshot.queryParamMap.get('email');
    const planId = this.activatedRoute.snapshot.queryParamMap.get('planId');
    if (!email || !planId) {
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
      this.errorReportingService.log('RequestHomeAssistancePage.initialize()', 'get-query-param-values', new Error(email ? 'planId is missing' : 'email is missing'));
      return;
    }
    this.email = email;
    this.planId = planId;
  }
}
