<main class="main-global-container">
  <div class="clouds">
    <img class="cloud first" src="assets/images/cloud1.svg" alt="ícono nube" loading="lazy" />
    <img class="cloud second" src="assets/images/cloud2.svg" alt="ícono nube" loading="lazy" />
    <img class="cloud third" src="assets/images/cloud3.svg" alt="ícono nube" loading="lazy" />
    <img class="cloud fourth" src="assets/images/cloud2.svg" alt="ícono nube" loading="lazy" />
  </div>
  <section class="main">
    <div class="hero-container">
      <h1 class="main-title">Conecta con tu hogar en un solo lugar</h1>
      <p class="main-subtitle">¿Cómo podemos ayudarte hoy?</p>
      <div class="vertical-boxes-container">
        <a routerLink="/moving" class="vertical-business-container" id="btn-link-DXgLHFoj">
          <div class="circle-icon-container">
            <i class="icon-truck"></i>
          </div>
          <span class="vertical-name">Mudarme de hogar</span>
        </a>
        <a routerLink="/remodeling" class="vertical-business-container" id="btn-link-xdIEf_v3">
          <div class="circle-icon-container">
            <i class="icon-remodeling"></i>
          </div>
          <span class="vertical-name">Remodelar mi hogar</span>
        </a>
        <a routerLink="/warehouse" class="vertical-business-container" id="btn-link-U7Iu_A0I">
          <div class="circle-icon-container">
            <i class="icon-warehouses"></i>
          </div>
          <span class="vertical-name warehouse">Rentar bodega</span>
        </a>
        <a routerLink="/ecommerce" class="vertical-business-container" id="btn-link-ZNECBe7z">
          <div class="circle-icon-container">
            <i class="icon-armchair"></i>
          </div>
          <span class="vertical-name">Compras para el hogar</span>
        </a>
        <a routerLink="/home-assistance" class="vertical-business-container" id="btn-link-2SHue-3K">
          <div class="circle-icon-container">
            <i class="icon-home-assistance"></i>
          </div>
          <span class="vertical-name">Planes de asistencia</span>
        </a>
      </div>
      <div class="sponsored-by">
        <p class="sponsor">Sitio de HomeIn SPA para el otorgamiento de beneficios exclusivos a clientes Santander.</p>
        <img class="image" src="assets/images/santander-red-logo.svg" width="68" height="11" />
      </div>
    </div>
  </section>
</main>
<section class="terms-banner">
  <app-terms-banner page="home" />
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">¿Por qué Mundo Hogar?</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">Todo en un mismo lugar</h4>
          <span class="description">Encuentra todo lo que necesitas para tu hogar, en un mismo portal 100% online.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">Beneficios exclusivos</h4>
          <span class="description">Alternativas de financiamiento exclusivas para clientes Santander y mucho más.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">Los mejores proveedores</h4>
          <span class="description">Proveedores calificados, servicios garantizados y métodos de pagos seguros para ti.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">Simple y transparente</h4>
          <span class="description">Gestiona y haz el seguimiento online de tus proyectos en pocos clics.</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <p class="customer-benefit">Beneficios exclusivos para clientes Santander. Si aún no eres cliente, haz <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener" id="btn-link-CDzvVTWu">clic aquí.</a><p>
    </div>
  </div>
</article>
<app-retryable-section [error]="errorLoadingAdvertisingSpaces" [loading]="loadingAdvertisingSpaces" (retry)="getAdvertisingSpaces()">
  <section class="promotions">
    @if (promotions.length) {
      <p-carousel class="promotions-carousel"
                  [value]="promotions"
                  [numVisible]="1"
                  [numScroll]="1"
                  [circular]="true"
                  [autoplayInterval]="5000"
                  [showNavigators]="false">
        <ng-template let-item pTemplate="item">
          <div class="carousel-container">
            <div class="carousel-item">
              <div class="text-content">
                <h3 class="title">{{ item.title }}</h3>
                <app-button [label]="item.buttonLabel" innerId="btn-tn0_sYYH-{{ item.index }}" [href]="item.buttonUrl" [context]="item.context" styleClass="primary negative" />
              </div>
              <div class="image-container">
                <img class="image" [src]="item.image" alt="{{ item.title }}" loading="lazy" />
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    }
  </section>
</app-retryable-section>
<section class="products-of-interest">
  <app-products-carousel innerId="WSSEedWp" title="Productos destacados" [filters]="[{ field: 'tags', operator: 'in', value: ['highlighted'] }]" [order]="{ field: 'updatedAt', direction: 'asc' }" [pagination]="{ documentsPerPage: 20, page: highlightedProductsPage }" href="/ecommerce/search" [queryParams]="{ filters: { categories: { field: 'tags', operator: 'in', value: 'highlighted' } } }" />
</section>
<section class="opportunities">
  <div class="opportunities-container">
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">Solicita tu tarjeta y paga todo hasta en 36 mensualidades</h4>
        <app-button innerId="btn-f-U2xnfC" label="Solicitar" styleClass="secondary" context="credit-card" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener" />
      </div>
      <div class="right-card-container">
        <img class="img" src="assets/images/banner-opportunity-01.png" alt="Imagen de oportunidad" loading="lazy" />
      </div>
    </div>
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">Simula esa remodelación que tanto sueñas</h4>
        <app-button innerId="btn-6LpKsuok" label="Simular" styleClass="secondary" context="project-simulator" routerLink="/project-simulator/step-1" />
      </div>
      <div class="right-card-container">
        <img class="img" src="assets/images/second-opportunity.jpg" alt="Imagen de oportunidad" loading="lazy" />
      </div>
    </div>
  </div>
</section>
<section class="exclusive-benefits-section" (wheel)="onWheel($event, carousel)">
  <div class="exclusive-benefits-container">
    <div class="title-container">
      <h2 class="title">Beneficios destacados para ti</h2>
      <p class="subtitle">Ofertas exclusivas para clientes Santander</p>
      <hr class="underline">
    </div>
    <app-retryable-section [error]="errorLoadingAdvertisingSpaces" [loading]="loadingAdvertisingSpaces" (retry)="getAdvertisingSpaces()">
      @if (exclusiveBenefits.length) {
        <p-carousel #carousel class="carousel-exclusive-benefits"
          [value]="exclusiveBenefits"
          [numVisible]="3.125"
          [numScroll]="1"
          [autoplayInterval]="5000"
          [showNavigators]="false"
          [responsiveOptions]="[
            { breakpoint: '1365px', numVisible: 4.125, numScroll: 1 },
            { breakpoint: '1250px', numVisible: 3.875, numScroll: 1 },
            { breakpoint: '1150px', numVisible: 3.5, numScroll: 1 },
            { breakpoint: '1050px', numVisible: 3.2, numScroll: 1 },
            { breakpoint: '950px', numVisible: 2.7, numScroll: 1 },
            { breakpoint: '850px', numVisible: 2.5, numScroll: 1 },
            { breakpoint: '750px', numVisible: 2.2, numScroll: 1 },
            { breakpoint: '650px', numVisible: 1.8, numScroll: 1 },
            { breakpoint: '550px', numVisible: 1.5, numScroll: 1 },
            { breakpoint: '450px', numVisible: 1.3, numScroll: 1 },
            { breakpoint: '400px', numVisible: 1.2, numScroll: 1 },
            { breakpoint: '380px', numVisible: 1.1, numScroll: 1 }
          ]" (onPage)="onPageChange($event)">
          <ng-template let-item pTemplate="item">
            <div class="card" (mouseenter)="stopAutoplay(carousel)" (mouseleave)="startAutoplay(carousel)">
              <div class="image" [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
              </div>
              <div class="card-content">
                <h6 class="title">{{ item.title }}</h6>
                <div class="icon-text">
                  <i class="icon-circle-checkmark"></i>
                  <span class="benefit-text">{{ item.description }}</span>
                </div>
              </div>
              <div class="card-information">
                <hr class="divider">
                <div class="button-container">
                  <app-button innerId="btn-KoYSlxnk-{{ item.index }}" [label]="item.buttonLabel" [href]="item.buttonUrl" [context]="item.context" styleClass="primary dark" />
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      }
    </app-retryable-section>
  </div>
</section>
<article class="faq-article">
  <h2 class="title">Preguntas frecuentes</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="¿Qué es Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Mundo Hogar es un portal exclusivo para clientes de Banco Santander que reúne en un mismo lugar beneficios únicos en servicios para el hogar. Es la primera plataforma digital del mercado donde puedes cotizar, contratar servicios y productos para el hogar, al mismo tiempo que accedes al mejor financiamiento.</p>
          <p class="text">Si ya eres cliente de Banco Santander, <a class="link" id="btn-link-yoPphZYP" routerLink="/enrollment/sign-up">activa tu cuenta</a> y empieza a disfrutar de tus beneficios exclusivos.</p>
          <p class="text">Si quieres ser cliente Santander, <a class="link" id="btn-link-QUV-dJ9L" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener">hazte cliente aquí</a></p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué puedo encontrar en Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">La remodelación que tu vivienda necesita, compra de muebles, mudanzas, bodegaje, y planes de asistencia para disfrutar tu hogar sin preocupaciones.</p>
          <p class="text">Además, tendrás acceso ilimitado a simuladores hipotecarios y de proyectos de remodelación, 100% online y sin costo.</p>
          <p class="text">Financiamiento exclusivo para clientes Santander</p>
        </div>
        <div class="text-container">
          <ul>
            <li><strong>Créditos Hipotecarios:</strong> Opciones tradicionales y un crédito que permite financiar la compra y remodelación de una vivienda de una vez.</li>
            <li><strong>Créditos Personales:</strong> Consigue una reducción en tu tasa de interés y hasta 60 meses de plazo de pago (solo aplica para compras en el portal).</li>
            <li><strong>Pago con Tarjeta de Crédito:</strong> Hasta 36 mensualidades en todos los servicios y productos para tu hogar.</li>
          </ul>
        </div>
        <div class="text-container">
          <p class="text">Si quieres ser cliente Santander, <a class="link" id="btn-link-kwF6B0Wu" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener">hazte cliente en pocos clics</a></p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Para quién es Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Mundo Hogar está disponible para todos los clientes de Banco Santander México. Si eres cliente del banco, este portal te ofrece opciones personalizadas de servicios para el hogar y alternativas de financiamientos a tu medida. Como cliente podrás pagar todo en 36 mensualidades, simular tu remodelación en pocos clics y acceder a promociones exclusivas. Al crear tu cuenta en Mundo Hogar, tendrás que identificarte como cliente Santander ingresando los primeros 6 dígitos de tu tarjeta de débito o crédito.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Por qué Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Mundo Hogar es tu aliado perfecto para transformar y personalizar tu hogar, con el respaldo y confianza de Banco Santander.</p>
        </div>
        <div class="text-container">
          <p class="text"><strong>Beneficios exclusivos para clientes Santander:</strong></p>
          <ul>
            <li>Acompañamiento Integral: Apoyo en cada reto asociado al hogar.</li>
            <li>Experiencia Unificada y Digital: Todo en un solo lugar, de manera simple y digital.</li>
            <li>Confianza y transparencia: Procesos claros y confiables.</li>
            <li>Personalización de la Oferta: Soluciones adaptadas a tus necesidades.</li>
            <li>Alternativas Únicas de Financiamiento: Opciones exclusivas.</li>
            <li>Mejores Precios del Mercado: Garantía de las mejores ofertas.</li>
          </ul>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Cómo funciona Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <ol>
            <li>Activa tu cuenta: Regístrate en Mundo Hogar usando tus credenciales de Apple, Google o tu correo electrónico. Recibirás un correo de validación para activar tu cuenta.</li>
            <li>Confirma tus datos: Verifica tu información para asegurarnos de entregar cotizaciones, presupuestos, ofertas y beneficios personalizados.</li>
            <li>Identifícate como cliente Santander: Ingresa los 6 primeros dígitos de alguna de tus tarjetas Santander para acceder a los beneficios exclusivos…¡Ahora estás listo para comprar productos o contratar servicios en Mundo Hogar!</li>
          </ol>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">Visita nuestro <a class="link" id="btn-link-YSje514Q" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">Centro de ayuda</a> para más información</h5>
</article>
