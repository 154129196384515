<section class="project-simulator-container">
  <div>
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">Atrás</span>
      <span class="mobile-text">Simula tu proyecto</span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">Simula el costo del proyecto que tienes en mente</h5>
      <app-circular-progress [currentStep]="4" [numberSteps]="6" [stepTitle]="titleByType | transloco" stepSubtitle="Siguiente: Superficie de la remodelación"></app-circular-progress>
    </div>
    <div class="bottom-container animate__fadeIn">
      @if (projectSimulation && projectSimulation.type === 'bathroom') {
        <form [formGroup]="bathroomForm" class="project-simulation-types-container">
          <h2 class="title">Selecciona el estándar de los acabados</h2>
          <p class="subtitle">Cada opción ofrece distintos niveles de calidad de materiales y estética en los acabados de tus baños.</p>
          <div class="project-simulation-type-container" id="btn-nonhJ2Wh" [class.selected]="bathroomForm.controls.surfaces.value === 'economic'" (click)="setFormControlValue(bathroomForm.controls.surfaces, 'economic')">
            <i class="icon-piggy-bank"></i>
            <span class="text">Económico</span>
          </div>
          <div class="project-simulation-type-container" id="btn-iM6z0XXp" [class.selected]="bathroomForm.controls.surfaces.value === 'intermediate'" (click)="setFormControlValue(bathroomForm.controls.surfaces, 'intermediate')">
            <i class="icon-price-award"></i>
            <span class="text">Intermedio</span>
          </div>
          <div class="project-simulation-type-container" id="btn-1aUITRH2" [class.selected]="bathroomForm.controls.surfaces.value === 'premium'" (click)="setFormControlValue(bathroomForm.controls.surfaces, 'premium')">
            <i class="icon-trophy"></i>
            <span class="text">Premium</span>
          </div>
          <h2 class="title">Selecciona el estándar de los accesorios</h2>
          <p class="subtitle">Cada opción ofrece distintos niveles de calidad en los accesorios de tus baños (muebles, lavabo y grifería).</p>
          <div class="project-simulation-type-container" id="btn-MgF4XKkb" [class.selected]="bathroomForm.controls.accessories.value === 'economic'" (click)="setFormControlValue(bathroomForm.controls.accessories, 'economic')">
            <i class="icon-piggy-bank"></i>
            <span class="text">Económico</span>
          </div>
          <div class="project-simulation-type-container" id="btn-kaxLkClX" [class.selected]="bathroomForm.controls.accessories.value === 'intermediate'" (click)="setFormControlValue(bathroomForm.controls.accessories, 'intermediate')">
            <i class="icon-price-award"></i>
            <span class="text">Intermedio</span>
          </div>
          <div class="project-simulation-type-container" id="btn-Zr4-1J-A" [class.selected]="bathroomForm.controls.accessories.value === 'premium'" (click)="setFormControlValue(bathroomForm.controls.accessories, 'premium')">
            <i class="icon-trophy"></i>
            <span class="text">Premium</span>
          </div>
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'kitchen') {
        <form [formGroup]="kitchenForm" class="project-simulation-types-container">
          <h2 class="title">Selecciona el estándar para la cubierta</h2>
          <p class="subtitle">Cada opción ofrece distintos niveles de calidad de materiales y estética para la cubierta de la cocina.</p>
          <div class="project-simulation-type-container" id="btn-KokJYu8L" [class.selected]="kitchenForm.controls.accessories.value === 'economic'" (click)="setFormControlValue(kitchenForm.controls.accessories, 'economic')">
            <i class="icon-piggy-bank"></i>
            <span class="text">Económico</span>
          </div>
          <div class="project-simulation-type-container" id="btn-fOgHoVfl" [class.selected]="kitchenForm.controls.accessories.value === 'intermediate'" (click)="setFormControlValue(kitchenForm.controls.accessories, 'intermediate')">
            <i class="icon-price-award"></i>
            <span class="text">Intermedio</span>
          </div>
          <div class="project-simulation-type-container" id="btn-jZZZ5fmr" [class.selected]="kitchenForm.controls.accessories.value === 'premium'" (click)="setFormControlValue(kitchenForm.controls.accessories, 'premium')">
            <i class="icon-trophy"></i>
            <span class="text">Premium</span>
          </div>
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'integral-remodeling') {
        <form [formGroup]="integralRemodelingForm" class="project-simulation-types-container">
          <h2 class="title">Selecciona el estándar de los accesorios</h2>
          <p>Cada opción ofrece distintos niveles de calidad de materiales y estética en los accesorios.</p>
          <div class="project-simulation-type-container" id="btn-x0WzJ2HE" [class.selected]="integralRemodelingForm.controls.accessories.value === 'economic'" (click)="setFormControlValue(integralRemodelingForm.controls.accessories, 'economic')">
            <i class="icon-piggy-bank"></i>
            <span class="text">Económico</span>
          </div>
          <div class="project-simulation-type-container" id="btn-lgEl7wKL" [class.selected]="integralRemodelingForm.controls.accessories.value === 'intermediate'" (click)="setFormControlValue(integralRemodelingForm.controls.accessories, 'intermediate')">
            <i class="icon-price-award"></i>
            <span class="text">Intermedio</span>
          </div>
          <div class="project-simulation-type-container" id="btn-F8aMThky" [class.selected]="integralRemodelingForm.controls.accessories.value === 'premium'" (click)="setFormControlValue(integralRemodelingForm.controls.accessories, 'premium')">
            <i class="icon-trophy"></i>
            <span class="text">Premium</span>
          </div>
        </form>
      }
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="Ilustración de la opción seleccionada" />
        <div class="continue-button-container">
          <app-button innerId="btn-VcFMgXbK" label="Continuar" styleClass="primary expand" (action)="onSubmit()" [disabled]="isInvalid" />
        </div>
      </div>
    </div>
  </div>
</section>