import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcommerceOrder, HomeAssistanceOrder, MovingOrder, PaymentIntent, RemodelingOrder } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom } from 'rxjs';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { EcommerceOrdersService } from '../../services/ecommerce-orders/ecommerce-orders.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { HomeAssistanceOrdersService } from '../../services/home-assistance-orders/home-assistance-orders.service';
import { MovingOrdersService } from '../../services/moving-orders/moving-orders.service';
import { RemodelingOrdersService } from '../../services/remodeling-orders/remodeling-orders.service';
import { ButtonComponent } from '../button/button.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

type Order = {
  createdAt: Date;
  id: string;
} & ({
  type: 'ecommerce';
  value: EcommerceOrder;
} | {
  type: 'home-assistance';
  value: HomeAssistanceOrder;
} | {
  type: 'moving';
  value: MovingOrder;
} | {
  type: 'remodeling';
  value: RemodelingOrder;
});

@Component({
  selector: 'app-purchases-sidebar',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    SidebarModule,
    TranslocoModule,
  ],
  templateUrl: './purchases-sidebar.component.html',
  styleUrl: './purchases-sidebar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PurchasesSidebarComponent {
  @Input({ required: true }) creditAmount: number;
  @Input({ required: true }) currency: string;
  @Input({ required: true }) meetsRequirements: boolean;
  @Input({ required: true }) paymentIntents: PaymentIntent[];
  @Input({ required: true }) purchasesAmount: number;
  @Input({ required: true }) sendingRequest: boolean;
  @Output() reEnterData = new EventEmitter<boolean>();
  @Output() sendRequest = new EventEmitter<boolean>();
  protected errorLoading = false;
  protected isMobile = false;
  protected isVisible = false;
  protected loading = false;
  protected orders: Order[] = [];

  constructor(
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    private movingOrdersService: MovingOrdersService,
    private remodelingOrdersService: RemodelingOrdersService,
  ) {}

  close(): void {
    this.isVisible = false;
  }

  open(): void {
    this.isVisible = true;
  }

  protected async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    this.orders = [];
    try {
      const ordersPromises = this.paymentIntents.map((paymentIntent) => {
        const { orderId } = paymentIntent;
        switch (paymentIntent.source) {
        case 'ecommerce':
          return firstValueFrom(this.ecommerceOrdersService.get(orderId));
        case 'home-assistance':
          return firstValueFrom(this.homeAssistanceOrdersService.get(orderId));
        case 'moving':
          return firstValueFrom(this.movingOrdersService.get(orderId));
        case 'remodeling':
          return firstValueFrom(this.remodelingOrdersService.get(orderId));
        default:
          throw new Error(`Unhandled payment intent source ${paymentIntent.source}`);
        }
      });
      const results = await Promise.allSettled(ordersPromises);
      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          this.errorReportingService.log('PurchasesSidebarComponent.initialize()', `get-${this.paymentIntents[index].source}-order`, result.reason);
          this.errorLoading = true;
          return;
        }

        const order = result.value;

        if (!order) {
          return;
        }

        const source = this.paymentIntents[index].source;

        switch (source) {
        case 'ecommerce':
          this.orders.push({
            createdAt: order.createdAt,
            id: order.id,
            value: order as EcommerceOrder,
            type: 'ecommerce',
          });
          break;
        case 'home-assistance':
          this.orders.push({
            createdAt: order.createdAt,
            id: order.id,
            value: order as HomeAssistanceOrder,
            type: 'home-assistance',
          });
          break;
        case 'moving':
          this.orders.push({
            createdAt: order.createdAt,
            id: order.id,
            value: order as MovingOrder,
            type: 'moving',
          });
          break;
        case 'remodeling':
          this.orders.push({
            createdAt: order.createdAt,
            id: order.id,
            value: order as RemodelingOrder,
            type: 'remodeling',
          });
          break;
        default:
          throw new Error(`Unhandled payment intent source ${source}`);
        }
      });
    } catch (error) {
      this.errorReportingService.log('PurchasesSidebarComponent.initialize()', 'get-orders', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  protected reEnterDataHandler(): void {
    this.close();
    this.reEnterData.next(true);
  }

  protected sendRequestHandler(): void {
    this.sendRequest.next(true);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }
}
