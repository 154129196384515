<p-sidebar [(visible)]="visible" [position]="isMobile ? 'bottom' : 'right'" showCloseIcon="false" blockScroll="true" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container" [class.hide]="sendingResult">
      <h2 class="title">
        @if (type === 'simulation') {
          {{ resultSent ? 'Simulación enviada' : 'Enviar simulación' }}
        } @else {
          {{ resultSent ? 'Solicitud enviada' : 'Enviar solicitud' }}
        }
      </h2>
      <div class="icon-container">
        <i class="icon icon-close" (click)="close()"></i>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    @if (sendingResult) {
      <div class="progress-indicator-container">
        <p-progressSpinner />
        <p class="text">Procesando...</p>
      </div>
    } @else {
      <div class="content-container">
        <img class="illustration" src="{{ resultSent ? '/assets/illustrations/success-check.svg' : '/assets/illustrations/whatsapp-logo-floating-on-phone.svg' }}" alt="{{ resultSent ? 'Imagen verificación de éxito' : 'Imagen de logo de WhatsApp sobre teléfono' }}" />
        <h3 class="title">
          @if (type === 'simulation') {
            {{ resultSent ? '¡Enviamos tu simulación!' : 'Enviaremos tu simulación' }}
          } @else {
            {{ resultSent ? '¡Enviamos tu solicitud de contacto!' : 'Enviaremos tu solicitud de contacto' }}
          }
        </h3>
        <p class="description">
          @if (type === 'simulation') {
            {{ resultSent ? 'Tu simulación fue enviada por WhatsApp al siguiente número telefónico.' : 'Tu simulación será enviada por WhatsApp al siguiente número telefónico:' }}
          } @else {
            {{ resultSent ? 'Tu confirmación de solicitud fue enviada por WhatsApp al siguiente número telefónico.' : 'Tu confirmación de solicitud será enviada por WhatsApp al siguiente número telefónico:' }}
          }
        </p>
        <p class="phone-number" data-private>{{ formattedPhoneNumber }}</p>
        @if (type === 'simulation') {
          <p class="terms-and-conditions">Al continuar estás aceptando nuestro<br><a href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" class="link" rel="noopener" target="_blank">Aviso de Privacidad</a> y <a href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" class="link" target="_blank" rel="noopener">Términos y Condiciones</a>. Este sitio está protegido por reCAPTCHA y aplican las <a class="link" href="https://policies.google.com/privacy" target="_blank" rel="noopener">Políticas de Privacidad</a> y los <a class="link" href="https://policies.google.com/terms" target="_blank" rel="noopener">Términos de Servicio</a> de Google.</p>
        }
        <p class="change-number-container">Lo ingresé mal. <span class="link" (click)="emitChangePhoneNumber()">Cambiar número.</span></p>
      </div>
    }
  </ng-template>
  <ng-template pTemplate="footer">
    @if (!sendingResult) {
      @if (!resultSent) {
        <div class="action-button-container" [class.hide]="sendingResult">
          <app-button innerId="btn-6AbMt-4d" [label]="type === 'simulation' ? 'Enviar simulación' : 'Enviar solicitud'" styleClass="primary expand" (action)="send()" />
        </div>
      } @else {
        <div class="actions-buttons-container">
          @if (type === 'simulation') {
            <app-button innerId="btn-FKpElM70" label="Volver a simular" styleClass="secondary" (action)="emitDoActionAgain()" />
          } @else {
            <app-button innerId="btn-96gsIyJx" [label]="goToLabel" styleClass="secondary" (action)="emitGoTo()" />
          }
          <app-button innerId="btn-9Xa3DdpV" label="Ir a WhatsApp" styleClass="primary" href="{{ 'https://wa.me/' + platformPhoneNumber }}" target="_blank" rel="noopener" />
        </div>
      }
    }
  </ng-template>
</p-sidebar>