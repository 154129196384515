<section class="main">
  <div class="main-container">
    <div class="right-side">
      <div class="title-container">
        <p class="on-title-text">Beneficios exclusivos para clientes <strong>Santander</strong></p>
        <h1 class="title">Gánale a los imprevistos en casa</h1>
      </div>
      <div class="button-container">
        <app-button label="Revisar planes" styleClass="primary" icon="home-assistance" (action)="goToPlans()" />
      </div>
    </div>
    <div class="left-side">
      <img class="main-image" src="assets/images/home-assistance-main.svg" alt="Imagen de asistencias del hogar" />
    </div>
  </div>
</section>
<section>
  <app-terms-banner page="home-assistance" />
</section>
<section class="assistance-request-section">
  <div class="container">
    <div class="title-container">
      <h2 class="title">Contrata tu asistencia en pocos clics</h2>
      <hr class="underline">
    </div>
    <div class="request-container">
      <ul class="requests">
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>Elige el plan ideal para ti y tu hogar</li>
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>Contrátalo 100% online y paga como prefieras</li>
        <li class="request"><i class="icon-checkmark-in-a-circle"></i>Relájate, ¡ya puedes solicitar asistencia 24/7!</li>
      </ul>
      <div class="button-container">
        <app-button label="Elegir plan" styleClass="secondary expand" (action)="goToPlans()" />
      </div>
    </div>
  </div>
</section>
<section class="exclusive-benefits-section" id="exclusive-benefits-section">
  <div class="exclusive-benefits-container">
    <div class="title-container">
      <h2 class="title">¿Qué plan vas a elegir hoy?</h2>
      <hr class="underline">
    </div>
    <app-retryable-section [error]="errorLoading" [loading]="loading" (retry)="initialize()">
      <p-carousel class="carousel-exclusive-benefits"
                  [value]="homeAssistancePlans"
                  [numVisible]="3"
                  [numScroll]="1"
                  [autoplayInterval]="0"
                  [showNavigators]="false"
                  [responsiveOptions]="[
                    { breakpoint: '900px', numVisible: 2.6, numScroll: 1 },
                    { breakpoint: '800px', numVisible: 2.2, numScroll: 1 },
                    { breakpoint: '700px', numVisible: 1.8, numScroll: 1 },
                    { breakpoint: '600px', numVisible: 1.6, numScroll: 1 },
                    { breakpoint: '500px', numVisible: 1.3, numScroll: 1 },
                    { breakpoint: '400px', numVisible: 1.2, numScroll: 1 },
                  ]">
        <ng-template let-plan pTemplate="item">
          <div class="card">
            <div class="image" [ngStyle]="{'background-image': 'url(/assets/images/home-assistance-plans/card-' + plan.id + '.png)'}">
              @if (plan.recommended) {
                <span class="offer-type">Mejor valorado</span>
              }
            </div>
            <div class="card-content">
              <h6 class="title">{{ plan.name }}</h6>
              <h5 class="pricing">Desde {{ plan.pricing.external['1-month']['debit'].price | currencyFormatter: plan.currency }}<span class="currency">{{ plan.currency }} </span><span class="currency">Mensual</span></h5>
              <div class="icon-text">
                <i class="icon-checkmark-in-a-circle"></i>
                <span class="benefit-text">{{ plan.description }}</span>
              </div>
            </div>
            <div class="card-information">
              <hr class="divider">
              <div class="button-container">
                <app-button label="Ver plan" [href]="'/home-assistance/' + plan.id" styleClass="primary" />
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </app-retryable-section>
  </div>
</section>
<section class="assistance-benefits">
  <div class="container">
    <div class="left-container">
      <img class="image" src="assets/images/assistance-benefits.svg" alt="Imagen de los beneficios de asistencia" />
    </div>
    <div class="right-container">
      <div class="title-container">
        <h2 class="title">Beneficios de contratar una asistencia</h2>
      </div>
      <div class="benefits-container">
        <ol class="benefits">
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>Soluciones que dan bienestar y tranquilidad</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>En cualquier momento y desde cualquier lugar</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>Protege cada espacio de tu hogar y a los que más quieres</li>
          <li class="benefit"><i class="icon-checkmark-in-a-circle"></i>Haz tu vida más fácil dejando tu hogar en nuestras manos</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<section class="why-trust-assistance-section">
  <div class="container">
    <div class="left-container">
      <div class="title-container">
        <span class="title">¿Por qué confiamos en<img class="ike-logo" alt="Logo ike" src="assets/images/ike-logo.svg" /> ?</span>
        <hr class="underline">
      </div>
    </div>
    <div class="right-container">
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">17 años contigo</h4>
          <p class="description">Llevamos 17 años acompañándote a ti, a tu familia y a tus mascotas, ofreciéndote soluciones que te brindan tranquilidad en cada imprevisto del hogar.</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">2 millones de usuarios</h4>
          <p class="description">Más de 2 millones de usuarios confirman nuestro compromiso con la calidad y profesionalidad.</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">26,000 servicios mensuales</h4>
          <p class="description">Cada mes, más de 26,000 personas confían en nosotros para resolver sus necesidades del hogar con rapidez y eficiencia, asegurando que tu hogar siempre esté en perfectas condiciones.</p>
        </div>
      </div>
      <div class="trust-content">
        <i class="icon icon-checkmark-in-a-circle"></i>
        <div class="trust">
          <h4 class="title">+ de 1,000 prestadores</h4>
          <p class="description">Contamos con una red de más de 1,000 prestadores listos para ayudarte en cualquier momento, garantizando asistencia rápida y efectiva en tu hogar.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">¿Por qué Mundo Hogar?</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">Todo en un mismo lugar</h4>
          <span class="description">Encuentra todo lo que necesitas para tu hogar, en un mismo portal 100% online.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">El mejor financiamiento</h4>
          <span class="description">Paga a meses sin intereses y con las mejores alternativas de pago a tu medida.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">Más tranquilidad</h4>
          <span class="description">Proveedores calificados, servicios garantizados y métodos de pagos seguros para ti.</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">Simple y transparente</h4>
          <span class="description">Gestiona y haz el seguimiento online de tus proyectos en pocos clics.</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <!-- TODO: Change later the link -->
      <p class="customer-benefit">Beneficios exclusivos para clientes Santander. Si aún no eres cliente, haz <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank">clic aquí</a><p>
    </div>
  </div>
</article>
<article class="faq-article">
  <h2 class="title">Preguntas frecuentes</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="¿Qué son las asistencias de Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Son soluciones eficientes ante cualquier urgencia, imprevisto o inconveniente que pueda surgir en tu hogar o a tus mascotas.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué es un plan de asistencias Mundo Hogar?" iconPos="end">
        <div class="text-container">
          <p class="text">Es un programa de asistencias que ofrece cobertura frente a los inconvenientes o imprevistos que debas resolver durante el periodo de vigencia de tu plan. Consulta los eventos que incluye cada uno de los planes disponibles.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué es un evento y límite de eventos?" iconPos="end">
        <div class="text-container">
          <p class="text">Un evento es cada vez que requieres hacer uso de alguno de los servicios incluidos en tu plan de asistencias. Los límites de eventos es la cantidad de veces que puedes usar un servicio en el mismo periodo.</p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="¿Qué servicios incluye mi plan de asistencia?" iconPos="end">
        <div class="text-container">
          <p class="text">Mundo Hogar tiene los planes adecuados para proteger lo que más te importa: <a class="link" routerLink="/home-assistance/plan-hogar">Plan Hogar, </a><a class="link" routerLink="/home-assistance/plan-mascotas">Plan Mascotas</a>, <a class="link" routerLink="/home-assistance/plan-hogar-mascotas">Plan Hogar + Mascotas</a>.</p>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">Visita nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">Centro de ayuda</a> para más información</h5>
</article>