import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { jwtDecode } from 'jwt-decode';
import { StepperModule } from 'primeng/stepper';
import { filter, firstValueFrom } from 'rxjs';
import { EMPTY_MORTGAGE_SIMULATION, LocalMortgageSimulation, MortgageSimulatorStep } from '../../models/mortgage-simulation.model';
import { DataKey, DataStorageService } from '../../services/data-storage/data-storage.service';
import { PlatformService } from '../../services/platform/platform.service';
import { SeoService } from '../../services/seo/seo.service';

@Component({
  selector: 'app-mortgage-simulator',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterModule,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './mortgage-simulator.page.html',
  styleUrl: './mortgage-simulator.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MortgageSimulatorPage implements OnInit {
  activeStep: MortgageSimulatorStep = MortgageSimulatorStep.SimulationType;
  goBackText: string;
  steps = new Array(3).fill(null);

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private location: Location,
    private router: Router,
    private seoService: SeoService,
    private platformService: PlatformService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  async ngOnInit(): Promise<void> {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('mortgage-simulator');
      return;
    }
    await this.initializeMortgageSimulation();
    this.setActiveStep(this.router.url);
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe({
      next: (event) => this.setActiveStep(event.url),
    });
    this.goBackText = window.innerWidth >= 1366 ? 'Back' : 'Simulate your mortgage';
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  private async initializeMortgageSimulation(): Promise<void> {
    let currentMortgageSimulation = await firstValueFrom(this.dataStorageService.get<LocalMortgageSimulation>(DataKey.MortgageSimulation)) ?? EMPTY_MORTGAGE_SIMULATION;
    const token = this.activatedRoute.snapshot.queryParams['token'];
    if (token) {
      const { email, fullName, phoneNumber } = jwtDecode<{
        email: string;
        fullName: string;
        phoneNumber: string;
      }>(token);
      const phoneNumberFormatted = (phoneNumber && phoneNumber.startsWith('+')) ? phoneNumber.slice(1) : null;
      currentMortgageSimulation = {
        ...currentMortgageSimulation,
        email: email ?? '',
        fullName: fullName ?? '',
        phoneNumber: phoneNumberFormatted ?? '',
      };
    }
    this.dataStorageService.set<LocalMortgageSimulation>(DataKey.MortgageSimulation, {
      ...currentMortgageSimulation
    });
  }

  private setActiveStep(currentUrl: string): void {
    if (currentUrl.includes('simulation-data')) {
      this.activeStep = MortgageSimulatorStep.SimulationData;
    } /* else if (currentUrl.includes('remodeling-amount')) { TODO: Uncomment when remodeling amount is available
      this.activeStep = MortgageSimulatorStep.RemodelingAmount;
    } */ else if(currentUrl.includes('contact-data')) {
      this.activeStep = MortgageSimulatorStep.ContactData;
    } else {
      this.activeStep = MortgageSimulatorStep.SimulationType;
    }
  }
}
