import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { filter, firstValueFrom, take } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';
import { EMAIL_PATTERN } from '../../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { SessionProvider, SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { WarehouseOrdersService } from '../../../services/warehouse-orders/warehouse-orders.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';
import { isActive } from '../../../utils/forms.utils';
import { passwordValidator } from '../../../validators/password.validator';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    ReminderComponent,
    RouterLink,
    TabViewModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sign-up.page.html',
  styleUrl: './sign-up.page.scss',
})
export class SignUpPage implements OnInit {
  form: FormGroup;
  signUpWithEmail = false;
  private loadingProvider: SessionProvider | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private metaTagsService: MetaTagsService,
    private movingOrdersService: MovingOrdersService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [Validators.required, passwordValidator]),
    });
  }

  // TODO: Remove and access controls from form property
  get email(): FormControl { return this.form.controls['email'] as FormControl; }
  get password(): FormControl { return this.form.controls['password'] as FormControl; }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  isLoading(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider === provider;
  }

  isLoadingOther(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider !== provider;
  }

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    this.analyticsService.logUserCreationEvent({ step: 'start' });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    if (this.signUpWithEmail) {
      this.signUpWithEmail = false;
      return;
    }
    return this.location.back();
  }

  goTo(url: string): void {
    const origin = this.activatedRoute.snapshot.queryParamMap.get('origin');
    const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
    const queryParams: Params = {
      ...(origin && { origin }),
      ...(redirectTo && { redirectTo }),
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate([url], { queryParams, queryParamsHandling: 'merge' });
  }

  showEmailForm(): void {
    this.signUpWithEmail = true;
  }

  async signUp(provider: SessionProvider): Promise<void> {
    let step = '';
    try {
      this.loadingProvider = provider;
      if (provider === 'email') {
        step = `sign-up-with-provider-${provider}`;
        await this.sessionsService.signUp({ provider: 'email', data: { email: this.email.value, password: this.password.value } }, () => firstValueFrom(this.recaptchaV3Service.execute('sessionsRouter/create')));
      } else {
        step = `sign-in-with-provider-${provider}`;
        await this.sessionsService.signIn({ provider }, () => firstValueFrom(this.recaptchaV3Service.execute('sessionsRouter/create')));
      }
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser), take(1)));
      step = 'validate-and-navigate';
      await validateAndNavigate(
        user,
        this.dataStorageService,
        this.router,
        this.activatedRoute.snapshot.queryParamMap,
        this.movingOrdersService,
        this.warehouseOrdersService,
      );
      const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
      this.analyticsService.logSignUpEvent({
        method: provider,
        ...(redirectTo && { redirect_to: decodeURIComponent(redirectTo) }),
      });
      this.analyticsService.logUserCreationEvent({ step: 'user-credentials', method: provider });
    } catch (error) {
      const { code } = error as { code: string; message: string; };
      if (code === 'email-already-in-use') {
        this.toastService.showError({ title: 'Error', description: 'The email is already in use.' });
      } else {
        this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
        this.errorReportingService.log('SignUpPage.signUp()', step, error);
      }
    } finally {
      this.loadingProvider = null;
    }
  }
}
