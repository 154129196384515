import { Route } from '@angular/router';
import { sessionGuard } from '../../guards/session.guard';

export default [
  {
    path: '',
    redirectTo: 'consumer-credit-discount',
    pathMatch: 'prefix',
  },
  {
    path: 'consumer-credit-discount',
    canActivate: [sessionGuard],
    loadComponent: () => import('./consumer-credit-discount/consumer-credit-discount.page').then(m => m.ConsumerCreditDiscountPage),
    data: {
      description: 'Conoce los descuentos que puedes obtener al contratar un crédito al consumo en Mundo Hogar.',
      title: 'Descuento por crédito al consumo',
    },
  }
] satisfies Route[];