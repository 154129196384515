import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { QRCodeModule } from 'angularx-qrcode';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { ReminderComponent } from '../../../../components/reminder/reminder.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { CurrencyFormatterPipe } from '../../../../pipes/currency-formatter.pipe';
import { DataKey, DataStorageService } from '../../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { HomeAssistanceOrderDetail, HomeAssistanceOrdersService } from '../../../../services/home-assistance-orders/home-assistance-orders.service';
import { LocalUser } from '../../../../services/users/users.service';
import { getComplementaryData } from '../../../../utils/home-assistances.utils';

@Component({
  selector: 'app-my-home-assistances',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    QRCodeModule,
    ReminderComponent,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './my-home-assistances.page.html',
  styleUrl: './my-home-assistances.page.scss',
})
export class MyHomeAssistancesPage implements OnInit, OnDestroy {
  errorLoading = false;
  homeAssistanceOrdersDetails: HomeAssistanceOrderDetail[] = [];
  loading = true;
  qrInformation = '';
  userEmail = '';
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private dataStorageService: DataStorageService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    private location: Location,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  childEventClicked(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  goBack(): void  {
    this.location.back();
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    combineLatest([
      this.homeAssistanceOrdersService.getAll(),
      this.dataStorageService.get<LocalUser>(DataKey.LocalUser),
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([homeAssistanceOrders, localUser]) => {
        if (localUser) {
          this.userEmail = localUser.email;
        }
        this.homeAssistanceOrdersDetails = homeAssistanceOrders.map((order) => {
          return {
            ...order,
            ...getComplementaryData(order),
            qrInformation: `${window.location.origin}/home-assistance/request-home-assistance?email=${this.userEmail}&planId=${order.plan.id}`,
          };
        });
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyHomeAssistancesPage.initialize()', 'get-all-home-assistance-orders', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  selfEventClicked(event: Event): void {
    event.stopPropagation();
  }
}
