<section class="leads-form-section">
  <div class="left-section">
    <div class="title-container">
      <h4 class="title">Hola 👋</h4>
      <p class="subtitle">Completa esta información para crear tu solicitud.</p>
    </div>
    <p class="text-center">¿Eres cliente Santander?</p>
    <div class="customer-container">
      <div class="option">
        <label>Si, soy cliente</label>
        <p-radioButton class="radio-button" name="isClient" [(ngModel)]="isClient" [value]="true" (onClick)="toggleIsClient()"></p-radioButton>
      </div>
      <div class="option">
        <label>No, no soy cliente</label>
        <p-radioButton class="radio-button" name="isClient" [(ngModel)]="isClient" [value]="false" (onClick)="toggleIsClient()"></p-radioButton>
      </div>
    </div>
    <form [formGroup]="form" class="form-container">
      @if (isClient) {
        <div class="input-container">
          <span class="label-container"><label for="client-code">Código cliente Santander*</label> <i class="icon-information-circle" pTooltip="Encuentra tu código cliente en tu estado de cuenta, en tu tarjeta de crédito o débito, y también en tu contrato de apertura." tooltipPosition="bottom"></i></span>
          <input class="input" id="client-code" pInputText placeholder="Ingrese código cliente Santander..." formControlName="clientCode" aria-describedby="client-code-help" data-private />
          <small id="client-code-help" [class.hidden]="!form.controls.clientCode.touched || !form.controls.clientCode.errors" class="p-error input-error">Ingresa un código cliente válido.</small>
        </div>
      }
      <div class="input-container">
        <label for="name">Nombre*</label>
        <input type="text" id="name" pInputText [formControl]="this.form.controls.name" maxLength="36" aria-describedby="name-help" data-private />
        <small id="name-help" [class.hidden]="!this.form.controls.name.touched || !this.form.controls.name.errors" class="p-error input-error">Ingresa un nombre válido.</small>
      </div>
      <div class="input-container">
        <label for="father-last-name">Primer apellido*</label>
        <input type="text" id="father-last-name" pInputText [formControl]="this.form.controls.fatherLastName" maxLength="36" aria-describedby="father-last-name-help" data-private />
        <small id="father-last-name-help" [class.hidden]="!this.form.controls.fatherLastName.touched || !this.form.controls.fatherLastName.errors" class="p-error input-error">Ingresa un primer apellido válido.</small>
      </div>
      <div class="input-container">
        <label for="mother-last-name">Segundo apellido</label>
        <input type="text" id="mother-last-name" pInputText [formControl]="this.form.controls.motherLastName" maxLength="36" aria-describedby="mother-last-name-help" data-private />
        <small id="mother-last-name-help" [class.hidden]="!this.form.controls.motherLastName.touched || !this.form.controls.motherLastName.errors" class="p-error input-error">Ingresa un segundo apellido válido.</small>
      </div>
      <div class="input-container">
        <label for="email">Correo electrónico*</label>
        <input id="email" type="email" pInputText formControlName="email" aria-describedby="email-help" [class.disabled]="form.controls.email.disabled" [class.input]="form.controls.email.enabled" data-private />
        <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">Ingresa un correo válido.</small>
      </div>
      <div class="input-container">
        <label for="phone-number">Número telefónico*</label>
        <p-inputMask id="phone-number" [formControl]="form.controls.phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 999?9" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" #phoneNumberInput data-private />
        <small id="phone-number-help" [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">Ingresa un número de teléfono válido, debe comenzar con "52" y luego 10 dígitos.</small>
      </div>
      <p class="text-center">¿Cómo te gustaría financiar este producto/servicio?</p>
      <div class="input-container financing-container">
        <label class="multiselect-label" for="financing">Selecciona una opción</label>
        <p-dropdown id="financing" class="service-input" placeholder="Selecciona una opción de financiamiento..." [formControl]="form.controls.financing" [options]="financingOptions" optionLabel="label" optionValue="value">
          <ng-template let-item pTemplate="item">
            <p><strong>{{ item.label }}</strong></p>
            <p>{{ item.subtitle }}</p>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="input-container">
        <label for="last-numbers-opportunity">Últimos 5 dígitos de la OPP*</label>
        <p-inputMask id="last-numbers-opportunity" [formControl]="form.controls.lastNumbersOpportunity" [autoClear]="false" [unmask]="true" mask="99999" placeholder="99999" slotChar="" aria-describedby="last-numbers-opportunity-help" #phoneNumberInput data-private />
        <small id="last-numbers-opportunity-help" [class.hidden]="!form.controls.lastNumbersOpportunity.touched || !form.controls.lastNumbersOpportunity.errors" class="p-error input-error">Ingresa los últimos 5 dígitos de la oportunidad.</small>
      </div>
      <div class="input-container">
        <label class="label" for="franchise-code">Código de franquicia*</label>
        <input class="input" pInputText id="franchise-code" [formControl]="form.controls.franchiseCode" placeholder="R12345" aria-describedby="franchise-code-help" maxlength="6" data-private />
        <small id="franchise-code-help" [class.hidden]="!form.controls.franchiseCode.touched || !form.controls.franchiseCode.errors" class="p-error input-error">Ingresa un código de franquicia válido.</small>
      </div>
      <div class="input-container">
        <label class="label" for="postal-code">Código postal*</label>
        <input class="input" pInputText id="postal-code" [formControl]="form.controls.postalCode" maxlength="5" aria-describedby="postal-code-help" data-private />
        <small id="postal-code-help" [class.hidden]="!form.controls.postalCode.touched || !form.controls.postalCode.errors" class="p-error input-error">Ingresa un código postal válido.</small>
      </div>
    </form>
    <div class="button-container">
      <app-button label="Continuar" innerId="btn-jXRBkMse" class="send" styleClass="primary expand" [disabled]="form.invalid" [loading]="sendingForm" (action)="onSubmit()" />
    </div>
  </div>
  <div class="right-section">
    <div class="text-container">
      <h2 class="title">El mejor financiamiento para tu hogar</h2>
      <p class="subtitle">En Mundo Hogar encuentra lo que necesitas y fináncialo con las mejores opciones.</p>
    </div>
  </div>
</section>
@if (form.controls.phoneNumber.value) {
  <app-send-result-sidebar #sendResultSidebar [phoneNumber]="form.controls.phoneNumber.value" [sendingResult]="sendingRequest" [resultSent]="requestSent" type="form" (sendResult)="sendRequest()" (changePhoneNumber)="changePhoneNumber()" (goTo)="goToHome()" goToLabel="Volver al Home" />
}
