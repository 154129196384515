import { ChargeIntent, WarehouseOrder, WarehouseOrderStatus } from '@homein-hogar-server';
import { constants } from '../constants';
import { ErrorReportingService } from '../services/error-reporting/error-reporting.service';
import { ToastService } from '../services/toast/toast.service';
import { differencesDays } from './date.utils';
import { openExternalLink } from './link.utils';

export type WarehouseOrderFinalStatus = WarehouseOrderStatus | 'finalized' | 'about-to-expire';

export function getWarehouseOrderFinalStatus(order: WarehouseOrder): WarehouseOrderFinalStatus {
  if (order.status === 'done') {
    if (order.finishAt.getTime() <= (new Date()).getTime()) {
      return 'finalized';
    }
    else if (differencesDays(order.finishAt, new Date()) <= constants.warehouse.aboutToExpireThreshold) {
      return 'about-to-expire';
    }
  }
  return order.status;
}

export function getWarehouseServiceFromChargeIntent(chargeIntent: ChargeIntent): string {
  let match = chargeIntent.items[0].title.toLowerCase().match(/^bodega\s+(\d+\.\d+)/);
  if (!match) {
    match = ['mudango', 'default'];
  }
  return match[1];
}

export function goToReserveWarehouse(
  order: WarehouseOrder,
  errorReportingService: ErrorReportingService,
  toastService: ToastService
): void {
  if (!order.additionalData['quoteUrl']) {
    errorReportingService.log('MyWarehousesPage.goToReserveWarehouse()', 'go-to-reserve-warehouse', new Error('quoteUrl empty or not defined'));
    toastService.showWarning({ title: 'Reserva de bodega no disponible', description: 'No se puede acceder a la reserva de la bodega en este momento.' }, 3000);
    return;
  }
  openExternalLink(order.additionalData['quoteUrl']);
}