<div class="my-favorites-container">
  <section class="my-favorites-section">
    <h1 class="title">{{ 'Favorites' | transloco }}</h1>
    <app-retryable-section [error]="errorLoadingFavorites" [loading]="loadingFavorites" (retry)="initialize()">
      @if (favorite && favorite.items.length === 1) {
        <span class="product-quantity">{{ 'You have 1 product in your list' | transloco }}</span>
      } @else if (favorite && favorite.items.length > 1) {
        <span class="product-quantity">{{ 'You have X products in your list' | transloco: { quantity: favorite.items.length } }}</span>
      }
      <div class="favorites-container">
        @if (favorite && favorite.items.length) {
          @for (item of favorite.items; track $index) {
            <div class="favorite-container">
              @if (item.resourceType === 'product' && item.resource) {
                <app-product-card [product]="item.resource" [isFavorite]="true"></app-product-card>
              }
            </div>
          }
        } @else {
          <div class="empty">
            <img class="image" src="/assets/illustrations/empty-favorites.svg" [alt]="'You don\'t have favorites illustration' | transloco" />
            <p>{{ 'You don\'t have products saved in "Your Favorites", start searching for products using the search engine.' | transloco }}</p>
          </div>
        }
      </div>
    </app-retryable-section>
  </section>
</div>
