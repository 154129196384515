import { Injectable } from '@angular/core';
import { HomeAssistancePlan } from '@homein-hogar-server';
import { environment } from '../../../environments/environment';
import { HomeAssistancePlansServiceApi } from './home-assistance-plans.service.api';
import { HomeAssistancePlansServiceMock } from './home-assistance-plans.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? HomeAssistancePlansServiceMock : HomeAssistancePlansServiceApi,
})
export abstract class HomeAssistancePlansService {
  abstract get(id: string): Promise<Omit<HomeAssistancePlan, 'pricing.internal'> | null>;
  abstract getAll(): Promise<Omit<HomeAssistancePlan, 'pricing.internal'>[]>;
}
