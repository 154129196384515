import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { subYears } from 'date-fns';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { emailPattern, namePattern, phoneNumberPattern } from '../../../../constants/validation-patterns.constants';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { SeoService } from '../../../../services/seo/seo.service';
import { ToastService } from '../../../../services/toast/toast.service';
import { UsersService } from '../../../../services/users/users.service';

@Component({
  selector: 'app-my-personal-data',
  standalone: true,
  imports: [
    ButtonComponent,
    CalendarModule,
    CommonModule,
    DropdownModule,
    InputMaskModule,
    InputTextModule,
    ReactiveFormsModule,
    RetryableSectionComponent,
    TranslocoPipe,
  ],
  templateUrl: './my-personal-data.page.html',
  styleUrl: './my-personal-data.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MyPersonalDataPage implements OnInit, OnDestroy {
  canDetectChanges = false;
  errorLoading = false;
  form: FormGroup<{
    birthDate: FormControl<Date | null>;
    email: FormControl<string | null>;
    fatherLastName: FormControl<string | null>;
    motherLastName: FormControl<string | null>;
    name: FormControl<string | null>;
    phoneNumber: FormControl<string | null>;
  }>;
  isLoading = false;
  isValidForm = false;
  maxDateBirthDate = subYears(new Date(), 18);
  sendingForm = false;
  user: User | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    protected location: Location,
    private router: Router,
    private seoService: SeoService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });

    this.form = new FormGroup({
      birthDate: new FormControl<Date | null>(null, [Validators.required]),
      email: new FormControl<string | null>('', [Validators.required, Validators.email, Validators.pattern(emailPattern)]),
      fatherLastName: new FormControl<string | null>('', [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      motherLastName: new FormControl<string | null>('', [Validators.maxLength(36), Validators.pattern(namePattern)]),
      name: new FormControl<string | null>('', [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      phoneNumber: new FormControl<string | null>('', [Validators.required, Validators.pattern(phoneNumberPattern)]),
    });

    this.form.valueChanges.pipe(takeUntil(this.viewDestroyed))
      .subscribe({
        next: () => {
          if (this.canDetectChanges) {
            this.checkFormValidity();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  initialize(): void {
    this.isLoading = true;
    this.errorLoading = false;
    this.usersService.getCurrentUser().pipe(takeUntil(this.viewDestroyed))
      .subscribe({
        next: (user) => {
          if (!user) {
            this.location.back();
            return;
          }
          this.user = user;
          this.form.patchValue({
            birthDate: user?.birthDate,
            email: user?.email,
            fatherLastName: user?.fatherLastName,
            motherLastName: user?.motherLastName,
            name: user?.name,
            phoneNumber: user?.phoneNumber.replace(/\D/g, ''),
          });
          this.form.controls.email.disable();
          this.canDetectChanges = true;
          this.isLoading = false;
        },
        error: (error) => {
          this.errorReportingService.log('MyPersonalDataPage.initialize()', 'get-user-data', error);
          this.isLoading = false;
          this.errorLoading = true;
        },
      });
  }

  async onSubmit(): Promise<void> {
    this.sendingForm = true;
    try {
      if (this.form.invalid) {
        return;
      }
      const formValue = this.form.getRawValue();
      await this.usersService.updateUser({
        birthDate: formValue.birthDate!,
        fatherLastName: formValue.fatherLastName!.trim(),
        motherLastName: formValue.motherLastName?.trim() || null,
        name: formValue.name!.trim(),
        phoneNumber: `+${formValue.phoneNumber}`,
      });
      this.toastService.showSuccess({ title: 'Datos guardados', description: 'Tus datos han sido guardados correctamente.' }, 3000);
      const route = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
      if (route) {
        await this.router.navigateByUrl(route);
        return;
      }
      await this.router.navigateByUrl('/private-site');
    } catch (error) {
      this.errorReportingService.log('MyPersonalDataPage.onSubmit()', 'submit-form', error);
      this.toastService.showError({ title: 'Error al guardar los datos', description: 'Ocurrió un error al guardar tus datos, por favor inténtalo más tarde.' }, 3000);
    } finally {
      this.sendingForm = false;
    }
  }

  private checkFormValidity(): void {
    const formValue = this.form.getRawValue();
    const hasChanges =
      formValue.birthDate !== this.user?.birthDate ||
      formValue.fatherLastName?.trim() !== this.user?.fatherLastName ||
      formValue.motherLastName?.trim() !== this.user?.motherLastName ||
      formValue.name?.trim() !== this.user?.name ||
      formValue.phoneNumber !== this.user?.phoneNumber?.replace(/\D/g, '');

    this.isValidForm = this.form.valid && hasChanges;
  }
}
