import { Injectable } from '@angular/core';
import { HomeAssistancePlan } from '@homein-hogar-server';
import { firstValueFrom, map } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { HomeAssistancePlansService } from './home-assistance-plans.service';

@Injectable()
export class HomeAssistancePlansServiceMock implements HomeAssistancePlansService {
  constructor(
    private mockService: MockService,
  ) {}

  get(id: string): Promise<Omit<HomeAssistancePlan, 'pricing.internal'> | null> {
    return firstValueFrom(this.mockService.getHomeAssistancePlan(id));
  }

  getAll(): Promise<Omit<HomeAssistancePlan, 'pricing.internal'>[]> {
    return firstValueFrom(this.mockService.getHomeAssistancePlans().pipe(map((homeAssistancePlans) => {
      return homeAssistancePlans.filter((homeAssistancePlan) => homeAssistancePlan.status === 'active');
    })));
  }
}
