<main class="main-container">
  <div class="clouds">
    <img class="cloud first" src="assets/images/cloud1.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
    <img class="cloud second" src="assets/images/cloud2.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
    <img class="cloud third" src="assets/images/cloud3.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
    <img class="cloud fourth" src="assets/images/cloud2.svg" [alt]="'cloud icon' | transloco" loading="lazy" />
  </div>
  <section class="main">
    <div class="hero-container">
      <h1 class="main-title">{{ 'Connect with your home in one place' | transloco }}</h1>
      <p class="main-subtitle">Beneficios exclusivos para clientes <strong>Santander</strong></p>
      <p class="main-subtitle">{{ 'How can we help you today?' | transloco }}</p>
      <div class="vertical-boxes-container">
        <a routerLink="/moving" class="vertical-business-container" id="btn-link-DXgLHFoj">
          <div class="circle-icon-container">
            <i class="icon-truck"></i>
          </div>
          <span class="vertical-name">{{ 'Moving home' | transloco }}</span>
        </a>
        <a routerLink="/remodeling" class="vertical-business-container" id="btn-link-xdIEf_v3">
          <div class="circle-icon-container">
            <i class="icon-remodeling"></i>
          </div>
          <span class="vertical-name">{{ 'Remodel my home' | transloco }}</span>
        </a>
        <a routerLink="/warehouse" class="vertical-business-container" id="btn-link-U7Iu_A0I">
          <div class="circle-icon-container">
            <i class="icon-warehouses"></i>
          </div>
          <span class="vertical-name warehouse">{{ 'Rent warehouse' | transloco }}</span>
        </a>
        <a routerLink="/ecommerce" class="vertical-business-container" id="btn-link-ZNECBe7z">
          <div class="circle-icon-container">
            <i class="icon-armchair"></i>
          </div>
          <span class="vertical-name">{{ 'Home shopping' | transloco }}</span>
        </a>
        <a routerLink="/home-assistance" class="vertical-business-container" id="btn-link-2SHue-3K">
          <div class="circle-icon-container">
            <i class="icon-home-assistance"></i>
          </div>
          <span class="vertical-name">{{ 'Assistance plans' | transloco }}</span>
        </a>
      </div>
      <div class="sponsored-by">
        <p class="sponsor">Patrocinado por:</p>
        <img class="image" src="assets/images/santander-red-logo.svg" width="68" height="11"/>
      </div>
    </div>
  </section>
</main>
<section class="terms-banner">
  <app-terms-banner page="home" />
</section>
<article class="why-platform-article">
  <div class="title-container">
    <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
    <hr class="underline">
    <div class="benefits-container">
      <div class="benefit">
        <i class="icon-monitor"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'All in one place' | transloco }}</h4>
          <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-jewel"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Exclusive benefits' | transloco }}</h4>
          <span class="description">{{ 'Exclusive financing alternatives for Santander customers and much more.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-security-profile"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'The best suppliers' | transloco }}</h4>
          <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
        </div>
      </div>
      <div class="benefit">
        <i class="icon-thumbs-up"></i>
        <div class="benefit-content">
          <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
          <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
        </div>
      </div>
    </div>
    <div class="become-a-customer">
      <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">{{ 'click here' | transloco }}.</a><p>
    </div>
  </div>
</article>
<app-retryable-section [error]="errorLoadingAdvertisingSpaces" [loading]="loadingAdvertisingSpaces" (retry)="getAdvertisingSpaces()">
  <section class="promotions">
    @if (promotions.length) {
      <p-carousel class="promotions-carousel"
                  [value]="promotions"
                  [numVisible]="1"
                  [numScroll]="1"
                  [circular]="true"
                  [autoplayInterval]="5000"
                  [showIndicators]="true"
                  [showNavigators]="false">
        <ng-template let-item pTemplate="item">
          <div class="carousel-container">
            <div class="carousel-item">
              <div class="text-content">
                <h3 class="title">{{ item.title }}</h3>
                <app-button [label]="item.buttonLabel" [innerId]="'btn-tn0_sYYH-' + item.index" [href]="item.buttonUrl" [translate]="false" styleClass="primary negative" />
              </div>
              <div class="image-container">
                <img class="image" [src]="item.image" alt="{{ item.title }}" loading="lazy" />
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    }
  </section>
</app-retryable-section>
<section class="products-of-interest">
  <app-products-carousel [filters]="[{ value: ['highlighted'], operator: 'in', field: 'tags' }]" [pagination]="{ documentsPerPage: 20, page: highlightedProductsPage }" title="Featured products" innerId="WSSEedWp" />
</section>
<section class="opportunities">
  <div class="opportunities-container">
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">{{ 'Apply for your card and pay for everything in up to 36 monthly installments' | transloco }}</h4>
        <app-button innerId="btn-f-U2xnfC" label="Request" styleClass="secondary" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener" />
      </div>
      <div class="right-card-container">
        <img class="img" src="assets/images/banner-opportunity-01.png" alt="{{ 'Opportunity image' | transloco }}" loading="lazy" />
      </div>
    </div>
    <div class="opportunity">
      <div class="left-card-container">
        <h4 class="title">{{ 'Simulate that remodeling that you dream of so much' | transloco }}</h4>
        <app-button innerId="btn-6LpKsuok" label="Simulate" styleClass="secondary" routerLink="/project-simulator/step-1" />
      </div>
      <div class="right-card-container">
        <img class="img" src="assets/images/second-opportunity.jpg" alt="{{ 'Opportunity image' | transloco }}" loading="lazy" />
      </div>
    </div>
  </div>
</section>
<section class="exclusive-benefits-section" (wheel)="onWheel($event, carousel)">
  <div class="exclusive-benefits-container">
    <div class="title-container">
      <h2 class="title">{{ 'Outstanding benefits for you' | transloco }}</h2>
      <p class="subtitle">{{ 'Exclusive offers for Santander customers' | transloco }}</p>
      <hr class="underline">
    </div>
    <app-retryable-section [error]="errorLoadingAdvertisingSpaces" [loading]="loadingAdvertisingSpaces" (retry)="getAdvertisingSpaces()">
      @if (exclusiveBenefits.length) {
        <p-carousel #carousel class="carousel-exclusive-benefits"
          [value]="exclusiveBenefits"
          [numVisible]="3.125"
          [numScroll]="1"
          [autoplayInterval]="5000"
          [showIndicators]="true"
          [showNavigators]="false"
          [responsiveOptions]="[
            { breakpoint: '1365px', numVisible: 4.125, numScroll: 1 },
            { breakpoint: '1250px', numVisible: 3.875, numScroll: 1 },
            { breakpoint: '1150px', numVisible: 3.5, numScroll: 1 },
            { breakpoint: '1050px', numVisible: 3.2, numScroll: 1 },
            { breakpoint: '950px', numVisible: 2.7, numScroll: 1 },
            { breakpoint: '850px', numVisible: 2.5, numScroll: 1 },
            { breakpoint: '750px', numVisible: 2.2, numScroll: 1 },
            { breakpoint: '650px', numVisible: 1.8, numScroll: 1 },
            { breakpoint: '550px', numVisible: 1.5, numScroll: 1 },
            { breakpoint: '450px', numVisible: 1.3, numScroll: 1 },
            { breakpoint: '400px', numVisible: 1.2, numScroll: 1 },
            { breakpoint: '380px', numVisible: 1.1, numScroll: 1 }
          ]" (onPage)="onPageChange($event)">
          <ng-template let-item pTemplate="item">
            <div class="card" (mouseenter)="stopAutoplay(carousel)" (mouseleave)="startAutoplay(carousel)">
              <div class="image" [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
              </div>
              <div class="card-content">
                <h6 class="title">{{ item.title }}</h6>
                <div class="icon-text">
                  <i class="icon-circle-checkmark"></i>
                  <span class="benefit-text">{{ item.description }}</span>
                </div>
              </div>
              <div class="card-information">
                <hr class="divider">
                <div class="button-container">
                  <app-button [innerId]="'btn-KoYSlxnk-' + item.index" [label]="item.buttonLabel" [href]="item.buttonUrl" [translate]="false" styleClass="primary dark" />
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      }
    </app-retryable-section>
  </div>
</section>
<article class="faq-article">
  <h2 class="title">{{ 'FAQ' | transloco }}</h2>
  <div class="faq-container">
    <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
      <p-accordionTab class="accordion-tab" header="{{ 'What is Mundo Hogar?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'Mundo Hogar is an exclusive portal for Banco Santander customers that brings together in one place unique benefits in home services. It is the first digital platform in the market where you can quote, hire services and products for the home, while accessing the best financing.' | transloco }}</p>
          <p class="text">{{ 'If you are already a Banco Santander customer,' | transloco }} <a class="link" id="btn-link-yoPphZYP" routerLink="/enrollment/sign-up">{{ 'activate your account' | transloco }} </a>{{'and start enjoying your exclusive benefits.' | transloco }}</p>
          <p class="text">{{ 'If you want to become a Santander customer' | transloco }}, <a class="link" id="btn-link-QUV-dJ9L" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener">{{ 'become a customer here' | transloco }}</a></p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'What can I find in Mundo Hogar?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'The remodeling your home needs, furniture purchase, moving, storage, and assistance plans to enjoy your home without worries.' | transloco }}</p>
          <p class="text">{{ 'In addition, you will have unlimited access to mortgage and remodeling project simulators, 100% online and free of charge.' | transloco }}</p>
          <p class="text">{{ 'Exclusive financing for Santander clients.' | transloco }}</p>
        </div>
        <div class="text-container">
          <ul>
            <li><strong>{{ 'Mortgage loans' | transloco }}:</strong> {{ 'Traditional options and a loan that allows financing the purchase and remodeling of a home at once.' | transloco }}</li>
            <li><strong>{{ 'Consumer loans' | transloco }}:</strong> {{ 'Up to 30% discount on the rate and terms up to 60 months (exclusive for purchases on the portal)' | transloco }}</li>
            <li><strong>{{ 'Credit card payment' | transloco }}:</strong> {{ 'Up to 36 months interest free on all services and products for your home' | transloco }}</li>
          </ul>
        </div>
        <div class="text-container">
          <p class="text">{{ 'If you want to become a Santander customer,' | transloco }} <a class="link" id="btn-link-kwF6B0Wu" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener">{{ 'become a customer in a few clicks' | transloco }}</a></p>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'Why Mundo Hogar?' | transloco }}" iconPos="end">
        <div class="text-container">
          <p class="text">{{ 'Mundo Hogar is your perfect ally to transform and personalize your home, with the support and trust of Banco Santander.' | transloco }}</p>
        </div>
        <div class="text-container">
          <p class="text"><strong>{{ 'Exclusive benefits for Santander customers:' | transloco }}</strong></p>
          <ul>
            <li>{{ 'Integral Accompaniment: Support in every challenge associated with the home.' | transloco }}</li>
            <li>{{ 'Unified and Digital Experience: Everything in one place, simply and digitally.' | transloco }}</li>
            <li>{{ 'Trust and Transparency: Clear and reliable processes.' | transloco }}</li>
            <li>{{ 'Offer Customization: Solutions tailored to your needs.' | transloco }}</li>
            <li>{{ 'Unique Financing Alternatives: Exclusive options.' | transloco }}</li>
            <li>{{ 'Best Prices in the Market: Guarantee of the best offers.' | transloco }}</li>
          </ul>
        </div>
      </p-accordionTab>
      <p-accordionTab class="accordion-tab" header="{{ 'How does Mundo Hogar work?' | transloco }}" iconPos="end">
        <div class="text-container">
          <ol>
            <li>{{ 'Activate your account: Sign up for Mundo Hogar using your Apple, Google or email credentials. You will receive a validation email to activate your account.' | transloco }}</li>
            <li>{{ 'Confirm your information: Verify your information to ensure we deliver personalized quotes, quotations, offers and benefits.' | transloco }}</li>
            <li>{{ 'Identify yourself as a Santander customer: Enter the first 6 digits of any of your Santander cards to access exclusive benefits...Now you are ready to buy products or contract services at Mundo Hogar!' | transloco }}</li>
          </ol>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" id="btn-link-YSje514Q" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
</article>
