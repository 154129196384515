import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { EcommerceOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { CurrencyFormatterPipe } from '../../../../pipes/currency-formatter.pipe';
import { EcommerceOrdersService } from '../../../../services/ecommerce-orders/ecommerce-orders.service';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { SeoService } from '../../../../services/seo/seo.service';

@Component({
  selector: 'app-my-purchases',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './my-purchases.page.html',
  styleUrl: './my-purchases.page.scss',
})
export class MyPurchasesPage implements OnInit, OnDestroy {
  ecommerceOrders: EcommerceOrder[] = [];
  errorLoading = false;
  loading = true;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.ecommerceOrdersService.getAll().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (ecommerceOrders) => {
        this.ecommerceOrders = ecommerceOrders;
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyPurchasesPage.initialize()', 'get-all-ecommerce-orders', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }
}
