<section class="request-home-assistance-section">
  <div class="main-container">
    <h5 class="title">{{ 'Request your home assistance' | transloco }}</h5>
    <img class="image" src="assets/illustrations/request-assistance.svg" alt="{{ 'Image about a guy asking if you need home assistance' | transloco }}" />
    <app-reminder title="Important" class="reminder" styleClass="alert">
      <span class="subtitle">{{ 'This is your identifier' | transloco }}: {{ email }}</span>
      <p class="description">{{ 'You will be asked for your identifier in order to attend.' | transloco }}</p>
      <p class="description">{{ 'You can also write to us by WhatsApp' | transloco }} <span class="phone-number">+52 55 8719 6412</span>, {{'or call us at' | transloco }} <span class="phone-number">55 9063 3719</span></p>
    </app-reminder>
    <div class="buttons-container">
      <app-button label="Call us" href="tel:5590633719" styleClass="secondary" icon="phone-call" />
      @if (planId === 'plan-hogar') {
        <app-button label="Write us" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar.+&type=&&type=383absent=0" styleClass="secondary" icon="whatsapp" />
      } @else if (planId === 'plan-mascotas') {
        <app-button label="Write us" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Mascotas.+&type=&&type=383absent=0" styleClass="secondary" icon="whatsapp" />
      } @else if (planId === 'plan-hogar-mascotas') {
        <app-button label="Write us" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar+y+Mascota.+&type=&&type=383absent=0" styleClass="secondary" icon="whatsapp" />
      }
    </div>
  </div>
</section>