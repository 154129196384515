import { Injectable } from '@angular/core';
import { CreatePaymentIntentData, PaymentIntent, PaymentIntentSource } from '@homein-hogar-server';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { constants } from '../../constants';
import { ECOMMERCE_PARTNER_ACCOUNT_MOCK } from '../accounts-server/accounts/accounts.mock';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { PaymentIntentsService } from './payment-intents.service';

@Injectable()
export class PaymentIntentsServiceMock implements PaymentIntentsService {

  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  async createExternal(data: CreatePaymentIntentData): Promise<{ id: string; redirectUrl: string; }> {
    const userId = await firstValueFrom(this.sessionsService.getSession()).then((session) => session?.userId);
    if (!userId) {
      throw new Error('User id not found.');
    }
    const id = await this.mockService.createPaymentIntent({
      additionalData: null,
      amount: data.apportions.external.reduce((amount, apportion) => amount + apportion.amount, 0) + data.apportions.internal.reduce((amount, apportion) => amount + apportion.amount, 0),
      apportions: data.apportions,
      authorizationId: null,
      currency: constants.defaultCurrency,
      paidAt: new Date(),
      paymentId: null,
      paymentReverseId: null,
      message: '',
      orderId: '',
      resourceIds: data.resourceIds,
      resourceSubtypes: data.source === 'home-assistance' ? data.resourceSubtypes : null,
      resourceType: data.resourceType,
      reversed: false,
      reversedAt: null,
      source: data.source as PaymentIntentSource,
      status: 'paid',
      statusDetail: null,
      toAccountId: ECOMMERCE_PARTNER_ACCOUNT_MOCK.id,
      transactionId: new Date().getTime().toString(),
      userId,
    });
    // TODO: update account balances
    setTimeout(() => {
      if (data.resourceType === 'charge-intent') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.mockService.updateChargeIntent(data.resourceIds[0], { status: 'paid' });
      }
      if (data.resourceType === 'product') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        // TODO: remove items from shopping cart
      }
      // TODO: create order depending on the resourceType
      // TODO: update payment intent with message and orderId
    }, 2000 );
    // TODO: change redirectUrl to some dummy url were we should wait a few seconds and process / reject the payment
    return { id, redirectUrl: 'https://www.google.cl' };
  }

  async createInternal(data: CreatePaymentIntentData): Promise<{ id: string; }> {
    const userId = await firstValueFrom(this.sessionsService.getSession()).then((session) => session?.userId);
    if (!userId) {
      throw new Error('User id not found.');
    }
    const id = await this.mockService.createPaymentIntent({
      additionalData: null,
      amount: data.apportions.external.reduce((amount, apportion) => amount + apportion.amount, 0) + data.apportions.internal.reduce((amount, apportion) => amount + apportion.amount, 0),
      apportions: data.apportions,
      authorizationId: null,
      currency: constants.defaultCurrency,
      paidAt: new Date(),
      paymentId: null,
      paymentReverseId: null,
      message: '',
      orderId: '',
      resourceIds: data.resourceIds,
      resourceSubtypes: data.source === 'home-assistance' ? data.resourceSubtypes : null,
      resourceType: data.resourceType,
      reversed: false,
      reversedAt: null,
      source: data.source as PaymentIntentSource,
      status: 'paid',
      statusDetail: null,
      toAccountId: ECOMMERCE_PARTNER_ACCOUNT_MOCK.id,
      transactionId: new Date().getTime().toString(),
      userId,
    });
    // TODO: update account balances
    setTimeout(() => {
      if (data.resourceType === 'charge-intent') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.mockService.updateChargeIntent(data.resourceIds[0], { status: 'paid' });
      }
      if (data.resourceType === 'product') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        // TODO: remove items from shopping cart
      }
      // TODO: create order depending on the resourceType
      // TODO: update payment intent with message and orderId
    }, 2000 );
    return { id };
  }

  get(id: string): Observable<PaymentIntent | null> {
    return this.mockService.getPaymentIntent(id);
  }

  getAllPaid(): Observable<PaymentIntent[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.mockService.getPaymentIntentsPaid().pipe(map((paymentIntents) => {
          return paymentIntents
            .filter((paymentIntent) => paymentIntent.userId === session.userId);
        }));
      }
      return of([]);
    }));
  }
}