import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProjectSimulationExecutionTimeFrame, ProjectSimulationPropertyType } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { jwtDecode } from 'jwt-decode';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { StepperModule } from 'primeng/stepper';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { CircularProgressComponent } from '../../../components/circular-progress/circular-progress.component';
import { constants } from '../../../constants';
import { LocalProjectSimulation } from '../../../models/local-project-simulation.model';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';

const emptyLocalProjectSimulation: LocalProjectSimulation = {
  accessories: null,
  completedSteps: [],
  email: '',
  executionTimeFrame: null,
  fullName: '',
  phoneNumber: '',
  postalCode: '',
  surfaceArea: 0,
  propertyType: null,
  type: null,
};

@Component({
  selector: 'app-step-1',
  standalone: true,
  imports: [
    ButtonComponent,
    CircularProgressComponent,
    CommonModule,
    FormsModule,
    InputNumberModule,
    InputTextModule,
    KeyFilterModule,
    ReactiveFormsModule,
    RouterModule,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './step-1.page.html',
  styleUrl: './step-1.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class Step1Page implements OnInit {
  form: FormGroup<{
    executionTimeFrame: FormControl<ProjectSimulationExecutionTimeFrame | null>;
    postalCode: FormControl<string | null>;
    propertyType: FormControl<ProjectSimulationPropertyType | null>;
  }>;
  projectSimulation: LocalProjectSimulation | null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private location: Location,
    private platformService: PlatformService,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.form = new FormGroup({
      executionTimeFrame: new FormControl<ProjectSimulationExecutionTimeFrame | null>(null, [Validators.required]),
      postalCode: new FormControl<string | null>(null, [
        Validators.required,
        Validators.minLength(constants.postalCodeLength),
        Validators.maxLength(constants.postalCodeLength),
        Validators.pattern(/^[0-9]+$/)
      ]),
      propertyType: new FormControl<ProjectSimulationPropertyType | null>(null, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('project-simulator');
      return;
    }
    const simulateAgain = this.activatedRoute.snapshot.queryParams['action'] === 'simulate-again';
    const token = this.activatedRoute.snapshot.queryParams['token'];
    if (!simulateAgain) {
      this.projectSimulation = emptyLocalProjectSimulation;
      this.dataStorageService.set(DataKey.ProjectSimulation, this.projectSimulation);
    }
    if (token) {
      const { email, fullName, phoneNumber } = jwtDecode<{
        email: string;
        fullName: string;
        phoneNumber: string;
      }>(token);
      const phoneNumberFormatted = (phoneNumber && phoneNumber.startsWith('+')) ? phoneNumber.slice(1) : null;
      this.projectSimulation = {
        ...emptyLocalProjectSimulation,
        email: email ?? '',
        fullName: fullName ?? '',
        phoneNumber: phoneNumberFormatted ?? '',
      };
      this.dataStorageService.set(DataKey.ProjectSimulation, this.projectSimulation);
    }
    this.projectSimulation = await this.getCurrentSimulation();
    this.form.patchValue({
      executionTimeFrame: this.projectSimulation?.executionTimeFrame ?? null,
      postalCode: this.projectSimulation?.postalCode ?? null,
      propertyType: this.projectSimulation?.propertyType ?? null,
    });
    this.analyticsService.logProjectSimulatorEvent({ source: 'app', step: 'start' });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  async onSubmit(): Promise<void> {
    const executionTimeFrame = this.form.controls.executionTimeFrame.value;
    const postalCode = this.form.controls.postalCode.value;
    const propertyType = this.form.controls.propertyType.value;
    if (!executionTimeFrame || !postalCode || !propertyType) {
      return;
    }
    const currentSimulation = await this.getCurrentSimulation();
    if (!currentSimulation) {
      return;
    }
    currentSimulation.completedSteps = [];
    if (!currentSimulation.completedSteps.includes(1)) {
      currentSimulation.completedSteps.push(1);
    }
    this.dataStorageService.set(DataKey.ProjectSimulation, {
      ...(currentSimulation ?? {}),
      executionTimeFrame,
      postalCode,
      propertyType,
    });
    this.analyticsService.logProjectSimulatorEvent({ source: 'app', step: 'step_1', time_frame: executionTimeFrame });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/project-simulator/step-2']);
  }

  setFormControlValue(formControl: FormControl, value: string): void {
    formControl.setValue(value);
  }

  private getCurrentSimulation(): Promise<LocalProjectSimulation | null> {
    return firstValueFrom(this.dataStorageService.get<LocalProjectSimulation>(DataKey.ProjectSimulation));
  }
}
