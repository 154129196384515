import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { RemodelingOrder } from '@homein-hogar-server';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { CurrencyFormatterPipe } from '../../../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { RemodelingOrdersService } from '../../../../services/remodeling-orders/remodeling-orders.service';

@Component({
  selector: 'app-my-remodelings',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    RouterLink,
  ],
  templateUrl: './my-remodelings.page.html',
  styleUrl: './my-remodelings.page.scss',
})
export class MyRemodelingsPage implements OnInit, OnDestroy {
  errorLoading = false;
  loading = false;
  remodelingOrders: RemodelingOrder[] = [];
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private remodelingOrdersService: RemodelingOrdersService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.remodelingOrdersService.getAll().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (orders) => {
        this.remodelingOrders = orders;
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('MyRemodelingsPage.initialize()', 'get-all-remodeling-orders', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  childEventClicked(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
