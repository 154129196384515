import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EcommerceOrder, EcommerceOrderItem } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { EcommercePurchaseDetailsComponent } from '../../../../components/ecommerce-purchase-details/ecommerce-purchase-details.component';
import { InvoicingSidebarComponent } from '../../../../components/invoicing-sidebar/invoicing-sidebar.component';
import { RatingComponent } from '../../../../components/rating/rating.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { CONFIG } from '../../../../constants';
import { EcommerceOrdersService } from '../../../../services/ecommerce-orders/ecommerce-orders.service';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { differencesDays } from '../../../../utils/date.utils';

@Component({
  selector: 'app-purchase-status',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    EcommercePurchaseDetailsComponent,
    InvoicingSidebarComponent,
    RatingComponent,
    RetryableSectionComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './purchase-status.page.html',
  styleUrl: './purchase-status.page.scss',
})
export class PurchaseStatusPage implements OnInit, OnDestroy {
  @ViewChild('invoicingSidebar') invoicingSidebar: InvoicingSidebarComponent;
  ecommerceOrder: EcommerceOrder;
  errorLoading = false;
  loading = true;
  orderItem: EcommerceOrderItem;
  showGenerateInvoiceOption = false;
  private orderId: string;
  private orderItemId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    const orderItemId = this.activatedRoute.snapshot.queryParamMap.get('itemId');
    if (!orderId || !orderItemId) {
      this.goToMyPurchases();
      return;
    }
    this.orderItemId = orderItemId;
    this.orderId = orderId;
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.ecommerceOrdersService.get(this.orderId).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (ecommerceOrder) => {
        const orderItem = (ecommerceOrder?.items ?? []).find((item) => item.id === this.orderItemId);
        if (!ecommerceOrder || !orderItem) {
          this.goToMyPurchases();
          return;
        }
        this.ecommerceOrder = ecommerceOrder;
        this.orderItem = orderItem;
        this.showGenerateInvoiceOption = this.validateGenerateInvoiceOption();
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('PurchaseStatusPage.initialize()', 'get-order', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  openInvoicingSidebar(): void {
    this.invoicingSidebar.open();
  }

  validateGenerateInvoiceOption(): boolean {
    const isDelivered = this.ecommerceOrder.items.some((item) => item.shippingStatus === 'delivered');
    if (!isDelivered) {
      return false;
    }
    const deliveredAt = this.ecommerceOrder.items.find((item) => item.shippingStatus === 'delivered')?.shippingDetails.find((detail) => detail.status === 'delivered')?.date;
    if (!deliveredAt) {
      return false;
    }
    return deliveredAt.getMonth() === new Date().getMonth() || differencesDays(new Date(), deliveredAt) <= CONFIG.ecommerce.invoices.deadlineSendingInvoice;
  }

  private goToMyPurchases(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-purchases']);
  }
}