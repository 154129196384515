import { Route } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
    data: {
      description: 'Arriendo de bodegas en México. Protege tus bienes junto a la empresa líder de bodegas en México.',
      title: 'Arriendo de bodegas en México fácil y seguro',
    },
  },
] satisfies Route[];