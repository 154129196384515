<div class="shopping-cart-content">
  <section class="shopping-cart-section">
    <h2 class="title">
      Carrito de compras
      @if (shoppingCartDetail && shoppingCartDetail.totalItems) {
        <span class="total-items">({{ shoppingCartDetail.totalItems }})</span>
      }
    </h2>
    <app-retryable-section [error]="errorLoadingShoppingCart" [loading]="loadingShoppingCart" (retry)="initialize()">
      <section class="shopping-cart-container">
        @if (!shoppingCartDetail || shoppingCartDetail.totalItems === 0) {
          <div class="empty-shopping-cart-container">
            <img src="/assets/illustrations/empty-shopping-cart.svg" alt="Carrito de compras vacío" />
            <p class="text">Tu carrito de compras se encuentra vacío. Comienza buscando tus próximos productos para el hogar.</p>
          </div>
        } @else {
          <div class="order-summary-and-shopping-cart-container">
            <div class="order-summary">
              <h3 class="summary-label">Resumen del pedido</h3>
              <div class="subtotal-container">
                <span class="products-quantity-label">{{ shoppingCartDetail.totalItems === 1 ? '1 producto' : shoppingCartDetail.totalItems + ' productos' }}</span>
                <span class="subtotal">{{ shoppingCartDetail.subtotal | currencyFormatter }} {{ currency }}</span>
              </div>
              @if (address) {
                <div class="subtotal-container">
                  <span class="label">Envío a {{ address.alias }}</span>
                  <span class="subtotal">{{ shoppingCartDetail.shippingCost | currencyFormatter }} {{ currency }}</span>
                </div>
              } @else {
                <div class="subtotal-container">
                  <span class="label">Costo de envío:</span>
                  <span class="subtotal">Aún no agregas una dirección</span>
                </div>
              }
              <div class="subtotal-incl-vat-container">
                <span class="label">Total</span>
                @if (address) {
                  <span class="amount">{{ (shoppingCartDetail.subtotal + shoppingCartDetail.shippingCost) | currencyFormatter }} {{ currency }}</span>
                } @else {
                  <span class="amount">-</span>
                }
              </div>
              <div class="shipping-address-container" (click)="openManageAddresses()">
                <i class="icon icon-location-start"></i>
                <div class="shipping-address-information-container">
                  @if (address) {
                    <span class="title-address-information" data-private>{{ address.alias }}</span>
                    <span class="value" data-private>
                      Se enviará a:
                      {{ address.street }},
                      {{ address.streetNumber }},
                      {{ address.streetInternalNumber ? (address.streetInternalNumber + ',') : '' }}
                      {{ address.province }},
                      {{ address.municipality }},
                      {{ address.state }},
                      {{ address.country }}
                    </span>
                  } @else {
                    <span class="title-address-information">Dirección de envío</span>
                    <span class="value">Aún no agregas tu dirección</span>
                  }
                  <span class="action">Gestionar direcciones</span>
                </div>
              </div>
              <p class="privacy-policy-text">Al presionar "Pagar pedido", estás aceptando nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank">Aviso de privacidad</a> y <a class="link" href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank">Términos y condiciones</a>.</p>
              <div class="pay-order-button-container">
                <app-button innerId="btn-2mnREx7i" label="Pagar pedido" styleClass="primary expand" (action)="goToCheckout()" />
              </div>
            </div>
            <div class="products-in-shopping-cart">
              <p class="total-products-label">{{ shoppingCartDetail.totalItems }} productos en el carrito</p>
              <div class="products-cards-container">
                @for (item of shoppingCartDetail.items; track $index) {
                  @if (item.resourceType === 'product' && item.resource) {
                    <div class="product-card">
                      <div class="first-row">
                        <div class="info-container" [routerLink]="['/ecommerce/products', item.resource.slug || item.resource.id]">
                          <img class="thumbnail" [src]="item.resource.thumbnails[0]" />
                          <div class="info">
                            <p class="brand">{{ item.resource.brand }}</p>
                            <p class="description">{{ item.resource.name }}</p>
                          </div>
                        </div>
                        <div class="product-price-container">
                          @if (item.resource.price === item.resource.originalPrice) {
                            <div class="price">{{ item.resource.originalPrice * item.quantity | currencyFormatter: item.resource.currency }} {{ item.resource.currency }}</div>
                          } @else {
                            <div class="price">{{ item.resource.price * item.quantity | currencyFormatter: item.resource.currency }} {{ item.resource.currency }}</div>
                            @if (item.resource.discountPercentage > offerThreshold){
                              <div class="original-price">{{ item.resource.originalPrice * item.quantity | currencyFormatter: item.resource.currency }} {{ item.resource.currency }}</div>
                            }
                          }
                        </div>
                      </div>
                      <div class="second-row">
                        <div class="quantity-column">
                          <div class="quantity-container">
                            <span><i class="pi pi-minus" (click)="removeProduct(item.resource)"></i></span>
                            @if (addingProduct || removingProduct) {
                              <div><i class="pi pi-spin pi-spinner"></i></div>
                            } @else {
                              <span class="value">{{ item.quantity }}</span>
                            }
                            <span><i class="pi pi-plus" (click)="addProduct(item.resource)"></i></span>
                          </div>
                        </div>
                        <div class="remove-product-link-container"><span class="remove-product-link" (click)="removeProduct(item.resource, item.quantity)">Quitar del carrito</span></div>
                      </div>
                    </div>
                  }
                }
              </div>
            </div>
          </div>
        }
      </section>
    </app-retryable-section>
  </section>
  @if (!loadingShoppingCart && !errorLoadingShoppingCart && shoppingCartDetail && shoppingCartDetail.items.length) {
    <div class="floating-pay-order">
      <app-button innerId="btn-XkNr6qU1" label="Pagar pedido" styleClass="primary expand" (action)="goToCheckout()" />
    </div>
  }
  @if (categories.length) {
    <section class="products-of-interest-section">
      <app-products-carousel
        title="Te podría interesar también"
        [filters]="[{ field: 'categories', operator: 'in', value: categories }]"
      />
    </section>
  }
</div>
<app-manage-addresses-sidebar #manageAddressesSidebar [selectedAddressId]="shoppingCartDetail?.shippingAddressId ?? null" />