import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONFIG } from '../../constants';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';

export interface TrackedProduct {
  brand: string;
  categories: string[];
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  constructor(
    private dataStorageService: DataStorageService
  ) {}

  getAll(): Observable<TrackedProduct[] | null> {
    return this.dataStorageService.get<TrackedProduct[]>(DataKey.TrackedProducts);
  }

  track(data: TrackedProduct): void {
    let trackedProducts = this.dataStorageService.getLocal<TrackedProduct[]>(DataKey.TrackedProducts) || [];
    const isTracked = trackedProducts.some((item) => item.id === data.id);
    if (isTracked) {
      return;
    }
    trackedProducts.push(data);
    if (trackedProducts.length > CONFIG.maxTrackedProduct) {
      trackedProducts = trackedProducts.slice(-CONFIG.maxTrackedProduct);
    }
    this.dataStorageService.set<TrackedProduct[]>(DataKey.TrackedProducts, trackedProducts);
  }
}
