import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ButtonComponent } from '../../../components/button/button.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LocalUser } from '../../../services/users/users.service';
import { WarehouseOrdersService } from '../../../services/warehouse-orders/warehouse-orders.service';
import { buildQuery } from '../../../utils/url.utils';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    ProgressSpinnerModule,
    SharedModule,
    TermsBannerComponent,
    TooltipModule,
    TranslocoModule,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
})
export class HomePage implements OnInit, OnDestroy {
  enableService = ['local', 'dev'].includes(environment.name); // TODO: Remove when this functionality is deployed to production
  loading = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private metaTagsService: MetaTagsService,
    private router: Router,
    private toastService: ToastService,
    private warehouseOrders: WarehouseOrdersService,
  ) {}

  async navigateToQuote(): Promise<void> {
    let step = '';
    this.loading = true;
    try {
      step = 'get-current-user';
      const user = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
      const { appUrl: warehouseAppUrl, reference: ref } = environment.partners.warehouse;
      if (!user) {
        this.toastService.showWarning({
          title: 'You need to log in',
          description: 'To perform this action, you need to be logged in to your Mundo Hogar account.' ,
        });
        step = 'navigate-to-sign-in';
        await this.router.navigate(['enrollment/sign-in'], { queryParams: { origin: 'warehouse', redirectTo: encodeURIComponent(warehouseAppUrl) } });
        return;
      } else if (user.enrollmentStatus === 'pending') {
        this.toastService.showWarning({
          title: 'Finalize your registration',
          description: 'To access all the exclusive benefits that Mundo Hogar has for you, you need to complete your registration.' ,
        });
        step = 'navigate-to-sign-in';
        await this.router.navigate(['enrollment/sign-in'], { queryParams: { origin: 'warehouse', redirectTo: encodeURIComponent(warehouseAppUrl) } });
        return;
      }
      const { email, fatherLastName, id, name, phoneNumber } = user;
      const queryParams = {
        email,
        external_reference: this.warehouseOrders.createId(),
        ref,
        user_id: id,
        ...(name && { name }),
        ...(fatherLastName && { last_name: fatherLastName }),
        ...(phoneNumber && { cellphone: phoneNumber.substring(3) }), // TODO: this should be truncated based on the phone country code
      };
      window.location.href = `${warehouseAppUrl}?${buildQuery(queryParams)}`;
    } catch (error) {
      this.errorReportingService.log('HomePage.navigateToQuote()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    if (this.router.url.includes('quote')) {
      this.loading = true;
    }
    this.activatedRoute.queryParams.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (queryParams) => {
        if (queryParams['action'] === 'quote') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.navigateToQuote();
        }
      }
    });
  }
}
