<section class="project-simulator-container">
  <form  [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">{{ 'Back' | transloco }}</span>
      <span class="mobile-text">{{ 'Simulate your project' | transloco }} </span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">{{ 'Simulates the cost of the project you have in mind' | transloco }}</h5>
      <app-circular-progress [currentStep]="1" [numberSteps]="6" stepTitle="{{ 'Location of the property' | transloco }}" stepSubtitle="{{ 'Next: Space to be remodelled' | transloco }}" />
    </div>
    <div class="bottom-container animate__fadeIn">
      <div class="project-simulation-types-container">
        <h2 class="title">{{ 'In which type of property will the renovation be carried out?' | transloco }}</h2>
        <div class="project-simulation-type-container" [class.selected]="form.controls.propertyType.value === 'house'" (click)="setFormControlValue(form.controls.propertyType, 'house')">
          <span class="text">{{ 'House' | transloco }}</span>
        </div>
        <div class="project-simulation-type-container" [class.selected]="form.controls.propertyType.value === 'apartment'" (click)="setFormControlValue(form.controls.propertyType, 'apartment')">
          <span class="text" [class.selected]="form.controls.propertyType.value === 'apartment'">{{ 'Apartment' | transloco }}</span>
        </div>
        <h2 class="title">{{ 'When you want to do it?' | transloco }}</h2>
        <div class="project-simulation-type-container" [class.selected]="form.controls.executionTimeFrame.value === 'before-3-months'" (click)="setFormControlValue(form.controls.executionTimeFrame, 'before-3-months')">
          <span class="text">{{ 'Before 3 months' | transloco }}</span>
        </div>
        <div class="project-simulation-type-container" [class.selected]="form.controls.executionTimeFrame.value === '3-6-months'" (click)="setFormControlValue(form.controls.executionTimeFrame, '3-6-months')">
          <span class="text">{{ '3 to 6 months' | transloco }}</span>
        </div>
        <div class="project-simulation-type-container" [class.selected]="form.controls.executionTimeFrame.value === 'more-than-6-months'" (click)="setFormControlValue(form.controls.executionTimeFrame, 'more-than-6-months')">
          <span class="text">{{ 'More than 6 months' | transloco }}</span>
        </div>
        <h2 class="title">{{ 'Where is the property located?' | transloco }}</h2>
        <div class="input-container">
          <label for="postal-code">{{ 'Enter the postal code of the property' | transloco }}</label>
          <input id="postal-code" type="text" aria-describedby="postal-code-help" pInputText [formControl]="form.controls.postalCode" maxlength="5" />
          @if (form.controls.postalCode.touched && form.controls.postalCode.invalid) {
            <small id="postal-code-help">
              @if (form.controls.postalCode.errors?.['required']) {
                {{ 'Postal code is required' | transloco }}
              }
              @if (form.controls.postalCode.errors?.['min'] || form.controls.postalCode.errors?.['max'] || form.controls.postalCode.errors?.['pattern']) {
                {{ 'The postal code must be a 5-digit number' | transloco }}
              }
            </small>
          }
        </div>
      </div>
      <div class="right-container">
        @if (form.controls.propertyType.value === 'house') {
          <img class="illustration animate__fadeIn" src="/assets/illustrations/project-simulation-prop-type-house.svg" alt="{{ 'House illustration' | transloco }}" />
        } @else if (form.controls.propertyType.value === 'apartment') {
          <img class="illustration animate__fadeIn" src="/assets/illustrations/project-simulation-prop-type-apartment.svg" alt="{{ 'Apartment illustration' | transloco }}" />
        } @else {
          <img class="illustration animate__fadeIn" src="/assets/illustrations/generic-empty.svg" alt="{{ 'Default illustration' | transloco }}" />
        }
          <div class="continue-button-container">
            <app-button label="Continue" styleClass="primary expand" type="submit" [disabled]="form.invalid"></app-button>
          </div>
      </div>
    </div>
  </form>
</section>
