<div class="sign-up-container">
  <section class="sign-up-section">
    <div class="column left">
      <p-tabView class="tab-panel-customer">
        <p-tabPanel class="customer-panel" header="{{ 'I am a Santander customer' | transloco }}">
          <div class="go-back-container" (click)="goBack()">
            <i class="icon-arrow-left"></i><span class="text">{{ 'Back' | transloco }}</span>
          </div>
          <h5 class="title">{{ 'Create your Mundo Hogar account' | transloco }}</h5>
          @if (!signUpWithEmail) {
            <div class="providers-container">
              <app-button [icon]="{ name: 'google', position: 'end' }" label="Create an account with Google" styleClass="secondary alternative expand" [disabled]="isLoadingOther('google')" [loading]="isLoading('google')" (action)="signUp('google')" />
              <app-button [icon]="{ name: 'apple', position: 'end' }" label="Create an account with Apple" styleClass="secondary alternative expand" [disabled]="isLoadingOther('apple')" [loading]="isLoading('apple')" (action)="signUp('apple')" />
              <app-button [icon]="{ name: 'email', position: 'end' }" label="Create an account with email" styleClass="secondary alternative expand" [disabled]="isLoadingOther('email')" [loading]="isLoading('email')" (action)="showEmailForm()" />
            </div>
          } @else {
            <form [formGroup]="form" class="form-container">
              <div class="input-container">
                <label for="email">{{ 'Email' | transloco }}</label>
                <input id="email" type="email" [class.active]="isActive(email)" pInputText [formControl]="email" aria-describedby="email-help" email />
                <small id="email-help" [class.hidden]="!email.touched || !email.errors" class="p-error">{{ 'Enter a valid email.' | transloco }}</small>
              </div>
              <div class="input-container">
                <label for="password">{{ 'Password' | transloco }}</label>
                <p-password id="password" [class.active]="isActive(email)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" [formControl]="password" aria-describedby="password-help" />
                @if (password.touched || password.dirty) {
                  <small [class.p-error]="password.errors?.['minLength']"><i [ngClass]="{'icon-close': password.errors?.['minLength'], 'icon-checkmark': !password.errors?.['minLength']}"></i> {{ 'Minimum X characters' | transloco: { min: 8 } }}</small>
                  <small [class.p-error]="password.errors?.['uppercase']"><i [ngClass]="{'icon-close': password.errors?.['uppercase'], 'icon-checkmark': !password.errors?.['uppercase']}"></i> {{ 'At least one capital letter' | transloco }}</small>
                  <small [class.p-error]="password.errors?.['specialCharacter']"><i [ngClass]="{'icon-close': password.errors?.['specialCharacter'], 'icon-checkmark': !password.errors?.['specialCharacter']}"></i> {{ 'At least one special character' | transloco }}</small>
                }
              </div>
              <app-button label="Create account" class="create-account-button" type="submit" styleClass="primary expand" [disabled]="!form.valid || isLoadingOther('email')" [loading]="isLoading('email')" (action)="signUp('email')" />
            </form>
          }
          <p class="account-reminder">{{ 'Already have a Mundo Hogar account?' | transloco }} <a class="link" (click)="goTo('/enrollment/sign-in')">{{ 'Enter' | transloco }}</a>.</p>
          <p class="privacy-policy-text">{{ 'By creating your Mundo Hogar account, you are agreeing to accept our' | transloco }} <a class="link" href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank">{{ 'Privacy policies' | transloco }}</a> {{ 'and also our' | transloco }} <a class="link" href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank">{{ 'Terms and conditions' | transloco }}</a>. {{ 'This site is protected by reCAPTCHA and apply the' | transloco }} <a class="link" href="https://policies.google.com/privacy" target="_blank" rel="noopener">{{ 'Privacy Policies' | transloco }}</a> {{ 'and the' | transloco }} <a class="link" href="https://policies.google.com/terms" target="_blank" rel="noopener">{{ 'Terms of Service' | transloco }}</a> {{ 'from Google' | transloco }}.</p>
        </p-tabPanel>
        <p-tabPanel class="not-customer-panel" header="{{'I am not a Santander customer' | transloco }}">
          <h2 class="title">{{ 'Join us and discover financial peace of mind with' | transloco }} <span class="highlight">Santander</span></h2>
          <p class="subtitle">{{ 'More than' | transloco }} <strong>{{ '10 millions customers' | transloco }} </strong>{{ 'already enjoy the benefits of Mundo Hogar.' | transloco }}</p>
          <a class="debit-container" href="https://www.santander.com.mx/personas/cuentas/debito-likeu.html" rel="noopener" target="_blank">
            <div class="content">
              <img class="image" src="/assets/images/likeu.png" alt="{{ 'LikeU Debit image' | transloco }}" />
              <div class="text-container">
                <p class="title">{{ 'LikeU Debit Account' | transloco }}</p>
                <p class="join-us">{{ 'Open your account from your cell phone and receive your personalized card at your home address, shop, pick up and pay from wherever you are!' | transloco }}</p>
                <p class="join-us summary">{{ 'Buy, pick up and pay from wherever you are!' | transloco }}</p>
              </div>
            </div>
          </a>
          <p class="discover">{{ 'Discover all that Santander has for you' | transloco }} <a class="link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener" target="_blank">{{ 'here' | transloco }}</a></p>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="column right">
      <div class="text-container">
        <h2 class="title">{{ 'Relax, we got it' | transloco }}</h2>
        <p class="subtitle">{{ 'Everything you need for your home in one place' | transloco }}</p>
      </div>
    </div>
  </section>
</div>