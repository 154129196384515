<div class="sign-up-container">
  <section class="sign-up-section">
    <div class="column left">
      <p-tabView class="tab-panel-customer">
        <p-tabPanel class="customer-panel" header="Soy cliente Santander">
          <div class="go-back-container" (click)="goBack()">
            <i class="icon-arrow-left"></i><span class="text">Atrás</span>
          </div>
          @if (showNewAccountContest) {
            <div class="upper-title-container">
              <h4 class="upper-title">Crea tu cuenta y gana</h4>
            </div>
          }
          <h5 class="title">Crea tu cuenta Mundo Hogar</h5>
          @if (!signUpWithEmail) {
            <div class="providers-container">
              <app-button innerId="btn-omzL08XS-google" [icon]="{ name: 'google', position: 'end' }" label="Crear cuenta con Google" styleClass="secondary alternative expand" [disabled]="isLoadingOther('google')" [loading]="isLoading('google')" (action)="signUp('google')" />
              <app-button innerId="btn-omzL08XS-apple" [icon]="{ name: 'apple', position: 'end' }" label="Crear cuenta con Apple" styleClass="secondary alternative expand" [disabled]="isLoadingOther('apple')" [loading]="isLoading('apple')" (action)="signUp('apple')" />
              <app-button innerId="btn-omzL08XS-email" [icon]="{ name: 'email', position: 'end' }" label="Crear cuenta con email" styleClass="secondary alternative expand" [disabled]="isLoadingOther('email')" [loading]="isLoading('email')" (action)="showEmailForm()" />
            </div>
          } @else {
            <form [formGroup]="form" class="form-container">
              <div class="input-container">
                <label for="email">Correo electrónico</label>
                <input id="email" type="email" [class.active]="isActive(form.controls.email)" pInputText [formControl]="form.controls.email" aria-describedby="email-help" email data-private />
                <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error">Ingresa un correo válido.</small>
              </div>
              <div class="input-container">
                <label for="password">Contraseña</label>
                <p-password id="password" [class.active]="isActive(form.controls.password)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" [formControl]="form.controls.password" aria-describedby="password-help" data-private />
                @if (form.controls.password.touched || form.controls.password.dirty) {
                  <small [class.p-error]="form.controls.password.errors?.['minLength']"><i [ngClass]="{'icon-close': form.controls.password.errors?.['minLength'], 'icon-checkmark': !form.controls.password.errors?.['minLength']}"></i> {{ 'Minimum X characters' | transloco: { min: 8 } }}</small>
                  <small [class.p-error]="form.controls.password.errors?.['uppercase']"><i [ngClass]="{'icon-close': form.controls.password.errors?.['uppercase'], 'icon-checkmark': !form.controls.password.errors?.['uppercase']}"></i> Al menos una letra mayúscula</small>
                  <small [class.p-error]="form.controls.password.errors?.['specialCharacter']"><i [ngClass]="{'icon-close': form.controls.password.errors?.['specialCharacter'], 'icon-checkmark': !form.controls.password.errors?.['specialCharacter']}"></i> Al menos un carácter especial</small>
                }
              </div>
              <app-button innerId="btn-sFwS30ja" label="Crear cuenta" class="create-account-button" type="submit" styleClass="primary expand" [disabled]="!form.valid || isLoadingOther('email')" [loading]="isLoading('email')" (action)="signUp('email')" />
            </form>
          }
          <p class="account-reminder">¿Ya tienes una cuenta Mundo Hogar? <a class="link" (click)="goTo('/enrollment/sign-in')">Ingresar</a>.</p>
          <p class="privacy-policy-text">Al crear tu cuenta Mundo Hogar, estás aceptando nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank">Aviso de privacidad</a> y también nuestros <a class="link" href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank">Términos y condiciones</a>. Este sitio está protegido por reCAPTCHA y aplican las <a class="link" href="https://policies.google.com/privacy" target="_blank" rel="noopener">Políticas de Privacidad</a> y los <a class="link" href="https://policies.google.com/terms" target="_blank" rel="noopener">Términos de Servicio</a> de Google.</p>
        </p-tabPanel>
        <p-tabPanel class="not-customer-panel" header="No soy cliente Santander">
          <h2 class="title">Únete y descubre la tranquilidad financiera con <span class="highlight">Santander</span></h2>
          <p class="subtitle">Más de <strong>10 millones de clientes </strong>ya disfrutan de los beneficios de Mundo Hogar.</p>
          <a class="debit-container" href="https://www.santander.com.mx/personas/cuentas/debito-likeu.html" rel="noopener" target="_blank">
            <div class="content">
              <img class="image" src="/assets/images/likeu.png" alt="Imagen de Débito LikeU" />
              <div class="text-container">
                <p class="title">Cuenta Débito LikeU</p>
                <p class="join-us">Abre tu cuenta desde tu celular y recibe tu tarjeta personalizada en tu domicilio. ¡Compra, retira y paga desde donde estés!</p>
                <p class="join-us summary">¡Compra, retira y paga desde donde estés!</p>
              </div>
            </div>
          </a>
          <p class="discover">Descubre todo lo que Santander tiene para ti <a class="link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener" target="_blank">aquí</a></p>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="column right" [class.offer]="showNewAccountContest">
      <div class="text-container">
        @if (showNewAccountContest) {
          <h2 class="title">¡Gana hasta 20,000 MXN en Mundo Hogar!</h2>
          <p class="subtitle">Al crear tu nueva cuenta participa por grandes premios en nuestra plataforma</p>
        } @else {
          <h2 class="title">Relájate, lo tenemos</h2>
          <p class="subtitle">Todo lo que necesitas para tu hogar en un solo lugar</p>
        }
      </div>
    </div>
  </section>
</div>