<div class="sign-in-container">
  <section class="sign-in-section">
    <div class="column left">
      <p-tabView class="tab-panel-customer">
        <p-tabPanel class="customer-panel" header="{{ 'I am a Santander customer' | transloco }}">
          <div class="go-back-container" (click)="goBack()">
            <i class="icon-arrow-left"></i><span class="text">{{ 'Back' | transloco }}</span>
          </div>
          <h5 class="title">{{ 'To continue, log in to your account Mundo Hogar' | transloco }}</h5>
          @if (!signInWithEmail) {
            <div class="providers-container">
              <app-button [icon]="{ name: 'google', position: 'end' }" label="Sign in with Google" styleClass="secondary alternative expand" [disabled]="isLoadingOther('google')" [loading]="isLoading('google')" (action)="signIn('google')" />
              <app-button [icon]="{ name: 'apple', position: 'end' }" label="Sign in with Apple" styleClass="secondary alternative expand" [disabled]="isLoadingOther('apple')" [loading]="isLoading('apple')" (action)="signIn('apple')" />
              <app-button [icon]="{ name: 'email', position: 'end' }" label="Sign in with email" styleClass="secondary alternative expand" [disabled]="isLoadingOther('email')" [loading]="isLoading('email')" (action)="showEmailForm()" />
            </div>
          } @else {
            <form [formGroup]="form" class="form-container">
              <div class="input-container">
                <label for="email">{{ 'Email' | transloco }}</label>
                <input id="email" type="email" [class.active]="isActive(email)" pInputText [formControl]="email" aria-describedby="email-help" email />
                <small id="email-help" [class.hidden]="!email.touched || !email.errors" class="p-error">{{ 'Enter a valid email.' | transloco }}</small>
              </div>
              <div class="input-container">
                <label for="password">{{ 'Password' | transloco }}</label>
                <p-password id="password" [class.active]="isActive(password)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" [formControl]="password" aria-describedby="password-help" />
                <small id="password-help" [class.hidden]="!password.touched || !password.errors" class="p-error input-error">{{ 'Enter a valid password.' | transloco }}</small>
              </div>
              <p class="forgot-password"><a class="link" routerLink="/enrollment/password-reset">{{ 'Forgot password?' | transloco }}</a></p>
              <app-button type="submit" label="Sign in" styleClass="primary expand" [disabled]="!form.valid || isLoadingOther('email')" [loading]="isLoading('email')" (action)="signIn('email')" />
            </form>
          }
          <p class="account-reminder">{{ 'Don\'t have a Mundo Hogar account?' | transloco }} <a class="link" (click)="goTo('/enrollment/sign-up')">{{ 'Create account' | transloco }}</a></p>
        </p-tabPanel>
        <p-tabPanel class="not-customer-panel" header="{{'I am not a Santander customer' | transloco }}">
          <h2 class="title">{{ 'Join us and discover financial peace of mind with' | transloco }} <span class="highlight">Santander</span></h2>
          <p class="subtitle">{{ 'More than' | transloco }} <strong>{{ '10 millions customers' | transloco }} </strong>{{ 'already enjoy the benefits of Mundo Hogar.' | transloco }}</p>
          <a class="debit-container" href="https://www.santander.com.mx/personas/cuentas/debito-likeu.html" rel="noopener" target="_blank">
            <div class="content">
              <img class="image" src="/assets/images/likeu.png" alt="{{ 'LikeU Debit image' | transloco }}" />
              <div class="text-container">
                <p class="title">{{ 'LikeU Debit Account' | transloco }}</p>
                <p class="join-us">{{ 'Open your account from your cell phone and receive your personalized card at your home address, shop, pick up and pay from wherever you are!' | transloco }}</p>
                <p class="join-us summary">{{ 'Buy, pick up and pay from wherever you are!' | transloco }}</p>
              </div>
            </div>
          </a>
          <p class="discover">{{ 'Discover all that Santander has for you' | transloco }} <a class="link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" rel="noopener" target="_blank">{{ 'here' | transloco }}</a></p>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="column right">
      <div class="text-container">
        <h2 class="title">{{ 'Enjoy with Mundo Hogar' | transloco }}</h2>
        <p class="subtitle">{{ 'Everything for your home in one place' | transloco }}</p>
      </div>
    </div>
  </section>
</div>
