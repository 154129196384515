<div class="verify-email-container">
  <section>
    <img src="/assets/illustrations/security-token.svg" alt="Illustración de verificación de correo" />
    <h1>Verifica tu correo</h1>
    <app-retryable-section [loading]="loading" [error]="error" (retry)="initialize()">
      <p>Hemos enviado un enlace de verificación al correo:</p>
      <p><strong>{{ user?.email }}</strong></p>
      <p class="email-not-arrived">¿No te ha llegado el correo electrónico?</p>
      @if (canSendEmail) {
        <app-button label="Reenviar correo" styleClass="tertiary" [loading]="sending" (click)="sendEmail()" />
      } @else {
        <app-button label="Reenviar ({{ timeLeftLabel }})" styleClass="tertiary" [disabled]="true" />
      }
    </app-retryable-section>
  </section>
</div>
