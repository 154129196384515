import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FavoriteItem } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { BadgeModule } from 'primeng/badge';
import { CarouselModule } from 'primeng/carousel';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ProductCardComponent } from '../../../components/product-card/product-card.component';
import { ProductsCarouselComponent } from '../../../components/products-carousel/products-carousel.component';
import { CONFIG } from '../../../constants';
import { AdvertisingSpacesService } from '../../../services/advertising-spaces/advertising-spaces.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { TrackedProduct, TrackingService } from '../../../services/tracking/tracking.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    BadgeModule,
    CarouselModule,
    CommonModule,
    ProductCardComponent,
    ProductsCarouselComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
})
export class HomePage implements OnInit, OnDestroy {
  categories: {
    code: string;
    image: string;
    label: string;
    queryParams: Record<string, string>;
  }[];
  ecommerceHomeImages: {
    image: string;
    label: string;
    type: string;
    url: string;
  }[] = [];
  favorites: Record<string, FavoriteItem> = {};
  mainBanner = [
    {
      label: '',
      queryParams: {
        filters: JSON.stringify({ categories: { value: 'salas-y-family-room', operator: 'in', field: 'categories' } }),
      }
    },
    {
      label: 'Patio y jardín',
      queryParams: {
        filters: JSON.stringify({ categories: { value: 'patio-y-jardin', operator: 'in', field: 'categories' } }),
      }
    },
    {
      label: 'Recámaras',
      queryParams: {
        filters: JSON.stringify({ categories: { value: 'recamaras', operator: 'in', field: 'categories' } }),
      }
    }
  ];
  productCategories: string[] = [];
  showCategories = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private advertisingSpacesService: AdvertisingSpacesService,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private metaTagsService: MetaTagsService,
    private trackingService: TrackingService,
  ) {
    this.setEcommerceCategories();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  private getCategoriesTracked(trackedData: TrackedProduct[] | null): void {
    if (!trackedData) {
      return;
    }
    const categories = new Set<string>();
    trackedData.forEach((product) => product.categories.forEach((category) => categories.add(category)));
    this.productCategories = Array.from(categories);
  }

  private initialize(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    combineLatest([
      this.favoritesService.get(),
      this.trackingService.getAll(),
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([favorite, trackedProducts]) => {
        this.favorites = {};
        if (favorite) {
          favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
        }
        this.getCategoriesTracked(trackedProducts);
      },
      error: (error) => {
        this.errorReportingService.log('HomePage.initialize()', 'get-all', error);
      }
    });
    // TODO: Find a better way to avoid the the double row of categories when the carousel is rendered
    setTimeout(() => {
      this.showCategories = true;
    }, 100);
  }

  private setEcommerceCategories(): void {
    const { tree, labels } = CONFIG.ecommerce.categories;
    this.categories = Object.keys(tree).map((code) => ({
      code,
      label: labels[code],
      image: `${code}.png`,
      queryParams: {
        filters: JSON.stringify({ categories: { value: [code], operator: 'in', field: 'categories' } }),
      },
    }));
  }
}
