import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { filter, firstValueFrom, Subject, take } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { SeoService } from '../../../services/seo/seo.service';
import { SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { WarehouseOrdersService } from '../../../services/warehouse-orders/warehouse-orders.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MessageModule,
    ProgressSpinnerModule,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './verify-email.page.html',
  styleUrl: './verify-email.page.scss',
})
export class VerifyEmailPage implements OnInit, OnDestroy {
  error = false;
  loading = false;
  sending = false;
  token: string;
  user: User | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private movingOrdersService: MovingOrdersService,
    private router: Router,
    private seoService: SeoService,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams['token'];
    if (!token) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/']);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.verifyEmail();
  }

  async verifyEmail(): Promise<void> {
    this.error = false;
    this.loading = true;
    let step = '';
    try {
      step = 'verify-email';
      const result = await this.sessionsService.verifyEmail(this.token);
      if (result) {
        const { origin, redirectTo } = result;
        const queryParams: Params = {
          ...(origin && { origin }),
          redirectTo,
        };
        await this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser?.emailValidated), take(1)));
      step = 'validate-and-navigate';
      await validateAndNavigate(
        user,
        this.dataStorageService,
        this.router,
        this.activatedRoute.snapshot.queryParamMap,
        this.analyticsService,
        this.movingOrdersService,
        this.warehouseOrdersService,
      );
      this.analyticsService.logUserCreationEvent({ step: 'verify-email', method: 'email' });
      this.toastService.showSuccess({ title: 'Correo verificado', description: 'Verificaste correctamente tu correo electrónico.' });
    } catch (error) {
      this.errorReportingService.log('VerifyEmailPage.verifyEmail()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Ocurrió un error al verificar tu correo. Por favor, inténtalo más tarde.' });
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

}
