import { Route } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
    data: {
      description: 'Conoce los planes de asistencia para tu hogar y salud de tus mascotas en Mundo Hogar.',
      title: 'Plan de asistencia hogar y salud para mascotas',
    },
  },
  {
    path: 'plan-hogar',
    loadComponent: () => import('./plan-hogar/plan-hogar.page').then(m => m.PlanHogarPage),
    data: {
      description: 'Asegura tu hogar ante cualquier imprevisto, con servicios de plomería, electricidad, vidriería y muchos más. ¡Contrata Plan Hogar y protege tu casa ya!',
      title: 'Contrata ya tu plan Hogar',
    },
  },
  {
    path: 'plan-mascotas',
    loadComponent: () => import('./plan-mascotas/plan-mascotas.page').then(m => m.PlanMascotasPage),
    data: {
      description: 'Plan de salud para tus mascotas. Protege su bienestar con servicios de veterinario, veterinario online, descuentos y muchos más. ¡Contrata tu Plan Mascotas ya!',
      title: 'Contrata ya tu plan Mascotas',
    },
  },
  {
    path: 'plan-hogar-mascotas',
    loadComponent: () => import('./plan-hogar-mascotas/plan-hogar-mascotas.page').then(m => m.PlanHogarMascotasPage),
    data: {
      description: 'Protege tu hogar y la salud de tus mascotas. El Plan Hogar + Mascotas combina lo mejor en servicios para tu hogar y el bienestar de tus mascotas. ¡Conócelo ya!',
      title: 'Contrata ya tu plan Hogar + Mascotas',
    },
  },
  {
    path: 'request-home-assistance',
    loadComponent: () => import('./request-home-assistance/request-home-assistance.page').then(m => m.RequestHomeAssistancePage),
    data: {
      description: 'Solicita asistencia para tu hogar y la salud de tus mascotas.',
      title: 'Solicita asistencia',
    },
  },
] satisfies Route[];