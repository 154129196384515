import { Injectable } from '@angular/core';
import { WarehouseOrder } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WarehouseOrdersServiceApi } from './warehouse-orders.service.api';
import { WarehouseOrdersServiceMock } from './warehouse-orders.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? WarehouseOrdersServiceMock : WarehouseOrdersServiceApi,
})
export abstract class WarehouseOrdersService {
  abstract createId(): string;
  abstract get(id: string): Observable<WarehouseOrder | null>;
  abstract getAll(): Observable<WarehouseOrder[]>;
  abstract getLastInProgress(): Observable<WarehouseOrder | null>;
}
