import { Inject, Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import {
  ContactForm,
  ContactFormSubject,
  FormsRouter,
  SubmitConsumerCreditDiscountData,
  SubmitContactEcommerceFormData,
  SubmitContactHomeAssistanceFormData,
  SubmitContactMovingFormData,
  SubmitContactPlatformFormData,
  SubmitContactRemodelingFormData,
  SubmitContactWarehouseFormData,
  SubmitInvoiceAgainData,
  SubmitInvoiceData,
  SubmitLeadsFormData,
  SubmitPartnerLeadsFormData,
} from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { FormsService } from './forms.service';

const COLLECTION_PATH = 'contactForms';

@Injectable()
export class FormsServiceApi implements FormsService {
  private client: CreateTRPCProxyClient<FormsRouter>;

  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<FormsRouter>(getTRPCClientOptions(`${environment.apiUrl}/forms`, () => this.sessionsService.getAccessToken()));
  }

  getContactForms(subject: ContactFormSubject): Observable<ContactForm[]> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.query<ContactForm>(COLLECTION_PATH, {
          constraints: [
            where('userId', '==', session.userId),
            where('subject', '==', subject),
            orderBy('createdAt', 'desc')
          ]
        });
      }
      return of([]);
    }));
  }

  submitConsumerCreditDiscount(data: SubmitConsumerCreditDiscountData): Promise<void> {
    return this.client.submitConsumerCreditDiscount.mutate(data).then();
  }

  submitContactEcommerce(data: SubmitContactEcommerceFormData): Promise<void> {
    return this.client.submitContactEcommerce.mutate(data).then();
  }

  submitContactHomeAssistance(data: SubmitContactHomeAssistanceFormData): Promise<void> {
    return this.client.submitContactHomeAssistance.mutate(data).then();
  }

  submitContactMoving(data: SubmitContactMovingFormData): Promise<void> {
    return this.client.submitContactMoving.mutate(data).then();
  }

  submitContactPlatform(data: SubmitContactPlatformFormData): Promise<void> {
    return this.client.submitContactPlatform.mutate(data).then();
  }

  submitContactRemodeling(data: SubmitContactRemodelingFormData): Promise<void> {
    return this.client.submitContactRemodeling.mutate(data).then();
  }

  submitContactWarehouse(data: SubmitContactWarehouseFormData): Promise<void> {
    return this.client.submitContactWarehouse.mutate(data).then();
  }

  submitInvoice(data: SubmitInvoiceData): Promise<string> {
    return this.client.submitInvoice.mutate(data).then(({ id }) => id);
  }

  submitInvoiceAgain(data: SubmitInvoiceAgainData): Promise<void> {
    return this.client.submitInvoiceAgain.mutate(data).then();
  }

  submitLeadsForm(data: SubmitLeadsFormData): Promise<void> {
    return this.client.submitLeadsForm.mutate(data).then();
  }

  submitPartnerLeadsForm(data: SubmitPartnerLeadsFormData): Promise<void> {
    return this.client.submitPartnerLeadsForm.mutate(data).then();
  }
}