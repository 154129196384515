import { Route } from '@angular/router';
import { MortgageSimulatorPage } from './mortgage-simulator.page';

export default [
  {
    path: '',
    component: MortgageSimulatorPage,
    children: [
      {
        path: '',
        loadComponent: () => import('./simulation-type/simulation-type.page').then(m => m.SimulationTypePage),
      },
      {
        path: 'simulation-data',
        loadComponent: () => import('./simulation-data/simulation-data.page').then(m => m.SimulationDataPage),
      },
      /* TODO: Uncomment when remodeling amount is available
      {
        path: 'remodeling-amount',
        loadComponent: () => import('./remodeling-amount/remodeling-amount.page').then(m => m.RemodelingAmountPage),
      },
      */
      {
        path: 'contact-data',
        loadComponent: () => import('./contact-data/contact-data.page').then(m => m.ContactDataPage),
      }
    ],
    data: {
      description: 'Simula tu crédito hipotecario con Banco Santander.',
      title: 'Simulador de Crédito Hipotecario',
    },
  },
  {
    path: 'simulate-again',
    loadComponent: () => import('./simulate-again/simulate-again.page').then(m => m.SimulateAgainPage),
    data: {
      description: 'Simula tu crédito hipotecario con Banco Santander.',
      title: 'Simulador de Crédito Hipotecario',
    },
  },
] satisfies Route[];