import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { TranslocoPercentPipe } from '@ngneat/transloco-locale';
import { TRPCClientError } from '@trpc/client';
import { RatingModule } from 'primeng/rating';
import { constants } from '../../constants';
import { CustomError } from '../../models/custom-error.model';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../services/favorites/favorites.service';
import { ShoppingCartsService } from '../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../services/toast/toast.service';
import { mapSearchEngineProductToCheckoutItem } from '../../utils/analytics.utils';

type ProductOfferType = 'sale' | 'super-sale';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyFormatterPipe,
    FormsModule,
    RatingModule,
    RouterModule,
    TranslocoPercentPipe,
    TranslocoPipe,
  ],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss'
})
export class ProductCardComponent implements OnInit {
  @Output() favoriteRemoved = new EventEmitter<string>();
  // TODO: add required: true, to innerId
  @Input() innerId = '';
  @Input() isFavorite = false;
  @Input() product: SearchEngineProduct;
  addingProductToShoppingCart = false;
  errorAddingProductToShoppingCart = false;
  offerType: ProductOfferType | null = null;
  offerTypeTranslationKey = '';
  togglingFavorite = false;

  constructor(
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private injector: Injector,
    private router: Router,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async addToShoppingCart(): Promise<void> {
    if (this.addingProductToShoppingCart) {
      return;
    }
    // TODO: this service will be removed when analytics circular bug is fixed
    const analyticsService = this.injector.get<AnalyticsService>(AnalyticsService);
    let step = '';
    this.addingProductToShoppingCart = true;
    this.errorAddingProductToShoppingCart = false;
    try {
      step = 'add-product-to-shopping-cart';
      await this.shoppingCartsService.addItem({
        quantity: 1,
        resourceId: this.product.id,
        resourceType: 'product',
      });
      analyticsService.logAddToShoppingCartEvent({
        currency: this.product.currency,
        items: [mapSearchEngineProductToCheckoutItem(this.product)],
        value: this.product.pricing['debit'].price
      });
      this.toastService.showSuccess({
        title: 'Añadiste un producto al carrito',
        description: `Se agregó "${this.product.name}" a tu carrito de compras.`,
        action: () => this.router.navigateByUrl('/ecommerce/shopping-cart'),
        actionLabel: 'Ver carrito de compras',
      }, 3000);
    } catch (error) {
      if ((error instanceof TRPCClientError || error instanceof CustomError) && error.data.cause === 'max-shopping-cart-items-reached') {
        this.toastService.showWarning({
          title: 'Alcanzaste el límite',
          description: 'Has alcanzado el límite de productos, puedes eliminar algunos para agregar nuevos.',
          action: () => this.router.navigateByUrl('/ecommerce/shopping-cart'),
          actionLabel: 'Gestionar carrito de compra',
        });
        this.errorAddingProductToShoppingCart = true;
        return;
      }
      this.errorReportingService.log('ProductCardComponent.addToShoppingCart()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
      this.errorAddingProductToShoppingCart = true;
    } finally {
      setTimeout(() => {
        this.addingProductToShoppingCart = false;
      }, 200);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async toggleFavorites(): Promise<void> {
    if (this.togglingFavorite) {
      return;
    }
    // TODO: this service will be removed when analytics circular bug is fixed
    const analyticsService = this.injector.get<AnalyticsService>(AnalyticsService);
    let step = '';
    this.togglingFavorite = true;
    try {
      if (this.isFavorite) {
        step = 'remove-from-favorites';
        await this.favoritesService.removeItem({
          resourceId: this.product.id,
          resourceType: 'product',
        });
        this.toastService.showSuccess({
          title: 'Eliminaste un favorito',
          description: `Se eliminó "${this.product.name}" de tu lista de favoritos.`,
          action: () => this.router.navigateByUrl('/ecommerce/my-favorites'),
          actionLabel: 'Ver Lista',
        }, 3000);
        this.favoriteRemoved.emit(this.product.id);
        this.isFavorite = !this.isFavorite;
        return;
      }
      step = 'add-to-favorites';
      await this.favoritesService.addItem({
        resourceId: this.product.id,
        resourceType: 'product',
      });
      analyticsService.logAddToFavoritesEvent({
        currency: this.product.currency,
        items: [mapSearchEngineProductToCheckoutItem(this.product)],
        value: this.product.pricing['debit'].price,
      });
      this.toastService.showSuccess({
        title: 'Añadiste un favorito',
        description: `Se agregó "${this.product.name}" a tu lista de favoritos.`,
        action: () => this.router.navigateByUrl('/ecommerce/my-favorites'),
        actionLabel: 'Ver Lista',
      }, 3000);
      this.isFavorite = !this.isFavorite;
    } catch (error) {
      if ((error instanceof TRPCClientError || error instanceof CustomError) && error.data.cause === 'max-favorites-items-reached') {
        this.toastService.showWarning({
          title: 'Alcanzaste el límite',
          description: 'Has alcanzado el límite de favoritos, puedes eliminar algunos para agregar nuevos.',
          action: () => this.router.navigateByUrl('/ecommerce/my-favorites'),
          actionLabel: 'Gestionar favoritos',
        });
        return;
      }
      this.errorReportingService.log('ProductCardComponent.toggleFavorites()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
    } finally {
      this.togglingFavorite = false;
    }
  }

  private initialize(): void {
    this.setOfferType();
  }

  private setOfferType(): void {
    if (this.product.discountPercentage >= constants.pricing.ecommerce.superSale) {
      this.offerType = 'super-sale';
    } else if (this.product.discountPercentage >= constants.pricing.ecommerce.sale) {
      this.offerType = 'sale';
    }
    if (this.offerType) {
      this.offerTypeTranslationKey = this.offerType === 'sale' ? 'Sale' : 'Super sale';
    }
  }
}
