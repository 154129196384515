import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { WarehouseOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { firstValueFrom } from 'rxjs';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { PlatformService } from '../../services/platform/platform.service';
import { SeoService } from '../../services/seo/seo.service';
import { WarehouseOrdersService } from '../../services/warehouse-orders/warehouse-orders.service';
import { PurchasePaymentDetailsComponent } from '../purchase-payment-details/purchase-payment-details.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-warehouse-purchase-details',
  standalone: true,
  imports: [
    AccordionModule,
    CommonModule,
    CurrencyFormatterPipe,
    PurchasePaymentDetailsComponent,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './warehouse-purchase-details.component.html',
  styleUrl: './warehouse-purchase-details.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class WarehousePurchaseDetailsComponent implements OnInit {
  @Input() order: WarehouseOrder;
  @Input() showBackButton = true;
  errorLoading = false;
  loading = false;
  orderId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private platformService: PlatformService,
    private router: Router,
    private seoService: SeoService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.platformService.isServer()) {
      return;
    }
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      await this.router.navigate(['/private-site/my-warehouses']);
      return;
    }
    this.orderId = orderId;
    if (!this.order) {
      this.seoService.setMetaTags({
        title: `Detalle de compra #${this.orderId}`,
        description: 'Detalle de compra de una bodega en Mundo Hogar.',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    try {
      if (!this.order) {
        const order = await firstValueFrom(this.warehouseOrdersService.get(this.orderId));

        if (!order) {
          throw new Error('Warehouse warehouseOrder not found');
        }

        this.order = order;
      }
    } catch (error) {
      this.errorReportingService.log('WarehousePurchaseDetailsComponent.initialize()', 'get-order', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }
}