<section class="home-container">
  <section class="categories-container">
    <h3 class="title">{{ 'Categories' | transloco }}</h3>
    @if (showCategories) {
      <p-carousel
        contentClass="carousel display-navigation"
        [value]="categories"
        [numVisible]="7"
        [numScroll]="1"
        [showIndicators]="false"
        [responsiveOptions]="[
          { breakpoint: '1365px', numVisible: 7.5, numScroll: 1 },
          { breakpoint: '1050px', numVisible: 6.5, numScroll: 1 },
          { breakpoint: '900px', numVisible: 6, numScroll: 1 },
          { breakpoint: '650px', numVisible: 4.5, numScroll: 1 },
          { breakpoint: '500px', numVisible: 3.5, numScroll: 1 },
          { breakpoint: '450px', numVisible: 2.5, numScroll: 1 },
        ]" ngSkipHydration>
        <ng-template pTemplate="previousicon">
          <div class="navigation-container">
            <i class="icon-arrow-left"></i>
          </div>
        </ng-template>
        <ng-template let-category pTemplate="item">
          <a class="item-container" routerLink="/ecommerce/search" [queryParams]="category.queryParams">
            <img class="category-image" src="assets/images/categories/{{category.image}}" alt="{{ 'X image' | transloco: { value: category.label } }}"/>
            <h4 class="label">{{ category.label }}</h4>
          </a>
        </ng-template>
        <ng-template pTemplate="nexticon">
          <div class="navigation-container">
            <i class="icon-arrow-right"></i>
          </div>
        </ng-template>
      </p-carousel>
    }
  </section>
  <section class="banner-section">
    <h3 class="title">{{ 'Renew your spaces' | transloco }}</h3>
    <div class="banners-container">
      <div class="main-banner-container">
        <div class="banner first" [routerLink]="['/ecommerce/search']" [queryParams]="mainBanner[0].queryParams">
        </div>
      </div>
      <div class="small-banner-container">
        <div class="banner second" [routerLink]="['/ecommerce/search']" [queryParams]="mainBanner[1].queryParams">
          <h4 class="title">{{ mainBanner[1].label }}</h4>
        </div>
        <div class="banner third" [routerLink]="['/ecommerce/search']" [queryParams]="mainBanner[2].queryParams">
          <h4 class="title">{{ mainBanner[2].label }}</h4>
        </div>
      </div>
    </div>
  </section>
  @if (productCategories.length) {
    <section class="products-container">
      <app-products-carousel title="Recommended for you" [filters]="[{ value: productCategories, operator: 'in', field: 'categories' }]" />
    </section>
  }
  <section class="products-container">
    <app-products-carousel
      title="Opportunities"
      [filters]="[
        { field: 'discountPercentage', operator: '>', value: '0' },
        { field: 'price', operator: '>=', value: '5000' },
        { field: 'price', operator: '<=', value: '30000' },
      ]"
      [order]="{ field: 'discountPercentage', direction: 'desc' }"
    />
  </section>
  <section class="products-container">
    <app-products-carousel title="Featured products" [filters]="[{ value: 'highlighted', operator: 'in', field: 'tags' }]" />
  </section>
</section>
