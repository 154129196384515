import { Injectable } from '@angular/core';
import { UpdateUserData, User, UserEnrollment } from '@homein-hogar-server';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { UsersService } from './users.service';

@Injectable()
export class UsersServiceMock implements UsersService {
  constructor(
    private dataStorageService: DataStorageService,
    private mockService: MockService,
  ) {
  }

  get<T extends User | UserEnrollment = User>(id: string): Observable<T | null> {
    return this.mockService.getUser(id) as Observable<T | null>;
  }

  getCurrentUser<T extends User | UserEnrollment = User>(): Observable<T | null> {
    return this.dataStorageService.get<string>(DataKey.MockUserId).pipe(switchMap(id => id ? this.get<T>(id) : of(null)));
  }

  async updateUser(data: UpdateUserData): Promise<{ code: string; message: string }> {
    const user = await firstValueFrom(this.getCurrentUser());
    if (!user) {
      return Promise.reject({ code: 'user-not-found', message: 'User not found.' });
    }
    await this.mockService.updateUser(user.id, {
      ...data,
      enrollmentStatus: user.bankValidated && user.emailValidated ? 'done' : 'pending',
      profileDataCompleted: true,
    });
    return { code: 'success', message: 'User updated successfully.' };
  }
}
