import { Route } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
    data: {
      description: 'Muebles, artículos para el hogar y herramientas de construcción. Encuentra todo lo que necesitas para renovar tus espacios en Mundo Hogar. Despacho a domicilio.',
      title: 'Encuentra todo para la renovación de tu hogar',
    },
  },
  {
    path: 'shopping-cart',
    loadComponent: () => import('./shopping-cart/shopping-cart.page').then(m => m.ShoppingCartPage),
    data: {
      description: 'Revisa los productos que has agregado a tu carrito de compras en Mundo Hogar.',
      title: 'Carrito de compras',
    },
  },
  {
    path: 'search',
    loadComponent: () => import('./product-search/product-search.page').then(m => m.ProductSearchPage),
    data: {
      description: 'Encuentra los productos que necesitas para renovar tus espacios en Mundo Hogar.',
      title: 'Busca y encuentra los productos que necesitas',
    },
  },
  {
    path: 'my-favorites',
    loadComponent: () => import('./my-favorites/my-favorites.page').then(m => m.MyFavoritesPage),
    data: {
      description: 'Revisa los productos que has marcado como favoritos en Mundo Hogar.',
      title: 'Mis favoritos',
    }
  },
  {
    path: 'products/:slug',
    loadComponent: () => import('./product-detail/product-detail.page').then(m => m.ProductDetailPage),
  }
] satisfies Route[];