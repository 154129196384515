import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-reminder',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './reminder.component.html',
  styleUrl: './reminder.component.scss',
})
export class ReminderComponent {
  @Output() action = new EventEmitter<void>();
  @Input() actionLabel: string;
  @Input() description: string;
  @Input() styleClass: string = 'info';
  @Input({ required: true }) title: string;

  constructor() {}

  triggerAction(): void {
    this.action?.emit();
  }
}
