import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { EcommerceOrder, EcommerceOrderItem, HomeAssistanceOrder, MovingOrder, RemodelingOrder, User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { SelectItemGroup } from 'primeng/api';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import { firstValueFrom, Subject } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { RetryableSectionComponent } from '../../components/retryable-section/retryable-section.component';
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from '../../constants/validation-patterns.constants';
import { EcommerceOrdersService } from '../../services/ecommerce-orders/ecommerce-orders.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../services/forms/forms.service';
import { HomeAssistanceOrderDetail, HomeAssistanceOrdersService } from '../../services/home-assistance-orders/home-assistance-orders.service';
import { MovingOrdersService } from '../../services/moving-orders/moving-orders.service';
import { RemodelingOrdersService } from '../../services/remodeling-orders/remodeling-orders.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';
import { isActive } from '../../utils/forms.utils';
import { getComplementaryData } from '../../utils/home-assistances.utils';

export type Services = 'Asistencia'| 'Bodegaje'| 'Compras para el hogar' | 'Mudanza' | 'Plataforma' | 'Remodelación';

const ecommerceRequestTypes = [
  {
    parent: 'Quiero devolver este producto',
    children: [
      'Producto no es lo que ordené',
    ]
  },
  {
    parent: 'Producto dañado',
    children: [
      'Producto llegó dañado',
      'Daño en producto de alto volumen'
    ]
  },
  {
    parent: 'Incidencia',
    children: [
      'Extravío o robo',
      'Producto incompleto',
      'Producto se encuentra en oficina de mensajería'
    ]
  },
  {
    parent: 'Garantía',
    children: [
      'Solicitar garantía',
      // ESCUCHAR producto JBL
    ]
  },
  {
    parent: 'Otros',
    children: [
      'No parece ser original',
      'La calidad no es la esperada',
      'Tiempo de entrega excedido',
      'Tamaño equivocado',
      'No coincide con la publicación',
      'Producto parece ser usado',
      'Llegó el empaque vacío sin el producto'
    ]
  }
];
@Component({
  selector: 'app-complaints-and-returns',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputMaskModule,
    InputTextModule,
    MultiSelectModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './complaints-and-returns.page.html',
  styleUrl: './complaints-and-returns.page.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class ComplaintsAndReturnsPage implements OnInit, OnDestroy {
  ecommerceOrders: EcommerceOrder[] = [];
  errorLoadingInitialData = false;
  errorLoadingOrders = false;
  form: FormGroup<{
    email: FormControl<string | null>;
    message: FormControl<string | null>,
    phoneNumber: FormControl<string | null>;
    service: FormControl<string | null>;
  }>;
  formEcommerce: FormGroup<{
    requestDetail: FormControl<string | null>;
    requestType: FormControl<string | null>;
    selectedEcommerceOrder: FormControl<{ item: EcommerceOrderItem, order: EcommerceOrder }[] | null>
  }>;
  formHomeAssistance: FormGroup<{
    purchase: FormControl<HomeAssistanceOrder | null>;
    requestType: FormControl<string | null>;
  }>;
  formMoving: FormGroup<{
    purchase: FormControl<MovingOrder | null>;
    requestType: FormControl<string | null>;
  }>;
  formRemodeling: FormGroup<{
    purchase: FormControl<RemodelingOrder | null>;
  }>;
  homeAssistanceOrdersDetails: HomeAssistanceOrderDetail[] = [];
  loadingInitialData = true;
  loadingOrders = false;
  maxWords = 500;
  movingOrders: MovingOrder[] = [];
  orders: SelectItemGroup[] = [];
  remodelingOrders: RemodelingOrder[] = [];
  requestDetailOptions: string[] = [];
  requestOptions = ecommerceRequestTypes.map((option) => option.parent);
  sendingForm = false;
  showSuccessScreen = false;
  suggestedHomeAssistanceApplications: string[] = [
    'Coberturas',
    'Daños',
    'Demora',
    'Desembolso',
    'Servicio mal realizado',
    'Servicio no brindado',
  ];
  suggestedMovingApplications: string[] = [
    'Felicitación',
    'Petición',
    'Queja',
    'Reclamo',
    'Saludo',
  ];
  suggestedServices: string[] = [
    'Asistencia',
    // 'Bodegaje', // TODO: enable when service is ready
    'Compras para el hogar',
    'Mudanza',
    'Plataforma Mundo Hogar',
    'Remodelación',
  ];
  user: User | null = null;
  userName: string | null = '';
  wordCount = 0;
  private viewDestroyed = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    protected location: Location,
    private movingOrdersService: MovingOrdersService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private remodelingOrdersService: RemodelingOrdersService,
    private router: Router,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    this.form = new FormGroup({
      email: new FormControl<string | null>('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      message: new FormControl<string | null>('', [Validators.required, Validators.maxLength(512)]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)]),
      service: new FormControl<string | null>('', [Validators.required]),
    });
    this.formHomeAssistance = new FormGroup({
      purchase: new FormControl<HomeAssistanceOrder | null>(null,[Validators.required]),
      requestType: new FormControl<string | null>(null,[Validators.required]),
    });
    this.formEcommerce = new FormGroup({
      requestDetail: new FormControl<string | null>(null,[Validators.required]),
      requestType: new FormControl<string | null>(null,[Validators.required]),
      selectedEcommerceOrder: new FormControl<{ item: EcommerceOrderItem, order: EcommerceOrder }[] | null>(null,[Validators.required]),
    });
    this.formMoving = new FormGroup({
      purchase: new FormControl<MovingOrder | null>(null,[Validators.required]),
      requestType: new FormControl<string | null>(null,[Validators.required]),
    });
    this.formRemodeling = new FormGroup({
      purchase: new FormControl<RemodelingOrder | null>(null,[Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get validateForms(): boolean {
    let formIsValid = this.form.valid;
    const service = this.form.controls.service.value;
    if (service === 'Compras para el hogar') {
      const hasEcommerceOrders = this.ecommerceOrders && this.ecommerceOrders.length > 0;
      if (hasEcommerceOrders) {
        formIsValid = formIsValid && this.formEcommerce.valid;
      }
    } else if (service === 'Asistencia') {
      const hasAssistanceOrders = this.homeAssistanceOrdersDetails && this.homeAssistanceOrdersDetails.length > 0;
      if (hasAssistanceOrders) {
        formIsValid = formIsValid && this.formHomeAssistance.valid;
      }
    } else if (service === 'Mudanza') {
      const hasMovingOrders = this.movingOrders && this.movingOrders.length > 0;
      if (hasMovingOrders) {
        formIsValid = formIsValid && this.formMoving.valid;
      }
    } else if (service === 'Remodelación') {
      const hasRemodelingOrders = this.remodelingOrders && this.remodelingOrders.length > 0;
      if (hasRemodelingOrders) {
        formIsValid = formIsValid && this.formRemodeling.valid;
      }
    }
    return !formIsValid;
  }

  goToSignIn(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/enrollment/sign-in'], {
      queryParams: {
        redirectTo: '/complaints',
      }
    });
  }

  handleRequestTypeChange($event: DropdownChangeEvent) {
    const selectedParent = $event.value;
    const selectedRequestType = ecommerceRequestTypes.find((option) => option.parent === selectedParent);
    this.requestDetailOptions = selectedRequestType ? selectedRequestType.children : [];
  }

  handleSelectedEcommerceOrder($event: MultiSelectChangeEvent) {
    const selectedItems = $event.value;
    if (selectedItems.length === 1) {
      return;
    }
    if (selectedItems.length > 1 && (selectedItems[0].order.id !== selectedItems[selectedItems.length - 1].order.id)) {
      this.toastService.showError({
        title: 'Selección incorrecta',
        description: {
          key: 'Por favor, selecciona productos de una misma orden',
        },
      }, 3000);
      selectedItems.pop();
    }
    this.formEcommerce.controls['selectedEcommerceOrder'].setValue(selectedItems);
  }

  handleTextAreaEvent($event: Event) {
    const input = $event.target as HTMLTextAreaElement;
    this.wordCount = input.value.length;
  }

  async initialize(): Promise<void> {
    this.loadingInitialData = true;
    this.errorLoadingInitialData = false;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    try {
      this.user = await firstValueFrom(this.usersService.getCurrentUser());
      if (this.user) {
        const username = this.user.name!;
        this.userName = username.charAt(0).toUpperCase() + username.slice(1);
        this.form.patchValue({
          email: this.user.email,
          phoneNumber: this.user.phoneNumber!.replace(/\D/g, ''),
        });
        this.form.controls.email.disable();
        this.form.controls.phoneNumber.disable();
      }
    } catch (error) {
      this.errorReportingService.log('ComplaintsAndReturnsPage.initialize()', 'get-user-data', error);
      this.errorLoadingInitialData = true;
    } finally {
      this.loadingInitialData = false;
    }
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  async loadOrders(): Promise<void> {
    this.loadingOrders = true;
    this.errorLoadingOrders = false;
    let step = '';
    const service = this.form.controls.service.value as string;
    this.resetOtherForms(service);
    try {
      if (service === 'Compras para el hogar') {
        step = 'get-all-ecommerce-orders';
        this.ecommerceOrders = await firstValueFrom(this.ecommerceOrdersService.getAll());
        this.orders = this.ecommerceOrders.map((order) => {
          return {
            label: `${this.datePipe.transform(order.createdAt, 'dd-MM-yyyy')} ID Orden: ${order.id}`,
            value: order,
            items: order.items.map((item) => ({
              label: `${item.brand} - ${item.name}`,
              value: { item, order },
            })),
          };
        });
      } else if (service === 'Mudanza') {
        step = 'get-all-moving-orders';
        this.movingOrders = await firstValueFrom(this.movingOrdersService.getAll());
      } else if (this.form.controls.service.value === 'Asistencia') {
        step = 'get-all-assistance-orders';
        const homeAssistanceOrders = await firstValueFrom(this.homeAssistanceOrdersService.getAll());
        this.homeAssistanceOrdersDetails = homeAssistanceOrders.map((order: HomeAssistanceOrder) => {
          return {
            ...order,
            ...getComplementaryData(order),
          };
        });
      } else if (service === 'Remodelación') {
        step = 'get-all-remodeling-orders';
        this.remodelingOrders = await firstValueFrom(this.remodelingOrdersService.getAll());
      }
      // TODO: include warehouse orders when enabled
    } catch (error) {
      this.errorReportingService.log('ComplainsAndReturnsPage.handleServiceChange()', step, error);
      this.errorLoadingOrders = true;
    } finally {
      this.loadingOrders = false;
    }
  }

  async onSubmit(): Promise<void> {
    this.sendingForm = true;
    let step = '';
    try {
      if (this.form.invalid) {
        throw new Error('Invalid form');
      }
      if (!this.user) {
        throw new Error('User not found');
      }
      if (this.form.controls.service.value === 'Plataforma Mundo Hogar') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactPlatform'));
        step = 'submit-contact-platform';
        await this.formsService.submitContactPlatform({
          email: this.user.email,
          fatherLastName: this.user.fatherLastName ?? '',
          message: this.form.controls.message.value ?? '',
          motherLastName: this.user.motherLastName ?? '',
          name: this.user.name ?? '',
          phoneNumber: this.user.phoneNumber ?? '',
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'Asistencia') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactHomeAssistance'));
        step = 'submit-contact-home-assistance';
        await this.formsService.submitContactHomeAssistance({
          message: this.form.controls.message.value ?? '',
          orderId: this.formHomeAssistance.controls.purchase.value?.id ?? null,
          requestType: this.formHomeAssistance.controls.requestType.value ?? null,
          recaptchaToken,
        });
      } /* else if (this.form.controls.service.value === 'Bodegaje') { // TODO: enable when service is ready
        console.log(this.formWarehouse.getRawValue());
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('submitContactWarehouse'));
        step = 'submit-contact-warehouse';
        await this.formsService.submitContactWarehouse({
          ...baseData,
          orderId: this.formWarehouse.controls.purchase.value?.id ?? null,
          requestType: this.formWarehouse.controls.requestType.value ?? null,
          recaptchaToken,
        });
      }*/ else if (this.form.controls.service.value === 'Compras para el hogar') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactEcommerce'));
        step = 'submit-contact-ecommerce';
        await this.formsService.submitContactEcommerce({
          message: this.form.controls.message.value ?? '',
          orderId: this.formEcommerce.controls.selectedEcommerceOrder.value?.[0].order.id ?? null,
          orderItemIds: this.formEcommerce.controls.selectedEcommerceOrder.value?.map((item) => item.item.id) ?? [],
          requestDetail: this.formEcommerce.controls.requestDetail.value ?? null,
          requestType: this.formEcommerce.controls.requestType.value ?? null,
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'Mudanza') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactMoving'));
        step = 'submit-contact-moving';
        await this.formsService.submitContactMoving({
          message: this.form.controls.message.value ?? '',
          orderId: this.formMoving.controls.purchase.value?.id ?? null,
          requestType: this.formMoving.controls.requestType.value ?? null,
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'Remodelación') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactRemodeling'));
        step = 'submit-contact-remodeling';
        await this.formsService.submitContactRemodeling({
          message: this.form.controls.message.value ?? '',
          orderId: this.formRemodeling.controls.purchase.value?.id ?? null,
          recaptchaToken,
        });
      }
      this.toastService.showSuccess({ title: 'Submitted form', description: 'We will contact you promptly to help you in this process' });
      this.showSuccessScreen = true;
    } catch (error) {
      this.errorReportingService.log('ComplaintsAndReturnsPage.onSubmit()', step, error);
      this.toastService.showError({ title: 'Error submitting form', description: 'An error occurred while submitting the form, please try again later' });
      this.showSuccessScreen = false;
    } finally {
      this.sendingForm = false;
    }
  }

  private resetOtherForms(selectedService: string): void {
    // TODO: enable when service are ready
    /* if (selectedService !== 'Bodegas') {
      this.formWarehouse.reset();
    }*/
    if (selectedService !== 'Compras para el hogar') {
      this.formEcommerce.reset();
    }
    if (selectedService !== 'Mudanza') {
      this.formMoving.reset();
    }
    if (selectedService !== 'Asistencia') {
      this.formHomeAssistance.reset();
    }
    if (selectedService !== 'Remodelación') {
      this.formRemodeling.reset();
    }
  }
}
