<section class="main-container">
  <div class="assistance-status-container">
    <div class="title-container">
      <i class="icon-arrow-left" routerLink="/private-site/my-home-assistances"></i>
      <h2 class="title">Estado del plan asistencia</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (homeAssistanceOrderDetail) {
        <div class="assistance-status-info">
          @if (homeAssistanceOrderDetail.status === 'active') {
            <span class="status">Activo</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">Vigencia hasta: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Tu plan está activo. Si necesitas una asistencia, contáctanos.</span>
            <a class="plan-link" (click)="openPlanDetails()">Ver condiciones del plan</a>
            <div class="active-order-content">
              <div class="qr-wrapper">
                <qrcode [qrdata]="qrInformation" [width]="110" [margin]="0" errorCorrectionLevel="M"></qrcode>
              </div>
              <app-reminder title="Escanea para llamar y solicitar asistencia" class="reminder" styleClass="alert">
                <span class="subtitle">Este es tu identificador: {{ userEmail }}</span>
                <p class="description">Se solicitará tu identificador para realizar la asistencia.</p>
                <p class="description">También puedes escribirnos por WhatsApp <span class="phone-number">+52 55 8719 6412</span>, o llámanos al <span class="phone-number">55 9063 3719</span></p>
              </app-reminder>
              <app-reminder title="Importante" class="reminder-mobile" styleClass="alert">
                <span class="subtitle">Este es tu identificador: {{ userEmail }}</span>
                <p class="description">Se solicitará tu identificador para realizar la asistencia.</p>
                <p class="description">También puedes escribirnos por WhatsApp <span class="phone-number">+52 55 8719 6412</span>, o llámanos al <span class="phone-number">55 9063 3719</span></p>
              </app-reminder>
            </div>
            <div class="buttons-container">
              <app-button innerId="btn-LM4sGnQ2" label="Llámanos" href="tel:5590633719" target="_blank" styleClass="secondary" icon="phone-call" />
              @if (homeAssistanceOrderDetail.plan.id === 'plan-hogar') {
                <app-button [innerId]="'btn-EBvFQSwY-' + homeAssistanceOrderDetail.plan.id" label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar.+&type=&&type=383absent=0" target="_blank" styleClass="secondary" icon="whatsapp" />
              } @else if (homeAssistanceOrderDetail.plan.id === 'plan-mascotas') {
                <app-button [innerId]="'btn-EBvFQSwY-' + homeAssistanceOrderDetail.plan.id" label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Mascotas.+&type=&&type=383absent=0" target="_blank" styleClass="secondary" icon="whatsapp" />
              } @else if (homeAssistanceOrderDetail.plan.id === 'plan-hogar-mascotas') {
                <app-button [innerId]="'btn-EBvFQSwY-' + homeAssistanceOrderDetail.plan.id" label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar+y+Mascota.+&type=&&type=383absent=0" target="_blank" styleClass="secondary" icon="whatsapp" />
              }
            </div>
          } @else if (homeAssistanceOrderDetail.status === 'activation-process') {
            <span class="status in-process">En proceso de activación</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">Vigencia hasta: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Tu plan está en proceso de ser activado. Para cualquier duda por favor contáctanos.</span>
            <a class="plan-link" (click)="openPlanDetails()">Ver condiciones del plan</a>
          } @else if (homeAssistanceOrderDetail.status === 'finished' ) {
            <span class="status done">Finalizada</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">Vigencia hasta: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Tu plan ya finalizó. Podrás contratarlo nuevamente en el botón reactivar.</span>
            <div class="secondary-button-container">
              <!-- TODO: Add link-->
              <!--<app-button styleClass="secondary expand" label="Reactivar plan" />-->
            </div>
          } @else if (homeAssistanceOrderDetail.status === 'voided' ) {
            <span class="status done">Anulado</span>
            <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
            <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
            <span class="description-date">Vigencia hasta: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
            <span class="status-description">Tu plan ha sido anulado. Puedes contratarlo nuevamente en el botón reactivar.</span>
            <div class="secondary-button-container">
              <!-- TODO: Add link-->
              <!--<app-button styleClass="secondary expand" label="Reactivar plan" />-->
            </div>
          }
        </div>
        <div class="help-links-container">
          <h4 class="title">Ayuda con este plan de asistencia</h4>
          <a routerLink="/complaints-and-returns" [queryParams]="{
            orderId: homeAssistanceOrderDetail.id,
            service: 'home-assistance',
          }" target="_blank" class="link">Obtener ayuda</a>
          <a href="https://ayuda.mundohogar.com.mx/es/" target="_blank" class="link">Información sobre Garantías</a>
          <!-- TODO: Enable when implementing page to evaluate experience
          <a href="" target="_blank" class="link">Escribir una opinión sobre el servicio</a>
          --->
        </div>
        <div class="home-assistance-details-navigation-container" routerLink="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}/details">
          <span class="label">Detalles de la compra</span>
          <i class="icon-arrow-right"></i>
        </div>
      }
    </app-retryable-section>
  </div>
  @if (homeAssistanceOrderDetail) {
    <div class="home-assistance-purchase-container">
      <app-home-assistance-purchase-details [order]="homeAssistanceOrderDetail" [showBackButton]="false" />
    </div>
  }
</section>
@if (homeAssistanceOrderDetail) {
  <app-home-assistance-plan-details-sidebar #assistancePlanDetailsSidebar [planId]="homeAssistanceOrderDetail.plan.id" [title]="homeAssistanceOrderDetail.plan.name" />
}
