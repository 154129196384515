@if (loading) {
  <main class="warehouse-loading-page">
    <p-progressSpinner></p-progressSpinner>
  </main>
} @else {
  <section class="warehouse-main-section">
    <div class="main-container">
      <div class="left-container">
        <div class="title-container">
          <h1 class="title">{{ 'Warehouses in Mexico Easy and safe' | transloco }}</h1>
        </div>
        <div class="button-container">
          @if (enableService) {
            <app-button label="Quote warehouses" styleClass="primary" icon="warehouses" (action)="navigateToQuote()" />
          } @else {
            <app-button label="Coming soon" styleClass="primary" [disabled]="true" icon="warehouses" pTooltip="{{ 'At the moment we do not have this option available.' | transloco }}" tooltipPosition="bottom" />
          }
        </div>
      </div>
      <div class="right-container">
        <img class="main-image" src="assets/illustrations/warehouse.svg" [alt]="'Warehouse main image' | transloco" />
      </div>
    </div>
    <section class="terms-banner">
      <app-terms-banner page="warehouse" />
    </section>
    @if (enableService) {
      <section class="benefits-section">
        <div class="container">
          <div class="left-container">
            <img class="image" src="assets/illustrations/warehouseman-holding-box.svg" [alt]="'Remodeling benefits image' | transloco" />
          </div>
          <div class="right-container">
            <div class="title-container">
              <h2 class="title">{{ 'Why hire Mudango Warehouses?' | transloco }}</h2>
            </div>
            <div class="underlined-line"></div>
            <div class="benefits-container">
              <ol class="benefits">
                <li class="benefit"><i class="icon icon-pig-plus-sign"></i>{{ 'Price: If you have a cheaper quote, we will match it' | transloco }}</li>
                <li class="benefit"><i class="icon icon-relax-face"></i>{{ 'Comfort: Let\'s get your things with 5-star movings' | transloco }}</li>
                <li class="benefit"><i class="icon icon-padlock-on-shield"></i>{{ 'Security: Modern center with 24/7 security' | transloco }}</li>
              </ol>
              <div class="action-button-container">
                <app-button label="See plans" styleClass="secondary expand" (action)="navigateToQuote()" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="opportunities-section">
        <div class="opportunities-container">
          <div class="opportunity">
            <div class="left-card-container">
              <h4 class="title">{{ 'We accompany you with Mudango to your new life' | transloco }}</h4>
              <app-button label="See more" styleClass="secondary" (action)="navigateToQuote()" />
            </div>
            <div class="right-card-container">
              <img class="img" src="assets/images/young-family-moving-into-new-home.jpg" [alt]="'Opportunity image' | transloco" loading="lazy" />
            </div>
          </div>
          <div class="opportunity">
            <div class="left-card-container">
              <h4 class="title">{{ 'Choose the m² that best suits you' | transloco }}</h4>
              <app-button label="Simulate" styleClass="secondary" (action)="navigateToQuote()" />
            </div>
            <div class="right-card-container">
              <img class="img" src="assets/images/man-delivering-box.jpg" [alt]="'Opportunity image' | transloco" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      <section class="how-does-it-work-section">
        <div class="left-column">
          <h2 class="title">{{ 'How does it work?' | transloco }}</h2>
          <div class="underlined-line"></div>
          <img class="image" src="assets/images/woman-talking-smartphone-reading-document.jpg" [alt]="'Image how does it work' | transloco" />
          <p class="description">{{ 'Mudango allows you to quote and reserve warehouses and mini warehouses in Mexico quickly and easily, combining security, flexibility and affordable prices. You can see prices and make your reservation online in seconds.' | transloco }}</p>
        </div>
        <div class="right-column">
          <img class="image" src="assets/images/woman-talking-smartphone-reading-document.jpg" [alt]="'Image how does it work' | transloco" />
        </div>
      </section>
      <section class="warehouses-and-mini-warehouses-section">
        <div class="container">
          <div class="left-container">
            <div class="title-container">
              <span class="title">{{ 'We also have warehouses and mini warehouses!' | transloco }}</span>
              <hr class="underline">
            </div>
          </div>
          <div class="right-container">
            <div class="feature-content">
              <i class="icon icon-checkmark-in-a-circle"></i>
              <div class="feature">
                <h4 class="title">{{ 'Prices' | transloco }}</h4>
                <p class="description">{{ 'We have the best prices for your warehouse or mini warehouse rental. Get to know our flexible, modern and safe warehouses. Quote with Mudango and you will not regret it.' | transloco }}</p>
              </div>
            </div>
            <div class="feature-content">
              <i class="icon icon-checkmark-in-a-circle"></i>
              <div class="feature">
                <h4 class="title">{{ 'Warehouses + Moving' | transloco }}</h4>
                <p class="description">{{ 'At Mudango Bodegas we not only give you a safe rental at a good price, but we also offer you transportation to and from the warehouse with our moving solution. Don\'t you know it?' | transloco }}</p>
              </div>
            </div>
            <div class="feature-content">
              <i class="icon icon-checkmark-in-a-circle"></i>
              <div class="feature">
                <h4 class="title">{{ 'We are different' | transloco }}</h4>
                <p class="description">{{ 'The prices of our warehouses vary depending on the actual m² you use. Our warehouses are flexible and if you remove some things, the price of your rental decreases.' | transloco }}</p>
              </div>
            </div>
            <div class="feature-content">
              <i class="icon icon-checkmark-in-a-circle"></i>
              <div class="feature">
                <h4 class="title">{{ 'Additional insurance' | transloco }}</h4>
                <p class="description">{{ 'Our warehouses have 24/7 security, but for your peace of mind, we also have additional fire and theft insurance.' | transloco }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- TODO: Talk with business about this translation -->
      <p class="see-more">{{ 'To see more information about our warehouses,' | transloco }} <a class="link">{{ 'click here' | transloco }}</a></p>
      <section class="complements-warehouse-section">
        <div class="content-container">
          <h3 class="title">{{ 'Complement your Warehouse' | transloco }}</h3>
          <p class="description">{{ 'If you need help with the transfer to the warehouse, you can quote your move in Mudango in less than 2 minutes and get the warehouse + moving service that suits your needs for the best price.' | transloco }}</p>
          <div class="action-button-container">
            <app-button label="Quote your moving" styleClass="primary expand" (action)="navigateToQuote()" />
          </div>
        </div>
      </section>
      <article class="why-platform-article">
        <div class="title-container">
          <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
          <hr class="underline">
          <div class="benefits-container">
            <div class="benefit">
              <i class="icon-monitor"></i>
              <div class="benefit-content">
                <h4 class="label">{{ 'All in one place' | transloco }}</h4>
                <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
              </div>
            </div>
            <div class="benefit">
              <i class="icon-jewel"></i>
              <div class="benefit-content">
                <h4 class="label">{{ 'Exclusive benefits' | transloco }}</h4>
                <span class="description">{{ 'Exclusive financing alternatives for Santander customers and much more.' | transloco }}</span>
              </div>
            </div>
            <div class="benefit">
              <i class="icon-security-profile"></i>
              <div class="benefit-content">
                <h4 class="label">{{ 'The best suppliers' | transloco }}</h4>
                <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
              </div>
            </div>
            <div class="benefit">
              <i class="icon-thumbs-up"></i>
              <div class="benefit-content">
                <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
                <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
              </div>
            </div>
          </div>
          <div class="become-a-customer">
            <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">{{ 'click here' | transloco }}.</a><p>
          </div>
        </div>
      </article>
      <section class="faq-article">
        <h2 class="title">{{ 'FAQ' | transloco }}</h2>
        <div class="faq-container">
          <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
            <p-accordionTab class="accordion-tab" [header]="'How can I rent a warehouse online?' | transloco" iconPos="end">
              <div class="text-container">
                <p class="text">{{ 'It is very simple, you just have to request in our chat, an entry of items at your service. Our team will consult with you so you can decide if you want it to be picked up by us or brought to the warehouse by you. Once you enter the additional items, the rent will be updated automatically.' | transloco }}</p>
              </div>
            </p-accordionTab>
            <p-accordionTab class="accordion-tab" [header]="'What documents do I need to rent a warehouse?' | transloco" iconPos="end">
              <div class="text-container">
                <p class="text">{{ 'We do not request documents, only the signing of the contract for your service, this requirement is activated once you make the first payment for the reservation.' | transloco }}</p>
              </div>
            </p-accordionTab>
            <p-accordionTab class="accordion-tab" [header]="'Can I access the warehouse at any time?' | transloco" iconPos="end">
              <div class="text-container">
                <p class="text">{{ 'You can request visits to our Warehouse through the coordination chat of our ally, Mudango, remember that your inventory will be available digitally, and you can hire pickup/dispatch services for greater convenience.' | transloco }}</p>
              </div>
            </p-accordionTab>
            <p-accordionTab class="accordion-tab" [header]="'What if I need more space?' | transloco" iconPos="end">
              <div class="text-container">
                <p class="text">{{ 'It\'s very simple, you just have to enter our chat and request an additional in your service. Our team will consult with you so you can decide if you want it to be picked up by us or brought to the warehouse by you. Once you enter the additional items, the rent will be updated automatically.' | transloco }}</p>
              </div>
            </p-accordionTab>
            <p-accordionTab class="accordion-tab" [header]="'Are the warehouses insured?' | transloco" iconPos="end">
              <div class="text-container">
                <p class="text">{{ 'Mundo Hogar in alliance with Mudango not only offers you warehouse service, but a complete service that accompanies you in every step of the process. From digital quotes to the safe transfer of your belongings, everything is designed to give you the greatest comfort and peace of mind. The warehouse, strategically located in Mexico City, has 24/7 surveillance and insurance options against fire and theft, ensuring that your belongings are always protected.' | transloco }}</p>
              </div>
            </p-accordionTab>
            <p-accordionTab class="accordion-tab" [header]="'What is prohibited to store in warehouses?' | transloco" iconPos="end">
              <div class="text-container">
                <p class="text">{{ 'In the terms and conditions you will find our storage regulations, we attach one link so you can consult it.' | transloco }} <a class="link" href="https://www.mudango.com/politics/terminos-y-condiciones-mexico.pdf" target="_blank" rel="noopener">{{ 'Terms and Conditions' | transloco }} Mudango</a></p>
              </div>
            </p-accordionTab>
            <p-accordionTab class="accordion-tab" [header]="'Can I cancel my rental early?' | transloco" iconPos="end">
              <div class="text-container">
                <p class="text">{{ 'We do not have permanence clauses. You can cancel whenever you want.' | transloco }}</p>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
        <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
      </section>
    }
  </section>
}