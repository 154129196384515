import { HomeAssistancePlan } from '@homein-hogar-server';
import { constants } from '../../constants';

export const HOME_ASSISTANCE_PLANS_MOCK: HomeAssistancePlan[] = [
  {
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    details: [
      {
        description: 'Reparación de fallas por rotura o fuga en las instalaciones hidráulicas, sanitarias y gas que se encuentren en el interior del domicilio y requieran reparación de emergencia por causar daño al inmueble con uso habitacional del Usuario. Únicamente se hará cuando correspondan de la salida del tanque de gas a los muebles, como estufas, calentadores.',
        subtitle: '4 eventos hasta $1,200',
        title: 'Plomería'
      },
      {
        description: 'Restablecimiento del servicio de energía eléctrica por causas de un corto circuito cuando la falla se origine en el interior del inmueble, y que corresponda al domicilio particular registrado en el programa del Usuario. La reparación o cambio de tableros eléctricos, interruptores de seguridad, centros de carga o fusibles dañados y pastillas termo magnéticas dañados por corto circuito o sobrecarga, sólo se hará en aquellas partes que pertenezcan a la instalación eléctrica del propio y que corresponda al domicilio particular del Usuario.',
        subtitle: '4 eventos hasta $1,200',
        title: 'Electricidad'
      },
      {
        description: 'Este servicio incluye el cambio de vidrios rotos en puertas y ventanas que den a la calle y atenten contra la seguridad de la casa-habitación del Usuario.',
        subtitle: '4 eventos hasta $1,200',
        title: 'Vidriería'
      },
      {
        description: 'Apertura de cerraduras a consecuencia de extravío u olvido de llaves, reparación y/o sustitución de cerraduras dañadas por descompostura, accidente, robo o intento del mismo, siempre que el servicio deba hacerse en las puertas exteriores de acceso al inmueble, que atente contra la seguridad de la casa-habitación del Usuario.',
        subtitle: '4 eventos hasta $1,200',
        title: 'Cerrajería'
      },
      {
        description: 'Iké coordinará servicios para la realización de tareas en el hogar, que no son lo suficientemente complejas como para requerir profesionales certificados, básicamente aquello que se pueda realizar con herramientas de uso común en el hogar, como: Conexión e instalación de aparatos electrónicos; Armado de muebles prefabricados.; Pintores, jardineros y fumigadores, solo en Ciudad de México y zona metropolitana.; Instalación de cámaras de seguridad.; Instalación de lámparas y apagadores.; Impermeabilización.; Colocación cuadros, repisas, estanterías y soportes de pared.; Cambio de puertas, poner tope de puertas, cierra puertas, bisagras.; Cambio de pernos y manijas de puertas.; Cambio de grifos, tomas de agua (plomería); Accesorios de baño y cocina.; Reparación e instalación de herrería.',
        subtitle: 'Ilimitado con costo para el usuario',
        title: 'Handyman'
      },
      {
        description: 'Los técnicos especialistas en tecnología brindarán orientación técnica en un horario de 09:00 a 21:00 horas, los 7 días de la semana, a través de una videollamada sobre el uso básico de los siguientes servicios de primer nivel.',
        subtitle: 'ilimitado',
        title: 'Tech buddy online'
      },
      {
        description: 'Reparaciones que sean consecuencia de un servicio de emergencia, como los siguientes: Demoliciones y/o aperturas de ranuras y huecos para descubrir tuberías de agua, drenaje o eléctricas.; Resanes de ranuras y huecos con aplanados de cemento o yeso en paredes, pisos o losas.; Colocar y fijar muebles de baño, fregaderos y lavaderos, cuando se presente un cambio obligado de emergencia por fractura o desprendimiento por accidente; en estos trabajos se incluye el desmantelamiento o el retiro del mueble dañado.',
        subtitle: '1 evento hasta $800',
        title: 'Albañilería'
      },
      {
        description: 'Se brindará apoyo telefónico al Usuario para reportar las fugas de gas a las autoridades competentes, así como asesoría con respecto a las acciones emergentes a seguir. A solicitud del Usuario, se enviará un especialista al domicilio para que cotice cualquier trabajo de reparación, ampliación, mantenimiento o remodelación que éste requiera sin que se trate de una situación de emergencia.',
        subtitle: 'ilimitado',
        title: 'Reporte de fugas'
      },
      {
        description: 'Se enviará al domicilio del Usuario un abogado para efecto de que le proporcione la asesoría legal en caso de robo en los bienes que se encuentren dentro del domicilio, así como al usuario para el levantamiento del acta correspondiente ante el agente del ministerio público o cualquier otra autoridad competente.',
        subtitle: '1 evento',
        title: 'Asesoría ante robo casa habitación'
      },
      {
        description: 'Se proporcionará al Usuario asesoría legal telefónica las 24 horas del día de forma ilimitada, los 365 días del año, para cualquier consulta telefónica relacionada con ramas del derecho.',
        subtitle: 'Ilimitado',
        title: 'Orientación legal telefónica'
      },
      {
        description: 'Se proporcionará al Usuario ayuda para la instalación de: Controles de iluminación; Cerraduras digitales; Controles para apertura de puertas eléctricas; Interruptores digitales; Aire acondicionado, sistemas de sonido y calefactores; Asistentes virtuales; Cámaras de seguridad; Sensores de movimiento en puertas y ventanas.',
        subtitle: '1 evento hasta $2500',
        title: 'Hogar inteligente '
      },
      {
        description: 'El Usuario contará con servicio de: lavado, aspirado y desinfección de: colchones, cojines, almohadas, edredones, tapetes y sillones.',
        subtitle: '1 evento sin costo, el resto con costo preferencial',
        title: 'Deep cleaning'
      }
    ],
    description: 'Plomería, electricidad, vidriería y muchos servicios más para tu hogar.',
    id: 'plan-hogar',
    name: 'Plan Hogar',
    partnerPlanId: '1',
    // TODO F&F: add more realistic pricing values
    pricing: {
      external: {
        '1-month': {
          debit: {
            discountAmount: 21,
            discountPercentage: 0.2,
            monthlyPayment: 84,
            originalPrice: 105,
            price: 94,
            term: 1,
          },
          credit: {
            discountAmount: 21,
            discountPercentage: 0.2,
            monthlyPayment: 84,
            originalPrice: 105,
            price: 94,
            term: 1,
          },
          'credit-3': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 3,
          },
          'credit-6': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 6,
          },
          'credit-12': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 12,
          },
          'credit-18': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 18,
          },
          'credit-24': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 36,
          },
          'credit-36': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 36,
          },
        },
        '6-months': {
          debit: {
            discountAmount: 126,
            discountPercentage: 0.2,
            monthlyPayment: 504,
            originalPrice: 630,
            price: 504,
            term: 1,
          },
          credit: {
            discountAmount: 126,
            discountPercentage: 0.2,
            monthlyPayment: 504,
            originalPrice: 630,
            price: 504,
            term: 1,
          },
          'credit-3': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 3,
          },
          'credit-6': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 6,
          },
          'credit-12': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 12,
          },
          'credit-18': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 36,
          },
          'credit-24': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 36,
          },
          'credit-36': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 36,
          },
        },
        '12-months': {
          debit: {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 108,
            originalPrice: 1260,
            price: 108,
            term: 1,
          },
          credit: {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 108,
            originalPrice: 1260,
            price: 108,
            term: 1,
          },
          'credit-3': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 3,
          },
          'credit-6': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 6,
          },
          'credit-12': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 12,
          },
          'credit-18': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 18,
          },
          'credit-24': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 36,
          },
          'credit-36': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 36,
          },
        },
      },
    },
    status: 'active',
    termsAndConditionsUrl: 'https://storage.googleapis.com/santandermx-ha-prod-public/home-assistance-plans/terminos-y-condiciones-plan-hogar.pdf',
    updatedAt: new Date(),
  },
  {
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    details: [
      {
        description: 'En caso de que el Usuario lo requiera, se coordinará una cita con los médicos veterinarios de Iké y darán orientación sobre padecimientos y/o cuidados de la mascota.',
        subtitle: '1 evento hasta $500.00 MXN',
        title: 'Consulta veterinaria'
      },
      {
        description: 'Recibe orientación veterinaria en un horario de 8:00 a 20:00 horas, de lunes a viernes, a través de videollamada. El veterinario toma su historial y síntomas, no se brindan recetas, solo informativos para medidas generales. La sesión tendrá una duración máxima de 20 (veinte) minutos.',
        subtitle: 'Ilimitado',
        title: 'Veterinaria online'
      },
      {
        description: 'El Usuario tendrá acceso a la red de proveedores para obtener precios preferenciales en alimentos, vacunas y estéticas. Se darán referencias de clínicas y médicos veterinarios. A solicitud del Usuario se coordinará la cita. El costo total de la consulta la deberá cubrir el Usuario. Información sobre albergues y rescatistas. En las principales ciudades hasta donde la infraestructura médica de la localidad lo permita.',
        title: 'Red de descuentos y referencias clínicas'
      },
      {
        description: 'Red de descuentos: El Usuario tendrá acceso a la red de proveedores para obtener precios preferenciales en alimentos, vacunas y estéticas. Referencias clínicas: Se darán referencias de clínicas y médicos veterinarios. A solicitud del Usuario se coordinará la cita. El costo total de la consulta la deberá cubrir el Usuario. Información sobre albergues y rescatistas. En las principales ciudades hasta donde la infraestructura médica de la localidad lo permita.',
        subtitle: '1 evento hasta $300.00 MXN',
        title: 'Consulta veterinaria para desparasitación y vacuna antirrábica'
      },
      {
        description: 'En caso de que el Usuario haya sido demandado por daños a terceros causados por su mascota se le brindará orientación telefónica de abogados las 24 horas del día los 365 días del año.',
        subtitle: 'Ilimitado',
        title: 'Asesoría legal'
      },
      {
        description: 'Se ofrecerá el servicio de check up que incluye los siguientes estudios médicos en las principales ciudades de la República Mexicana: Exploración física; Química sanguínea de 6 elementos; Estudio coproparasitológico.',
        title: 'Check up mascota'
      },
      {
        description: 'En caso de fallecimiento de la mascota se coordinará el servicio de: Cremación individual; Urna; Recolección a domicilio programado; Recuperación de cenizas en un máximo de 24 a 48 horas; Certificado de cremación. Servicio en las principales ciudades y hasta donde la infraestructura de la localidad lo permita. De lunes a domingo de 08:00 a 19:00 horas.',
        subtitle: '1 evento',
        title: 'Pet funeraria'
      },
      {
        description: 'Se ofrecerá apoyo telefónico sobre cómo vivir el duelo de la pérdida de una mascota. Las 24 horas del día los 365 días del año.',
        subtitle: 'Ilimitado',
        title: 'Tanatología'
      }
    ],
    description: 'Incluye veterinario presencial y online, descuentos, y más.',
    id: 'plan-mascotas',
    name: 'Plan Mascotas',
    partnerPlanId: '2',
    // TODO F&F: change the pricing values to real values
    pricing: {
      external: {
        '1-month': {
          debit: {
            discountAmount: 21,
            discountPercentage: 0.2,
            monthlyPayment: 84,
            originalPrice: 105,
            price: 94,
            term: 1,
          },
          credit: {
            discountAmount: 21,
            discountPercentage: 0.2,
            monthlyPayment: 84,
            originalPrice: 105,
            price: 94,
            term: 1,
          },
          'credit-3': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 3,
          },
          'credit-6': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 6,
          },
          'credit-12': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 12,
          },
          'credit-18': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 18,
          },
          'credit-24': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 36,
          },
          'credit-36': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 1,
          },
        },
        '6-months': {
          debit: {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 504,
            originalPrice: 630,
            price: 504,
            term: 1,
          },
          credit: {
            discountAmount: 126,
            discountPercentage: 0.2,
            monthlyPayment: 504,
            originalPrice: 630,
            price: 504,
            term: 1,
          },
          'credit-3': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 3,
          },
          'credit-6': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 6,
          },
          'credit-12': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 12,
          },
          'credit-18': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 36,
          },
          'credit-24': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 36,
          },
          'credit-36': {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 1,
          },
        },
        '12-months': {
          debit: {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 108,
            originalPrice: 1260,
            price: 108,
            term: 1,
          },
          credit: {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 108,
            originalPrice: 1260,
            price: 108,
            term: 1,
          },
          'credit-3': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 3,
          },
          'credit-6': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 6,
          },
          'credit-12': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 12,
          },
          'credit-18': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 18,
          },
          'credit-24': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 36,
          },
          'credit-36': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 1,
          },
        },
      },
    },
    status: 'active',
    termsAndConditionsUrl: 'https://storage.googleapis.com/santandermx-ha-prod-public/home-assistance-plans/terminos-y-condiciones-plan-mascotas.pdf',
    updatedAt: new Date(),
  },
  {
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    details: [
      {
        description: 'Reparación de fallas por rotura o fuga en las instalaciones hidráulicas, sanitarias y gas que se encuentren en el interior del domicilio y requieran reparación de emergencia por causar daño al inmueble con uso habitacional del Usuario. Únicamente se hará cuando correspondan de la salida del tanque de gas a los muebles, como estufas, calentadores.',
        subtitle: '4 eventos hasta $1,200.00 MXN',
        title: 'Plomería'
      },
      {
        description: 'Restablecimiento del servicio de energía eléctrica por causas de un corto circuito cuando la falla se origine en el interior del inmueble, y que corresponda al domicilio particular registrado en el programa del Usuario. La reparación o cambio de tableros eléctricos, interruptores de seguridad, centros de carga o fusibles dañados y pastillas termo magnéticas dañados por corto circuito o sobrecarga, sólo se hará en aquellas partes que pertenezcan a la instalación eléctrica del propio y que corresponda al domicilio particular del Usuario.',
        subtitle: '4 eventos hasta $1,200.00 MXN',
        title: 'Electricidad'
      },
      {
        description: 'Este servicio incluye el cambio de vidrios rotos en puertas y ventanas que den a la calle y atenten contra la seguridad de la casa-habitación del Usuario.',
        subtitle: '4 eventos hasta $1,200.00 MXN',
        title: 'Vidriería'
      },
      {
        description: 'Apertura de cerraduras a consecuencia de extravío u olvido de llaves, reparación y/o sustitución de cerraduras dañadas por descompostura, accidente, robo o intento del mismo, siempre que el servicio deba hacerse en las puertas exteriores de acceso al inmueble, que atente contra la seguridad de la casa-habitación del Usuario.',
        subtitle: '4 eventos hasta $1,200.00 MXN',
        title: 'Cerrajería'
      },
      {
        description: 'Iké coordinará servicios para la realización de tareas en el hogar, que no son lo suficientemente complejas como para requerir profesionales certificados, básicamente aquello que se pueda realizar con herramientas de uso común en el hogar, como: Conexión e instalación de aparatos electrónicos; Armado de muebles prefabricados.; Pintores, jardineros y fumigadores, solo en Ciudad de México y zona metropolitana.; Instalación de cámaras de seguridad.; Instalación de lámparas y apagadores.; Impermeabilización.; Colocación cuadros, repisas, estanterías y soportes de pared.; Cambio de puertas, poner tope de puertas, cierra puertas, bisagras.; Cambio de pernos y manijas de puertas.; Cambio de grifos, tomas de agua (plomería); Accesorios de baño y cocina.; Reparación e instalación de herrería.',
        subtitle: 'Ilimitado con costo para el usuario',
        title: 'Handyman'
      },
      {
        description: 'Los técnicos especialistas en tecnología brindarán orientación técnica en un horario de 09:00 a 21:00 horas, los 7 días de la semana, a través de una videollamada sobre el uso básico de los siguientes servicios de primer nivel.',
        subtitle: 'ilimitado',
        title: 'Tech buddy online'
      },
      {
        description: 'Reparaciones que sean consecuencia de un servicio de emergencia, como los siguientes: Demoliciones y/o aperturas de ranuras y huecos para descubrir tuberías de agua, drenaje o eléctricas.; Resanes de ranuras y huecos con aplanados de cemento o yeso en paredes, pisos o losas.; Colocar y fijar muebles de baño, fregaderos y lavaderos, cuando se presente un cambio obligado de emergencia por fractura o desprendimiento por accidente; en estos trabajos se incluye el desmantelamiento o el retiro del mueble dañado.',
        subtitle: '1 evento hasta $800.00 MXN',
        title: 'Albañilería'
      },
      {
        description: 'Se brindará apoyo telefónico al Usuario para reportar las fugas de gas a las autoridades competentes, así como asesoría con respecto a las acciones emergentes a seguir. A solicitud del Usuario, se enviará un especialista al domicilio para que cotice cualquier trabajo de reparación, ampliación, mantenimiento o remodelación que éste requiera sin que se trate de una situación de emergencia.',
        subtitle: 'ilimitado',
        title: 'Reporte de fugas'
      },
      {
        description: 'Se enviará al domicilio del Usuario un abogado para efecto de que le proporcione la asesoría legal en caso de robo en los bienes que se encuentren dentro del domicilio, así como al usuario para el levantamiento del acta correspondiente ante el agente del ministerio público o cualquier otra autoridad competente.',
        subtitle: '1 evento',
        title: 'Asesoría ante robo casa habitación'
      },
      {
        description: 'Se proporcionará al Usuario asesoría legal telefónica las 24 horas del día de forma ilimitada, los 365 días del año, para cualquier consulta telefónica relacionada con ramas del derecho.',
        subtitle: 'Ilimitado',
        title: 'Orientación legal telefónica'
      },
      {
        description: 'Se proporcionará al Usuario ayuda para la instalación de: Controles de iluminación; Cerraduras digitales; Controles para apertura de puertas eléctricas; Interruptores digitales; Aire acondicionado, sistemas de sonido y calefactores; Asistentes virtuales; Cámaras de seguridad; Sensores de movimiento en puertas y ventanas.',
        subtitle: '1 evento hasta $2,500.00 MXN',
        title: 'Hogar inteligente '
      },
      {
        description: 'El Usuario contará con servicio de: lavado, aspirado y desinfección de: colchones, cojines, almohadas, edredones, tapetes y sillones.',
        subtitle: '1 evento sin costo, el resto con costo preferencial',
        title: 'Deep cleaning'
      },
      {
        description: 'En caso de que el Usuario lo requiera, se coordinará una cita con los médicos veterinarios de Iké y darán orientación sobre padecimientos y/o cuidados de la mascota.',
        subtitle: '1 evento hasta $500.00 MXN',
        title: 'Consulta veterinaria'
      },
      {
        description: 'Recibe orientación veterinaria en un horario de 8:00 a 20:00 horas, de lunes a viernes, a través de videollamada. El veterinario toma su historial y síntomas, no se brindan recetas, solo informativos para medidas generales. La sesión tendrá una duración máxima de 20 (veinte) minutos.',
        subtitle: 'Ilimitado',
        title: 'Veterinaria online'
      },
      {
        description: 'El Usuario tendrá acceso a la red de proveedores para obtener precios preferenciales en alimentos, vacunas y estéticas. Se darán referencias de clínicas y médicos veterinarios. A solicitud del Usuario se coordinará la cita. El costo total de la consulta la deberá cubrir el Usuario. Información sobre albergues y rescatistas. En las principales ciudades hasta donde la infraestructura médica de la localidad lo permita.',
        title: 'Red de descuentos y referencias clínicas'
      },
      {
        description: 'Red de descuentos: El Usuario tendrá acceso a la red de proveedores para obtener precios preferenciales en alimentos, vacunas y estéticas. Referencias clínicas: Se darán referencias de clínicas y médicos veterinarios. A solicitud del Usuario se coordinará la cita. El costo total de la consulta la deberá cubrir el Usuario. Información sobre albergues y rescatistas. En las principales ciudades hasta donde la infraestructura médica de la localidad lo permita.',
        subtitle: '1 evento hasta $300.00 MXN',
        title: 'Consulta veterinaria para desparasitación y vacuna antirrábica'
      },
      {
        description: 'En caso de que el Usuario haya sido demandado por daños a terceros causados por su mascota se le brindará orientación telefónica de abogados las 24 horas del día los 365 días del año.',
        subtitle: 'Ilimitado',
        title: 'Asesoría legal'
      },
      {
        description: 'Se ofrecerá el servicio de check up que incluye los siguientes estudios médicos en las principales ciudades de la República Mexicana: Exploración física; Química sanguínea de 6 elementos; Estudio coproparasitológico.',
        title: 'Check up mascota'
      },
      {
        description: 'En caso de fallecimiento de la mascota se coordinará el servicio de: Cremación individual; Urna; Recolección a domicilio programado; Recuperación de cenizas en un máximo de 24 a 48 horas; Certificado de cremación. Servicio en las principales ciudades y hasta donde la infraestructura de la localidad lo permita. De lunes a domingo de 08:00 a 19:00 horas.',
        subtitle: '1 evento',
        title: 'Pet funeraria'
      },
      {
        description: 'Se ofrecerá apoyo telefónico sobre cómo vivir el duelo de la pérdida de una mascota. Las 24 horas del día los 365 días del año.',
        subtitle: 'Ilimitado',
        title: 'Tanatología'
      }
    ],
    description: 'Para la tranquilidad de tu hogar y mascotas, revisa este plan que tiene ambos servicios.',
    id: 'plan-hogar-mascotas',
    name: 'Plan Hogar + Mascotas',
    partnerPlanId: '3',
    // TODO F&F: change the pricing values to real values
    pricing: {
      external: {
        '1-month': {
          debit: {
            discountAmount: 21,
            discountPercentage: 0.2,
            monthlyPayment: 84,
            originalPrice: 105,
            price: 94,
            term: 1,
          },
          credit: {
            discountAmount: 21,
            discountPercentage: 0.2,
            monthlyPayment: 84,
            originalPrice: 105,
            price: 94,
            term: 1,
          },
          'credit-3': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 3,
          },
          'credit-6': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 6,
          },
          'credit-12': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 12,
          },
          'credit-18': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 18,
          },
          'credit-24': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 36,
          },
          'credit-36': {
            discountAmount: 30,
            discountPercentage: 0.2,
            monthlyPayment: 3.37,
            originalPrice: 151,
            price: 121,
            term: 1,
          },
        },
        '6-months': {
          debit: {
            discountAmount: 126,
            discountPercentage: 0.2,
            monthlyPayment: 504,
            originalPrice: 630,
            price: 504,
            term: 1,
          },
          credit: {
            discountAmount: 126,
            discountPercentage: 0.2,
            monthlyPayment: 504,
            originalPrice: 630,
            price: 504,
            term: 1,
          },
          'credit-3': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 3,
          },
          'credit-6': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 6,
          },
          'credit-12': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 12,
          },
          'credit-18': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 36,
          },
          'credit-24': {
            discountAmount: 180,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 36,
          },
          'credit-36': {
            discountAmount: 126,
            discountPercentage: 0.2,
            monthlyPayment: 20.17,
            originalPrice: 906,
            price: 726,
            term: 1,
          },
        },
        '12-months': {
          debit: {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 108,
            originalPrice: 1260,
            price: 108,
            term: 1,
          },
          credit: {
            discountAmount: 252,
            discountPercentage: 0.2,
            monthlyPayment: 108,
            originalPrice: 1260,
            price: 108,
            term: 1,
          },
          'credit-3': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 3,
          },
          'credit-6': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 6,
          },
          'credit-12': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 12,
          },
          'credit-18': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 18,
          },
          'credit-24': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 36,
          },
          'credit-36': {
            discountAmount: 360,
            discountPercentage: 0.2,
            monthlyPayment: 40.33,
            originalPrice: 1812,
            price: 1452,
            term: 1,
          },
        },
      },
    },
    status: 'active',
    termsAndConditionsUrl: 'https://storage.googleapis.com/santandermx-ha-prod-public/home-assistance-plans/terminos-y-condiciones-plan-hogar-mascotas.pdf',
    updatedAt: new Date(),
  }
];