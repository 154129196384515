@if (loading) {
  <main class="moving-loading-page">
    <p-progressSpinner></p-progressSpinner>
  </main>
} @else {
  <section class="main">
    <div class="main-container">
      <div class="right-side">
        <div class="title-container">
          <p class="on-title-text">Beneficios exclusivos para clientes <strong>Santander</strong></p>
          <h1 class="title">Mudanzas sin estrés a tu nuevo hogar</h1>
        </div>
        <div class="button-container">
          <app-button label="Cotizar mudanza" (action)="navigateToQuote()" styleClass="primary" icon="truck" />
        </div>
      </div>
      <div class="left-side">
        <img class="main-image" src="assets/images/moving-main.svg" [alt]="'Moving main image' | transloco " />
      </div>
    </div>
  </section>
  <section class="terms-banner">
    <app-terms-banner page="moving" />
  </section>
  <section class="moving-benefits">
    <div class="container">
      <div class="left-container">
        <img src="assets/images/moving-steps.svg" class="moving-image" alt="Imagen de pasos de tu mudanza" />
      </div>
      <div class="right-container">
        <div class="title-container">
          <h2 class="title">Tu mudanza en solo 3 pasos</h2>
        </div>
        <div class="benefits-container">
          <ol class="benefits">
            <li class="benefit">Ingresa los datos para cotizar tu mudanza.</li>
            <li class="benefit">Selecciona el tipo de servicio que necesitas.</li>
            <li class="benefit">Todo listo, nos ocuparemos del resto.</li>
          </ol>
        </div>
        <div class="button-container">
          <app-button label="Cotiza gratis" (action)="navigateToQuote()" styleClass="secondary expand" />
        </div>
      </div>
    </div>
  </section>
  <section class="how-moving-works-section">
    <div class="container">
      <h4 class="title">¿Cómo funciona?</h4>
      <div class="how-moving-works">
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">Cotiza, escoge el plan que se adapte mejor a tus necesidades.</p>
        </div>
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">Paga un anticipo para reservar tu mudanza en el día seleccionado.</p>
        </div>
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">Recibe la visita de tu mudancero días antes de la mudanza para asegurarse que todo está listo.</p>
        </div>
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">¡Llegó el día! Realiza tu mudanza y paga el monto restante a tu mudancero.</p>
        </div>
      </div>
    </div>
  </section>
  <section class="moving-banner-section">
    <div class="banner-container">
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">{{ "Your next move with up to 15% discount" | transloco }}</h4>
          <app-button label="Cotizar" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img" src="assets/images/moving-banner-1.png" alt="Imagen del banner de mudanza" loading="lazy" />
        </div>
      </div>
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">Protege cada espacio de tu siguiente hogar</h4>
          <app-button label="Ver plan" routerLink="/home-assistance" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img second-banner" src="assets/images/moving-banner-2.png" alt="Imagen del banner de mudanza" loading="lazy" />
        </div>
      </div>
    </div>
  </section>
  <section class="why-trust-moving-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <span class="title">¿Por qué confiamos en<img class="moving-logo" alt="Logo Mudango" src="assets/images/mudango-logo.svg" />?</span>
          <hr class="underline">
        </div>
      </div>
      <div class="right-container">
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Centrados en ti</h4>
            <p class="description">Presupuesto sin sorpresas y a la medida de lo que necesitas.</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Experiencia y capacidad</h4>
            <p class="description">Más de 30,000 mudanzas al año en las principales ciudades de la región.</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Cobertura local</h4>
            <p class="description">Presencia en las principales ciudades y zonas del país.</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Trayectoria</h4>
            <p class="description">Pioneros en mudanzas online desde el 2017 con presencia en México, Colombia y Chile.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="coverage-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <h2 class="title">Estamos en las principales ciudades de México</h2>
        </div>
        <div class="left-button-container">
          <app-button label="Consulta tu dirección" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
      </div>
      <div class="right-container">
        <ul class="cities-list">
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Ciudad de México</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Guadalajara</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Monterrey</span>
          </li>
        </ul>
        <p class="more-info">y más... consulta y cotiza tu mudanza</p>
      </div>
      <div class="button-container">
        <app-button label="Consulta tu dirección" (action)="navigateToQuote()" styleClass="secondary" />
      </div>
    </div>
  </section>
  <article class="why-platform-article">
    <div class="title-container">
      <h2 class="title">¿Por qué Mundo Hogar?</h2>
      <hr class="underline">
      <div class="benefits-container">
        <div class="benefit">
          <i class="icon-monitor"></i>
          <div class="benefit-content">
            <h4 class="label">Todo en un mismo lugar</h4>
            <span class="description">Encuentra todo lo que necesitas para tu hogar, en un mismo portal 100% online.</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-jewel"></i>
          <div class="benefit-content">
            <h4 class="label">Beneficios exclusivos</h4>
            <span class="description">Alternativas de financiamiento exclusivas para clientes Santander y mucho más.</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-security-profile"></i>
          <div class="benefit-content">
            <h4 class="label">Los mejores proveedores</h4>
            <span class="description">Proveedores calificados, servicios garantizados y métodos de pagos seguros para ti.</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-thumbs-up"></i>
          <div class="benefit-content">
            <h4 class="label">Simple y transparente</h4>
            <span class="description">Gestiona y haz el seguimiento online de tus proyectos en pocos clics.</span>
          </div>
        </div>
      </div>
      <div class="become-a-customer">
        <p class="customer-benefit">Beneficios exclusivos para clientes Santander. Si aún no eres cliente, haz <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">clic aquí.</a><p>
      </div>
    </div>
  </article>
  <article class="faq-article">
    <h2 class="title">Preguntas frecuentes</h2>
    <div class="faq-container">
      <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
        <p-accordionTab class="accordion-tab" header="¿Por qué debo pagar una reserva?" iconPos="end">
          <div class="text-container">
            <p class="text">La reserva es un pago del precio total, que garantiza la disponibilidad para su mudanza y le da tranquilidad. La reserva es 100% reembolsable en caso de que cancele su mudanza, y 100% flexible para cambios de fecha, sin penalizaciones.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'What if I don\'t have a definite date for my move?' | transloco }}" iconPos="end">
          <div class="text-container">
            <p class="text">No hay problema, puedes reservar y cambiar la fecha cuantas veces quieras. Queremos darte tranquilidad mental.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Cómo aseguro que el volumen ingresado sea el correcto?" iconPos="end">
          <div class="text-container">
            <p class="text">Nuestro algoritmo está basado en las miles de mudanzas que hacemos cada año y aprende constantemente. Si has ingresado correctamente el inventario, el volumen será el correcto. De todas maneras, una vez reservado, agendaremos una visita de confirmación de volumen para asegurar que todo esté bien.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Quién realiza mi mudanza?" iconPos="end">
          <div class="text-container">
            <p class="text">El servicio de mudanza es realizado por nuestros Moving Partners, que son equipos preseleccionados y constantemente capacitados por nosotros. Los equipos están clasificados según el tipo de servicio que deseas contratar, y trabajan codo a codo con nosotros en nuestra plataforma.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Qué es la Reserva de mi mudanza?" iconPos="end">
          <div class="text-container">
            <p class="text">{{ 'The reservation of a move is the process by which a customer schedules and secures the availability of the moving service on a specific date and time. The reservation is equivalent to 20% of the estimated value of the move. Once the booking is made, we organize the necessary equipment and resources to execute the move according to the customer\'s requirements, ensuring a safe and efficient experience.' | transloco }}</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Qué es la Visita de Confirmación de Volumen (VCV)?" iconPos="end">
          <div class="text-container">
            <p class="text">Uno de los proceso más importantes que ocurren entre tu reserva de mudanza y el día del servicio, es la Visita de Confirmación de Volumen (VCV). En esta visita, el equipo que realizará tu mudanza va a inspeccionar tu hogar y tus cosas, para revisar todos los detalles menores - pero importantes - que podrían afectar la buena realización de tu servicio.</p>
            <p class="text">{{ 'What kind of details? For example, if you didn\'t indicate in the quote that your building doesn\'t have nearby parking for the truck, or if there are any oversized items that need to be carried up or down stairs or out of windows. Another typical example is when there is more complex furniture that needs to be disassembled to move out of your current home, or into your new home.' | transloco }}</p>
            <p class="text">{{ 'So, to understand all these details, and also so that we can be sure that the size of the truck will be adequate for the amount of things that will be moved in the move, the VCV is mandatory for all customers.\nGenerally these details are already reviewed and considered in the quotation with your consultant before booking, while you resolve your doubts and make the decision to make your move with us. However, it is important that we are sure that everything is considered. This is the only way we can deliver the quality of service that we want and that you deserve.' | transloco }}</p>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
    <h5 class="help-content">Visita nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">Centro de ayuda</a> para más información</h5>
  </article>
}
