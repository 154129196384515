<p-sidebar class="remodeling-quote-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [blockScroll]="true" [showCloseIcon]="false" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="title">Formulario cotización de proyecto</span>
      <button class="custom-button-clear close" (click)="close()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="remodeling-quote-container">
      <div class="form-container">
        <form [formGroup]="form" class="form">
          <div class="input-container">
            <label for="budget">¿Qué presupuesto aproximado tienes?</label>
            <p-inputNumber class="input" id="budget" formControlName="budget" inputId="budget" mode="currency" currency="{{ currency }}" placeholder="$" [locale]="locale" [minFractionDigits]="0" />
          </div>
          <div class="input-container">
            <label class="label" for="service">¿Qué tipo de remodelación deseas?</label>
            <p-dropdown class="input" id="service" formControlName="service" [options]="remodelingServices" optionLabel="label" optionValue="value" placeholder="Selecciona un tipo de remodelación" />
          </div>
          <div class="input-container">
            <label class="label" for="postal-code">Código postal</label>
            <input class="input" pInputText id="postal-code" formControlName="postalCode" maxlength="5" />
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="button-container">
      <app-button class="button" label="Enviar solicitud" styleClass="primary" [disabled]="form.invalid" [loading]="sendingQuote" (action)="sendQuote()" />
    </div>
  </ng-template>
</p-sidebar>