import { Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { PlatformService } from '../platform/platform.service';
import {
  AddShippingAddressEvent,
  AddToShoppingCartEvent,
  AnalyticsService,
  BeginCheckoutEvent,
  GenerateLeadEvent,
  HomeAssistanceEvent,
  LeadCreationEvent,
  MortgageSimulatorEvent,
  MovingEvent,
  ProjectSimulatorEvent,
  PurchaseEvent,
  RemodelingEvent,
  RemoveFromShoppingCartEvent,
  SearchEvent,
  SignInEvent,
  SignUpEvent,
  UserCreationEvent,
  ViewItemEvent,
  ViewShoppingCartEvent,
  WarehouseEvent,
} from './analytics.service';

@Injectable()
export class AnalyticsServiceApi implements AnalyticsService {
  constructor(
    private analytics: Analytics,
    private platformService: PlatformService,
  ) {}

  logAddShippingAddressEvent(data: AddShippingAddressEvent): void {
    this.logEvent('add_shipping_info', data);
  }

  logAddToFavoritesEvent(data: AddToShoppingCartEvent): void {
    this.logEvent('add_to_wishlist', data);
  }

  logAddToShoppingCartEvent(data: AddToShoppingCartEvent): void {
    this.logEvent('add_to_cart', data);
  }

  logBeginCheckoutEvent(data: BeginCheckoutEvent): void {
    this.logEvent('begin_checkout', data);
  }

  logGenerateLeadEvent(data: GenerateLeadEvent): void {
    this.logEvent('generate_lead', data);
  }

  logHomeAssistanceEvent(data: HomeAssistanceEvent): void {
    this.logEvent('home_assistance', data);
  }

  logLeadsForm(data: LeadCreationEvent): void {
    this.logEvent('leads_form', data);
  }

  logMortgageSimulatorEvent(data: MortgageSimulatorEvent): void {
    this.logEvent('mortgage_simulator', data);
  }

  logMovingEvent(data: MovingEvent): void {
    this.logEvent('moving', data);
  }

  logProjectSimulatorEvent(data: ProjectSimulatorEvent): void {
    this.logEvent('project_simulator', data);
  }

  logPurchaseEvent(data: PurchaseEvent): void {
    this.logEvent('purchase', data);
  }

  logRemodelingEvent(data: RemodelingEvent): void {
    this.logEvent('remodeling', data);
  }

  logRemoveFromShoppingCartEvent(data: RemoveFromShoppingCartEvent): void {
    this.logEvent('remove_from_cart', data);
  }

  logSearchEvent(data: SearchEvent): void {
    this.logEvent('search', data);
  }

  logSignInEvent(data: SignInEvent): void {
    this.logEvent('login', data);
  }

  logSignUpEvent(data: SignUpEvent): void {
    this.logEvent('sign_up', data);
  }

  logUserCreationEvent(data: UserCreationEvent): void {
    this.logEvent('user_creation', data);
  }

  logViewItemEvent(data: ViewItemEvent) {
    this.logEvent('view_item', data);
  }

  logViewShoppingCartEvent(data: ViewShoppingCartEvent) {
    this.logEvent('view_cart', data);
  }

  logWarehouseEvent(data: WarehouseEvent): void {
    this.logEvent('warehouse', data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private logEvent(name: string, params: Record<string, any>): void {
    if (this.platformService.isServer()) {
      return;
    }
    logEvent(this.analytics, name, params);
  }
}
