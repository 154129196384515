import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { FavoriteItem, SearchEngineProduct } from '@homein-hogar-server';
import { isAxiosError } from 'axios';
import { CarouselModule } from 'primeng/carousel';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../services/favorites/favorites.service';
import { PlatformService } from '../../services/platform/platform.service';
import { ProductsService } from '../../services/products/products.service';
import { SearchFilter, SearchOrder, SearchPagination } from '../../services/search/search.service';
import { ProductCardComponent } from '../product-card/product-card.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-products-carousel',
  standalone: true,
  imports: [
    CarouselModule,
    CommonModule,
    ProductCardComponent,
    RetryableSectionComponent,
    RouterLink,
  ],
  templateUrl: './products-carousel.component.html',
  styleUrl: './products-carousel.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ProductsCarouselComponent implements OnDestroy, OnInit {
  @Input() filters: SearchFilter[] | null = null;
  @Input() href: string;
  @Input() innerId = ''; // TODO: add later on: add required true on innerId
  @Input() order: SearchOrder | null = null;
  @Input() pagination: SearchPagination | null = null;
  @Input() queryParams: Params;
  @Input() rel = '';
  @Input() target = '_self';
  @Input({ required: true }) title: string;
  errorLoadingProducts = false;
  favorites: Record<string, FavoriteItem> = {};
  internalLink = false;
  loadingProducts = false;
  products: SearchEngineProduct[] = [];
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private platformService: PlatformService,
    private productsService: ProductsService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getProducts(): void {
    this.loadingProducts = true;
    this.errorLoadingProducts = false;
    combineLatest([
      this.productsService.search(null, { filters: this.filters, order: this.order, pagination: this.pagination }),
      this.favoritesService.get(),
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([search, favorite]) => {
        this.products = search.data;
        this.products = this.products.map((value, index) => ({
          ...value,
          index: index + 1
        }));
        this.favorites = {};
        if (favorite) {
          favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
        }
        this.loadingProducts = false;
      },
      error: (error) => {
        if (!(isAxiosError(error) && error.code === 'ECONNABORTED')) {
          this.errorReportingService.log('ProductsCarouselComponent.getProducts()', 'get-products-carousel', error);
        }
        this.products = [];
        this.errorLoadingProducts = true;
        this.loadingProducts = false;
      }
    });
  }

  private initialize(): void {
    if (this.platformService.isServer()) {
      return;
    }
    this.getProducts();
    this.internalLink = this.href?.startsWith('/');
    if (this.queryParams) {
      Object.entries(this.queryParams).forEach(([key, value]) => this.queryParams[key] = JSON.stringify(value));
    }
  }
}
