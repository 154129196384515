<div class="warehouse-status-container animate__fadeIn">
  <div class="left-column">
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <h2 class="title">{{ 'Warehouse status' | transloco }}</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (order) {
        <div class="order-info-container">
          <h4 class="status {{ orderFinalStatus }}">
            @switch (orderFinalStatus) {
              @case ('about-to-expire') {
                {{ 'About to expire' | transloco }}
              } @case ('done') {
                {{ 'Active' | transloco }}
              } @case ('finalized') {
                {{ 'Finalized' | transloco }}
              } @case ('pending-payment') {
                {{ 'Payment pending' | transloco }}
              } @case ('voided') {
                {{ 'Voided' | transloco }}
              } @case ('waiting-confirmation') {
                {{ 'Pending confirmation' | transloco }}
              }
            }  
          </h4>
          <p class="total-volume">{{ 'Warehouse X㎥' | transloco: { volume: order.additionalData['volume']} }}</p>
          @if (order.additionalData['hasTransportation']) {
            <p class="transfer">{{ 'Includes transfer to warehouse' | transloco }}</p>
          } @else {
            <p class="transfer">{{ 'Does not include transfer to the warehouse' | transloco }}</p>
          }
          <p class="location">{{ order.warehouseAddress }}</p>
          <a class="location-link" href="https://www.google.com/maps/search/?api=1&query={{ this.order.warehouseAddress }}" target="_blank" rel="noopener">(<span class="text">{{ 'See warehouse location' | transloco }}</span>)</a>
          <p class="start-and-end-dates">{{ 'Start: XX - End: YY' | transloco: { end: order.finishAt | date: 'dd/MM/yyyy', start: order.startAt | date: 'dd/MM/yyyy' } }}</p>
          <p class="description">
            @switch (orderFinalStatus) {
              @case ('about-to-expire') {
                {{ 'Your warehouse lease is about to expire. If you want to keep your plan active, please contact us.' | transloco }}
              } @case ('done') {
                {{ 'Your warehouse is active, use it when you need it. For any questions please contact us.' | transloco }}
              } @case ('finalized') {
                {{ 'Your warehouse lease has already ended. You can hire it again using the reactivate button.' | transloco }}
              } @case ('pending-payment') {
                {{ 'You need to make the payment to reserve your warehouse rental.' | transloco }}
              } @case ('voided') {
                {{ 'We regret canceling your warehouse rental. Please let us know how we can improve the service.' | transloco }}
              } @case ('waiting-confirmation') {
                {{ 'Your warehouse is in the process of being confirmed. We will review that the payment has been made and other processes.' | transloco }}
              }
            }
          </p>
          <div class="action-button-container">
            @if (['done', 'about-to-expire'].includes(orderFinalStatus)) {
              <app-button label="Go to chat" styleClass="secondary expand" [href]="order.additionalData['chatUrl']" target="_blank" rel="noopener"></app-button>
            } @else {
              @switch (orderFinalStatus) {
                @case ('pending-payment') {
                  <app-button label="Go to reserve the warehouse" styleClass="secondary expand" (action)="goToReserveWarehouse(order)"></app-button>
                } @case ('finalized') {
                  <!-- TODO: Uncomment this line when the action is available -->
                  <!--<app-button label="Renew lease" styleClass="tertiary"></app-button>-->
                }
              }
            }
          </div>
        </div>
        <div class="help-links-container">
          <h4 class="title">{{ 'Help with this purchase' | transloco }}</h4>
          <!-- TODO: Update link -->
          <a class="link" href="#">{{ 'I have a problem with my warehouse' | transloco }}</a>
          <!-- TODO: Update link -->
          <a class="link" href="#">{{ 'I have a problem with my warehouse' | transloco }}</a>
        </div>
        <div class="purchase-details-container" routerLink="/private-site/my-warehouses/{{ orderId }}/details">
          <h4 class="title">{{ 'Purchase details' | transloco }}</h4>
          <i class="icon icon-arrow-right"></i>
        </div>
      }
    </app-retryable-section>
  </div>
  @if (order && !['pending-payment', 'waiting-confirmation'].includes(order.status)) {
    <div class="right-column">
        <app-warehouse-purchase-details [showBackButton]="false" [order]="order"></app-warehouse-purchase-details>
    </div>
  }
</div>