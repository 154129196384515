import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlatformService } from '../platform/platform.service';

export enum DataKey {
  Apportions = 'apportions',
  Favorites = 'favorites',
  LocalUser = 'localUser',
  MockAccounts = 'mockAccounts',
  MockAddresses = 'mockAddresses',
  MockChargeIntents = 'mockChargeIntents',
  MockEcommerceOrders = 'mockEcommerceOrders',
  MockFavorites = 'mockFavorites',
  MockIdToken = 'mockIdToken',
  MockIdTokenResult = 'mockIdTokenResult',
  MockMovingOrders = 'mockMovingOrders',
  MockPaymentIntents = 'mockPaymentIntents',
  MockRemodelingOrders = 'mockRemodelingOrders',
  MockShoppingCarts = 'mockShoppingCarts',
  MockUserId = 'mockUserId',
  MockUsers = 'mockUsers',
  MockWarehouseOrders = 'mockWarehouseOrders',
  MortgageSimulation = 'mortgageSimulation',
  PostalCode = 'postalCode',
  ProjectSimulation = 'projectSimulation',
  SearchHistory = 'searchHistory',
  ShoppingCart = 'shoppingCart',
  TermsOfServiceAcceptance = 'termsOfServiceAcceptance',
  TrackedProducts = 'trackedProducts',
}

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  private data: Record<string, BehaviorSubject<any>> = {};

  constructor(
    private platformService: PlatformService,
  ) {
    if (this.platformService.isBrowser()) {
      Object.keys(localStorage).forEach((key) => {
        const value = localStorage.getItem(key) as string;
        if (value.startsWith('{')) {
          this.data[key] = new BehaviorSubject(JSON.parse(value));
        }
      });
    }
  }

  clear(key: DataKey): void {
    if (this.data[key]) {
      this.data[key].next(null);
    }
    if (this.platformService.isBrowser()) {
      localStorage.removeItem(key);
    }
  }

  clearAll(): void {
    if (this.platformService.isBrowser()) {
      if (environment.usingMocks) {
        Object.keys(localStorage).forEach((key) => {
          if (!key.startsWith('mock')) {
            localStorage.removeItem(key);
            this.data[key].next(null);
          }
        });
      } else {
        localStorage.clear();
        Object.keys(this.data).forEach((key) => this.data[key].next(null));
      }
    }
  }

  get<T>(key: DataKey): Observable<T | null> {
    if (!this.data[key]) {
      this.data[key] = new BehaviorSubject<T | null>(null);
    }
    return this.data[key] as Observable<T>;
  }

  getLocal<T>(key: DataKey): T | null {
    if (this.platformService.isBrowser()) {
      const rawData = localStorage.getItem(key);
      if (rawData) {
        return JSON.parse(rawData) as T;
      }
    }
    return null;
  }

  set<T>(key: DataKey, data: T): void {
    if (this.platformService.isBrowser()) {
      localStorage.setItem(key, JSON.stringify(data));
      if (!this.data[key]) {
        this.data[key] = new BehaviorSubject<T>(data);
      }
      this.data[key].next(data);
    }
  }
}
