import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { QRCodeModule } from 'angularx-qrcode';
import { AccordionModule } from 'primeng/accordion';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { HomeAssistancePlanDetailsSidebarComponent } from '../../../../components/home-assistance-plan-details-sidebar/home-assistance-plan-details-sidebar.component';
import { HomeAssistancePurchaseDetailsComponent } from '../../../../components/home-assistance-purchase-details/home-assistance-purchase-details.component';
import { MovingPurchaseDetailsComponent } from '../../../../components/moving-purchase-details/moving-purchase-details.component';
import { RatingComponent } from '../../../../components/rating/rating.component';
import { ReminderComponent } from '../../../../components/reminder/reminder.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { SelectAssistancePlanSidebarComponent } from '../../../../components/select-assistance-plan-sidebar/select-assistance-plan-sidebar.component';
import { DataKey, DataStorageService } from '../../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { HomeAssistanceOrderDetail, HomeAssistanceOrdersService } from '../../../../services/home-assistance-orders/home-assistance-orders.service';
import { SeoService } from '../../../../services/seo/seo.service';
import { LocalUser } from '../../../../services/users/users.service';
import { getComplementaryData } from '../../../../utils/home-assistances.utils';

@Component({
  selector: 'app-home-assistance-status',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CommonModule,
    HomeAssistancePlanDetailsSidebarComponent,
    HomeAssistancePurchaseDetailsComponent,
    MovingPurchaseDetailsComponent,
    QRCodeModule,
    RatingComponent,
    ReminderComponent,
    RetryableSectionComponent,
    RouterModule,
    SelectAssistancePlanSidebarComponent,
    TranslocoPipe,
  ],
  templateUrl: './home-assistance-status.page.html',
  styleUrl: './home-assistance-status.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomeAssistanceStatusPage implements OnInit, OnDestroy {
  @ViewChild('assistancePlanDetailsSidebar') assistancePlanDetailsSidebar: HomeAssistancePlanDetailsSidebarComponent;
  errorLoading = false;
  homeAssistanceOrderDetail: HomeAssistanceOrderDetail;
  loading = true;
  qrInformation = '';
  userEmail = '';
  private orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    private router: Router,
    private seoService: SeoService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyHomeAssistance();
      return;
    }
    this.orderId = orderId;
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    combineLatest([
      this.homeAssistanceOrdersService.get(this.orderId),
      this.dataStorageService.get<LocalUser>(DataKey.LocalUser),
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([homeAssistanceOrder, localUser]) => {
        if (localUser) {
          this.userEmail = localUser.email;
          this.qrInformation = `${window.location.origin}/home-assistance/request-home-assistance?email=${localUser.email}`;
        }
        if (!homeAssistanceOrder) {
          this.goToMyHomeAssistance();
          return;
        }
        this.seoService.setMetaTags({
          description: 'Consulta el estado de tu plan de asistencia en Mundo Hogar.',
          title: `Estado plan de asistencia: ${homeAssistanceOrder.plan.name}`,
        });
        this.homeAssistanceOrderDetail = {
          ...homeAssistanceOrder,
          ...getComplementaryData(homeAssistanceOrder),
        };
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('HomeAssistanceStatusPage.initialize()', 'get-home-assistance-order', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  openPlanDetails(): void {
    this.assistancePlanDetailsSidebar.open();
  }

  private goToMyHomeAssistance(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/home-assistance']);
  }
}