import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { EcommerceOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { firstValueFrom, Subject } from 'rxjs';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { EcommerceOrdersService } from '../../services/ecommerce-orders/ecommerce-orders.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { SeoService } from '../../services/seo/seo.service';
import { PurchasePaymentDetailsComponent } from '../purchase-payment-details/purchase-payment-details.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-ecommerce-purchase-details',
  standalone: true,
  imports: [
    AccordionModule,
    CommonModule,
    CurrencyFormatterPipe,
    PurchasePaymentDetailsComponent,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ecommerce-purchase-details.component.html',
  styleUrl: './ecommerce-purchase-details.component.scss',
})
export class EcommercePurchaseDetailsComponent implements OnInit, OnDestroy {
  @Input() order: EcommerceOrder;
  @Input() showBackButton = true;
  errorLoading = false;
  loading = true;
  protected orderId: string;
  protected orderItemId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private seoService: SeoService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    const orderItemId = this.activatedRoute.snapshot.queryParamMap.get('itemId');
    if (!orderId || !orderItemId) {
      this.goToMyPurchases();
      return;
    }
    this.orderId = orderId;
    this.orderItemId = orderItemId;
    if (!this.order) {
      this.seoService.setMetaTags({
        title: `Detalle de compra #${this.orderId}`,
        description: 'Detalle de una compra para el hogar en Mundo Hogar.',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    let step = '';
    try {
      if (!this.order) {
        step = 'get-ecommerce-order';
        const ecommerceOrder = await firstValueFrom(this.ecommerceOrdersService.get(this.orderId));
        if (!ecommerceOrder) {
          throw new Error('Ecommerce order not found');
        }
        this.order = ecommerceOrder;
      }
    } catch (error) {
      this.errorReportingService.log('EcommercePurchaseDetailsComponent.initialize()', step, error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  private goToMyPurchases(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-purchases']);
  }
}