import { AddFavoriteItemData, FavoriteItem, RemoveFavoriteItemData } from '@homein-hogar-server';
import { CustomError } from '../models/custom-error.model';

export const MAX_FAVORITES_ITEMS = 16; // match this value with the one in the server

export function getUpdatedFavoriteItems(params: ({
  items: FavoriteItem[];
} & ({
  action: 'add';
  addItemData: AddFavoriteItemData;
} | {
  action: 'remove';
  removeItemData: RemoveFavoriteItemData;
}))): FavoriteItem[] {
  const { resourceId, resourceType } = params.action === 'add' ? params.addItemData : params.removeItemData;
  if (params.action === 'add' && params.items.length >= MAX_FAVORITES_ITEMS) {
    throw new CustomError('max-favorites-items-reached', 'CONFLICT', 'Max favorite items reached.');
  }
  const index = params.items.findIndex((item) => item.resourceId === resourceId && item.resourceType === resourceType);
  if (params.action === 'add') {
    if (index === -1) {
      params.items.push({
        addedAt: new Date(),
        resourceId,
        resourceType,
      });
    }
    return params.items;
  }
  if (index !== -1) {
    params.items.splice(index, 1);
  }
  return params.items;
}
