import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Account } from '@homein-accounts-server';
import { ChargeIntent, HomeAssistancePlan, HomeAssistancePlanDuration } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ReminderComponent } from '../../../components/reminder/reminder.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { constants } from '../../../constants';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { AccountsService } from '../../../services/accounts-server/accounts/accounts.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ChargeIntentsService } from '../../../services/charge-intents/charge-intents.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { HomeAssistancePlansService } from '../../../services/home-assistance-plans/home-assistance-plans.service';
import { SeoService } from '../../../services/seo/seo.service';
import { ShoppingCartDetail, ShoppingCartsService } from '../../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../../services/toast/toast.service';
import { mapShoppingCartItemProductsToCheckoutItems } from '../../../utils/analytics.utils';
import { getMovingServiceFromChargeIntent } from '../../../utils/moving.utils';
import { normalizeFloat } from '../../../utils/numbers.utils';
import { getRemodelingServiceFromChargeIntent } from '../../../utils/remodeling.utils';
import { toCapitalizeCase } from '../../../utils/string.utils';
import { getWarehouseServiceFromChargeIntent } from '../../../utils/warehouse.utils';

export type PaymentMethod = 'monthly-installments' | 'spot-payment';
export type CardType = 'debit' | 'credit';

interface AccountControls {
  amount: number;
  availableAmount: number;
  disabled: boolean;
  disabledTooltip: string;
  selected: boolean;
}

interface CardControls {
  amount: number;
  selectedType: CardType | null;
}

interface MonthlyInstallmentCardControls {
  amount: number;
  installmentAmount: number;
  installments: number;
  selected: boolean;
}

interface CheckoutControls {
  currency: string;
  missingAmount: number;
  monthlyInstallmentsControls: {
    amount: number;
    installmentAmount: number;
    installments: number;
    opened: boolean;
    options: MonthlyInstallmentCardControls[],
  };
  selectedPaymentMethod: PaymentMethod | null;
  spotPaymentControls: {
    card: CardControls;
    debit: AccountControls;
    mortgage: AccountControls;
    opened: boolean;
    price: number;
  };
}

export type CheckoutState = EcommerceCheckoutState | HomeAssistanceCheckoutState | MovingCheckoutState | RemodelingCheckoutState | WarehouseCheckoutState;

interface EcommerceCheckoutState {
  resource: ShoppingCartDetail | null;
  resourceIds: string[];
  resourceType: 'product';
  source: 'ecommerce';
}

interface HomeAssistanceCheckoutState {
  resource: HomeAssistancePlan | null;
  resourceIds: string[];
  resourceSubtypes: HomeAssistancePlanDuration[];
  resourceType: 'home-assistance-plan';
  source: 'home-assistance';
}

interface MovingCheckoutState {
  resource: ChargeIntent | null;
  resourceIds: string[];
  resourceType: 'charge-intent';
  source: 'moving';
}

interface RemodelingCheckoutState {
  resource: ChargeIntent | null;
  resourceIds: string[];
  resourceType: 'charge-intent';
  source: 'remodeling';
}

interface WarehouseCheckoutState {
  resource: ChargeIntent | null;
  resourceIds: string[];
  resourceType: 'charge-intent';
  source: 'warehouse';
}

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    RouterModule,
    TranslocoPipe,
    TooltipModule,
    ReminderComponent
  ],
  templateUrl: './checkout.page.html',
  styleUrl: './checkout.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutPage implements OnInit, OnDestroy {
  controls: CheckoutControls = {
    currency: constants.defaultCurrency,
    missingAmount: 0,
    monthlyInstallmentsControls: {
      amount: 0,
      installmentAmount: 0,
      installments: 0,
      opened: false,
      options: [],
    },
    selectedPaymentMethod: null,
    spotPaymentControls: {
      card: {
        amount: 0,
        selectedType: null,
      },
      debit: {
        amount: 0,
        availableAmount: 0,
        disabled: false,
        disabledTooltip: 'Balance that you can use to buy anything you want in Mundo Hogar.',
        selected: false,
      },
      mortgage: {
        amount: 0,
        availableAmount: 0,
        disabled: false,
        disabledTooltip: 'Balance from your mortgage, exclusively for remodeling your home.',
        selected: false,
      },
      opened: false,
      price: 0,
    },
  };
  errorLoading = false;
  loading = false;
  state: CheckoutState;
  private viewDestroyed = new Subject<void>();

  constructor(
    private accountsService: AccountsService,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private chargeIntentsService: ChargeIntentsService,
    private errorReportingService: ErrorReportingService,
    private homeAssistancePlansService: HomeAssistancePlansService,
    private location: Location,
    private router: Router,
    private seoService: SeoService,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  get debitApportionedAmount(): number {
    return this.controls.spotPaymentControls.debit.selected ? this.controls.spotPaymentControls.debit.amount : 0;
  }

  get isAtLeastOneSpotPaymentMethodSelected(): boolean {
    return !!this.controls.spotPaymentControls.card.selectedType || this.controls.spotPaymentControls.debit.selected || this.controls.spotPaymentControls.mortgage.selected;
  }

  get isMonthlyInstallmentsMethodSelected(): boolean {
    return !!this.controls.monthlyInstallmentsControls.options.find(option => option.selected);
  }

  get mortgageApportionedAmount(): number {
    return this.controls.spotPaymentControls.mortgage.selected ? this.controls.spotPaymentControls.mortgage.amount : 0;
  }

  get spotPaymentCardApportionedAmount(): number {
    return this.controls.spotPaymentControls.card.selectedType ? this.controls.spotPaymentControls.card.amount : 0;
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const source = this.activatedRoute.snapshot.queryParamMap.get('source');
    const resourceIds = this.activatedRoute.snapshot.queryParamMap.getAll('resourceIds');
    const resourceSubtypes = this.activatedRoute.snapshot.queryParamMap.getAll('resourceSubtypes');
    const resourceType = this.activatedRoute.snapshot.queryParamMap.get('resourceType');
    if (!source || !['ecommerce', 'home-assistance', 'moving', 'remodeling', 'warehouse'].includes(source) || !resourceIds.length || !resourceType) {
      this.goBack();
      return;
    }
    this.state = {
      resource: null,
      resourceIds,
      ...(resourceSubtypes.length && { resourceSubtypes }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resourceType: resourceType as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      source: source as any,
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    if (this.state.source === 'ecommerce') {
      combineLatest([
        this.accountsService.getAll(),
        this.shoppingCartsService.get(),
      ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: ([accounts, resource]) => this.handleEcommerceInitialization(accounts, resource),
        error: (error) => this.handleInitializationError(error, 'get-ecommerce-data'),
      });
    } else if (this.state.source === 'home-assistance') {
      await this.shoppingCartsService.addItem({
        quantity: 1,
        resourceId: this.state.resourceIds[0],
        resourceType: 'home-assistance-plan',
      }).catch((error) => {
        this.errorReportingService.log('CheckoutPage.initialize()', 'add-home-assistance-plan-to-cart', error);
        this.goBack();
      });
      combineLatest([
        this.accountsService.getAll(),
        this.homeAssistancePlansService.get(this.state.resourceIds[0]),
      ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: ([accounts, resource]) => this.handleHomeAssistanceInitialization(accounts, resource),
        error: (error) => this.handleInitializationError(error, 'get-home-assistance-data'),
      });
    } else if (this.state.source === 'moving' || this.state.source === 'remodeling' || this.state.source === 'warehouse') {
      await this.shoppingCartsService.addItem({
        quantity: 1,
        resourceId: this.state.resourceIds[0],
        resourceType: 'charge-intent',
      }).catch((error) => {
        this.errorReportingService.log('CheckoutPage.initialize()', 'add-moving-charge-intent-to-cart', error);
        this.goBack();
      });
      combineLatest([
        this.accountsService.getAll(),
        this.chargeIntentsService.get(this.state.resourceIds[0]),
      ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: ([accounts, chargeIntent]) => {
          if (this.state.source === 'moving' || this.state.source === 'remodeling' || this.state.source === 'warehouse') {
            this.handleChargeIntentInitialization(accounts, chargeIntent);
          }
        },
        error: (error) => this.handleInitializationError(error, 'get-moving-data'),
      });
    } else {
      this.goBack();
    }
  }

  next(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/payment-gateway/confirm-payment'], {
      queryParams: {
        source: this.state.source,
        resourceIds: this.state.resourceIds,
        resourceType: this.state.resourceType,
        paymentMethod: this.controls.selectedPaymentMethod,
        ...(this.state.source === 'home-assistance' && { resourceSubtypes: this.state.resourceSubtypes }),
        ...(this.controls.selectedPaymentMethod === 'monthly-installments' && {
          monthlyInstallmentAmount: normalizeFloat(this.controls.monthlyInstallmentsControls.options.find((option) => option.selected)?.amount || 0),
          monthlyInstallments: this.controls.monthlyInstallmentsControls.installments,
        }),
        ...(this.controls.selectedPaymentMethod === 'spot-payment' && {
          spotPaymentCard: !!this.controls.spotPaymentControls.card.selectedType,
          spotPaymentCardAmount: normalizeFloat(this.controls.spotPaymentControls.card.amount),
          spotPaymentCardType: this.controls.spotPaymentControls.card.selectedType,
          spotPaymentDebit: this.controls.spotPaymentControls.debit.selected,
          spotPaymentDebitAmount: normalizeFloat(this.controls.spotPaymentControls.debit.amount),
          spotPaymentMortgage: this.controls.spotPaymentControls.mortgage.selected,
          spotPaymentMortgageAmount: normalizeFloat(this.controls.spotPaymentControls.mortgage.amount),
        }),
      }
    });
  }

  selectAccount(type: 'debit' | 'mortgage'): void {
    if (type === 'debit') {
      if (this.controls.spotPaymentControls.debit.disabled) {
        return;
      }
      this.controls.spotPaymentControls.debit.selected = !this.controls.spotPaymentControls.debit.selected;
      this.controls.spotPaymentControls.opened = true;
      this.updateControlSelectAccountDebit();
    } else {
      if (this.controls.spotPaymentControls.mortgage.disabled) {
        return;
      }
      this.controls.spotPaymentControls.mortgage.selected = !this.controls.spotPaymentControls.mortgage.selected;
      this.controls.spotPaymentControls.opened = true;
      this.updateControlSelectAccountMortgage();
    }
  }

  selectCard(type: 'credit' | 'debit'): void {
    this.controls.spotPaymentControls.card.selectedType = this.controls.spotPaymentControls.card.selectedType === type ? null : type;
    this.controls.spotPaymentControls.opened = true;
    this.updateControlSelectCard();
  }

  toggleMonthlyInstallmentCardOption(option: MonthlyInstallmentCardControls): void {
    for (let i = 0; i < this.controls.monthlyInstallmentsControls.options.length; i++) {
      if (this.controls.monthlyInstallmentsControls.options[i].installments === option.installments) {
        this.controls.monthlyInstallmentsControls.options[i].selected = !option.selected;
      } else {
        this.controls.monthlyInstallmentsControls.options[i].selected = false;
      }
    }
    this.controls.monthlyInstallmentsControls.installmentAmount = option.installmentAmount;
    this.controls.monthlyInstallmentsControls.installments = option.installments;
    this.updateMissingAmount();
  }

  togglePaymentMethod(paymentMethod: PaymentMethod) {
    this.controls.selectedPaymentMethod = paymentMethod;
    if (paymentMethod === 'spot-payment') {
      this.controls.spotPaymentControls.opened = !this.controls.spotPaymentControls.opened;
      this.controls.monthlyInstallmentsControls.opened = false;
    } else {
      this.controls.monthlyInstallmentsControls.opened = !this.controls.monthlyInstallmentsControls.opened;
      this.controls.spotPaymentControls.opened = false;
    }
  }

  private handleEcommerceInitialization(accounts: Account[], resource: ShoppingCartDetail | null): void {
    if (this.state.source !== 'ecommerce' || !resource) {
      return this.handleInitializationError(new Error('Shopping cart not found'), 'handle-ecommerce-initialization');
    }
    if (resource && !this.state.resource) {
      this.analyticsService.logBeginCheckoutEvent({
        currency: resource.currency,
        items: mapShoppingCartItemProductsToCheckoutItems(resource.items),
        value: resource.subtotal,
      });
    }
    this.state.resource = resource;
    const options = constants.paymentOptions.filter((option) => !['debit', 'credit'].includes(option));
    const debitAccount = accounts.find((account) => account.type === 'debit');
    const mortgageAccount = accounts.find((account) => account.type === 'mortgage');
    this.controls = {
      ...this.controls,
      monthlyInstallmentsControls: {
        ...this.controls.monthlyInstallmentsControls,
        installmentAmount: normalizeFloat(resource.pricing[options[0]].monthlyPayment + resource.pricing[options[0]].monthlyShippingPayment),
        installments: +options[0].substring(options[0].lastIndexOf('-') + 1),
        options: options.map((option) => ({
          amount: normalizeFloat(resource.pricing[option].price + resource.pricing[option].shippingCost),
          installmentAmount: normalizeFloat(resource.pricing[option].monthlyPayment + resource.pricing[option].monthlyShippingPayment),
          installments: +option.substring(option.lastIndexOf('-') + 1),
          selected: false,
        })),
      },
      spotPaymentControls: {
        ...this.controls.spotPaymentControls,
        debit: {
          ...this.controls.spotPaymentControls.debit,
          disabled: (debitAccount?.balance || 0) === 0,
          availableAmount: debitAccount?.balance || 0,
        },
        mortgage: {
          ...this.controls.spotPaymentControls.mortgage,
          disabled: true,
          availableAmount: mortgageAccount?.balance || 0,
        },
        price: resource.total,
      },
    };
    this.initializeControls();
    this.loading = false;
  }

  private handleHomeAssistanceInitialization(accounts: Account[], resource: HomeAssistancePlan | null): void {
    if (this.state.source !== 'home-assistance' || !resource) {
      return this.handleInitializationError(new Error('Home assistance plan not found'), 'handle-home-assistance-initialization');
    }
    const duration = this.state.resourceSubtypes[0];
    this.state.resource = resource;
    const options = constants.paymentOptions.filter((option) => !['debit', 'credit'].includes(option));
    const debitAccount = accounts.find((account) => account.type === 'debit');
    const mortgageAccount = accounts.find((account) => account.type === 'mortgage');
    this.controls = {
      ...this.controls,
      monthlyInstallmentsControls: {
        ...this.controls.monthlyInstallmentsControls,
        installmentAmount: resource.pricing.external[duration]['credit-36'].monthlyPayment,
        installments: +options[0].substring(options[0].lastIndexOf('-') + 1),
        options: options.map((option) => ({
          amount: resource.pricing.external[duration][option].price,
          installmentAmount: resource.pricing.external[duration][option].monthlyPayment,
          installments: +option.substring(option.lastIndexOf('-') + 1),
          selected: false,
        })),
      },
      spotPaymentControls: {
        ...this.controls.spotPaymentControls,
        debit: {
          ...this.controls.spotPaymentControls.debit,
          disabled: (debitAccount?.balance || 0) === 0,
          availableAmount: debitAccount?.balance || 0,
        },
        mortgage: {
          ...this.controls.spotPaymentControls.mortgage,
          disabled: true,
          availableAmount: mortgageAccount?.balance || 0,
        },
        price: resource.pricing.external[duration]['debit'].price,
      },
    };
    this.initializeControls();
    this.loading = false;
    this.analyticsService.logHomeAssistanceEvent({ step: 'begin_checkout', plan: resource.id, duration: this.state.resourceSubtypes[0] });
  }

  private handleChargeIntentInitialization(accounts: Account[], chargeIntent: ChargeIntent | null): void {
    if ((this.state.source !== 'moving' && this.state.source !== 'remodeling' && this.state.source !== 'warehouse') || !chargeIntent) {
      return this.handleInitializationError(new Error(`${toCapitalizeCase(this.state.source)} charge intent not found`), `handle-${this.state.source}-initialization`);
    }
    if (!chargeIntent.pricing) {
      return this.handleInitializationError(new Error(`${toCapitalizeCase(this.state.source)} charge intent pricing not found`), `handle-${this.state.source}-initialization`);
    }
    this.state.resource = chargeIntent;
    const options = constants.paymentOptions.filter((option) => !['debit', 'credit'].includes(option));
    const debitAccount = accounts.find((account) => account.type === 'debit');
    const mortgageAccount = accounts.find((account) => account.type === 'mortgage');
    this.controls = {
      ...this.controls,
      monthlyInstallmentsControls: {
        ...this.controls.monthlyInstallmentsControls,
        installmentAmount: chargeIntent.pricing.external['credit-36'].monthlyPayment,
        installments: +options[0].substring(options[0].lastIndexOf('-') + 1),
        options: options.map((option) => ({
          amount: chargeIntent.pricing?.external[option].price || 0,
          installmentAmount: chargeIntent.pricing?.external[option].monthlyPayment || 0,
          installments: +option.substring(option.lastIndexOf('-') + 1),
          selected: false,
        })),
      },
      spotPaymentControls: {
        ...this.controls.spotPaymentControls,
        debit: {
          ...this.controls.spotPaymentControls.debit,
          disabled: (debitAccount?.balance || 0) === 0,
          availableAmount: debitAccount?.balance || 0,
        },
        mortgage: {
          ...this.controls.spotPaymentControls.mortgage,
          disabled: true,
          availableAmount: mortgageAccount?.balance || 0,
        },
        price: chargeIntent.pricing.external['debit'].price,
      },
    };
    this.initializeControls();
    this.loading = false;
    if (this.state.source === 'moving') {
      this.analyticsService.logMovingEvent({ step: 'begin_checkout', service: getMovingServiceFromChargeIntent(chargeIntent) });
    } else if (this.state.source === 'remodeling') {
      this.analyticsService.logRemodelingEvent({ step: 'begin_checkout', service: getRemodelingServiceFromChargeIntent(chargeIntent) });
    } else if (this.state.source === 'warehouse') {
      this.analyticsService.logWarehouseEvent({ step: 'begin_checkout', service: getWarehouseServiceFromChargeIntent(chargeIntent) });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleInitializationError(error: any, step: string): void {
    this.errorReportingService.log('CheckoutPage.initialize()', step, error);
    this.goBack();
    this.errorLoading = true;
    this.loading = false;
  }

  private initializeControls(): void {
    const { price: spotPrice } = this.controls.spotPaymentControls;
    if (this.controls.spotPaymentControls.debit.disabled) {
      this.controls.spotPaymentControls.debit.selected = false;
      this.controls.spotPaymentControls.debit.amount = 0;
    } else {
      this.controls.spotPaymentControls.debit.amount = spotPrice > this.controls.spotPaymentControls.debit.availableAmount ? this.controls.spotPaymentControls.debit.availableAmount : spotPrice;
    }
    if (this.controls.spotPaymentControls.mortgage.disabled) {
      this.controls.spotPaymentControls.mortgage.selected = false;
      this.controls.spotPaymentControls.mortgage.amount = 0;
    } else {
      this.controls.spotPaymentControls.mortgage.amount = spotPrice > this.controls.spotPaymentControls.mortgage.availableAmount ? this.controls.spotPaymentControls.mortgage.availableAmount : spotPrice;
    }
    this.controls.spotPaymentControls.card.amount = spotPrice;
    this.controls.missingAmount = spotPrice;
  }

  private updateControlSelectAccountDebit(propagate = true): void {
    const { price: spotPrice } = this.controls.spotPaymentControls;
    if (this.controls.spotPaymentControls.debit.selected) {
      if (spotPrice <= this.controls.spotPaymentControls.debit.availableAmount) {
        if (this.controls.spotPaymentControls.mortgage.selected || this.controls.spotPaymentControls.card.selectedType) {
          if (this.controls.spotPaymentControls.mortgage.selected && spotPrice <= this.controls.spotPaymentControls.mortgage.availableAmount) {
            this.controls.spotPaymentControls.mortgage.selected = false;
          }
          if (this.controls.spotPaymentControls.card.selectedType) {
            this.controls.spotPaymentControls.card.selectedType = null;
          }
        } else {
          this.controls.spotPaymentControls.debit.amount = spotPrice;
        }
        this.updateMissingAmount();
        this.controls.spotPaymentControls.debit.amount = this.debitApportionedAmount;
        this.controls.spotPaymentControls.card.amount = this.debitApportionedAmount;
        this.controls.spotPaymentControls.card.selectedType = null;
        return;
      }
    } else if (propagate) {
      this.updateControlSelectAccountMortgage(false);
      this.updateControlSelectCard(false);
      return;
    }
    this.updateMissingAmount();
    if (this.controls.missingAmount) {
      if (this.controls.missingAmount > this.controls.spotPaymentControls.mortgage.availableAmount) {
        this.controls.spotPaymentControls.mortgage.amount = this.controls.spotPaymentControls.mortgage.availableAmount;
      } else {
        this.controls.spotPaymentControls.mortgage.amount = this.controls.missingAmount;
      }
      this.controls.spotPaymentControls.card.amount = this.controls.missingAmount;
    }
  }

  private updateControlSelectAccountMortgage(propagate = true): void {
    const { price: spotPrice } = this.controls.spotPaymentControls;
    if (this.controls.spotPaymentControls.mortgage.selected) {
      if (spotPrice <= this.controls.spotPaymentControls.mortgage.availableAmount) {
        if (this.controls.spotPaymentControls.debit.selected || this.controls.spotPaymentControls.card.selectedType) {
          if (this.controls.spotPaymentControls.debit.selected && spotPrice <= this.controls.spotPaymentControls.debit.availableAmount) {
            this.controls.spotPaymentControls.debit.selected = false;
          }
          if (this.controls.spotPaymentControls.card.selectedType) {
            this.controls.spotPaymentControls.card.selectedType = null;
          }
        } else {
          this.controls.spotPaymentControls.mortgage.amount = spotPrice;
        }
        this.updateMissingAmount();
        this.controls.spotPaymentControls.mortgage.amount = this.mortgageApportionedAmount;
        this.controls.spotPaymentControls.card.amount = this.mortgageApportionedAmount;
        this.controls.spotPaymentControls.card.selectedType = null;
        return;
      }
    } else if (propagate) {
      this.controls.spotPaymentControls.debit.amount += this.controls.spotPaymentControls.mortgage.amount;
      this.controls.spotPaymentControls.card.amount += this.controls.spotPaymentControls.mortgage.amount;
      this.updateControlSelectAccountDebit(false);
      this.updateControlSelectCard(false);
      return;
    }
    this.controls.spotPaymentControls.debit.amount -= this.mortgageApportionedAmount;
    this.controls.spotPaymentControls.card.amount -= this.mortgageApportionedAmount;
    this.updateMissingAmount();
    if (this.controls.missingAmount) {
      if (this.controls.missingAmount > this.controls.spotPaymentControls.debit.availableAmount) {
        this.controls.spotPaymentControls.debit.amount = this.controls.spotPaymentControls.debit.availableAmount;
      } else {
        this.controls.spotPaymentControls.debit.amount = this.controls.missingAmount;
      }
      this.controls.spotPaymentControls.card.amount = this.controls.missingAmount;
    }
  }

  private updateControlSelectCard(propagate = true): void {
    const { price: spotPrice } = this.controls.spotPaymentControls;
    if (this.controls.spotPaymentControls.card.selectedType) {
      if (this.controls.spotPaymentControls.mortgage.selected || this.controls.spotPaymentControls.debit.selected) {
        if (this.controls.spotPaymentControls.mortgage.selected && spotPrice <= this.controls.spotPaymentControls.mortgage.availableAmount) {
          this.controls.spotPaymentControls.mortgage.selected = false;
        }
        if (this.controls.spotPaymentControls.debit.selected && spotPrice <= this.controls.spotPaymentControls.debit.availableAmount) {
          this.controls.spotPaymentControls.debit.selected = false;
        }
      } else {
        this.controls.spotPaymentControls.card.amount = spotPrice;
      }
      this.updateMissingAmount();
      this.controls.spotPaymentControls.mortgage.amount = spotPrice >= this.controls.spotPaymentControls.mortgage.availableAmount ? this.controls.spotPaymentControls.mortgage.availableAmount : spotPrice;
    } else if (propagate) {
      this.updateControlSelectAccountMortgage(false);
      this.updateControlSelectAccountDebit(false);
    }
  }

  private updateMissingAmount(): void {
    if (this.controls.selectedPaymentMethod === 'monthly-installments') {
      this.controls.missingAmount = this.isMonthlyInstallmentsMethodSelected ? 0 : this.controls.spotPaymentControls.price;
    } else {
      this.controls.missingAmount = normalizeFloat(this.controls.spotPaymentControls.price - this.mortgageApportionedAmount - this.debitApportionedAmount - this.spotPaymentCardApportionedAmount);
    }
    if (this.controls.missingAmount === 0) {
      if (this.controls.selectedPaymentMethod === 'spot-payment') {
        this.controls.spotPaymentControls.opened = false;
      } else {
        this.controls.monthlyInstallmentsControls.opened = false;
      }
    }
  }
}
