import { Route } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then(m => m.HomePage),
    data: {
      description: 'Servicio de mudanzas sin estrés. Cotiza gratis y 100% online la mudanza a tu nuevo hogar junto a la empresa líder en México. ¡Hazlo fácil y cotiza ya!',
      title: 'Cotiza tu mudanza de manera rápida y fácil',
    },
  },
] satisfies Route[];