<div class="confirm-payment-title-container-mobile">
  <i class="icon-arrow-left" (click)="goBack()"></i>
  <h2 class="title">Confirmar pago</h2>
</div>
<section class="confirm-payment-container">
  <div class="title-container">
    <i class="icon-arrow-left" (click)="goBack()"></i>
    <h2 class="title">Confirmar pago</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    <p class="subtitle">Resumen del cargo</p>
    <div class="summary-container">
      @if (paymentMethod === 'spot-payment' && spotPayment) {
        <div class="item">Estás pagando de contado:</div>
        @if (spotPayment.mortgage.selected) {
          <div class="item">
            <div>Saldo remodelaciones</div>
            <div>{{ spotPayment.mortgage.amount | currencyFormatter: currency }} {{ currency }}</div>
          </div>
        }
        @if (spotPayment.debit.selected) {
          <div class="item">
            <div>Saldo full disponibilidad</div>
            <div>{{ spotPayment.debit.amount | currencyFormatter: currency }} {{ currency }}</div>
          </div>
        }
        @if (spotPayment.card.selectedType) {
          <div class="item">
            @if (spotPayment.card.selectedType === 'credit') {
              <div>Tarjeta de crédito</div>
            } @else {
              <div>Tarjeta de débito</div>
            }
            <div>{{ spotPayment.card.amount | currencyFormatter: currency }} {{ currency }}</div>
          </div>
        }
      } @else if (paymentMethod === 'monthly-installments' && monthlyInstallments) {
        <div class="item">Estás pagando en meses sin intereses:</div>
        <div class="item">
          <div>Tarjeta de crédito</div>
        </div>
        <div class="item">
          <div>{{ 'X months of' | transloco: { months: monthlyInstallments.installments } }}</div>
          <div>{{ monthlyInstallments.amount / monthlyInstallments.installments | currencyFormatter: currency }} {{ currency }}</div>
        </div>
      }
      <hr class="spacer" />
      <div class="total">
        <div>Total del cargo</div>
        <div>{{ this.totalAmount | currencyFormatter: currency }} {{ currency }}</div>
      </div>
      <div class="disclaimer">{{ 'Upon confirmation, you will pay X with selected payment methods' | transloco: { amount: (this.totalAmount | currencyFormatter: currency) + ' ' + currency } }}</div>
    </div>
    <div class="buttons-container">
      <app-button innerId="btn-X0U77IzQ" label="Cancelar" styleClass="secondary expand" (click)="goBack()" [disabled]="paying" />
      <app-button innerId="btn-NylTTHWA" label="Confirmar pago" styleClass="primary expand" (click)="pay()" [disabled]="paying" [loading]="paying" />
    </div>
  </app-retryable-section>
</section>
@if (!loading && !errorLoading) {
  <div class="confirm-payment-buttons-container-mobile">
    <div class="disclaimer">{{ 'Upon confirmation, you will pay X with selected payment methods' | transloco: { amount: (this.totalAmount | currencyFormatter: currency) + ' ' + currency } }}</div>
    <app-button innerId="btn-jhoPDUSc" label="Confirmar pago" styleClass="primary expand" (click)="pay()" [disabled]="paying" [loading]="paying" />
    <app-button innerId="btn-44zzWwTd" label="Cancelar" styleClass="secondary expand" (click)="goBack()" [disabled]="paying" />
  </div>
}
