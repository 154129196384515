<app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
  @if (paymentIntent && paymentIntent.status === 'paid') {
    <section class="payment-section payment-successful">
      <div class="payment-bar">
        <div class="payment-content">
          <div class="text-content">
            <h3 class="title">Pago realizado</h3>
            <p class="description">Hemos procesado el pago exitosamente</p>
          </div>
        </div>
        <div class="icon-circle">
          <i class="icon-checkmark"></i>
        </div>
      </div>
      <div class="payment-details">
        <h5 class="subtitle">¡Tu pago se realizó con éxito!</h5>
        <p class="info-text">Tu recibo fue enviado a:</p>
        <p class="info-text">
          <strong class="info-email">{{ email }}</strong>
        </p>
        <div class="payment-details-info">
          @if (externalPaymentData) {
            <p class="text">Empresa:
              <strong class="value">{{ externalPaymentData.company }}</strong>
            </p>
            <p class="text">Fecha del pago:
              <strong class="value">{{ externalPaymentData.date }}</strong>
            </p>
            @if (externalPaymentData.authorization) {
              <p class="text"># Autorización:
                <strong class="value">{{ externalPaymentData.authorization }}</strong>
              </p>
            }
          } @else {
            <p class="text">Fecha del pago:
              <strong class="value">{{ paymentIntent.createdAt | date: 'dd/MM/yy \'' + ('at' | transloco) + '\' h:mm a' }}</strong>
            </p>
          }
          <p class="text">Monto:
            <strong class="value">{{ paymentIntent.amount | currencyFormatter: paymentIntent.currency }} {{ paymentIntent.currency }}</strong>
          </p>
          <p class="text">ID de transacción:
            <strong class="value">{{ paymentIntent.id }}</strong>
          </p>
        </div>
        <div class="button-container">
          <app-button innerId="btn-lYPhanmI" label="Volver a Mi Hogar" styleClass="primary dark expand" (action)="goToMyHome()" />
        </div>
        <div class="countdown">
          <span class="text">{{ 'In X seconds you will be redirected to My Home' | transloco: { countdown } }}</span>
        </div>
      </div>
      <div class="lottie-container">
        <ng-lottie [options]="{ path: 'assets/animations/lottie-successful-payment.json' }" class="lottie-animation"></ng-lottie>
        <ng-lottie [options]="{ path: 'assets/animations/lottie-successful-payment.json' }" class="lottie-animation"></ng-lottie>
      </div>
    </section>
  } @else if (paymentIntent && paymentIntent.status === 'failed') {
      <section class="payment-section payment-failure">
        <div class="payment-bar">
          <div class="payment-content">
            <div class="text-content">
              <h3 class="title">Pago no realizado</h3>
              <p class="description">Ha ocurrido un error en el proceso</p>
            </div>
          </div>
          <div class="icon-circle">
            <i class="icon-close"></i>
          </div>
        </div>
        <div class="payment-details">
          <h5 class="subtitle">Tu pago no fue procesado</h5>
          <p class="info-text">No se efectuaron cargos a tus cuentas ni medios de pago.</p>
          <div class="payment-details-info">
            @if (externalPaymentData) {
              @if (externalPaymentData.statusDetail) {
                <p class="text">Motivo:
                  <strong class="value">{{ externalPaymentData.statusDetail }}</strong>
                </p>
              }
              @if (externalPaymentData.statusAction) {
                <p class="text">
                  <strong class="value">{{ externalPaymentData.statusAction }}</strong>
                </p>
              }
              <p class="text">Empresa:
                <strong class="value">{{ externalPaymentData.company }}</strong>
              </p>
              @if (externalPaymentData.authorization) {
                <p class="text">Fecha del pago:
                  <strong class="value">{{ externalPaymentData.date }}</strong>
                </p>
                <p class="text"># Autorización:
                  <strong class="value">{{ externalPaymentData.authorization }}</strong>
                </p>
              }
              <p class="text">ID de transacción:
                <strong class="value">{{ paymentIntent.id }}</strong>
              </p>
            }
          </div>
          <div class="button-container">
            <app-button innerId="btn-im7y1Ua6" label="Reintentar el pago" styleClass="primary dark expand" (action)="goToPayment()" />
          </div>
        </div>
      </section>
  }
</app-retryable-section>