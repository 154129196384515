import { RemodelingOrder } from '@homein-hogar-server';
import { USERS_MOCK } from '../users/users.mock';

const user = USERS_MOCK[0];

export const LEAD = {
  code: 'R999_BCN',
  email: user.email,
  id: 'cuf0WYhIPiqc63QnlOms',
  name: user.name || '',
  phoneNumber: user.phoneNumber || '',
};

export const REMODELING_ORDERS_MOCK: RemodelingOrder[] = [
  {
    additionalData: {
      opportunityName: 'Luis reforma de cocina',
      partnerOrderId: LEAD.id,
      service: 'kitchen',
    },
    contractSigned: false,
    createdAt: new Date(),
    currency: 'MXN',
    endAt: null,
    discounts: 0,
    id: 'grr5V1a5Q8wtE0Q29kRe',
    paid: false,
    paymentIntentIds: [],
    pendingChargeIntentId: null,
    projectManager: {
      code: LEAD.code,
      email: LEAD.email,
      name: LEAD.name,
      phoneNumber: LEAD.phoneNumber,
    },
    startAt: null,
    status: 'lead-created',
    statusDetail: null,
    subtotal: 1000,
    tax: 160,
    totalAmount: 1160,
    updatedAt: new Date(),
    userBudget: 1500,
    userId: user.id,
  },
  {
    additionalData: {
      certifications: [],
      opportunityId: 'OPP-HA2-089404952830020731240237',
      opportunityName: 'Luis reforma de cocina',
      opportunityUpdatedAt: new Date(),
      originalStatus: 'on-budget',
      partnerOrderId: LEAD.id,
      service: 'kitchen',
    },
    contractSigned: false,
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    endAt: null,
    id: 'fJLHtjLQNx4ZvuBRTEEt',
    paid: false,
    paymentIntentIds: [],
    pendingChargeIntentId: 'vDRlEBEwEvehnuYxNTWF',
    projectManager: {
      code: 'R08140',
      email: 'emailfacutracion@2.com',
      name: 'Testing Facturación 2 SL',
      phoneNumber: '666555999',
    },
    startAt: null,
    status: 'pending',
    statusDetail: null,
    subtotal: 7000,
    tax: 1000,
    totalAmount: 8000,
    updatedAt: new Date(),
    userBudget: 5000,
    userId: user.id,
  },
  {
    additionalData: {
      certifications: [],
      opportunityId: 'OPP-HA2-089404952830020731240237',
      opportunityName: 'Luis reforma de baño',
      opportunityUpdatedAt: new Date(),
      partnerOrderId: LEAD.id,
      originalStatus: 'on-budget',
      service: 'bathroom',
    },
    contractSigned: false,
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    endAt: null,
    id: 'Yr6Hsnixndgvp6XQrAlj',
    paid: true,
    paymentIntentIds: [
      'FORZAMGjNOYVI5h4r8t2',
    ],
    pendingChargeIntentId: null,
    projectManager: {
      code: 'R08140',
      email: 'emailfacutracion@2.com',
      name: 'Testing Facturación 2 SL',
      phoneNumber: '666555999',
    },
    startAt: null,
    status: 'pending',
    statusDetail: null,
    subtotal: 8000,
    tax: 1000,
    totalAmount: 9000,
    updatedAt: new Date(),
    userBudget: 5000,
    userId: user.id,
  },
  {
    additionalData: {
      certifications: [],
      opportunityId: 'OPP-HA2-089404952830020731240237',
      opportunityName: 'Luis reforma baño',
      opportunityUpdatedAt: new Date(),
      originalStatus: 'sold',
      partnerOrderId: LEAD.id,
      service: 'bathroom',
    },
    contractSigned: true,
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    endAt: new Date(),
    id: 'yYM8OqQ7LOCi5rOETHrp',
    paid: true,
    paymentIntentIds: [
      '6CttfibstLEm2qBRJ11v'
    ],
    pendingChargeIntentId: null,
    projectManager: {
      code: LEAD.code,
      email: LEAD.email,
      name: LEAD.name,
      phoneNumber: LEAD.phoneNumber,
    },
    startAt: new Date(),
    status: 'waiting',
    statusDetail: null,
    subtotal: 2500,
    tax: 500,
    totalAmount: 3000,
    updatedAt: new Date(),
    userBudget: 2500,
    userId: user.id,
  },
  {
    additionalData: {
      certifications: [],
      opportunityId: 'OPP-HA2-089404952830020731240237',
      opportunityName: 'Luis reforma baño',
      opportunityUpdatedAt: new Date(),
      originalStatus: 'in-progress',
      partnerOrderId: LEAD.id,
      service: 'bathroom',
    },
    contractSigned: true,
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    endAt: new Date(),
    id: 'i8EZJjykKNQeVgrE6B60',
    paid: true,
    paymentIntentIds: [
      '6CttfibstLEm2qBRJ11v'
    ],
    pendingChargeIntentId: null,
    projectManager: {
      code: LEAD.code,
      email: LEAD.email,
      name: LEAD.name,
      phoneNumber: LEAD.phoneNumber,
    },
    startAt: new Date(),
    status: 'in-progress',
    statusDetail: null,
    subtotal: 2500,
    tax: 500,
    totalAmount: 3000,
    updatedAt: new Date(),
    userBudget: 2500,
    userId: user.id,
  },
  {
    additionalData: {
      certifications: [],
      opportunityId: 'OPP-HA2-089404952830020731240237',
      opportunityName: 'Luis reforma baño',
      opportunityUpdatedAt: new Date(),
      originalStatus: 'in-progress',
      partnerOrderId: LEAD.id,
      service: 'bathroom',
    },
    contractSigned: true,
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    endAt: new Date(),
    id: 'BHge8TSDJMUf4YeAOLQD',
    paid: true,
    paymentIntentIds: [
      '6CttfibstLEm2qBRJ11v'
    ],
    pendingChargeIntentId: 'lo6sUky04a61fXL8rkBf',
    projectManager: {
      code: LEAD.code,
      email: LEAD.email,
      name: LEAD.name,
      phoneNumber: LEAD.phoneNumber,
    },
    startAt: new Date(),
    status: 'in-progress',
    statusDetail: null,
    subtotal: 2500,
    tax: 500,
    totalAmount: 3000,
    updatedAt: new Date(),
    userBudget: 2500,
    userId: user.id,
  },
  {
    additionalData: {
      certifications: [],
      opportunityId: 'OPP-HA2-089404952830020731240237',
      opportunityName: 'Luis reforma baño',
      opportunityUpdatedAt: new Date(),
      originalStatus: 'finished',
      partnerOrderId: LEAD.id,
      service: 'bathroom',
    },
    contractSigned: true,
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    endAt: new Date(),
    id: 'ZEmUg4LD4BaUcJEpxgnf',
    paid: true,
    paymentIntentIds: [],
    pendingChargeIntentId: null,
    projectManager: {
      code: LEAD.code,
      email: LEAD.email,
      name: LEAD.name,
      phoneNumber: LEAD.phoneNumber,
    },
    startAt: new Date(),
    status: 'done',
    statusDetail: null,
    subtotal: 2500,
    tax: 500,
    totalAmount: 3000,
    updatedAt: new Date(),
    userBudget: 2500,
    userId: user.id,
  },
  {
    additionalData: {
      certifications: [],
      opportunityId: 'OPP-HA2-089404952830020731240237',
      opportunityName: 'Luis reforma integral',
      opportunityUpdatedAt: new Date(),
      originalStatus: 'discarded',
      partnerOrderId: LEAD.id,
      service: 'fullhouse',
    },
    contractSigned: false,
    createdAt: new Date(),
    currency: 'MXN',
    discounts: 0,
    endAt: null,
    id: 'Vybxxqj4VL5M5qO8tFoF',
    paid: false,
    paymentIntentIds: [],
    pendingChargeIntentId: null,
    projectManager: {
      code: LEAD.code,
      email: LEAD.email,
      name: LEAD.name,
      phoneNumber: LEAD.phoneNumber,
    },
    startAt: null,
    status: 'cancelled',
    statusDetail: null,
    subtotal: 1000,
    tax: 160,
    totalAmount: 1160,
    updatedAt: new Date(),
    userBudget: 1500,
    userId: user.id,
  },
];