import { Injectable } from '@angular/core';
import { AddFavoriteItemData, Favorite, RemoveFavoriteItemData, SearchEngineProduct } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FavoritesServiceApi } from './favorites.service.api';
import { FavoritesServiceMock } from './favorites.service.mock';

interface FavoriteItemProduct {
  addedAt: Date;
  resource: SearchEngineProduct | null;
  resourceId: string;
  resourceType: 'product';
}

export type FavoriteDetail = Omit<Favorite, 'createdAt' | 'id' | 'items' | 'updatedAt' | 'userId'> & ({
  items: FavoriteItemProduct[];
});

export type LocalFavorite = Omit<Favorite, 'createdAt' | 'id' | 'updatedAt' | 'userId'>;

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? FavoritesServiceMock : FavoritesServiceApi,
})
export abstract class FavoritesService {
  abstract addItem(data: AddFavoriteItemData): Promise<void>;
  abstract get(): Observable<FavoriteDetail | null>;
  abstract hasFavorite(resourceType: string, resourceId: string): Observable<boolean>;
  abstract hasFavoriteItems(): Observable<boolean>;
  abstract removeItem(data: RemoveFavoriteItemData): Promise<void>;
  abstract synchronize(): Promise<void>;
}