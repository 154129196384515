<div class="checkout-title-container-mobile">
  <i class="icon-arrow-left" (click)="goBack()"></i>
  <h2 class="title">Formas de pago</h2>
</div>
<section class="checkout-container">
  <div class="title-container">
    <i class="icon-arrow-left" (click)="goBack()"></i>
    <h2 class="title">Formas de pago</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    <p class="subtitle">Selecciona una forma de pago</p>
    <div class="payment-methods-container">
      <div class="payment-method">
        <div class="first-section" [class.opened]="controls.selectedPaymentMethod === 'spot-payment'" (click)="togglePaymentMethod('spot-payment')">
          <div class="content">
            <div class="title">Pago de contado</div>
            <div class="price">{{ controls.spotPaymentControls.price | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
          </div>
          <i class="pi" [class.pi-angle-down]="!this.controls.spotPaymentControls.opened" [class.pi-angle-up]="this.controls.spotPaymentControls.opened"></i>
        </div>
        <div class="second-section" [class.show]="controls.selectedPaymentMethod === 'spot-payment' && (controls.spotPaymentControls.opened || isAtLeastOneSpotPaymentMethodSelected)">
          <div class="opened">
            @if (controls.spotPaymentControls.opened && (controls.spotPaymentControls.debit.availableAmount > 0 || controls.spotPaymentControls.mortgage.availableAmount > 0)) {
              <div class="title">Saldos Mundo Hogar</div>
            }
            <div class="accounts-container">
              @if (controls.spotPaymentControls.mortgage.availableAmount > 0 && (controls.spotPaymentControls.opened || (controls.spotPaymentControls.mortgage.selected && !controls.spotPaymentControls.opened))) {
                <div class="account" [class.disabled]="controls.spotPaymentControls.mortgage.disabled" [class.selected]="controls.spotPaymentControls.mortgage.selected" (click)="selectAccount('mortgage')">
                  <div class="right">
                    <div class="account-icon"></div>
                    <div class="account-info">
                      <div class="type">Saldo remodelaciones</div>
                      <div class="amount">{{ controls.spotPaymentControls.mortgage.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                      <div class="available-amount">Disponible: {{ controls.spotPaymentControls.mortgage.availableAmount | currencyFormatter: controls.currency }} {{ controls.currency }}</div>
                    </div>
                  </div>
                  <div class="account-control">
                    @if (controls.spotPaymentControls.mortgage.disabled) {
                      <i class="icon-information-circle" [pTooltip]="controls.spotPaymentControls.mortgage.disabledTooltip | transloco" tooltipPosition="bottom"></i>
                    } @else {
                      <div class="toggle"><i class="icon-checkmark"></i></div>
                    }
                  </div>
                </div>
              }
              @if (controls.spotPaymentControls.debit.availableAmount > 0 && (controls.spotPaymentControls.opened || (controls.spotPaymentControls.debit.selected && !controls.spotPaymentControls.opened))) {
                <div class="account" [class.disabled]="controls.spotPaymentControls.debit.disabled" [class.selected]="controls.spotPaymentControls.debit.selected" (click)="selectAccount('debit')">
                  <div class="right">
                    <div class="account-icon"></div>
                    <div class="account-info">
                      <div class="type">Saldo full disponibilidad</div>
                      <div class="amount">{{ controls.spotPaymentControls.debit.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                      <div class="available-amount">Disponible: {{ controls.spotPaymentControls.debit.availableAmount | currencyFormatter: controls.currency }} {{ controls.currency }}</div>
                    </div>
                  </div>
                  <div class="account-control">
                    @if (controls.spotPaymentControls.debit.disabled) {
                      <i class="icon-information-circle" [pTooltip]="controls.spotPaymentControls.debit.disabledTooltip | transloco" tooltipPosition="bottom"></i>
                    } @else {
                      <div class="toggle"><i class="icon-checkmark"></i></div>
                    }
                  </div>
                </div>
              }
            </div>
            @if (controls.spotPaymentControls.opened || (isAtLeastOneSpotPaymentMethodSelected && controls.missingAmount > 0 && !controls.spotPaymentControls.opened)) {
              <div class="missing-amount-container" [class.show]="isAtLeastOneSpotPaymentMethodSelected && controls.missingAmount > 0">
                @if (controls.spotPaymentControls.opened) {
                  <app-reminder title="Saldo insuficiente" description="Necesitas complementar pagando {{ (controls.missingAmount | currencyFormatter: controls.currency) + ' ' + controls.currency }} con tarjeta de débito o crédito" styleClass="alert light" />
                } @else {
                  <app-reminder title="Saldo insuficiente" description="Necesitas complementar pagando {{ (controls.missingAmount | currencyFormatter: controls.currency) + ' ' + controls.currency }} con tarjeta de débito o crédito" actionLabel="Seleccionar tarjeta" (action)="togglePaymentMethod('spot-payment')" styleClass="alert light" />
                }
              </div>
            }
            <div class="cards-container">
              @if (controls.spotPaymentControls.opened) {
                <div class="title">Tarjetas</div>
              }
              @if (controls.spotPaymentControls.opened || (controls.spotPaymentControls.card.selectedType === 'credit' && !controls.spotPaymentControls.opened)) {
                <div class="card" [class.selected]="controls.spotPaymentControls.card.selectedType === 'credit'" (click)="selectCard('credit')">
                  <div class="right">
                    <div class="card-icon"></div>
                    <div class="card-info">
                      <div class="type">Tarjeta de crédito</div>
                      <div class="amount">{{ controls.spotPaymentControls.card.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                    </div>
                  </div>
                  <div class="card-control">
                    <div class="toggle"><i class="icon-checkmark"></i></div>
                  </div>
                </div>
              }
              @if (controls.spotPaymentControls.opened || (controls.spotPaymentControls.card.selectedType === 'debit' && !controls.spotPaymentControls.opened)) {
                <div class="card" [class.selected]="controls.spotPaymentControls.card.selectedType === 'debit'" (click)="selectCard('debit')">
                  <div class="right">
                    <div class="card-icon"></div>
                    <div class="card-info">
                      <div class="type">Tarjeta de débito</div>
                      <div class="amount">{{ controls.spotPaymentControls.card.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                    </div>
                  </div>
                  <div class="card-control">
                    <div class="toggle"><i class="icon-checkmark"></i></div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="payment-method">
        <div class="first-section" (click)="togglePaymentMethod('monthly-installments')">
          <div class="content">
            <div class="chip">Beneficios Santander</div>
            <div class="title">Pagos en mensualidades</div>
            <div class="price">{{ controls.monthlyInstallmentsControls.installmentAmount | currencyFormatter }} <span class="currency">{{ controls.currency }}</span><span class="term">{{ 'for X months' | transloco: { months: controls.monthlyInstallmentsControls.installments } }}</span></div>
          </div>
          <i class="pi" [class.pi-angle-down]="!this.controls.monthlyInstallmentsControls.opened" [class.pi-angle-up]="this.controls.monthlyInstallmentsControls.opened"></i>
        </div>
        <div class="second-section" [class.show]="controls.selectedPaymentMethod === 'monthly-installments' && (controls.monthlyInstallmentsControls.opened || isMonthlyInstallmentsMethodSelected)">
          <div class="opened">
            @if (controls.monthlyInstallmentsControls.opened) {
              <div class="title">Precios exclusivos con tus tarjetas Santander</div>
            }
            <div class="installments-container">
              @if (controls.monthlyInstallmentsControls.opened || (isMonthlyInstallmentsMethodSelected && !controls.monthlyInstallmentsControls.opened)) {
                @for (option of controls.monthlyInstallmentsControls.options; track $index) {
                  @if (controls.monthlyInstallmentsControls.opened || option.selected) {
                    <div class="installment-option" [class.selected]="option.selected" (click)="toggleMonthlyInstallmentCardOption(option)">
                      <div class="right">
                        <div class="option-icon"></div>
                        <div class="option-info">
                          <div class="term">{{ 'Pay in X months interest-free' | transloco: { months: option.installments } }}</div>
                          <div class="price">{{ option.installmentAmount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                          <div class="total-payment">Pago total: {{ option.amount | currencyFormatter: controls.currency }} {{ controls.currency }}</div>
                        </div>
                      </div>
                      <div class="option-control">
                        <div class="toggle"><i class="icon-checkmark"></i></div>
                      </div>
                    </div>
                  }
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <app-button innerId="btn-DF8Ju8wk" label="Cancelar" styleClass="secondary expand" (click)="goBack()" />
      <app-button innerId="btn-nfU5S6YO" label="Siguiente" styleClass="primary expand" (click)="next()" [disabled]="controls.missingAmount > 0" />
    </div>
  </app-retryable-section>
</section>
@if (!loading && !errorLoading) {
  <div class="checkout-buttons-container-mobile">
    <app-button innerId="btn-Lcrb6gZ0" label="Siguiente" styleClass="primary expand" (click)="next()" [disabled]="controls.missingAmount > 0" />
    <app-button innerId="btn-YvNjxwFw" label="Cancelar" styleClass="secondary expand" (click)="goBack()" />
  </div>
}