import { Inject, Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { AdvertisingSpace } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { AdvertisingSpacesService } from './advertising-spaces.service';

const COLLECTION_PATH = 'advertisingSpaces';

@Injectable()
export class AdvertisingSpacesServiceApi implements AdvertisingSpacesService {
  constructor(
    @Inject('appFirestore') private firestore: FirestoreService,
  ) {}

  getExclusiveBenefits(): Observable<AdvertisingSpace[]> {
    return this.firestore.query<AdvertisingSpace>(COLLECTION_PATH, {
      constraints: [
        where('type', '==', 'exclusive-benefit'),
        where('status', '==', 'active'),
        orderBy('createdAt', 'desc')
      ]
    });
  }

  getPrivateSitePromotions(): Observable<AdvertisingSpace[]> {
    return this.firestore.query<AdvertisingSpace>(COLLECTION_PATH, {
      constraints: [
        where('type', '==', 'private-site-promotion'),
        where('status', '==', 'active'),
        orderBy('createdAt', 'desc')
      ]
    });
  }

  getPromotions(): Observable<AdvertisingSpace[]> {
    return this.firestore.query<AdvertisingSpace>(COLLECTION_PATH, {
      constraints: [
        where('type', '==', 'promotion'),
        where('status', '==', 'active'),
        orderBy('createdAt', 'desc')
      ]
    });
  }
}
