<section class="warehouse-purchase-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon icon-arrow-left"></i>
    }
    <h2 class="title">{{ 'Purchase detail' | transloco }}</h2>
  </div>
  <app-retryable-section [loading]="false" [error]="false" (retry)="initialize()">
    <div class="total-container">
      <div class="purchase-detail">{{ order.createdAt | date: 'dd/MM/yyyy' }} | #{{ order.id }}</div>
      <div class="products-value">
        <span class="label">{{ 'Warehouse reservation' | transloco }}</span>
        <span class="value">{{ order.subtotal | currencyFormatter: order.currency }} {{ order.currency }}</span>
      </div>
      <div class="separator"></div>
      <div class="total">
        <div class="label">{{ 'Total' | transloco }}</div>
        <div class="value">{{ order.totalAmount | currencyFormatter: order.currency }} {{ order.currency }}</div>
      </div>
    </div>
    <div class="app-purchase-payment">
       @if (order.paymentIntentId) {
        <app-purchase-payment-details [paymentIntentIds]="[order.paymentIntentId]" />
       }
    </div>
    <div class="warehouse-container">
      <h2 class="title">{{ 'Purchase items' | transloco }}</h2>
      <div class="item-container">
        <div class="item-wrapper">
          <img class="image" src="assets/illustrations/boxes-stacked.svg" [alt]="'Warehouse item image' | transloco" />
          <div class="info">
            <span class="data highlight">{{ 'Warehouse X㎥' | transloco: { volume: order.additionalData['volume']} }}</span>
            <span class="data">{{ order.totalAmount | currencyFormatter: order.currency }} {{ order.currency }}</span>
          </div>
        </div>
      </div>
      @if (order.additionalData['hasTransportation']) {
        <div class="item-container">
          <div class="item-wrapper">
            <img class="image" src="assets/illustrations/half-of-a-truck.svg" [alt]="'Warehouse item image' | transloco" />
            <div class="info">
              <span class="data highlight">{{ 'Transfer included' | transloco }}</span>
              <span class="data">{{ order.warehouseAddress }}</span>
            </div>
          </div>
        </div>
      }
    </div>
  </app-retryable-section>
</section>