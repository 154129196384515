<section class="home-assistance-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon-arrow-left" (click)="goBack()"></i>
    }
    <h2 class="title">{{ 'Purchase detail' | transloco }}</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (homeAssistanceOrderDetail) {
      <div class="total-container">
        <div class="purchase-detail">{{ homeAssistanceOrderDetail.createdAt | date: 'dd/MM/yyyy' }} | #{{ homeAssistanceOrderDetail.id }}</div>
        <div class="products-value">
          <span class="label">{{ 'Plan' | transloco }}</span>
          <span class="value">{{ homeAssistanceOrderDetail.subtotal | currencyFormatter: homeAssistanceOrderDetail.currency }} {{ homeAssistanceOrderDetail.currency }}</span>
        </div>
        <div class="separator"></div>
        <div class="total">
          <div class="label">{{ 'Total' | transloco }}</div>
          <div class="value">{{ homeAssistanceOrderDetail.totalAmount | currencyFormatter: homeAssistanceOrderDetail.currency }} {{ homeAssistanceOrderDetail.currency }}</div>
        </div>
      </div>
      <div class="app-purchase-payment">
        @if (homeAssistanceOrderDetail.paymentIntentId) {
          <app-purchase-payment-details [paymentIntentIds]="[homeAssistanceOrderDetail.paymentIntentId]" />
        }
      </div>
      <div class="home-assistance-items-container">
        <div class="assistance-item">
          <span class="assistance-header">{{ 'Purchase items' | transloco }}</span>
          <div class="product-container">
            <img class="image" src="assets/illustrations/{{ homeAssistanceOrderDetail.plan.id }}.svg" alt="{{ homeAssistanceOrderDetail.plan.name + ' image' | transloco }}" loading="lazy" />
            <div class="info">
              <span class="above">{{ homeAssistanceOrderDetail.plan.name }}</span>
              <span class="under">{{ homeAssistanceOrderDetail.totalAmount | currencyFormatter: homeAssistanceOrderDetail.currency }} {{ homeAssistanceOrderDetail.currency }} | 1 u.</span>
            </div>
          </div>
        </div>
      </div>
    }
  </app-retryable-section>
</section>