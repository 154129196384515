import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { RetryableSectionComponent } from '../../components/retryable-section/retryable-section.component';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_NUMBER_PATTERN } from '../../constants/validation-patterns.constants';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../services/forms/forms.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    InputMaskModule,
    InputTextModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RetryableSectionComponent,
    TranslocoPipe,
    RouterLink,
  ],
  templateUrl: './contact.page.html',
  styleUrl: './contact.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ContactPage implements OnInit, OnDestroy {
  errorLoading = false;
  form: FormGroup<{
    email: FormControl<string | null>;
    fatherLastName: FormControl<string | null>;
    message: FormControl<string | null>;
    motherLastName: FormControl<string | null>;
    name: FormControl<string | null>;
    phoneNumber: FormControl<string | null>;
  }>;
  isLoading = false;
  sendingForm = false;
  showSuccessScreen = false;
  user: User | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    protected location: Location,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    this.form = new FormGroup({
      email: new FormControl<string | null>('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      fatherLastName: new FormControl<string | null>('', [Validators.required, Validators.maxLength(36), Validators.pattern(NAME_PATTERN)]),
      message: new FormControl<string | null>('', [Validators.maxLength(512)]),
      motherLastName: new FormControl<string | null>('', [Validators.required, Validators.maxLength(36), Validators.pattern(NAME_PATTERN)]),
      name: new FormControl<string | null>('', [Validators.required, Validators.maxLength(36), Validators.pattern(NAME_PATTERN)]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(PHONE_NUMBER_PATTERN)]),
    });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  goToSignIn(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/enrollment/sign-in'], {
      queryParams: {
        redirectTo: '/complaints-and-returns',
      }
    });
  }

  initialize(): void {
    this.isLoading = true;
    this.errorLoading = false;
    this.usersService.getCurrentUser().pipe(takeUntil(this.viewDestroyed))
      .subscribe({
        next: (user) => {
          this.user = user;
          if (user) {
            this.form.patchValue({
              email: user.email,
              fatherLastName: user.fatherLastName ?? '',
              motherLastName: user.motherLastName ?? '',
              name: user.name,
              phoneNumber: user.phoneNumber!.replace(/\D/g, ''),
            });
            this.form.controls.email.disable();
            this.form.controls.fatherLastName.disable();
            this.form.controls.motherLastName.disable();
            this.form.controls.name.disable();
            this.form.controls.phoneNumber.disable();
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.errorReportingService.log('ContactPage.initialize()', 'get-user-data', error);
          this.isLoading = false;
          this.errorLoading = true;
        },
      });
  }

  async onSubmit(): Promise<void> {
    this.sendingForm = true;
    let step = '';
    try {
      const formValue = this.form.getRawValue();
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactPlatform'));
      step = 'submit-contact-platform';
      await this.formsService.submitContactPlatform({
        email: formValue.email!,
        fatherLastName: this.user?.fatherLastName ?? formValue.fatherLastName!,
        message: formValue.message!,
        motherLastName: this.user?.motherLastName ?? formValue.motherLastName!,
        name: formValue.name!,
        phoneNumber: `+${formValue.phoneNumber}`,
        recaptchaToken,
      });
      this.toastService.showSuccess({ title: 'Submitted form', description: 'We will contact you promptly to help you in this process' });
      this.showSuccessScreen = true;
    } catch (error) {
      this.errorReportingService.log('ContactPage.onSubmit()', step, error);
      this.toastService.showError({ title: 'Error submitting form', description: 'An error occurred while submitting the form, please try again later' });
      this.showSuccessScreen = false;
    } finally {
      this.sendingForm = false;
    }
  }
}
