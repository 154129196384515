export function changeDataIsoStringToDate<T>(data: T): T {
  if (data && typeof data === 'object') {
    for (const key in data) {
      if (key.endsWith('At') && typeof data[key] === 'string') {
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        (data as any)[key] = new Date(data[key] as string);
      } else if (data[key] && typeof data[key] === 'object') {
        data[key] = changeDataIsoStringToDate(data[key]);
      }
    }
  }
  return data;
}