import { Route } from '@angular/router';

export default [
  {
    path: '',
    redirectTo: '/payment-gateway/checkout',
    pathMatch: 'full',
  },
  {
    path: 'checkout',
    loadComponent: () => import('./checkout/checkout.page').then((c) => c.CheckoutPage),
    data: {
      title: 'Pasarela de pago',
      description: 'Realiza tu pago de forma segura',
    },
  },
  {
    path: 'confirm-payment',
    loadComponent: () => import('./confirm-payment/confirm-payment.page').then((c) => c.ConfirmPaymentPage),
    data: {
      title: 'Pasarela de pago',
      description: 'Realiza tu pago de forma segura',
    },
  },
  {
    path: 'payment-result/:paymentIntentId',
    loadComponent: () => import('./payment-result/payment-result.page').then((c) => c.PaymentResultPage),
    data: {
      title: 'Pasarela de pago',
      description: 'Realiza tu pago de forma segura',
    },
  },
  {
    path: 'payment-result',
    loadComponent: () => import('./payment-result/payment-result.page').then((c) => c.PaymentResultPage),
    data: {
      title: 'Pasarela de pago',
      description: 'Realiza tu pago de forma segura',
    },
  },
] satisfies Route[];