import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-purchases/my-purchases.page').then(m => m.MyPurchasesPage),
    data: {
      description: 'Portal exclusivo para clientes de Banco Santander que reúne en un mismo lugar beneficios únicos en servicios para el hogar.',
      title: 'Mis compras',
    },
  },
  {
    path: ':orderId',
    loadComponent: () => import('./purchase-status/purchase-status.page').then(m => m.PurchaseStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('./../../../components/ecommerce-purchase-details/ecommerce-purchase-details.component').then(m => m.EcommercePurchaseDetailsComponent)
  }
] satisfies Routes;
