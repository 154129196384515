@if (loading) {
  <main class="moving-loading-page">
    <p-progressSpinner></p-progressSpinner>
  </main>
} @else {
  <section class="main">
    <div class="main-container">
      <div class="right-side">
        <div class="title-container">
          <p class="on-title-text">Beneficios exclusivos para clientes <strong>Santander</strong></p>
          <h1 class="title">Mudanzas sin estrés a tu nuevo hogar</h1>
        </div>
        <div class="button-container">
          <app-button innerId="btn-ErM5pMli" label="Cotizar mudanza" (action)="navigateToQuote()" styleClass="primary" icon="truck" />
        </div>
      </div>
      <div class="left-side">
        <img class="main-image" src="assets/images/moving-main.svg" [alt]="'Moving main image' | transloco " />
      </div>
    </div>
  </section>
  <section class="terms-banner">
    <app-terms-banner page="moving" />
  </section>
  <section class="moving-benefits">
    <div class="container">
      <div class="left-container">
        <img src="assets/images/moving-steps.svg" class="moving-image" alt="Imagen de pasos de tu mudanza" />
      </div>
      <div class="right-container">
        <div class="title-container">
          <h2 class="title">Tu mudanza en solo 3 pasos</h2>
        </div>
        <div class="benefits-container">
          <ol class="benefits">
            <li class="benefit">Ingresa los datos para cotizar tu mudanza.</li>
            <li class="benefit">Selecciona el tipo de servicio que necesitas.</li>
            <li class="benefit">Todo listo, nos ocuparemos del resto.</li>
          </ol>
        </div>
        <div class="button-container">
          <app-button innerId="btn-50EFv0vY" label="Cotiza gratis" (action)="navigateToQuote()" styleClass="secondary expand" />
        </div>
      </div>
    </div>
  </section>
  <section class="how-moving-works-section">
    <div class="container">
      <h4 class="title">¿Cómo funciona?</h4>
      <div class="how-moving-works">
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">Cotiza, escoge el plan que se adapte mejor a tus necesidades.</p>
        </div>
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">Paga un anticipo para reservar tu mudanza en el día seleccionado.</p>
        </div>
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">Recibe la visita de tu mudancero días antes de la mudanza para asegurarse que todo está listo.</p>
        </div>
        <div class="how-container">
          <div class="hyphen">-</div>
          <p class="text">¡Llegó el día! Realiza tu mudanza y paga el monto restante a tu mudancero.</p>
        </div>
      </div>
    </div>
  </section>
  <section class="moving-banner-section">
    <div class="banner-container">
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">{{ "Your next move with up to 15% discount" | transloco }}</h4>
          <app-button innerId="btn-PlG87rvh" label="Cotizar" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img" src="assets/images/moving-banner-1.png" alt="Imagen del banner de mudanza" loading="lazy" />
        </div>
      </div>
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">Protege cada espacio de tu siguiente hogar</h4>
          <app-button innerId="btn-zsBY2RDQ" label="Ver plan" routerLink="/home-assistance" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img second-banner" src="assets/images/moving-banner-2.png" alt="Imagen del banner de mudanza" loading="lazy" />
        </div>
      </div>
    </div>
  </section>
  <section class="why-trust-moving-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <span class="title">¿Por qué confiamos en<img class="moving-logo" alt="Logo Mudango" src="assets/images/mudango-logo.svg" />?</span>
          <hr class="underline">
        </div>
      </div>
      <div class="right-container">
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Centrados en ti</h4>
            <p class="description">Presupuesto sin sorpresas y a la medida de lo que necesitas.</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Experiencia y capacidad</h4>
            <p class="description">Más de 30,000 mudanzas al año en las principales ciudades de la región.</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Cobertura local</h4>
            <p class="description">Presencia en las principales ciudades y zonas del país.</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">Trayectoria</h4>
            <p class="description">Pioneros en mudanzas online desde el 2017 con presencia en México, Colombia y Chile.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="coverage-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <h2 class="title">Estamos en las principales ciudades de México</h2>
        </div>
        <div class="left-button-container">
          <app-button innerId="btn-BcSyNEUS" label="Consulta tu dirección" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
      </div>
      <div class="right-container">
        <ul class="cities-list">
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Ciudad de México</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Guadalajara</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Monterrey</span>
          </li>
        </ul>
        <p class="more-info">y más... consulta y cotiza tu mudanza</p>
      </div>
      <div class="button-container">
        <app-button innerId="btn-sOxeJZRO" label="Consulta tu dirección" (action)="navigateToQuote()" styleClass="secondary" />
      </div>
    </div>
  </section>
  <article class="why-platform-article">
    <div class="title-container">
      <h2 class="title">¿Por qué Mundo Hogar?</h2>
      <hr class="underline">
      <div class="benefits-container">
        <div class="benefit">
          <i class="icon-monitor"></i>
          <div class="benefit-content">
            <h4 class="label">Todo en un mismo lugar</h4>
            <span class="description">Encuentra todo lo que necesitas para tu hogar, en un mismo portal 100% online.</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-jewel"></i>
          <div class="benefit-content">
            <h4 class="label">Beneficios exclusivos</h4>
            <span class="description">Alternativas de financiamiento exclusivas para clientes Santander y mucho más.</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-security-profile"></i>
          <div class="benefit-content">
            <h4 class="label">Los mejores proveedores</h4>
            <span class="description">Proveedores calificados, servicios garantizados y métodos de pagos seguros para ti.</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-thumbs-up"></i>
          <div class="benefit-content">
            <h4 class="label">Simple y transparente</h4>
            <span class="description">Gestiona y haz el seguimiento online de tus proyectos en pocos clics.</span>
          </div>
        </div>
      </div>
      <div class="become-a-customer">
        <p class="customer-benefit">Beneficios exclusivos para clientes Santander. Si aún no eres cliente, haz <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">clic aquí.</a><p>
      </div>
    </div>
  </article>
  <article class="faq-article">
    <h2 class="title">Preguntas frecuentes</h2>
    <div class="faq-container">
      <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
        <p-accordionTab class="accordion-tab" header="¿Por qué debo pagar una reserva?" iconPos="end">
          <div class="text-container">
            <p class="text">La reserva es un abono del precio total, que permite asegurar disponibilidad para tu mudanza y darte tranquilidad mental. La reserva es 100% reembolsable en caso que canceles tu mudanza, y 100% flexible para cambios de fecha, libre de penalizaciones.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Qué pasa si no tengo fecha definida para mi mudanza?" iconPos="end">
          <div class="text-container">
            <p class="text">No hay problema, puedes reservar y cambiar la fecha cuantas veces quieras. Queremos darte tranquilidad mental.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Quién realiza mi mudanza?" iconPos="end">
          <div class="text-container">
            <p class="text">El servicio de mudanza es realizado por nuestros Moving Partners, que son equipos preseleccionados y constantemente capacitados por nosotros. Los equipos están clasificados según el tipo de servicio que deseas contratar, y trabajan codo a codo con nosotros en nuestra plataforma.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Qué es la Reserva de mi mudanza?" iconPos="end">
          <div class="text-container">
            <p class="text">La reserva de una mudanza consiste en el proceso mediante el cual un cliente programa y asegura la disponibilidad del servicio de mudanza en una fecha y hora específica. La reserva equivale al 20% del valor estimado de la mudanza. Una vez realizada la reserva, organizamos el equipo y recursos necesarios para ejecutar la mudanza según los requisitos del cliente, garantizando una experiencia segura y eficiente.</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="¿Qué es la Visita de Confirmación de Volumen (VCV)?" iconPos="end">
          <div class="text-container">
            <p class="text">Uno de los proceso más importantes que ocurren entre tu reserva de mudanza y el día del servicio, es la Visita de Confirmación de Volumen (VCV). En esta visita, el equipo que realizará tu mudanza va a inspeccionar tu hogar y tus cosas, para revisar todos los detalles menores - pero importantes - que podrían afectar la buena realización de tu servicio.</p>
            <p class="text">¿Qué tipo de detalles? Por ejemplo, si no indicaste en la cotización que tu edificio no tiene estacionamiento cercano para el camión, o si hay algún ítem sobre-dimensionado que requiera ser subido o bajado por escaleras o por ventana. Otro ejemplo típico es cuando hay muebles más complejos que requieren ser desarmados para salir de tu hogar actual, o para ingresar a tu nuevo hogar.</p>
            <p class="text">Así, para entender todos estos detalles, y también para que estemos seguros de que el tamaño del camión será el adecuado para la cantidad de cosas que se moverán en la mudanza, la VCV es obligatoria para todos los clientes. Generalmente estos detalles ya se revisan y se consideran en la cotización junto con tu asesor antes de reservar, mientras resuelves tus dudas y tomas la decisión de hacer tu mudanza con nosotros. Sin embargo es importante que estemos seguros de que todo está considerado. Sólo así podremos entregar un servicio con la calidad que queremos y que tu te mereces.</p>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
    <h5 class="help-content">Visita nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">Centro de ayuda</a> para más información</h5>
  </article>
}
