import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ButtonComponent } from '../../../components/button/button.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LocalUser } from '../../../services/users/users.service';
import { buildQuery } from '../../../utils/url.utils';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    RouterLink,
    SharedModule,
    TranslocoPipe,
    ProgressSpinnerModule,
    TermsBannerComponent,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnDestroy, OnInit {
  loading = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private movingOrdersService: MovingOrdersService,
    private platformService: PlatformService,
    private router: Router,
    private seoService: SeoService,
    private toastService: ToastService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('moving');
    }
    this.analyticsService.logMovingEvent({ step: 'start' });
    if (this.router.url.includes('quote')) {
      this.loading = true;
    }
    this.activatedRoute.queryParams.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (queryParams) => {
        if (queryParams['action'] === 'quote') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.navigateToQuote();
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async navigateToQuote(): Promise<void> {
    let step = '';
    this.loading = true;
    try {
      step = 'get-current-user';
      const user = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
      const { appUrl: movingAppUrl, reference: ref } = environment.partners.moving;
      if (!user) {
        this.toastService.showWarning({ title: 'Necesitas iniciar sesión', description: 'Para realizar esta acción, necesitas haber ingresado a tu cuenta Mundo Hogar.' });
        step = 'navigate-to-sign-in';
        await this.router.navigate(['enrollment/sign-in'], { queryParams: { origin: 'moving', redirectTo: encodeURIComponent(movingAppUrl) } });
        return;
      } else if (user.enrollmentStatus === 'pending') {
        this.toastService.showWarning({ title: 'Finaliza tu registro', description: 'Para acceder a todos los beneficios exclusivos que Mundo Hogar tiene para ti, necesitas finalizar tu registro.' });
        step = 'navigate-to-sign-in';
        await this.router.navigate(['enrollment/sign-in'], { queryParams: { origin: 'moving', redirectTo: encodeURIComponent(movingAppUrl) } });
        return;
      }
      const { email, fatherLastName, id, name, phoneNumber } = user;
      const queryParams = {
        email,
        external_reference: this.movingOrdersService.createId(),
        ref,
        user_id: id,
        ...(name && { name }),
        ...(fatherLastName && { last_name: fatherLastName }),
        ...(phoneNumber && { cellphone: phoneNumber.substring(3) }), // TODO: this should be truncated based on the phone country code
      };
      window.location.href = `${movingAppUrl}?${buildQuery(queryParams)}`;
      this.analyticsService.logMovingEvent({ step: 'start_quote' });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    } catch (error) {
      this.errorReportingService.log('HomePage.navigateToQuote()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
      this.loading = false;
    }
  }
}
