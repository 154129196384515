import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MortgageSimulationType } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import {
  EMPTY_MORTGAGE_SIMULATION,
  LocalMortgageSimulation,
  MortgageSimulatorStep,
} from '../../../models/mortgage-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { PlatformService } from '../../../services/platform/platform.service';

interface MortgageSimulationTypeSelector {
  icon: string;
  selected: boolean;
  translationKey: string;
  type: MortgageSimulationType;
}

@Component({
  selector: 'app-simulation-type',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './simulation-type.page.html',
  styleUrl: './simulation-type.page.scss',
})
export class SimulationTypePage implements OnInit {
  simulationTypeSelected: MortgageSimulationType | null = null;
  simulationTypes: MortgageSimulationTypeSelector[] = [{
    icon: 'icon-house-dollar-sign-inside',
    selected: false,
    translationKey: 'Housing cost',
    type: 'housing-cost',
  }, {
    icon: 'icon-hand-dollar-sign-floating',
    selected: false,
    translationKey: 'Amount you need',
    type: 'amount',
  }];

  constructor(
    private dataStorageService: DataStorageService,
    private platformService: PlatformService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.platformService.isServer()) {
      return;
    }
    const currentSimulation = await this.getCurrentSimulation();
    if (!currentSimulation) {
      return;
    }
    this.simulationTypes = this.simulationTypes.map((mortgageSimulationType) => ({
      ...mortgageSimulationType,
      selected: mortgageSimulationType.type === currentSimulation.type,
    }));
    this.simulationTypeSelected = currentSimulation.type;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async continue(): Promise<void> {
    let currentSimulation = await this.getCurrentSimulation();
    if (!currentSimulation) {
      return;
    }
    currentSimulation = this.resetSimulation(currentSimulation);
    this.dataStorageService.set(DataKey.MortgageSimulation, {
      ...currentSimulation,
      stepsCompleted: currentSimulation.stepsCompleted.includes(MortgageSimulatorStep.SimulationType) ?
        currentSimulation.stepsCompleted :
        [MortgageSimulatorStep.SimulationType],
      type: this.simulationTypes.filter((mortgageSimulationType) => mortgageSimulationType.selected)[0].type,
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/mortgage-simulator/simulation-data']);
  }

  selectSimulationType(simulationTypeOption: MortgageSimulationTypeSelector): void {
    this.simulationTypes = this.simulationTypes.map((simulationType) => ({
      ...simulationType,
      selected: simulationType.type === simulationTypeOption.type,
    }));
    this.simulationTypeSelected = simulationTypeOption.type;
  }

  private getCurrentSimulation(): Promise<LocalMortgageSimulation | null> {
    return firstValueFrom(this.dataStorageService.get<LocalMortgageSimulation>(DataKey.MortgageSimulation));
  }

  private resetSimulation(currentSimulation: LocalMortgageSimulation): LocalMortgageSimulation {
    if (currentSimulation.type !== this.simulationTypes.find((type) => type.selected)?.type) {
      return {
        ...EMPTY_MORTGAGE_SIMULATION,
        email: currentSimulation.email,
        fullName: currentSimulation.fullName,
        phoneNumber: currentSimulation.phoneNumber,
      };
    }
    return currentSimulation;
  }
}
