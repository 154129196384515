<section class="remodeling-purchase-details-container">
  <div class="title-container">
    @if (showBackButton) {
      <i class="icon icon-arrow-left" routerLink="/private-site/my-remodelings/{{ orderId }}"></i>
    }
    <h2 class="title">Detalle de compra</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (order) {
      <div class="total-container">
        <div class="purchase-detail">{{ order.createdAt | date: 'dd/MM/yyyy' }} | #{{ order.id }}</div>
        <div class="products-value">
          <span class="label">Subtotal</span>
          <span class="value">{{ order.subtotal | currencyFormatter: order.currency }} {{ order.currency }}</span>
        </div>
        <div class="separator"></div>
        <div class="total">
          <div class="label">Total</div>
          <div class="value">{{ order.totalAmount | currencyFormatter: order.currency }} {{ order.currency }}</div>
        </div>
      </div>
      @if (order.paymentIntentIds && order.paymentIntentIds.length) {
        <div class="app-purchase-payment">
          <app-purchase-payment-details [paymentIntentIds]="order.paymentIntentIds" />
        </div>
      }
      <div class="remodeling-container">
        <h2 class="title">Items de la compra</h2>
        <div class="item-container">
          <div class="item-wrapper">
            <img class="image" src="/assets/illustrations/remodeling-item.svg" alt="Imagen del item de la remodelación" />
            <div class="info">
              <span class="data">{{ order.additionalData['opportunityName'] }}</span>
              <div class="amounts">
                <span class="value">{{ order.totalAmount | currencyFormatter: order.currency }}</span>
                <span class="value">{{ order.subtotal | currencyFormatter: order.currency }}</span>
                <span class="value">| 1 unidad</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </app-retryable-section>
</section>