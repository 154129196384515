import { ParamMap, Params, Router } from '@angular/router';
import { User } from '@homein-hogar-server';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { DataKey, DataStorageService } from '../services/data-storage/data-storage.service';
import { LocalUser } from '../services/users/users.service';
import { environment } from '../../environments/environment';
import { MovingOrdersService } from '../services/moving-orders/moving-orders.service';
import { WarehouseOrdersService } from '../services/warehouse-orders/warehouse-orders.service';
import { buildQuery } from './url.utils';

export async function validateAndNavigate(
  user: User | null,
  dataStorageService: DataStorageService,
  router: Router,
  queryParamMap: ParamMap,
  analyticsService: AnalyticsService,
  movingOrdersService: MovingOrdersService,
  warehouseOrdersService: WarehouseOrdersService): Promise<void> {
  if (!user) {
    throw new Error('Error getting user');
  }
  dataStorageService.set<LocalUser>(DataKey.LocalUser, {
    bankValidated: user.bankValidated,
    email: user.email,
    emailValidated: user.emailValidated,
    enrollmentStatus: user.enrollmentStatus,
    fatherLastName: user.fatherLastName,
    id: user.id,
    motherLastName: user.motherLastName,
    name: user.name,
    phoneNumber: user.phoneNumber,
    profileDataCompleted: user.profileDataCompleted,
  });
  const origin = queryParamMap.get('origin');
  let redirectTo = queryParamMap.get('redirectTo');
  const queryParams: Params = {
    ...(origin && { origin }),
    ...(redirectTo && { redirectTo })
  };
  if (!user.emailValidated) {
    redirectTo = redirectTo ? decodeURIComponent(redirectTo) : null;
    if (redirectTo?.startsWith('/enrollment/verify-email')) {
      await router.navigateByUrl(redirectTo);
      return;
    }
    await router.navigate(['/enrollment/verify-email'], { queryParams, queryParamsHandling: 'merge' });
    return;
  }
  if (!user.name || !user.fatherLastName || !user.phoneNumber || !user.birthDate) {
    await router.navigate(['/enrollment/onboarding'], { queryParams, queryParamsHandling: 'merge' });
    return;
  }
  if (!user.bankValidated) {
    await router.navigate(['/enrollment/bank-validation'], { queryParams, queryParamsHandling: 'merge' });
    return;
  }

  await customNavigate(user, router, analyticsService, movingOrdersService, warehouseOrdersService, queryParamMap);
}

async function customNavigate(
  user: User | null,
  router: Router,
  analyticsService: AnalyticsService,
  movingOrdersService: MovingOrdersService,
  warehouseOrdersService: WarehouseOrdersService,
  queryParamMap: ParamMap
): Promise<void> {
  if (!user) {
    return;
  }
  let redirectTo = queryParamMap.get('redirectTo');
  redirectTo = redirectTo ? decodeURIComponent(redirectTo) : null;
  if (!redirectTo) {
    await router.navigateByUrl('/private-site');
    return;
  }
  const navigationOrigin = queryParamMap.get('origin');
  let queryParams!: string;
  if (navigationOrigin && ['moving', 'warehouse'].includes(navigationOrigin)) {
    const { email, fatherLastName, id, name, phoneNumber } = user;
    const externalReference = navigationOrigin === 'moving' ? movingOrdersService.createId() : warehouseOrdersService.createId();
    const reference = environment.partners[navigationOrigin as keyof typeof environment.partners].reference;
    queryParams = buildQuery({
      email,
      external_reference: externalReference,
      ref: reference,
      user_id: id,
      ...(name && { name }),
      ...(fatherLastName && { last_name: fatherLastName }),
      ...(phoneNumber && { cellphone: phoneNumber.substring(3) }), // TODO: this should be truncated based on the phone country code
    });
  }
  if (redirectTo.startsWith('/')) {
    await router.navigateByUrl(redirectTo);
  } else {
    const params = queryParams ? `?${queryParams}` : '';
    window.location.href = `${redirectTo}${params}`;
    if (navigationOrigin === 'moving') {
      analyticsService.logMovingEvent({ step: 'start_quote' });
    } else if (navigationOrigin === 'warehouse') {
      analyticsService.logWarehouseEvent({ step: 'start_quote' });
    }
  }
}

export function brevoIdentify(user: User | null): void {
  if (!window.sib || !window.Brevo || !window.Brevo.push) {
    return;
  }
  const email = user?.email ?? '';
  const attributes = user ? {
    user_bank_validated: `${user.bankValidated}`,
    user_email: user.email,
    user_email_validated: `${user.emailValidated}`,
    user_enrollment_status: user.enrollmentStatus,
    user_father_last_name: user.fatherLastName ?? '',
    user_id: user.id,
    user_mother_last_name: user.motherLastName ?? '',
    user_name: user.name ?? '',
    user_phone_number: user.phoneNumber ?? '',
    user_profile_data_completed: `${user.profileDataCompleted}`,
  } : {};

  window.sib.email_id = email;
  window.Brevo.push([
    'identify', {
      attributes,
      identifiers : {
        email_id : email
      },
    },
  ]);
}