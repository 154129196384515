import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ContactForm, EcommerceOrder, EcommerceOrderItem, HomeAssistanceOrder, MovingOrder, RemodelingOrder, User, WarehouseOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { SelectItemGroup } from 'primeng/api';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import { firstValueFrom, Subject } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { RetryableSectionComponent } from '../../components/retryable-section/retryable-section.component';
import { emailPattern, phoneNumberPattern } from '../../constants/validation-patterns.constants';
import { EcommerceOrdersService } from '../../services/ecommerce-orders/ecommerce-orders.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../services/forms/forms.service';
import { HomeAssistanceOrderDetail, HomeAssistanceOrdersService } from '../../services/home-assistance-orders/home-assistance-orders.service';
import { MovingOrdersService } from '../../services/moving-orders/moving-orders.service';
import { RemodelingOrdersService } from '../../services/remodeling-orders/remodeling-orders.service';
import { SeoService } from '../../services/seo/seo.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';
import { WarehouseOrdersService } from '../../services/warehouse-orders/warehouse-orders.service';
import { isActive } from '../../utils/forms.utils';
import { getComplementaryData } from '../../utils/home-assistances.utils';

type Service = 'home-assistance'| 'warehouse'| 'ecommerce' | 'moving' | 'platform' | 'remodeling';
const ecommerceRequestTypes = [
  {
    parent: 'Quiero devolver este producto',
    children: [
      'Producto no es lo que ordené',
    ]
  },
  {
    parent: 'Producto dañado',
    children: [
      'Producto llegó dañado',
      'Daño en producto de alto volumen'
    ]
  },
  {
    parent: 'Incidencia',
    children: [
      'Extravío o robo',
      'Producto incompleto',
      'Producto se encuentra en oficina de mensajería'
    ]
  },
  {
    parent: 'Garantía',
    children: [
      'Solicitar garantía',
    ]
  },
  {
    parent: 'Otros',
    children: [
      'No parece ser original',
      'La calidad no es la esperada',
      'Tiempo de entrega excedido',
      'Tamaño equivocado',
      'No coincide con la publicación',
      'Producto parece ser usado',
      'Llegó el empaque vacío sin el producto'
    ]
  }
];

@Component({
  selector: 'app-complaints-and-returns',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputMaskModule,
    InputTextModule,
    MultiSelectModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './complaints-and-returns.page.html',
  styleUrl: './complaints-and-returns.page.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
})
export class ComplaintsAndReturnsPage implements OnInit, OnDestroy {
  contactEcommerceForms: ContactForm[] = [];
  ecommerceOrders: EcommerceOrder[] = [];
  errorLoadingInitialData = false;
  errorLoadingOrders = false;
  form: FormGroup<{
    email: FormControl<string | null>;
    message: FormControl<string | null>,
    phoneNumber: FormControl<string | null>;
    service: FormControl<Service | null>;
  }>;
  formEcommerce: FormGroup<{
    requestDetail: FormControl<string | null>;
    requestType: FormControl<string | null>;
    selectedEcommerceOrder: FormControl<{ item: EcommerceOrderItem, order: EcommerceOrder }[] | null>
  }>;
  formHomeAssistance: FormGroup<{
    purchase: FormControl<HomeAssistanceOrderDetail | null>;
    requestType: FormControl<string | null>;
  }>;
  formMoving: FormGroup<{
    purchase: FormControl<MovingOrder | null>;
    requestType: FormControl<string | null>;
  }>;
  formRemodeling: FormGroup<{
    purchase: FormControl<RemodelingOrder | null>;
  }>;
  formWarehouse: FormGroup<{
    purchase: FormControl<WarehouseOrder | null>;
    requestType: FormControl<string | null>;
  }>;
  homeAssistanceOrdersDetails: HomeAssistanceOrderDetail[] = [];
  loadingInitialData = true;
  loadingOrders = false;
  maxWords = 500;
  movingOrders: MovingOrder[] = [];
  orders: SelectItemGroup[] = [];
  orderItemIdsByOrderId: Record<string, string[]> = {};
  remodelingOrders: RemodelingOrder[] = [];
  requestDetailOptions: string[] = [];
  requestOptions = ecommerceRequestTypes.map((option) => option.parent);
  sendingForm = false;
  services: { label: string; value: Service; }[] = [
    { label: 'Asistencia', value: 'home-assistance' },
    { label: 'Bodegaje', value: 'warehouse' },
    { label: 'Compras para el hogar', value: 'ecommerce' },
    { label: 'Mudanza', value: 'moving' },
    { label: 'Plataforma Mundo Hogar', value: 'platform' },
    { label: 'Remodelación', value: 'remodeling' },
  ];
  showSuccessScreen = false;
  suggestedApplications: { label: string; value: string; }[] = [
    { label: 'Felicitación', value: 'congratulation' },
    { label: 'Petición', value: 'petition' },
    { label: 'Queja', value: 'grievance' },
    { label: 'Reclamo', value: 'complaint' },
    { label: 'Saludo', value: 'greeting' },
  ];
  suggestedHomeAssistanceApplications: { label: string; value: string; }[] = [
    { label: 'Coberturas', value: 'coverage' },
    { label: 'Daños', value: 'damage' },
    { label: 'Demora', value: 'delay' },
    { label: 'Desembolso', value: 'reimbursement' },
    { label: 'Servicio mal realizado', value: 'service-not-performed' },
    { label: 'Servicio no brindado', value: 'service-not-provided' },
  ];
  user: User | null = null;
  userName = '';
  warehouseOrders: WarehouseOrder[] = [];
  wordCount = 0;
  private orderId: string | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private ecommerceOrdersService: EcommerceOrdersService,
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    private movingOrdersService: MovingOrdersService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private remodelingOrdersService: RemodelingOrdersService,
    private seoService: SeoService,
    private toastService: ToastService,
    private usersService: UsersService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {
    this.form = new FormGroup({
      email: new FormControl<string | null>('', [Validators.required, Validators.pattern(emailPattern)]),
      message: new FormControl<string | null>('', [Validators.required, Validators.maxLength(512)]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(phoneNumberPattern)]),
      service: new FormControl<Service | null>(null, [Validators.required]),
    });
    this.form.controls.service.getRawValue();
    this.formHomeAssistance = new FormGroup({
      purchase: new FormControl<HomeAssistanceOrderDetail | null>(null,[Validators.required]),
      requestType: new FormControl<string | null>(null,[Validators.required]),
    });
    this.formEcommerce = new FormGroup({
      requestDetail: new FormControl<string | null>(null,[Validators.required]),
      requestType: new FormControl<string | null>(null,[Validators.required]),
      selectedEcommerceOrder: new FormControl<{ item: EcommerceOrderItem, order: EcommerceOrder }[] | null>(null,[Validators.required]),
    });
    this.formMoving = new FormGroup({
      purchase: new FormControl<MovingOrder | null>(null,[Validators.required]),
      requestType: new FormControl<string | null>(null,[Validators.required]),
    });
    this.formRemodeling = new FormGroup({
      purchase: new FormControl<RemodelingOrder | null>(null,[Validators.required]),
    });
    this.formWarehouse = new FormGroup({
      purchase: new FormControl<WarehouseOrder | null>(null,[Validators.required]),
      requestType: new FormControl<string | null>(null,[Validators.required]),
    });
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.loadOrderData();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get validateForms(): boolean {
    let formIsValid = this.form.valid;
    const service = this.form.controls.service.value;
    if (service === 'ecommerce') {
      const hasEcommerceOrders = this.ecommerceOrders && this.ecommerceOrders.length > 0;
      formIsValid = hasEcommerceOrders ? formIsValid && this.formEcommerce.valid : formIsValid;
    } else if (service === 'home-assistance') {
      const hasAssistanceOrders = this.homeAssistanceOrdersDetails && this.homeAssistanceOrdersDetails.length > 0;
      formIsValid = hasAssistanceOrders ? formIsValid && this.formHomeAssistance.valid : formIsValid;
    } else if (service === 'moving') {
      const hasMovingOrders = this.movingOrders && this.movingOrders.length > 0;
      formIsValid = hasMovingOrders ? formIsValid && this.formMoving.valid : formIsValid;
    } else if (service === 'remodeling') {
      const hasRemodelingOrders = this.remodelingOrders && this.remodelingOrders.length > 0;
      formIsValid = hasRemodelingOrders ? formIsValid && this.formRemodeling.valid : formIsValid;
    } else if (service === 'warehouse') {
      const hasWarehouseOrders = this.warehouseOrders && this.warehouseOrders.length > 0;
      formIsValid = hasWarehouseOrders ? formIsValid && this.formWarehouse.valid : formIsValid;
    }
    return !formIsValid;
  }

  handleRequestTypeChange($event: DropdownChangeEvent): void {
    const selectedParent = $event.value;
    const selectedRequestType = ecommerceRequestTypes.find((option) => option.parent === selectedParent);
    this.requestDetailOptions = selectedRequestType ? selectedRequestType.children : [];
  }

  handleSelectedEcommerceOrder(option: MultiSelectChangeEvent): void {
    const selectedItem = option.itemValue.value;
    const selectedItems = option.value;
    const itemSubmitted = this.orderItemIdsByOrderId[selectedItem.order.id]?.includes(selectedItem.item.id);
    if (itemSubmitted) {
      this.toastService.showError({ title: 'Error', description: 'Ya se ha enviado un formulario para este producto.' }, 3000);
      selectedItems.pop();
      return;
    }
    if (selectedItems.length === 1) {
      return;
    }
    if (selectedItems.length > 1 && (selectedItems[0].order.id !== selectedItem.order.id)) {
      this.toastService.showError({ title: 'Selección incorrecta', description: 'Por favor, selecciona productos de una misma orden.' }, 3000);
      selectedItems.pop();
    }
    this.formEcommerce.controls['selectedEcommerceOrder'].setValue(selectedItems);
  }

  handleTextAreaEvent($event: Event): void {
    const input = $event.target as HTMLTextAreaElement;
    this.wordCount = input.value.length;
  }

  async initialize(): Promise<void> {
    this.loadingInitialData = true;
    this.errorLoadingInitialData = false;
    try {
      this.user = await firstValueFrom(this.usersService.getCurrentUser());
      if (this.user) {
        const userName = this.user.name;
        this.userName = userName ? userName.charAt(0).toUpperCase() + userName.slice(1) : '';
        this.form.patchValue({
          email: this.user.email,
          ...(this.user.phoneNumber && { phoneNumber: this.user.phoneNumber.replace(/\D/g, '') }),
        });
        this.form.controls.email.disable();
        this.form.controls.phoneNumber.disable();
      }
    } catch (error) {
      this.errorReportingService.log('ComplaintsAndReturnsPage.initialize()', 'get-user-data', error);
      this.errorLoadingInitialData = true;
    } finally {
      this.loadingInitialData = false;
    }
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  async loadOrders(): Promise<void> {
    this.loadingOrders = true;
    this.errorLoadingOrders = false;
    let step = '';
    const service = this.form.controls.service.value as Service;
    this.resetOtherForms(service);
    try {
      if (service === 'ecommerce') {
        step = 'get-all-ecommerce-orders';
        this.ecommerceOrders = await firstValueFrom(this.ecommerceOrdersService.getAll());
        this.orders = this.ecommerceOrders.map((order) => {
          return {
            label: `${this.datePipe.transform(order.createdAt, 'dd-MM-yyyy')} ID Orden: ${order.id}`,
            value: order,
            items: order.items.map((item) => ({
              label: `${item.brand} - ${item.name}`,
              value: { item, order },
            })),
          };
        });
        this.contactEcommerceForms = await firstValueFrom(this.formsService.getContactForms('ecommerce'));
        this.contactEcommerceForms.forEach((form) => this.orderItemIdsByOrderId[form.additionalData?.['orderId']] = form.additionalData?.['orderItemIds'] ?? []);
        if (this.orderId) {
          const orderItemId = this.activatedRoute.snapshot.queryParamMap.get('orderItemId');
          const itemSubmitted = orderItemId && this.orderItemIdsByOrderId[this.orderId]?.includes(orderItemId);
          if (!itemSubmitted) {
            const ecommerceOrder = this.ecommerceOrders.find((order) => order.id === this.orderId);
            const orderItem = ecommerceOrder?.items.find((item) => item.id === orderItemId);
            this.formEcommerce.controls.selectedEcommerceOrder.setValue(ecommerceOrder && orderItem ? [{ item: orderItem, order: ecommerceOrder }] : null);
          } else {
            this.toastService.showError({ title: 'Error', description: 'Ya se ha enviado un formulario para este producto.' }, 3000);
          }
        }
      } else if (service === 'moving') {
        step = 'get-all-moving-orders';
        this.movingOrders = await firstValueFrom(this.movingOrdersService.getAll());
        if (this.orderId) {
          const movingOrder = this.movingOrders.find((order) => order.id === this.orderId);
          this.formMoving.controls.purchase.setValue(movingOrder ?? null);
        }
      } else if (service === 'home-assistance') {
        step = 'get-all-assistance-orders';
        const homeAssistanceOrders = await firstValueFrom(this.homeAssistanceOrdersService.getAll());
        this.homeAssistanceOrdersDetails = homeAssistanceOrders.map((order: HomeAssistanceOrder) => {
          return {
            ...order,
            ...getComplementaryData(order),
          };
        });
        if (this.orderId) {
          const homeAssistanceOrder = this.homeAssistanceOrdersDetails.find((order) => order.id === this.orderId);
          this.formHomeAssistance.controls.purchase.setValue(homeAssistanceOrder ?? null);
        }
      } else if (service === 'remodeling') {
        step = 'get-all-remodeling-orders';
        this.remodelingOrders = await firstValueFrom(this.remodelingOrdersService.getAll());
        if (this.orderId) {
          const remodelingOrder = this.remodelingOrders.find((order) => order.id === this.orderId);
          this.formRemodeling.controls.purchase.setValue(remodelingOrder ?? null);
        }
      } else if (service === 'warehouse') {
        step = 'get-all-warehouse-orders';
        this.warehouseOrders = await firstValueFrom(this.warehouseOrdersService.getAll());
        if (this.orderId) {
          const warehouseOrder = this.warehouseOrders.find((order) => order.id === this.orderId);
          this.formWarehouse.controls.purchase.setValue(warehouseOrder ?? null);
        }
      }
    } catch (error) {
      this.errorReportingService.log('ComplainsAndReturnsPage.handleServiceChange()', step, error);
      this.errorLoadingOrders = true;
    } finally {
      this.loadingOrders = false;
    }
  }

  async onSubmit(): Promise<void> {
    this.sendingForm = true;
    let step = '';
    try {
      if (this.form.invalid) {
        throw new Error('Invalid form');
      }
      if (!this.user) {
        throw new Error('User not found');
      }
      if (this.form.controls.service.value === 'platform') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactPlatform'));
        step = 'submit-contact-platform';
        await this.formsService.submitContactPlatform({
          email: this.user.email,
          fatherLastName: this.user.fatherLastName,
          message: this.form.controls.message.value!,
          motherLastName: this.user.motherLastName,
          name: this.user.name,
          phoneNumber: this.user.phoneNumber,
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'home-assistance') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactHomeAssistance'));
        step = 'submit-contact-home-assistance';
        await this.formsService.submitContactHomeAssistance({
          message: this.form.controls.message.value ?? '',
          orderId: this.formHomeAssistance.controls.purchase.value?.id ?? null,
          requestType: this.formHomeAssistance.controls.requestType.value ?? null,
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'warehouse') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactWarehouse'));
        step = 'submit-contact-warehouse';
        await this.formsService.submitContactWarehouse({
          message: this.form.controls.message.value ?? '',
          orderId: this.formWarehouse.controls.purchase.value?.id ?? null,
          requestType: this.formWarehouse.controls.requestType.value ?? null,
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'ecommerce') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactEcommerce'));
        step = 'submit-contact-ecommerce';
        await this.formsService.submitContactEcommerce({
          message: this.form.controls.message.value ?? '',
          orderId: this.formEcommerce.controls.selectedEcommerceOrder.value?.[0].order.id ?? null,
          orderItemIds: this.formEcommerce.controls.selectedEcommerceOrder.value?.map((item) => item.item.id) ?? [],
          requestDetail: this.formEcommerce.controls.requestDetail.value ?? null,
          requestType: this.formEcommerce.controls.requestType.value ?? null,
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'moving') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactMoving'));
        step = 'submit-contact-moving';
        await this.formsService.submitContactMoving({
          message: this.form.controls.message.value ?? '',
          orderId: this.formMoving.controls.purchase.value?.id ?? null,
          requestType: this.formMoving.controls.requestType.value ?? null,
          recaptchaToken,
        });
      } else if (this.form.controls.service.value === 'remodeling') {
        step = 'get-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitContactRemodeling'));
        step = 'submit-contact-remodeling';
        await this.formsService.submitContactRemodeling({
          message: this.form.controls.message.value ?? '',
          orderId: this.formRemodeling.controls.purchase.value?.id ?? null,
          recaptchaToken,
        });
      }
      this.toastService.showSuccess({ title: 'Formulario enviado', description: 'Nos comunicaremos contigo prontamente para ayudarte en este proceso.' });
      this.showSuccessScreen = true;
    } catch (error) {
      this.errorReportingService.log('ComplaintsAndReturnsPage.onSubmit()', step, error);
      this.toastService.showError({ title: 'Error al enviar el formulario', description: 'Ocurrió un error al enviar el formulario, por favor inténtalo más tarde.' });
      this.showSuccessScreen = false;
    } finally {
      this.sendingForm = false;
    }
  }

  private loadOrderData(): void {
    const service = this.activatedRoute.snapshot.queryParamMap.get('service');
    this.orderId = this.activatedRoute.snapshot.queryParamMap.get('orderId');
    if (service) {
      this.form.patchValue({
        service: service as Service,
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.loadOrders();
    }
  }

  private resetOtherForms(selectedService: Service): void {
    if (selectedService !== 'warehouse') {
      this.formWarehouse.reset();
    }
    if (selectedService !== 'ecommerce') {
      this.formEcommerce.reset();
    }
    if (selectedService !== 'moving') {
      this.formMoving.reset();
    }
    if (selectedService !== 'home-assistance') {
      this.formHomeAssistance.reset();
    }
    if (selectedService !== 'remodeling') {
      this.formRemodeling.reset();
    }
  }
}
