import { Injectable } from '@angular/core';
import { BankIdentificationNumbersRouter, ValidateBankIdentificationNumberData } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { SessionsService } from '../sessions/sessions.service';
import { BankIdentificationNumbersService } from './bank-identification-numbers.service';

@Injectable({
  providedIn: 'root'
})
export class BankIdentificationNumbersServiceApi implements BankIdentificationNumbersService {
  private client: CreateTRPCProxyClient<BankIdentificationNumbersRouter>;

  constructor(
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<BankIdentificationNumbersRouter>(getTRPCClientOptions(`${environment.apiUrl}/bankIdentificationNumbers`, () => this.sessionsService.getAccessToken()));
  }

  validate(data: ValidateBankIdentificationNumberData): Promise<void> {
    return this.client.validate.mutate(data).then();
  }
}
