import { Injectable } from '@angular/core';
import {
  AddShoppingCartItemData, EcommerceExternalPricing,
  RemoveShoppingCartItemData,
  SearchEngineProduct,
  ShoppingCart,
  ShoppingCartItem,
} from '@homein-hogar-server';
import { firstValueFrom, from, map, Observable, of, switchMap } from 'rxjs';
import { CONFIG } from '../../constants';
import { getUpdatedShoppingCartItems } from '../../utils/shopping-cart.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { MockService } from '../mock/mock.service';
import { SearchService } from '../search/search.service';
import { SessionsService } from '../sessions/sessions.service';
import { LocalShoppingCart, ShoppingCartDetail, ShoppingCartsService } from './shopping-carts.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartsServiceMock implements ShoppingCartsService {
  private shoppingCartDetail: Observable<ShoppingCartDetail | null>;

  constructor(
    private dataStorageService: DataStorageService,
    private mockService: MockService,
    private searchService: SearchService,
    private sessionsService: SessionsService,
  ) {
    this.shoppingCartDetail = this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.getShoppingCartMock(session.userId).pipe(switchMap((shoppingCart) => {
          return this.getMockedChildren(shoppingCart);
        }));
      }
      return this.getLocalShoppingCart().pipe(switchMap((localShoppingCart) => {
        return this.getMockedChildren(localShoppingCart);
      }));
    }));
  }

  async addItem(data: AddShoppingCartItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const shoppingCart = await firstValueFrom(this.getShoppingCartMock(session.userId));
      if (!shoppingCart) {
        return this.mockService.createShoppingCart({
          currency: CONFIG.defaultCurrency,
          items: [{
            ...data,
            addedAt: new Date(),
          }],
          id: session.userId,
          shippingAddressId: null,
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateShoppingCart(session.userId, {
        items: getUpdatedShoppingCartItems({
          items: shoppingCart.items,
          action: 'add',
          addItemData: data,
        }),
      });
    }
    let localShoppingCart = await firstValueFrom(this.getLocalShoppingCart());
    if (!localShoppingCart) {
      localShoppingCart = {
        currency: CONFIG.defaultCurrency,
        items: [{
          ...data,
          addedAt: new Date(),
        }],
        shippingAddressId: null,
      };
    } else {
      localShoppingCart = {
        ...localShoppingCart,
        items: getUpdatedShoppingCartItems({
          items: localShoppingCart.items,
          action: 'add',
          addItemData: data,
        }),
      };
    }
    this.dataStorageService.set<LocalShoppingCart>(DataKey.ShoppingCart, localShoppingCart);
  }

  get(): Observable<ShoppingCartDetail | null> {
    return this.shoppingCartDetail;
  }

  async removeItem(data: RemoveShoppingCartItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      const shoppingCart = await firstValueFrom(this.getShoppingCartMock(session.userId));
      if (!shoppingCart) {
        return this.mockService.createShoppingCart({
          currency: CONFIG.defaultCurrency,
          id: session.userId,
          items: [],
          shippingAddressId: null,
          userId: session.userId,
        }).then();
      }
      return this.mockService.updateShoppingCart(session.userId, {
        items: getUpdatedShoppingCartItems({
          action: 'remove',
          items: shoppingCart.items,
          removeItemData: data,
        }),
      });
    }
    let localShoppingCart = await firstValueFrom(this.getLocalShoppingCart());
    if (!localShoppingCart) {
      localShoppingCart = {
        currency: CONFIG.defaultCurrency,
        items: [{
          ...data,
          addedAt: new Date(),
        }],
        shippingAddressId: null,
      };
    } else {
      localShoppingCart = {
        ...localShoppingCart,
        items: getUpdatedShoppingCartItems({
          action: 'remove',
          items: localShoppingCart.items,
          removeItemData: data,
        }),
      };
    }
    this.dataStorageService.set<LocalShoppingCart>(DataKey.ShoppingCart, localShoppingCart);
  }

  async synchronize(): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (!session) {
      return;
    }
    const localShoppingCart = await firstValueFrom(this.getLocalShoppingCart());
    const shoppingCart = await firstValueFrom(this.getShoppingCartMock(session.userId));
    if (!shoppingCart) {
      return this.mockService.createShoppingCart({
        currency: CONFIG.defaultCurrency,
        id: session.userId,
        items: localShoppingCart ? localShoppingCart.items : [],
        shippingAddressId: null,
        userId: session.userId,
      }).then();
    }
    if (localShoppingCart && !localShoppingCart.items.length) {
      return;
    }
    const shoppingCartProductItems = shoppingCart.items.filter((item) => item.resourceType === 'product');
    let newShoppingCartProductItems: ShoppingCartItem[] = [];
    (localShoppingCart?.items ?? []).forEach((item) => {
      const index = shoppingCartProductItems.findIndex((product) => product.resourceId === item.resourceId);
      if (index !== -1) {
        const shoppingCartProductItem = shoppingCartProductItems[index];
        newShoppingCartProductItems.push(shoppingCartProductItem.addedAt.getTime() > item.addedAt.getTime() ? shoppingCartProductItem : item);
        shoppingCartProductItems.splice(index, 1);
      } else {
        newShoppingCartProductItems.push(item);
      }
    });
    newShoppingCartProductItems = newShoppingCartProductItems.sort((a, b) => a.addedAt.getTime() - b.addedAt.getTime());
    if (newShoppingCartProductItems.length > CONFIG.maxShoppingCartItems) {
      newShoppingCartProductItems = newShoppingCartProductItems.slice(-CONFIG.maxShoppingCartItems);
    }
    const newShoppingCartItems = shoppingCart.items.filter((item) => item.resourceType !== 'product').concat(newShoppingCartProductItems);
    await this.mockService.updateShoppingCart(session.userId, { items: newShoppingCartItems });
  }

  async updateShippingAddress(shippingAddressId: string): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      await this.mockService.updateShoppingCart(session.userId, { shippingAddressId });
    }
    const localShoppingCart = await firstValueFrom(this.getLocalShoppingCart());
    this.dataStorageService.set<LocalShoppingCart>(DataKey.ShoppingCart, {
      ...localShoppingCart as LocalShoppingCart,
      shippingAddressId,
    });
  }

  private getLocalShoppingCart(): Observable<LocalShoppingCart | null> {
    return this.dataStorageService.get<LocalShoppingCart>(DataKey.ShoppingCart).pipe(map((localShoppingCart) => {
      if (localShoppingCart) {
        localShoppingCart.items = localShoppingCart.items.map((item) => {
          if (item.addedAt) {
            item.addedAt = new Date(item.addedAt);
          }
          return item;
        });
      }
      return localShoppingCart;
    }));
  }

  private getMockedChildren(shoppingCart: ShoppingCart | LocalShoppingCart | null): Observable<ShoppingCartDetail | null> {
    if (!shoppingCart) {
      return of(null);
    }
    return from(this.searchService.search<SearchEngineProduct>('products', {
      filters: [
        {
          field: 'id',
          operator: 'in',
          value: shoppingCart.items.map((item) => item.resourceId),
        }
      ],
      pagination: {
        documentsPerPage: CONFIG.maxShoppingCartItems,
      },
    })).pipe(map((paginable) => {
      let subtotal = 0;
      let discounts = 0;
      let shippingCost = 0;
      const pricing: Record<string, EcommerceExternalPricing> = {};
      const detailItems = shoppingCart.items.map((item) => {
        if (item.resourceType === 'product') {
          item.addedAt = new Date(item.addedAt);
          const resource = paginable.data.find((product) => product.id === item.resourceId) ?? null;
          if (resource) {
            shippingCost += item.quantity * resource.pricing['debit'].shippingCost;
            subtotal += item.quantity * resource.pricing['debit'].price;
            discounts += item.quantity * (resource.originalPrice - resource?.pricing['debit'].price);
            CONFIG.paymentOptions.forEach((option) => {
              if (!pricing[option]) {
                pricing[option] = { ...resource.pricing[option] };
              } else {
                pricing[option].monthlyPayment += item.quantity * resource.pricing[option].monthlyPayment;
                pricing[option].originalShippingCost += item.quantity * resource.pricing[option].originalShippingCost;
                pricing[option].price += item.quantity * resource.pricing[option].price;
                pricing[option].shippingCost += item.quantity * resource.pricing[option].shippingCost;
              }
            });
            return {
              ...item,
              resource,
            };
          }
        }
        return {
          ...item,
          resource: null,
        };
      }).filter((item) => item.resource);
      let total = subtotal;
      if (shoppingCart.shippingAddressId) {
        total += shippingCost;
      }
      return {
        ...shoppingCart,
        discounts,
        items: detailItems,
        pricing,
        shippingCost,
        subtotal,
        total,
        totalItems: detailItems.reduce((totalItems, item) => totalItems + item.quantity, 0),
      };
    }));
  }

  private getShoppingCartMock(id: string): Observable<ShoppingCart | null> {
    return this.mockService.getShoppingCart(id).pipe(map((shoppingCart) => {
      if (shoppingCart) {
        shoppingCart.createdAt = new Date(shoppingCart.createdAt);
        shoppingCart.updatedAt = new Date(shoppingCart.updatedAt);
        shoppingCart.items = shoppingCart.items.map((item) => {
          if (item.addedAt) {
            item.addedAt = new Date(item.addedAt);
          }
          return item;
        });
      }
      return shoppingCart;
    }));
  }
}
