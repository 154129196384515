@if (products.length >= 4) {
  <section class="products-of-interest-container">
    <div class="title-container">
      @if (href) {
        @if (internalLink) {
          <a class="title" [id]="innerId + '-title-' + title" [routerLink]="href" [queryParams]="queryParams">{{ title }}</a>
        } @else {
          <a class="title" [id]="innerId + '-title-' + title" [href]="href" [target]="target" [rel]="rel">{{ title }}</a>
        }
      } @else {
        <h3 class="title">{{ title }}</h3>
      }
    </div>
    <app-retryable-section [error]="errorLoadingProducts" [loading]="loadingProducts" (retry)="getProducts()">
      <p-carousel class="products-of-interest-carousel"
        [contentClass]="'carousel display-navigation'"
        [value]="products"
        [numVisible]="4"
        [numScroll]="4"
        [responsiveOptions]="[
          { breakpoint: '1365px', numVisible: 4, numScroll: 4 },
          { breakpoint: '1200px', numVisible: 4, numScroll: 3 },
          { breakpoint: '1100px', numVisible: 3.5, numScroll: 3 },
          { breakpoint: '1000px', numVisible: 3, numScroll: 2 },
          { breakpoint: '800px', numVisible: 3.2, numScroll: 2 },
          { breakpoint: '650px', numVisible: 3, numScroll: 2 },
          { breakpoint: '550px', numVisible: 2.5, numScroll: 2 },
          { breakpoint: '500px', numVisible: 2.1, numScroll: 2 },
        ]"
      >
        <ng-template pTemplate="previousicon">
          <div class="navigation-wrapper">
            <i class="icon-arrow-left"></i>
          </div>
        </ng-template>
        <ng-template let-product pTemplate="item">
          <app-product-card class="product-card" [innerId]="innerId + '-' + product.index" [product]="product" [isFavorite]="!!favorites['product-' + product.id]"></app-product-card>
        </ng-template>
        <ng-template pTemplate="nexticon">
          <div class="navigation-wrapper">
            <i class="icon-arrow-right"></i>
          </div>
        </ng-template>
      </p-carousel>
    </app-retryable-section>
  </section>
}