import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProjectSimulationType } from '@homein-hogar-server';
import { TranslocoModule } from '@ngneat/transloco';
import { InputTextModule } from 'primeng/inputtext';
import { StepperModule } from 'primeng/stepper';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { CircularProgressComponent } from '../../../components/circular-progress/circular-progress.component';
import { LocalProjectSimulation } from '../../../models/local-project-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { SeoService } from '../../../services/seo/seo.service';
import { getImageByType } from '../../../utils/project-simulator.utils';

@Component({
  selector: 'app-step-2',
  standalone: true,
  imports: [
    ButtonComponent,
    CircularProgressComponent,
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './step-2.page.html',
  styleUrl: './step-2.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class Step2Page implements OnDestroy, OnInit {
  form: FormGroup<{
    type: FormControl<ProjectSimulationType | null>;
  }>;
  projectSimulation: LocalProjectSimulation | null;
  selectedImage = '';
  subtitleByType = '';
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private location: Location,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.form = new FormGroup({
      type: new FormControl<ProjectSimulationType | null>(null, [Validators.required]),
    });
    this.form.controls.type.valueChanges.pipe(takeUntil(this.viewDestroyed)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async (value) => {
        if (value !== this.projectSimulation?.type) {
          const simulation = await this.getCurrentSimulation() as LocalProjectSimulation;
          const data: LocalProjectSimulation = {
            accessories: null,
            completedSteps: simulation.completedSteps.filter((step) => ![3, 4, 5].includes(step)),
            email: this.projectSimulation?.email ?? '',
            executionTimeFrame: simulation.executionTimeFrame,
            fullName: this.projectSimulation?.fullName ?? '',
            phoneNumber: this.projectSimulation?.phoneNumber ?? '',
            postalCode: simulation.postalCode,
            surfaceArea: 0,
            propertyType: simulation.propertyType,
            type: null,
          };
          this.dataStorageService.set(DataKey.ProjectSimulation, data);
          this.projectSimulation = data;
        }
        this.setSelectedImage(value);
        if (value === 'bathroom') {
          this.subtitleByType = 'Next: Number of bathrooms';
        } else if (value === 'kitchen') {
          this.subtitleByType = 'Next: Kitchen configuration';
        } else if (value === 'integral-remodeling') {
          this.subtitleByType = 'Next: Finishing standards';
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  async ngOnInit(): Promise<void> {
    this.projectSimulation = await this.getCurrentSimulation();
    if (!this.projectSimulation || !this.projectSimulation.completedSteps.includes(1)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/project-simulator/step-1']);
      return;
    }
    this.setSelectedImage(this.projectSimulation.type);
    this.form.patchValue({
      type: this.projectSimulation.type,
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  async onSubmit(): Promise<void> {
    const type = this.form.controls.type.value;
    if (!type) {
      return;
    }
    const currentSimulation = await this.getCurrentSimulation();
    if (!currentSimulation) {
      return;
    }
    if (!currentSimulation.completedSteps.includes(2)) {
      currentSimulation.completedSteps.push(2);
    }
    this.dataStorageService.set(DataKey.ProjectSimulation, {
      ...(currentSimulation ?? {}),
      type,
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/project-simulator/step-3']);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setFormControlValue(formControl: FormControl, value: any): void {
    formControl.setValue(value);
  }

  setSelectedImage(type: ProjectSimulationType | null): void {
    if (!type) {
      if (this.projectSimulation?.propertyType === 'house') {
        this.selectedImage = '/assets/illustrations/project-simulation-prop-type-house.svg';
      } else if (this.projectSimulation?.propertyType === 'apartment') {
        this.selectedImage = '/assets/illustrations/project-simulation-prop-type-apartment.svg';
      }
      return;
    }
    this.selectedImage = getImageByType(type);
  }

  private getCurrentSimulation(): Promise<LocalProjectSimulation | null> {
    return firstValueFrom(this.dataStorageService.get<LocalProjectSimulation>(DataKey.ProjectSimulation));
  }
}
