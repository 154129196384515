import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { environment } from '../../../environments/environment';
import { formatPhoneNumber } from '../../utils/string.utils';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-send-result-sidebar',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ProgressSpinnerModule,
    SidebarModule,
    TranslocoModule,
  ],
  templateUrl: './send-result-sidebar.component.html',
  styleUrl: './send-result-sidebar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SendResultSidebarComponent implements OnInit, OnChanges {
  @Output() changePhoneNumber = new EventEmitter<void>();
  @Output() doActionAgain = new EventEmitter<void>();
  @Output() goTo = new EventEmitter<void>();
  @Input() goToLabel: string;
  @Input({ required: true }) phoneNumber: string;
  @Input({ required: true }) resultSent: boolean;
  @Input({ required: true }) sendingResult: boolean;
  @Output() sendResult = new EventEmitter<void>();
  @Input({ required: true }) type: 'simulation' | 'form';
  protected formattedPhoneNumber = '';
  protected isMobile = false;
  protected platformPhoneNumber = environment.platformWhatsAppPhoneNumber;
  protected visible = false;

  ngOnInit(): void {
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['phoneNumber']?.currentValue) {
      this.formattedPhoneNumber = formatPhoneNumber(this.phoneNumber);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  close(): void {
    this.sendingResult = false;
    this.resultSent = false;
    this.visible = false;
  }

  open(): void {
    this.visible = true;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }

  protected emitChangePhoneNumber(): void {
    this.changePhoneNumber.emit();
    this.close();
  }

  protected emitDoActionAgain(): void {
    this.doActionAgain.emit();
  }

  protected emitGoTo(): void {
    this.goTo.emit();
  }

  protected send(): void {
    this.sendResult.emit();
  }
}
