<header class="header-navbar" [class.not-home]="!isHome" [class.no-shadow]="isEcommerce" [class.no-fixed]="isEcommerceSearch">
  <div class="header-container">
    <div class="nav-container">
      <nav class="navbar">
        <app-button innerId="btn-Bx5pnWMP" label="Menu" styleClass="secondary dark" icon="hamburger-menu" (action)="showMenuSidebar()" />
        <app-button innerId="btn-Ldp9gqaB" label="What is Mundo Hogar?" styleClass="tertiary dark" routerLink="/what-is-mundo-hogar" />
      </nav>
      <div class="logo-container">
        <a class="link" routerLink="/" id="btn-link-t826lA0j"><div class="logo animate__fadeIn"></div></a>
      </div>
      <div class="right-container" [class.show]="firstLoadFinished" [class.animate__fadeIn]="firstLoadFinished">
        @if(!isEcommerce && (hasFavorites || (shoppingCartDetail && shoppingCartDetail.totalItems))) {
          <div class="ecommerce-container">
            <a class="custom-button-clear" routerLink="/ecommerce/my-favorites">
              <i class="icon-heart icon" [class.p-overlay-badge]="hasFavorites">
                @if (hasFavorites) {
                  <p-badge class="badge" />
                }
              </i>
            </a>
            <a class="custom-button-clear" routerLink="/ecommerce/shopping-cart">
              <i class="icon-supermarket-cart icon cart" [class.p-overlay-badge]="shoppingCartDetail && shoppingCartDetail.totalItems">
                @if (shoppingCartDetail && shoppingCartDetail.totalItems) {
                  <p-badge class="badge" [value]="shoppingCartDetail.totalItems.toString()" />
                }
              </i>
            </a>
          </div>
        }
        @if (user && user.enrollmentStatus === 'done') {
          <div class="avatar-container">
            <div class="user-info">
              <p class="greeting">{{ 'Hi' | transloco }} 👋</p>
              <p class="user-name">{{ user.name }}</p>
            </div>
            <div class="avatar" (click)="overlayPanel.toggle($event)" id="btn-link-VsmwzkAU">{{ userNameInitials }}</div>
          </div>
        } @else {
          @if (user && user.enrollmentStatus === 'pending') {
            <app-button innerId="btn-epQDZCd_" label="Complete" styleClass="secondary" routerLink="/enrollment" />
          } @else {
            <app-button innerId="btn-qifipqQG" label="Sign in" styleClass="primary" routerLink="/enrollment/sign-in" />
          }
        }
      </div>
    </div>
  </div>
</header>
<div class="header-spacer" [class.no-spacer]="isEcommerceSearch"></div>
<app-menu-sidebar [visible]="menuSidebarVisible" (closed)="closedMenuSidebar()" id="btn-link-nDL8kpzv"/>
<p-overlayPanel #overlayPanel>
  <ng-template pTemplate="content">
    <app-button innerId="btn-lC9969JT" label="My Services" (action)="goTo('/private-site')" styleClass="tertiary dark" />
    <app-button innerId="btn-6awjUhOU" label="My favorites" (action)="goTo('/ecommerce/my-favorites')" styleClass="tertiary dark" />
    <app-button innerId="btn-_RfJ2APO" label="My shopping cart" (action)="goTo('/ecommerce/shopping-cart')" styleClass="tertiary dark" />
    <app-button innerId="btn-RROcWIVC" label="My personal data" (action)="goTo('/private-site/profile/my-personal-data')" styleClass="tertiary dark" />
    <app-button innerId="btn-AO7vpEa7" label="Sign out" (action)="signOut()" styleClass="tertiary dark" />
  </ng-template>
</p-overlayPanel>
@if ((!isEcommerce && !isEnrollment && !isPrivateSite) && (hasFavorites || (shoppingCartDetail && shoppingCartDetail.totalItems))) {
  <div class="fixed-wrapper">
    <div class="helpful-ecommerce-container">
      <div class="helpful-ecommerce">
        <i class="icon-heart icon" [class.p-overlay-badge]="hasFavorites" routerLink="/ecommerce/my-favorites">
          @if (hasFavorites) {
            <p-badge class="badge" />
          }
        </i>
        <i class="icon-supermarket-cart icon cart" [class.p-overlay-badge]="shoppingCartDetail && shoppingCartDetail.totalItems" routerLink="/ecommerce/shopping-cart">
          @if (shoppingCartDetail && shoppingCartDetail.totalItems) {
            <p-badge class="badge" [value]="shoppingCartDetail.totalItems.toString()" />
          }
        </i>
      </div>
    </div>
  </div>
} @else if (isEcommerce) {
  <div class="ecommerce-complementary-bar">
    <div class="search-input-container">
      <app-search-input [query]="query" (inputClick)="openSearch()" />
    </div>
    <div class="main-container">
      <div class="main-wrapper">
        <div class="left-controls">
          @if (isEcommerceSearch) {
            <button class="custom-button-clear filters" (click)="openFilters()">{{ 'Filters' | transloco }}
              <i class="icon icon-filter" [class.p-overlay-badge]="hasActiveFilters">
                @if (hasActiveFilters) {
                  <p-badge class="badge" />
                }
              </i>
            </button>
          } @else if (showBackButton) {
            <button class="custom-button-clear back" (click)="goBack()"><i class="pi pi-angle-left icon"></i></button>
          }
        </div>
        <app-search-input class="search-input" [query]="query" [searchHistory]="searchHistory" (searchClick)="search($event)" (deleteHistoryClick)="deleteSearchHistory()" (changeQuery)="changeQuery($event)" />
        <div class="right-controls">
          @if (showSearchIcon) {
            <button class="custom-button-clear search" (click)="openSearch()"><i class="icon-search icon"></i></button>
          }
          <a class="custom-button-clear" routerLink="/private-site/my-purchases"><i class="icon-shopping-bag icon"></i></a>
          <a class="custom-button-clear" routerLink="/ecommerce/my-favorites">
            <i class="icon-heart icon" [class.p-overlay-badge]="hasFavorites">
              @if (hasFavorites) {
                <p-badge class="badge" />
              }
            </i>
          </a>
          <a class="custom-button-clear" routerLink="/ecommerce/shopping-cart">
            <i class="icon-supermarket-cart icon cart" [class.p-overlay-badge]="shoppingCartDetail && shoppingCartDetail.totalItems">
              @if (shoppingCartDetail && shoppingCartDetail.totalItems) {
                <p-badge class="badge" [value]="shoppingCartDetail.totalItems.toString()" />
              }
            </i>
          </a>
        </div>
      </div>
    </div>
    <div class="ecommerce-bar-spacer"></div>
    <ng-container>
      <ng-content></ng-content>
    </ng-container>
  </div>
}
<app-filter-sidebar #filterSidebar [filtersApplied]="activatedFilters" (applyFiltersClick)="filter($event)" />
<app-search-sidebar #searchSidebar [query]="query" [searchHistory]="searchHistory" (searchClick)="search($event)" (deleteHistoryClick)="deleteSearchHistory()" (changeQuery)="changeQuery($event)" />
