import { PaymentIntent } from '@homein-hogar-server';
import { CONFIG } from '../../constants';
import { ACCOUNTS_MOCK } from '../accounts-server/accounts/accounts.mock';
import { USERS_MOCK } from '../users/users.mock';

// TODO: add reversed payment intents and external apportions
export const PAYMENT_INTENTS_MOCK: PaymentIntent[] = [
  {
    additionalData: null,
    amount: 100,
    apportions: {
      external: [],
      internal: [
        {
          amount: 50,
          fromAccountId: 'dto3Qxi7t7fybfV98cSJ',
          fromAccountType: 'debit',
        },
      ],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: '5kRr3kd4mXFOGLHH2i2t',
    message: 'Servicio de Mudanza: Mudanza Lite',
    orderId: 'bTbxBen3OxEXncIbNWFf',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: ['1BW3X7Ze5S8va0dU8GYd'],
    resourceSubtypes: ['Mudanza Lite'],
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'moving',
    status: 'paid',
    statusDetail: null,
    toAccountId: '1IdB2MzN0LaROGJc8fjM',
    transactionId: 'duv3jIy8aP02xiERfFjt',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 120,
    apportions: {
      external: [],
      internal: [
        {
          amount: 120,
          fromAccountId: 'dto3Qxi7t7fybfV98cSJ',
          fromAccountType: 'debit',
        },
      ],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'nmfYcRBovOiji1VIRabw',
    message: 'Servicio de Mudanza: Mudanza Lite',
    orderId: '8Dz0Y4oel39r3uaJ06Qy',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: ['dcJYsQK4zoEhNBAsBCPb'],
    resourceSubtypes: ['Mudanza Lite'],
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'moving',
    status: 'paid',
    statusDetail: null,
    toAccountId: '1IdB2MzN0LaROGJc8fjM',
    transactionId: 'NGvxPgyO1B01XQLIEIyF',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 90,
    apportions: {
      external: [],
      internal: [
        {
          amount: 90,
          fromAccountId: 'dto3Qxi7t7fybfV98cSJ',
          fromAccountType: 'debit',
        },
      ],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: '0M3mZOnbsfrgYGafqiQp',
    message: 'Servicio de Mudanza: Mudanza Lite',
    orderId: 'ThiyfHvCOQoWFhu59gtR',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: ['bnUgSYiBxhRC2GJWBpzN'],
    resourceSubtypes: ['Mudanza Lite'],
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'moving',
    status: 'paid',
    statusDetail: null,
    toAccountId: '1IdB2MzN0LaROGJc8fjM',
    transactionId: 'daFLwZCCqYhOzZFJ3pvI',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 1178.33,
    apportions: {
      external: [],
      internal: [
        {
          amount: 1178.33,
          fromAccountId: 'dto3Qxi7t7fybfV98cSJ',
          fromAccountType: 'debit',
        },
      ],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'WXXK7DGcWqxwBL12KxKH',
    message: 'Servicio de Mudanza: Mudango Black',
    orderId: 'ThiyfHvCOQoWFhu59gtR',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: ['duv3jIy8aP02xiERfFjt'],
    resourceSubtypes: ['Mudango Black'],
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'moving',
    status: 'paid',
    statusDetail: null,
    toAccountId: '1IdB2MzN0LaROGJc8fjM',
    transactionId: 'NGvxPgyO1B01XQLIEIyF',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 8000,
    apportions: {
      external: [],
      internal: [
        {
          amount: 8000,
          fromAccountId: ACCOUNTS_MOCK[0].id,
          fromAccountType: ACCOUNTS_MOCK[0].type,
        },
      ],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'FORZAMGjNOYVI5h4r8t2',
    message: 'Cargo por proyecto de remodelación',
    orderId: 'Yr6Hsnixndgvp6XQrAlj',
    paymentId: 'Eyw8ySWJlVtwSff00P14',
    paymentReverseId: null,
    resourceIds: ['JaqtPea7UWU4lVfmWCzS'],
    resourceSubtypes: null,
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'remodeling',
    status: 'paid',
    statusDetail: null,
    toAccountId: 'NeFrdPDICOCDvdnNICsL',
    transactionId: 'NGvxPgyO1B01XQLIEIyF',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 3000,
    apportions: {
      external: [],
      internal: [
        {
          amount: 3000,
          fromAccountId: ACCOUNTS_MOCK[0].id,
          fromAccountType: ACCOUNTS_MOCK[0].type,
        },
      ],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: '6CttfibstLEm2qBRJ11v',
    message: 'Cargo por proyecto de remodelación',
    orderId: 'yYM8OqQ7LOCi5rOETHrp',
    paymentId: 'w1Bll1wozCfV4l4FWphL',
    paymentReverseId: null,
    resourceIds: ['JaqtPea7UWU4lVfmWCzS'],
    resourceSubtypes: null,
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'remodeling',
    status: 'paid',
    statusDetail: null,
    toAccountId: 'NeFrdPDICOCDvdnNICsL',
    transactionId: 'NGvxPgyO1B01XQLIEIyF',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 3000,
    apportions: {
      external: [
        {
          amount: 3000,
          channel: 'getnet',
          installments: 1,
          option: 'debit',
        }
      ],
      internal: [],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'yJDQSejpzKBgvxgH9jUd',
    message: 'Cargo por proyecto de remodelación',
    orderId: 'Yr6Hsnixndgvp6XQrAlj',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: ['JaqtPea7UWU4lVfmWCzS'],
    resourceSubtypes: null,
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'remodeling',
    status: 'paid',
    statusDetail: null,
    toAccountId: 'NeFrdPDICOCDvdnNICsL',
    transactionId: 'NGvxPgyO1B01XQLIEIyF',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 2203.62,
    apportions: {
      external: [
        {
          amount: 2203.62,
          channel: 'getnet',
          installments: 1,
          option: 'debit',
        }
      ],
      internal: [],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'ZupaDcqpEydfR7X99Ct1',
    message: '1 producto',
    orderId: 'n60YyoeeyxX2mVevazeN',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: ['t0h8tk8xehwfw2xpyk79'],
    resourceSubtypes: null,
    resourceType: 'product',
    reversed: false,
    reversedAt: null,
    source: 'ecommerce',
    status: 'paid',
    statusDetail: null,
    toAccountId: 'product-FAoraMUfgMe5s2gapNF1',
    transactionId: 'NGvxPgyO1B01XQLIEIyF',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 2000,
    apportions: {
      external: [
        {
          amount: 2000,
          channel: 'getnet',
          installments: 1,
          option: 'debit',
        }
      ],
      internal: [],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'AHGf5jJuqUes4EqCOWKb',
    message: 'Plan Hogar: 1 mes',
    orderId: 'exhnRTzlj7uWi3S0RTKf',
    paymentId: 'Qrd7262aw9ZPWkB6jjQj',
    paymentReverseId: null,
    resourceIds: [],
    resourceSubtypes: null,
    resourceType: 'home-assistance-plan',
    reversed: false,
    reversedAt: null,
    source: 'home-assistance',
    status: 'paid',
    statusDetail: null,
    toAccountId: 'product-FAoraMUfgMe5s2gapNF1',
    transactionId: 'NGvxPgyO1B01XQLIEIyF',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 199,
    apportions: {
      external: [],
      internal: [{
        amount: 199,
        fromAccountId: 'dto3Qxi7t7fybfV98cSJ',
        fromAccountType: 'debit'
      }],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'KhcATKPIS5IlbXAXb6pW',
    message: 'Servicio de bodega',
    orderId: 'JpE4OXbfKfCtNpilcppa',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: [],
    resourceSubtypes: null,
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'warehouse',
    status: 'paid',
    statusDetail: null,
    toAccountId: '1IdB2MzN0LaROGJc8fjM',
    transactionId: 'aZrjk1XMKgO6JcSSoBp6',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 199,
    apportions: {
      external: [],
      internal: [{
        amount: 199,
        fromAccountId: 'dto3Qxi7t7fybfV98cSJ',
        fromAccountType: 'debit'
      }],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'f3lXSUOcOq4JnkqTNMLn',
    message: 'Servicio de bodega',
    orderId: 'pl8YAncZPzYZfnKHMgVc',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: [],
    resourceSubtypes: null,
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'warehouse',
    status: 'paid',
    statusDetail: null,
    toAccountId: '1IdB2MzN0LaROGJc8fjM',
    transactionId: 'GBbU5FfFt3OekFOh59Hx',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    amount: 199,
    apportions: {
      external: [],
      internal: [{
        amount: 199,
        fromAccountId: 'dto3Qxi7t7fybfV98cSJ',
        fromAccountType: 'debit'
      }],
    },
    authorizationId: null,
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    id: 'gUQDJ77JIX0GVy5v6TNc',
    message: 'Servicio de bodega',
    orderId: 'QEudcyLPVxyxFMCcAWG3',
    paymentId: null,
    paymentReverseId: null,
    resourceIds: [],
    resourceSubtypes: null,
    resourceType: 'charge-intent',
    reversed: false,
    reversedAt: null,
    source: 'warehouse',
    status: 'paid',
    statusDetail: null,
    toAccountId: '1IdB2MzN0LaROGJc8fjM',
    transactionId: 'm8YiK5Lj6cuRM7LKPlrK',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
];
