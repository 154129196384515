@if (innerId) {
  @if (href) {
    @if (internalLink) {
      <a class="hm-button {{ styleClass }}" [class.loading]="loading" [id]="innerId" [routerLink]="href" [queryParams]="queryParams">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    } @else {
      <a class="hm-button {{ styleClass }}" [class.loading]="loading" [id]="innerId" [href]="href" [target]="target" [rel]="rel">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    }
  } @else {
    <button class="hm-button {{ styleClass }}" [class.loading]="loading" [id]="innerId" [type]="type" [disabled]="disabled" (click)="triggerAction()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  }
} @else {
  <!-- TODO: Delete when script is complete-->
  @if (href) {
    @if (internalLink) {
      <a class="hm-button {{ styleClass }}" [class.loading]="loading" [routerLink]="href" [queryParams]="queryParams">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    } @else {
      <a class="hm-button {{ styleClass }}" [class.loading]="loading" [href]="href" [target]="target" [rel]="rel">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    }
  } @else {
    <button class="hm-button {{ styleClass }}" [class.loading]="loading" [type]="type" [disabled]="disabled" (click)="triggerAction()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  }
}
<ng-template #content>
  @if (loading) {
    <i class="pi pi-spin pi-spinner"></i>
  } @else if (iconConfig && label) {
    <span class="button-content {{ iconConfig.position }}">
      <i class="icon-{{ iconConfig.name }} text"></i>{{ label }}
    </span>
  } @else if (iconConfig) {
    <i class="icon-{{ iconConfig.name }}"></i>
  } @else {
    {{ label }}
  }
</ng-template>