<div class="checkout-title-container-mobile">
  <i class="icon-arrow-left" (click)="goBack()"></i>
  <h2 class="title">{{ 'Payment methods' | transloco }}</h2>
</div>
<section class="checkout-container">
  <div class="title-container">
    <i class="icon-arrow-left" (click)="goBack()"></i>
    <h2 class="title">{{ 'Payment methods' | transloco }}</h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    <p class="subtitle">{{ 'Select a payment method' | transloco }}</p>
    <div class="payment-methods-container">
      <div class="payment-method">
        <div class="first-section" [class.opened]="controls.selectedPaymentMethod === 'spot-payment'" (click)="togglePaymentMethod('spot-payment')">
          <div class="content">
            <div class="title">{{ 'Spot payment' | transloco }}</div>
            <div class="price">{{ controls.spotPaymentControls.price | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
          </div>
          <i class="pi" [class.pi-angle-down]="!this.controls.spotPaymentControls.opened" [class.pi-angle-up]="this.controls.spotPaymentControls.opened"></i>
        </div>
        <div class="second-section" [class.show]="controls.selectedPaymentMethod === 'spot-payment' && (controls.spotPaymentControls.opened || isAtLeastOneSpotPaymentMethodSelected)">
          <div class="opened">
            @if (controls.spotPaymentControls.opened && (controls.spotPaymentControls.debit.availableAmount > 0 || controls.spotPaymentControls.mortgage.availableAmount > 0)) {
              <div class="title">{{ 'Mundo Hogar balances' | transloco }}</div>
            }
            <div class="accounts-container">
              @if (controls.spotPaymentControls.mortgage.availableAmount > 0 && (controls.spotPaymentControls.opened || (controls.spotPaymentControls.mortgage.selected && !controls.spotPaymentControls.opened))) {
                <div class="account" [class.disabled]="controls.spotPaymentControls.mortgage.disabled" [class.selected]="controls.spotPaymentControls.mortgage.selected" (click)="selectAccount('mortgage')">
                  <div class="right">
                    <div class="account-icon"></div>
                    <div class="account-info">
                      <div class="type">{{ 'Remodeling balance' | transloco }}</div>
                      <div class="amount">{{ controls.spotPaymentControls.mortgage.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                      <div class="available-amount">{{ 'Available:' | transloco }} {{ controls.spotPaymentControls.mortgage.availableAmount | currencyFormatter: controls.currency }} {{ controls.currency }}</div>
                    </div>
                  </div>
                  <div class="account-control">
                    @if (controls.spotPaymentControls.mortgage.disabled) {
                      <i class="icon-information-circle" [pTooltip]="controls.spotPaymentControls.mortgage.disabledTooltip | transloco" tooltipPosition="bottom"></i>
                    } @else {
                      <div class="toggle"><i class="icon-checkmark"></i></div>
                    }
                  </div>
                </div>
              }
              @if (controls.spotPaymentControls.debit.availableAmount > 0 && (controls.spotPaymentControls.opened || (controls.spotPaymentControls.debit.selected && !controls.spotPaymentControls.opened))) {
                <div class="account" [class.disabled]="controls.spotPaymentControls.debit.disabled" [class.selected]="controls.spotPaymentControls.debit.selected" (click)="selectAccount('debit')">
                  <div class="right">
                    <div class="account-icon"></div>
                    <div class="account-info">
                      <div class="type">{{ 'Full availability balance' | transloco }}</div>
                      <div class="amount">{{ controls.spotPaymentControls.debit.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                      <div class="available-amount">{{ 'Available:' | transloco }} {{ controls.spotPaymentControls.debit.availableAmount | currencyFormatter: controls.currency }} {{ controls.currency }}</div>
                    </div>
                  </div>
                  <div class="account-control">
                    @if (controls.spotPaymentControls.debit.disabled) {
                      <i class="icon-information-circle" [pTooltip]="controls.spotPaymentControls.debit.disabledTooltip | transloco" tooltipPosition="bottom"></i>
                    } @else {
                      <div class="toggle"><i class="icon-checkmark"></i></div>
                    }
                  </div>
                </div>
              }
            </div>
            @if (controls.spotPaymentControls.opened || (isAtLeastOneSpotPaymentMethodSelected && controls.missingAmount > 0 && !controls.spotPaymentControls.opened)) {
              <div class="missing-amount-container" [class.show]="isAtLeastOneSpotPaymentMethodSelected && controls.missingAmount > 0">
                @if (controls.spotPaymentControls.opened) {
                  <app-reminder title="Insufficient balance" [description]="{ key: 'You need to complement by paying X by debit or credit card.', params: { missingAmount: (controls.missingAmount | currencyFormatter: controls.currency) + ' ' + controls.currency } }" styleClass="alert light"></app-reminder>
                } @else {
                  <app-reminder title="Insufficient balance" [description]="{ key: 'You need to complement by paying X by debit or credit card.', params: { missingAmount: (controls.missingAmount | currencyFormatter: controls.currency) + ' ' + controls.currency } }" actionLabel="Select card" (action)="togglePaymentMethod('spot-payment')" styleClass="alert light"></app-reminder>
                }
              </div>
            }
            <div class="cards-container">
              @if (controls.spotPaymentControls.opened) {
                <div class="title">{{ 'Cards' | transloco }}</div>
              }
              @if (controls.spotPaymentControls.opened || (controls.spotPaymentControls.card.selectedType === 'credit' && !controls.spotPaymentControls.opened)) {
                <div class="card" [class.selected]="controls.spotPaymentControls.card.selectedType === 'credit'" (click)="selectCard('credit')">
                  <div class="right">
                    <div class="card-icon"></div>
                    <div class="card-info">
                      <div class="type">{{ 'Credit card' | transloco }}</div>
                      <div class="amount">{{ controls.spotPaymentControls.card.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                    </div>
                  </div>
                  <div class="card-control">
                    <div class="toggle"><i class="icon-checkmark"></i></div>
                  </div>
                </div>
              }
              @if (controls.spotPaymentControls.opened || (controls.spotPaymentControls.card.selectedType === 'debit' && !controls.spotPaymentControls.opened)) {
                <div class="card" [class.selected]="controls.spotPaymentControls.card.selectedType === 'debit'" (click)="selectCard('debit')">
                  <div class="right">
                    <div class="card-icon"></div>
                    <div class="card-info">
                      <div class="type">{{ 'Debit card' | transloco }}</div>
                      <div class="amount">{{ controls.spotPaymentControls.card.amount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                    </div>
                  </div>
                  <div class="card-control">
                    <div class="toggle"><i class="icon-checkmark"></i></div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="payment-method">
        <div class="first-section" (click)="togglePaymentMethod('monthly-installments')">
          <div class="content">
            <div class="chip">{{ 'Santander Benefits' | transloco }}</div>
            <div class="title">{{ 'Monthly installments' | transloco }}</div>
            <div class="price">{{ controls.monthlyInstallmentsControls.installmentAmount | currencyFormatter }} <span class="currency">{{ controls.currency }}</span><span class="term">{{ 'for X months' | transloco: { months: controls.monthlyInstallmentsControls.installments } }}</span></div>
          </div>
          <i class="pi" [class.pi-angle-down]="!this.controls.monthlyInstallmentsControls.opened" [class.pi-angle-up]="this.controls.monthlyInstallmentsControls.opened"></i>
        </div>
        <div class="second-section" [class.show]="controls.selectedPaymentMethod === 'monthly-installments' && (controls.monthlyInstallmentsControls.opened || isMonthlyInstallmentsMethodSelected)">
          <div class="opened">
            @if (controls.monthlyInstallmentsControls.opened) {
              <div class="title">{{ 'Exclusive prices with your Santander cards' | transloco }}</div>
            }
            <div class="installments-container">
              @if (controls.monthlyInstallmentsControls.opened || (isMonthlyInstallmentsMethodSelected && !controls.monthlyInstallmentsControls.opened)) {
                @for (option of controls.monthlyInstallmentsControls.options; track $index) {
                  @if (controls.monthlyInstallmentsControls.opened || option.selected) {
                    <div class="installment-option" [class.selected]="option.selected" (click)="toggleMonthlyInstallmentCardOption(option)">
                      <div class="right">
                        <div class="option-icon"></div>
                        <div class="option-info">
                          <div class="term">{{ 'Pay in X months interest-free' | transloco: { months: option.installments } }}</div>
                          <div class="price">{{ option.installmentAmount | currencyFormatter: controls.currency }} <span class="currency">{{ controls.currency }}</span></div>
                          <div class="total-payment">{{ 'Total payment:' | transloco }} {{ option.amount | currencyFormatter: controls.currency }} {{ controls.currency }}</div>
                        </div>
                      </div>
                      <div class="option-control">
                        <div class="toggle"><i class="icon-checkmark"></i></div>
                      </div>
                    </div>
                  }
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <app-button label="Cancel" styleClass="secondary expand" (click)="goBack()" />
      <app-button label="Next" styleClass="primary expand" (click)="next()" [disabled]="controls.missingAmount > 0" />
    </div>
  </app-retryable-section>
</section>
@if (!loading && !errorLoading) {
  <div class="checkout-buttons-container-mobile">
    <app-button label="Next" styleClass="primary expand" (click)="next()" [disabled]="controls.missingAmount > 0" />
    <app-button label="Cancel" styleClass="secondary expand" (click)="goBack()" />
  </div>
}