import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-warehouses/my-warehouses.page').then(m => m.MyWarehousesPage),
    data: {
      description: 'Portal exclusivo para clientes de Banco Santander que reúne en un mismo lugar beneficios únicos en servicios para el hogar.',
      title: 'Mis bodedas',
    },
  },
  {
    path: ':orderId',
    loadComponent: () => import('./warehouse-status/warehouse-status.page').then(m => m.WarehouseStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('../../../components/warehouse-purchase-details/warehouse-purchase-details.component').then(m => m.WarehousePurchaseDetailsComponent)
  }
] satisfies Routes;
