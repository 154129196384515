<div class="my-warehouses-container">
  <div class="go-back-container" routerLink="/private-site">
    <i class="icon icon-arrow-left"></i>
    <h3 class="text">Mis bodegas
      @if (warehouseOrders.length) {
        <span class="orders-quantity">({{ warehouseOrders.length }})</span>
      }
    </h3>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    <div class="orders-container animate__fadeIn">
      @for (order of warehouseOrders; track order.id) {
        <div class="order-container">
          <div class="left-column">
            <div class="first-column">
              <h4 class="status {{ getOrderFinalStatus(order) }}">
                @switch (getOrderFinalStatus(order)) {
                  @case ('about-to-expire') {
                    Próximo a vencer
                  } @case ('done') {
                    Activo
                  } @case ('finalized') {
                    Finalizado
                  } @case ('pending-payment') {
                    Pendiente de pago
                  } @case ('voided') {
                    Anulado
                  } @case ('waiting-confirmation') {
                    Pendiente a confirmación
                  }
                }  
              </h4>
              <p class="total-volume">{{ 'Warehouse X㎥' | transloco: { volume: order.additionalData['volume']} }}</p>
              @if (order.status !== 'voided') {
                <p class="start-and-end-dates">{{ 'Start: XX - End: YY' | transloco: { end: order.finishAt| date: 'dd/MM/yyyy', start: order.startAt | date: 'dd/MM/yyyy' } }}</p>
              }
            </div>
            <p class="description">
              @switch (getOrderFinalStatus(order)) {
                @case ('about-to-expire') {
                  Tu arriendo de bodega está próximo a vencer. Si quieres mantener tu plan activo, por favor contáctanos.
                } @case ('done') {
                  Tu bodega está activa, úsala cuando lo necesites. Para cualquier duda por favor contáctanos.
                } @case ('finalized') {
                  Tu arriendo de bodega ya finalizó. Podrás contratarlo nuevamente en el botón reactivar.
                } @case ('pending-payment') {
                  Necesitas realizar el pago para reservar tu arriendo de bodega.
                } @case ('voided') {
                  Lamentamos cancelar la renta de tu bodega. Por favor, comentamos como podemos mejorar el servicio.
                } @case ('waiting-confirmation') {
                  Tu bodega está en proceso de ser confirmada. Revisaremos que el pago se haya realizado y otros procesos.
                }
              } 
            </p>
            <div class="last-column">
              <div class="action-button-container">
                @if (['done', 'about-to-expire'].includes(getOrderFinalStatus(order))) {
                  <app-button label="Ir al chat" styleClass="tertiary" [href]="order.additionalData['chatUrl']" target="_blank" rel="noopener" />
                } @else {
                  @switch (getOrderFinalStatus(order)) {
                    @case ('pending-payment') {
                      <app-button label="Ir a reservar la bodega" styleClass="tertiary" (action)="goToReserveWarehouse(order)" />
                    } @case ('finalized') {
                      <!-- TODO: Uncomment this line when the action is available -->
                      <!--<app-button label="Renovar arriendo" styleClass="tertiary" />-->
                    }
                  }
                }
              </div>
              <app-button label="Ver bodega" styleClass="secondary" href="/private-site/my-warehouses/{{ order.id }}" />
            </div>
          </div>
          <div class="right-column">
            <i class="icon icon-arrow-right" routerLink="/private-site/my-warehouses/{{ order.id }}"></i>
          </div>
        </div>
      } @empty {
        <div class="empty-warehouses">
          <img class="image" src="/assets/illustrations/warehouseman-carrying-boxes-to-warehouse.svg" [alt]="'You don\'t have warehouses illustration' | transloco" />
          <h5 class="title">Aún no tienes bodegas programadas/cotizadas</h5>
          <p class="subtitle">Cotiza tu próxima bodega</p>
          <div class="action-button-container">
            <app-button label="Ir a bodegas" styleClass="secondary" href="/warehouse" />
          </div>
        </div>
      }
    </div>
  </app-retryable-section>
</div>