import { EcommerceOrderItem, SearchEngineProduct } from '@homein-hogar-server';
import { CheckoutItem } from '../services/analytics/analytics.service';
import { ShoppingCartItemProduct } from '../services/shopping-carts/shopping-carts.service';

export function mapShoppingCartItemProductsToCheckoutItems(shoppingCartItems: ShoppingCartItemProduct[]): CheckoutItem[] {
  return shoppingCartItems.map(mapShoppingCartItemProductToCheckoutItem);
}

export function mapShoppingCartItemProductToCheckoutItem(shoppingCartItem: ShoppingCartItemProduct): CheckoutItem {
  if (!shoppingCartItem.resource) {
    throw new Error('Shopping cart item product resource is required');
  }
  if (shoppingCartItem.resourceType === 'product') {
    return mapSearchEngineProductToCheckoutItem(shoppingCartItem.resource);
  }
  return {
    discount: 0,
    item_brand: '',
    item_category: '',
    item_id: shoppingCartItem.resourceId,
    item_name: '',
    price: 0,
    quantity: shoppingCartItem.quantity,
  };
}

export function mapSearchEngineProductToCheckoutItem(searchEngineProduct: SearchEngineProduct): CheckoutItem {
  return {
    discount: searchEngineProduct.discountAmount,
    item_brand: searchEngineProduct.brand,
    item_category: searchEngineProduct.categories[0],
    ...(searchEngineProduct.categories[1] && { item_category2: searchEngineProduct.categories[1] }),
    ...(searchEngineProduct.categories[2] && { item_category3: searchEngineProduct.categories[2] }),
    ...(searchEngineProduct.categories[3] && { item_category4: searchEngineProduct.categories[3] }),
    ...(searchEngineProduct.categories[4] && { item_category5: searchEngineProduct.categories[4] }),
    item_id: searchEngineProduct.id,
    item_name: searchEngineProduct.name,
    price: searchEngineProduct.price,
    quantity: 1,
  };
}

export function mapEcommerceOrderItemsToCheckoutItems(ecommerceOrderItems: EcommerceOrderItem[]): CheckoutItem[] {
  return ecommerceOrderItems.map(mapEcommerceOrderItemToCheckoutItem);
}

export function mapEcommerceOrderItemToCheckoutItem(ecommerceOrderItem: EcommerceOrderItem): CheckoutItem {
  return {
    discount: ecommerceOrderItem.discountAmount,
    item_brand: ecommerceOrderItem.brand,
    item_category: ecommerceOrderItem.categories[0],
    ...(ecommerceOrderItem.categories[1] && { item_category2: ecommerceOrderItem.categories[1] }),
    ...(ecommerceOrderItem.categories[2] && { item_category3: ecommerceOrderItem.categories[2] }),
    ...(ecommerceOrderItem.categories[3] && { item_category4: ecommerceOrderItem.categories[3] }),
    ...(ecommerceOrderItem.categories[4] && { item_category5: ecommerceOrderItem.categories[4] }),
    item_id: ecommerceOrderItem.id,
    item_name: ecommerceOrderItem.name,
    price: ecommerceOrderItem.price,
    quantity: 1,
  };
}