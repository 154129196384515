import { HomeAssistanceOrder } from '@homein-hogar-server';
import { constants } from '../../constants';
import { USERS_MOCK } from '../users/users.mock';
import { HOME_ASSISTANCE_PLANS_MOCK } from '../home-assistance-plans/home-assistance-plans.mock';

export const HOME_ASSISTANCE_ORDER_MOCK: HomeAssistanceOrder[] = [
  {
    additionalData: {
      idTransaction: 1,
    },
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    discounts: 0,
    duration: '6-months',
    finishAt: new Date(Date.now() + (177 * 24 * 60 * 60 * 1000)),
    id: '4ttvNq71Ul8lwK7Xi2Oa',
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    plan: HOME_ASSISTANCE_PLANS_MOCK[0],
    startAt: new Date(Date.now() - (3 * 24 * 60 * 60 * 1000)),
    status: 'active',
    subtotal: 1000,
    tax: 1000,
    totalAmount: 2000,
    transactionId: 'E12zOCMd3fv77E8RQT4s',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      idTransaction: 2,
    },
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    discounts: 0,
    duration: '6-months',
    finishAt: new Date(Date.now() + (180 * 24 * 60 * 60 * 1000)),
    id: 'z72MhFejjgdUbVcONyrM',
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    plan: HOME_ASSISTANCE_PLANS_MOCK[0],
    startAt: new Date(),
    status: 'active',
    subtotal: 1000,
    tax: 1000,
    totalAmount: 2000,
    transactionId: 'QWDqwCD4fyzGkbaJSiWr',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      idTransaction: 2,
    },
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    discounts: 0,
    duration: '1-month',
    finishAt: new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)),
    id: 'bTbxBen3OxEXncIbNWFf',
    paymentIntentId: 'nmfYcRBovOiji1VIRabw',
    plan: HOME_ASSISTANCE_PLANS_MOCK[1],
    startAt: new Date(Date.now() - (60 * 24 * 60 * 60 * 1000)),
    status: 'finished',
    subtotal: 1000,
    tax: 1000,
    totalAmount: 2000,
    transactionId: 'RGaeQs3uJmD5MBbxhzej',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      idTransaction: 3,
    },
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    discounts: 0,
    duration: '12-months',
    finishAt: new Date(Date.now() + (365 * 24 * 60 * 60 * 1000)),
    id: '8Dz0Y4oel39r3uaJ06Qy',
    paymentIntentId: '0M3mZOnbsfrgYGafqiQp',
    plan: HOME_ASSISTANCE_PLANS_MOCK[2],
    startAt: new Date(),
    status: 'canceled',
    subtotal: 1000,
    tax: 1000,
    totalAmount: 2000,
    transactionId: '04bZbfSa5YYGkeQ6z6l7',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: {
      idTransaction: 1,
    },
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    discounts: 0,
    duration: '6-months',
    finishAt: new Date(Date.now() + (177 * 24 * 60 * 60 * 1000)),
    id: 'exhnRTzlj7uWi3S0RTKf',
    paymentIntentId: 'AHGf5jJuqUes4EqCOWKb',
    plan: HOME_ASSISTANCE_PLANS_MOCK[0],
    startAt: new Date(Date.now() - (3 * 24 * 60 * 60 * 1000)),
    status: 'active',
    subtotal: 1000,
    tax: 1000,
    totalAmount: 2000,
    transactionId: 'E12zOCMd3fv77E8RQT4s',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
];