import { Injectable } from '@angular/core';
import { GetEstimatedDeliveryData, SearchEngineProduct } from '@homein-hogar-server';
import { triggerRandomError } from '../../utils/mock.utils';
import { Paginable, SearchService } from '../search/search.service';
import {
  BASIC_FILTER,
  PRODUCT_SEARCH_DEFAULT_FIELDS,
  PRODUCT_SEARCH_DEFAULT_WEIGHTS,
  PRODUCTS_PER_PAGE_DEFAULT,
  ProductSearchConfig,
  ProductsService,
} from './products.service';

@Injectable()
export class ProductsServiceMock implements ProductsService {
  constructor(
    private searchService: SearchService,
  ) {}

  get(id: string): Promise<SearchEngineProduct | null> {
    return this.searchService.get<SearchEngineProduct>('products', id);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getEstimatedDelivery(data: GetEstimatedDeliveryData): Promise<{ from: Date; to: Date; }> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        const currentDate = new Date();
        const from = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        const to = new Date(from.getTime() + 7 * 24 * 60 * 60 * 1000);
        resolve({
          from,
          to,
        });
      }, 2000);
    });
  }

  search(query: string | null, config?: ProductSearchConfig): Promise<Paginable<SearchEngineProduct>> {
    return this.searchService.search<SearchEngineProduct>('products', {
      filters: config?.filters ? [...BASIC_FILTER, ...config.filters]: BASIC_FILTER,
      query: {
        value: query,
        fields: PRODUCT_SEARCH_DEFAULT_FIELDS,
        weights: PRODUCT_SEARCH_DEFAULT_WEIGHTS,
      },
      order: config?.order,
      pagination: {
        documentsPerPage: config?.pagination?.documentsPerPage ?? PRODUCTS_PER_PAGE_DEFAULT,
        page: config?.pagination?.page ?? 1,
      },
    });
  }
}
