import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MovingOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { firstValueFrom, Subject } from 'rxjs';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { MovingOrdersService } from '../../services/moving-orders/moving-orders.service';
import { PlatformService } from '../../services/platform/platform.service';
import { SeoService } from '../../services/seo/seo.service';
import { PurchasePaymentDetailsComponent } from '../purchase-payment-details/purchase-payment-details.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-moving-purchase-details',
  standalone: true,
  imports: [
    AccordionModule,
    CommonModule,
    CurrencyFormatterPipe,
    PurchasePaymentDetailsComponent,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './moving-purchase-details.component.html',
  styleUrl: './moving-purchase-details.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MovingPurchaseDetailsComponent implements OnInit, OnDestroy {
  @Input() order: MovingOrder;
  @Input() showBackButton = true;
  endAddress: {
    firstLine: string;
    secondLine: string;
  };
  errorLoading = false;
  loading = true;
  startAddress: {
    firstLine: string;
    secondLine: string;
  };
  protected orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private movingOrdersService: MovingOrdersService,
    private platformService: PlatformService,
    private router: Router,
    private seoService: SeoService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyMovings();
      return;
    }
    this.orderId = orderId;
    if (!this.order) {
      this.seoService.setMetaTags({
        title: `Detalle de compra #${this.orderId}`,
        description: 'Detalle de compra de una mudanza en Mundo Hogar.',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    let step = '';
    try {
      if (!this.order) {
        step = 'get-moving-order';
        const movingOrder = await firstValueFrom(this.movingOrdersService.get(this.orderId));
        if (!movingOrder) {
          throw new Error('Moving order not found');
        }
        this.order = movingOrder;
      }
      this.startAddress = {
        firstLine: this.order.startAddress.split(',')[0].trim(),
        secondLine: this.order.startAddress.split(',').slice(1).join(',').trim()
      };
      this.endAddress = {
        firstLine: this.order.endAddress.split(',')[0].trim(),
        secondLine: this.order.endAddress.split(',').slice(1).join(',').trim()
      };
    } catch (error) {
      this.errorReportingService.log('MovingPurchaseDetailsComponent.initialize()', step, error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  private goToMyMovings(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-movings']);
  }
}