import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { RemodelingOrder } from '@homein-hogar-server';
import { AccordionModule } from 'primeng/accordion';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RatingComponent } from '../../../../components/rating/rating.component';
import { RemodelingPurchaseDetailsComponent } from '../../../../components/remodeling-purchase-details/remodeling-purchase-details.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { constants } from '../../../../constants';
import { CurrencyFormatterPipe } from '../../../../pipes/currency-formatter.pipe';
import { ChargeIntentsService } from '../../../../services/charge-intents/charge-intents.service';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { RemodelingOrdersService } from '../../../../services/remodeling-orders/remodeling-orders.service';
import { SeoService } from '../../../../services/seo/seo.service';

@Component({
  selector: 'app-remodeling-status',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CommonModule,
    CurrencyFormatterPipe,
    RatingComponent,
    RemodelingPurchaseDetailsComponent,
    RetryableSectionComponent,
    RouterModule,
  ],
  templateUrl: './remodeling-status.page.html',
  styleUrl: './remodeling-status.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class RemodelingStatusPage implements OnInit, OnDestroy {
  chargeIntentDetail: {
    amount: number;
    currency: string;
    tax: number;
    total: number;
  } | null = null;
  errorLoading = false;
  loading = true;
  order: RemodelingOrder;
  showRemodelingPurchaseDetail = false;
  private orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private chargeIntentsService: ChargeIntentsService,
    private errorReportingService: ErrorReportingService,
    private remodelingOrdersService: RemodelingOrdersService,
    private router: Router,
    private seoService: SeoService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyRemodelings();
      return;
    }
    this.orderId = orderId;
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.remodelingOrdersService.get(this.orderId).pipe(takeUntil(this.viewDestroyed)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async (order) => {
        if (!order) {
          this.goToMyRemodelings();
          return;
        }
        this.seoService.setMetaTags({
          description: 'Consulta el estado de tu remodelación en Mundo Hogar.',
          title: `Estado de remodelación: Proyecto ${order.additionalData['service']}`,
        });
        this.order = order;
        const chargeIntent = this.order.pendingChargeIntentId ? await this.chargeIntentsService.get(this.order.pendingChargeIntentId) : null;
        if (chargeIntent) {
          const totalAmount = chargeIntent.items.reduce((acc, item) => acc + (item.quantity * item.unitPrice), 0);
          this.chargeIntentDetail = {
            amount: totalAmount / (1 + constants.tax),
            currency: chargeIntent.items[0].currency,
            tax: totalAmount - (totalAmount / (1 + constants.tax)),
            total: totalAmount,
          };
        }
        this.setShowRemodelingPurchaseDetail();
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('RemodelingStatusPage.initialize()', 'get-order', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  private goToMyRemodelings(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-remodelings']);
  }

  private setShowRemodelingPurchaseDetail(): void {
    this.showRemodelingPurchaseDetail = (this.order.status !== 'lead-created' && (this.order.status !== 'pending' || this.order.paid));
  }
}