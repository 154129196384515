import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable } from 'rxjs';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';

const MAX_SEARCH_HISTORY_ITEMS = 5;

@Injectable({
  providedIn: 'root',
})
export class SearchHistoryService {
  private searchHistory: string[];

  constructor(
    private dataStorageService: DataStorageService,
  ) {
    this.getAll().subscribe((searchHistory) => this.searchHistory = searchHistory);
  }

  create(query: string): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return new Promise(async (resolve) => {
      this.searchHistory = await firstValueFrom(this.getAll());
      this.searchHistory = this.searchHistory.filter((entry) => entry !== query);
      if (this.searchHistory.length === MAX_SEARCH_HISTORY_ITEMS) {
        this.searchHistory.pop();
      }
      this.searchHistory.unshift(query);
      this.dataStorageService.set(DataKey.SearchHistory, this.searchHistory);
      resolve(query);
    });
  }

  getAll(): Observable<string[]> {
    return this.dataStorageService.get<string[]>(DataKey.SearchHistory).pipe(map((searchHistory) => searchHistory ?? []));
  }

  removeAll(): Promise<void> {
    return new Promise((resolve) => {
      this.dataStorageService.set(DataKey.SearchHistory, []);
      resolve();
    });
  }
}
