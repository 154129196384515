import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FavoriteItem, SearchEngineProduct } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { TranslocoPercentPipe } from '@ngneat/transloco-locale';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { ProductCardComponent } from '../../../components/product-card/product-card.component';
import { ProductsCarouselComponent } from '../../../components/products-carousel/products-carousel.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { CONFIG } from '../../../constants';
import { CustomError } from '../../../models/custom-error.model';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { ProductsService } from '../../../services/products/products.service';
import { ShoppingCartsService } from '../../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../../services/toast/toast.service';
import { TrackingService } from '../../../services/tracking/tracking.service';
import { isActive } from '../../../utils/forms.utils';
import { mapSearchEngineProductToCheckoutItem } from '../../../utils/analytics.utils';

@Component({
  selector: 'app-product-detail',
  standalone: true,
  imports: [
    BreadcrumbModule,
    ButtonComponent,
    CarouselModule,
    CurrencyFormatterPipe,
    FormsModule,
    GalleriaModule,
    InputTextModule,
    OverlayPanelModule,
    ProductCardComponent,
    ProductsCarouselComponent,
    ReactiveFormsModule,
    RetryableSectionComponent,
    TranslocoPercentPipe,
    TranslocoPipe,
  ],
  templateUrl: './product-detail.page.html',
  styleUrl: './product-detail.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ProductDetailPage implements OnInit, OnDestroy {
  @ViewChild('overlayPanel') overlayPanel: OverlayPanel;
  addingProductToShoppingCart = false;
  breadcrumbs: MenuItem[] | undefined;
  categories: string[] = [];
  currentPhotoIndex = 0;
  errorLoadingProduct = false;
  favorites: Record<string, FavoriteItem> = {};
  form: FormGroup<{
    postalCode: FormControl<string | null>;
  }>;
  home: MenuItem | undefined;
  installmentOptions: {
    discount: number;
    monthlyPayment: number;
    term: number;
    total: number;
  }[] = [];
  isCollapsed = true;
  isFavorite = false;
  loadingProduct = true;
  loadingShippingDates = false;
  offerThreshold = CONFIG.pricing.ecommerce.sale;
  product: SearchEngineProduct;
  productId: string;
  shippingDates: {
    from: Date;
    to: Date;
  } | null = null;
  showGallery = false;
  togglingFavorite = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
    private trackingService: TrackingService,
    private location: Location,
  ) {
    this.form = new FormGroup({
      postalCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(CONFIG.postalCodeLength), Validators.maxLength(CONFIG.postalCodeLength), Validators.pattern(/^\d+$/)]),
    });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.form.controls.postalCode.setValue(this.dataStorageService.getLocal(DataKey.PostalCode));
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
    this.home = { icon: 'icon-house', routerLink: '/ecommerce' };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  async addToShoppingCart(): Promise<void> {
    if (this.addingProductToShoppingCart) {
      return;
    }
    let step = '';
    this.addingProductToShoppingCart = true;
    try {
      if (!this.product) {
        return;
      }
      step = 'add-product-to-shopping-cart';
      await this.shoppingCartsService.addItem({
        quantity: 1,
        resourceId: this.product.id,
        resourceType: 'product',
      });
      this.analyticsService.logAddToShoppingCartEvent({
        currency: this.product.currency,
        items: [mapSearchEngineProductToCheckoutItem(this.product)],
        value: this.product.price,
      });
      this.toastService.showSuccess({
        title: 'Added a product to shopping cart',
        description: {
          key: '"X" has been added to your shopping cart.',
          params: { name: this.product.name }
        },
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('/ecommerce/shopping-cart');
        },
        actionLabel: 'View shopping cart',
      }, 3000);
    } catch (error) {
      this.errorReportingService.log('ProductDetailPage.addToShoppingCart()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
    } finally {
      this.addingProductToShoppingCart = false;
    }
  }

  async getProduct(): Promise<void> {
    try {
      this.loadingProduct = true;
      this.errorLoadingProduct = false;
      const product = await this.productsService.get(this.productId);
      if (!product) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigateByUrl('/ecommerce');
        return;
      }
      this.product = product;
      this.categories = product.categories;
      this.breadcrumbs = this.categories.map((category) => ({
        label: CONFIG.ecommerce.categories.labels[category],
        routerLink: '/ecommerce/search',
        queryParams: { filters: JSON.stringify({ categories: { value: [category], operator: 'in', field: 'categories' } }) },
      }));
      const options = CONFIG.paymentOptions.filter((option) => !['debit', 'credit'].includes(option));
      this.installmentOptions = options.map((option) => ({
        discount: product.pricing[option].discountPercentage,
        term: product.pricing[option].term,
        monthlyPayment: product.pricing[option].monthlyPayment,
        total: product.pricing[option].price,
      }));
      this.analyticsService.logViewItemEvent({
        currency: this.product.currency,
        items: [mapSearchEngineProductToCheckoutItem(this.product)],
        value: this.product.price,
      });
      this.trackingService.track({ brand: product.brand, categories: product.categories, id: product.id });
      combineLatest([
        this.favoritesService.hasFavorite('product', product.id),
        this.favoritesService.get()
      ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: ([isFavorite, favorite]) => {
          this.isFavorite = isFavorite;
          this.favorites = {};
          if (favorite) {
            favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
          }
        },
        error: (error) => {
          this.errorReportingService.log('ProductDetailPage.initialize()', 'get-all-favorites', error);
        },
      });
      if (this.product.status === 'active') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.setShippingDates();
      }
    } catch (error) {
      this.errorReportingService.log('ProductDetailPage.getProduct()', 'get-product', error);
      this.errorLoadingProduct = true;
    } finally {
      this.loadingProduct = false;
    }
  }

  goBack(): void {
    this.location.back();
  }

  initialize(): void {
    this.route.paramMap.pipe(takeUntil(this.viewDestroyed)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: async (params) => {
        const slug = params.get('slug');
        if (!slug) {
          await this.router.navigateByUrl('/ecommerce');
          return;
        }
        const slugParts = slug.split('-');
        this.productId = slugParts[slugParts.length - 1];
        await this.getProduct();
      }
    });
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  onEnterAnotherZipCode(): void {
    this.shippingDates = null;
    this.form.controls.postalCode.reset();
    this.form.controls.postalCode.enable();
  }

  async setShippingDates(): Promise<void> {
    if (this.form.invalid || this.loadingShippingDates) {
      return;
    }
    this.loadingShippingDates = true;
    this.form.controls.postalCode.disable();
    try {
      this.dataStorageService.set(DataKey.PostalCode, this.form.controls.postalCode.value!);
      this.shippingDates = await this.productsService.getEstimatedDelivery({ partnerProductId: this.product.partnerProductId, postalCode: this.form.controls.postalCode.value! });
    } catch (error) {
      const { data: { cause } } = error as { data: { cause: string; } };
      if (cause === 'invalid-postal-code') {
        this.toastService.showError({
          title: 'Invalid postal code',
          description: 'The postal code must be valid.',
        });
      } else {
        this.errorReportingService.log('ProductDetailPage.setShippingDates()', 'get-estimated-delivery', error);
        this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
      }
      this.form.controls.postalCode.enable();
    } finally {
      this.loadingShippingDates = false;
    }
  }

  toggleDescription(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  async toggleFavorites(): Promise<void> {
    if (this.togglingFavorite) {
      return;
    }
    let step = '';
    this.togglingFavorite = true;
    try {
      if (!this.product) {
        return;
      }
      if (this.isFavorite) {
        step = 'remove-from-favorites';
        await this.favoritesService.removeItem({
          resourceId: this.product.id,
          resourceType: 'product',
        });
        this.analyticsService.logRemoveFromShoppingCartEvent({
          currency: this.product.currency,
          items: [mapSearchEngineProductToCheckoutItem(this.product)],
          value: this.product.price,
        });
        this.toastService.showSuccess({
          title: 'Removed a favorite',
          description: {
            key: '"X" has been removed from your favorites list.',
            params: { name: this.product.name }
          },
          action: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigateByUrl('/ecommerce/my-favorites');
          },
          actionLabel: 'View Favorites',
        }, 3000);
        return;
      }
      step = 'add-to-favorites';
      await this.favoritesService.addItem({
        resourceId: this.product.id,
        resourceType: 'product',
      });
      this.analyticsService.logAddToFavoritesEvent({
        currency: this.product.currency,
        items: [mapSearchEngineProductToCheckoutItem(this.product)],
        value: this.product.price,
      });
      this.toastService.showSuccess({
        title: 'Added a favorite',
        description: {
          key: '"X" has been added to your favorites list.',
          params: { name: this.product.name }
        },
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('/ecommerce/my-favorites');
        },
        actionLabel: 'View Favorites',
      }, 3000);
    } catch (error) {
      if (error instanceof CustomError && error.code === 'max-favorites-reached') {
        this.toastService.showWarning({
          title: 'You reached the limit',
          description: 'You reached the limit of favorites, you can remove some to add new ones.',
          action: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigateByUrl('/ecommerce/my-favorites');
          },
          actionLabel: 'Manage Favorites',
        });
        return;
      }
      this.errorReportingService.log('ProductDetailPage.toggleFavorites()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
    } finally {
      this.togglingFavorite = false;
    }
  }

  toggleShowGallery(index?: number): void {
    if (typeof index === 'number') {
      this.currentPhotoIndex = index;
    }
    this.showGallery = !this.showGallery;
  }
}