import { CommonModule, Location } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { BadgeModule } from 'primeng/badge';
import { ImageModule } from 'primeng/image';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject, takeUntil } from 'rxjs';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../services/favorites/favorites.service';
import { PlatformService } from '../../services/platform/platform.service';
import { SearchHistoryService } from '../../services/search-history/search-history.service';
import { SearchFilter } from '../../services/search/search.service';
import { SessionsService } from '../../services/sessions/sessions.service';
import { ShoppingCartDetail, ShoppingCartsService } from '../../services/shopping-carts/shopping-carts.service';
import { UsersService } from '../../services/users/users.service';
import { areEqualArrays } from '../../utils/array.utils';
import { areEqualObjects } from '../../utils/object.utils';
import { ButtonComponent } from '../button/button.component';
import { FilterSidebarComponent, FiltersSideBar } from '../filter-sidebar/filter-sidebar.component';
import { MenuSidebarComponent } from '../menu-sidebar/menu-sidebar.component';
import { ProductCardComponent } from '../product-card/product-card.component';
import { SearchInputComponent } from '../search-input/search-input.component';
import { SearchSidebarComponent } from '../search-sidebar/search-sidebar.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    BadgeModule,
    ButtonComponent,
    CommonModule,
    FilterSidebarComponent,
    ImageModule,
    MenuSidebarComponent,
    OverlayPanelModule,
    ProductCardComponent,
    RouterModule,
    SearchInputComponent,
    SearchSidebarComponent,
    TranslocoPipe,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
  @ViewChild('overlayPanel') overlayPanel: OverlayPanel;
  @ViewChild('filterSidebar') filterSidebar: FilterSidebarComponent;
  @ViewChild('searchSidebar') searchSidebar: SearchSidebarComponent;
  @Input() filters: SearchFilter[] | null;
  @Input() order: { direction: 'asc' | 'desc'; field: string; } | null;
  @Input() query: string | null = null;
  @Input() showBackButton = false;
  @Input() showSearchIcon = false;
  activatedFilters: FiltersSideBar;
  firstLoadFinished = false;
  hasActiveFilters = false;
  hasFavorites = false;
  isEcommerceSearch = false;
  isEcommerce = false;
  isEnrollment = false;
  isHome = true;
  isPrivateSite = false;
  isScrolled = false;
  menuSidebarVisible = false;
  page: number;
  searchHistory: string[] | null = null;
  shoppingCartDetail: ShoppingCartDetail | null = null;
  user: User | null = null;
  userNameInitials = '';
  private isMobile = false;
  private prevScrollPos = 0;
  private isTablet = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private elementRef: ElementRef,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private location: Location,
    private platformService: PlatformService,
    private renderer: Renderer2,
    private router: Router,
    private searchHistoryService: SearchHistoryService,
    private sessionsService: SessionsService,
    private shoppingCartsService: ShoppingCartsService,
    private usersService: UsersService,
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome = event.url === '/';
        this.isEcommerce =  event.url.startsWith('/ecommerce');
        this.isEcommerceSearch = event.url.startsWith('/ecommerce/search');
        this.isEnrollment = event.url.startsWith('/enrollment');
        this.isPrivateSite = event.url.startsWith('/private-site');
      }
    });
    if (this.platformService.isServer()) {
      return;
    }
    this.initialize();
    if (this.platformService.isBrowser()) {
      this.isTablet = window.innerWidth >= 1000 && window.innerWidth < 1366;
      this.isMobile = window.innerWidth < 1000;
      this.prevScrollPos = window.scrollY;
    }
    this.activatedRoute.queryParams.pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (params) => {
        const query = params['query'] ?? null;
        const filters = params['filters'] ? Object.keys(JSON.parse(params['filters'])).map((key) => JSON.parse(params['filters'])[key] as SearchFilter) : null;
        const order = params['order'] ? JSON.parse(params['order']) : null;
        this.page = params['page'] ? +params['page'] : 1;
        if (this.query !== query || !areEqualArrays(this.filters, filters) || !areEqualObjects(this.order, order)) {
          if (this.query !== query) {
            this.query = query;
          }
          if (!areEqualArrays(this.filters, filters)) {
            this.filters = filters;
          }
          if (!areEqualObjects(this.order, order)) {
            this.order = order;
          }
        }
      }
    });
  }

  async changeQuery(query: string | null): Promise<void> {
    this.query = query;
    if (!this.query) {
      this.hasActiveFilters = false;
      this.filterSidebar.deleteFilters();
      await this.router.navigateByUrl('/ecommerce/search');
    }
  }

  closedMenuSidebar(): void {
    this.menuSidebarVisible = false;
  }

  async deleteSearchHistory(): Promise<void> {
    await this.searchHistoryService.removeAll();
  }

  goTo(route: string): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate([route]);
    this.overlayPanel.hide();
  }

  async filter(filters: FiltersSideBar): Promise<void> {
    const encodedFilters = this.getEncodedFilters(filters);
    const encodedOrder = this.getEncodedOrder(filters);
    this.hasActiveFilters = !!encodedFilters || !!encodedOrder;
    await this.router.navigate(['/ecommerce/search'],
      {
        queryParams: {
          ...(this.query && { query: this.query }),
          ...(encodedFilters && { filters: encodedFilters }),
          ...(encodedOrder && { order: encodedOrder }),
        }
      });
  }

  goBack(): void {
    this.location.back();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.platformService.isServer()) {
      return;
    }
    const scrollY = this.platformService.isBrowser() ? window.scrollY : 0;
    const header = this.elementRef.nativeElement.querySelector('.header-navbar');
    const logo = this.elementRef.nativeElement.querySelector('.logo');
    if (this.isHome) {
      if (scrollY > 50 && !this.isScrolled) {
        this.renderer.addClass(header, 'scrolled');
        this.renderer.removeClass(logo, 'large');
        this.renderer.addClass(logo, 'small');
        this.isScrolled = true;
      } else if (scrollY <= 50 && this.isScrolled) {
        this.renderer.removeClass(header, 'scrolled');
        this.renderer.removeClass(logo, 'small');
        this.renderer.addClass(logo, 'large');
        this.isScrolled = false;
      }
      return;
    }
    if (scrollY > 0 && !this.isScrolled) {
      this.renderer.addClass(header, 'scrolled');
      this.renderer.removeClass(logo, 'large');
      this.renderer.addClass(logo, 'small');
      this.isScrolled = true;
    } else if (scrollY === 0 && this.isScrolled) {
      this.renderer.removeClass(header, 'scrolled');
      this.isScrolled = false;
    } else if (scrollY === 0 && !this.isScrolled) {
      this.renderer.addClass(logo, 'small');
    }
    const mainContainer = this.elementRef.nativeElement.querySelector('.main-container');
    this.showSearchIcon = scrollY >= 134;
    if (scrollY >= 1 ) {
      this.renderer.setStyle(mainContainer, 'box-shadow', 'var(--light-shadow-08)');
    } else {
      this.renderer.setStyle(mainContainer, 'box-shadow', 'none');
    }
    if (scrollY >= 134 && this.isTablet && !this.isEcommerceSearch) {
      this.renderer.setStyle(mainContainer, 'top', '99px');
      this.renderer.setStyle(mainContainer, 'position', 'sticky');
    }
    if (scrollY >= 134 && this.isMobile && !this.isEcommerceSearch) {
      this.renderer.setStyle(mainContainer, 'top', '71px');
      this.renderer.setStyle(mainContainer, 'position', 'sticky');
    }
    if (this.isEcommerceSearch) {
      if (scrollY < 100) {
        this.renderer.addClass(mainContainer, 'sticky');
        this.renderer.setStyle(this.elementRef.nativeElement.querySelector('.ecommerce-bar-spacer'), 'height', '0px');
      }
    } else if (this.prevScrollPos > scrollY) {
      this.renderer.setStyle(mainContainer, 'transform', 'translateY(0)');
    } else if (this.prevScrollPos < scrollY && scrollY > 110) {
      this.renderer.setStyle(mainContainer, 'transform', 'translateY(-100%)');
    }
    this.prevScrollPos = scrollY;
  }

  openFilters(): void {
    this.filterSidebar.open();
  }

  openSearch(): void {
    this.searchSidebar.open();
  }

  async search(query: string): Promise<void> {
    if (this.searchSidebar.isOpen()) {
      this.searchSidebar.close();
    }
    this.query = query;
    this.analyticsService.logSearchEvent({ search_term: this.query });
    this.hasActiveFilters = false;
    await this.searchHistoryService.create(query);
    await this.router.navigateByUrl(`/ecommerce/search?query=${this.query}`);
  }

  showMenuSidebar(): void {
    this.menuSidebarVisible = true;
  }

  signOut(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.sessionsService.signOut();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/']);
    this.overlayPanel.hide();
  }

  private decodeFilters(): void {
    this.activatedFilters = {
      categories: this.filters?.find((filter) => filter.field === 'categories')?.value as string[] ?? [],
      discountPercentage: this.filters?.find((filter) => filter.field === 'discount')?.value as number ?? null,
      max: this.filters?.find((filter) => filter.field === 'price' && filter.operator === '<=')?.value as number ?? null,
      min: this.filters?.find((filter) => filter.field === 'price' && filter.operator === '>=')?.value as number ?? null,
      rating: this.filters?.find((filter) => filter.field === 'rating')?.value as number ?? null,
      sortBy: this.order,
    };
    const { categories, discountPercentage, max, min, rating, sortBy } = this.activatedFilters;
    this.hasActiveFilters = (!!categories && !!categories.length) || !!discountPercentage || !!max || !!min || !!rating || !!sortBy;
  }

  private getEncodedFilters(filters: FiltersSideBar): string | null {
    const filtersToApply = {
      ...(filters.categories?.length && { categories: { field: 'categories', operator: 'in', value: filters.categories } }),
      ...(filters.discountPercentage && { discount: { field: 'discountPercentage', operator: '>=', value: filters.discountPercentage } }),
      ...(filters.max && { max: { field: 'price', operator: '<=', value: filters.max } }),
      ...(filters.min && { min: { field: 'price', operator: '>=', value: filters.min } }),
      ...(filters.rating && { min: { field: 'rating', operator: '>=', value: filters.rating } }),
    };
    return Object.keys(filtersToApply).length ? JSON.stringify(filtersToApply) : null;
  }

  private getEncodedOrder(filters: FiltersSideBar): string | null {
    if (filters.sortBy?.field !== 'default') {
      return JSON.stringify(filters.sortBy);
    }
    return null;
  }

  private initialize(): void {
    this.decodeFilters();
    this.usersService.getCurrentUser().subscribe({
      next: (user) => {
        this.user = user;
        this.setUserNameInitials();
        this.firstLoadFinished = true;
      },
      error: (error) => {
        this.errorReportingService.log('NavbarComponent.initialize()', 'get-current-user', error);
        this.firstLoadFinished = true;
      }
    });
    this.favoritesService.hasFavoriteItems().subscribe({
      next: (hasFavorites) => this.hasFavorites = hasFavorites,
      error: (error) => this.errorReportingService.log('NavbarComponent.initialize()', 'has-favorite-items', error),
    });
    this.searchHistoryService.getAll().subscribe({
      next: (searchHistory) => this.searchHistory = searchHistory,
      error: (error) => {
        this.errorReportingService.log('NavbarComponent.initialize()', 'get-search-history', error);
        this.searchHistory = null;
      },
    });
    this.shoppingCartsService.get().subscribe({
      next: (shoppingCart) => this.shoppingCartDetail = shoppingCart,
      error: (error) => {
        this.errorReportingService.log('NavbarComponent.initialize()', 'get-shopping-cart', error);
        this.shoppingCartDetail = null;
      }
    });
  }

  private setUserNameInitials(): void {
    this.userNameInitials = `${this.user?.name?.charAt(0).toLocaleUpperCase() ?? ''}${this.user?.fatherLastName?.charAt(0).toLocaleUpperCase() ?? ''}`;
  }
}
