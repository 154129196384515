// TODO: Use getTRPCClientOptions from @homein/utils and delete this utils
import { httpBatchLink } from '@trpc/client';
import superjson from 'superjson';
import { constants } from '../constants';

export function getTRPCClientOptions(url: string, getAuthorizationToken?: () => Promise<string | null>) {
  return {
    transformer: superjson,
    links: [
      httpBatchLink({
        url,
        async headers() {
          const headers: Record<string, string | string[] | undefined> = {};
          if (getAuthorizationToken) {
            const authorizationToken = await getAuthorizationToken();
            if (authorizationToken) {
              headers['Authorization'] = `Bearer ${authorizationToken}`;
            }
          }
          headers['x-mundohogar-app-version'] = `0.0.1-${constants.appVersionCode}`;
          return headers;
        },
        fetch(input, init) {
          return fetch(input, {
            ...init,
            credentials: 'include',
          });
        },
      }),
    ],
  };
}
