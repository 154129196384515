import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

interface MetaTagsConfig {
  description: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  setCanonicalUrl(url: string): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', `${environment.appUrl}/${url}`);
  }

  setMetaTags(config: MetaTagsConfig): void {
    const title = `${config.title} | Mundo Hogar`;
    const description = config.description.substring(0, 160);
    this.title.setTitle(title);
    const tags = {
      description,
      robots: 'index, follow',
      'og:description': description,
      'og:image': 'https://storage.googleapis.com/santandermx-ha-prod-public/misc/og-image-default.jpg',
      'og:locale': 'es_MX',
      'og:title': title,
      'og:type': 'website',
    };
    Object.entries(tags).forEach(([tag, value]) => this.meta.updateTag({ name: tag, content: value }));
  }
}