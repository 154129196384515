import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { TRPCClientError } from '@trpc/client';
import { constants } from '../../constants';
import { emailPattern, namePattern, rfcLegalEntityPattern, rfcNaturalPersonPattern } from '../../constants/validation-patterns.constants';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../services/forms/forms.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';
import { ButtonComponent } from '../button/button.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

interface TaxRegimeOption {
  label: string;
  personTypes: PersonType[];
  value: TaxRegime;
}

type FormValues = {
  cfdiUse: CfdiUse;
  email: string;
  name: string;
  orderId: string;
  postalCode: string;
  rfc: string;
} & ({
  fatherLastName: string;
  motherLastName: string | null;
  personType: 'natural-person';
  taxRegime: 'simplified-trust-regime';
} | {
  companyName: string;
  personType: 'legal-entity';
  taxRegime: TaxRegime;
});

type PersonType = 'natural-person' | 'legal-entity';
type CfdiUse =
  | 'computer-equipment-and-accessories'
  | 'dies-punches-molds-dies-and-tooling'
  | 'office-furniture-and-equipment-for-investments'
  | 'general-expenses'
  | 'goods-acquisition'
  | 'payments'
  | 'returns-discounts-or-bonuses'
  | 'without-tax-effects';
type TaxRegime =
  | 'general-law-legal-entities'
  | 'optional-for-groups-of-companies'
  | 'simplified-trust-regime';

@Component({
  selector: 'app-invoicing-sidebar',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    InputTextModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RetryableSectionComponent,
    RouterLink,
    SidebarModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './invoicing-sidebar.component.html',
  styleUrl: './invoicing-sidebar.component.scss'
})

export class InvoicingSidebarComponent implements OnInit, OnDestroy {
  @Input({ required: true }) orderId: string;
  @Input() invoiceId: string | null;
  protected invoiceCreated = false;
  protected invoiceSent = false;
  protected cfdiUseOptions: { label: string; value: CfdiUse; }[] = [
    { label: 'Adquisición de mercancías', value: 'goods-acquisition' },
    { label: 'Dados, troqueles, moldes, matrices y herramental', value: 'dies-punches-molds-dies-and-tooling' },
    { label: 'Devoluciones, descuentos o bonificaciones', value: 'returns-discounts-or-bonuses' },
    { label: 'Equipo de cómputo y accesorios', value: 'computer-equipment-and-accessories' },
    { label: 'Gastos en general', value: 'general-expenses' },
    { label: 'Mobiliario y equipo de oficina para inversiones', value: 'office-furniture-and-equipment-for-investments' },
    { label: 'Pagos', value: 'payments' },
    { label: 'Sin efectos fiscales', value: 'without-tax-effects' },
  ];
  protected errorLoadingUserData = false;
  protected form: FormGroup<{
    cfdiUse: FormControl<CfdiUse | null>;
    companyName: FormControl<string | null>;
    email: FormControl<string | null>;
    fatherLastName: FormControl<string | null>;
    motherLastName: FormControl<string | null>;
    name: FormControl<string | null>;
    orderId: FormControl<string | null>;
    personType: FormControl<PersonType | null>;
    postalCode: FormControl<string | null>;
    rfc: FormControl<string | null>;
    taxRegime: FormControl<TaxRegime | null>;
  }>;
  protected isMobile = false;
  protected isVisible = false;
  protected loadingUserData = true;
  protected personTypeOptions: { label: string; value: PersonType; }[] = [
    { label: 'Natural person', value: 'natural-person' },
    { label: 'Legal entity', value: 'legal-entity' },
  ];
  protected sendingInvoice = false;
  protected taxRegimeFiltered: TaxRegimeOption[] = [];
  protected taxRegimeOptions: TaxRegimeOption[] = [
    { label: 'General de Ley Personas Morales', personTypes: ['legal-entity'], value: 'general-law-legal-entities' },
    { label: 'Opcional para Grupos de Sociedades', personTypes: ['legal-entity'], value: 'optional-for-groups-of-companies' },
    { label: 'Régimen Simplificado de Confianza', personTypes: ['natural-person', 'legal-entity'], value: 'simplified-trust-regime' },
  ];
  protected user: User;
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {}

  ngOnInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  protected onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }

  close(): void {
    this.isVisible = false;
    this.invoiceSent = false;
    if (this.form) {
      this.form.reset();
    }
  }

  open(): void {
    this.invoiceCreated = !!this.invoiceId;
    this.createForm();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.getUserData();
    this.sendingInvoice = false;
    this.isVisible = true;
  }

  protected async getUserData(): Promise<void> {
    try {
      this.loadingUserData = true;
      this.user = await firstValueFrom(this.usersService.getCurrentUser()) as User;
      if (!this.invoiceCreated) {
        if (this.user) {
          this.form.patchValue({
            personType: 'natural-person',
            email: this.user.email,
            fatherLastName: this.user.fatherLastName,
            motherLastName: this.user.motherLastName,
            name: this.user.name,
          });
        }
        this.form.controls.orderId.patchValue(this.orderId);
      }
    } catch (error) {
      this.errorReportingService.log('InvoicingSidebarComponent.getUserData()', 'get-user-data', error);
      this.errorLoadingUserData = true;
    } finally {
      this.loadingUserData = false;
    }
  }

  protected modifyContactData(): void {
    const { value: personType } = this.form.controls.personType;
    this.form.controls.name.enable();
    if (personType === 'natural-person') {
      this.form.controls.fatherLastName.enable();
      this.form.controls.motherLastName.enable();
    }
  }

  protected async sendInvoice(): Promise<void> {
    this.sendingInvoice = true;
    this.invoiceSent = false;
    let step = '';
    try {
      if (this.form.invalid) {
        return;
      }
      const {
        cfdiUse,
        email,
        name,
        orderId,
        personType,
        postalCode,
        rfc,
        taxRegime,
      } = this.form.getRawValue() as FormValues;
      const fullName = `${name} ${this.form.controls.fatherLastName.value}${this.form.controls.motherLastName.value ? (' ' +  this.form.controls.motherLastName.value) : ''}`;
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitInvoice'));
      step = 'submit-invoice';
      await this.formsService.submitInvoice({
        cfdiUse,
        recaptchaToken,
        email,
        orderId,
        postalCode,
        rfc,
        ...(personType === 'natural-person' ? {
          name: fullName,
          personType: 'natural-person',
          taxRegime,
        } : {
          companyName: this.form.getRawValue().companyName as string,
          name,
          personType: 'legal-entity',
          taxRegime,
        }),
      });
      this.invoiceSent = true;
    } catch (error) {
      this.close();
      this.errorReportingService.log('InvoicingSidebarComponent.sendInvoice()', step, error);
      this.invoiceSent = false;
      let errorDescription = 'Ocurrió un error al enviar tu factura.';
      errorDescription = error instanceof TRPCClientError && error.data.cause ?
        constants.ecommerce.invoices.createInvoiceErrorCausesToDescriptions[error.data.cause] ?? errorDescription :
        errorDescription;
      this.toastService.showError({ title: 'Error', description: errorDescription });
    } finally {
      this.sendingInvoice = false;
    }
  }

  protected async sendInvoiceAgain(): Promise<void> {
    if (!this.invoiceId || !this.invoiceCreated) {
      return;
    }
    let step = '';
    this.sendingInvoice = true;
    this.invoiceSent = false;
    try {
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitInvoiceAgain'));
      step = 'submit-invoice-again';
      await this.formsService.submitInvoiceAgain({ invoiceId: this.invoiceId, orderId: this.orderId, recaptchaToken });
      this.invoiceSent = true;
    } catch (error) {
      this.close();
      this.errorReportingService.log('InvoicingSidebarComponent.sendInvoiceAgain()', step, error);
      this.toastService.showError({ title: 'Error', description: 'Ocurrió un error al enviar tu factura.' });
      this.invoiceSent = false;
    } finally {
      this.sendingInvoice = false;
    }
  }

  private createForm(): void {
    if (this.form || this.invoiceCreated) {
      return;
    }
    this.form = new FormGroup({
      cfdiUse: new FormControl<CfdiUse | null>(null, Validators.required),
      companyName: new FormControl<string | null>(null, [Validators.maxLength(36), Validators.pattern(namePattern)]),
      email: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required, Validators.pattern(emailPattern)]),
      fatherLastName: new FormControl<string | null>({ value: null, disabled: true }, [Validators.maxLength(36), Validators.pattern(namePattern)]),
      motherLastName: new FormControl<string | null>({ value: null, disabled: true }, [Validators.maxLength(36), Validators.pattern(namePattern)]),
      name: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      orderId: new FormControl<string | null>({ value: this.orderId, disabled: true }, Validators.required),
      personType: new FormControl<PersonType | null>(null, Validators.required),
      postalCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(constants.postalCodeLength), Validators.maxLength(constants.postalCodeLength)]),
      rfc: new FormControl<string | null>(null, [Validators.required]),
      taxRegime: new FormControl<TaxRegime | null>(null, Validators.required),
    });

    this.form.controls.personType.valueChanges.pipe(takeUntil(this.viewDestroyed))
      .subscribe({
        next: (value) => {
          if (value === 'natural-person') {
            this.form.controls.companyName.removeValidators(Validators.required);
            this.form.controls.companyName.updateValueAndValidity();
            this.form.controls.fatherLastName.addValidators(Validators.required);
            this.form.controls.fatherLastName.updateValueAndValidity();
            this.form.controls.rfc.clearValidators();
            this.form.controls.rfc.setValidators([Validators.required, Validators.pattern(rfcNaturalPersonPattern)]);
            this.form.controls.rfc.updateValueAndValidity();
            this.filterAndPatchTaxRegime('natural-person');
          } else {
            this.form.controls.companyName.addValidators(Validators.required);
            this.form.controls.companyName.updateValueAndValidity();
            this.form.controls.fatherLastName.removeValidators(Validators.required);
            this.form.controls.fatherLastName.updateValueAndValidity();
            this.form.controls.rfc.clearValidators();
            this.form.controls.rfc.setValidators([Validators.required, Validators.pattern(rfcLegalEntityPattern)]);
            this.form.controls.rfc.updateValueAndValidity();
            this.filterAndPatchTaxRegime('legal-entity');
          }
          this.form.controls.name.disable();
          this.form.controls.fatherLastName.disable();
          this.form.controls.motherLastName.disable();
        }
      });
  }

  private filterAndPatchTaxRegime(personType: PersonType): void {
    this.form.controls.taxRegime.reset();
    this.taxRegimeFiltered = this.taxRegimeOptions.filter((taxRegime) => taxRegime.personTypes.includes(personType));
    if (personType === 'legal-entity') {
      return;
    }
    this.form.controls.taxRegime.patchValue('simplified-trust-regime');
  }
}
