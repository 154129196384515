<section class="mortgage-simulation-data-main-container">
  <div class="mortgage-simulation-data-container">
    <h2 class="title">Datos de la simulación</h2>
    <form [formGroup]="form" class="form-container">
      @if (mortgageSimulation) {
        @if (mortgageSimulation.type === 'housing-cost') {
          <div class="input-container">
            <label for="property-value">¿Cuál es el valor del inmueble que deseas comprar?</label>
            <p-inputNumber
              ariaLabelledBy="property-value-help"
              id="property-value"
              inputId="property-value"
              formControlName="propertyValue"
              locale="es-MX"
              mode="currency"
              placeholder="$2,000,000.00 {{ currency }}"
              [currency]="currency"
              suffix=" {{ currency }}"
              [minFractionDigits]="2"
              [maxFractionDigits]="2"
            />
            @if (form.controls.propertyValue.touched || form.controls.propertyValue.dirty) {
              <small id="property-value-help" class="p-error">
                @if (form.controls.propertyValue.errors?.['min']) {
                  {{ 'The minimum amount is X' | transloco: { amount: ( form.controls.propertyValue.errors?.['min'].min | currency ) } }} {{ currency }}
                } @else if (form.controls.propertyValue.errors?.['max']) {
                  {{ 'The maximum amount is X' | transloco: { amount: ( form.controls.propertyValue.errors?.['max'].max | currency ) } }} {{ currency }}
                }
              </small>
            }
          </div>
        } @else if (mortgageSimulation && mortgageSimulation.type === 'amount') {
          <div class="input-container">
            <label for="amount-needed">¿Cuánto dinero necesitas?</label>
            <p-inputNumber
              ariaLabelledBy="amount-needed-help"
              id="amount-needed"
              inputId="amount-needed"
              formControlName="amountNeeded"
              locale="es-MX"
              mode="currency"
              placeholder="$2,000,000.00 {{ currency }}"
              [currency]="currency"
              suffix=" {{ currency }}"
              [minFractionDigits]="2"
              [maxFractionDigits]="2" />
            @if (form.controls.amountNeeded.touched || form.controls.amountNeeded.dirty) {
              <small id="amount-needed-help" class="p-error">
                @if (form.controls.amountNeeded.errors?.['min']) {
                  {{ 'The minimum amount is X' | transloco: { amount: ( form.controls.amountNeeded.errors?.['min'].min | currency ) } }} {{ currency }}
                } @else if (form.controls.amountNeeded.errors?.['max']) {
                  {{ 'The maximum amount is X' | transloco: { amount: ( form.controls.amountNeeded.errors?.['max'].max | currency ) } }} {{ currency }}
                }
              </small>
            }
          </div>
        }
      }
      <div class="input-container">
        <label for="down-mortgage">¿Cuánto quieres dar de enganche?</label>
        <p-inputNumber
          ariaLabelledBy="down-mortgage-help"
          id="down-mortgage"
          inputId="down-mortgage"
          formControlName="downMortgage"
          locale="es-MX"
          mode="currency"
          placeholder="$200,000.00 {{ currency }}"
          [currency]="currency"
          suffix=" {{ currency }}"
          [minFractionDigits]="2"
          [maxFractionDigits]="2" />
      @if (!form.controls.downMortgage.touched || form.controls.downMortgage.dirty) {
        <small id="down-mortgage-help" class="p-error">
          @if (form.controls.downMortgage.errors?.['valueComparison']?.operator === 'gte') {
            {{ 'The minimum down mortgage is X' | transloco: { amount: (form.controls.downMortgage.errors?.['valueComparison'].value | currency) } }} {{ currency }}
          } @else if (form.controls.downMortgage.errors?.['valueComparison']?.operator === 'lte') {
            {{ 'The maximum down mortgage is X' | transloco: { amount: (form.controls.downMortgage.errors?.['valueComparison'].value | currency) } }} {{ currency }}
          }
        </small>
      }
      </div>
      <div class="payment-terms-container">
        <h3 class="title">¿En qué plazo quieres pagar?</h3>
        <div class="radio-buttons-container">
          @for (paymentTerm of paymentTerms; track $index) {
            <div class="radio-button-container">
              <p-radioButton formControlName="paymentTerm" id="payment-term" inputId="payment-term" [value]="paymentTerm" />
              <label class="label" for="payment-term">{{ 'X years' | transloco: { quantity: paymentTerm } }}</label>
            </div>
          }
        </div>
      </div>
    </form>
  </div>
  <div class="right-container">
    @if (mortgageSimulation) {
      @if (mortgageSimulation.type === 'amount') {
        <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-amount.svg" alt="Ilustración de simulación de hipoteca por monto que necesitas" />
      } @else if (mortgageSimulation.type === 'housing-cost') {
        <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-housing-cost.svg" alt="Ilustración de simulación de hipoteca por costo de inmueble" />
      }
    }
    <div class="continue-button-container animate__fadeIn">
      <app-button innerId="btn-LB7FUVUe" label="Continuar" styleClass="primary expand" [disabled]="form.invalid" (action)="continue()" />
    </div>
  </div>
</section> 