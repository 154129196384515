
<section class="balances-section">
  <div class="balances-wrapper">
    <div class="title-container">
      <i class="icon-arrow-left" (click)="goBack()"></i>
      <h2 class="title">{{ 'My balances and movements' | transloco }}</h2>
    </div>
    <app-retryable-section [error]="errorLoadingAccounts" [loading]="loadingAccounts" (retry)="getAccounts()">
      <div class="balances-container">
        @for (account of accounts; track $index) {
          <div class="balance-card animate__fadeIn">
            <div class="balance">
              <div class="icon">
                @if (account.type === 'mortgage') {
                  <i class="icon-remodeling"></i>
                } @else if (account.type === 'debit') {
                  <i class="icon-investment-fund"></i>
                }
              </div>
              <div class="balance-wrapper">
                @if (account.type === 'mortgage') {
                  <span class="balance-text">{{'Remodeling balance' | transloco }}<i class="icon-information-circle" pTooltip="{{ 'Balance from your mortgage, exclusively for remodeling your home.' | transloco }}" tooltipPosition="bottom"></i></span>
                } @else if (account.type === 'debit') {
                  <span class="balance-text">{{ 'Full availability balance' | transloco }}<i class="icon-information-circle" pTooltip="{{ 'Balance that you can use to buy anything you want in Mundo Hogar.' | transloco }}" tooltipPosition="bottom"></i></span>
                }
                <span class="amount">{{ account.balance | currencyFormatter: account.currency }} <span class="currency">{{ account.currency }}</span></span>
              </div>
            </div>
            <app-button class="button" label="Learn more" styleClass="secondary" [href]="getAccountTypeDetailUrl(account.type)" target="_blank" />
          </div>
        }
      </div>
    </app-retryable-section>
  </div>
  <div class="movements">
    <div class="title-container">
      <h2 class="title">{{ 'List of movements' | transloco }}</h2>
    </div>
    <app-retryable-section [error]="errorLoadingMovements" [loading]="loadingMovements" (retry)="getMovements()">
      @if (movements.length) {
        <div class="movement-list">
          @for (movement of movements; track $index) {
            <div class="movement-item animate__fadeIn" [routerLink]="getMovementDetailUrl(movement)">
              <div class="movement-details">
                <div class="movement-icon">
                  @if (movement.type === 'charge') {
                    <i class="pi pi-arrow-up-right"></i>
                  } @else {
                    <i class="pi pi-arrow-down-left"></i>
                  }
                </div>
                <div>
                  <p class="movement-title">{{ movement.title }}</p>
                  <p class="movement-subtitle">{{ movement.subtitle }}</p>
                </div>
              </div>
              <div class="movements-date-container">
                <p class="movement-date">{{ 'DD of MMMM YYYY' | transloco: {
                  day: movement.date | date: 'd',
                  month: ((movement.date | date: 'LLLL')?.toLocaleLowerCase() | transloco),
                  year: (movement.date | date: 'YYYY'),
                } }}</p>
                <p class="movement-time">{{ movement.date | date: 'HH:mm' }} hrs</p>
              </div>
              @if (movement.type === 'charge') {
                <p class="movement-amount">-{{ movement.amount | currencyFormatter: movement.currency }} <span class="currency">{{ movement.currency }}</span></p>
              } @else {
                <p class="movement-amount">{{ movement.amount | currencyFormatter: movement.currency }} <span class="currency">{{ movement.currency }}</span></p>
              }
              <div class="button-container" (click)="childEventClicked($event)">
                <app-button class="button" label="See detail" styleClass="secondary" [href]="getMovementDetailUrl(movement)" />
              </div>
              <div class="navigation-balance-container">
                <i class="icon-arrow-right"></i>
              </div>
            </div>
          }
        </div>
      } @else {
        <div class="empty-movements animate__fadeIn">
          <img class="image" src="/assets/illustrations/generic-empty.svg" [alt]="'You don\'t have yet movements' | transloco" />
          <h5 class="title">{{ 'You don\'t have yet movements' | transloco }}</h5>
        </div>
      }
    </app-retryable-section>
  </div>
</section>