import { Account } from '@homein-accounts-server';
import { constants } from '../../../constants';
import { USERS_MOCK } from '../../users/users.mock';

export const ECOMMERCE_PARTNER_ACCOUNT_MOCK: Account = {
  additionalData: null,
  balance: 0,
  blockedBalance: 0,
  createdAt: new Date(),
  currency: constants.defaultCurrency,
  id: 'u9B3jVWDMzcYxEjcqOS6',
  lastMovementAt: null,
  maxBalance: null,
  ownerId: 'Js1OBwc9vtHZYrd3K1At',
  ownerType: 'company',
  status: 'active',
  transactionId: null,
  type: 'debit',
  updatedAt: new Date(),
  usageRules: null,
};

export const MOVING_PARTNER_ACCOUNT_MOCK: Account = {
  additionalData: null,
  balance: 0,
  blockedBalance: 0,
  createdAt: new Date(),
  currency: constants.defaultCurrency,
  id: '1IdB2MzN0LaROGJc8fjM',
  lastMovementAt: null,
  maxBalance: null,
  ownerId: 'B7BMP6zq5yXlAGgtZOlY',
  ownerType: 'company',
  status: 'active',
  transactionId: null,
  type: 'debit',
  updatedAt: new Date(),
  usageRules: null,
};

export const WAREHOUSE_PARTNER_ACCOUNT_MOCK: Account = {
  additionalData: null,
  balance: 0,
  blockedBalance: 0,
  createdAt: new Date(),
  currency: constants.defaultCurrency,
  id: 'tAFHMpsaOiGTSUgvI5br',
  lastMovementAt: null,
  maxBalance: null,
  ownerId: 'SVLIQ7wC1j5OROMMPVDY',
  ownerType: 'company',
  status: 'active',
  transactionId: null,
  type: 'debit',
  updatedAt: new Date(),
  usageRules: null,
};

export const ACCOUNTS_MOCK: Account[] = [
  {
    additionalData: null,
    balance: 250000.0,
    blockedBalance: 0,
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    id: 'dto3Qxi7t7fybfV98cSJ',
    lastMovementAt: null,
    maxBalance: null,
    ownerId: USERS_MOCK[0].id,
    ownerType: 'user',
    status: 'active',
    transactionId: null,
    type: 'debit',
    updatedAt: new Date(),
    usageRules: null,
  },
  {
    additionalData: null,
    balance: 400000.0,
    blockedBalance: 0,
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    id: 'W06UyWZuiihdBjqaNsrN',
    lastMovementAt: null,
    maxBalance: null,
    ownerId: USERS_MOCK[0].id,
    ownerType: 'user',
    status: 'active',
    transactionId: null,
    type: 'mortgage',
    updatedAt: new Date(),
    usageRules: null,
  },
  {
    additionalData: null,
    balance: 2500.0,
    blockedBalance: 0,
    createdAt: new Date(),
    currency: constants.defaultCurrency,
    id: 'LE2H2uqnhgOyJZGUNIj8',
    lastMovementAt: null,
    maxBalance: null,
    ownerId: USERS_MOCK[1].id,
    ownerType: 'user',
    status: 'active',
    transactionId: null,
    type: 'debit',
    updatedAt: new Date(),
    usageRules: null,
  },
  MOVING_PARTNER_ACCOUNT_MOCK,
  ECOMMERCE_PARTNER_ACCOUNT_MOCK,
  WAREHOUSE_PARTNER_ACCOUNT_MOCK,
];
