import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { filter, firstValueFrom, take } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { EMAIL_PATTERN } from '../../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { SessionProvider, SessionsService } from '../../../services/sessions/sessions.service';
import { ShoppingCartsService } from '../../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { WarehouseOrdersService } from '../../../services/warehouse-orders/warehouse-orders.service';
import { validateAndNavigate } from '../../../utils/enrollment.utils';
import { isActive } from '../../../utils/forms.utils';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RetryableSectionComponent,
    RouterLink,
    TabViewModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sign-in.page.html',
  styleUrl: './sign-in.page.scss',
})
export class SignInPage implements OnInit {
  form: FormGroup;
  signInWithEmail = false;
  private loadingProvider: SessionProvider | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private location: Location,
    private metaTagsService: MetaTagsService,
    private movingOrdersService: MovingOrdersService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private sessionsService: SessionsService,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
    private usersService: UsersService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
    });
  }

  // TODO: Remove and access controls from form property
  get email(): FormControl { return this.form.controls['email'] as FormControl; }
  get password(): FormControl { return this.form.controls['password'] as FormControl; }

  goBack(): void {
    if (this.signInWithEmail) {
      this.signInWithEmail = false;
      return;
    }
    return this.location.back();
  }

  goTo(url: string): void {
    const origin = this.activatedRoute.snapshot.queryParamMap.get('origin');
    const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
    const queryParams: Params = {
      ...(origin && { origin }),
      ...(redirectTo && { redirectTo }),
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate([url], { queryParams, queryParamsHandling: 'merge' });
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  isLoading(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider === provider;
  }

  isLoadingOther(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider !== provider;
  }

  ngOnInit(): void {
    // TODO: Define content params
    this.metaTagsService.setMetaTags();
    const source = this.activatedRoute.snapshot.queryParamMap.get('source');
    if (source === 'verify-email') {
      setTimeout(() => {
        this.toastService.showWarning({
          title: 'Finish registration',
          description: 'To continue with your verification, please sign in.',
        });
      }, 500);
    }
  }

  showEmailForm(): void {
    this.signInWithEmail = true;
  }

  async signIn(provider: 'apple' | 'email' | 'google'): Promise<void> {
    if (provider === 'email' && !this.form.valid) {
      return;
    }
    let step = '';
    try {
      this.loadingProvider = provider;
      step = `sign-in-with-provider-${provider}`;
      provider === 'email' ?
        await this.sessionsService.signIn({ provider, data: this.form.getRawValue() }, () => firstValueFrom(this.recaptchaV3Service.execute('sessionsRouter/create'))) :
        await this.sessionsService.signIn({ provider }, () => firstValueFrom(this.recaptchaV3Service.execute('sessionsRouter/create')));
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser), take(1)));
      step = 'synchronize-shopping-cart';
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.shoppingCartsService.synchronize();
      step = 'synchronize-favorite';
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.favoritesService.synchronize();
      step = 'validate-and-navigate';
      await validateAndNavigate(
        user,
        this.dataStorageService,
        this.router,
        this.activatedRoute.snapshot.queryParamMap,
        this.movingOrdersService,
        this.warehouseOrdersService,
      );
      const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
      this.analyticsService.logSignInEvent({
        method: provider,
        ...(redirectTo && { redirect_to: decodeURIComponent(redirectTo) }),
      });
    } catch (error) {
      const { code } = error as { code: string; message: string; };
      if (code === 'invalid-credential') {
        this.toastService.showError({ title: 'Error', description: 'The email or password is incorrect.' });
      } else {
        this.toastService.showError({ title: 'Error', description: 'Unexpected error' });
      }
      this.errorReportingService.log('SignInPage.signIn()', step, error);
    } finally {
      this.loadingProvider = null;
    }
  }
}
