<app-toast />
<app-navbar [class.hide]="isPaymentGatewayRoute" />
@if (shouldAskForCookiesAcceptance) {
  <div class="cookies-container-bar">
    <div class="cookies-container">
      <div class="cookies">
        <div class="title-container">
          <i class="icon-information-circle icon"></i>
          <h4 class="title">Este sitio utiliza cookies</h4>
        </div>
        <div class="content">
        <!-- TODO: Delete display hidden in link and add final pivot, when the link is available-->
          <p class="text">Utilizamos cookies propias y de terceros para obtener datos estadísticos de la navegación de nuestros usuarios y mejorar nuestros servicios.<a class="link" rel="noopener">Leer más información</a></p>
        </div>
      </div>
      <app-button class="button" label="Aceptar" (action)="acceptCookies()" />
    </div>
  </div>
}
<router-outlet />
<app-footer [class.hide]="isPaymentGatewayRoute" />
