import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { RemodelingOrder } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../../components/button/button.component';
import { RatingComponent } from '../../../../components/rating/rating.component';
import { RemodelingPurchaseDetailsComponent } from '../../../../components/remodeling-purchase-details/remodeling-purchase-details.component';
import { RetryableSectionComponent } from '../../../../components/retryable-section/retryable-section.component';
import { ErrorReportingService } from '../../../../services/error-reporting/error-reporting.service';
import { RemodelingOrdersService } from '../../../../services/remodeling-orders/remodeling-orders.service';
import { ToastService } from '../../../../services/toast/toast.service';

@Component({
  selector: 'app-remodeling-status',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CommonModule,
    RatingComponent,
    RemodelingPurchaseDetailsComponent,
    RetryableSectionComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './remodeling-status.page.html',
  styleUrl: './remodeling-status.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class RemodelingStatusPage implements OnInit, OnDestroy {
  errorLoading = false;
  loading = true;
  order: RemodelingOrder;
  showFranchiseeData = false;
  showRemodelingPurchaseDetail = false;
  private orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private location: Location,
    private remodelingOrdersService: RemodelingOrdersService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyRemodelings();
      return;
    }
    this.orderId = orderId;
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    this.remodelingOrdersService.get(this.orderId).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (order) => {
        if (!order) {
          this.goToMyRemodelings();
          return;
        }
        this.order = order;
        this.setShowRemodelingPurchaseDetail();
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('RemodelingStatusPage.initialize()', 'get-order', error);
        this.errorLoading = true;
        this.loading = false;
      },
    });
  }

  private goToMyRemodelings(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-remodelings']);
  }

  private setShowRemodelingPurchaseDetail(): void {
    this.showRemodelingPurchaseDetail = (this.order.status !== 'lead-created' && (this.order.status !== 'pending' || this.order.paid));
  }
}