import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FavoriteItem } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { BadgeModule } from 'primeng/badge';
import { CarouselModule } from 'primeng/carousel';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { ProductsCarouselComponent } from '../../../components/products-carousel/products-carousel.component';
import { constants } from '../../../constants';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';
import { TrackedProduct, TrackingService } from '../../../services/tracking/tracking.service';
import { getRandomInteger } from '../../../utils/numbers.utils';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    BadgeModule,
    CarouselModule,
    CommonModule,
    ProductsCarouselComponent,
    RouterModule,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomePage implements OnInit, OnDestroy {
  categories: {
    code: string;
    image: string;
    label: string;
    queryParams: Record<string, string>;
  }[];
  favorites: Record<string, FavoriteItem> = {};
  highlightedProductsPage = getRandomInteger(1, 5);
  mainBanners: {
    queryParams: Record<string, string>;
    subtitle?: string;
    title?: string;
  }[] = [
      {
        queryParams: {
          filters: JSON.stringify({ categories: { field: 'categories', operator: 'in', value: 'salas-y-family-room' } }),
        }
      },
      {
        title: 'Electrodomésticos y línea blanca',
        queryParams: {
          filters: JSON.stringify({ categories: { field: 'categories', operator: 'in', value: 'electrodomesticos-y-linea-blanca' } }),
        }
      },
      {
        title: 'Recámaras',
        queryParams: {
          filters: JSON.stringify({ categories: { field: 'categories', operator: 'in', value: 'recamaras' } }),
        }
      }
    ];
  productCategories: string[] = [];
  showCategories = false;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private platformService: PlatformService,
    private seoService: SeoService,
    private trackingService: TrackingService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.setEcommerceCategories();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('ecommerce');
    }
    this.initialize();
  }

  private getCategoriesTracked(trackedData: TrackedProduct[] | null): void {
    if (!trackedData) {
      return;
    }
    const categories = new Set<string>();
    trackedData.forEach((product) => product.categories.forEach((category) => categories.add(category)));
    this.productCategories = Array.from(categories);
  }

  private initialize(): void {
    combineLatest([
      this.favoritesService.get(),
      this.trackingService.getAll(),
    ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: ([favorite, trackedProducts]) => {
        this.favorites = {};
        if (favorite) {
          favorite.items.forEach((item) => this.favorites[`${item.resourceType}-${item.resourceId}`] = item);
        }
        this.getCategoriesTracked(trackedProducts);
      },
      error: (error) => this.errorReportingService.log('HomePage.initialize()', 'get-all', error),
    });
    // TODO: Find a better way to avoid the the double row of categories when the carousel is rendered
    setTimeout(() => {
      this.showCategories = true;
    }, 100);
  }

  private setEcommerceCategories(): void {
    const { tree, labels } = constants.ecommerce.categories;
    this.categories = Object.keys(tree).map((code) => ({
      code,
      label: labels[code],
      image: `${code}.png`,
      queryParams: {
        filters: JSON.stringify({ categories: { value: [code], operator: 'in', field: 'categories' } }),
      },
    }));
  }
}
