<section class="my-home-assistances-container">
  <div class="title-container">
    <i class="icon-arrow-left" routerLink="/private-site"></i>
    <h2 class="title">Mis planes de asistencia
      @if (homeAssistanceOrdersDetails.length) {
        <span class="total-items">({{ homeAssistanceOrdersDetails.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!homeAssistanceOrdersDetails.length) {
      <div class="empty-assistances animate__fadeIn">
        <img class="image" src="/assets/illustrations/generic-empty.svg" [alt]="'You don\'t yet request assistances' | transloco" />
        <h5 class="title">Aún no tienes planes activos</h5>
        <p class="subtitle">Comienza a cotizar tu plan de asistencia</p>
        <app-button innerId="btn-Iy2J30Q5" label="Elige tu plan" styleClass="secondary" href="/home-assistance" />
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
      @for (homeAssistanceOrderDetail of homeAssistanceOrdersDetails; track $index) {
        <div class="order-container">
          <div class="products-container">
            <a class="product-container" routerLink="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}">
              @if (homeAssistanceOrderDetail.status === 'active') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status active">Activo</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">Vigencia: {{ homeAssistanceOrderDetail.startAt | date: 'dd/MM/yyyy' }}</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">Tu plan está activo. Si necesitas una asistencia, contáctanos.</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button innerId="btn-OI3HJ0uX" label="Ver detalle" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
                <div class="active-order-content" (click)="childEventClicked($event)">
                  <div class="qr-wrapper">
                    @if (homeAssistanceOrderDetail.qrInformation) {
                      <qrcode [qrdata]="homeAssistanceOrderDetail.qrInformation" [width]="110" [margin]="0" errorCorrectionLevel="M"></qrcode>
                    }
                  </div>
                  <app-reminder title="Escanea para llamar y solicitar asistencia" class="reminder" styleClass="alert">
                    <span class="subtitle">Este es tu identificador: {{ userEmail }}</span>
                    <p class="description">Se solicitará tu identificador para realizar la asistencia.</p>
                    <p class="description">También puedes escribirnos por WhatsApp <span class="phone-number">+52 55 8719 6412</span>, o llámanos al <span class="phone-number">55 9063 3719</span></p>
                  </app-reminder>
                  <app-reminder title="Importante" class="reminder-mobile" styleClass="alert">
                    <span class="subtitle">Este es tu identificador: {{ userEmail }}</span>
                    <p class="description">Se solicitará tu identificador para realizar la asistencia.</p>
                    <p class="description">También puedes escribirnos por WhatsApp <span class="phone-number">+52 55 8719 6412</span>, o llámanos al <span class="phone-number">55 9063 3719</span></p>
                  </app-reminder>
                </div>
                <div class="buttons-container">
                  <app-button innerId="btn-26VVt5ff" label="Llámanos" href="tel:5590633719" target="_blank" styleClass="secondary" icon="phone-call" (click)="selfEventClicked($event)" />
                  @if (homeAssistanceOrderDetail.plan.id === 'plan-hogar') {
                    <app-button [innerId]="'btn-0IAb9FAS-' + homeAssistanceOrderDetail.plan.id" label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar.+&type=&&type=383absent=0" target="_blank" styleClass="secondary" icon="whatsapp" (click)="selfEventClicked($event)" />
                  } @else if (homeAssistanceOrderDetail.plan.id === 'plan-mascotas') {
                    <app-button [innerId]="'btn-0IAb9FAS-' + homeAssistanceOrderDetail.plan.id" label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Mascotas.+&type=&&type=383absent=0" target="_blank" styleClass="secondary" icon="whatsapp" (click)="selfEventClicked($event)" />
                  } @else if (homeAssistanceOrderDetail.plan.id === 'plan-hogar-mascotas') {
                    <app-button [innerId]="'btn-0IAb9FAS-' + homeAssistanceOrderDetail.plan.id" label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar+y+Mascota.+&type=&&type=383absent=0" target="_blank" styleClass="secondary" icon="whatsapp" (click)="selfEventClicked($event)" />
                  }
                </div>
              } @else if (homeAssistanceOrderDetail.status === 'activation-process') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status in-process">En proceso de activación</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">Vigencia: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
                      <span class="description-status">Tu plan está en proceso de ser activado. Para cualquier duda por favor contáctanos.</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">Tu plan está en proceso de ser activado. Para cualquier duda por favor contáctanos.</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button [innerId]="'btn-bbDtydht-' + homeAssistanceOrderDetail.plan.id" label="Ver detalle" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
              } @else if (homeAssistanceOrderDetail.status === 'finished') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status done">Finalizada</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">Vigencia: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
                      <span class="description-status">Tu plan ha finalizado.</span>
                      <span class="description-status">Podrás contratarlo nuevamente en el botón reactivar.</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">Tu plan ha finalizado.</span>
                    <span class="description-status">Podrás contratarlo nuevamente en el botón reactivar.</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button [innerId]="'btn-5iWTYD7e-' + homeAssistanceOrderDetail.plan.id" label="Reactivar plan" styleClass="tertiary" [href]="'/home-assistance/' + homeAssistanceOrderDetail.plan.id" [queryParams]="{ action: 'repurchase', duration: homeAssistanceOrderDetail.duration }" />
                    <app-button [innerId]="'btn-xKhup5Do-' + homeAssistanceOrderDetail.plan.id" label="Ver detalle" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
              } @else if (homeAssistanceOrderDetail.status === 'voided') {
                <div class="product-content">
                  <div class="column first">
                    <div class="right-container">
                      <span class="plan-status done">Anulado</span>
                      <span class="plan-name">{{ homeAssistanceOrderDetail.plan.name }}</span>
                      <span class="description">{{ homeAssistanceOrderDetail.membershipLabel | transloco }}</span>
                      <span class="description">Vigencia: {{ homeAssistanceOrderDetail.finishAt | date: 'dd/MM/yyyy' }}</span>
                      <span class="description-status">Tu plan ha sido anulado.</span>
                    </div>
                  </div>
                  <div class="column second">
                    <span class="description-status">Podrás contratarlo nuevamente en el botón reactivar.</span>
                  </div>
                  <div class="column third" (click)="childEventClicked($event)">
                    <app-button [innerId]="'btn-vn8drDaT-' + homeAssistanceOrderDetail.plan.id" label="Reactivar plan" styleClass="tertiary" [href]="'/home-assistance/' + homeAssistanceOrderDetail.plan.id" [queryParams]="{ action: 'repurchase', duration: homeAssistanceOrderDetail.duration }" />
                    <app-button [innerId]="'btn-3pDQWRtC-' + homeAssistanceOrderDetail.plan.id" label="Ver detalle" styleClass="secondary" href="/private-site/my-home-assistances/{{ homeAssistanceOrderDetail.id }}" />
                  </div>
                  <i class="icon-arrow-right"></i>
                </div>
              }
            </a>
          </div>
        </div>
      }
    </div>
    }
  </app-retryable-section>
</section>