@if (loading) {
  <main class="moving-loading-page">
    <p-progressSpinner></p-progressSpinner>
  </main>
} @else {
  <section class="main">
    <div class="main-container">
      <div class="right-side">
        <div class="title-container">
          <p class="on-title-text">Beneficios exclusivos para clientes <strong>Santander</strong></p>
          <h1 class="title">{{ 'Stress-free moves to your new home' | transloco }}</h1>
        </div>
        <div class="button-container">
          <app-button label="Moving quote" (action)="navigateToQuote()" styleClass="primary" icon="truck" />
        </div>
      </div>
      <div class="left-side">
        <img class="main-image" src="assets/images/moving-main.svg" [alt]="'Moving main image' | transloco ">
      </div>
    </div>
  </section>
  <section class="terms-banner">
    <app-terms-banner page="moving" />
  </section>
  <section class="moving-benefits">
    <div class="container">
      <div class="left-container">
        <img src="assets/images/moving-steps.svg" class="moving-image" alt="{{ 'moving steps image' | transloco }}">
      </div>
      <div class="right-container">
        <div class="title-container">
          <h2 class="title">{{ 'Your move in just 3 steps' | transloco }}</h2>
        </div>
        <div class="benefits-container">
          <ol class="benefits">
            <li class="benefit">{{ 'Enter the data for quote your moving.' | transloco }}</li>
            <li class="benefit">{{ 'Select the type of service you need.' | transloco }}</li>
            <li class="benefit">{{ 'All set, we will take care of the rest.' | transloco }}</li>
          </ol>
        </div>
        <div class="button-container">
          <app-button label="Free quote" (action)="navigateToQuote()" styleClass="secondary expand" />
        </div>
      </div>
    </div>
  </section>
  <section class="moving-banner-section">
    <div class="banner-container">
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">{{ "Your next move with up to 15% discount" | transloco }}</h4>
          <app-button label="Quote" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img" src="assets/images/moving-banner-1.png" alt="{{ 'Moving banner image' | transloco }}" loading="lazy" />
        </div>
      </div>
      <div class="banner">
        <div class="left-card-container">
          <h4 class="title">{{ 'Protect every space in your next home' | transloco }}</h4>
          <app-button label="View plan" routerLink="/home-assistance" styleClass="secondary" />
        </div>
        <div class="right-card-container">
          <img class="img second-banner" src="assets/images/moving-banner-2.png" alt="{{ 'Moving banner image' | transloco }}" loading="lazy" />
        </div>
      </div>
    </div>
  </section>
  <section class="why-trust-moving-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <span class="title">{{ 'Why do we rely on' | transloco }}<img class="moving-logo" alt="{{ 'Mudango logo' | transloco }}" src="assets/images/mudango-logo.svg">?</span>
          <hr class="underline">
        </div>
      </div>
      <div class="right-container">
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">{{ 'Focused on you' | transloco }}</h4>
            <p class="description">{{ 'No-surprise quote tailored to your needs.' | transloco }}</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">{{ 'Experience and capacity' | transloco }}</h4>
            <p class="description">{{ 'More than 30,000 moves per year in the main cities of the region.' | transloco }}</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">{{ 'Local coverage' | transloco }}</h4>
            <p class="description">{{ 'Presence in the main cities and areas of the country.' | transloco }}</p>
          </div>
        </div>
        <div class="trust-content">
          <i class="icon-checkmark-in-a-circle"></i>
          <div class="trust">
            <h4 class="title">{{ 'Trajectory' | transloco }}</h4>
            <p class="description">{{ 'Pioneers in online moving since 2017 with presence in Mexico, Colombia and Chile.' | transloco }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="coverage-section">
    <div class="container">
      <div class="left-container">
        <div class="title-container">
          <h2 class="title">{{ 'We are in the main cities of Mexico' | transloco }}</h2>
        </div>
        <div class="left-button-container">
          <app-button label="Check your address" (action)="navigateToQuote()" styleClass="secondary" />
        </div>
      </div>
      <div class="right-container">
        <ul class="cities-list">
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Ciudad de México</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Guadalajara</span>
          </li>
          <li class="city-item">
            <i class="icon-location"></i>
            <span class="city">Monterrey</span>
          </li>
        </ul>
        <p class="more-info">{{ 'and more... consult and quote your move' | transloco }}</p>
      </div>
      <div class="button-container">
        <app-button label="Check your address" (action)="navigateToQuote()" styleClass="secondary" />
      </div>
    </div>
  </section>
  <article class="why-platform-article">
    <div class="title-container">
      <h2 class="title">{{ 'Why Mundo Hogar?' | transloco }}</h2>
      <hr class="underline">
      <div class="benefits-container">
        <div class="benefit">
          <i class="icon-monitor"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'All in one place' | transloco }}</h4>
            <span class="description">{{ 'Find everything you need for your home, in the same 100% online portal.' | transloco }}</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-jewel"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'Exclusive benefits' | transloco }}</h4>
            <span class="description">{{ 'Exclusive financing alternatives for Santander customers and much more.' | transloco }}</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-security-profile"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'The best suppliers' | transloco }}</h4>
            <span class="description">{{ 'Qualified suppliers, guaranteed services and secure payment methods for you.' | transloco }}</span>
          </div>
        </div>
        <div class="benefit">
          <i class="icon-thumbs-up"></i>
          <div class="benefit-content">
            <h4 class="label">{{ 'Simple and transparent' | transloco }}</h4>
            <span class="description">{{ 'Manage and track your projects online in just a few clicks.' | transloco }}</span>
          </div>
        </div>
      </div>
      <div class="become-a-customer">
        <p class="customer-benefit">{{ 'Exclusive benefits for Santander customers. If you are not a customer yet' | transloco }} <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">{{ 'click here' | transloco }}.</a><p>
      </div>
    </div>
  </article>
  <article class="faq-article">
    <h2 class="title">{{ 'FAQ' | transloco }}</h2>
    <div class="faq-container">
      <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
        <p-accordionTab class="accordion-tab" header="{{ 'Why do I have to pay for a reservation?' | transloco }}" iconPos="end">
          <div class="text-container">
            <p class="text">{{ 'The reservation is a payment of the total price, which ensures availability for your move and gives you peace of mind. The reservation is 100% refundable in case you cancel your move, and 100% flexible for date changes, free of penalties.' | transloco }}</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'What if I don\'t have a definite date for my move?' | transloco }}" iconPos="end">
          <div class="text-container">
            <p class="text">{{ 'No problem, you can book and reschedule as many times as you want. We want to give you peace of mind.' | transloco }}</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'How do I ensure that the volume entered is correct?' | transloco }}" iconPos="end">
          <div class="text-container">
            <p class="text">{{ 'Our algorithm is based on the thousands of moves we make each year and is constantly learning. If you have entered the inventory correctly, the volume will be correct. However, once booked, we will schedule a volume confirmation visit to make sure everything is correct.' | transloco }}</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'Who does my move?' | transloco }}" iconPos="end">
          <div class="text-container">
            <p class="text">{{ 'El servicio de mudanza es realizado por nuestros Moving Partners, que son equipos preseleccionados y constantemente capacitados por nosotros. Los equipos están clasificados según el tipo de servicio que deseas contratar, y trabajan codo a codo con nosotros en nuestra plataforma.' | transloco }}</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'What is my Move Reserve?' | transloco }}" iconPos="end">
          <div class="text-container">
            <p class="text">{{ 'The reservation of a move is the process by which a customer schedules and secures the availability of the moving service on a specific date and time. The reservation is equivalent to 20% of the estimated value of the move. Once the booking is made, we organize the necessary equipment and resources to execute the move according to the customer\'s requirements, ensuring a safe and efficient experience.' | transloco }}</p>
          </div>
        </p-accordionTab>
        <p-accordionTab class="accordion-tab" header="{{ 'What is the Volume Confirmation Visit (VCV)?' | transloco }}" iconPos="end">
          <div class="text-container">
            <p class="text">{{ 'One of the most important processes that occurs between your moving reservation and the day of service is the Volume Confirmation Visit (VVV). In this visit, the team that will perform your move will inspect your home and your things, to check all the minor - but important - details that could affect the good performance of your service.' | transloco }}</p>
            <p class="text">{{ 'What kind of details? For example, if you didn\'t indicate in the quote that your building doesn\'t have nearby parking for the truck, or if there are any oversized items that need to be carried up or down stairs or out of windows. Another typical example is when there is more complex furniture that needs to be disassembled to move out of your current home, or into your new home.' | transloco }}</p>
            <p class="text">{{ 'So, to understand all these details, and also so that we can be sure that the size of the truck will be adequate for the amount of things that will be moved in the move, the VCV is mandatory for all customers.\nGenerally these details are already reviewed and considered in the quotation with your consultant before booking, while you resolve your doubts and make the decision to make your move with us. However, it is important that we are sure that everything is considered. This is the only way we can deliver the quality of service that we want and that you deserve.' | transloco }}</p>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
    <h5 class="help-content">{{ 'Visit our' | transloco }} <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">{{ 'Help center' | transloco }}</a> {{ 'for more information' | transloco }}</h5>
  </article>
}
