<section class="password-reset-main-container">
  <div class="left-column">
    @if (displayNewPasswordView && !updatedPassword) {
      <div class="new-password-content-container">
        <h2 class="title">Restablecer contraseña</h2>
        <p class="description">Introduce la nueva contraseña para tu cuenta Mundo Hogar</p>
        <form [formGroup]="newPasswordForm" class="form-container">
          <div class="input-container">
            <label for="password">Nueva contraseña Mundo Hogar</label>
            <p-password id="password" [class.active]="isActive(newPasswordForm.controls.password)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" formControlName="password" aria-describedby="password-help" data-private></p-password>
            @if (newPasswordForm.controls.password.touched || newPasswordForm.controls.password.dirty) {
              <small class="input-error" [class.p-error]="newPasswordForm.controls.password.errors?.['minLength']"><i [ngClass]="{'icon-close': newPasswordForm.controls.password.errors?.['minLength'], 'icon-checkmark': !newPasswordForm.controls.password.errors?.['minLength']}"></i> {{ 'Minimum X characters' | transloco: { min: 8 } }}</small>
              <small class="input-error" [class.p-error]="newPasswordForm.controls.password.errors?.['uppercase']"><i [ngClass]="{'icon-close': newPasswordForm.controls.password.errors?.['uppercase'], 'icon-checkmark': !newPasswordForm.controls.password.errors?.['uppercase']}"></i> Al menos una letra mayúscula</small>
              <small class="input-error" [class.p-error]="newPasswordForm.controls.password.errors?.['specialCharacter']"><i [ngClass]="{'icon-close': newPasswordForm.controls.password.errors?.['specialCharacter'], 'icon-checkmark': !newPasswordForm.controls.password.errors?.['specialCharacter']}"></i> Al menos un carácter especial</small>
            }
          </div>
          <div class="input-container">
            <label for="repeated-password">Reingresa la nueva contraseña</label>
            <p-password id="repeated-password" [class.active]="isActive(newPasswordForm.controls.repeatedPassword)" [feedback]="false" [toggleMask]="true" [maxLength]="16" [minlength]="8" formControlName="repeatedPassword" aria-describedby="repeated-password-help" data-private></p-password>
            @if ((newPasswordForm.controls.repeatedPassword.touched || newPasswordForm.controls.repeatedPassword.dirty) && newPasswordForm.controls.repeatedPassword.errors?.['equalValues']) {
              <small class="input-error" [class.p-error]="newPasswordForm.controls.repeatedPassword.errors?.['equalValues']"><i [ngClass]="{'icon-close': newPasswordForm.controls.repeatedPassword.errors?.['equalValues'], 'icon-checkmark': !newPasswordForm.controls.repeatedPassword.errors?.['equalValues']}"></i> Las contraseñas no coinciden</small>
            }
          </div>
          <div class="action-button-container">
            <app-button innerId="btn-bQXr8Dlz" label="Restablecer contraseña" styleClass="primary expand" [loading]="updatingPassword" [disabled]="newPasswordForm.invalid" (action)="update()" />
          </div>
        </form>
      </div>
    } @else if (!displayNewPasswordView && !updatedPassword) {
      <div class="password-recovery-content-container">
        <h2 class="title">¿Olvidaste tu contraseña?</h2>
        <p class="description">Escribe el correo de tu cuenta para enviar instrucciones</p>
        <form class="form-container" [formGroup]="emailForm">
          <div class="input-container">
            <label for="email">Correo electrónico</label>
            <input id="email" type="email" [class.active]="isActive(emailForm.controls.email)" [class.disabled]="recoveringPassword" pInputText formControlName="email" data-private />
          </div>
          <div class="action-button-container">
            <app-button innerId="btn-yytVheyl" label="Enviar instrucciones" styleClass="primary expand" [loading]="recoveringPassword" [disabled]="emailForm.invalid" (action)="recover()" />
          </div>
        </form>
      </div>
    } @else {
      <div class="updated-password-content-container">
        <img class="image" src="/assets/illustrations/success-check.svg" alt="Imagen verificación de éxito" />
        <h4 class="title">¡Ya tienes nueva contraseña!</h4>
        <p class="description">Ingresa a tu cuenta Mundo Hogar con tu nueva contraseña</p>
        <div class="action-button-container">
          <app-button innerId="btn-Bfxxeryi" label="Ingresar" styleClass="primary expand" href="/enrollment/sign-in" />
        </div>
      </div>
    }
  </div>
  <div class="right-column">
    <div class="text-container">
      <h3 class="title">{{ (displayNewPasswordView || updatedPassword ? 'We love to see you happy' : 'Enjoy with Mundo Hogar') | transloco }}</h3>
      <p class="description">{{ (displayNewPasswordView || updatedPassword ? 'You almost regain access to your account' : 'Everything for your home in one place') | transloco }}</p>
    </div>
    <img class="image" src="/assets/images/woman-laughing.jpg" alt="imagen recuperar contraseña" />
  </div>
</section>