import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Account } from '@homein-accounts-server';
import {
  ChargeIntent,
  CreatePaymentIntentData,
  HomeAssistancePlan,
  PaymentIntentExternalApportion,
  PaymentIntentExternalApportionInstallments,
  PaymentIntentExternalApportionOption,
  PaymentIntentInternalApportion,
} from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { ButtonModule } from 'primeng/button';
import { combineLatest, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { constants } from '../../../constants';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { AccountsService } from '../../../services/accounts-server/accounts/accounts.service';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ChargeIntentsService } from '../../../services/charge-intents/charge-intents.service';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { HomeAssistancePlansService } from '../../../services/home-assistance-plans/home-assistance-plans.service';
import { PaymentIntentsService } from '../../../services/payment-intents/payment-intents.service';
import { SeoService } from '../../../services/seo/seo.service';
import { ShoppingCartDetail, ShoppingCartsService } from '../../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../../services/toast/toast.service';
import { LocalUser } from '../../../services/users/users.service';
import { getMovingServiceFromChargeIntent } from '../../../utils/moving.utils';
import { normalizeFloat } from '../../../utils/numbers.utils';
import { getRemodelingServiceFromChargeIntent } from '../../../utils/remodeling.utils';
import { toCapitalizeCase } from '../../../utils/string.utils';
import { getWarehouseServiceFromChargeIntent } from '../../../utils/warehouse.utils';
import { CardType, CheckoutState, PaymentMethod } from '../checkout/checkout.page';

@Component({
  selector: 'app-confirm-payment',
  standalone: true,
  imports: [
    ButtonComponent,
    ButtonModule,
    CommonModule,
    CurrencyFormatterPipe,
    RecaptchaV3Module,
    RetryableSectionComponent,
    RouterModule,
    TranslocoPipe
  ],
  templateUrl: './confirm-payment.page.html',
  styleUrl: './confirm-payment.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmPaymentPage implements OnInit, OnDestroy {
  currency = constants.defaultCurrency;
  data: CreatePaymentIntentData;
  errorLoading = false;
  loading = false;
  monthlyInstallments: {
    amount: number;
    installments: PaymentIntentExternalApportionInstallments;
  } | null;
  paying = false;
  paymentMethod: PaymentMethod;
  spotPayment: {
    card: {
      amount: number;
      selectedType: CardType | null;
    },
    debit: {
      amount: number;
      selected: boolean;
    },
    mortgage: {
      amount: number;
      selected: boolean;
    },
  } | null;
  state: CheckoutState;
  totalAmount: number;
  private viewDestroyed = new Subject<void>();

  constructor(
    private accountsService: AccountsService,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private chargeIntentsService: ChargeIntentsService,
    private errorReportingService: ErrorReportingService,
    private dataStorageService: DataStorageService,
    private homeAssistancePlansService: HomeAssistancePlansService,
    private location: Location,
    private paymentIntentsService: PaymentIntentsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private seoService: SeoService,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    const source = this.activatedRoute.snapshot.queryParamMap.get('source');
    const resourceIds = this.activatedRoute.snapshot.queryParamMap.getAll('resourceIds');
    const resourceSubtypes = this.activatedRoute.snapshot.queryParamMap.getAll('resourceSubtypes');
    const resourceType = this.activatedRoute.snapshot.queryParamMap.get('resourceType');
    const paymentMethod = this.activatedRoute.snapshot.queryParamMap.get('paymentMethod');
    const spotPaymentCard = this.activatedRoute.snapshot.queryParamMap.get('spotPaymentCard') === 'true';
    const spotPaymentCardAmount = +(this.activatedRoute.snapshot.queryParamMap.get('spotPaymentCardAmount') ?? '0');
    const spotPaymentCardType = this.activatedRoute.snapshot.queryParamMap.get('spotPaymentCardType');
    const spotPaymentDebit = this.activatedRoute.snapshot.queryParamMap.get('spotPaymentDebit') === 'true';
    const spotPaymentDebitAmount = +(this.activatedRoute.snapshot.queryParamMap.get('spotPaymentDebitAmount') ?? '0');
    const spotPaymentMortgage = this.activatedRoute.snapshot.queryParamMap.get('spotPaymentMortgage') === 'true';
    const spotPaymentMortgageAmount = +(this.activatedRoute.snapshot.queryParamMap.get('spotPaymentMortgageAmount') ?? '0');
    const monthlyInstallmentAmount = +(this.activatedRoute.snapshot.queryParamMap.get('monthlyInstallmentAmount') ?? '0');
    const monthlyInstallments = +(this.activatedRoute.snapshot.queryParamMap.get('monthlyInstallments') ?? '0');
    if (!source ||
      !['ecommerce', 'home-assistance', 'moving', 'remodeling', 'warehouse'].includes(source) ||
      !resourceIds.length ||
      !resourceType ||
      !paymentMethod ||
      (paymentMethod === 'spot-payment' && !spotPaymentDebit && !spotPaymentMortgage && !spotPaymentCard) ||
      (spotPaymentCard && (!spotPaymentCardAmount || !spotPaymentCardType)) ||
      (spotPaymentDebit && !spotPaymentDebitAmount) ||
      (spotPaymentMortgage && !spotPaymentMortgageAmount) ||
      (paymentMethod === 'monthly-installments' && !monthlyInstallments && !monthlyInstallmentAmount)
    ) {
      this.goBack();
      return;
    }
    this.paymentMethod = paymentMethod as PaymentMethod;
    if (this.paymentMethod === 'spot-payment') {
      this.spotPayment = {
        card: {
          amount: spotPaymentCardAmount,
          selectedType: spotPaymentCardType as CardType,
        },
        debit: {
          amount: spotPaymentDebitAmount,
          selected: spotPaymentDebit,
        },
        mortgage: {
          amount: spotPaymentMortgageAmount,
          selected: spotPaymentMortgage,
        },
      };
    } else if (this.paymentMethod === 'monthly-installments') {
      this.monthlyInstallments = {
        amount: monthlyInstallmentAmount,
        installments: monthlyInstallments as PaymentIntentExternalApportionInstallments,
      };
    }
    this.state = {
      resource: null,
      resourceIds,
      ...(resourceSubtypes.length && { resourceSubtypes }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resourceType: resourceType as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      source: source as any,
    };
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    this.location.back();
  }

  initialize(): void {
    this.errorLoading = false;
    this.loading = true;
    if (this.state.source === 'ecommerce') {
      combineLatest([
        this.accountsService.getAll(),
        this.shoppingCartsService.get(),
      ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: ([accounts, resource]) => this.validateEcommercePrerequisites(accounts, resource),
        error: (error) => this.handleInitializationError(error, 'validate-ecommerce-prerequisites'),
      });
    } else if (this.state.source === 'home-assistance') {
      combineLatest([
        this.accountsService.getAll(),
        this.homeAssistancePlansService.get(this.state.resourceIds[0]),
      ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: ([accounts, resource]) => this.validateHomeAssistancePrerequisites(accounts, resource),
        error: (error) => this.handleInitializationError(error, 'validate-home-assistance-prerequisites'),
      });
    } else if (this.state.source === 'moving' || this.state.source === 'remodeling' || this.state.source === 'warehouse') {
      combineLatest([
        this.accountsService.getAll(),
        this.chargeIntentsService.get(this.state.resourceIds[0]),
      ]).pipe(takeUntil(this.viewDestroyed)).subscribe({
        next: ([accounts, chargeIntent]) => this.validateChargeIntentPrerequisites(accounts, chargeIntent),
        error: (error) => this.handleInitializationError(error, 'validate-moving-prerequisites'),
      });
    } else {
      this.goBack();
    }
  }

  async pay(): Promise<void> {
    this.paying = true;
    let step = '';
    try {
      step = 'get-local-user';
      const localUser = await firstValueFrom(this.dataStorageService.get<LocalUser>(DataKey.LocalUser));
      if (!localUser) {
        throw new Error('Local user not found');
      }
      if (this.data.apportions.external.length && !this.data.apportions.internal.length) {
        step = 'get-external-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('paymentIntentsRouter/createExternal'));
        step = 'create-external-payment-intent';
        window.location.href = await this.paymentIntentsService.createExternal({ ...this.data, recaptchaToken }).then((response) => response.redirectUrl);
      } else if (this.data.apportions.internal.length && !this.data.apportions.external.length) {
        step = 'get-internal-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('paymentIntentsRouter/createInternal'));
        step = 'create-internal-payment-intent';
        const id = await this.paymentIntentsService.createInternal({ ...this.data, recaptchaToken }).then((response) => response.id);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['payment-gateway', 'payment-result', id]);
      } else {
        step = 'get-hybrid-recaptcha-token';
        const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('paymentIntentsRouter/createHybrid'));
        step = 'create-hybrid-payment-intent';
        window.location.href = await this.paymentIntentsService.createHybrid({ ...this.data, recaptchaToken }).then((response) => response.redirectUrl);
      }
    } catch (error) {
      this.toastService.showError({ title: 'Error', description: 'Ocurrió un error al procesar el pago. Por favor, inténtalo más tarde.' });
      this.errorReportingService.log('CheckoutPage.pay()', step, error);
      this.paying = false;
    }
  }

  private getPaymentOption(): PaymentIntentExternalApportionOption {
    if (this.spotPayment?.card.selectedType) {
      return this.spotPayment.card.selectedType;
    }
    if (this.monthlyInstallments?.installments) {
      return `credit-${this.monthlyInstallments.installments}` as PaymentIntentExternalApportionOption;
    }
    return 'debit';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleInitializationError(error: any, step: string): void {
    this.errorReportingService.log('ConfirmPayment.initialize()', step, error);
    this.goBack();
    this.errorLoading = true;
    this.loading = false;
  }

  private validateEcommercePrerequisites(accounts: Account[], shoppingCartDetail: ShoppingCartDetail | null): void {
    if (this.paying) {
      return;
    }
    if (!shoppingCartDetail) {
      return this.handleInitializationError(new Error('Shopping cart not found'), 'handle-ecommerce-initialization');
    }
    if (this.spotPayment?.mortgage.selected) {
      return this.handleInitializationError(new Error('Mortgage not supported for ecommerce'), 'handle-ecommerce-initialization');
    }
    const apportions = this.initializeApportions();
    this.totalAmount = 0;
    if (this.spotPayment?.card.selectedType) {
      this.totalAmount += this.spotPayment.card.amount;
      apportions.external.push({
        amount: this.spotPayment.card.amount,
        channel: 'getnet',
        installments: 1,
        option: this.spotPayment.card.selectedType,
      });
    }
    if (this.spotPayment?.debit.selected) {
      this.totalAmount += this.spotPayment.debit.amount;
      const debitAccount = accounts.find((account) => account.type === 'debit') ?? null;
      if (!debitAccount) {
        return this.handleInitializationError(new Error('Debit account not found'), 'handle-ecommerce-initialization');
      }
      if (debitAccount.balance < this.spotPayment.debit.amount) {
        return this.handleInitializationError(new Error('Debit account balance less than apportion amount'), 'handle-ecommerce-initialization');
      }
      apportions.internal.push({
        amount: this.spotPayment.debit.amount,
        fromAccountId: debitAccount.id,
        fromAccountType: debitAccount.type
      });
    }
    if (this.monthlyInstallments?.installments) {
      this.totalAmount += this.monthlyInstallments.amount;
      apportions.external.push({
        amount: this.monthlyInstallments.amount,
        channel: 'getnet',
        installments: this.monthlyInstallments.installments,
        option: 'credit',
      });
    }
    const paymentOption = this.getPaymentOption();
    const subtotal = shoppingCartDetail.items
      .filter((item) => this.state.resourceIds.includes(item.resourceId))
      .reduce((acc, item) => acc + ((item.resource ? item.resource.pricing[paymentOption].price : 0) * item.quantity), 0);
    if (normalizeFloat(this.totalAmount) !== normalizeFloat(subtotal + shoppingCartDetail.shippingCost)) {
      return this.handleInitializationError(new Error(`Payment amounts do not match for payment option ${paymentOption}: ${normalizeFloat(this.totalAmount)} != ${normalizeFloat(subtotal + shoppingCartDetail.shippingCost)}`), 'handle-ecommerce-initialization');
    }
    this.data = {
      apportions,
      resourceIds: this.state.resourceIds,
      resourceType: 'product',
      source: 'ecommerce',
      recaptchaToken: '',
    };
    this.loading = false;
  }

  private validateHomeAssistancePrerequisites(accounts: Account[], homeAssistancePlan: HomeAssistancePlan | null): void {
    if (this.paying) {
      return;
    }
    if (this.state.source !== 'home-assistance') {
      return this.handleInitializationError(new Error('Invalid source'), 'handle-home-assistance-initialization');
    }
    if (!homeAssistancePlan) {
      return this.handleInitializationError(new Error('Home assistance plan not found'), 'handle-home-assistance-initialization');
    }
    if (this.spotPayment?.mortgage.selected) {
      return this.handleInitializationError(new Error('Mortgage not supported for home assistance'), 'handle-home-assistance-initialization');
    }
    const apportions = this.initializeApportions();
    this.totalAmount = 0;
    if (this.spotPayment?.card.selectedType) {
      this.totalAmount += this.spotPayment.card.amount;
      apportions.external.push({
        amount: this.spotPayment.card.amount,
        channel: 'getnet',
        installments: 1,
        option: this.spotPayment.card.selectedType,
      });
    }
    if (this.spotPayment?.debit.selected) {
      this.totalAmount += this.spotPayment.debit.amount;
      const debitAccount = accounts.find((account) => account.type === 'debit') ?? null;
      if (!debitAccount) {
        return this.handleInitializationError(new Error('Debit account not found'), 'handle-ecommerce-initialization');
      }
      if (debitAccount.balance < this.spotPayment.debit.amount) {
        return this.handleInitializationError(new Error('Debit account balance less than apportion amount'), 'handle-ecommerce-initialization');
      }
      apportions.internal.push({
        amount: this.spotPayment.debit.amount,
        fromAccountId: debitAccount.id,
        fromAccountType: debitAccount.type
      });
    }
    if (this.monthlyInstallments?.installments) {
      this.totalAmount += this.monthlyInstallments.amount;
      apportions.external.push({
        amount: this.monthlyInstallments.amount,
        channel: 'getnet',
        installments: this.monthlyInstallments.installments,
        option: 'credit',
      });
    }
    const paymentOption = this.getPaymentOption();
    if (normalizeFloat(this.totalAmount) !== homeAssistancePlan.pricing.external[this.state.resourceSubtypes[0]][paymentOption].price) {
      return this.handleInitializationError(new Error(`Payment amounts do not match for payment option ${paymentOption}`), 'handle-home-assistance-initialization');
    }
    this.data = {
      apportions,
      resourceIds: this.state.resourceIds,
      resourceSubtypes: this.state.resourceSubtypes,
      resourceType: 'home-assistance-plan',
      source: 'home-assistance',
      recaptchaToken: '',
    };
    this.loading = false;
    this.analyticsService.logHomeAssistanceEvent({ step: 'confirm_payment', plan: this.state.resourceIds[0], duration: this.state.resourceSubtypes[0] });
  }

  private validateChargeIntentPrerequisites(accounts: Account[], chargeIntent: ChargeIntent | null): void {
    if (this.paying) {
      return;
    }
    if (this.state.source !== 'moving' && this.state.source !== 'remodeling' && this.state.source !== 'warehouse') {
      return this.handleInitializationError(new Error('Invalid source'), `handle-${this.state.source}-initialization`);
    }
    if (!chargeIntent) {
      return this.handleInitializationError(new Error(`${toCapitalizeCase(this.state.source)} charge intent not found`), `handle-${this.state.source}-initialization`);
    }
    if (!chargeIntent.pricing) {
      return this.handleInitializationError(new Error(`${toCapitalizeCase(this.state.source)} charge intent pricing not found`), `handle-${this.state.source}-initialization`);
    }
    if (this.spotPayment?.mortgage.selected && this.state.source !== 'remodeling') {
      return this.handleInitializationError(new Error(`Mortgage not supported for ${this.state.source}`), `handle-${this.state.source}-initialization`);
    }
    const apportions = this.initializeApportions();
    this.totalAmount = 0;
    if (this.spotPayment?.card.selectedType) {
      this.totalAmount += this.spotPayment.card.amount;
      apportions.external.push({
        amount: this.spotPayment.card.amount,
        channel: 'getnet',
        installments: 1,
        option: this.spotPayment.card.selectedType,
      });
    }
    if (this.spotPayment?.debit.selected) {
      this.totalAmount += this.spotPayment.debit.amount;
      const debitAccount = accounts.find((account) => account.type === 'debit') ?? null;
      if (!debitAccount) {
        return this.handleInitializationError(new Error('Debit account not found'), 'handle-ecommerce-initialization');
      }
      if (debitAccount.balance < this.spotPayment.debit.amount) {
        return this.handleInitializationError(new Error('Debit account balance less than apportion amount'), 'handle-ecommerce-initialization');
      }
      apportions.internal.push({
        amount: this.spotPayment.debit.amount,
        fromAccountId: debitAccount.id,
        fromAccountType: debitAccount.type
      });
    }
    if (this.spotPayment?.mortgage.selected) {
      this.totalAmount += this.spotPayment.mortgage.amount;
      const mortgageAccount = accounts.find((account) => account.type === 'mortgage') ?? null;
      if (!mortgageAccount) {
        return this.handleInitializationError(new Error('Mortgage account not found'), 'handle-ecommerce-initialization');
      }
      if (mortgageAccount.balance < this.spotPayment.debit.amount) {
        return this.handleInitializationError(new Error('Mortgage account balance less than apportion amount'), 'handle-ecommerce-initialization');
      }
      apportions.internal.push({
        amount: this.spotPayment.mortgage.amount,
        fromAccountId: mortgageAccount.id,
        fromAccountType: mortgageAccount.type
      });
    }
    if (this.monthlyInstallments?.installments) {
      this.totalAmount += this.monthlyInstallments.amount;
      apportions.external.push({
        amount: this.monthlyInstallments.amount,
        channel: 'getnet',
        installments: this.monthlyInstallments.installments,
        option: 'credit',
      });
    }
    const paymentOption = this.getPaymentOption();
    if (normalizeFloat(this.totalAmount) !== chargeIntent.pricing.external[paymentOption].price) {
      return this.handleInitializationError(new Error(`Payment amounts do not match for payment option ${paymentOption}`), `handle-${this.state.source}-initialization`);
    }
    this.data = {
      apportions,
      resourceIds: this.state.resourceIds,
      resourceType: 'charge-intent',
      source: this.state.source,
      recaptchaToken: '',
    };
    this.loading = false;
    if (this.state.source === 'moving') {
      this.analyticsService.logMovingEvent({ step: 'confirm_payment', service: getMovingServiceFromChargeIntent(chargeIntent) });
    } else if (this.state.source === 'remodeling') {
      this.analyticsService.logRemodelingEvent({ step: 'confirm_payment', service: getRemodelingServiceFromChargeIntent(chargeIntent) });
    } else if (this.state.source === 'warehouse') {
      this.analyticsService.logWarehouseEvent({ step: 'confirm_payment', service: getWarehouseServiceFromChargeIntent(chargeIntent) });
    }
  }

  private initializeApportions(): { external: PaymentIntentExternalApportion[]; internal: PaymentIntentInternalApportion[]; } {
    return { external: [], internal: [], };
  }
}
