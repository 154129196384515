import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

interface ToastConfig {
  action?: () => unknown;
  actionLabel?: string;
  description: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private messageService: MessageService,
  ) {}

  showError(config: ToastConfig, duration?: number): void {
    this.showRaw('error', config, duration);
  }

  showInfo(config: ToastConfig, duration?: number): void {
    this.showRaw('info', config, duration);
  }

  showSuccess(config: ToastConfig, duration?: number): void {
    this.showRaw('success', config, duration);
  }

  showWarning(config: ToastConfig, duration?: number): void {
    this.showRaw('warn', config, duration);
  }

  private showRaw(severity: string, config: ToastConfig, duration: number = 6000): void {
    const { action, actionLabel, description, title } = config;
    this.messageService.add({
      data: {
        actionLabel,
        action,
        description,
        title,
      },
      life: duration,
      severity
    });
  }
}
