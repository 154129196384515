import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { StepperModule } from 'primeng/stepper';
import { firstValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { CircularProgressComponent } from '../../../components/circular-progress/circular-progress.component';
import { LocalProjectSimulation } from '../../../models/local-project-simulation.model';
import { DataKey, DataStorageService } from '../../../services/data-storage/data-storage.service';
import { SeoService } from '../../../services/seo/seo.service';
import { getImageByType } from '../../../utils/project-simulator.utils';

@Component({
  selector: 'app-step-5',
  standalone: true,
  imports: [
    ButtonComponent,
    CircularProgressComponent,
    CommonModule,
    FormsModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    ReactiveFormsModule,
    RouterModule,
    StepperModule,
    TranslocoModule,
  ],
  templateUrl: './step-5.page.html',
  styleUrl: './step-5.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class Step5Page implements OnInit {
  bathroomForm: FormGroup<{
    surfaceAreaDetail: FormArray<FormControl<string | null>>;
  }>;
  integralRemodelingForm: FormGroup<{
    numberOfBathrooms: FormControl<number | null>;
    numberOfRooms: FormControl<number | null>;
    surfaceArea: FormControl<string | null>;
  }>;
  kitchenForm: FormGroup<{
    surfaceArea: FormControl<string | null>;
  }>;
  projectSimulation: LocalProjectSimulation | null;
  selectedImage = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private location: Location,
    private router: Router,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  async ngOnInit(): Promise<void> {
    await this.initializeForm();
    if (!this.projectSimulation || !this.projectSimulation.completedSteps.includes(1) || !this.projectSimulation.completedSteps.includes(2) || !this.projectSimulation.completedSteps.includes(3) || !this.projectSimulation.completedSteps.includes(4)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigate(['/project-simulator/step-1']);
      return;
    }
    const { type } = this.projectSimulation;
    this.selectedImage = getImageByType(type);
    if (type === 'bathroom') {
      this.bathroomForm.patchValue({
        surfaceAreaDetail: this.projectSimulation.surfaceAreaDetail?.map((value) => value ? value.toString() : null) ?? [],
      });
    } else if (type === 'kitchen') {
      this.kitchenForm.patchValue({
        surfaceArea: this.projectSimulation.surfaceArea ? this.projectSimulation.surfaceArea.toString() : null,
      });
    } else if (type === 'integral-remodeling') {
      this.integralRemodelingForm.patchValue({
        numberOfBathrooms: this.projectSimulation.numberOfBathrooms ?? null,
        numberOfRooms: this.projectSimulation.numberOfRooms ?? null,
        surfaceArea: this.projectSimulation.surfaceArea ? this.projectSimulation.surfaceArea.toString() : null,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get isInvalid(): boolean {
    if (this.projectSimulation?.type === 'kitchen') {
      return this.kitchenForm.invalid;
    } else if (this.projectSimulation?.type === 'integral-remodeling') {
      return this.integralRemodelingForm.invalid;
    } else if (this.projectSimulation?.type === 'bathroom') {
      return this.bathroomForm.invalid;
    }
    return true;
  }

  goBack(): void {
    this.location.back();
  }

  async initializeForm(): Promise<void> {
    this.projectSimulation = await firstValueFrom(this.dataStorageService.get<LocalProjectSimulation>(DataKey.ProjectSimulation));
    if (this.projectSimulation && this.projectSimulation.type === 'bathroom') {
      this.bathroomForm = new FormGroup({
        surfaceAreaDetail: new FormArray(this.createItems<string>(this.projectSimulation.numberOfBathrooms)),
      });
    } else if (this.projectSimulation && this.projectSimulation.type === 'integral-remodeling') {
      this.integralRemodelingForm = new FormGroup({
        numberOfBathrooms: new FormControl<number | null>(null, [Validators.required, Validators.min(1), Validators.max(9)]),
        numberOfRooms: new FormControl<number | null>(null, [Validators.required, Validators.min(1), Validators.max(19)]),
        surfaceArea: new FormControl<string | null>(null, [Validators.required, Validators.min(10), Validators.max(999)]),
      });
    } else if (this.projectSimulation && this.projectSimulation.type === 'kitchen') {
      this.kitchenForm = new FormGroup({
        surfaceArea: new FormControl<string | null>(null, [Validators.required, Validators.min(2), Validators.max(99)]),
      });
    }
  }

  async onSubmit(): Promise<void> {
    const simulation = await firstValueFrom(this.dataStorageService.get<LocalProjectSimulation>(DataKey.ProjectSimulation));
    if (!simulation) {
      return;
    }
    if (!simulation.completedSteps.includes(5)) {
      simulation.completedSteps.push(5);
    }
    if (simulation.type === 'bathroom') {
      if (!simulation.surfaceAreaDetail) {
        simulation.surfaceAreaDetail = [];
      }
      if (this.bathroomForm.valid) {
        simulation.surfaceArea = 0;
        simulation.surfaceAreaDetail = [];
        for (const control of this.bathroomForm.controls.surfaceAreaDetail.controls) {
          if (!control.value) {
            return;
          }
          const value = +control.value;
          simulation.surfaceArea = simulation.surfaceArea + value;
          simulation.surfaceAreaDetail.push(value);
        }
      }
    }
    if (simulation.type === 'kitchen' && this.kitchenForm.valid) {
      simulation.surfaceArea = +(this.kitchenForm.controls.surfaceArea.value as string);
    }
    if (simulation.type === 'integral-remodeling' && this.integralRemodelingForm.valid) {
      simulation.surfaceArea = +(this.integralRemodelingForm.controls.surfaceArea.value as string);
      simulation.numberOfBathrooms = this.integralRemodelingForm.controls.numberOfBathrooms.value as number;
      simulation.numberOfRooms = this.integralRemodelingForm.controls.numberOfRooms.value as number;
    }
    this.dataStorageService.set(DataKey.ProjectSimulation, simulation);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/project-simulator/step-6']);
  }

  private createItems<T>(items: number): FormControl[] {
    const array = [];
    for (let i = 0; i < items; i++) {
      array.push(new FormControl<T | null>(null, [Validators.required, Validators.min(2), Validators.max(99)]));
    }
    return array;
  }
}