import { Injectable } from '@angular/core';
import {
  ContactForm,
  ContactFormSubject,
  SubmitConsumerCreditDiscountData,
  SubmitContactEcommerceFormData,
  SubmitContactHomeAssistanceFormData,
  SubmitContactMovingFormData,
  SubmitContactPlatformFormData,
  SubmitContactRemodelingFormData,
  SubmitContactWarehouseFormData,
  SubmitInvoiceAgainData,
  SubmitInvoiceData,
} from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormsServiceApi } from './forms.service.api';
import { FormsServiceMock } from './forms.service.mock';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? FormsServiceMock : FormsServiceApi,
})
export abstract class FormsService {
  abstract getContactForms(subject: ContactFormSubject): Observable<ContactForm[]>;
  abstract submitConsumerCreditDiscount(data: SubmitConsumerCreditDiscountData): Promise<void>;
  abstract submitContactEcommerce(data: SubmitContactEcommerceFormData): Promise<void>;
  abstract submitContactHomeAssistance(data: SubmitContactHomeAssistanceFormData): Promise<void>;
  abstract submitContactMoving(data: SubmitContactMovingFormData): Promise<void>;
  abstract submitContactPlatform(data: SubmitContactPlatformFormData): Promise<void>;
  abstract submitContactRemodeling(data: SubmitContactRemodelingFormData): Promise<void>;
  abstract submitContactWarehouse(data: SubmitContactWarehouseFormData): Promise<void>;
  abstract submitInvoice(data: SubmitInvoiceData): Promise<string>;
  abstract submitInvoiceAgain(data: SubmitInvoiceAgainData): Promise<void>;
}
