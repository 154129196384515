import { Injectable } from '@angular/core';
import { CreateRemodelingOrderData, RemodelingOrder } from '@homein-hogar-server';
import * as faker from 'faker';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { constants } from '../../constants';
import { normalizeFloat } from '../../utils/numbers.utils';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { LEAD } from './remodeling-orders.mock';
import { RemodelingOrdersService } from './remodeling-orders.service';

@Injectable()
export class RemodelingOrdersServiceMock implements RemodelingOrdersService {
  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  async create(data: CreateRemodelingOrderData): Promise<string> {
    const userId = await firstValueFrom(this.sessionsService.getSession()).then((session) => session?.userId);
    if (!userId) {
      throw new Error('User id not found.');
    }
    const subtotal = faker.datatype.number({ min: normalizeFloat(data.budget * 0.75), max: data.budget });
    const tax = normalizeFloat(subtotal * constants.tax);
    return this.mockService.createRemodelingOrder({
      additionalData: {
        partnerOrderId: LEAD.id,
      },
      contractSigned: false,
      currency: 'MXN',
      discounts: 0,
      endAt: null,
      paid: false,
      paymentIntentIds: [],
      pendingChargeIntentId: null,
      projectManager: {
        code: LEAD.code,
        email: LEAD.email,
        name: LEAD.name,
        phoneNumber: LEAD.phoneNumber,
      },
      startAt: null,
      status: 'lead-created',
      statusDetail: null,
      subtotal,
      tax,
      totalAmount: normalizeFloat(subtotal + tax),
      userBudget: data.budget,
      userId,
    });
  }

  get(id: string): Observable<RemodelingOrder | null> {
    return this.mockService.getRemodelingOrder(id);
  }

  getAll(): Observable<RemodelingOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap(session => {
      if (session) {
        return this.mockService.getRemodelingOrders().pipe(map((movingOrders) => {
          return movingOrders.filter((movingOrder) => movingOrder.userId === session.userId);
        }));
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<RemodelingOrder | null> {
    return this.mockService.getLastRemodelingOrderInProgress();
  }
}
