import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { interval, Subject, Subscription, takeUntil } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { SeoService } from '../../../services/seo/seo.service';
import { SessionsService } from '../../../services/sessions/sessions.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';

const minimumTimeBetweenEmailVerificationSends = 1000 * 60 * 5;

interface Timer {
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-send-verify-email',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MessageModule,
    ProgressSpinnerModule,
    RetryableSectionComponent,
    RouterLink,
    TranslocoPipe,
  ],
  templateUrl: './send-verify-email.page.html',
  styleUrl: './send-verify-email.page.scss',
})
export class SendVerifyEmailPage implements OnInit, OnDestroy {
  canSendEmail = false;
  error = false;
  loading = false;
  sending = false;
  timeLeftLabel = '';
  timerSubscription: Subscription | null = null;
  user: User | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private router: Router,
    private seoService: SeoService,
    private sessionsService: SessionsService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  initialize(): void {
    this.loading = true;
    this.error = false;
    this.usersService.getCurrentUser().pipe(takeUntil(this.viewDestroyed)).subscribe({
      next: (user) => {
        this.user = user;
        if (!user || user.emailValidated) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigate(['/enrollment']);
          return;
        }
        if (user.lastEmailVerificationSentAt) {
          const timePassed = Date.now() - user.lastEmailVerificationSentAt.getTime();
          if (timePassed < minimumTimeBetweenEmailVerificationSends) {
            this.canSendEmail = false;
            this.startTimer(timePassed);
          } else {
            this.canSendEmail = true;
          }
        } else {
          this.canSendEmail = true;
        }
        this.loading = false;
      },
      error: (error) => {
        this.errorReportingService.log('SendVerifyEmailPage.loadInitialData()', 'get-current-user', error);
        this.loading = false;
        this.error = true;
      }
    });
  }

  async sendEmail(): Promise<void> {
    this.sending = true;
    try {
      const origin = this.activatedRoute.snapshot.queryParamMap.get('origin');
      const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
      await this.sessionsService.sendEmailVerification({
        ...(origin && { origin }),
        ...(redirectTo && { redirectTo }),
      });
      this.canSendEmail = false;
      this.startTimer(0);
      this.toastService.showSuccess({ title: 'Correo enviado', description: 'Se ha reenviado el correo de verificación.' });
    } catch (error) {
      this.errorReportingService.log('SendVerifyEmailPage.sendEmail()', 'send-email-verification', error);
      this.toastService.showError({ title: 'Error', description: 'Ocurrió un error al enviar el correo de verificación. Por favor, inténtalo más tarde.' });
    } finally {
      this.sending = false;
    }
  }

  private startTimer(timePassed: number): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    const timeLeft = minimumTimeBetweenEmailVerificationSends - timePassed;
    const timer = {
      minutes: Math.floor(timeLeft / 1000 / 60),
      seconds: Math.floor(timeLeft / 1000) % 60,
    };
    this.updateTimeLeftLabel(timer);
    this.timerSubscription = interval(1000).subscribe({
      next: () => {
        timer.seconds--;
        if (timer.seconds < 0) {
          timer.seconds = 59;
          timer.minutes--;
        }
        if (timer.minutes < 0) {
          timer.minutes = 0;
          timer.seconds = 0;
          this.canSendEmail = true;
        }
        this.updateTimeLeftLabel(timer);
      }
    });
  }

  private updateTimeLeftLabel(timer: Timer): void {
    this.timeLeftLabel = `${timer.minutes.toString().padStart(2, '0')}:${timer.seconds.toString().padStart(2, '0')}`;
  }
}
