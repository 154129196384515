@if (page === 'private-site') {
  @if (shouldAskForTermsOfServiceAcceptance) {
    <div class="private-site-container">
      <div class="private-content-container">
        <div class="content">
          <i class="icon-circle-checkmark icon"></i>
          <div class="disclaimer-text-container">
            <div class="header-content">
              <i class="icon-circle-checkmark icon"></i>
              <span class="disclaimer">Servicios entregados por Homein y proveedores asociados.</span>
            </div>
            <span class="sub-disclaimer">Banco Santander no es responsable por los servicios ofrecidos en Mundo Hogar.</span>
            <a class="clickeable-text" routerLink="/what-is-mundo-hogar" [id]="'btn-link-rDKZtrW1-' + page">Saber más</a>
          </div>
        </div>
        <div class="footer">
          <app-button innerId="btn-DSE04gb7" label="Entendido" (action)="hideBanner()" />
        </div>
      </div>
    </div>
  }
} @else {
  <div class="homein-services">
    <div class="services-container">
      <div class="disclaimer-container">
        <div class="first-content">
          <i class="icon-circle-checkmark"></i>
          @if (['home-assistance', 'moving', 'remodeling', 'warehouse'].includes(page)) {
            <p class="text-container">
              <span class="disclaimer">Servicio entregado por</span>
              @if (page === 'warehouse') {
                <img src="assets/images/mudango-logo.svg" class="moving-logo logo" alt="Logo Mudango" />
                <span class="disclaimer">la empresa N°1 de bodegas en México</span>
              } @else if (page === 'moving'){
                <img src="assets/images/mudango-logo.svg" class="moving-logo logo" alt="Logo Mudango" />
                <span class="disclaimer">la empresa N°1 de mudanzas en México</span>
              } @else if (page === 'remodeling') {
                <img src="assets/images/atr-logo-cafe.png" class="atr-logo logo" alt="Logo Aquí tu remodelación" />
                <span class="disclaimer">la empresa N°1 de remodelaciones en México</span>
              } @else if (page === 'home-assistance') {
                <img src="assets/images/ike-logo.svg" class="ike-logo logo" alt="Logo ike" />
                <span class="disclaimer">la empresa N°1 de asistencias en México</span>
              }
            </p>
          } @else {
            <p class="text-container">
              <span class="disclaimer">Servicios entregados por Homein y proveedores asociados.</span>
            </p>
          }
        </div>
        <div class="second-content">
          <span class="sub-disclaimer">Banco Santander no es responsable por los servicios ofrecidos en Mundo Hogar.</span>
          <a class="clickeable-text" routerLink="/what-is-mundo-hogar">Saber más</a>
        </div>
      </div>
    </div>
  </div>
}