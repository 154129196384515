import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { WarehousePurchaseDetailsComponent } from '../../../../components/warehouse-purchase-details/warehouse-purchase-details.component';

@Component({
  selector: 'app-warehouse-details',
  standalone: true,
  imports: [
    CommonModule,
    WarehousePurchaseDetailsComponent,
  ],
  templateUrl: './warehouse-details.page.html',
  styleUrl: './warehouse-details.page.scss',
})
export class WarehouseDetailsPage {}
