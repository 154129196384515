import { User } from '@homein-hogar-server';

export const USERS_MOCK: User[] = [
  {
    bankValidated: true,
    birthDate: new Date(),
    createdAt: new Date(),
    email: 'client1@test.com',
    emailValidated: true,
    enrollmentStatus: 'done',
    fatherLastName: 'Doe',
    id: '6xQGvcIhSwSkSH938uaC',
    lastEmailVerificationSentAt: null,
    lastSignInAt: new Date(),
    motherLastName: 'Perez',
    name: 'John',
    phoneNumber: '+521234567890',
    profileDataCompleted: true,
    provider: 'email',
    status: 'active',
    statusDetail: null,
    uid: '1982cc94-427b-46ac-9ffd-cafd12ac948d',
    updatedAt: new Date(),
  },
  {
    bankValidated: true,
    birthDate: new Date(),
    createdAt: new Date(),
    email: 'client2@test.com',
    emailValidated: true,
    enrollmentStatus: 'done',
    fatherLastName: 'Alcahuan',
    id: 'hf3HuND7RALmJIFOoKew',
    lastEmailVerificationSentAt: null,
    lastSignInAt: new Date(),
    motherLastName: 'Pedilla',
    name: 'Ignacio',
    phoneNumber: '+521234567898',
    profileDataCompleted: true,
    provider: 'email',
    status: 'active',
    statusDetail: null,
    uid: 'a6169ca4-da02-4d06-8f82-450b15697d22',
    updatedAt: new Date(),
  }
];
