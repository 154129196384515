import { EventEmitter, Injectable } from '@angular/core';
import { SendEmailVerificationData, SendPasswordRecoveryData } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionsServiceApi } from './sessions.service.api';
import { SessionsServiceMock } from './sessions.service.mock';

export interface Session {
  claims: {
    [key: string]: unknown;
  };
  token: string;
  userId: string;
}

export type SessionProvider = 'apple' | 'email' | 'google';

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? SessionsServiceMock : SessionsServiceApi,
})
export abstract class SessionsService {
  abstract getAccessToken(): Promise<string | null>;
  abstract getSession(): Observable<Session | null>;
  abstract onSignIn(): EventEmitter<void>;
  abstract onSignOut(): EventEmitter<void>;
  abstract sendEmailVerification(data: SendEmailVerificationData): Promise<void>;
  abstract sendPasswordRecovery(data: SendPasswordRecoveryData): Promise<void>;
  abstract signIn(options: { provider: 'apple'; } | { provider: 'google'; } | { data: { email: string; password: string; }; provider: 'email'; }, recaptchaTokenGenerator: () => Promise<string>): Promise<void>;
  abstract signOut(): Promise<void>;
  abstract signUp(options: { data: { email: string; password: string; }; provider: 'email'; }, recaptchaTokenGenerator: () => Promise<string>): Promise<void>;
  abstract updatePassword(password: string, resetCode?: string): Promise<void>;
  abstract verifyEmail(token: string): Promise<{ origin?: string; redirectTo: string; } | null>;
  abstract verifyPasswordResetCode(code: string): Promise<void>;
}
