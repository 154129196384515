<section class="project-simulator-container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">Atrás</span>
      <span class="mobile-text">Simula tu proyecto</span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">Simula el costo del proyecto que tienes en mente</h5>
      <app-circular-progress [currentStep]="6" [numberSteps]="6" stepTitle="¡Enviaremos tu simulación!" stepSubtitle="Completarás el proceso" />
    </div>
    <div class="bottom-container animate__fadeIn">
      <div class="project-simulation-types-container">
        <h2 class="title">¡Casi listo! Ingresa tu información</h2>
        <div class="input-container">
          <label for="full-name">Nombre completo</label>
          <input id="full-name" type="text" pInputText [formControl]="form.controls.fullName" />
        </div>
        <div class="input-container">
          <label for="email">Correo electrónico</label>
          <input id="email" type="email" pInputText [formControl]="form.controls.email" />
        </div>
        <div class="input-container">
          <label for="phone-number">Número de teléfono móvil*</label>
          <p-inputMask id="phone-number" [class.active]="isActive(form.controls.phoneNumber)" [formControl]="form.controls.phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 999?9" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" #phoneNumberInput />
          <small id="phone-number-help" [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">Ingresa un número de teléfono válido, debe comenzar con "52" y luego 10 dígitos.</small>
        </div>
        <div class="reminder-container">
          <app-reminder styleClass="alert" title="Importante" description="Se enviará la simulación vía WhatsApp, es importante ingresar un número telefónico válido al cual tengas acceso." />
        </div>
      </div>
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="Ilustración de la opción seleccionada" />
        <div class="continue-button-container">
          <app-button innerId="btn-A0srdZmb" label="Continuar" styleClass="primary expand" type="submit" [disabled]="form.invalid" />
        </div>
      </div>
    </div>
  </form>
</section>
@if (form.controls.phoneNumber.value) {
  <app-send-simulation-sidebar #sendSimulationSidebar [phoneNumber]="form.controls.phoneNumber.value" [sendingSimulation]="sendingSimulation" [simulationSent]="simulationSent" (sendSimulation)="sendSimulation()" (simulateAgain)="simulateAgain()" (changePhoneNumber)="changePhoneNumber()" />
}
