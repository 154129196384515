<p-sidebar class="plan-hogar-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [showCloseIcon]="false" [blockScroll]="true" [showCloseIcon]="false" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <h6 class="title">Qué incluye el {{ title }}</h6>
      <button class="custom-button-clear close" (click)="close()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="tabs-container">
      <app-retryable-section [error]="errorLoading" [loading]="loading" (retry)="initialize()">
        @if (homeAssistancePlan) {
          <p-accordion [multiple]="true" class="accordion-filters" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
            @for (detail of homeAssistancePlan.details; track $index) {
              <p-accordionTab class="accordion-tab" header="{{ detail.title }}" iconPos="end">
                <p class="subtitle">{{ detail.subtitle }}</p>
                <p class="description">{{ detail.description }}</p>
              </p-accordionTab>
            }
          </p-accordion>
        }
      </app-retryable-section>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="button-container">
      @if (homeAssistancePlan) {
        <app-button innerId="btn-07cKjaB2" label="Descargar cobertura y limitaciones" (action)="downloadFile()" styleClass="secondary" />
      }
    </div>
  </ng-template>
</p-sidebar>