import { Inject, Injectable } from '@angular/core';
import { UpdateUserData, User, UserEnrollment, UsersRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionsService } from '../sessions/sessions.service';
import { UsersService } from './users.service';

@Injectable()
export class UsersServiceApi implements UsersService {
  private client: CreateTRPCProxyClient<UsersRouter>;

  constructor(
    private sessionsService: SessionsService,
    @Inject('appFirestore') private firestore: FirestoreService,
  ) {
    this.client = createTRPCProxyClient<UsersRouter>(getTRPCClientOptions(`${environment.apiUrl}/users`, () => this.sessionsService.getAccessToken()));
  }

  get<T extends User | UserEnrollment = User>(id: string): Observable<T | null> {
    return this.firestore.doc<T>(`users/${id}`);
  }

  getCurrentUser<T extends User | UserEnrollment = User>(): Observable<T | null> {
    // TODO: find a better way to share subscriptions
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.doc<T>(`users/${session.userId}`);
      }
      return of(null);
    }));
  }

  updateUser(data: UpdateUserData): Promise<{ code: string; message: string; }> {
    return this.client.update.mutate(data);
  }
}
