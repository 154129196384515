import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { environment } from '../../../environments/environment';
import { formatPhoneNumber } from '../../utils/string.utils';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-send-simulation-sidebar',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ProgressSpinnerModule,
    SidebarModule,
    TranslocoModule,
  ],
  templateUrl: './send-simulation-sidebar.component.html',
  styleUrl: './send-simulation-sidebar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SendSimulationSidebarComponent implements OnInit, OnChanges {
  @Output() changePhoneNumber = new EventEmitter<void>();
  @Input({ required: true }) phoneNumber: string;
  @Output() sendSimulation = new EventEmitter<void>();
  @Input({ required: true }) sendingSimulation = false;
  @Output() simulateAgain = new EventEmitter<void>();
  @Input({ required: true }) simulationSent = false;
  isMobile = false;
  protected formattedPhoneNumber = '';
  protected platformPhoneNumber = environment.platformWhatsAppPhoneNumber;
  protected visible = false;

  ngOnInit(): void {
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['phoneNumber']?.currentValue) {
      this.formattedPhoneNumber = formatPhoneNumber(this.phoneNumber);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  close(): void {
    this.sendingSimulation = false;
    this.simulationSent = false;
    this.visible = false;
  }

  open(): void {
    this.visible = true;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }

  protected emitChangePhoneNumber(): void {
    this.changePhoneNumber.emit();
    this.close();
  }

  protected emitSimulateAgain(): void {
    this.simulateAgain.emit();
  }

  protected send(): void {
    this.sendSimulation.emit();
  }
}
