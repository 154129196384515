@if (loading) {
  <main class="warehouse-loading-page">
    <p-progressSpinner></p-progressSpinner>
  </main>
} @else {
  <section class="warehouse-main-section">
    <div class="main-container">
      <div class="left-container">
        <div class="title-container">
          <p class="on-title-text">Beneficios exclusivos para clientes <strong>Santander</strong></p>
          <h1 class="title">Bodegas en México Fácil y seguro</h1>
        </div>
        <div class="button-container">
          <app-button innerId="btn-gDXGaaES" label="Cotizar bodega" styleClass="primary" icon="warehouses" (action)="navigateToQuote()" />
        </div>
      </div>
      <div class="right-container">
        <img class="main-image" src="assets/illustrations/warehouse.svg" alt="Imagen principal de bodega" />
      </div>
    </div>
    <section class="terms-banner">
      <app-terms-banner page="warehouse" />
    </section>
    <section class="benefits-section">
      <div class="container">
        <div class="left-container">
          <img class="image" src="assets/illustrations/warehouseman-holding-box.svg" alt="Imagen de los beneficios de la remodelación" />
        </div>
        <div class="right-container">
          <div class="title-container">
            <h2 class="title">¿Por qué contratar Mudango Bodegas?</h2>
          </div>
          <div class="underlined-line"></div>
          <div class="benefits-container">
            <ol class="benefits">
              <li class="benefit"><i class="icon icon-pig-plus-sign"></i>Precio: Si tienes una cotización más económica, la igualamos</li>
              <li class="benefit"><i class="icon icon-relax-face"></i>Comodidad: Vamos por tus cosas con mudanzas 5 estrellas</li>
              <li class="benefit"><i class="icon icon-padlock-on-shield"></i>Seguridad: Centro moderno con seguridad 24/7</li>
            </ol>
            <div class="action-button-container">
              <app-button innerId="btn-hsgY0qum" label="Ver planes" styleClass="secondary expand" (action)="navigateToQuote()" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="opportunities-section">
      <div class="opportunities-container">
        <div class="opportunity">
          <div class="left-card-container">
            <h4 class="title">Te acompañamos junto a Mudango a tu nueva vida</h4>
            <app-button innerId="btn-C4COZu6k" label="Ver más" styleClass="secondary" (action)="navigateToQuote()" />
          </div>
          <div class="right-card-container">
            <img class="img" src="assets/images/young-family-moving-into-new-home.jpg" alt="Imagen de oportunidad" loading="lazy" />
          </div>
        </div>
        <div class="opportunity">
          <div class="left-card-container">
            <h4 class="title">Elige los m² que más te acomoden</h4>
            <app-button innerId="btn-ZOKOvJ9g" label="Cotizar" styleClass="secondary" (action)="navigateToQuote()" />
          </div>
          <div class="right-card-container">
            <img class="img" src="assets/images/storing-boxes.jpg" alt="Imagen de oportunidad" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
    <section class="how-does-it-work-section">
      <div class="left-column">
        <h2 class="title">¿Cómo funciona?</h2>
        <div class="underlined-line"></div>
        <img class="image" src="assets/images/woman-talking-smartphone-reading-document.jpg" alt="Imagen de cómo funciona" />
        <p class="description">Mudango te permite cotizar y reservar bodegas y mini bodegas en México de forma rápida y fácil, combinando seguridad, flexibilidad y precios accesibles. Puedes ver precios y hacer tu reserva en línea en segundos.</p>
      </div>
      <div class="right-column">
        <img class="image" src="assets/images/woman-talking-smartphone-reading-document.jpg" alt="Imagen de cómo funciona" />
      </div>
    </section>
    <section class="warehouses-and-mini-warehouses-section">
      <div class="container">
        <div class="left-container">
          <div class="title-container">
            <span class="title">¡También tenemos bodegas y mini bodegas!</span>
            <hr class="underline">
          </div>
        </div>
        <div class="right-container">
          <div class="feature-content">
            <i class="icon icon-checkmark-in-a-circle"></i>
            <div class="feature">
              <h4 class="title">Precios</h4>
              <p class="description">Tenemos los mejores precios para tu arriendo de bodega o mini bodega. Conoce nuestras bodegas flexibles, modernas y seguras. Cotiza con Mudango y no te arrepentirás.</p>
            </div>
          </div>
          <div class="feature-content">
            <i class="icon icon-checkmark-in-a-circle"></i>
            <div class="feature">
              <h4 class="title">Bodegas + Mudanza</h4>
              <p class="description">En Mudango Bodegas no solo te entregamos un arriendo seguro y a buen precio, sino que también te ofrecemos el traslado hacia y desde la bodega con nuestra solución de mudanzas ¿No la conoces?</p>
            </div>
          </div>
          <div class="feature-content">
            <i class="icon icon-checkmark-in-a-circle"></i>
            <div class="feature">
              <h4 class="title">Somos diferentes</h4>
              <p class="description">Los precios de nuestras bodegas varían según los m² reales que uses. Nuestras bodegas son flexibles y si retiras algunas cosas, el precio de tu arriendo disminuye.</p>
            </div>
          </div>
          <div class="feature-content">
            <i class="icon icon-checkmark-in-a-circle"></i>
            <div class="feature">
              <h4 class="title">Seguro adicional</h4>
              <p class="description">Nuestras bodegas tienen seguridad 24/7, pero para tu tranquilidad, contamos también con un seguro de incendio y robo adicional.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- TODO: Talk with business about this translation -->
    <p class="see-more">Para ver más información sobre nuestras bodegas, haz <a class="link">clic aquí</a></p>
    <section class="complements-warehouse-section">
      <div class="content-container">
        <h3 class="title">Complementa tu Bodega</h3>
        <p class="description">Si necesitas ayuda con el traslado hacia la bodega, puedes cotizar tu mudanza en Mudango en menos de 2 minutos y conseguir el servicio de bodega + mudanza que se adecúe a tus necesidades por el mejor precio.</p>
        <div class="action-button-container">
          <app-button innerId="btn-3KO0Ief2" label="Cotiza tu mudanza" styleClass="primary expand" (action)="navigateToQuote()" />
        </div>
      </div>
    </section>
    <article class="why-platform-article">
      <div class="title-container">
        <h2 class="title">¿Por qué Mundo Hogar?</h2>
        <hr class="underline">
        <div class="benefits-container">
          <div class="benefit">
            <i class="icon-monitor"></i>
            <div class="benefit-content">
              <h4 class="label">Todo en un mismo lugar</h4>
              <span class="description">Encuentra todo lo que necesitas para tu hogar, en un mismo portal 100% online.</span>
            </div>
          </div>
          <div class="benefit">
            <i class="icon-jewel"></i>
            <div class="benefit-content">
              <h4 class="label">Beneficios exclusivos</h4>
              <span class="description">Alternativas de financiamiento exclusivas para clientes Santander y mucho más.</span>
            </div>
          </div>
          <div class="benefit">
            <i class="icon-security-profile"></i>
            <div class="benefit-content">
              <h4 class="label">Los mejores proveedores</h4>
              <span class="description">Proveedores calificados, servicios garantizados y métodos de pagos seguros para ti.</span>
            </div>
          </div>
          <div class="benefit">
            <i class="icon-thumbs-up"></i>
            <div class="benefit-content">
              <h4 class="label">Simple y transparente</h4>
              <span class="description">Gestiona y haz el seguimiento online de tus proyectos en pocos clics.</span>
            </div>
          </div>
        </div>
        <div class="become-a-customer">
          <p class="customer-benefit">Beneficios exclusivos para clientes Santander. Si aún no eres cliente, haz <a class="customer-link" href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener">clic aquí.</a><p>
        </div>
      </div>
    </article>
    <section class="faq-article">
      <h2 class="title">Preguntas frecuentes</h2>
      <div class="faq-container">
        <p-accordion [multiple]="true" class="accordion-faq" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
          <p-accordionTab class="accordion-tab" header="¿Cómo puedo alquilar una bodega en línea?" iconPos="end">
            <div class="text-container">
              <p class="text">Es muy sencillo sólo debes solicitar en nuestro chat, un ingreso de artículos a tu servicio. Nuestro equipo te consultará para que decidas si deseas que sea recogido por nosotros o traído por ti a la bodega. Una vez ingreses los ítems adicionales el arriendo será actualizado automáticamente.</p>
            </div>
          </p-accordionTab>
          <p-accordionTab class="accordion-tab" header="¿Qué documentos necesito para alquilar una bodega?" iconPos="end">
            <div class="text-container">
              <p class="text">No solicitamos documentos, únicamente la firma del contrato de tu servicio, este requisito se activa una vez realices el primero pago de la reserva.</p>
            </div>
          </p-accordionTab>
          <p-accordionTab class="accordion-tab" header="¿Puedo acceder a la bodega en cualquier momento?" iconPos="end">
            <div class="text-container">
              <p class="text">Las visitas a nuestra Bodega las puedes solicitar a través del chat de coordinación de nuestro aliado, Mudango, recuerda igual que tu inventario estará disponible en digital, y puedes contratar servicios de retiros/despacho para mayor comodidad.</p>
            </div>
          </p-accordionTab>
          <p-accordionTab class="accordion-tab" header="¿Qué pasa si necesito más espacio?" iconPos="end">
            <div class="text-container">
              <p class="text">Es muy sencillo solo debes ingresar a nuestro chat y solicitar un ingreso adicional en tu servicio. Nuestro equipo te consultará para que decidas si deseas que sea recogido por nosotros o traído por ti a la bodega. Una vez ingreses los ítems adicionales el arriendo será actualizado automáticamente.</p>
            </div>
          </p-accordionTab>
          <p-accordionTab class="accordion-tab" header="¿Las bodegas están aseguradas?" iconPos="end">
            <div class="text-container">
              <p class="text">Mundo Hogar en alianza con Mudango no sólo te ofrece servicio de bodegas, sino un servicio completo que te acompaña en cada paso del proceso. Desde cotizaciones digitales hasta el traslado seguro de tus pertenencias, todo está pensado para brindarte la mayor comodidad y tranquilidad. La bodega, ubicada estratégicamente en Ciudad de México, cuenta con vigilancia 24/7 y opciones de seguro contra incendios y robos, garantizando que tus pertenencias estén siempre protegidas.</p>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
      <h5 class="help-content">Visita nuestro <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">Centro de ayuda</a> para más información</h5>
    </section>
  </section>
}