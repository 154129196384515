import { Injectable } from '@angular/core';
import { ErrorReportingRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { CONFIG } from '../../constants';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { SessionsService } from '../sessions/sessions.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportingService {
  private client: CreateTRPCProxyClient<ErrorReportingRouter>;

  constructor(
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<ErrorReportingRouter>(getTRPCClientOptions(`${environment.apiUrl}/errorReporting`,() => this.sessionsService.getAccessToken()));
  }

  log(location: string, step: string, error: unknown): void {
    if (['prod', 'stg'].includes(environment.name)) {
      let errorStackTrace = (error as Error).stack ?? JSON.stringify(error);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((error as any).error?.error?.data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errorStackTrace = JSON.stringify((error as any).error.error.data);
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.client.create.mutate({
        context: {
          url: window.location.href,
          userAgent: navigator.userAgent,
          functionName: location,
        },
        message: `Error in ${location} at ${step}: ${errorStackTrace ?? 'unknown'}`,
        serviceContext: {
          service: 'homein-hogar-app',
          version: `0.0.1-${CONFIG.appVersionCode}`,
        },
      });
    } else {
      console.error(`Error in ${location} at ${step}`, error);
    }
  }
}
