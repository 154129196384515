<section class="request-home-assistance-section">
  <div class="main-container">
    <h5 class="title">Solicita tu asistencia</h5>
    <img class="image" src="assets/illustrations/request-assistance.svg" alt="Imagen sobre un hombre preguntando si necesitas asistencias" />
    <app-reminder title="Importante" class="reminder" styleClass="alert">
      <span class="subtitle">Este es tu identificador: {{ email }}</span>
      <p class="description">Se solicitará tu identificador para realizar la asistencia.</p>
      <p class="description">También puedes escribirnos por WhatsApp <span class="phone-number">+52 55 8719 6412</span>, o llámanos al <span class="phone-number">55 9063 3719</span></p>
    </app-reminder>
    <div class="buttons-container">
      <app-button label="Llámanos" href="tel:5590633719" styleClass="secondary" icon="phone-call" />
      @if (planId === 'plan-hogar') {
        <app-button label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar.+&type=&&type=383absent=0" styleClass="secondary" icon="whatsapp" />
      } @else if (planId === 'plan-mascotas') {
        <app-button label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Mascotas.+&type=&&type=383absent=0" styleClass="secondary" icon="whatsapp" />
      } @else if (planId === 'plan-hogar-mascotas') {
        <app-button label="Escríbenos" href="https://api.whatsapp.com/send?phone=5215587196412&text=Quiero+solicitar+un+servicio+de+mi+Membresía+Hogar+y+Mascota.+&type=&&type=383absent=0" styleClass="secondary" icon="whatsapp" />
      }
    </div>
  </div>
</section>