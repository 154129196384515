import { Inject, Injectable } from '@angular/core';
import {
  AddShoppingCartItemData, EcommerceExternalPricing,
  RemoveShoppingCartItemData,
  SearchEngineProduct,
  ShoppingCart,
  ShoppingCartsRouter,
  SynchronizeShoppingCartData,
} from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { firstValueFrom, from, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { constants } from '../../constants';
import { getUpdatedShoppingCartItems } from '../../utils/shopping-cart.utils';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { FirestoreService } from '../firestore/firestore.service';
import { SearchService } from '../search/search.service';
import { SessionsService } from '../sessions/sessions.service';
import { LocalShoppingCart, ShoppingCartDetail, ShoppingCartsService } from './shopping-carts.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartsServiceApi implements ShoppingCartsService {
  private client: CreateTRPCProxyClient<ShoppingCartsRouter>;
  private shoppingCartDetail: Observable<ShoppingCartDetail | null>;

  constructor(
    private dataStorageService: DataStorageService,
    @Inject('appFirestore') private firestore: FirestoreService,
    private searchService: SearchService,
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<ShoppingCartsRouter>(getTRPCClientOptions(`${environment.apiUrl}/shoppingCarts`, () => this.sessionsService.getAccessToken()));
    this.shoppingCartDetail = this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.firestore.doc<ShoppingCart>(`shoppingCarts/${session.userId}`).pipe(switchMap((shoppingCart) => this.getChildren(shoppingCart)));
      }
      return this.getLocalShoppingCart().pipe(switchMap((shoppingCart) => this.getChildren(shoppingCart)));
    }));
  }

  async addItem(data: AddShoppingCartItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      return this.client.addItem.mutate(data).then();
    }
    let localShoppingCart = await firstValueFrom(this.getLocalShoppingCart());
    if (!localShoppingCart) {
      localShoppingCart = {
        currency: constants.defaultCurrency,
        items: [{
          ...data,
          addedAt: new Date(),
        }],
        shippingAddressId: null,
      };
    } else {
      localShoppingCart = {
        ...localShoppingCart,
        items: getUpdatedShoppingCartItems({
          items: localShoppingCart.items,
          action: 'add',
          addItemData: data,
        }),
      };
    }
    this.dataStorageService.set<LocalShoppingCart>(DataKey.ShoppingCart, localShoppingCart);
  }

  get(): Observable<ShoppingCartDetail | null> {
    return this.shoppingCartDetail;
  }

  async removeItem(data: RemoveShoppingCartItemData): Promise<void> {
    const session = await firstValueFrom(this.sessionsService.getSession());
    if (session) {
      return this.client.removeItem.mutate(data).then();
    }
    let localShoppingCart = await firstValueFrom(this.getLocalShoppingCart());
    if (!localShoppingCart) {
      localShoppingCart = {
        currency: constants.defaultCurrency,
        items: [{
          ...data,
          addedAt: new Date(),
        }],
        shippingAddressId: null,
      };
    } else {
      localShoppingCart = {
        ...localShoppingCart,
        items: getUpdatedShoppingCartItems({
          action: 'remove',
          items: localShoppingCart.items,
          removeItemData: data,
        }),
      };
    }
    this.dataStorageService.set<LocalShoppingCart>(DataKey.ShoppingCart, localShoppingCart);
  }

  async synchronize(): Promise<void> {
    const localShoppingCart = await firstValueFrom(this.getLocalShoppingCart());
    if (!localShoppingCart) {
      return Promise.resolve();
    }
    if (localShoppingCart && localShoppingCart.items.length) {
      const { currency, items } = localShoppingCart;
      const data = { currency, items: items.filter((item) => item.resourceType === 'product') } as SynchronizeShoppingCartData;
      return this.client.synchronize.mutate(data).then();
    }
  }

  updateShippingAddress(shippingAddressId: string): Promise<void> {
    return this.client.updateShippingAddress.mutate({ shippingAddressId }).then();
  }

  private getChildren(shoppingCart: ShoppingCart | LocalShoppingCart | null): Observable<ShoppingCartDetail | null> {
    if (!shoppingCart) {
      return of(null);
    }
    return from(this.searchService.search<SearchEngineProduct>('products', {
      filters: [
        {
          field: 'id',
          operator: 'in',
          value: shoppingCart.items.map((item) => item.resourceId),
        }
      ],
      pagination: {
        documentsPerPage: constants.maxShoppingCartItems,
      },
    })).pipe(map((paginable) => {
      let subtotal = 0;
      let discounts = 0;
      let shippingCost = 0;
      const pricing: Record<string, EcommerceExternalPricing> = {};
      const detailItems = shoppingCart.items.map((item) => {
        if (item.resourceType === 'product') {
          const resource = paginable.data.find((product) => product.id === item.resourceId) ?? null;
          if (resource) {
            shippingCost += item.quantity * resource.pricing['debit'].shippingCost;
            subtotal += item.quantity * resource.pricing['debit'].price;
            discounts += item.quantity * (resource.originalPrice - resource?.pricing['debit'].price);
            constants.paymentOptions.forEach((option) => {
              if (!pricing[option]) {
                pricing[option] = { ...resource.pricing[option] };
              } else {
                pricing[option].monthlyPayment += item.quantity * resource.pricing[option].monthlyPayment;
                pricing[option].originalShippingCost += item.quantity * resource.pricing[option].originalShippingCost;
                pricing[option].price += item.quantity * resource.pricing[option].price;
                pricing[option].shippingCost += item.quantity * resource.pricing[option].shippingCost;
              }
            });
            return {
              ...item,
              resource,
            };
          }
        }
        return {
          ...item,
          resource: null,
        };
      }).filter((item) => item.resource);
      let total = subtotal;
      if (shoppingCart.shippingAddressId) {
        total += shippingCost;
      }
      return {
        ...shoppingCart,
        discounts,
        items: detailItems,
        pricing,
        shippingCost,
        subtotal,
        total,
        totalItems: detailItems.reduce((totalItems, item) => totalItems + item.quantity, 0),
      };
    }));
  }

  private getLocalShoppingCart(): Observable<LocalShoppingCart | null> {
    return this.dataStorageService.get<LocalShoppingCart>(DataKey.ShoppingCart).pipe(map((localShoppingCart) => {
      if (localShoppingCart) {
        localShoppingCart.items = localShoppingCart.items.map((item) => {
          if (item.addedAt) {
            item.addedAt = new Date(item.addedAt);
          }
          return item;
        });
      }
      return localShoppingCart;
    }));
  }
}
