import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AnalyticsServiceApi } from './analytics.service.api';
import { AnalyticsServiceMock } from './analytics.service.mock';

interface BaseCheckoutEvent {
  currency: string;
  items: CheckoutItem[];
  value: number;
}

export interface CheckoutItem {
  discount: number;
  item_brand: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
}

export interface GenerateLeadEvent {
  lead_source: string;
}

export interface SignInEventData {
  method: string;
  redirect_to?: string;
}

export interface SignUpEventData {
  method: string;
  redirect_to?: string;
}

export interface SearchEventData {
  search_term: string;
}

export interface UserCreationEventData {
  method?: string;
  step: string;
}

export type AddShippingAddressEvent = BaseCheckoutEvent & {
  shipping_tier?: string;
}
export type AddToFavoritesEvent = BaseCheckoutEvent;
export type AddToShoppingCartEvent = BaseCheckoutEvent;
export type BeginCheckoutEvent = BaseCheckoutEvent & {
  coupon?: string;
}
export type PurchaseEventData = BaseCheckoutEvent & {
  coupon?: string;
  shipping: number;
  tax: number;
  transaction_id: string;
}
export type RemoveFromShoppingCartEvent = BaseCheckoutEvent;
export type ViewItemEvent = BaseCheckoutEvent;
export type ViewShoppingCartEvent = BaseCheckoutEvent;

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? AnalyticsServiceMock : AnalyticsServiceApi,
})
export abstract class AnalyticsService {
  abstract logAddShippingAddressEvent(data: AddShippingAddressEvent): void;
  abstract logAddToFavoritesEvent(data: AddToFavoritesEvent): void;
  abstract logAddToShoppingCartEvent(data: AddToShoppingCartEvent): void;
  abstract logBeginCheckoutEvent(data: BeginCheckoutEvent): void;
  abstract logGenerateLeadEvent(data: GenerateLeadEvent): void;
  abstract logPurchaseEvent(data: PurchaseEventData): void;
  abstract logRemoveFromShoppingCartEvent(data: RemoveFromShoppingCartEvent): void;
  abstract logSearchEvent(data: SearchEventData): void;
  abstract logSignInEvent(data: SignInEventData): void;
  abstract logSignUpEvent(data: SignUpEventData): void;
  abstract logUserCreationEvent(data: UserCreationEventData): void;
  abstract logViewItemEvent(data: ViewItemEvent): void;
  abstract logViewShoppingCartEvent(data: ViewShoppingCartEvent): void;
}
