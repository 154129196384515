const categoryLabels: Record<string, string> = {
  'accesorios-audio-y-sonido': 'Accesorios',
  'accesorios-fotografia': 'Accesorios',
  'accesorios-tv-y-video': 'Accesorios',
  'adiestramiento-y-repelentes': 'Adiestramiento y Repelentes',
  'albercas': 'Albercas',
  'amplificadores': 'Amplificadores',
  'aparatos-de-ejercicio': 'Aparatos de ejercicio',
  'aparatos-de-ejercicio-accesorios': 'Accesorios',
  'artefactos-para-el-hogar': 'Artefactos para el Hogar',
  'articulos-de-limpieza': 'Artículos de limpieza',
  'asadores': 'Asadores',
  'aspiradoras': 'Aspiradoras',
  'audifonos-y-manos-libres': 'Audífonos y manos libres',
  'audio-para-auto': 'Audio para auto',
  'audio-y-sonido': 'Audio y sonido',
  'aves': 'Aves',
  'banos': 'Baños',
  'binoculares-y-telescopios': 'Binoculares y Telescopios',
  'blancos': 'Blancos',
  'bocinas': 'Bocinas',
  'brincolines-albercas-y-plazas': 'Brincolines, albercas y plazas',
  'calefaccion-y-refrigeracion-al-aire-libre': 'Calefacción y refrigeración al aire libre',
  'camaras-analogicas': 'Cámaras Analógicas',
  'camaras-digitales': 'Cámaras Digitales',
  'carpas': 'Carpas',
  'carpinteria': 'Carpintería',
  'climatizacion': 'Climatización',
  'cocina': 'Cocina',
  'colchones-y-boxes': 'Colchones y Boxes',
  'comedor-y-cocina': 'Comedor y Cocina',
  'computo-y-tabletas': 'Computo y tabletas',
  'consolas-y-mezcladoras': 'Consolas y Mezcladoras',
  'construccion': 'Construcción',
  'construccion-y-ferreteria': 'Construcción y Ferretería',
  'contenedores-de-basura': 'Contenedores de basura',
  'control-de-plagas': 'Control de plagas',
  'decoracion': 'Decoración',
  'decoracion-al-aire-libre': 'Decoración al aire libre',
  'deporte-en-el-hogar': 'Deporte en el hogar',
  'dispositivos-streaming': 'Dispositivos Streaming',
  'drones': 'Drones',
  'electricidad': 'Electricidad',
  'electrodomesticos': 'Electrodomésticos',
  'electrodomesticos-y-linea-blanca': 'Electrodomésticos y línea blanca',
  'entretenimiento-y-tecnologia': 'Entretenimiento y Tecnología',
  'equinos': 'Equinos',
  'estudio-y-oficina': 'Estudio y Oficina',
  'estudio-y-oficina-muebles': 'Muebles',
  'fregaderos': 'Fregaderos',
  'gatos': 'Gatos',
  'generadores-y-energia-portatil': 'Generadores y energía portátil',
  'go-pro': 'Go-Pro',
  'juguetes': 'Juguetes',
  'juegos-de-mesa': 'Juegos de mesa',
  'hamacas': 'Hamacas',
  'herramientas': 'Herramientas',
  'hidrolavadoras-y-herramientas': 'Hidrolavadoras y herramientas',
  'hora-de-dormir': 'Hora de dormir',
  'hora-del-bano': 'Hora del baño',
  'iluminacion': 'Iluminación',
  'iluminacion-de-exteriores': 'Iluminación de exteriores',
  'instrumentos-musicales': 'Instrumentos musicales',
  'jardineria': 'Jardineria',
  'lavado-y-secado-de-ropa': 'Lavado y Secado de Ropa',
  'libros-y-revistas': 'Libros y Revistas',
  'limpiadores-quimicos': 'Limpiadores químicos',
  'limpieza': 'Limpieza',
  'limpieza-refacciones-y-accesorios': 'Refacciones y Accesorios',
  'limpieza-y-organizacion-del-hogar': 'Limpieza y Organización del Hogar',
  'macetas': 'Macetas',
  'mascotas': 'Mascotas',
  'maternidad-y-bebe': 'Maternidad y bebé',
  'mejora-de-hogar': 'Mejora de Hogar',
  'mejora-de-hogar-limpieza-y-conservacion': 'Limpieza y Conservación',
  'mincomponentes-y-receptores': 'Minicomponentes y Receptores',
  'muebles-y-decoracion': 'Muebles y Decoración',
  'muebles-limpieza-y-conservacion': 'Limpieza y Conservación',
  'muebles-para-bebe': 'Muebles para bebé',
  'muebles-para-jardin': 'Muebles para jardín',
  'navidad': 'Navidad',
  'ninos-y-juguetes': 'Niños y juguetes',
  'para-cocinar': 'Para cocinar',
  'patio-y-jardin': 'Patio y jardín',
  'peces': 'Peces',
  'pequenas-mascotas': 'Pequeñas mascotas',
  'perros': 'Perros',
  'pisos-y-alfombras': 'Pisos y Alfombras',
  'plomeria': 'Plomería',
  'proteccion-y-seguridad': 'Protección y seguridad',
  'proyectores-y-pantallas': 'Proyectores y Pantallas',
  'recamaras': 'Recámaras',
  'refacciones': 'Refacciones',
  'refacciones-y-accesorios': 'Refacciones y Accesorios',
  'reflex-semiprofesionales': 'Réflex/Semiprofesionales',
  'reproductores-bluray-y-dvds': 'Reproductores BluRay y DVD\'s',
  'reptiles-y-anfibios': 'Reptiles y Anfibios',
  'roedores': 'Roedores',
  'salas-y-family-room': 'Salas y Family room',
  'sombrillas': 'Sombrillas',
  'soportes': 'Soportes',
  'teatro-en-casa': 'Teatro en casa',
  'televisiones': 'Televisiones',
  'tv-y-video': 'TV y video',
  'videocamaras': 'Videocámaras',
  'videojuegos': 'Videojuegos'
};
const categoryTree: Record<string, string[]> = {
  'muebles-y-decoracion': [
    'salas-y-family-room',
    'comedor-y-cocina',
    'colchones-y-boxes',
    'recamaras',
    'muebles-limpieza-y-conservacion',
    'decoracion',
    'banos',
    'limpieza-y-organizacion-del-hogar',
    'cocina',
    'blancos',
    'navidad',
    'iluminacion',
  ],
  'entretenimiento-y-tecnologia': [
    /*
    'videocamaras',
    'camaras-analogicas',
    'camaras-digitales',
    'reflex-semiprofesionales',
    'drones',
    'go-pro',
    'accesorios-fotografia',
    'binoculares-y-telescopios',
    'computo-y-tabletas',
    'videojuegos',
    'instrumentos-musicales',
    */
    'juegos-de-mesa',
  ],
  'estudio-y-oficina': [
    'libros-y-revistas',
    'estudio-y-oficina-muebles',
  ],
  'limpieza': [
    'articulos-de-limpieza',
    'aspiradoras',
    'contenedores-de-basura',
    'hidrolavadoras-y-herramientas',
    'limpiadores-quimicos',
    'limpieza-refacciones-y-accesorios',
  ],
  'electrodomesticos-y-linea-blanca': [
    'electrodomesticos',
    'artefactos-para-el-hogar',
    'para-cocinar',
    'lavado-y-secado-de-ropa',
    'climatizacion',
    'refacciones-y-accesorios',
  ],
  'construccion-y-ferreteria': [
    'electricidad',
    'herramientas',
    'plomeria',
    'carpinteria',
    'construccion',
  ],
  'patio-y-jardin': [
    'muebles-para-jardin',
    'sombrillas',
    'hamacas',
    'carpas',
    'macetas',
    'asadores',
    'albercas',
    'decoracion-al-aire-libre',
    'iluminacion-de-exteriores',
    'control-de-plagas',
    'generadores-y-energia-portatil',
    'calefaccion-y-refrigeracion-al-aire-libre',
    'jardineria',
  ],
  'deporte-en-el-hogar': [
    'aparatos-de-ejercicio',
    'aparatos-de-ejercicio-accesorios',
  ],
  'mascotas': [
    'aves',
    'equinos',
    'gatos',
    'peces',
    'perros',
    'reptiles-y-anfibios',
    'roedores',
    'pequenas-mascotas',
    'adiestramiento-y-repelentes',
  ],
  'ninos-y-juguetes': [
    'brincolines-albercas-y-plazas',
    'juguetes',
  ],
  'maternidad-y-bebe': [
    'hora-del-bano',
    'hora-de-dormir',
    'muebles-para-bebe',
  ],
  /* TODO: Enable these categories when in stock
  'tv-y-video': [
    'televisiones',
    'dispositivos-streaming',
    'proyectores-y-pantallas',
    'reproductores-bluray-y-dvds',
    'soportes',
    'accesorios-tv-y-video',
    'refacciones',
  ],
  'audio-y-sonido': [
    'audifonos-y-manos-libres',
    'teatro-en-casa',
    'amplificadores',
    'audio-para-auto',
    'bocinas',
    'consolas-y-mezcladoras',
    'mincomponentes-y-receptores',
    'accesorios-audio-y-sonido',
  ],
  'mejora-de-hogar': [
    'pisos-y-alfombras',
    'proteccion-y-seguridad',
    'fregaderos',
    'mejora-de-hogar-limpieza-y-conservacion',
  ],
   */
};
const createInvoiceErrorCausesToDescriptions: Record<string, string> = {
  'rfc-inactive-in-sat': 'RFC inactivo en la lista de RFC del SAT.',
  'name-inactive-in-sat': 'Nombre inactivo en la lista de RFC del SAT.',
  'name-not-associated-with-rfc': 'Nombre no pertenece al RFC registrado.',
  'tax-domicile-not-found-in-sat': 'Domicilio fiscal no se encuentra en la lista de RFC del SAT.',
  'tax-domicile-not-associated-with-registered-rfc': 'Domicilio fiscal no pertecene al RFC registrado.',
  'tax-regime-not-related-to-type-of-person': 'Régimen Fiscal no corresponde al tipo de persona.', // TODO: This error won't happen if we filter the tax regime based on type of person
  'tax-regime-not-related-to-rfc': 'Régimen Fiscal no corresponde al RFC.',
  'cfdi-use-not-related-to-type-of-person': 'Uso del CFDI debe corresponder con el tipo de persona y el régimen fiscal conforme al catálogo del SAT.', // TODO: This error won't happen if we filter the CFDI use based on type of person and tax regime
};

export const constants = {
  appVersionCode: 29,
  cache: {
    expireThreshold: 3 * 60 * 1000, // 3 minutes
    uniqueLabels: ['favorites', 'shopping-cart'], // Entries on cache with these labels will be unique
    whiteListLabels: ['favorites', 'highlights', 'opportunities', 'shopping-cart'], // Entries on cache with these labels will be saved on localStorage
  },
  consumerCreditDiscountBenefit: {
    requestedCreditDateSubtraction: 90, // days
    threshold: 0.7,
  },
  defaultCurrency: 'MXN',
  ecommerce: {
    categories: {
      labels: categoryLabels,
      tree: categoryTree,
    },
    invoices: {
      createInvoiceErrorCausesToDescriptions,
      deadlineSendingInvoice: 5, // in days
    }
  },
  maxFavoriteItems: 16,
  maxShoppingCartItems: 16,
  maxTrackedProduct: 16,
  mortgageSimulator: {
    loanPercentage: {
      maximum: 0.9,
      minimum: 0.1,
    },
    propertyValue: {
      maximum: 999999999,
      minimum: 250000,
    }
  },
  paymentOptions: ['debit', 'credit', 'credit-36', 'credit-24', 'credit-18', 'credit-12', 'credit-6', 'credit-3'] as const, // TODO: Don't change the order, we should refactor some logic first
  postalCodeLength: 5, // TODO F&F: remove or add/define all the country specific validations here
  pricing: {
    ecommerce: {
      superSale: 0.5,
      sale: 0.35,
    }
  },
  shipping: {
    priority: {
      delay: 2,
      time: 5,
    },
    regular: {
      delay: 5,
      time: 10,
    },
  },
  remodeling: {
    types: [
      { label: 'Baño', value: 'bathroom' },
      { label: 'Cocina', value: 'kitchen' },
      { label: 'Remodelación integral', value: 'fullhouse' },
      { label: 'Otro', value: 'others' },
    ]
  },
  tax: 0.16,
  warehouse: {
    aboutToExpireThreshold: 7, // days
  }
};
