import { Injectable } from '@angular/core';
import { GetEstimatedDeliveryData, ProductsRouter, SearchEngineProduct } from '@homein-hogar-server';
import { CreateTRPCProxyClient, createTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { Paginable, SearchService } from '../search/search.service';
import {
  BASIC_FILTER,
  PRODUCT_SEARCH_DEFAULT_FIELDS,
  PRODUCT_SEARCH_DEFAULT_WEIGHTS,
  PRODUCTS_PER_PAGE_DEFAULT,
  ProductSearchConfig,
  ProductsService,
} from './products.service';

const COLLECTION_PATH = 'products';

@Injectable()
export class ProductsServiceApi implements ProductsService {
  private client: CreateTRPCProxyClient<ProductsRouter>;

  constructor(
    private searchService: SearchService,
  ) {
    this.client = createTRPCProxyClient<ProductsRouter>(getTRPCClientOptions(`${environment.apiUrl}/${COLLECTION_PATH}`));
  }

  get(id: string): Promise<SearchEngineProduct | null> {
    return this.searchService.get('products', id);
  }

  getEstimatedDelivery(data: GetEstimatedDeliveryData): Promise<{ from: Date; to: Date; }> {
    return this.client.getEstimatedDelivery.query(data).then((response) => response.data);
  }

  search(query: string | null, config?: ProductSearchConfig): Promise<Paginable<SearchEngineProduct>> {
    return this.searchService.search<SearchEngineProduct>('products', {
      filters: config?.filters ? [...BASIC_FILTER, ...config.filters]: BASIC_FILTER,
      query: {
        value: query,
        fields: PRODUCT_SEARCH_DEFAULT_FIELDS,
        weights: PRODUCT_SEARCH_DEFAULT_WEIGHTS,
      },
      pagination: {
        documentsPerPage: config?.pagination?.documentsPerPage ?? PRODUCTS_PER_PAGE_DEFAULT,
        page: config?.pagination?.page ?? 1,
      },
      order: config?.order,
    });
  }
}