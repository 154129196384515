import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { User } from '@homein-hogar-server';
import { PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Carousel } from 'primeng/carousel';
import { combineLatest } from 'rxjs';
import calendarSettings from '../assets/i18n/calendar-settings.json';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ToastComponent } from './components/toast/toast.component';
import { DataKey, DataStorageService } from './services/data-storage/data-storage.service';
import { ErrorReportingService } from './services/error-reporting/error-reporting.service';
import { MetaTagsService } from './services/meta-tags/meta-tags.service';
import { PlatformService } from './services/platform/platform.service';
import { RemoteConfigsService } from './services/remote-configs/remote-configs.service';
import { ToastService } from './services/toast/toast.service';
import { LocalUser, UsersService } from './services/users/users.service';
import { CONFIG } from './constants';

declare global {
  interface Window {
    Brevo?: {
      identify(email: string, data: Record<string, string>): void;
      page(page: string, properties: Record<string, string>): void;
    };
    sib?: {
      email_id: string;
    };
  }
}

@Component({
  standalone: true,
  imports: [
    ButtonModule,
    FooterComponent,
    NavbarComponent,
    RouterModule,
    ToastComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isPaymentGatewayRoute = false;
  constructor(
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private metaTagsService: MetaTagsService,
    private platformService: PlatformService,
    private primeNgConfig: PrimeNGConfig,
    private remoteConfigsService: RemoteConfigsService,
    private router: Router,
    private titleService: Title,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    if (this.platformService.isBrowser()) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (!event.url.startsWith('/ecommerce/search')) {
            window.scrollTo(0, 0);
          }
          this.isPaymentGatewayRoute = event.url.startsWith('/payment-gateway/');
          const pageName = this.router.url;
          const properties = {
            ma_title: this.titleService.getTitle(),
            ma_url: window.location.href,
            ma_path: this.router.url,
            ma_referrer: document.referrer
          };
          if (window.Brevo && window.Brevo.page) {
            window.Brevo.page(pageName, properties);
          }
        }
      });
    }
    Carousel.prototype.onTouchMove = () => {}; // this is for Carousel vertical scroll on mobile
  }

  ngOnInit(): void {
    this.initialize();
  }

  private brevoIdentify(user: User | null): void {
    if (!window.sib || !window.Brevo || !window.Brevo.identify) {
      return;
    }
    if (user) {
      window.sib.email_id = user.email;
      window.Brevo.identify(user.email, {
        user_bank_validated: `${user.bankValidated}`,
        user_email: user.email,
        user_email_validated: `${user.emailValidated}`,
        user_enrollment_status: user.enrollmentStatus,
        user_father_last_name: user.fatherLastName ?? '',
        user_id: user.id,
        user_mother_last_name: user.motherLastName ?? '',
        user_name: user.name ?? '',
        user_phone_number: user.phoneNumber ?? '',
        user_profile_data_completed: `${user.profileDataCompleted}`,
      });
    } else {
      window.sib.email_id = '';
      window.Brevo.identify('', {});
    }
  }

  private initialize(): void {
    this.primeNgConfig.setTranslation(calendarSettings);
    this.metaTagsService.setMetaTags();
    if (this.platformService.isServer()) {
      return;
    }
    this.usersService.getCurrentUser().subscribe({
      next: (user) => {
        if (user) {
          this.dataStorageService.set<LocalUser>(DataKey.LocalUser, {
            bankValidated: user.bankValidated,
            email: user.email,
            emailValidated: user.emailValidated,
            enrollmentStatus: user.enrollmentStatus,
            fatherLastName: user.fatherLastName,
            id: user.id,
            motherLastName: user.motherLastName,
            name: user.name,
            phoneNumber: user.phoneNumber,
            profileDataCompleted: user.profileDataCompleted,
          });
        } else {
          this.dataStorageService.clear(DataKey.LocalUser);
        }
        this.brevoIdentify(user);
      },
      error: (error) => this.errorReportingService.log('AppComponent.initialize()', 'get-current-user', error)
    });
    combineLatest([
      this.router.events,
      this.remoteConfigsService.get(),
    ]).subscribe({
      next: ([routerEvent, remoteConfig]) => {
        if (routerEvent instanceof NavigationEnd) {
          this.isPaymentGatewayRoute = routerEvent.url.startsWith('/payment-gateway/');
          if (!routerEvent.url.startsWith('/ecommerce/search')) {
            window.scrollTo(0, 0);
          }
          if (remoteConfig && !remoteConfig.paymentGatewayEnabled && this.isPaymentGatewayRoute) {
            this.toastService.showError({ title: 'An error has occurred in the process', description: 'We are unable to complete this action at this time, please try again later.' });
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigateByUrl('/');
          }
        }
        if (remoteConfig && remoteConfig.appVersionCode > CONFIG.appVersionCode) {
          setTimeout(() => window.location.reload(), 5000);
        }
      },
      error: (error) => this.errorReportingService.log('AppComponent.initialize()', 'get-router-events-and-remote-config', error)
    });
  }
}