import dayjs, { ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

/* ********* Not use ********* */
// TODO: Delete use of dayjs and remove or replace methods

export function differencesDays(firstDate: Date, secondDate: Date): number {
  return dayjs(firstDate).diff(dayjs(secondDate), 'days');
}

export function subtract(date: Date, value: number, unit: ManipulateType): Date {
  return dayjs(date).subtract(value, unit).toDate();
}