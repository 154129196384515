import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Carousel } from 'primeng/carousel';
import { combineLatest, filter, firstValueFrom } from 'rxjs';
import calendarSettings from '../assets/i18n/calendar-settings.json';
import { environment } from '../environments/environment';
import { BirdOfferComponent } from './components/bird-offer/bird-offer.component';
import { ButtonComponent } from './components/button/button.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ToastComponent } from './components/toast/toast.component';
import { constants } from './constants';
import { DataKey, DataStorageService } from './services/data-storage/data-storage.service';
import { ErrorReportingService } from './services/error-reporting/error-reporting.service';
import { PlatformService } from './services/platform/platform.service';
import { RemoteConfigsService } from './services/remote-configs/remote-configs.service';
import { ToastService } from './services/toast/toast.service';
import { LocalUser, UsersService } from './services/users/users.service';
import { brevoIdentify } from './utils/enrollment.utils';

declare global {
  interface Window {
    Brevo?: {
      push(params: unknown[]): void;
    };
    sib?: {
      email_id: string;
    };
  }
}

@Component({
  standalone: true,
  imports: [
    BirdOfferComponent,
    ButtonComponent,
    ButtonModule,
    FooterComponent,
    NavbarComponent,
    RouterModule,
    ToastComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  isPaymentGatewayRoute = false;
  shouldAskForCookiesAcceptance = false;
  showTestingEnvironmentAlert = false;
  protected url: string;

  constructor(
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private platformService: PlatformService,
    private primeNgConfig: PrimeNGConfig,
    private remoteConfigsService: RemoteConfigsService,
    private router: Router,
    private titleService: Title,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    if (this.platformService.isBrowser()) {
      this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event) => {
        this.url = event.url;
        if (!event.url.startsWith('/ecommerce/search')) {
          window.scrollTo(0, 0);
        }
        this.isPaymentGatewayRoute = event.url.startsWith('/payment-gateway/');
        setTimeout(() => this.brevoPage(), 5000);
      });
    }
    Carousel.prototype.onTouchMove = () => {}; // this is for Carousel vertical scroll on mobile
    if (this.dataStorageService.getLocal(DataKey.HideTestingEnvironmentAlert)) {
      this.showTestingEnvironmentAlert = false;
    } else {
      this.showTestingEnvironmentAlert = environment.name !== 'prod';
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.platformService.isBrowser()) {
      const cookiesAcceptance = await firstValueFrom(this.dataStorageService.get<boolean>(DataKey.CookiesAcceptance));
      this.shouldAskForCookiesAcceptance = !cookiesAcceptance;
    }
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  acceptCookies(): void {
    this.dataStorageService.set(DataKey.CookiesAcceptance, true);
    this.shouldAskForCookiesAcceptance = false;
  }

  hideTestingEnvironmentAlert(): void {
    this.showTestingEnvironmentAlert = false;
    this.dataStorageService.set(DataKey.HideTestingEnvironmentAlert, true);
  }

  private brevoPage(): void {
    if (!window.Brevo || !window.Brevo.push) {
      return;
    }
    const pageName = this.router.url;
    const properties = {
      ma_title: this.titleService.getTitle(),
      ma_url: window.location.href,
      ma_path: this.router.url,
      ma_referrer: document.referrer,
    };
    window.Brevo.push([
      'page',
      pageName,
      properties,
    ]);
  }

  private initialize(): void {
    this.primeNgConfig.setTranslation(calendarSettings);
    if (this.platformService.isServer()) {
      return;
    }
    this.usersService.getCurrentUser().subscribe({
      next: (user) => {
        if (user) {
          this.dataStorageService.set<LocalUser>(DataKey.LocalUser, {
            bankValidated: user.bankValidated,
            email: user.email,
            emailValidated: user.emailValidated,
            enrollmentStatus: user.enrollmentStatus,
            fatherLastName: user.fatherLastName,
            id: user.id,
            motherLastName: user.motherLastName,
            name: user.name,
            phoneNumber: user.phoneNumber,
            profileDataCompleted: user.profileDataCompleted,
          });
        } else {
          this.dataStorageService.clear(DataKey.LocalUser);
        }
        brevoIdentify(user);
      },
      error: (error) => this.errorReportingService.log('AppComponent.initialize()', 'get-current-user', error)
    });
    combineLatest([
      this.router.events,
      this.remoteConfigsService.get(),
    ]).subscribe({
      next: ([routerEvent, remoteConfig]) => {
        if (routerEvent instanceof NavigationEnd) {
          this.isPaymentGatewayRoute = routerEvent.url.startsWith('/payment-gateway/');
          if (!routerEvent.url.startsWith('/ecommerce/search')) {
            window.scrollTo(0, 0);
          }
          if (remoteConfig && !remoteConfig.paymentGatewayEnabled && this.isPaymentGatewayRoute) {
            this.toastService.showError({ title: 'Ha ocurrido un error en el proceso', description: 'En este momento no podemos completar esta acción, por favor intenta nuevamente mas tarde.' });
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigateByUrl('/');
          }
        }
        if (remoteConfig && remoteConfig.appVersionCode > constants.appVersionCode) {
          setTimeout(() => window.location.reload(), 5000);
        }
      },
      error: (error) => this.errorReportingService.log('AppComponent.initialize()', 'get-router-events-and-remote-config', error)
    });
  }
}