import { Route } from '@angular/router';

const data = {
  description: 'Simula el costo de tu próxima renovación. Aquí podrás simular el costo del proyecto de tu próxima renovación en Mundo Hogar, de manera gratuita y 100% online.',
  title: 'Simula el costo de tu proximo proyecto',
};

export default [
  {
    path: '',
    redirectTo: 'step-1',
    pathMatch: 'prefix'
  },
  {
    path: 'step-1',
    loadComponent: () => import('./step-1/step-1.page').then(m => m.Step1Page),
    data,
  },
  {
    path: 'step-2',
    loadComponent: () => import('./step-2/step-2.page').then(m => m.Step2Page),
    data,
  },
  {
    path: 'step-3',
    loadComponent: () => import('./step-3/step-3.page').then(m => m.Step3Page),
    data,
  },
  {
    path: 'step-4',
    loadComponent: () => import('./step-4/step-4.page').then(m => m.Step4Page),
    data,
  },
  {
    path: 'step-5',
    loadComponent: () => import('./step-5/step-5.page').then(m => m.Step5Page),
    data,
  },
  {
    path: 'step-6',
    loadComponent: () => import('./step-6/step-6.page').then(m => m.Step6Page),
    data,
  },
  {
    path: 'simulate-again',
    loadComponent: () => import('./simulate-again/simulate-again.page').then(m => m.SimulateAgainPage),
    data,
  },
] satisfies Route[];