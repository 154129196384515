<section class="main-container">
  <div class="purchase-status-container">
    <div class="title-container">
      <i class="icon-arrow-left" (click)="goBack()"></i>
      <h2>{{ 'Purchase status' | transloco }}</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (orderItem) {
        <div class="animate__fadeIn">
          <div class="product-container">
            <img [src]="orderItem.thumbnail" alt="{{ 'Product image' | transloco }}" />
            <div class="info">
              <span class="name">{{ orderItem.name }}</span>
              <a routerLink="/ecommerce/products/{{ orderItem.id }}">{{ 'See product detail' | transloco }}</a>
            </div>
          </div>
          <div class="purchase-status-info">
            @if (['in-process', 'collected'].includes(orderItem.shippingStatus)) {
              <span class="status active">{{ 'In preparation' | transloco }}</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: orderItem.shippingDate | date: 'dd/MM/yyyy' } }}</span>
              }
              <!-- TODO: Uncomment when the estimatedShippingDate is available
              @else {
                <span class="delivery-date">{{ 'Arrives between X and Y' | transloco: { dateFrom: ecommerceOrder.estimatedShippingDate.from | date: 'dd/MM/yyyy', dateTo: ecommerceOrder.estimatedShippingDate.to | date: 'dd/MM/yyyy' } }}</span>
              }
              -->
              <span class="status-description">{{ 'We are preparing your order' | transloco }}</span>
            } @else if (orderItem.shippingStatus === 'in-transit') {
              <span class="status active">{{ 'In transit' | transloco }}</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: orderItem.shippingDate | date: 'dd/MM/yyyy' } }}</span>
              }
              <!-- TODO: Uncomment when the estimatedShippingDate is available
              @else {
                <span class="delivery-date">{{ 'Arrives between X and Y' | transloco: { dateFrom: ecommerceOrder.estimatedShippingDate.from | date: 'dd/MM/yyyy', dateTo: ecommerceOrder.estimatedShippingDate.to | date: 'dd/MM/yyyy' } }}</span>
              }
              -->
              <span class="status-description">{{ 'Your order is in your way' | transloco }}</span>
            } @else if (orderItem.shippingStatus === 'delivered') {
              <span class="status done">{{ 'Delivered' | transloco }}</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">{{ 'Arrived on X' | transloco: { date: (orderItem.shippingDate | date: 'dd/MM/yyyy') } }}</span>
              }
              <span class="status-description">{{ 'Your order has been delivered' | transloco }}</span>
            } @else if (orderItem.shippingStatus === 'exception-of-delivery') {
              <span class="status delayed">{{ 'Delivery failed' | transloco }}</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">{{ 'Arrives on X' | transloco: { date: orderItem.shippingDate | date: 'dd/MM/yyyy' } }}</span>
              }
              <span class="status-description">{{ 'Your order could not be delivered due to unavoidable circumstances' | transloco }}</span>
            } @else if (orderItem.shippingStatus === 'voided') {
              <span class="status done">{{ 'Cancelled' | transloco }}</span>
              <span class="status-description">{{ 'Your order has been cancelled, contact us for more information' | transloco }}</span>
            }
            <div class="repurchase-button-container">
              <!-- TODO: Add logic to repurchase. This link is displayed only when one product was purchased -->
              <!--<app-button styleClass="secondary expand" label="Repurchase" />-->
            </div>
          </div>
          <!-- TODO: uncomment when rating logic is ready -->
          <!--<div class="rating-container">
            <h4>{{ 'What did you think of the purchasing experience?' | transloco }}</h4>
            <div class="stars-container">
              <app-rating></app-rating>
            </div>
          </div>-->
          @if (showGenerateInvoiceOption) {
            <div class="invoice-container">
              <h4 class="title">{{ 'Invoice this purchase' | transloco }}</h4>
              <a class="link" (click)="openInvoicingSidebar()">{{ (ecommerceOrder.invoiceId ? 'Resend Invoice (generated on DD/MM/AAAA)' : 'Generate invoice') | transloco: { date: ecommerceOrder.invoicedAt | date: 'dd/MM/yyyy' } }}</a>
            </div>
          }
          <div class="help-links-container">
            <h4 class="title">{{ 'Help with this purchase' | transloco }}</h4>
            <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">{{ 'I have a problem with my purchase' | transloco }}</a>
            <a class="link" href="https://ayuda.mundohogar.com.mx/" target="_blank" rel="noopener">{{ 'The payment was charged twice to my card' | transloco }}</a>
          </div>
          <div class="purchase-details-navigation-container" routerLink="/private-site/my-purchases/{{ ecommerceOrder.id }}/details">
            <span class="label">{{ 'Purchase details' | transloco }}</span>
            <i class="icon-arrow-right"></i>
          </div>
        </div>
      }
    </app-retryable-section>
  </div>
  <div class="purchase-detail-container">
    <app-ecommerce-purchase-details [ecommerceOrder]="ecommerceOrder" [showBackButton]="false" />
  </div>
</section>
@if (!loading && !errorLoading && ecommerceOrder) {
  <app-invoicing-sidebar #invoicingSidebar [orderId]="ecommerceOrder.id" [invoiceId]="ecommerceOrder.invoiceId" />
}