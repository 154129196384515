import { Injectable } from '@angular/core';
import { UpdateUserData, User, UserEnrollment } from '@homein-hogar-server';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UsersServiceApi } from './users.service.api';
import { UsersServiceMock } from './users.service.mock';

export type LocalUser = Pick<User,
  'bankValidated' |
  'email' |
  'emailValidated' |
  'enrollmentStatus' |
  'fatherLastName' |
  'id' |
  'motherLastName' |
  'name' |
  'phoneNumber' |
  'profileDataCompleted'>;

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? UsersServiceMock : UsersServiceApi,
})
export abstract class UsersService {
  abstract get<T extends User | UserEnrollment = User>(id: string): Observable<T | null>;
  abstract getCurrentUser<T extends User | UserEnrollment = User>(): Observable<T | null>;
  abstract updateUser(data: UpdateUserData): Promise<{ code: string; message: string; }>;
}

