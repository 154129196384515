import { Injectable } from '@angular/core';
import { WarehouseOrder } from '@homein-hogar-server';
import * as faker from 'faker';
import { map, Observable, of, switchMap } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { SessionsService } from '../sessions/sessions.service';
import { WarehouseOrdersService } from './warehouse-orders.service';

@Injectable()
export class WarehouseOrdersServiceMock implements WarehouseOrdersService {
  constructor(
    private mockService: MockService,
    private sessionsService: SessionsService,
  ) {}

  createId(): string {
    return faker.random.alphaNumeric(20);
  }

  get(id: string): Observable<WarehouseOrder | null> {
    return this.mockService.getWarehouseOrder(id).pipe(map((order) => {
      if (order) {
        order.createdAt = new Date(order.createdAt);
        order.updatedAt = new Date(order.updatedAt);
        order.startAt = new Date(order.startAt);
        order.finishAt = new Date(order.finishAt);
      }
      return order;
    }));
  }

  getAll(): Observable<WarehouseOrder[]> {
    return this.sessionsService.getSession().pipe(switchMap(session => {
      if (session) {
        return this.mockService.getWarehouseOrders().pipe(map((orders) => {
          return orders.filter((order) => order.userId === session.userId && ['done', 'pending-payment', 'waiting-confirmation', 'voided'].includes(order.status)).map((order) => {
            return {
              ...order,
              createdAt: new Date(order.createdAt),
              finishAt: new Date(order.finishAt),
              startAt: new Date(order.startAt),
              updatedAt: new Date(order.updatedAt),
            };
          });
        }));
      }
      return of([]);
    }));
  }

  getLastInProgress(): Observable<WarehouseOrder | null> {
    return this.sessionsService.getSession().pipe(switchMap((session) => {
      if (session) {
        return this.mockService.getLastWarehouseOrderInProgress(session.userId).pipe(map((order) => {
          if (order) {
            order.createdAt = new Date(order.createdAt);
            order.updatedAt = new Date(order.updatedAt);
            order.startAt = new Date(order.startAt);
            order.finishAt = new Date(order.finishAt);
          }
          return order;
        }));
      }
      return of(null);
    }));
  }
}
