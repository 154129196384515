<section class="mortgage-simulation-type-main-container">
  <div class="mortgage-simulation-types-container">
    <h2 class="title">¿Cómo deseas simular tu hipoteca?</h2>
    @for (simulationType of simulationTypes; track $index) {
      <div class="mortgage-simulation-type-container" [class.selected]="simulationType.selected" (click)="selectSimulationType(simulationType)">
        <i class="icon {{ simulationType.icon }}" [class.selected]="simulationType.selected"></i>
        <span class="text" [class.selected]="simulationType.selected">{{ simulationType.translationKey | transloco }}</span>
      </div>
    }
  </div>
  <div class="right-container">
    @if (simulationTypeSelected === 'amount') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-amount.svg" alt="Ilustración de simulación de hipoteca por monto que necesitas" />
    } @else if (simulationTypeSelected === 'housing-cost') {
      <img class="illustration animate__fadeIn" src="/assets/illustrations/mortgage-simulation-type-housing-cost.svg" alt="Ilustración de simulación de hipoteca por costo de inmueble" />
    }
    <div class="continue-button-container animate__fadeIn">
      <app-button label="Continuar" styleClass="primary expand" [disabled]="!simulationTypeSelected" (action)="continue()" />
    </div>
  </div>
</section>
