import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { HomeAssistancePlan, HomeAssistancePlanDuration } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { HomeAssistancePlansService } from '../../services/home-assistance-plans/home-assistance-plans.service';
import { PlatformService } from '../../services/platform/platform.service';
import { ButtonComponent } from '../button/button.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-select-assistance-plan-sidebar',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CheckboxModule,
    CommonModule,
    CurrencyFormatterPipe,
    FormsModule,
    InputNumberModule,
    RadioButtonModule,
    RatingModule,
    ReactiveFormsModule,
    RetryableSectionComponent,
    RouterLink,
    SidebarModule,
    TabViewModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './select-assistance-plan-sidebar.component.html',
  styleUrl: './select-assistance-plan-sidebar.component.scss'
})
export class SelectAssistancePlanSidebarComponent implements OnInit {
  @Input({ required: true }) planId: string;
  @Input({ required: true }) title: string;
  errorLoading = false;
  homeAssistancePlan: HomeAssistancePlan;
  isMobile = false;
  isVisible = false;
  loading = true;
  selectedDuration: HomeAssistancePlanDuration = '12-months'; // 12-months by default

  constructor(
    private analyticsService: AnalyticsService,
    private errorReportingService: ErrorReportingService,
    private homeAssistancePlansService: HomeAssistancePlansService,
    private platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
    this.onResize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  close(): void {
    this.isVisible = false;
  }

  async initialize(): Promise<void> {
    this.loading = true;
    this.errorLoading = false;
    try {
      const homeAssistancePlan = await this.homeAssistancePlansService.get(this.planId);
      if (!homeAssistancePlan) {
        throw new Error('Home assistance plan not found');
      }
      this.homeAssistancePlan = homeAssistancePlan;
    } catch (error) {
      this.errorReportingService.log('SelectAssistancePlanSidebarComponent.initialize()', 'get-home-assistance-plan', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  open(duration?: HomeAssistancePlanDuration): void {
    if (duration) {
      this.selectedDuration = duration;
    }
    this.isVisible = true;
    this.analyticsService.logHomeAssistanceEvent({ step: 'select_duration', plan: this.planId });
  }

  selectDuration($event: TabViewChangeEvent): void {
    if ($event.index === 0) {
      this.selectedDuration = '1-month';
    } else if ($event.index === 1) {
      this.selectedDuration = '6-months';
    } else if ($event.index === 2) {
      this.selectedDuration = '12-months';
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  onResize(): void {
    if (this.platformService.isBrowser()) {
      this.isMobile = window.innerWidth <= 480;
    }
  }
}