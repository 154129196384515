<section class="project-simulator-container">
  <div>
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">Atrás</span>
      <span class="mobile-text">Simula tu proyecto</span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">Simula el costo del proyecto que tienes en mente</h5>
      <app-circular-progress [currentStep]="5" [numberSteps]="6" stepTitle="Superficie de la remodelación" stepSubtitle="Siguiente: ¡Enviaremos la simulación!" />
    </div>
    <div class="bottom-container animate__fadeIn">
      @if (projectSimulation && projectSimulation.type === 'bathroom') {
        <form [formGroup]="bathroomForm" class="project-simulation-types-container">
          <div formArrayName="surfaceAreaDetail">
            <span class="title">Ingresa la superficie de tus baños</span>
            @for (control of bathroomForm.controls.surfaceAreaDetail.controls; let i = $index; track control) {
              <div class="input-container">
                <label class="subtitle" [for]="'bathroom-' + i">{{ 'Bathroom surface X (m²)' | transloco: { number: i + 1 } }}</label>
                <p-inputMask [id]="'bathroom-' + i" [formControlName]="i" mask="9?9 m²" slotChar="" placeholder="Superficie en m²" />
                  @if (control.touched && control.invalid) {
                    @if (control.errors?.['required']) {
                      <small class="p-error input-error">Este campo es requerido</small>
                    }
                    @if (control.errors?.['min']) {
                      <small class="p-error input-error">{{ 'Value must be greater than X' | transloco: { value: control.errors?.['min'].min } }}</small>
                    }
                    @if (control.errors?.['max']) {
                      <small class="p-error input-error">{{ 'Value must be less than X' | transloco: { value: control.errors?.['max'].max } }}</small>
                    }
                  }
              </div>
            }
          </div>
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'kitchen') {
        <form [formGroup]="kitchenForm" class="project-simulation-types-container">
          <h2 class="title">Ingresa la superficie de la remodelación</h2>
            <div class="input-container">
            <label for="kitchen">Superficie de la cocina</label>
            <p-inputMask id="kitchen" formControlName="surfaceArea" mask="9?9 m²" slotChar="" placeholder="Superficie en m²" />
            @if (kitchenForm.controls.surfaceArea.touched && kitchenForm.controls.surfaceArea.invalid) {
              @if (kitchenForm.controls.surfaceArea.errors?.['required']) {
                <small class="p-error input-error">Este campo es requerido</small>
              }
              @if (kitchenForm.controls.surfaceArea.errors?.['min']) {
                <small class="p-error input-error">{{ 'Value must be greater than X' | transloco: { value: kitchenForm.controls.surfaceArea.errors?.['min'].min } }}</small>
              }
              @if (kitchenForm.controls.surfaceArea.errors?.['max']) {
                <small class="p-error input-error">{{ 'Value must be less than X' | transloco: { value: kitchenForm.controls.surfaceArea.errors?.['max'].max } }}</small>
              }
            }
          </div>
        </form>
      }
      @if (projectSimulation && projectSimulation.type === 'integral-remodeling') {
        <form [formGroup]="integralRemodelingForm" class="project-simulation-types-container">
          <h2 class="title">Ingresa la superficie de la remodelación</h2>
          <div class="input-container">
            <label for="surface-area">Superficie a remodelar</label>
            <p-inputMask id="surface-area" formControlName="surfaceArea" mask="9?99 m²" slotChar="" placeholder="Superficie en m²" />
            @if (integralRemodelingForm.controls.surfaceArea.touched && integralRemodelingForm.controls.surfaceArea.invalid) {
              @if (integralRemodelingForm.controls.surfaceArea.errors?.['required']) {
                <small class="p-error input-error">Este campo es requerido</small>
              }
              @if (integralRemodelingForm.controls.surfaceArea.errors?.['min']) {
                <small class="p-error input-error">{{ 'Value must be greater than X' | transloco: { value: integralRemodelingForm.controls.surfaceArea.errors?.['min'].min } }}</small>
              }
              @if (integralRemodelingForm.controls.surfaceArea.errors?.['max']) {
                <small class="p-error input-error">{{ 'Value must be less than X' | transloco: { value: integralRemodelingForm.controls.surfaceArea.errors?.['max'].max } }}</small>
              }
            }
          </div>
          <div class="input-container">
            <label for="number-of-bathrooms">Número de baños</label>
            <p-inputNumber class="input" id="number-of-bathrooms" placeholder="Ingresa número de baños" formControlName="numberOfBathrooms" />
            @if (integralRemodelingForm.controls.numberOfBathrooms.touched && integralRemodelingForm.controls.numberOfBathrooms.invalid) {
              @if (integralRemodelingForm.controls.numberOfBathrooms.errors?.['required']) {
                <small class="p-error input-error">Este campo es requerido</small>
              }
              @if (integralRemodelingForm.controls.numberOfBathrooms.errors?.['min']) {
                <small class="p-error input-error">{{ 'Value must be greater than X' | transloco: { value: integralRemodelingForm.controls.numberOfBathrooms.errors?.['min'].min } }}</small>
              }
              @if (integralRemodelingForm.controls.numberOfBathrooms.errors?.['max']) {
                <small class="p-error input-error">{{ 'Value must be less than X' | transloco: { value: integralRemodelingForm.controls.numberOfBathrooms.errors?.['max'].max } }}</small>
              }
            }
          </div>
          <div class="input-container">
            <label for="number-of-rooms">Número de habitaciones</label>
            <p-inputNumber class="input" id="number-of-rooms" placeholder="Ingresa número de habitaciones" formControlName="numberOfRooms" />
            @if (integralRemodelingForm.controls.numberOfRooms.touched && integralRemodelingForm.controls.numberOfRooms.invalid) {
              @if (integralRemodelingForm.controls.numberOfRooms.errors?.['required']) {
                <small class="p-error input-error">Este campo es requerido</small>
              }
              @if (integralRemodelingForm.controls.numberOfRooms.errors?.['min']) {
                <small class="p-error input-error">{{ 'Value must be greater than X' | transloco: { value: integralRemodelingForm.controls.numberOfRooms.errors?.['min'].min } }}</small>
              }
              @if (integralRemodelingForm.controls.numberOfRooms.errors?.['max']) {
                <small class="p-error input-error">{{ 'Value must be less than X' | transloco: { value: integralRemodelingForm.controls.numberOfRooms.errors?.['max'].max } }}</small>
              }
            }
          </div>
        </form>
      }
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="Ilustración de la opción seleccionada" />
        <div class="continue-button-container">
          <app-button innerId="btn-VcFMgXbK" label="Continuar" styleClass="primary expand" (action)="onSubmit()" [disabled]="isInvalid" />
        </div>
      </div>
    </div>
  </div>
</section>