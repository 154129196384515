import { Routes } from '@angular/router';
import { enrollmentGuard } from '../../guards/enrollment.guard';

export default [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'prefix'
  },
  {
    path: 'bank-validation',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./bank-validation/bank-validation.page').then(m => m.BankValidationPage),
    data: {
      description: 'Valida tu cuenta bancaria para disfrutar de los beneficios de Mundo Hogar.',
      title: 'Validación de cuenta bancaria',
    },
  },
  {
    path: 'onboarding',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./onboarding/onboarding.page').then(m => m.OnboardingPage),
    data: {
      title: '¡Bienvenido a Mundo Hogar!',
      description: 'Completa tu registro para disfrutar de los beneficios de Mundo Hogar.'
    },
  },
  {
    path: 'password-reset',
    loadComponent: () => import('./password-reset/password-reset.page').then(m => m.PasswordResetPage),
    data: {
      description: 'Recupera tu contraseña de Mundo Hogar.',
      title: 'Recuperar contraseña',
    },
  },
  {
    path: 'sign-in',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./sign-in/sign-in.page').then(m => m.SignInPage),
    data: {
      description: 'Inicia sesión en Mundo Hogar para disfrutar de los beneficios exclusivos para clientes de Banco Santander.',
      title: 'Ingresa a Mundo Hogar',
    },
  },
  {
    path: 'sign-up',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./sign-up/sign-up.page').then(m => m.SignUpPage),
    data: {
      description: 'Regístrate en Mundo Hogar para disfrutar de los beneficios exclusivos para clientes de Banco Santander.',
      title: 'Crea tu cuenta Mundo Hogar',
    },
  },
  {
    path: 'verify-email',
    loadComponent: () => import('./verify-email/verify-email.page').then(m => m.VerifyEmailPage),
    data: {
      description: 'Verifica tu correo electrónico para disfrutar de los beneficios de Mundo Hogar.',
      title: 'Verificación de correo electrónico',
    },
  },
  {
    path: 'send-verify-email',
    canActivate: [enrollmentGuard],
    loadComponent: () => import('./send-verify-email/send-verify-email.page').then(m => m.SendVerifyEmailPage),
    data: {
      description: 'Envía un correo electrónico de verificación para disfrutar de los beneficios de Mundo Hogar.',
      title: 'Envía correo electrónico de verificación',
    },
  },
] satisfies Routes;
