import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HomeAssistancePlan } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ButtonComponent } from '../../../components/button/button.component';
import { RetryableSectionComponent } from '../../../components/retryable-section/retryable-section.component';
import { TermsBannerComponent } from '../../../components/terms-banner/terms-banner.component';
import { CurrencyFormatterPipe } from '../../../pipes/currency-formatter.pipe';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { HomeAssistancePlansService } from '../../../services/home-assistance-plans/home-assistance-plans.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';

type HomeAssistancePlanCard = HomeAssistancePlan & {
  recommended: boolean;
};

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonComponent,
    CarouselModule,
    CommonModule,
    CurrencyFormatterPipe,
    RetryableSectionComponent,
    RouterLink,
    SharedModule,
    TermsBannerComponent,
    TranslocoPipe,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit {
  errorLoading = false;
  homeAssistancePlans: HomeAssistancePlanCard[];
  loading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private errorReportingService: ErrorReportingService,
    private homeAssistancePlansService: HomeAssistancePlansService,
    private platformService: PlatformService,
    private seoService: SeoService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('home-assistance');
    }
    this.analyticsService.logHomeAssistanceEvent({ step: 'start' });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goToPlans(): void {
    const section = document.getElementById('exclusive-benefits-section');
    if (section) {
      const offsetTop = section.offsetTop - 100;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
  }

  async initialize(): Promise<void> {
    this.loading = true;
    this.errorLoading = false;
    try {
      const homeAssistancePlans = await this.homeAssistancePlansService.getAll();
      this.homeAssistancePlans = homeAssistancePlans.map((plan) => ({
        ...plan,
        recommended: plan.name === 'Plan Hogar + Mascotas',
      }));
    } catch (error) {
      this.errorReportingService.log('HomePage.initialize()', 'get-all-home-assistance-plans', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }
}
