<section class="personal-data-container">
  <div class="left-section">
    <h1 class="section-title">
      <i class="icon icon-arrow-left" (click)="goBack()"></i>
      <span>Datos personales</span>
    </h1>
    <app-retryable-section [error]="errorLoading" [loading]="isLoading" (retry)="initialize()">
      <form [formGroup]="form" class="form-container">
        <div class="input-container">
          <label for="name">Nombre*</label>
          <input class="input" id="name" type="text" pInputText formControlName="name" aria-describedby="name-help" data-private />
          <small id="name-help" [class.hidden]="!form.controls.name.touched || !form.controls.name.errors" class="p-error input-error">Ingresa un nombre válido.</small>
        </div>
        <div class="input-container">
          <label for="father-last-name">Apellido paterno*</label>
          <input class="input" type="text" id="father-last-name" pInputText formControlName="fatherLastName" aria-describedby="father-last-name-help" data-private />
          <small id="father-last-name-help" [class.hidden]="!form.controls.fatherLastName.touched || !form.controls.fatherLastName.errors" class="p-error input-error">Ingresa un apellido paterno válido.</small>
        </div>
        <div class="input-container">
          <label for="mother-last-name">Apellido materno</label>
          <input class="input" type="text" id="mother-last-name" pInputText formControlName="motherLastName" aria-describedby="mother-last-name-help" data-private />
          <small id="mother-last-name-help" [class.hidden]="!form.controls.motherLastName.touched || !form.controls.motherLastName.errors" class="p-error input-error">Ingresa un apellido materno válido.</small>
        </div>
        <div class="input-container">
          <label for="birth-date">Fecha de nacimiento*</label>
          <p-calendar class="input" id="birth-date" dateFormat="dd/mm/yy" [maxDate]="maxDateBirthDate" [defaultDate]="user?.birthDate!" formControlName="birthDate" aria-describedby="birth-date-help" class="birth-date" />
          <small id="birth-date-help" [class.hidden]="!form.controls.birthDate.touched || !form.controls.birthDate.errors" class="p-error input-error">Ingresa una fecha de nacimiento válida.</small>
        </div>
        <div class="input-container">
          @if (user?.emailValidated) {
            <div class="email-container">
              <label for="email" class="label">Correo electrónico*</label>
              <div class="verified-container" [class.disabled]="form.controls.email.disabled">
                <i class="icon icon-checkmark"></i>
                <small class="email-verified" [class.check]="user?.emailValidated">Verificado</small>
              </div>
            </div>
          } @else {
            <label for="email">Correo electrónico*</label>
          }
          <input id="email" type="email" pInputText formControlName="email" aria-describedby="email-help" [class.disabled]="form.controls.email.disabled" data-private />
          <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">Ingresa un correo válido.</small>
        </div>
        <div class="input-container">
          <label for="phone-number">Número telefónico*</label>
          <p-inputMask id="phone-number" formControlName="phoneNumber" [autoClear]="false" [unmask]="true" mask="+(99) 99 9999 999?9" placeholder="+(52) 99 9999 9999" slotChar="" aria-describedby="phone-number-help" [class.disabled]="form.controls.phoneNumber.disabled" [class.input-mask]="!form.controls.phoneNumber.disabled" data-private />
          <small id="phone-number-help" [class.hidden]="!form.controls.phoneNumber.touched || !form.controls.phoneNumber.errors" class="p-error input-error">Ingresa un número de teléfono válido, debe comenzar con "52" y luego 10 dígitos.</small>
        </div>
        <app-button innerId="btn-eRwNDXPV" label="Guardar cambios" class="send" styleClass="primary expand" [disabled]="!isValidForm" [loading]="sendingForm" (action)="onSubmit()" />
      </form>
    </app-retryable-section>
  </div>
  <div class="right-section">
    <div class="text-container">
      <h2 class="title">Mundo Hogar</h2>
      <p class="subtitle">Un lugar que conecta las experiencias que tu hogar necesita</p>
    </div>
  </div>
</section>