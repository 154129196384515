import { Injectable } from '@angular/core';
import { HomeAssistancePlan, HomeAssistancePlansRouter } from '@homein-hogar-server';
import { createTRPCProxyClient, CreateTRPCProxyClient } from '@trpc/client';
import { environment } from '../../../environments/environment';
import { getTRPCClientOptions } from '../../utils/trpc.utils';
import { SessionsService } from '../sessions/sessions.service';
import { HomeAssistancePlansService } from './home-assistance-plans.service';

@Injectable()
export class HomeAssistancePlansServiceApi implements HomeAssistancePlansService {
  private client: CreateTRPCProxyClient<HomeAssistancePlansRouter>;

  constructor(
    private sessionsService: SessionsService,
  ) {
    this.client = createTRPCProxyClient<HomeAssistancePlansRouter>(getTRPCClientOptions(`${environment.apiUrl}/homeAssistancePlans`, () => this.sessionsService.getAccessToken()));
  }

  get(id: string): Promise<Omit<HomeAssistancePlan, 'pricing.internal'> | null> {
    return this.client.get.query({ id }).then(({ data }) => data);
  }

  getAll(): Promise<Omit<HomeAssistancePlan, 'pricing.internal'>[]> {
    return this.client.getAll.query().then(({ data }) => data);
  }
}
