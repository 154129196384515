import { Routes } from '@angular/router';

export default [
  {
    path: '',
    loadComponent: () => import('./my-movings/my-movings.page').then(m => m.MyMovingsPage),
    data: {
      description: 'Portal exclusivo para clientes de Banco Santander que reúne en un mismo lugar beneficios únicos en servicios para el hogar.',
      title: 'Mis mudanzas',
    },
  },
  {
    path: ':orderId',
    loadComponent: () => import('./moving-status/moving-status.page').then(m => m.MovingStatusPage)
  },
  {
    path: ':orderId/details',
    loadComponent: () => import('../../../components/moving-purchase-details/moving-purchase-details.component').then(m => m.MovingPurchaseDetailsComponent)
  }
] satisfies Routes;
