import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { normalizeFloat } from '../utils/numbers.utils';

export const valueComparison = (otherControl: FormControl<number | null>, operator: 'gt' | 'gte' | 'lt' | 'lte', percentage: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!otherControl || !otherControl.value || !control.value) {
      return null;
    }

    const controlValue = parseFloat(control.value);
    const otherControlValue = normalizeFloat(otherControl.value * (percentage / 100));
    let meetCondition = true;

    switch (operator) {
    case 'gt':
      meetCondition = controlValue > otherControlValue;
      break;
    case 'gte':
      meetCondition = controlValue >= otherControlValue;
      break;
    case 'lt':
      meetCondition = controlValue < otherControlValue;
      break;
    case 'lte':
      meetCondition = controlValue <= otherControlValue;
      break;
    }

    return meetCondition ? null : { valueComparison: { operator, value: otherControlValue } };
  };
};