import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { firstValueFrom, Subject } from 'rxjs';
import { CurrencyFormatterPipe } from '../../pipes/currency-formatter.pipe';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { HomeAssistanceOrderDetail, HomeAssistanceOrdersService } from '../../services/home-assistance-orders/home-assistance-orders.service';
import { PlatformService } from '../../services/platform/platform.service';
import { SeoService } from '../../services/seo/seo.service';
import { getComplementaryData } from '../../utils/home-assistances.utils';
import { PurchasePaymentDetailsComponent } from '../purchase-payment-details/purchase-payment-details.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

@Component({
  selector: 'app-home-assistance-purchase-details',
  standalone: true,
  imports: [
    AccordionModule,
    CommonModule,
    CurrencyFormatterPipe,
    PurchasePaymentDetailsComponent,
    RetryableSectionComponent,
    RouterLink,
  ],
  templateUrl: './home-assistance-purchase-details.component.html',
  styleUrl: './home-assistance-purchase-details.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomeAssistancePurchaseDetailsComponent implements OnInit, OnDestroy {
  @Input() order: HomeAssistanceOrderDetail;
  @Input() showBackButton = true;
  errorLoading = false;
  loading = true;
  protected orderId: string;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorReportingService: ErrorReportingService,
    private homeAssistanceOrdersService: HomeAssistanceOrdersService,
    private platformService: PlatformService,
    private router: Router,
    private seoService: SeoService,
  ) {}

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      return;
    }
    const orderId = this.activatedRoute.snapshot.paramMap.get('orderId');
    if (!orderId) {
      this.goToMyHomeAssistances();
      return;
    }
    this.orderId = orderId;
    if (!this.order) {
      this.seoService.setMetaTags({
        title: `Detalle de compra #${this.orderId}`,
        description: 'Detalle de compra de un plan de asistencia en Mundo Hogar.',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/require-await,@typescript-eslint/member-ordering
  async initialize(): Promise<void> {
    this.errorLoading = false;
    this.loading = true;
    try {
      const homeAssistanceOrder = await firstValueFrom(this.homeAssistanceOrdersService.get(this.orderId));
      if (!homeAssistanceOrder) {
        throw new Error('HomeAssistanceOrder not found');
      }
      this.order =  {
        ...homeAssistanceOrder,
        ...getComplementaryData(homeAssistanceOrder),
      };
    } catch (error) {
      this.errorReportingService.log('HomeAssistancePurchaseDetailsComponent.initialize()', 'get-home-assistance-order', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  private goToMyHomeAssistances(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/private-site/my-home-assistances']);
  }
}