import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { TranslocoPipe } from '@ngneat/transloco';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { CONFIG } from '../../constants';
import { EMAIL_PATTERN, NAME_PATTERN, RFC_LEGAL_ENTITY_PATTERN, RFC_NATURAL_PERSON_PATTERN } from '../../constants/validation-patterns.constants';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../services/forms/forms.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';
import { ButtonComponent } from '../button/button.component';
import { RetryableSectionComponent } from '../retryable-section/retryable-section.component';

type FormValues = {
  cfdiUse: CfdiUse;
  email: string;
  name: string;
  orderId: string;
  postalCode: string;
  rfc: string;
  taxRegime: TaxRegime;
} & ({
  fatherLastName: string;
  motherLastName: string;
  personType: 'natural-person',
} | {
  companyName: string;
  personType: 'legal-entity',
});

type PersonType = 'natural-person' | 'legal-entity';
type CfdiUse =
  | 'general-expenses'
  | 'goods-acquisition'
  | 'returns-discounts-or-bonuses'
  | 'without-tax-effects';
type TaxRegime =
  | 'agricultural-livestock-forestry-and-fishing-activities'
  | 'coordinated'
  | 'dividend-income-partners-and-shareholders'
  | 'general-law-legal-entities'
  | 'individuals-with-business-and-professional-activities'
  | 'interest-income'
  | 'leasing'
  | 'non-profit-legal-entities'
  | 'no-tax-obligations'
  | 'other-income'
  | 'optional-for-groups-of-companies'
  | 'production-cooperatives-that-opt-to-defer-their-income'
  | 'regime-of-alienation-or-acquisition-of-assets'
  | 'regime-of-business-activities-with-income-through-technological-platforms'
  | 'regime-of-income-from-prizes'
  | 'residents-abroad-without-permanent-establishment-in-mexico'
  | 'salaries-and-wages-and-income-assimilated-to-salaries'
  | 'simplified-trust-regime'
  | 'tax-incorporation';

@Component({
  selector: 'app-invoicing-sidebar',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    InputTextModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RetryableSectionComponent,
    RouterLink,
    SidebarModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './invoicing-sidebar.component.html',
  styleUrl: './invoicing-sidebar.component.scss'
})

export class InvoicingSidebarComponent implements OnInit, OnDestroy {
  @Input({ required: true }) orderId: string;
  @Input() invoiceId: string | null;
  protected invoiceCreated = false;
  protected invoiceSent = false;
  protected cfdiUseOptions: { label: string; value: CfdiUse; }[] = [
    { label: 'Gastos en general', value: 'general-expenses' },
    { label: 'Adquisición de mercancías', value: 'goods-acquisition' },
    { label: 'Devoluciones, descuentos o bonificaciones', value: 'returns-discounts-or-bonuses' },
    { label: 'Sin efectos fiscales', value: 'without-tax-effects' },
  ];
  protected errorLoadingUserData = false;
  protected form: FormGroup<{
    cfdiUse: FormControl<CfdiUse | null>;
    companyName: FormControl<string | null>;
    email: FormControl<string | null>;
    fatherLastName: FormControl<string | null>;
    motherLastName: FormControl<string | null>;
    name: FormControl<string | null>;
    orderId: FormControl<string | null>;
    personType: FormControl<PersonType | null>;
    postalCode: FormControl<string | null>;
    rfc: FormControl<string | null>;
    taxRegime: FormControl<TaxRegime | null>;
  }>;
  protected isMobile = false;
  protected isVisible = false;
  protected loadingUserData = true;
  protected personTypeOptions: { label: string; value: PersonType; }[] = [
    { label: 'Natural person', value: 'natural-person' },
    { label: 'Legal entity', value: 'legal-entity' },
  ];
  protected sendingInvoice = false;
  protected taxRegimeOptions: { label: string; value: TaxRegime; }[] = [
    { label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras', value: 'agricultural-livestock-forestry-and-fishing-activities' },
    { label: 'Coordinados', value: 'coordinated' },
    { label: 'Ingresos por Dividendos (socios y accionistas)', value: 'dividend-income-partners-and-shareholders' },
    { label: 'General de Ley Personas Morales', value: 'general-law-legal-entities' },
    { label: 'Personas Físicas con Actividades Empresariales y Profesionales', value: 'individuals-with-business-and-professional-activities' },
    { label: 'Ingresos por intereses', value: 'interest-income' },
    { label: 'Arrendamiento', value: 'leasing' },
    { label: 'Personas Morales con Fines no Lucrativos', value: 'non-profit-legal-entities' },
    { label: 'Sin obligaciones fiscales', value: 'no-tax-obligations' },
    { label: 'Demás ingresos', value: 'other-income' },
    { label: 'Opcional para Grupos de Sociedades', value: 'optional-for-groups-of-companies' },
    { label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos', value: 'production-cooperatives-that-opt-to-defer-their-income' },
    { label: 'Régimen de Enajenación o Adquisición de Bienes', value: 'regime-of-alienation-or-acquisition-of-assets' },
    { label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas', value: 'regime-of-business-activities-with-income-through-technological-platforms' },
    { label: 'Régimen de los ingresos por obtención de premios', value: 'regime-of-income-from-prizes' },
    { label: 'Residentes en el Extranjero sin Establecimiento Permanente en México', value: 'residents-abroad-without-permanent-establishment-in-mexico' },
    { label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios', value: 'salaries-and-wages-and-income-assimilated-to-salaries' },
    { label: 'Régimen Simplificado de Confianza', value: 'simplified-trust-regime' },
    { label: 'Incorporación Fiscal', value: 'tax-incorporation' },
  ];
  protected user: User;
  private viewDestroyed = new Subject<void>();

  constructor(
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {}

  ngOnInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('window:resize')
  protected onResize(): void {
    this.isMobile = window.innerWidth <= 480;
  }

  close(): void {
    this.isVisible = false;
    this.invoiceSent = false;
    if (this.form) {
      this.form.reset();
    }
  }

  open(): void {
    this.invoiceCreated = !!this.invoiceId;
    this.createForm();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.getUserData();
    this.sendingInvoice = false;
    this.isVisible = true;
  }

  protected async getUserData(): Promise<void> {
    try {
      this.loadingUserData = true;
      this.user = await firstValueFrom(this.usersService.getCurrentUser()) as User;
      if (!this.invoiceCreated) {
        if (this.user) {
          this.form.patchValue({
            personType: 'natural-person',
            email: this.user.email,
            fatherLastName: this.user.fatherLastName,
            motherLastName: this.user.motherLastName,
            name: this.user.name,
          });
        }
        this.form.controls.orderId.patchValue(this.orderId);
      }
    } catch (error) {
      this.errorReportingService.log('InvoicingSidebarComponent.getUserData()', 'get-user-data', error);
      this.errorLoadingUserData = true;
    } finally {
      this.loadingUserData = false;
    }
  }

  protected modifyContactData(): void {
    const { value: personType } = this.form.controls.personType;
    this.form.controls.name.enable();
    if (personType === 'natural-person') {
      this.form.controls.fatherLastName.enable();
      this.form.controls.motherLastName.enable();
    }
  }

  protected async sendInvoice(): Promise<void> {
    this.sendingInvoice = true;
    this.invoiceSent = false;
    let step = '';
    try {
      if (this.form.invalid) {
        return;
      }
      const {
        cfdiUse,
        email,
        name,
        orderId,
        personType,
        postalCode,
        rfc,
        taxRegime,
      } = this.form.getRawValue() as FormValues;
      const fullName = `${name} ${this.form.controls.fatherLastName.value} ${this.form.controls.motherLastName.value}`;
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitInvoice'));
      step = 'submit-invoice';
      await this.formsService.submitInvoice({
        cfdiUse,
        recaptchaToken,
        email,
        orderId,
        postalCode,
        rfc,
        taxRegime,
        ...(personType === 'natural-person' ? {
          name: fullName,
          personType: 'natural-person',
        } : {
          companyName: this.form.getRawValue().companyName as string,
          name,
          personType: 'legal-entity',
        }),
      });
      this.invoiceSent = true;
    } catch (error) {
      this.close();
      this.errorReportingService.log('InvoicingSidebarComponent.sendInvoice()', step, error);
      this.invoiceSent = false;
      this.toastService.showError({ title: 'Error', description: 'An error occurred while sending your invoice.' });
    } finally {
      this.sendingInvoice = false;
    }
  }

  protected async sendInvoiceAgain(): Promise<void> {
    if (!this.invoiceId || !this.invoiceCreated) {
      return;
    }
    let step = '';
    this.sendingInvoice = true;
    this.invoiceSent = false;
    try {
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitInvoiceAgain'));
      step = 'submit-invoice-again';
      await this.formsService.submitInvoiceAgain({ invoiceId: this.invoiceId, orderId: this.orderId, recaptchaToken });
      this.invoiceSent = true;
    } catch (error) {
      this.close();
      this.errorReportingService.log('InvoicingSidebarComponent.sendInvoiceAgain()', step, error);
      this.toastService.showError({ title: 'Error', description: 'An error occurred while sending your invoice.' });
      this.invoiceSent = false;
    } finally {
      this.sendingInvoice = false;
    }
  }

  private createForm(): void {
    if (this.form || this.invoiceCreated) {
      return;
    }
    this.form = new FormGroup({
      cfdiUse: new FormControl<CfdiUse | null>(null, Validators.required),
      companyName: new FormControl<string | null>(null, [Validators.maxLength(36), Validators.pattern(NAME_PATTERN)]),
      email: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
      fatherLastName: new FormControl<string | null>({ value: null, disabled: true }, [Validators.maxLength(36), Validators.pattern(NAME_PATTERN)]),
      motherLastName: new FormControl<string | null>({ value: null, disabled: true }, [Validators.maxLength(36), Validators.pattern(NAME_PATTERN)]),
      name: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required, Validators.maxLength(36), Validators.pattern(NAME_PATTERN)]),
      orderId: new FormControl<string | null>({ value: this.orderId, disabled: true }, Validators.required),
      personType: new FormControl<PersonType | null>(null, Validators.required),
      postalCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(CONFIG.postalCodeLength), Validators.maxLength(CONFIG.postalCodeLength)]),
      rfc: new FormControl<string | null>(null, [Validators.required]),
      taxRegime: new FormControl<TaxRegime | null>(null, Validators.required),
    });

    this.form.controls.personType.valueChanges.pipe(takeUntil(this.viewDestroyed))
      .subscribe({
        next: (value) => {
          if (value === 'natural-person') {
            this.form.controls.companyName.removeValidators(Validators.required);
            this.form.controls.companyName.updateValueAndValidity();
            this.form.controls.fatherLastName.addValidators(Validators.required);
            this.form.controls.fatherLastName.updateValueAndValidity();
            this.form.controls.motherLastName.addValidators(Validators.required);
            this.form.controls.motherLastName.updateValueAndValidity();
            this.form.controls.rfc.clearValidators();
            this.form.controls.rfc.setValidators([Validators.required, Validators.pattern(RFC_NATURAL_PERSON_PATTERN)]);
            this.form.controls.rfc.updateValueAndValidity();
          } else {
            this.form.controls.companyName.addValidators(Validators.required);
            this.form.controls.companyName.updateValueAndValidity();
            this.form.controls.fatherLastName.removeValidators(Validators.required);
            this.form.controls.motherLastName.removeValidators(Validators.required);
            this.form.controls.fatherLastName.updateValueAndValidity();
            this.form.controls.motherLastName.updateValueAndValidity();
            this.form.controls.rfc.clearValidators();
            this.form.controls.rfc.setValidators([Validators.required, Validators.pattern(RFC_LEGAL_ENTITY_PATTERN)]);
            this.form.controls.rfc.updateValueAndValidity();
          }
          this.form.controls.name.disable();
          this.form.controls.fatherLastName.disable();
          this.form.controls.motherLastName.disable();
        }
      });
  }
}
