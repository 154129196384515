<section class="project-simulator-container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">{{ 'Back' | transloco }}</span>
      <span class="mobile-text">{{ 'Simulate your project' | transloco }} </span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">{{ 'Simulates the cost of the project you have in mind' | transloco }}</h5>
      <app-circular-progress [currentStep]="2" [numberSteps]="6" stepTitle="{{ 'Space to be remodelled' | transloco }}" [stepSubtitle]="subtitleByType | transloco" />
    </div>
    <div class="bottom-container animate__fadeIn">
      <div class="project-simulation-types-container">
        <h2 class="title">{{ 'What is the space you want to remodel?' | transloco }}</h2>
        <div class="project-simulation-type-container" [class.selected]="form.controls.type.value === 'bathroom'" (click)="setFormControlValue(form.controls.type, 'bathroom')">
          <span class="text">{{ 'Bathroom' | transloco }}</span>
        </div>
        <div class="project-simulation-type-container" [class.selected]="form.controls.type.value === 'kitchen'" (click)="setFormControlValue(form.controls.type, 'kitchen')">
          <span class="text">{{ 'Kitchen' | transloco }}</span>
        </div>
        <div class="project-simulation-type-container" [class.selected]="form.controls.type.value === 'integral-remodeling'" (click)="setFormControlValue(form.controls.type, 'integral-remodeling')">
          <span class="text">{{ 'Integral remodeling' | transloco }}</span>
        </div>
      </div>
      <div class="right-container">
        <img class="illustration animate__fadeIn" [src]="selectedImage" alt="{{ 'Illustration of the selected option' | transloco }}" />
        <div class="continue-button-container">
          <app-button label="Continue" styleClass="primary expand" type="submit" [disabled]="form.invalid" />
        </div>
      </div>
    </div>
  </form>
</section>
