<section class="main-container">
  <div class="purchase-status-container">
    <div class="title-container">
      <i class="icon-arrow-left" routerLink="/private-site/my-purchases"></i>
      <h2>Estado de la compra</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (orderItem) {
        <div class="animate__fadeIn">
          <div class="product-container">
            <img [src]="orderItem.thumbnail" alt="Imagen del producto" />
            <div class="info">
              <span class="name">{{ orderItem.name }}</span>
              <a routerLink="/ecommerce/products/{{ orderItem.id }}">Ver detalle del producto</a>
            </div>
          </div>
          <div class="purchase-status-info">
            @if (['in-process', 'collected'].includes(orderItem.shippingStatus)) {
              <span class="status active">En preparación</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">Llega el {{ orderItem.shippingDate | date: 'dd/MM/yyyy' }}</span>
              }
              <!-- TODO: Uncomment when the estimatedShippingDate is available
              @else {
                <span class="delivery-date">Llega entre el {{ ecommerceOrder.estimatedShippingDate.from | date: 'dd/MM/yyyy' }} y {{ ecommerceOrder.estimatedShippingDate.to | date: 'dd/MM/yyyy' }}</span>
              }
              -->
              <span class="status-description">
                @if (!itemDelayed) {
                  Estamos preparando tu pedido
                } @else {
                  Lamentamos que tu producto no haya llegado en la fecha estimada. Estamos trabajando para que sea entregado lo antes posible. Agradecemos tu comprensión.
                }
              </span>
            } @else if (orderItem.shippingStatus === 'in-transit') {
              <span class="status active">En camino</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">Llega el {{ orderItem.shippingDate | date: 'dd/MM/yyyy' }}</span>
              }
              <!-- TODO: Uncomment when the estimatedShippingDate is available
              @else {
                <span class="delivery-date">Llega entre el {{ ecommerceOrder.estimatedShippingDate.from | date: 'dd/MM/yyyy' }} y {{ ecommerceOrder.estimatedShippingDate.to | date: 'dd/MM/yyyy' }}</span>
              }
              -->
              <span class="status-description">
                @if (!itemDelayed) {
                  Tu pedido está en camino
                } @else {
                  Lamentamos que tu producto no haya llegado en la fecha estimada. Estamos trabajando para que sea entregado lo antes posible. Agradecemos tu comprensión.
                }
              </span>
            } @else if (orderItem.shippingStatus === 'delivered') {
              <span class="status done">Entregado</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">Llegó el {{ orderItem.shippingDate | date: 'dd/MM/yyyy' }}</span>
              }
              <span class="status-description">Tu pedido ha sido entregado</span>
            } @else if (orderItem.shippingStatus === 'exception-of-delivery') {
              <span class="status delayed">Fallo de entrega</span>
              @if (orderItem.shippingDate) {
                <span class="delivery-date">Llega el {{ orderItem.shippingDate | date: 'dd/MM/yyyy' }}</span>
              }
              <span class="status-description">Tu pedido no se pudo entregar debido a circunstancias fuera de lo normal</span>
            } @else if (['refund-approved', 'refund-requested'].includes(orderItem.shippingStatus)) {
              @if (orderItem.shippingStatus === 'refund-approved') {
                <span class="status delayed">Devolución confirmada</span>
              } @else {
                <span class="status delayed">Devolución solicitada</span>
              }
              <span class="status-description">Estamos gestionando tu devolución</span>
            } @else if (orderItem.shippingStatus === 'refunded') {
              <span class="status done">Reembolsado</span>
              <span class="status-description">Tu pedido ha sido reembolsado</span>
            } @else if (orderItem.shippingStatus === 'voided') {
              <span class="status done">Cancelado</span>
              <span class="status-description">Tu pedido ha sido cancelado, contáctanos para más información</span>
            }
            <div class="repurchase-button-container">
              <!-- TODO: Add logic to repurchase. This link is displayed only when one product was purchased -->
              <!--<app-button styleClass="secondary expand" label="Volver a comprar" />-->
            </div>
          </div>
          <!-- TODO: uncomment when rating logic is ready -->
          <!--<div class="rating-container">
            <h4>¿Qué te pareció la experiencia de compra?</h4>
            <div class="stars-container">
              <app-rating></app-rating>
            </div>
          </div>-->
          @if (showGenerateInvoiceOption) {
            <div class="invoice-container">
              <h4 class="title">Facturar esta compra</h4>
              <a class="link" (click)="openInvoicingSidebar()">
                @if (ecommerceOrder.invoiceId) {
                  Reenviar factura (generada el {{ ecommerceOrder.invoicedAt | date: 'dd/MM/yyyy' }})
                } @else {
                  Generar factura
                }
              </a>
            </div>
          }
          <div class="help-links-container">
            <h4 class="title">Ayuda con esta compra</h4>
            <a routerLink="/complaints-and-returns" [queryParams]="{
              orderId: ecommerceOrder.id,
              orderItemId: orderItem.id,
              service: 'ecommerce',
            }" target="_blank" class="link">Obtener ayuda</a>
            <a href="https://ayuda.mundohogar.com.mx/es/" target="_blank" class="link">Información sobre Garantías</a>
            <!-- TODO: Enable when implementing page to evaluate experience
            <a href="" target="_blank" class="link">Escribir una opinión sobre el servicio</a>
            --->
          </div>
          <div class="purchase-details-navigation-container" routerLink="/private-site/my-purchases/{{ ecommerceOrder.id }}/details" [queryParams]="{ itemId: orderItemId }">
            <span class="label">Detalles de la compra</span>
            <i class="icon-arrow-right"></i>
          </div>
        </div>
      }
    </app-retryable-section>
  </div>
  <div class="purchase-detail-container">
    <app-ecommerce-purchase-details [order]="ecommerceOrder" [showBackButton]="false" />
  </div>
</section>
@if (!loading && !errorLoading && ecommerceOrder) {
  <app-invoicing-sidebar #invoicingSidebar [orderId]="ecommerceOrder.id" [invoiceId]="ecommerceOrder.invoiceId" />
}