import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MockService } from '../mock/mock.service';
import { DOCUMENTS_PER_PAGE_DEFAULT, FilterOperator, PAGE_DEFAULT, Paginable, SearchConfig, SearchService } from './search.service';

@Injectable()
export class SearchServiceMock implements SearchService {
  constructor(
    private mockService: MockService,
  ) {}

  async get<T>(collection: string, id: string): Promise<T | null> {
    if (collection !== 'products') {
      throw new Error('Collection not supported');
    }
    return firstValueFrom(this.mockService.getProduct(id) as Observable<T | null>);
  }

  async search<T>(collection: string, config?: SearchConfig): Promise<Paginable<T>> {
    let observable: Observable<T[]>;
    if (collection === 'products') {
      observable = this.mockService.getProducts() as Observable<T[]>;
    } else {
      throw new Error('Search not supported');
    }
    let data: T[];
    if (config?.query?.value) {
      const { fields, value } = config.query;
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
      data = await firstValueFrom(observable.pipe(map((objects) => objects.filter((obj) => fields.some((field) => (obj as any)[field].toLowerCase().includes(value.toLowerCase()))))));
    } else {
      data = await firstValueFrom(observable);
    }
    if (config?.filters) {
      config.filters.forEach((filter) => {
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        data = data.filter((obj) => this.validateFilter((obj as any)[filter.field], filter.operator, filter.value));
      });
    }
    if (config?.order) {
      const { field, direction } = config.order;
      data = data.sort((a, b) => (direction === 'desc' ? 1 : -1) * (a[field as keyof T] > b[field as keyof T] ? -1 : 1));
    }
    const documentsPerPage = config?.pagination?.documentsPerPage ?? DOCUMENTS_PER_PAGE_DEFAULT;
    const page = config?.pagination?.page ?? PAGE_DEFAULT;
    const totalItems = data.length;
    const done = totalItems > 0 ? Math.ceil((totalItems / documentsPerPage)) === page : true;
    const pageData = data.slice((page - 1) * documentsPerPage, page * documentsPerPage);
    return {
      data: pageData,
      done,
      getMore: () => this.search<T>(collection, {
        filters: config?.filters,
        query: {
          fields: config?.query?.fields ?? [],
          value: config?.query?.value ?? null,
          weights: config?.query?.weights ?? [],
        },
        order: config?.order,
        pagination: {
          documentsPerPage,
          page: page + 1,
        },
      }),
      size: documentsPerPage,
      totalItems,
    };
  }

  private validateFilter(value: number | string, operator: FilterOperator, filterValue: number | string | string[]): boolean {
    switch (operator) {
    case '>':
      return value > filterValue;
    case '<':
      return value < filterValue;
    case '<=':
      return value <= filterValue;
    case '>=':
      return value >= filterValue;
    case '=':
      return value === filterValue;
    case '!=':
      return value !== filterValue;
    case 'in':
      return (filterValue as string[]).includes(`${value}`);
    }
  }
}
