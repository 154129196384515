import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { TRPCClientError } from '@trpc/client';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { filter, firstValueFrom, take } from 'rxjs';
import { ButtonComponent } from '../../../components/button/button.component';
import { emailPattern } from '../../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import { ErrorReportingService } from '../../../services/error-reporting/error-reporting.service';
import { FavoritesService } from '../../../services/favorites/favorites.service';
import { MovingOrdersService } from '../../../services/moving-orders/moving-orders.service';
import { PlatformService } from '../../../services/platform/platform.service';
import { SeoService } from '../../../services/seo/seo.service';
import { SessionProvider, SessionsService } from '../../../services/sessions/sessions.service';
import { ShoppingCartsService } from '../../../services/shopping-carts/shopping-carts.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UsersService } from '../../../services/users/users.service';
import { WarehouseOrdersService } from '../../../services/warehouse-orders/warehouse-orders.service';
import { brevoIdentify, validateAndNavigate } from '../../../utils/enrollment.utils';
import { isActive } from '../../../utils/forms.utils';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RouterLink,
    TabViewModule,
    TranslocoPipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sign-in.page.html',
  styleUrl: './sign-in.page.scss',
})
export class SignInPage implements OnInit {
  form: FormGroup<{
    email: FormControl<string | null>;
    password: FormControl<string | null>;
  }>;
  signInWithEmail = false;
  private loadingProvider: SessionProvider | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private dataStorageService: DataStorageService,
    private errorReportingService: ErrorReportingService,
    private favoritesService: FavoritesService,
    private location: Location,
    private movingOrdersService: MovingOrdersService,
    private platformService: PlatformService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private seoService: SeoService,
    private sessionsService: SessionsService,
    private shoppingCartsService: ShoppingCartsService,
    private toastService: ToastService,
    private usersService: UsersService,
    private warehouseOrdersService: WarehouseOrdersService,
  ) {
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
    this.form = new FormGroup({
      email: new FormControl<string | null>('', [Validators.required, Validators.pattern(emailPattern)]),
      password: new FormControl<string | null>('', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
    });
  }

  ngOnInit(): void {
    if (this.platformService.isServer()) {
      this.seoService.setCanonicalUrl('enrollment/sign-in');
    }
    const source = this.activatedRoute.snapshot.queryParamMap.get('source');
    if (source === 'verify-email') {
      setTimeout(() => {
        this.toastService.showWarning({ title: 'Finalizar registro', description: 'Para continuar con tu verificación, inicia sesión.' });
      }, 500);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  goBack(): void {
    if (this.signInWithEmail) {
      this.signInWithEmail = false;
      return;
    }
    return this.location.back();
  }

  goTo(url: string): void {
    const origin = this.activatedRoute.snapshot.queryParamMap.get('origin');
    const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
    const queryParams: Params = {
      ...(origin && { origin }),
      ...(redirectTo && { redirectTo }),
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate([url], { queryParams, queryParamsHandling: 'merge' });
  }

  isActive(control: FormControl): boolean {
    return isActive(control);
  }

  isLoading(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider === provider;
  }

  isLoadingOther(provider: SessionProvider): boolean {
    return !!this.loadingProvider && this.loadingProvider !== provider;
  }

  showEmailForm(): void {
    this.signInWithEmail = true;
  }

  async signIn(provider: 'apple' | 'email' | 'google'): Promise<void> {
    if (provider === 'email' && !this.form.valid) {
      return;
    }
    let step = '';
    try {
      this.loadingProvider = provider;
      step = `sign-in-with-provider-${provider}`;
      provider === 'email' ?
        await this.sessionsService.signIn({ provider, data: { email: this.form.getRawValue().email!, password: this.form.getRawValue().password! } }, () => firstValueFrom(this.recaptchaV3Service.execute('sessionsRouter/create'))) :
        await this.sessionsService.signIn({ provider }, () => firstValueFrom(this.recaptchaV3Service.execute('sessionsRouter/create')));
      step = 'get-user';
      const user = await firstValueFrom(this.usersService.getCurrentUser().pipe(filter((currentUser) => !!currentUser), take(1)));
      step = 'synchronize-shopping-cart';
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.shoppingCartsService.synchronize();
      step = 'synchronize-favorite';
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.favoritesService.synchronize();
      step = 'brevo-identify';
      brevoIdentify(user);
      step = 'validate-and-navigate';
      await validateAndNavigate(
        user,
        this.dataStorageService,
        this.router,
        this.activatedRoute.snapshot.queryParamMap,
        this.analyticsService,
        this.movingOrdersService,
        this.warehouseOrdersService,
      );
      const redirectTo = this.activatedRoute.snapshot.queryParamMap.get('redirectTo');
      this.analyticsService.logSignInEvent({
        method: provider,
        ...(redirectTo && { redirect_to: decodeURIComponent(redirectTo) }),
      });
    } catch (error) {
      const { code } = error as { code: string; message: string; };
      if (['popup-closed-by-user', 'cancelled-popup-request'].includes(code)) {
        return;
      }
      if (code === 'invalid-credential') {
        this.toastService.showError({ title: 'Error', description: 'El correo o la contraseña son incorrectos.' });
      } else if (code === 'network-request-failed') {
        this.toastService.showError({ title: 'Error', description: 'Ocurrió un error en tu conexión de red. Por favor, inténtalo de nuevo más tarde.' });
      } else if (error instanceof TRPCClientError && error.data.cause === 'user-not-active') {
        this.toastService.showError({
          title: 'Cuenta suspendida',
          description: 'Llegaste al límite de intentos para validar tu cuenta, para reactivarla ponte en contacto con nosotros.',
          action: () => this.router.navigate(['/contact']),
          actionLabel: 'Contactar a soporte',
        });
      } else {
        this.toastService.showError({ title: 'Error', description: 'Ha ocurrido un error inesperado, intente nuevamente más tarde.' });
        this.errorReportingService.log('SignInPage.signIn()', step, error);
      }
    } finally {
      this.loadingProvider = null;
    }
  }
}
