import { BillingAddress, EcommerceOrder } from '@homein-hogar-server';
import { ADDRESSES_MOCK } from '../addresses/addresses.mock';
import { CONFIG } from '../../constants';
import { PRODUCTS_MOCK } from '../products/products.mock';
import { USERS_MOCK } from '../users/users.mock';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ECOMMERCE_ORDER_ADDRESS: BillingAddress = {
  ...ADDRESSES_MOCK[0],
  company: null,
  fatherLastName: USERS_MOCK[0].fatherLastName as string,
  motherLastName: USERS_MOCK[0].motherLastName as string,
  name: USERS_MOCK[0].name as string,
};

export const ECOMMERCE_ORDERS_MOCK: EcommerceOrder[] = [
  // TODO F&F: fix this mocks
  /* {
    additionalData: null,
    billingAddress: { ... ECOMMERCE_ORDER_ADDRESS },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    estimatedShippingDate: {
      from: new Date(),
      to: new Date(),
    },
    id: 'Kv9zKyurnm7KLfsIh1Ba',
    items: [
      {
        ...PRODUCTS_MOCK[0],
        quantity: 1,
        thumbnail: PRODUCTS_MOCK[0].thumbnails[0],
      },
      {
        ...PRODUCTS_MOCK[1],
        quantity: 2,
        thumbnail: PRODUCTS_MOCK[1].thumbnails[0],
      },
    ],
    notes: null,
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    shippingAddress: { ...ECOMMERCE_ORDER_ADDRESS },
    shippingDetails: [
      {
        date: new Date(),
        status: 'in-process',
      },
    ],
    shippingDate: null,
    shippingStatus: 'in-process',
    shippingPrice: 0,
    status: 'in-progress',
    subtotal: 990.19,
    totalAmount: 1178.33,
    tax: 188.14,
    transactionId: 'jSwObCTo50WkHcahmIiP',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    billingAddress: { ... ECOMMERCE_ORDER_ADDRESS },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    estimatedShippingDate: {
      from: new Date(),
      to: new Date(),
    },
    id: 'o9yOJ7chiqaToFpWYGQ4',
    items: [
      {
        ...PRODUCTS_MOCK[2],
        quantity: 3,
        thumbnail: PRODUCTS_MOCK[2].thumbnails[0],
      },
    ],
    notes: null,
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    shippingAddress: { ...ECOMMERCE_ORDER_ADDRESS },
    shippingDetails: [
      {
        date: new Date(),
        status: 'collected',
      },
      {
        date: new Date(),
        status: 'in-process',
      },
    ],
    shippingDate: new Date(),
    shippingStatus: 'collected',
    shippingPrice: 0,
    status: 'in-progress',
    subtotal: 1990.19,
    totalAmount: 2203.62,
    tax: 388.14,
    transactionId: 'uFSDSuVDdgk6ktKkVFqj',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    billingAddress: { ... ECOMMERCE_ORDER_ADDRESS },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    estimatedShippingDate: {
      from: new Date(),
      to: new Date(),
    },
    id: 'rQKGKxRS0rD0ZcJqBrwA',
    items: [
      {
        ...PRODUCTS_MOCK[3],
        quantity: 2,
        thumbnail: PRODUCTS_MOCK[3].thumbnails[0],
      },
    ],
    notes: null,
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    shippingAddress: { ...ECOMMERCE_ORDER_ADDRESS },
    shippingDetails: [
      {
        date: new Date(),
        status: 'in-transit',
      },
      {
        date: new Date(),
        status: 'collected',
      },
      {
        date: new Date(),
        status: 'in-process',
      },
    ],
    shippingDate: new Date(),
    shippingStatus: 'in-transit',
    shippingPrice: 0,
    status: 'in-progress',
    subtotal: 1990.19,
    totalAmount: 2203.62,
    tax: 388.14,
    transactionId: 'EkB69eSvvHbOaincJY0Y',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    billingAddress: { ... ECOMMERCE_ORDER_ADDRESS },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    estimatedShippingDate: {
      from: new Date(),
      to: new Date(),
    },
    id: 'RZl6FtghbwLtQXVh5lod',
    items: [
      {
        ...PRODUCTS_MOCK[4],
        quantity: 1,
        thumbnail: PRODUCTS_MOCK[4].thumbnails[0],
      },
      {
        ...PRODUCTS_MOCK[5],
        quantity: 5,
        thumbnail: PRODUCTS_MOCK[5].thumbnails[0],
      },
    ],
    notes: null,
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    shippingAddress: { ...ECOMMERCE_ORDER_ADDRESS },
    shippingDetails: [
      {
        date: new Date(),
        status: 'exception-of-delivery',
      },
      {
        date: new Date(),
        status: 'in-transit',
      },
      {
        date: new Date(),
        status: 'collected',
      },
      {
        date: new Date(),
        status: 'in-process',
      },
    ],
    shippingDate: new Date(),
    shippingStatus: 'exception-of-delivery',
    // | 'delivered'
    shippingPrice: 0,
    status: 'in-progress',
    subtotal: 1990.19,
    totalAmount: 2203.62,
    tax: 388.14,
    transactionId: 'QUeHiV3cBRQV59C7nIaO',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    billingAddress: { ... ECOMMERCE_ORDER_ADDRESS },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    estimatedShippingDate: {
      from: new Date(),
      to: new Date(),
    },
    id: 'qXXZBCaRaIh6WSEqCgX0',
    items: [
      {
        ...PRODUCTS_MOCK[6],
        quantity: 1,
        thumbnail: PRODUCTS_MOCK[6].thumbnails[0],
      },
    ],
    notes: null,
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    shippingAddress: { ...ECOMMERCE_ORDER_ADDRESS },
    shippingDetails: [
      {
        date: new Date(),
        status: 'delivered',
      },
      {
        date: new Date(),
        status: 'in-transit',
      },
      {
        date: new Date(),
        status: 'collected',
      },
      {
        date: new Date(),
        status: 'in-process',
      },
    ],
    shippingDate: new Date(),
    shippingStatus: 'delivered',
    shippingPrice: 0,
    status: 'in-progress',
    subtotal: 1990.19,
    totalAmount: 2203.62,
    tax: 388.14,
    transactionId: '8yna9F2HYjRNjGh32m8j',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  },
  {
    additionalData: null,
    billingAddress: { ... ECOMMERCE_ORDER_ADDRESS },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    estimatedShippingDate: {
      from: new Date(),
      to: new Date(),
    },
    id: 'T4Pb1UNfnGq6ciSkXe0y',
    items: [
      {
        ...PRODUCTS_MOCK[6],
        quantity: 1,
        thumbnail: PRODUCTS_MOCK[6].thumbnails[0],
      },
    ],
    notes: null,
    paymentIntentId: 'WXXK7DGcWqxwBL12KxKH',
    shippingAddress: { ...ECOMMERCE_ORDER_ADDRESS },
    shippingDetails: [
      {
        date: new Date(),
        status: 'collected',
      },
      {
        date: new Date(),
        status: 'in-process',
      },
    ],
    shippingDate: new Date(),
    shippingStatus: 'collected',
    shippingPrice: 0,
    status: 'cancelled',
    subtotal: 1990.19,
    totalAmount: 2203.62,
    tax: 388.14,
    transactionId: 'HaNwUe6I6tSoS53AHJVw',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  }*/
  {
    additionalData: null,
    billingAddress: { ... ECOMMERCE_ORDER_ADDRESS },
    createdAt: new Date(),
    currency: CONFIG.defaultCurrency,
    discounts: 0,
    estimatedShippingDate: {
      from: new Date(),
      to: new Date(),
    },
    id: 'n60YyoeeyxX2mVevazeN',
    invoicedAt: null,
    invoiceId: null,
    items: [
      {
        ...PRODUCTS_MOCK[0],
        partnerItemTrackingId: null,
        quantity: 4,
        thumbnail: PRODUCTS_MOCK[0].thumbnails[0],
        shippingDetails: [
          {
            date: new Date(),
            status: 'collected',
          },
          {
            date: new Date(),
            status: 'in-process',
          },
        ],
        shippingDate: new Date(),
        shippingStatus: 'collected',
      },
    ],
    notes: null,
    originalPrice: 4000,
    originalShippingCost: 0,
    paymentIntentId: 'ZupaDcqpEydfR7X99Ct1',
    shippingAddress: { ...ECOMMERCE_ORDER_ADDRESS },
    shippingCost: 0,
    status: 'fulfilled',
    subtotal: 1990.19,
    totalAmount: 2203.62,
    tax: 388.14,
    transactionId: 'HaNwUe6I6tSoS53AHJVw',
    updatedAt: new Date(),
    userId: USERS_MOCK[0].id,
  }
];
