<div class="footer-note-container">
  <div class="footer-note">
    <p class="note-title">Servicios entregados por HomeIn y proveedores asociados.<p>
    <p class="note-text">HomeIn SPA, será el responsable exclusivo de la operación y funcionamiento del presente sitio, por tanto, Banco Santander México, S.A. Institución de Banca Múltiple, Grupo Financiero Santander México, se libera de cualquier responsabilidad relacionada con el presente sitio y no es responsable por la calidad de dichos productos y servicios.</p>
  </div>
</div>
<footer class="footer">
  <div class="footer-container">
    <div class="logo"></div>
    <div class="content-container">
      <div class="section">
        <div class="title">¿Qué deseas hacer?</div>
        <ul>
          <li><a routerLink="/moving" id="btn-link-NaPXDUNr">Mudarme de hogar</a></li>
          <li><a routerLink="/remodeling" id="btn-link-2dJOM8Xe">Remodelar mi hogar</a></li>
          <li><a routerLink="/ecommerce" id="btn-link-pWY0xIMk">Compras para el hogar</a></li>
          <li><a routerLink="/home-assistance" id="btn-link-BT13gAOi">Planes de asistencia</a></li>
          <li><a routerLink="/warehouse" id="btn-link-W7knyd2-">Rentar bodega</a></li>
        </ul>
      </div>
      <div class="section">
        <h2 class="title">Funcionamiento</h2>
        <ul>
          <li><a routerLink="/what-is-mundo-hogar" id="btn-link-CCfZpHVV">¿Qué es Mundo Hogar?</a></li>
        </ul>
      </div>
      <div class="section">
        <h2 class="title">Ayuda</h2>
        <ul>
          <li><a href="https://ayuda.mundohogar.com.mx/" target="_blank" id="btn-link-dFZ7WA6_">Ir al centro de ayuda</a></li>
          <li><a href="https://ayuda.mundohogar.com.mx/preguntas-frecuentes" target="_blank" id="btn-link-rsrrnpA4">Preguntas frecuentes</a></li>
          <li><a href="https://ayuda.mundohogar.com.mx/aviso-de-privacidad" target="_blank" id="btn-link-6SlfJF4M">Aviso de privacidad</a></li>
          <li><a href="https://ayuda.mundohogar.com.mx/terminos-y-condiciones" target="_blank" id="btn-link-W6S-_PC8">Términos y condiciones</a></li>
          <li><a routerLink="/complaints-and-returns" target="_blank" id="btn-link-VpFcM2w9">Reclamos y devoluciones</a></li>
          <li><a routerLink="/contact" id="btn-link-iUw-BwBq">Contacto</a></li>
        </ul>
      </div>
      <div class="section">
        <div class="title">¿No eres cliente Santander?</div>
        <ul>
          <li><a href="https://www.santander.com.mx/personas/santander-digital/hazte-cliente.html" target="_blank" rel="noopener" id="btn-link-CcLAb24S">Quiero ser cliente</a></li>
        </ul>
      </div>
      <!-- TODO: enable this later on -->
      <!--<div class="section">
        <div class="title">Síguenos en redes sociales</div>
        <div class="social">
          <a href="https://www.facebook.com/" target="_blank" rel="noopener" id="btn-link-KZlCqcZ-"><i class="icon-facebook"></i></a>
          <a href="https://www.instagram.com/" target="_blank" rel="noopener" id="btn-link-Gl6rpAQM"><i class="icon-instagram"></i></a>
        </div>
      </div>-->
    </div>
  </div>
  <div class="version">MHv{{ appVersionCode }}</div>
</footer>
