import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { User } from '@homein-hogar-server';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { DropdownModule } from 'primeng/dropdown';
import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom, Subject } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { RetryableSectionComponent } from '../../components/retryable-section/retryable-section.component';
import { SendResultSidebarComponent } from '../../components/send-result-sidebar/send-result-sidebar.component';
import { emailPattern, namePattern, phoneNumberPattern } from '../../constants/validation-patterns.constants';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ErrorReportingService } from '../../services/error-reporting/error-reporting.service';
import { FormsService } from '../../services/forms/forms.service';
import { SeoService } from '../../services/seo/seo.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';
import { emptyArrayValidator } from '../../validators/array-validator';

interface Option {
  icon: string;
  label: string;
  service: Service;
}

type Financing = 'credit-card' | 'mortgage-credit' | 'personal-credit'; // TODO: enable later on 'need-advice';
type Service = 'ecommerce' | 'home-assistance' | 'moving' | 'remodeling' | 'warehouse';

@Component({
  selector: 'app-leads-form',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RetryableSectionComponent,
    RouterLink,
    SendResultSidebarComponent,
    TooltipModule,
  ],
  templateUrl: './leads-form.page.html',
  styleUrl: './leads-form.page.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LeadsFormPage implements OnInit, OnDestroy {
  @ViewChild('phoneNumberInput') phoneNumberInput: InputMask;
  @ViewChild('sendResultSidebar') sendResultSidebar: SendResultSidebarComponent;
  errorLoading = false;
  financingOptions: { label: string; subtitle: string; value: Financing; }[] = [
    { label: 'Tarjeta de crédito', subtitle: 'Hasta 36 MSI', value: 'credit-card' },
    { label: 'Crédito Hipotecario', subtitle: '(10, 15 y 20 años)', value: 'mortgage-credit' },
    { label: 'Crédito Personal', subtitle: '(6 a 72 meses)', value: 'personal-credit' },
    // TODO: enable later on
    // { label: 'Necesito asesoria', subtitle: '(No lo tengo claro)', value: 'need-advice' },
  ];
  form: FormGroup<{
    clientCode: FormControl<string | null>;
    email: FormControl<string | null>;
    fatherLastName: FormControl<string | null>,
    financing: FormControl<Financing | null>;
    motherLastName: FormControl<string | null>,
    name: FormControl<string | null>,
    phoneNumber: FormControl<string | null>;
    services: FormControl<Service[] | null>;
  }>;
  isClient = true;
  loading = true;
  options: Option[] = [
    { icon: 'icon-truck', label: 'Mudarme de hogar', service: 'moving' },
    { icon: 'icon-remodeling', label: 'Remodelar mi hogar', service: 'remodeling' },
    { icon: 'icon-warehouses', label: 'Rentar una bodega', service: 'warehouse' },
    { icon: 'icon-armchair', label: 'Compras para el hogar', service: 'ecommerce' },
    { icon: 'icon-home-assistance', label: 'Planes para el hogar', service: 'home-assistance' },
  ];
  requestSent = false;
  sendingForm = false;
  sendingRequest = false;
  user: User | null = null;
  private viewDestroyed = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private errorReportingService: ErrorReportingService,
    private formsService: FormsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private seoService: SeoService,
    private toastService: ToastService,
    private usersService: UsersService,
  ) {
    this.form = new FormGroup({
      clientCode: new FormControl<string | null>(null, [Validators.required, Validators.pattern(/^[0-9]{1,8}$/), Validators.maxLength(8), Validators.minLength(8)]),
      email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(emailPattern)]),
      name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      financing: new FormControl<Financing | null>(null, [Validators.required]),
      fatherLastName: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(36), Validators.pattern(namePattern)]),
      motherLastName: new FormControl<string | null>(null, [Validators.maxLength(36), Validators.pattern(namePattern)]),
      phoneNumber: new FormControl<string | null>('52', [Validators.required, Validators.pattern(phoneNumberPattern)]),
      services: new FormControl<Service[] | null>([], [Validators.required, emptyArrayValidator]),
    });
    const { title, description } = this.activatedRoute.snapshot.data;
    this.seoService.setMetaTags({ title, description });
  }

  ngOnDestroy(): void {
    this.viewDestroyed.next();
  }

  ngOnInit(): void {
    this.analyticsService.logLeadsForm({ step: 'start' });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initialize();
    this.handleQueryParams();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  changePhoneNumber(): void {
    this.form.controls.phoneNumber.patchValue('52');
    setTimeout(() => this.phoneNumberInput.focus(), 150);
  }

  goToHome(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigate(['/']);
  }

  async initialize(): Promise<void> {
    this.loading = true;
    this.errorLoading = false;
    try {
      this.user = await firstValueFrom(this.usersService.getCurrentUser());
      if (this.user) {
        this.form.patchValue({
          email: this.user.email ?? null,
          name: this.user.name ?? null,
          fatherLastName: this.user.fatherLastName ?? null,
          motherLastName: this.user.motherLastName ?? null,
          ...(this.user.phoneNumber && { phoneNumber: this.user.phoneNumber.replace(/\D/g, '') }),
        });
        this.form.updateValueAndValidity();
      }
    } catch (error) {
      this.errorReportingService.log('LeadsFormPage.initialize()', 'get-user-data', error);
      this.errorLoading = true;
    } finally {
      this.loading = false;
    }
  }

  async onSubmit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.sendingForm = true;
    let step = '';
    try {
      if (this.user && this.user.emailValidated && this.user.profileDataCompleted && this.user.phoneNumber !== `+${this.form.controls.phoneNumber.value}`) {
        step = 'update-user';
        await this.usersService.updateUser({
          ...this.user,
          phoneNumber: `+${this.form.controls.phoneNumber.value}`,
        });
        this.toastService.showSuccess({ title: 'Número actualizado', description: 'Hemos actualizado tu número de contacto exitosamente.' });
      }
    } catch (error) {
      this.errorReportingService.log('LeadsFormPage.onSubmit()', step, error);
      this.toastService.showError({ title: 'Algo salió mal', description: 'No se ha podido actualizar tu número telefónico, vuelve a intentarlo.' });
      return;
    }
    this.analyticsService.logLeadsForm({ step: 'confirm_contact' });
    this.sendingForm = false;
    this.sendResultSidebar.open();
  }

  async sendRequest(): Promise<void> {
    this.sendingRequest = true;
    let step = '';
    try {
      step = 'get-recaptcha-token';
      const recaptchaToken = await firstValueFrom(this.recaptchaV3Service.execute('formsRouter/submitLeadsForm'));
      const { clientCode, email, financing, name, fatherLastName, motherLastName, phoneNumber, services } = this.form.value;
      step = 'submit-leads-form';
      await this.formsService.submitLeadsForm({
        ...(clientCode && { clientCode: clientCode }),
        email: email!,
        financing: financing!,
        name: name!,
        fatherLastName: fatherLastName!,
        motherLastName: motherLastName ?? null,
        phoneNumber: `+${phoneNumber!}`,
        recaptchaToken,
        services: services!,
      });
      this.toastService.showSuccess({ title: 'Solicitud enviada', description: 'Hemos enviado la confirmación por WhatsApp al número proporcionado.' });
      this.requestSent = true;
      this.analyticsService.logGenerateLeadEvent({ lead_source: 'leads-form' });
      this.analyticsService.logLeadsForm({ step: 'generate_lead', services: services!, financing: financing!, is_client: this.isClient });
    } catch (error) {
      this.errorReportingService.log('LeadsFormPage.sendRequest()', step, error);
      this.toastService.showError({ title: 'Algo salió mal', description: 'No se ha podido enviar tu solicitud, vuelve a intentarlo.' });
      this.requestSent = false;
    } finally {
      this.sendingRequest = false;
    }
  }

  toggleIsClient(): void {
    if (this.isClient) {
      this.form.controls.clientCode.addValidators(Validators.required);
    } else {
      this.form.controls.clientCode.setValue(null);
      this.form.controls.clientCode.removeValidators(Validators.required);
    }
    this.form.controls.clientCode.updateValueAndValidity();
  }

  toggleSelected(service: Service): void {
    if (!this.form.controls.services.value) {
      this.form.controls.services.setValue([]);
    }
    if (this.form.controls.services.value!.includes(service)) {
      this.form.controls.services.setValue(this.form.controls.services.value!.filter((value) => value !== service));
    } else {
      this.form.controls.services.setValue([...this.form.controls.services.value!, service]);
    }
    this.form.controls.services.updateValueAndValidity();
  }

  private handleQueryParams(): void {
    const servicesParam = this.activatedRoute.snapshot.queryParams['services'];
    if (servicesParam) {
      const services = servicesParam.split(',') as Service[];
      const validServices = services.filter((service) => this.options.some((option) => option.service === service));
      if (validServices.length) {
        this.form.controls.services.patchValue(validServices);
      }
    }
    const financing = this.activatedRoute.snapshot.queryParams['financing'];
    if (financing && ['credit-card', 'mortgage-credit', 'personal-credit'].includes(financing)) {
      this.form.patchValue({ financing });
    }
    this.form.updateValueAndValidity();
  }
}