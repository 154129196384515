import { Injectable } from '@angular/core';
import { Account } from '@homein-accounts-server';
import {
  Address,
  AdvertisingSpace,
  AdvertisingSpaceType,
  ChargeIntent,
  EcommerceOrder,
  Favorite,
  HomeAssistanceOrder,
  HomeAssistancePlan,
  MovingOrder,
  PaymentIntent,
  PostalCodeLocation,
  RemodelingOrder,
  SearchEngineProduct,
  ShoppingCart,
  User,
  UserEnrollment,
  WarehouseOrder,
} from '@homein-hogar-server';
import * as faker from 'faker';
import { map, Observable, Subject } from 'rxjs';
import { triggerRandomError } from '../../utils/mock.utils';
import { ACCOUNTS_MOCK } from '../accounts-server/accounts/accounts.mock';
import { ADDRESSES_MOCK } from '../addresses/addresses.mock';
import { ADVERTISING_SPACES_MOCK } from '../advertising-spaces/advertising-spaces.mock';
import { CHARGE_INTENTS_MOCK } from '../charge-intents/charge-intents.mock';
import { DataKey, DataStorageService } from '../data-storage/data-storage.service';
import { ECOMMERCE_ORDERS_MOCK } from '../ecommerce-orders/ecommerce-orders.mock';
import { HOME_ASSISTANCE_ORDER_MOCK } from '../home-assistance-orders/home-assistance-orders.mock';
import { HOME_ASSISTANCE_PLANS_MOCK } from '../home-assistance-plans/home-assistance-plans.mock';
import { MOVING_ORDERS_MOCK } from '../moving-orders/moving-orders.mock';
import { PAYMENT_INTENTS_MOCK } from '../payment-intents/payment-intents.mock';
import { POSTAL_CODE_LOCATIONS_MOCK } from '../postal-code-locations/postal-code-locations.mock';
import { PRODUCTS_MOCK } from '../products/products.mock';
import { REMODELING_ORDERS_MOCK } from '../remodeling-orders/remodeling-orders.mock';
import { USERS_MOCK } from '../users/users.mock';
import { WAREHOUSE_ORDERS_MOCK } from '../warehouse-orders/warehouse-orders.mock';

class Mock<T> {
  data: T[] = [];
  subject = new Subject<T[]>();
}

type DefaultFields = 'createdAt' | 'id' | 'updatedAt';
type CreateData<T> = Omit<T, DefaultFields> & {
  id?: string;
};

// TODO: find a way to make all this generic, it should emulate firestore behavior
// tslint:disable-next-line:max-classes-per-file
@Injectable({
  providedIn: 'root'
})
export class MockService {
  private accounts = new Mock<Account>();
  private addresses = new Mock<Address>();
  private advertisingSpaces = new Mock<AdvertisingSpace>();
  private chargeIntents = new Mock<ChargeIntent>();
  private ecommerceOrders = new Mock<EcommerceOrder>();
  private favorites = new Mock<Favorite>();
  private homeAssistanceOrders = new Mock<HomeAssistanceOrder>();
  private homeAssistancePlans = new Mock<HomeAssistancePlan>();
  private movingOrders = new Mock<MovingOrder>();
  private paymentIntents = new Mock<PaymentIntent>();
  private postalCodeLocations = new Mock<PostalCodeLocation>();
  private products = new Mock<SearchEngineProduct>();
  private remodelingOrders = new Mock<RemodelingOrder>();
  private shoppingCarts = new Mock<ShoppingCart>();
  private users = new Mock<User | UserEnrollment>();
  private warehouseOrders = new Mock<WarehouseOrder>();

  constructor(
    private dataStorageService: DataStorageService,
  ) {
    this.initializeData();
  }

  createAccount(data: CreateData<Account>): Promise<string> {
    return this.create<Account>(this.accounts, data, DataKey.MockAccounts);
  }

  createAddress(data: CreateData<Address>): Promise<string> {
    return this.create<Address>(this.addresses, data, DataKey.MockAddresses);
  }

  createFavorite(data: CreateData<Favorite>): Promise<string> {
    return this.create<Favorite>(this.favorites, data, DataKey.MockFavorites);
  }

  createPaymentIntent(data: CreateData<PaymentIntent>): Promise<string> {
    return this.create<PaymentIntent>(this.paymentIntents, data, DataKey.MockPaymentIntents);
  }

  createRemodelingOrder(data: CreateData<RemodelingOrder>): Promise<string> {
    return this.create<RemodelingOrder>(this.remodelingOrders, data, DataKey.MockRemodelingOrders);
  }

  createShoppingCart(data: CreateData<ShoppingCart>): Promise<string> {
    return this.create<ShoppingCart>(this.shoppingCarts, data, DataKey.MockShoppingCarts);
  }

  createUser(data: CreateData<UserEnrollment>): Promise<string> {
    return this.create<UserEnrollment>(this.users, data, DataKey.MockUsers);
  }

  getAccount(id: string): Observable<Account | null> {
    return this.get<Account>(this.accounts, id);
  }

  getAccounts(): Observable<Account[]> {
    return this.getAll<Account>(this.accounts);
  }

  getAddress(id: string): Observable<Address | null> {
    return this.get<Address>(this.addresses, id);
  }

  getAddresses(): Observable<Address[]> {
    return this.getAll<Address>(this.addresses);
  }

  getAdvertisingSpaces(type: AdvertisingSpaceType): Observable<AdvertisingSpace[]> {
    return this.getAll(this.advertisingSpaces).pipe(map((values: AdvertisingSpace[]) => {
      return values.filter((value) => value.type === type && value.status === 'active');
    }));
  }

  getChargeIntent(id: string): Observable<ChargeIntent | null> {
    return this.get<ChargeIntent>(this.chargeIntents, id);
  }

  getEcommerceOrder(id: string): Observable<EcommerceOrder | null> {
    return this.get<EcommerceOrder>(this.ecommerceOrders, id);
  }

  getEcommerceOrders(): Observable<EcommerceOrder[]> {
    return this.getAll<EcommerceOrder>(this.ecommerceOrders);
  }

  getFavorite(id: string): Observable<Favorite | null> {
    return this.get<Favorite>(this.favorites, id);
  }

  getFavorites(): Observable<Favorite[]> {
    return this.getAll<Favorite>(this.favorites);
  }

  getHomeAssistanceOrder(id: string): Observable<HomeAssistanceOrder | null> {
    return this.get<HomeAssistanceOrder>(this.homeAssistanceOrders, id);
  }

  getHomeAssistanceOrders(): Observable<HomeAssistanceOrder[]> {
    return this.getAll<HomeAssistanceOrder>(this.homeAssistanceOrders);
  }

  getHomeAssistancePlan(id: string): Observable<HomeAssistancePlan | null> {
    return this.get<HomeAssistancePlan>(this.homeAssistancePlans, id);
  }

  getHomeAssistancePlans(): Observable<HomeAssistancePlan[]> {
    return this.getAll<HomeAssistancePlan>(this.homeAssistancePlans);
  }

  getLastEcommerceOrderInProgress(): Observable<EcommerceOrder | null> {
    return this.getAllByField<EcommerceOrder>(this.ecommerceOrders, 'status', 'in-progress').pipe(map((orders) => orders.length ? orders[0] : null));
  }

  getLastMovingOrderInProgress(): Observable<MovingOrder | null> {
    return this.getAllByField<MovingOrder>(this.movingOrders, 'status', ['pending-confirmation-visit', 'pending-payment', 'waiting-confirmation', 'waiting-moving'])
      .pipe(map((movingOrders) => movingOrders.length ? movingOrders[0] : null));
  }

  getLastRemodelingOrderInProgress(): Observable<RemodelingOrder | null> {
    return this.getAllByField<RemodelingOrder>(this.remodelingOrders, 'status', ['in-progress', 'lead-created', 'pending', 'waiting'])
      .pipe(map((movingOrders) => movingOrders.length ? movingOrders[0] : null));
  }

  getLastWarehouseOrderInProgress(userId: string): Observable<WarehouseOrder | null> {
    return this.getAllByField<WarehouseOrder>(this.warehouseOrders, 'status', ['done', 'pending-payment', 'waiting-confirmation'])
      .pipe(map((orders) => {
        orders = orders.filter((order) => order.userId === userId && new Date(order.finishAt).getTime() >= (new Date((new Date()).getTime() + 86400000)).getTime());
        return orders.length ? orders[0] : null;
      }));
  }

  getMovingOrder(id: string): Observable<MovingOrder | null> {
    return this.get<MovingOrder>(this.movingOrders, id);
  }

  getMovingOrders(): Observable<MovingOrder[]> {
    return this.getAll<MovingOrder>(this.movingOrders);
  }

  getPaymentIntent(id: string): Observable<PaymentIntent | null> {
    return this.get<PaymentIntent>(this.paymentIntents, id);
  }

  getPaymentIntentsPaid(): Observable<PaymentIntent[]> {
    return this.getAllByField(this.paymentIntents, 'status', 'paid');
  }

  getPostalCodeLocation(id: string): Observable<PostalCodeLocation | null> {
    return this.get<PostalCodeLocation>(this.postalCodeLocations, id);
  }

  getProduct(id: string): Observable<SearchEngineProduct | null> {
    return this.get<SearchEngineProduct>(this.products, id);
  }

  getProducts(): Observable<SearchEngineProduct[]> {
    return this.getAll<SearchEngineProduct>(this.products);
  }

  getRemodelingOrder(id: string): Observable<RemodelingOrder | null> {
    return this.get<RemodelingOrder>(this.remodelingOrders, id);
  }

  getRemodelingOrders(): Observable<RemodelingOrder[]> {
    return this.getAll<RemodelingOrder>(this.remodelingOrders);
  }

  getShoppingCart(id: string): Observable<ShoppingCart | null> {
    return this.get<ShoppingCart>(this.shoppingCarts, id);
  }

  getUser(id: string): Observable<User | UserEnrollment | null> {
    return this.get<User | UserEnrollment>(this.users, id);
  }

  getUsers(): Observable<(User | UserEnrollment)[]> {
    return this.getAll<User | UserEnrollment>(this.users);
  }

  getWarehouseOrder(id: string): Observable<WarehouseOrder | null> {
    return this.get<WarehouseOrder>(this.warehouseOrders, id);
  }

  getWarehouseOrders(): Observable<WarehouseOrder[]> {
    return this.getAll<WarehouseOrder>(this.warehouseOrders);
  }

  updateChargeIntent(id: string, data: Partial<ChargeIntent>): Promise<void> {
    return this.update<ChargeIntent>(this.chargeIntents, id, data, DataKey.MockChargeIntents);
  }

  updateFavorite(id: string, data: Partial<Favorite>): Promise<void> {
    return this.update<Favorite>(this.favorites, id, data, DataKey.MockFavorites);
  }

  updatePaymentIntent(id: string, data: Partial<PaymentIntent>): Promise<void> {
    return this.update<PaymentIntent>(this.paymentIntents, id, data, DataKey.MockPaymentIntents);
  }

  updateShoppingCart(id: string, data: Partial<ShoppingCart>): Promise<void> {
    return this.update<ShoppingCart>(this.shoppingCarts, id, data, DataKey.MockShoppingCarts);
  }

  updateUser(id: string, data: Partial<User | UserEnrollment>): Promise<void> {
    return this.update<User | UserEnrollment>(this.users, id, data, DataKey.MockUsers);
  }

  private create<T>(mock: Mock<T>, data: CreateData<T>, key?: DataKey): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        let found = false;
        if (data.id) {
          for (let i = 0; i < mock.data.length; i++) {
            /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
            if ((mock.data[i] as any).id === (data as any).id) {
              mock.data[i] = {
                ...mock.data[i],
                ...data,
                createdAt: new Date(),
                updatedAt: new Date(),
              };
              found = true;
              break;
            }
          }
        }
        if (!found) {
          mock.data.push({
            ...data,
            createdAt: new Date(),
            id: data.id ?? faker.random.alphaNumeric(20),
            updatedAt: new Date(),
          } as T);
        }
        if (key) {
          this.dataStorageService.set(key, mock.data);
        }
        this.triggerChange(mock, 0);
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        const id = found ? data.id : (mock.data[mock.data.length - 1] as any).id;
        resolve(id);
      }, 1000);
    });
  }

  private get<T extends { id: string; }>(mock: Mock<T>, id: string): Observable<T | null> {
    return this.getAll(mock).pipe(map((values: T[]) => {
      return values.find((value) => value.id === id) ?? null;
    }));
  }

  private getAll<T>(mock: Mock<T>): Observable<T[]> {
    this.triggerChange(mock);
    return new Observable((subscriber) => mock.subject.subscribe((values) => {
      if (triggerRandomError(subscriber)) {
        return;
      }
      subscriber.next(values);
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getAllByField<T>(mock: Mock<T>, field: string, fieldValue: any): Observable<T[]> {
    return this.getAll(mock).pipe(map((values: T[]) => {
      if (Array.isArray(fieldValue)) {
        return values.filter((value) => fieldValue.includes(value[field as keyof T]));
      }
      return values.filter((value) => value[field as keyof T] === fieldValue);
    }));
  }

  private initializeData(): void {
    this.accounts.data = this.mergeEntitiesList(ACCOUNTS_MOCK, DataKey.MockAccounts);
    this.advertisingSpaces.data = ADVERTISING_SPACES_MOCK;
    this.addresses.data = this.mergeEntitiesList(ADDRESSES_MOCK, DataKey.MockAddresses);
    this.chargeIntents.data = this.mergeEntitiesList(CHARGE_INTENTS_MOCK, DataKey.MockChargeIntents);
    this.ecommerceOrders.data = this.mergeEntitiesList(ECOMMERCE_ORDERS_MOCK, DataKey.MockEcommerceOrders);
    this.favorites.data = this.mergeEntitiesList([], DataKey.MockFavorites);
    this.homeAssistanceOrders.data = HOME_ASSISTANCE_ORDER_MOCK;
    this.homeAssistancePlans.data = HOME_ASSISTANCE_PLANS_MOCK;
    this.movingOrders.data = this.mergeEntitiesList(MOVING_ORDERS_MOCK, DataKey.MockMovingOrders);
    this.paymentIntents.data = this.mergeEntitiesList(PAYMENT_INTENTS_MOCK, DataKey.MockPaymentIntents);
    this.postalCodeLocations.data = POSTAL_CODE_LOCATIONS_MOCK;
    this.products.data = PRODUCTS_MOCK;
    this.remodelingOrders.data = this.mergeEntitiesList(REMODELING_ORDERS_MOCK, DataKey.MockRemodelingOrders);
    this.shoppingCarts.data = this.mergeEntitiesList([], DataKey.MockShoppingCarts);
    this.users.data = this.mergeEntitiesList(USERS_MOCK, DataKey.MockUsers);
    this.warehouseOrders.data = this.mergeEntitiesList(WAREHOUSE_ORDERS_MOCK, DataKey.MockWarehouseOrders);
  }

  private mergeEntitiesList<T>(listA: (T & { id: string })[] | null, key: DataKey): T[] {
    const mergedMap = new Map<string, T>();
    if (listA) {
      listA.forEach((elementA) => {
        if (elementA) {
          mergedMap.set(elementA.id, elementA);
        }
      });
    }
    const listB = this.dataStorageService.getLocal<T[]>(key);
    if (listB) {
      listB.forEach((elementB) => {
        if (elementB) {
          /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
          mergedMap.set((elementB as any).id, elementB);
        }
      });
    }
    const mergedArray = Array.from(mergedMap.values());
    this.dataStorageService.set(key, mergedArray);
    return mergedArray;
  }

  private triggerChange<T>(mock: Mock<T>, timeout = 1000): void {
    setTimeout(() => mock.subject.next(mock.data), timeout);
  }

  private update<T>(mock: Mock<T>, id: string, data: Partial<T>, key?: DataKey): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (triggerRandomError(reject)) {
          return;
        }
        let found = false;
        for (let i = 0; i < mock.data.length; i++) {
          /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
          if ((mock.data[i] as any).id === id) {
            mock.data[i] = {
              ...mock.data[i],
              ...data,
              updatedAt: new Date(),
            };
            found = true;
            break;
          }
        }
        if (!found) {
          throw new Error(`Entity ${id} not found.`);
        }
        if (key) {
          this.dataStorageService.set(key, mock.data);
        }
        this.triggerChange(mock, 0);
        resolve();
      }, 1000);
    });
  }
}
