<section class="my-remodelings-container">
  <div class="title-container">
    <i class="icon icon-arrow-left" (click)="goBack()"></i>
    <h2 class="title">{{ 'My remodeling' | transloco }}
      @if (remodelingOrders.length) {
        <span class="total-items">({{ remodelingOrders.length }})</span>
      }
    </h2>
  </div>
  <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
    @if (!remodelingOrders.length) {
      <div class="empty-remodelings animate__fadeIn">
        <img class="image" src="/assets/illustrations/remodeling-empty.svg" alt="{{ 'You don\'t have remodeling illustration' | transloco }}" />
        <h5 class="title">{{ 'You have no scheduled/quoted remodeling yet' | transloco }}</h5>
        <p class="subtitle">{{ 'Quote your next remodeling' | transloco }}</p>
        <app-button label="Go to remodeling" styleClass="secondary" href="/remodeling" [queryParams]="{ action: 'quote' }"></app-button>
      </div>
    } @else {
      <div class="orders-container animate__fadeIn">
        @for (remodelingOrder of remodelingOrders; track $index) {
          <div class="order-container">
            <div class="remodelings-container">
              <a class="remodeling-container" routerLink="/private-site/my-remodelings/{{ remodelingOrder.id }}">
                <div class="column first">
                  <div class="right-container">
                    @if (remodelingOrder.status === 'lead-created') {
                      <span class="remodeling-status active">{{ 'Request received' | transloco }}</span>
                      <span class="description"></span> <!-- TODO: Add description -->
                      <span class="description-status">{{ 'We have received your details. We will call you soon to gather more information about your project.' | transloco }}</span>
                    } @else if (remodelingOrder.status === 'pending-budget') {
                      <span class="remodeling-status active">{{ 'Preparing your budget' | transloco }}</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] ?? '' }}</span>
                      <span class="description-status">{{ 'We are preparing a good budget for you.' | transloco }}</span>
                    } @else if (remodelingOrder.status === 'pending') {
                      @if (!remodelingOrder.paid || remodelingOrder.pendingChargeIntentId) {
                        <span class="remodeling-status active">{{ 'All set, pay your budget' | transloco }}</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">{{ 'Your quote has been sent! Pay it to confirm and schedule the start of the work.' | transloco }}</span>
                      } @else if (!remodelingOrder.contractSigned) {
                        <span class="remodeling-status active">{{ 'Now all that is missing is your signature' | transloco }}</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">{{ 'Your quote is paid for! Sign it to confirm and schedule the start of the work.' | transloco }}</span>
                      }
                    } @else if (remodelingOrder.status === 'waiting') {
                      <span class="remodeling-status active">{{ 'All set!' | transloco }}</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                      <span class="description-status">{{ 'Your project is scheduled to start on DD/MM/AAAA' | transloco: { date: remodelingOrder.startAt | date: 'dd/MM/yyyy' } }}</span>
                    } @else if (remodelingOrder.status === 'in-progress') {
                      @if (!remodelingOrder.pendingChargeIntentId) {
                        <span class="remodeling-status active">{{ 'Project on site' | transloco }}</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">{{ 'Your project has started on DD/MM/AAAA' | transloco: { date: remodelingOrder.startAt | date: 'dd/MM/yyyy' } }}</span>
                      } @else {
                        <span class="remodeling-status pending">{{ 'Pending payment' | transloco }}</span>
                        <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                        <span class="description-status">{{ 'You have a pending payment' | transloco }}</span>
                      }
                    } @else if (remodelingOrder.status === 'done') {
                      <span class="remodeling-status">{{ 'Completed project' | transloco }}</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                      <span class="description-status">{{ 'Your project has ended on DD/MM/AAAA' | transloco: { date: remodelingOrder.endAt | date: 'dd/MM/yyyy' } }}</span>
                      <span class="description-status">{{ 'Help us improve the Mundo Hogar experience.' | transloco }}</span>
                    } @else if (remodelingOrder.status === 'cancelled') {
                      <span class="remodeling-status pending">{{ 'Project cancelled' | transloco }}</span>
                      <span class="description">{{ remodelingOrder.additionalData['opportunityName'] }}</span>
                      <span class="description-status">{{ 'We regret to cancel your project.' | transloco }}</span>
                      <span class="description-status">{{ 'Please let us know how we can improve the service.' | transloco }}</span>
                    }
                  </div>
                </div>
                <div class="column second">
                  @if (remodelingOrder.status === 'lead-created') {
                    <span class="description-status">{{ 'We have received your details. We will call you soon to gather more information about your project.' | transloco }}</span>
                  } @else if (remodelingOrder.status === 'pending-budget') {
                    <span class="description-status">{{ 'We are preparing a good budget for you.' | transloco }}</span>
                  } @else if (remodelingOrder.status === 'pending') {
                    @if (!remodelingOrder.paid || remodelingOrder.pendingChargeIntentId) {
                      <span class="description-status">{{ 'Your quote has been sent! Pay it to confirm and schedule the start of the work.' | transloco }}</span>
                    } @else if (!remodelingOrder.contractSigned) {
                      <span class="description-status">{{ 'Your quote is paid for! Sign it to confirm and schedule the start of the work.' | transloco }}</span>
                    }
                  } @else if (remodelingOrder.status === 'waiting') {
                    <span class="description-status">{{ 'Your project is scheduled to start on DD/MM/AAAA' | transloco: { date: remodelingOrder.startAt | date: 'dd/MM/yyyy' } }}</span>
                  } @else if (remodelingOrder.status === 'in-progress') {
                    @if (!remodelingOrder.pendingChargeIntentId) {
                      <span class="description-status">{{ 'Your project has started on DD/MM/AAAA' | transloco: { date: remodelingOrder.startAt | date: 'dd/MM/yyyy' } }}</span>
                    } @else {
                      <span class="description-status">{{ 'You have a pending payment' | transloco }}</span>
                    }
                  } @else if (remodelingOrder.status === 'done') {
                    <span class="description-status">{{ 'Your project has ended on DD/MM/AAAA' | transloco: { date: remodelingOrder.endAt | date: 'dd/MM/yyyy' } }}</span>
                    <span class="description-status">{{ 'Help us improve the Mundo Hogar experience.' | transloco }}</span>
                  } @else if (remodelingOrder.status === 'cancelled') {
                    <span class="description-status">{{ 'We regret to cancel your project.' | transloco }}</span>
                    <span class="description-status">{{ 'Please let us know how we can improve the service.' | transloco }}</span>
                  }
                </div>
                <div class="column third" (click)="childEventClicked($event)">
                  @if (remodelingOrder.status === 'pending') {
                    @if (!remodelingOrder.paid) {
                      <app-button label="Pay budget" styleClass="secondary" href="/payment-gateway/checkout" [queryParams]="{
                        resourceIds: [remodelingOrder.pendingChargeIntentId],
                        resourceType: 'charge-intent',
                        source: 'remodeling',
                      }" />
                    } @else if (!remodelingOrder.contractSigned) {
                      <app-button label="See detail" styleClass="secondary" href="/private-site/my-remodelings/{{ remodelingOrder.id }}" />
                    }
                  } @else if (remodelingOrder.status === 'waiting') {
                    <app-button label="See detail" styleClass="secondary" href="/private-site/my-remodelings/{{ remodelingOrder.id }}" />
                  } @else if (remodelingOrder.status === 'in-progress') {
                    @if (!remodelingOrder.pendingChargeIntentId) {
                      <app-button label="See detail" styleClass="secondary" href="/private-site/my-remodelings/{{ remodelingOrder.id }}" />
                    } @else {
                      <app-button label="Make payment" styleClass="tertiary" href="/payment-gateway/checkout" [queryParams]="{
                        resourceIds: [remodelingOrder.pendingChargeIntentId],
                        resourceType: 'charge-intent',
                        source: 'remodeling',
                      }" />
                      <app-button label="See detail" styleClass="secondary" href="/private-site/my-remodelings/{{ remodelingOrder.id }}" />
                    }
                  } @else if (remodelingOrder.status === 'cancelled' || remodelingOrder.status === 'done') {
                    <!-- TODO: Enable when implementing page to evaluate experience
                    <app-button label="Evaluate experience" styleClass="tertiary" href="/" />
                    -->
                    <app-button label="See detail" styleClass="secondary" href="/private-site/my-remodelings/{{ remodelingOrder.id }}" />
                  }
                </div>
                <i class="icon icon-arrow-right"></i>
              </a>
            </div>
          </div>
        }
      </div>
    }
  </app-retryable-section>
</section>