<div class="warehouse-status-container animate__fadeIn">
  <div class="left-column">
    <div class="go-back-container" routerLink="/private-site/my-warehouses">
      <i class="icon icon-arrow-left"></i>
      <h2 class="title">Estado de la bodega</h2>
    </div>
    <app-retryable-section [loading]="loading" [error]="errorLoading" (retry)="initialize()">
      @if (order) {
        <div class="order-info-container">
          <h4 class="status {{ orderFinalStatus }}">
            @switch (orderFinalStatus) {
              @case ('about-to-expire') {
                Próximo a vencer
              } @case ('done') {
                Activo
              } @case ('finalized') {
                Finalizado
              } @case ('pending-payment') {
                Pendiente de pago
              } @case ('voided') {
                Anulado
              } @case ('waiting-confirmation') {
                Pendiente a confirmación
              }
            }  
          </h4>
          <p class="total-volume"> Bodega {{ order.additionalData['volume'] }}㎥</p>
          @if (order.additionalData['hasTransportation']) {
            <p class="transfer">Incluye traslado a bodega</p>
          } @else {
            <p class="transfer">No incluye traslado a bodega</p>
          }
          <p class="location">{{ order.warehouseAddress }}</p>
          <a class="location-link" href="https://www.google.com/maps/search/?api=1&query={{ this.order.warehouseAddress }}" target="_blank" rel="noopener">(<span class="text">Ver ubicación de la bodega</span>)</a>
          <p class="start-and-end-dates">Inicio: {{ order.startAt | date: 'dd/MM/yyyy' }} - Vence: {{ order.finishAt | date: 'dd/MM/yyyy' }}</p>
          <p class="description">
            @switch (orderFinalStatus) {
              @case ('about-to-expire') {
                Tu arriendo de bodega está próximo a vencer. Si quieres mantener tu plan activo, por favor contáctanos.
              } @case ('done') {
                Tu bodega está activa, úsala cuando lo necesites. Para cualquier duda por favor contáctanos.
              } @case ('finalized') {
                Tu arriendo de bodega ya finalizó. Podrás contratarlo nuevamente en el botón reactivar.
              } @case ('pending-payment') {
                Necesitas realizar el pago para reservar tu arriendo de bodega.
              } @case ('voided') {
                Lamentamos cancelar la renta de tu bodega. Por favor, comentamos como podemos mejorar el servicio.
              } @case ('waiting-confirmation') {
                Tu bodega está en proceso de ser confirmada. Revisaremos que el pago se haya realizado y otros procesos.
              }
            }
          </p>
          <div class="action-button-container">
            @if (['done', 'about-to-expire'].includes(orderFinalStatus)) {
              <app-button innerId="btn-co1nd1Ex" label="Ir al chat" styleClass="secondary expand" [href]="order.additionalData['chatUrl']" target="_blank" rel="noopener" />
            } @else {
              @switch (orderFinalStatus) {
                @case ('pending-payment') {
                  <app-button innerId="btn-sv7Zbgbk" label="Ir a reservar la bodega" styleClass="secondary expand" (action)="goToReserveWarehouse(order)" />
                } @case ('finalized') {
                  <!-- TODO: Uncomment this line when the action is available -->
                  <!--<app-button label="Renovar arriendo" styleClass="tertiary" />-->
                }
              }
            }
          </div>
        </div>
        <div class="help-links-container">
          <h4 class="title">Ayuda con esta reserva</h4>
          <a routerLink="/complaints-and-returns" [queryParams]="{
            orderId: order.id,
            service: 'warehouse',
          }" target="_blank" class="link">Obtener ayuda</a>
          <a href="https://ayuda.mundohogar.com.mx/es/" target="_blank" class="link">Información sobre Garantías</a>
          <!-- TODO: Enable when implementing page to evaluate experience
          <a href="" target="_blank" class="link">Escribir una opinión sobre el servicio</a>
          --->
        </div>
        @if (order && !['pending-payment', 'waiting-confirmation'].includes(order.status)) {
          <div class="purchase-details-container" routerLink="/private-site/my-warehouses/{{ orderId }}/details">
            <h4 class="title">Detalles de la compra</h4>
            <i class="icon icon-arrow-right"></i>
          </div>
        }
      }
    </app-retryable-section>
  </div>
  @if (order && !['pending-payment', 'waiting-confirmation'].includes(order.status)) {
    <div class="right-column">
        <app-warehouse-purchase-details [order]="order" [showBackButton]="false" />
    </div>
  }
</div>