<section class="project-simulator-container">
  <form  [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="go-back-container" (click)="goBack()">
      <i class="icon icon-arrow-left"></i>
      <span class="text">Atrás</span>
      <span class="mobile-text">Simula tu proyecto</span>
    </div>
    <div class="circular-stepper-container">
      <h5 class="title">Simula el costo del proyecto que tienes en mente</h5>
      <app-circular-progress [currentStep]="1" [numberSteps]="6" stepTitle="Ubicación del inmueble" stepSubtitle="Siguiente: Espacio a remodelar" />
    </div>
    <div class="bottom-container animate__fadeIn">
      <div class="project-simulation-types-container">
        <h2 class="title">¿En qué tipo de inmueble se realizará la remodelación?</h2>
        <div class="project-simulation-type-container" id="btn-U7Iu_A0I" [class.selected]="form.controls.propertyType.value === 'house'" (click)="setFormControlValue(form.controls.propertyType, 'house')">
          <span class="text">Casa</span>
        </div>
        <div class="project-simulation-type-container" id="btn-iDmouRVU" [class.selected]="form.controls.propertyType.value === 'apartment'" (click)="setFormControlValue(form.controls.propertyType, 'apartment')">
          <span class="text" [class.selected]="form.controls.propertyType.value === 'apartment'">Departamento</span>
        </div>
        <h2 class="title">¿Cuando quieres realizarlo?</h2>
        <div class="project-simulation-type-container" id="btn-siTZOGD8" [class.selected]="form.controls.executionTimeFrame.value === 'before-3-months'" (click)="setFormControlValue(form.controls.executionTimeFrame, 'before-3-months')">
          <span class="text">Antes de 3 meses</span>
        </div>
        <div class="project-simulation-type-container" id="btn-iddMPZYj" [class.selected]="form.controls.executionTimeFrame.value === '3-6-months'" (click)="setFormControlValue(form.controls.executionTimeFrame, '3-6-months')">
          <span class="text">Entre 3 a 6 meses</span>
        </div>
        <div class="project-simulation-type-container" id="btn-j1_OYFBF" [class.selected]="form.controls.executionTimeFrame.value === 'more-than-6-months'" (click)="setFormControlValue(form.controls.executionTimeFrame, 'more-than-6-months')">
          <span class="text">Más de 6 meses</span>
        </div>
        <h2 class="title">¿Dónde se encuentra el inmueble?</h2>
        <div class="input-container">
          <label for="postal-code">Ingresa el código postal del inmueble</label>
          <input id="postal-code" type="text" aria-describedby="postal-code-help" pInputText [formControl]="form.controls.postalCode" maxlength="5" />
          @if (form.controls.postalCode.touched && form.controls.postalCode.invalid) {
            <small id="postal-code-help">
              @if (form.controls.postalCode.errors?.['required']) {
                El código postal es requerido.
              }
              @if (form.controls.postalCode.errors?.['min'] || form.controls.postalCode.errors?.['max'] || form.controls.postalCode.errors?.['pattern']) {
                El código postal debe ser un número de 5 dígitos.
              }
            </small>
          }
        </div>
      </div>
      <div class="right-container">
        @if (form.controls.propertyType.value === 'house') {
          <img class="illustration animate__fadeIn" src="/assets/illustrations/project-simulation-prop-type-house.svg" alt="Ilustración de casa" />
        } @else if (form.controls.propertyType.value === 'apartment') {
          <img class="illustration animate__fadeIn" src="/assets/illustrations/project-simulation-prop-type-apartment.svg" alt="Ilustración de departamento" />
        } @else {
          <img class="illustration animate__fadeIn" src="/assets/illustrations/generic-empty.svg" alt="Ilustración por defecto" />
        }
          <div class="continue-button-container">
            <app-button innerId="btn-Qygo6BUk" label="Continuar" styleClass="primary expand" type="submit" [disabled]="form.invalid" />
          </div>
      </div>
    </div>
  </form>
</section>
