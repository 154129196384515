<p-sidebar class="select-assistance-plan-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [showCloseIcon]="false" [blockScroll]="true" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <h6 class="title">{{ title }}</h6>
      <button class="custom-button-clear close" (click)="close()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="tabs-container">
      <app-retryable-section [error]="errorLoading" [loading]="loading" (retry)="initialize()">
        @if (homeAssistancePlan) {
          <p-tabView class="tab-panel-plan" (onChange)="selectDuration($event)">
            <p-tabPanel [header]="'Monthly' | transloco" [selected]="selectedDuration === '1-month'">
              <div class="plan-duration">Plan mensual</div>
              <div class="plan-pricing-container">
                <span class="price">{{ homeAssistancePlan.pricing.external['1-month']['debit'].price | currencyFormatter: homeAssistancePlan.currency }}</span>
                <span class="currency">{{ homeAssistancePlan.currency }}</span>
              </div>
              <div class="plan-original-price">Antes {{ homeAssistancePlan.pricing.external['1-month']['debit'].originalPrice | currencyFormatter: homeAssistancePlan.currency }} {{ homeAssistancePlan.currency }}</div>
            </p-tabPanel>
            <p-tabPanel [header]="'Biannually' | transloco" [selected]="selectedDuration === '6-months'">
              <div class="plan-duration">Plan semestral</div>
              <div class="plan-pricing-container">
                <span class="price">{{ homeAssistancePlan.pricing.external['6-months']['debit'].price | currencyFormatter: homeAssistancePlan.currency }}</span>
                <span class="currency">{{ homeAssistancePlan.currency }}</span>
              </div>
              <div class="plan-original-price">Antes {{ homeAssistancePlan.pricing.external['6-months']['debit'].originalPrice | currencyFormatter: homeAssistancePlan.currency }} {{ homeAssistancePlan.currency }}</div>
            </p-tabPanel>
            <p-tabPanel [header]="'Annual' | transloco" [selected]="selectedDuration === '12-months'">
              <div class="plan-duration">Plan anual</div>
              <div class="plan-pricing-container">
                <span class="price">{{ homeAssistancePlan.pricing.external['12-months']['debit'].price | currencyFormatter: homeAssistancePlan.currency }}</span>
                <span class="currency">{{ homeAssistancePlan.currency }}</span>
              </div>
              <div class="plan-original-price">Antes {{ homeAssistancePlan.pricing.external['12-months']['debit'].originalPrice | currencyFormatter: homeAssistancePlan.currency }} {{ homeAssistancePlan.currency }}</div>
              <p class="limited-time-offer">¡Solo por tiempo limitado!</p>
              <div class="most-chosen">
                <i class="icon icon-star"></i>
                <p class="text">El más elegido</p>
              </div>
            </p-tabPanel>
          </p-tabView>
          <p-accordion [multiple]="true" class="accordion-conditions" expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
            <p-accordionTab class="accordion-tab" [header]="'X Conditions' | transloco: { plan: homeAssistancePlan.name }" iconPos="end">
              @for (detail of homeAssistancePlan.details; track $index) {
                <div class="service-title">{{ detail.title }}</div>
                <p class="service-subtitle">{{ detail.subtitle }}</p>
                <p class="service-description">{{ detail.description }}</p>
              }
            </p-accordionTab>
          </p-accordion>
          <div class="terms-and-conditions">Al contratar estás aceptando los <a class="link" [href]="homeAssistancePlan.termsAndConditionsUrl" target="_blank" rel="noopener">{{ 'Terms and Conditions of X' | transloco: { plan: homeAssistancePlan.name } }}</a></div>
        }
      </app-retryable-section>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    @if (homeAssistancePlan) {
      <div class="button-container">
        <app-button label="Contratar plan" routerLink="/payment-gateway/checkout" [queryParams]="{ resourceIds: [planId], resourceSubtypes: [selectedDuration], resourceType: 'home-assistance-plan', source: 'home-assistance' }" styleClass="primary" />
      </div>
    }
  </ng-template>
</p-sidebar>