import { Injectable } from '@angular/core';
import { ValidateBankIdentificationNumberData } from '@homein-hogar-server';
import { firstValueFrom } from 'rxjs';
import { MockService } from '../mock/mock.service';
import { UsersService } from '../users/users.service';
import { BankIdentificationNumbersService } from './bank-identification-numbers.service';

@Injectable({
  providedIn: 'root'
})
export class BankIdentificationNumbersServiceMock implements BankIdentificationNumbersService {
  constructor(
    private mockService: MockService,
    private usersService: UsersService,
  ) {
  }

  async validate(data: ValidateBankIdentificationNumberData): Promise<void> {
    const user = await firstValueFrom(this.usersService.getCurrentUser());
    if (!user) {
      return Promise.reject({ code: 'user-not-found', message: 'User not found.' });
    }
    // TODO: add more test cases
    if (data.bankIdentificationNumber === '111111') {
      return Promise.reject({ code: 'error', message: 'Invalid bank identification number.' });
    }
    await this.mockService.updateUser(user.id, {
      ...data,
      bankValidated: true,
      enrollmentStatus: user.profileDataCompleted && user.emailValidated ? 'done' : 'pending',
    });
    return Promise.resolve();
  }
}
