<p-sidebar class="invoicing-sidebar" [(visible)]="isVisible" [position]="isMobile ? 'bottom' : 'right'" [blockScroll]="true" [showCloseIcon]="false" (onHide)="close()">
  <ng-template pTemplate="header">
    <div class="header-container">
      <span class="title">{{ (invoiceCreated ? 'Resend invoice' : 'Generate invoice') | transloco }}</span>
      <button class="custom-button-clear close" (click)="close()"><i class="icon-close"></i></button>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <app-retryable-section [loading]="loadingUserData" [error]="errorLoadingUserData" (retry)="getUserData()">
      @if (!invoiceCreated && !invoiceSent) {
        <div class="invoicing-container">
          <div class="form-container">
            <form [formGroup]="form" class="form">
              <div class="input-container select-container">
                <label class="label">Selecciona tipo de persona</label>
                @for (option of personTypeOptions; track $index) {
                  <div class="option">
                    <label [for]="option.label">{{ option.label | transloco }}</label>
                    <p-radioButton class="radio-button" [inputId]="option.label" [value]="option.value" [formControl]="form.controls.personType"></p-radioButton>
                  </div>
                }
              </div>
              <span class="disclaimer">Primero confirma tus datos para generar la factura</span>
              @if (form.controls.personType.value === 'natural-person') {
                <div class="input-container">
                  <label class="label" [class.disabled]="form.controls.name.disabled" for="name">Nombre(s)*</label>
                  <input class="input" pInputText id="name" [formControl]="form.controls.name" aria-describedby="name-help" />
                  <small id="name-help" [class.hidden]="!form.controls.name.touched || !form.controls.name.errors" class="p-error input-error">Ingresa un nombre válido.</small>
                </div>
                <div class="input-container">
                  <label class="label" [class.disabled]="form.controls.fatherLastName.disabled" for="father-last-name">Apellido paterno*</label>
                  <input class="input" pInputText id="father-last-name" [formControl]="form.controls.fatherLastName" aria-describedby="father-last-name-help" />
                  <small id="father-last-name-help" [class.hidden]="!form.controls.fatherLastName.touched || !form.controls.fatherLastName.errors" class="p-error input-error">Ingresa un apellido paterno válido.</small>
                </div>
                <div class="input-container">
                  <label class="label" [class.disabled]="form.controls.motherLastName.disabled" for="mother-last-name">Apellido materno</label>
                  <input class="input" pInputText id="mother-last-name" [formControl]="form.controls.motherLastName" aria-describedby="mother-last-name-help" />
                  <small id="mother-last-name-help" [class.hidden]="!form.controls.motherLastName.touched || !form.controls.motherLastName.errors" class="p-error input-error">Ingresa un apellido materno válido.</small>
                </div>
                <a class="modify-data-option" routerLink="/private-site/profile/my-personal-data" id="btn-link-133AgAOi">Modificar datos de contacto</a>
              }
              <div class="input-container">
                <label class="label" [class.disabled]="form.controls.email.disabled" for="email">Correo electrónico*</label>
                <input class="input" pInputText id="email" type="email" [formControl]="form.controls.email" aria-describedby="email-help" />
                <small id="email-help" [class.hidden]="!form.controls.email.touched || !form.controls.email.errors" class="p-error input-error">Ingresa un correo válido.</small>
              </div>
              @if (form.controls.personType.value === 'legal-entity') {
                <div class="input-container">
                  <label class="label" for="company-name">Razón social*</label>
                  <input class="input" pInputText id="company-name" [formControl]="form.controls.companyName" aria-describedby="company-name-help" />
                  <small id="company-name-help" [class.hidden]="!form.controls.companyName.touched || !form.controls.companyName.errors" class="p-error input-error">Ingresa una razón social válida.</small>
                </div>
              }
              <div class="input-container">
                <label class="label" for="rfc">RFC*</label>
                <input class="input" pInputText id="rfc" [formControl]="form.controls.rfc" placeholder="ABCD1234567X8" aria-describedby="rfc-help" maxlength="13" />
                <small id="rfc-help" [class.hidden]="!form.controls.rfc.touched || !form.controls.rfc.errors" class="p-error input-error">Ingresa un RFC válido.</small>
              </div>
              <div class="input-container">
                <label class="label" [class.disabled]="form.controls.orderId.disabled" for="order-id">ID Orden*</label>
                <input class="input" pInputText id="order-id" [formControl]="form.controls.orderId" />
              </div>
              <div class="input-container">
                <label class="label" for="postal-code">Código postal*</label>
                <input class="input" pInputText id="postal-code" [formControl]="form.controls.postalCode" maxlength="5" aria-describedby="postal-code-help" />
                <small id="postal-code-help" [class.hidden]="!form.controls.postalCode.touched || !form.controls.postalCode.errors" class="p-error input-error">Ingresa un código postal válido.</small>
              </div>
              <div class="input-container">
                <label class="label" for="tax-regime">Régimen fiscal*</label>
                <p-dropdown class="input dropdown" id="tax-regime" [formControl]="form.controls.taxRegime" [options]="taxRegimeFiltered" optionLabel="label" optionValue="value" placeholder="Selecciona un régimen fiscal" aria-describedby="tax-regime-help" />
                <small id="tax-regime-help" [class.hidden]="!form.controls.taxRegime.touched || !form.controls.taxRegime.errors" class="p-error input-error">Ingresa un régimen fiscal válido.</small>
              </div>
              <div class="input-container">
                <label class="label" for="type">Uso del CFDI*</label>
                <p-dropdown class="input dropdown" id="type" [formControl]="form.controls.cfdiUse" [options]="cfdiUseOptions" optionLabel="label" optionValue="value" placeholder="Selecciona un uso" aria-describedby="cfdi-use-help" />
                <small id="cfdi-use-help" [class.hidden]="!form.controls.cfdiUse.touched || !form.controls.cfdiUse.errors" class="p-error input-error">Ingresa un uso de CFDI válido.</small>
              </div>
            </form>
          </div>
        </div>
      } @else if (invoiceCreated && !invoiceSent && user) {
        <div class="re-send-invoice-container">
          <img class="image" src="/assets/illustrations/executive-woman.svg" alt="Imagen mujer ejecutiva">
          <span class="title">¡Reenviaremos tu factura!</span>
          <span class="description">Tu factura de la compra #<span class="highlighted">{{ orderId }}</span> será reenviada al correo electrónico <span class="highlighted">{{ user.email }}</span>.</span>
        </div>
      } @else if (invoiceSent && user) {
        <div class="invoice-sent-container">
          <img class="image" src="/assets/illustrations/success-check.svg" alt="Imagen verificación de éxito">
          <span class="title">¡Tu factura fue enviada!</span>
          <span class="description">Tu factura de la compra #<span class="highlighted">{{ orderId }}</span> fue enviada al correo electrónico <span class="highlighted">{{ user.email }}</span>.</span>
        </div>
      }
    </app-retryable-section>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="button-container">
    @if (!invoiceCreated && !invoiceSent) {
      <app-button class="button" label="Generar factura" styleClass="primary" [disabled]="form.invalid" [loading]="sendingInvoice" (action)="sendInvoice()" />
    } @else if (invoiceCreated && !invoiceSent) {
      <app-button class="button" label="Reenviar factura" styleClass="primary" [loading]="sendingInvoice" (action)="sendInvoiceAgain()" />
    } @else if (invoiceSent) {
      <app-button class="button" label="Aceptar" styleClass="primary" (action)="close()" />
    }
    </div>
  </ng-template>
</p-sidebar>